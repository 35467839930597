import { Stack } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';

export const HelpEmbed = () => {
  const { isMd } = useResponsive();

  return (
    <Stack width={isMd ? '100%' : '50%'} height="fit-content">
      <div
        className="meetings-iframe-container"
        style={{
          minWidth: isMd ? '100%' : '320px',
          maxHeight: '500px',
          border: 'none',
        }}
        data-src="https://meetings-eu1.hubspot.com/joakim-bertilsson/self-serve-demo?embed=true"
      ></div>
    </Stack>
  );
};
