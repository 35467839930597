import { lightTheme, Text } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';

export const MonoText = ({ children }: PropsWithChildren) => (
  <Text
    color={lightTheme.palette.contrast.black}
    style={{
      fontFamily: 'Menlo, Consolas, Monaco, monospace',
      fontSize: '14px',
      wordBreak: 'break-all',
    }}
  >
    {children}
  </Text>
);
