import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  CloseRounded,
} from '@mui/icons-material';
import { Stack, styled, TextField } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import DialogBase from '../../../../../../Components/dialog/dialog-base';
import { SimpleSkeleton } from '../../../../../../Components/SimpleSkeleton/SimpleSkeleton';
import { MultiSelectFilter } from '../../../../../../features/calendar/filters/view/MultiSelectFilter';
import { useAdUsps } from '../../../../../../Hooks/use-ad-usps';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useAdvertisementPlan } from '../use-advertisement-plan';
import {
  AdvertisementPlanContext,
  StepProps,
} from './grow-advertisement-flow-steps';

const OptionItem = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
  cursor: 'pointer',
});

const MessageItem = styled(Stack)({
  gap: 8,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderRadius: 16,
  backgroundColor: lightTheme.palette.contrast.surface2,
  border: `1px solid ${lightTheme.palette.neutral.n100}`,
});

export const availableGoals = ['bookings', 'awareness'] as const;
type Goal = (typeof availableGoals)[number];

export const GoalsStep: FC<StepProps> = ({ onNext }) => {
  const { t } = useTranslate('grow.advertisement.getStarted.goals');
  const { usps: uspsQuery } = useAdUsps();
  const [isAddMessageDialogOpen, setIsAddMessageDialogOpen] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const localized = useLocalizedStringFormatter();

  const {
    experiences: { data: experiences, isLoading: isLoadingExperiences },
  } = useExperience();

  const { adPlan } = useContext(AdvertisementPlanContext);
  const { updateGoals, updateUsps, updateExperiences } = useAdvertisementPlan();

  const experienceOptions = useMemo(() => {
    if (!experiences) return [];

    return experiences
      .filter(({ status }) => status === 'active')
      .map(({ id, headline }) => ({
        id,
        name: localized(headline),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [experiences, localized]);

  useEffect(() => {
    if (uspsQuery.data && !adPlan.usps.length) {
      updateUsps(uspsQuery.data);
    }
  }, [uspsQuery.data, updateUsps]);

  const toggleGoal = useCallback(
    (goal: Goal) => {
      const newGoals = adPlan.goals.includes(goal)
        ? adPlan.goals.filter((g) => g !== goal)
        : [...adPlan.goals, goal];
      updateGoals(newGoals);
    },
    [adPlan.goals, updateGoals]
  );

  const toggleUsp = useCallback(
    (usp: string) => {
      const newUsps = adPlan.usps?.includes(usp)
        ? adPlan.usps.filter((u) => u !== usp)
        : [...(adPlan.usps || []), usp];
      updateUsps(newUsps);
    },
    [adPlan.usps, updateUsps]
  );

  const handleAddMessage = useCallback(() => {
    if (newMessage.trim()) {
      const newUsps = [...(adPlan.usps || []), newMessage.trim()];
      updateUsps(newUsps);
      setNewMessage('');
      setIsAddMessageDialogOpen(false);
    }
  }, [newMessage, adPlan.usps, updateUsps]);

  const goalItems = useMemo(
    () =>
      availableGoals.map((goal) => (
        <OptionItem key={goal} onClick={() => toggleGoal(goal)}>
          {adPlan.goals.includes(goal) ? (
            <CheckBox color="success" />
          ) : (
            <CheckBoxOutlineBlank />
          )}
          <Text fontSize="small">{t(goal)}</Text>
        </OptionItem>
      )),
    [adPlan.goals, t, toggleGoal]
  );

  const messageItems = useMemo(() => {
    if (uspsQuery.isLoading) {
      return <SimpleSkeleton />;
    }

    return adPlan.usps?.map((usp) => (
      <MessageItem key={usp}>
        <Text fontSize="small">{usp}</Text>
        <CloseRounded
          fontSize="small"
          onClick={() => toggleUsp(usp)}
          sx={{ cursor: 'pointer' }}
        />
      </MessageItem>
    ));
  }, [adPlan.usps, toggleUsp, uspsQuery.isLoading]);

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Text variant="medium">{t('advertisingGoals')}</Text>
        <Stack
          gap={2}
          sx={{
            backgroundColor: lightTheme.palette.contrast.surface2,
            borderRadius: 2,
            padding: 2,
          }}
        >
          {goalItems}
        </Stack>
        <Stack
          gap={2}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            backgroundColor: lightTheme.palette.contrast.surface2,
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Text fontSize="small">{t('experiencesToPromote')}</Text>
          <MultiSelectFilter
            inputId="experiences-to-promote"
            label={''}
            options={experienceOptions}
            checkedIds={adPlan.experiences || []}
            onChange={(value) => {
              if (typeof value === 'function') {
                const newValue = value(adPlan.experiences || []);
                updateExperiences(newValue);
              } else {
                updateExperiences(value);
              }
            }}
            isLoading={isLoadingExperiences}
            sx={{
              maxWidth: 'none',
              width: 'fit-content',
              '& .MuiInputBase-root': {
                border: 'none',
                backgroundColor: 'transparent',
                width: 'fit-content',
                '& fieldset': { border: 'none' },
                '& .MuiSelect-icon': { display: 'none' },
              },
              '& .MuiSelect-select': {
                padding: '0px !important',
                minHeight: 'unset !important',
                color: lightTheme.palette.action.a300,
                textDecoration: 'underline',
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Text variant="medium">{t('keyMessages')}</Text>
        <Stack gap={1}>
          {messageItems}
          <MessageItem
            onClick={() => setIsAddMessageDialogOpen(true)}
            style={{
              cursor: 'pointer',
            }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Add />
              <Text fontSize="small">{t('addCustomMessage')}</Text>
            </Stack>
          </MessageItem>
        </Stack>
      </Stack>

      <Button variant="primary" size="large" onClick={onNext}>
        {t('nextStep', 'buttons')}
      </Button>

      <DialogBase
        title={t('addMessage.title')}
        description={t('addMessage.description')}
        open={isAddMessageDialogOpen}
        onClose={() => setIsAddMessageDialogOpen(false)}
        maxWidth="sm"
      >
        <Stack gap={4}>
          <TextField
            fullWidth
            placeholder={t('addMessage.placeholder')}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddMessage();
              }
            }}
          />
          <Button
            variant="primary"
            size="large"
            onClick={handleAddMessage}
            disabled={!newMessage.trim()}
          >
            {t('addMessage.button')}
          </Button>
        </Stack>
      </DialogBase>
    </Stack>
  );
};
