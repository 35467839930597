import { ChatOutlined, Close } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

const ICON_BUTTON_SIZE_PX = 24;
const ICON_SIZE_PX = 16;

interface LanguageAlertProps {
  onClick: () => void;
}

export function LanguageNotification({ onClick }: LanguageAlertProps) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        backgroundColor: lightTheme.palette.action.a100,
        padding: 2,
        borderRadius: 1.5,
        gap: 2,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <ChatOutlined
          sx={{
            color: lightTheme.palette.contrast.black,
            width: ICON_BUTTON_SIZE_PX,
            height: ICON_BUTTON_SIZE_PX,
          }}
        />
        <Stack>
          <Text
            color={lightTheme.palette.contrast.black}
            variant="medium"
            fontSize="small"
          >
            Auto-translate is on
          </Text>
          <Text
            color={lightTheme.palette.neutral.n400}
            variant="normal"
            fontSize="small"
          >
            We automatically translate your text. You can check and adjust the
            quality of the translation anytime from the language selector.
          </Text>
        </Stack>
      </Stack>
      <IconButton
        onClick={onClick}
        sx={{
          width: ICON_BUTTON_SIZE_PX,
          height: ICON_BUTTON_SIZE_PX,
        }}
      >
        <Close
          sx={{
            width: ICON_SIZE_PX,
            height: ICON_SIZE_PX,
          }}
        />
      </IconButton>
    </Stack>
  );
}
