import { BookingAvailabilityManager } from '@understory-io/availability';

import { UseProductsReturn } from '../../Hooks/useProducts';

export interface CalculateMaxBookingSizeParams {
  experienceMaxCount: number | false | null | undefined;
  currentGuestCount: number;
  eventMaxCapacity?: number;
}

export const calculateMaxBookingSize = ({
  experienceMaxCount,
  currentGuestCount,
  eventMaxCapacity = 0,
}: CalculateMaxBookingSizeParams): number => {
  // If no experience max count or it's falsy, only use event capacity
  if (!experienceMaxCount) {
    return currentGuestCount + eventMaxCapacity;
  }

  // Consider both experience and event limits
  return Math.min(
    currentGuestCount + eventMaxCapacity,
    Math.max(experienceMaxCount, currentGuestCount)
  );
};

interface ResourceManagement {
  resourceAvailability: Record<string, number>;
  ticketsRequiringResources: Record<string, Record<string, number>>;
}

interface BookingItems {
  [key: string]: number;
}

export const adjustResourceAvailability = (
  currentBookingItems: BookingItems,
  eventResourceManagement?: {
    resourceAvailability?: Record<string, number>;
    ticketsRequiringResources?: Record<string, Record<string, number>>;
  }
): ResourceManagement => {
  const adjustedResouceAvailability = {
    ...eventResourceManagement?.resourceAvailability,
  };

  for (const [ticketId, count] of Object.entries(currentBookingItems)) {
    const resourceRequirements =
      eventResourceManagement?.ticketsRequiringResources?.[ticketId] ?? {};

    for (const [resourceId, resourceCount] of Object.entries(
      resourceRequirements
    )) {
      adjustedResouceAvailability[resourceId] ||= 0;
      adjustedResouceAvailability[resourceId] += resourceCount * count;
    }
  }

  return {
    resourceAvailability: adjustedResouceAvailability,
    ticketsRequiringResources:
      eventResourceManagement?.ticketsRequiringResources ?? {},
  };
};

export interface UpdateGuestsParams {
  id: string;
  direction: 'up' | 'down';
  step?: number;
  prevGuests: Record<string, number>;
  bookingManager: BookingAvailabilityManager;
}
export const updateGuests = ({
  id,
  direction,
  step = 1,
  prevGuests,
  bookingManager,
}: UpdateGuestsParams): Record<string, number> | undefined => {
  if (direction === 'up' && !bookingManager.canAddTickets({ [id]: step })) {
    return undefined;
  }

  const currentValue = prevGuests[id] ?? 0;
  const newValue = currentValue + (direction === 'up' ? step : -step);

  return {
    ...prevGuests,
    [id]: newValue,
  };
};

export const calculateBookingTotal = (
  guests: Record<string, number>,
  variants: UseProductsReturn['variants']
): number => {
  console.log('guests', guests);
  console.log('variants', variants);
  return Object.entries(guests).reduce((total, [id, quantity]) => {
    const item = variants.find(
      (variant) =>
        variant.id === id || variant.addons.find((addon) => addon.id === id)
    );

    if (!item) {
      return total;
    }

    let itemPrice;
    if (item.id === id) {
      // It's a variant
      itemPrice = item.priceBreakdown.vatInclusivePriceCents;
    } else {
      // It's an addon
      const addon = item.addons.find((addon) => addon.id === id);
      itemPrice = addon?.priceBreakdown.vatInclusivePriceCents ?? 0;
    }

    return total + itemPrice * quantity;
  }, 0);
};
