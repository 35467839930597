import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC, PropsWithChildren } from 'react';

import { Badge } from '../../../../Components/badge/badge';

export const ElementList = ({ children }: PropsWithChildren) => {
  return (
    <Stack sx={{ gap: { xs: 1, sm: 1.5 }, marginTop: { xs: 1, sm: 0 } }}>
      {children}
    </Stack>
  );
};

const DEFAULT_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.contrast.white,
  borderColor: 'transparent',
  color: lightTheme.palette.contrast.black,
};

const ERROR_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.error.e100,
  borderColor: lightTheme.palette.error.e300,
  color: lightTheme.palette.error.e300,
};

export type ElementItemProps = PropsWithChildren<{
  onEdit: () => void;
  onDelete: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  hasError?: boolean;
  badgeValue?: string | null;
  hideDelete?: boolean;
}>;

const ICON_SIZE_PX = 32;

export const ElementItem = ({
  onEdit,
  onDelete,
  Icon,
  hasError,
  children,
  badgeValue,
  hideDelete,
}: ElementItemProps) => {
  return (
    <ButtonBase
      disableRipple
      sx={{
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        boxShadow: lightTheme.shadows.small,
        paddingY: 1.25,
        paddingLeft: 1,
        paddingRight: { xs: 1, sm: 2 },
        borderRadius: 1.25,
        borderWidth: 1,
        borderStyle: 'solid',
        cursor: 'pointer',
        transition: '0.1s ease-in-out',
        ':hover:not(:has(.delete:hover))': {
          backgroundColor: lightTheme.palette.contrast.surface1,
        },
        ':focus-visible': {
          outline: `1px solid ${lightTheme.palette.neutral.n500}`,
        },
        ...(hasError ? ERROR_ITEM_STYLE : DEFAULT_ITEM_STYLE),
      }}
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
    >
      <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Stack
          sx={{
            gap: 1,
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '100%',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: lightTheme.palette.action.a100,
              height: ICON_SIZE_PX,
              width: ICON_SIZE_PX,
              minWidth: ICON_SIZE_PX,
              borderRadius: '50%',
              color: lightTheme.palette.action.a300,
            }}
          >
            <Icon fontSize="small" />
          </Stack>
          <Stack sx={{ minWidth: 0, flexGrow: 1 }}>{children}</Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          gap: { xs: 2, md: 4 },
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {badgeValue && (
          <Badge appearance="action" size="small">
            {badgeValue}
          </Badge>
        )}
        {!hideDelete && (
          <IconButton
            className="delete"
            sx={{
              color: lightTheme.palette.neutral.n300,
              height: ICON_SIZE_PX,
              width: ICON_SIZE_PX,
              minWidth: ICON_SIZE_PX,
              padding: 0.5,
              transition: 'color 0.1s',
              ':hover': {
                color: lightTheme.palette.neutral.n500,
                backgroundColor: lightTheme.palette.contrast.surface1,
              },
              ':focus-visible': {
                outline: `1px solid ${lightTheme.palette.neutral.n500}`,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </ButtonBase>
  );
};

type ElementItemContentProps = {
  value: string;
  description?: string;
  hasError?: boolean;
  errorPlaceholder?: string;
};
export const ElementItemContent: FC<ElementItemContentProps> = ({
  value,
  description,
  hasError,
  errorPlaceholder,
}) => {
  return (
    <>
      <EllipsedText fontSize="small" variant="medium" color="inherit">
        {hasError && errorPlaceholder ? errorPlaceholder : value}
      </EllipsedText>
      {description && (
        <Box sx={{ display: 'block' }}>
          <EllipsedText
            variant="medium"
            fontSize="small"
            color={lightTheme.palette.neutral.n300}
          >
            {description}
          </EllipsedText>
        </Box>
      )}
    </>
  );
};

export const EllipsedText = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
