import { LightbulbOutlined, OpenInFullRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { motion } from 'framer-motion';

import { ampli } from '../../Ampli';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';

interface ShowTipButtonProps {
  onClick: () => void;
  location: string;
}

export const ShowTipButton = ({ onClick, location }: ShowTipButtonProps) => {
  const { t } = useTranslate('tips');
  const { isSm } = useResponsive();

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1],
      }}
      sx={{
        position: 'fixed',
        right: isSm ? 24 : 80,
        bottom: isSm ? 80 : 24,
        zIndex: 80,
      }}
    >
      <Button
        variant="secondary"
        size="small"
        onClick={() => {
          onClick();
          ampli.showTipButtonClicked({
            tip: location,
          });
        }}
        leftIcon={
          <LightbulbOutlined
            fontSize="small"
            sx={{
              backgroundColor: '#E9F7BA',
              borderRadius: '50%',
              width: 24,
              height: 24,
              padding: 0.5,
              marginLeft: -1,
              color: lightTheme.palette.contrast.black,
            }}
          />
        }
        rightIcon={<OpenInFullRounded sx={{ fontSize: 14, ml: 0.5 }} />}
        style={{
          boxShadow: lightTheme.shadows.large,
          borderRadius: '50px',
          padding: '20px',
        }}
      >
        {t('showTip')}
      </Button>
    </Box>
  );
};
