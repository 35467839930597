import './styles.css';

import { styled } from '@mui/material';
import {
  LinkBubbleMenu,
  RichTextEditor,
  RichTextEditorProps,
  RichTextEditorRef,
} from 'mui-tiptap';
import { forwardRef, PropsWithChildren } from 'react';

import useResponsive from '../../Hooks/layout/useResponsive';
import CustomBubbleMenu from './components/custom-bubble-menu';
import EditorDesktopControls from './editor-desktop-controls';
import useExtensions from './use-extensions';

type MarkdownEditorProps = {
  placeholder?: string;
  value?: string;
  onChange?: (e: string) => void;
  preview?: boolean;
  autoFocus?: boolean;
};

const MarkdownEditor = forwardRef<
  RichTextEditorRef,
  PropsWithChildren<MarkdownEditorProps>
>(
  (
    {
      children,
      value,
      placeholder,
      onChange,
      preview = false,
      autoFocus = false,
    },
    ref
  ) => {
    const { isSm } = useResponsive();
    const extensions = useExtensions({
      placeholder,
    });

    return (
      <RichTextEditorStyled
        ref={ref}
        extensions={extensions}
        injectCSS={false}
        editable={!preview}
        enablePasteRules={extensions}
        renderControls={() =>
          !isSm && <EditorDesktopControls>{children}</EditorDesktopControls>
        }
        autofocus={autoFocus}
        RichTextFieldProps={{
          variant: 'standard',
          disabled: preview,
          MenuBarProps: {
            disableSticky: true,
            hide: preview,
          },
        }}
        onUpdate={(e) => {
          const markdownString =
            e.editor.storage.markdown.getMarkdown() as string;
          onChange && onChange(markdownString);
        }}
        content={value}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            {isSm && (
              <>
                <CustomBubbleMenu>{children}</CustomBubbleMenu>
              </>
            )}
          </>
        )}
      </RichTextEditorStyled>
    );
  }
);

MarkdownEditor.displayName = 'MarkdownEditor';

const RichTextEditorStyled = styled(RichTextEditor)<RichTextEditorProps>({
  '.MuiTiptap-RichTextField-content': {
    padding: 0,
    'p:only-child, &.MuiTiptap-RichTextContent-editable': {
      minHeight: 100,
    },
    'li p:only-child': {
      minHeight: 'unset',
    },
    'p + p': {
      marginTop: 8,
    },
  },
});

export default MarkdownEditor;
