import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { FullPageFormSettingSingleItem } from '../../../../../Components/full-page/form-components/setting/full-page-form-setting-single-item';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SecondsToTimeString } from '../../seconds-to-time-string';
import { useUpdateResourceTypeLinks } from '../update-resource-type-context';
import { UpdateResourceTypeInputs } from '../update-resource-type-inputs';

export const UpdateResourceTypeRecoverTimeSetting: FC = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const {
    watch,
    formState: { errors },
  } = useFormContext<UpdateResourceTypeInputs>();

  const recoverTimeInSeconds = watch('recoverTimeInSeconds');

  const links = useUpdateResourceTypeLinks();

  return (
    <FullPageFormSettingSingleItem
      title={t('followUpTime')}
      value={<SecondsToTimeString seconds={recoverTimeInSeconds ?? 0} />}
      to={links.recoverTime}
      hasError={!!errors.recoverTimeInSeconds}
    />
  );
};
