import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { lightTheme, Text } from '@understory-io/pixel';
import { useCallback, useMemo, useState } from 'react';

import * as api from '../../../../../Api';
import {
  domainAvailabilityQuery,
  domainQueryKey,
} from '../../../../../Api/queries';
import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useDebounce } from '../../../../../Hooks/use-debounce';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { getQueryClient } from '../../../../../query-client';

const ICON_SIZE_PX = 18;

export function DomainWidget() {
  const { t } = useTranslate('settings.company.domain');
  const [showDialog, setShowDialog] = useState(false);
  const [isCreatingDomain, setIsCreatingDomain] = useState(false);

  const {
    company: { data: company },
  } = useProfile();

  const { data: domain, isLoading: isDomainLoading } = useQuery({
    queryKey: domainQueryKey(company?.id),
    queryFn: () => api.getCompanyDomain(company!.id!),
    enabled: !!company?.id,
  });

  const prefix = useMemo(() => {
    return domain?.type !== 'custom' ? domain?.domain.split('.')[0] : undefined;
  }, [domain]);

  const [value, setValue] = useState(prefix);
  const debouncedValue = useDebounce(value, 300);

  const { data: domainAvailability, isLoading: isDomainAvailabilityLoading } =
    useQuery(domainAvailabilityQuery(debouncedValue ?? ''));

  const handleSubmit = useCallback(async () => {
    if (!debouncedValue) return;

    setIsCreatingDomain(true);
    try {
      await api.createDomain({ domain: debouncedValue, type: 'subdomain' });
      getQueryClient().invalidateQueries({
        queryKey: domainQueryKey(company?.id),
      });
      setShowDialog(false);
    } catch (error) {
      captureException(error);
    } finally {
      setIsCreatingDomain(false);
    }
  }, [debouncedValue, company?.id]);

  const isEndpointsLoading = useMemo(
    () => isDomainLoading || isDomainAvailabilityLoading || isCreatingDomain,
    [isDomainLoading, isDomainAvailabilityLoading, isCreatingDomain]
  );

  return (
    <Stack gap={1}>
      <Text variant="medium" fontSize="small">
        {t('title')}
      </Text>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          boxShadow: 'none',
          border: `1px solid ${lightTheme.palette.neutral.n100}`,
          paddingY: 1.5,
        }}
        component={Card}
        onClick={() => setShowDialog(true)}
      >
        <Text variant="medium" fontSize="small">
          {domain?.domain ?? <Skeleton sx={{ width: 184 }} />}
        </Text>
        <IconButton size="small">
          <EditOutlined
            sx={{
              width: ICON_SIZE_PX,
              height: ICON_SIZE_PX,
            }}
          />
        </IconButton>
      </Stack>
      <DialogBase
        title={t('title')}
        description={t('description')}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        actionsInHeader={false}
        primaryAction={{
          loading: isEndpointsLoading,
          disabled: !domainAvailability?.available || !prefix,
          label: isDomainAvailabilityLoading
            ? t('checkingDomainAvailability')
            : t('save', 'buttons'),
          variant: 'primary',
          onClick: handleSubmit,
        }}
        secondaryAction={{
          label: t('cancel', 'utils.generic'),
          variant: 'secondary',
          onClick: () => setShowDialog(false),
        }}
        fullHeight={false}
        showSecondaryActionDesktop={true}
      >
        <TextField
          fullWidth
          error={!domainAvailability?.available}
          InputLabelProps={{ shrink: true }}
          hiddenLabel
          disabled={!prefix}
          defaultValue={prefix ?? domain?.domain}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: prefix && (
              <InputAdornment sx={{ opacity: 0.5 }} position="end">
                .understory.io
              </InputAdornment>
            ),
          }}
        />
        {domain?.type === 'custom' ? (
          <Stack flexDirection="row" gap={1}>
            <Box
              component={Text}
              color={lightTheme.palette.neutral.n300}
              variant="normal"
              fontSize="xsmall"
              sx={{
                display: 'flex',
                gap: 0.5,
              }}
            >
              {t('customDomain', 'utils.errors')}
              <Box
                component={'a'}
                sx={{
                  color: lightTheme.palette.action.a300,
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                  textDecoration: 'underline',
                }}
                target="_blank"
                href="https://help.understory.io/en/articles/10353415-custom-storefront-domain"
              >
                {t('helpCenter', 'utils.generic')}
              </Box>
            </Box>
          </Stack>
        ) : (
          <Text
            color={
              domainAvailability?.available
                ? lightTheme.palette.success.s400
                : lightTheme.palette.error.e300
            }
            variant="normal"
            fontSize="xsmall"
          >
            {domainAvailability?.available
              ? t('domainAvailable', 'utils.generic')
              : t('domainUnavailable', 'utils.errors')}
          </Text>
        )}
      </DialogBase>
    </Stack>
  );
}
