import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const backofficeLanguages = ['da', 'en', 'no', 'sv', 'de', 'en-US'] as const;
const storefrontLanguages = [...backofficeLanguages, 'nl'] as const;

export const supportedBackofficeLanguages = [...backofficeLanguages].sort();
export const supportedStorefrontLanguages = [...storefrontLanguages].sort();

export type BackofficeLanguage = (typeof supportedBackofficeLanguages)[number];
export type StorefrontLanguage = (typeof supportedStorefrontLanguages)[number];

export const isBackofficeLanguage = (
  value: string
): value is BackofficeLanguage =>
  supportedBackofficeLanguages.includes(value as BackofficeLanguage);

export const isStorefrontLanguage = (
  value: string
): value is StorefrontLanguage =>
  supportedStorefrontLanguages.includes(value as StorefrontLanguage);

export const BACKOFFICE_FALLBACK_LOCALE_KEY = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: BACKOFFICE_FALLBACK_LOCALE_KEY,
    supportedLngs: supportedBackofficeLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export function t(text: string, options?: TOptions): string {
  return i18n.t(text, options);
}
