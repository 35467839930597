import { Grid, Skeleton } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { ampli } from '../../../Ampli';
import { useTranslate } from '../../../Hooks/useTranslate';
import { Suggestion } from './config';
import {
  SkeletonCard,
  SuggestionCard,
  SuggestionContent,
  SuggestionDuration,
  SuggestionImage,
} from './styles';

type DashboardSuggestionCardProps = {
  suggestion: Suggestion & { onClick: () => void };
};

export const DashboardSuggestionCard = ({
  suggestion,
}: DashboardSuggestionCardProps) => {
  const { t } = useTranslate('dashboard.suggestions.nextSteps');

  const handleClick = () => {
    suggestion.onClick();
    ampli.dashboardSuggestionsNextStepClicked({
      step: suggestion.id,
    });
  };

  return (
    <Grid key={suggestion.id} item xs={12} sm={4} md={4} onClick={handleClick}>
      <SuggestionCard>
        <SuggestionImage
          sx={{
            backgroundImage: `url('/graphics/suggestions/${suggestion.id}.png')`,
          }}
        />

        <SuggestionContent>
          <Text variant="medium" fontSize="small">
            {t(suggestion.id)}
          </Text>
          <SuggestionDuration>
            <Text variant="medium" fontSize="xsmall">
              {suggestion.duration} {t('abbreviatedMinute', 'utils.generic')}
            </Text>
          </SuggestionDuration>
        </SuggestionContent>
      </SuggestionCard>
    </Grid>
  );
};

export const DashboardSuggestionSkeleton = () => (
  <SkeletonCard>
    <Skeleton
      variant="rectangular"
      width={48}
      height={48}
      sx={{ borderRadius: 2 }}
    />
    <SuggestionContent>
      <Skeleton variant="text" width="80%" height={48} />
      <Skeleton variant="text" width="20%" height={20} />
    </SuggestionContent>
  </SkeletonCard>
);
