import { Box } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { AnimatePresence, motion } from 'framer-motion';

interface TipContainerProps {
  children: React.ReactNode;
  show: boolean;
  animate?: boolean;
}

export const TipContainer = ({
  children,
  show,
  animate = true,
}: TipContainerProps) => {
  const container = (
    <Box
      component={motion.div}
      initial={animate ? { x: 'calc(100% + 24px)' } : undefined}
      animate={{ x: 0 }}
      exit={animate ? { x: 'calc(100% + 24px)' } : undefined}
      transition={{
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
      }}
      sx={{
        position: 'fixed',
        right: 24,
        top: 24,
        bottom: 24,
        width: 400,
        backgroundColor: lightTheme.palette.contrast.white,
        boxShadow: lightTheme.shadows.large,
        borderLeft: `1px solid ${lightTheme.palette.neutral.n100}`,
        borderRadius: '12px',
        zIndex: 80,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        p: 4,
      }}
    >
      {children}
    </Box>
  );

  if (!animate) {
    return show ? container : null;
  }

  return <AnimatePresence mode="wait">{show && container}</AnimatePresence>;
};
