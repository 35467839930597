import { ArrowBackOutlined } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { mergeSx } from '../../Utils/merge-sx';

type FullPageHeaderBackButtonProps = {
  /** Used if history is empty */
  fallbackUrl?: string;
} & IconButtonProps;

const ICON_SIZE_PX = { xs: 24, md: 32 };

export const FullPageHeaderBackButton = forwardRef<
  HTMLButtonElement,
  FullPageHeaderBackButtonProps
>(({ fallbackUrl, sx, ...rest }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (fallbackUrl && location.key === 'default') {
      navigate(fallbackUrl, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  }, [fallbackUrl, location.key, navigate]);

  return (
    <IconButton
      ref={ref}
      size="small"
      onClick={handleBack}
      sx={mergeSx(
        {
          width: ICON_SIZE_PX,
          height: ICON_SIZE_PX,
          ':focus-visible': { outline: 1 },
        },
        sx
      )}
      {...rest}
    >
      <ArrowBackOutlined />
    </IconButton>
  );
});

FullPageHeaderBackButton.displayName = 'FullPageHeaderBackButton';
