import { BookingStatus } from '@understory-io/utils-types';
import { useMemo } from 'react';

import { useGetBookingsForEvent } from '../../../Hooks/data/useBookings';
import { useLanguages } from '../../../Hooks/locales/use-languages';

const CANCELLABLE_BOOKING_STATUSES: BookingStatus[] = [
  'active',
  'unpaid',
  'checked-in',
];

export const useCancelEventLanguages = (eventId: string) => {
  const {
    bookingsForEvent: { data: bookingsForEvent, isLoading: isBookingsLoading },
  } = useGetBookingsForEvent(eventId);

  const languages = Array.from(
    new Set(
      (bookingsForEvent ?? [])
        ?.filter(
          ({ language, status }) =>
            Boolean(language) && CANCELLABLE_BOOKING_STATUSES.includes(status)
        )
        .map(({ language }) => language as string)
    )
  );

  const { storefrontLanguages: supportedLanguages, languageOption } =
    useLanguages();

  const selectedLanguages = useMemo(
    () => supportedLanguages.map(languageOption),
    [languageOption, supportedLanguages]
  );

  return {
    languages,
    isBookingsLoading,
    selectedLanguages,
  };
};
