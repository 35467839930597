import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { AddOutlined, CheckOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import isEqual from 'lodash.isequal';
import { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Badge } from '../../../../Components/badge/badge';
import { ContextMenu } from '../../../../Components/context-menu/context-menu';
import { ContextMenuItemProps } from '../../../../Components/context-menu/context-menu-item';
import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { StorefrontLanguage } from '../../../../i18n/config';
import { InfoDialog } from '../../../../Modals/InfoDialog';
import {
  trackStorefrontLanguagesRemoveLanguage,
  trackStorefrontLanguagesSetPrimaryLanguage,
} from '../../../../tracking/storefront/languages-events';
import { StorefrontLanguagesFormData } from '../storefront-language';
import {
  AddLanguageDialog,
  AddLanguageDialogProps,
} from './add-language-dialog';
import { useTranslationStatus } from './use-translation-status';

export const LanguageList = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<StorefrontLanguagesFormData>();

  const { field: defaultLanguage } = useController({
    name: 'defaultLanguage',
    control,
    rules: { required: t('utils.errors.required') },
  });
  const { field: languages } = useController({
    name: 'languages',
    control,
    rules: {
      validate: (value) =>
        value.length > 0 ? true : t('storefront.languages.add.required'),
    },
  });

  const { languageOption, storefrontLanguages } = useLanguages();

  const missingTranslationsForLanguage = useTranslationStatus();

  const showMissingTranslationsDialog = useCallback(
    (languages: string[]) => {
      const isMissingTranslations = languages.some(
        missingTranslationsForLanguage
      );

      if (!isMissingTranslations) {
        return;
      }

      const contentTranslationsPromptShown = sessionStorage.getItem(
        'contentTranslationsPromptShown'
      );

      if (
        contentTranslationsPromptShown &&
        contentTranslationsPromptShown.toLowerCase() === 'true'
      ) {
        return;
      }

      NiceModal.show(InfoDialog, {
        info: [
          {
            title: '',
            content: t('storefront.languages.missingTranslationsDialog'),
          },
        ],
      }).then(() => {
        sessionStorage.setItem('contentTranslationsPromptShown', 'true');
      });
    },
    [missingTranslationsForLanguage, t]
  );

  const canAddLanguages = useMemo(
    () =>
      storefrontLanguages.filter((locale) => !languages.value.includes(locale))
        .length > 0,
    [languages.value, storefrontLanguages]
  );

  return (
    <Stack sx={{ gap: 2, alignItems: 'flex-start' }}>
      <Stack sx={{ gap: 1, width: '100%' }}>
        {languages.value
          .sort((language) => (language === defaultLanguage.value ? -1 : 0))
          .map((language) => {
            const { locale, label, regionLabel } = languageOption(
              language as StorefrontLanguage
            );

            const isDefault = locale === defaultLanguage.value;

            return (
              <LanguageItem
                key={locale}
                label={label}
                regionLabel={regionLabel}
                isDefault={isDefault}
                onSetDefaultLanguage={() => {
                  defaultLanguage.onChange(locale);
                  trackStorefrontLanguagesSetPrimaryLanguage(locale);
                }}
                isRemovable={languages.value.length > 1}
                onRemoveLanguage={() => {
                  const newLanguages = languages.value.filter(
                    (addedLocale) => addedLocale !== locale
                  );

                  languages.onChange(newLanguages);
                  if (isDefault) {
                    defaultLanguage.onChange(newLanguages[0]);
                  }
                  trackStorefrontLanguagesRemoveLanguage(locale);
                }}
              />
            );
          })}
      </Stack>
      {canAddLanguages && (
        <Button
          type="button"
          variant="secondary"
          size="medium"
          leftIcon={<AddOutlined />}
          onClick={async () => {
            const newLanguages = await NiceModal.show<
              StorefrontLanguage[],
              AddLanguageDialogProps & NiceModalHocProps,
              AddLanguageDialogProps
            >(AddLanguageDialog, {
              selectedLanguages: languages.value as StorefrontLanguage[],
            });
            if (newLanguages && newLanguages.length) {
              if (!isEqual(newLanguages, languages.value)) {
                showMissingTranslationsDialog(newLanguages);
              }
              languages.onChange(newLanguages);

              if (
                newLanguages.length === 1 ||
                !newLanguages.includes(
                  defaultLanguage.value as StorefrontLanguage
                )
              ) {
                defaultLanguage.onChange(newLanguages[0]);
              }
            }
          }}
        >
          {t('storefront.languages.addLanguage')}
        </Button>
      )}
    </Stack>
  );
};

const LanguageItem = ({
  label,
  regionLabel,
  isDefault,
  onSetDefaultLanguage,
  isRemovable,
  onRemoveLanguage,
}: {
  label: string;
  regionLabel?: string;
  isDefault: boolean;
  onSetDefaultLanguage: () => void;
  isRemovable: boolean;
  onRemoveLanguage: () => void;
}) => {
  const { t } = useTranslation();
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const contextMenuOptions = useMemo(() => {
    const options: ContextMenuItemProps[] = [];

    if (!isDefault) {
      options.push({
        label: t('storefront.languages.setDefaultLanguage'),
        onClick: onSetDefaultLanguage,
      });
    }

    options.push({
      label: t('storefront.languages.removeLanguage'),
      onClick: onRemoveLanguage,
      textColor: lightTheme.palette.error.e300,
      disabled: !isRemovable,
    });

    return options;
  }, [isDefault, isRemovable, onRemoveLanguage, onSetDefaultLanguage, t]);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 3,
        paddingY: { xs: 1, sm: 2 },
        paddingX: { xs: 2, sm: 3 },
        backgroundColor: lightTheme.palette.contrast.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: lightTheme.palette.neutral.n100,
        borderRadius: 2,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1.5,
          maxWidth: '40%',
          '& > *:last-child': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      >
        <Text variant="medium">{label}</Text>
        {regionLabel && (
          <Text color={lightTheme.palette.neutral.n300}>{regionLabel}</Text>
        )}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 1.5 }}>
        {isDefault && (
          <Badge appearance="default" size="small">
            <CheckOutlined sx={{ height: 16, width: 16 }} />
            <Text variant="medium" fontSize="xsmall">
              {t('storefront.languages.defaultLanguage')}
            </Text>
          </Badge>
        )}
        <ContextMenu
          open={contextMenuOpen}
          onOpenChange={setContextMenuOpen}
          options={contextMenuOptions}
        />
      </Stack>
    </Stack>
  );
};
