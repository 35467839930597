import { FC } from 'react';

import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { ResourceManagementEmptyList } from '../list/resource-management-empty-list';

export const ResourceTypesListEmpty: FC = () => {
  const { t } = useTranslate('resourceManagement.overview');

  return (
    <ResourceManagementEmptyList
      mt={12}
      title={t('list.empty.title')}
      description={t('list.empty.description')}
      action={
        <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
          <RouterLinkButton
            variant="secondary"
            size="medium"
            href={routes.resourceManagement.create.index}
          >
            {t('newResourceType')}
          </RouterLinkButton>
        </ShowForScope>
      }
    />
  );
};
