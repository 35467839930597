import { lightTheme, Text } from '@understory-io/pixel';
import { format } from 'date-fns';

import { useAdPlans } from '../../../../Hooks/use-ad-plans';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const GrowAdvertisementDashboardFooter = () => {
  const { t } = useTranslate('dashboard');
  const {
    metrics: { data: metrics },
  } = useAdPlans();

  if (!metrics?.lastUpdatedAt) {
    return null;
  }

  return (
    <Text fontSize={'xsmall'} color={lightTheme.palette.neutral.n300}>
      {t('metadata.lastUpdated')}:{' '}
      {format(new Date(metrics?.lastUpdatedAt), 'PPp')}
    </Text>
  );
};
