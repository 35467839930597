import { Box } from '@mui/material';
import { lightTheme, SpinnerIcon } from '@understory-io/pixel';

import { ContentCard } from './content-card';

export const LoadingKeys = () => (
  <ContentCard>
    <Box height={32} width={32}>
      <SpinnerIcon theme={lightTheme} />
    </Box>
  </ContentCard>
);
