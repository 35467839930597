import { differenceInMonths } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAdPlans } from '../../../Hooks/use-ad-plans';
import { useProfile } from '../../../Hooks/useProfile';
import { ProductAnnouncementBanner } from '../../product-announcement-banner/product-announcement-banner';

export const GrowAdvertisingAnnouncementBanner = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  const { menuItemProductAnnouncementBanner, featureGrowAdvertisement } =
    useFlags();

  const {
    adPlans: { data: adPlans, isLoading: isAdPlansLoading },
  } = useAdPlans();

  const {
    company: { data: company, isLoading: isCompanyLoading },
  } = useProfile();

  const showBanner =
    (!adPlans ||
      adPlans.length === 0 ||
      adPlans.every((plan) => plan.state === 'draft')) &&
    differenceInMonths(
      new Date(),
      new Date(company?.metadata?.createdAt ?? '')
    ) >= 2 &&
    featureGrowAdvertisement &&
    menuItemProductAnnouncementBanner &&
    !isAdPlansLoading &&
    !isCompanyLoading;

  if (!showBanner) {
    return null;
  }

  return (
    <ProductAnnouncementBanner
      id="growAdvertising"
      url="/growth/advertisement"
      onClick={onClick}
    />
  );
};
