import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const GrowAdvertisementDashboardBanner = () => {
  const { t } = useTranslate('grow.advertisement.dashboard');
  const { isSm } = useResponsive();

  return (
    <Stack
      sx={{
        padding: isSm ? 2 : 4,
        backgroundColor: '#cfe8ff',
        borderRadius: 1,
        boxShadow: lightTheme.shadows.small,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={1}>
          <Text
            fontSize={isSm ? 'h6' : 'h5'}
            variant="medium"
            style={{
              fontFamily: 'National2Condensed, Inter, Helvetica, Arial, serif',
              lineHeight: '100%',
              textTransform: 'uppercase',
            }}
          >
            {t('learningTitle')}
          </Text>
          <Text
            fontSize="xsmall"
            color={lightTheme.palette.neutral.n400}
            style={{ lineHeight: '1.2', maxWidth: isSm ? '100%' : '80%' }}
          >
            {t('learningDescription')}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
