import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { Image } from '@understory-io/utils-types';
import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import { useUploader } from '../../Hooks/use-uploader';
import { TFileTypes, TModelTypes } from '../../types';

export const ImageUpload = ({
  id,
  collection,
  type,
  onUploaded,
  variant = 'square',
  label,
  description,
  children,
  handleDelete,
  badgeLabel,
}: {
  id: string;
  collection: TModelTypes;
  type: TFileTypes;
  onUploaded?: (image: Image) => void;
  variant?: 'square' | 'circle';
  label?: string;
  description?: string;
  children?: ReactNode;
  handleDelete?: () => void;
  badgeLabel?: string;
}) => {
  const { onDrop, filestoBeUploaded: filesBeingUploaded } = useUploader({
    id,
    modelType: collection,
    fileType: type,
    onUploaded: (uploadedFiles) => {
      const media = uploadedFiles[0];
      if (media.type !== 'image') return;
      onUploaded?.(media);
    },
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.webp', '.gif'] },
  });

  const isCircle = variant === 'circle';

  if (children) {
    return (
      <Stack
        sx={{
          width: '100%',
          position: 'relative',
          ':hover': {
            '#delete, #add': {
              display: 'flex',
            },
          },
          '#delete, #add': {
            display: { md: 'none' },
          },
        }}
      >
        {children}
        {handleDelete && (
          <Button
            id="delete"
            type="button"
            size="small"
            variant="secondary"
            style={{
              zIndex: 50,
              position: 'absolute',
              top: 8,
              right: 8,
              aspectRatio: '1 / 1',
            }}
            onClick={() => handleDelete()}
          >
            <DeleteOutlined />
          </Button>
        )}
        <Stack
          sx={{
            position: 'absolute',
            inset: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            id="add"
            size="small"
            variant="secondary"
            type="button"
            style={{
              aspectRatio: '1 / 1',
            }}
            onClick={open}
          >
            <AddOutlined />
          </Button>
        </Stack>
        {badgeLabel && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 12,
              left: 12,
              borderRadius: 1.5,
              paddingX: 1,
              paddingY: 0.5,
              backgroundColor: lightTheme.palette.contrast.surface1,
            }}
          >
            <Text
              color={lightTheme.palette.neutral.n400}
              fontWeight="medium"
              fontSize="xsmall"
            >
              {badgeLabel}
            </Text>
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack {...getRootProps()} sx={{ width: '100%' }}>
      <input {...getInputProps()} />

      <Stack
        sx={{
          padding: 3,
          borderRadius: isCircle ? '100%' : 2,
          aspectRatio: isCircle ? '1 / 1' : undefined,
          border: '1px dashed',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          height: '100%',
          borderColor: lightTheme.palette.neutral.n200,
          backgroundColor: lightTheme.palette.contrast.surface1,
          ':hover': {
            backgroundColor: lightTheme.palette.neutral.n100,
          },
        }}
      >
        <Stack
          sx={{
            opacity: filesBeingUploaded.length ? 0 : 1,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: lightTheme.palette.neutral.n200,
              borderRadius: 1,
              marginBottom: 0.5,
              width: 32,
              height: 32,
            }}
          >
            <AddOutlined />
          </Stack>
          {label && (
            <Text variant="medium" fontSize="small">
              {label}
            </Text>
          )}
          {description && <Text fontSize="xsmall">{description}</Text>}
        </Stack>
        {!!filesBeingUploaded.length && (
          <CircularProgress sx={{ position: 'absolute' }} />
        )}
      </Stack>
    </Stack>
  );
};
