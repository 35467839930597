import { InfoOutlined } from '@mui/icons-material';
import { Stack, StackProps, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC } from 'react';

type Props = {
  note: string;
} & StackProps;

export const NoteBox: FC<Props> = ({ note, ...rest }) => {
  return (
    <StyledInfoBox direction="row" gap={1} alignItems="center" {...rest}>
      <InfoOutlined
        sx={{ width: 24, color: lightTheme.palette.neutral.n400 }}
      />
      <Text color={lightTheme.palette.action.a300} fontSize="small">
        {note}
      </Text>
    </StyledInfoBox>
  );
};

const StyledInfoBox = styled(Stack)`
  width: 100%;
  padding: 24px 16px;
  border-radius: 8px;
  background-color: ${lightTheme.palette.neutral.n100};
  border: 1px solid ${lightTheme.palette.neutral.n200};
`;
