import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BookingInformationRequests,
  CheckIn,
  Customer,
} from '@understory-io/utils-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from '../Api';
import {
  bookingQuery,
  BOOKINGS_ALL_QUERY_KEY,
  bookingsForDateQuery,
  bookingsForEventQuery,
} from '../Api/queries/bookings';
import { BackofficeLanguage } from '../i18n/config';
import { getLocalizedString, useLocale } from './locales/use-locale.context';
import { IExperience, useExperience } from './useExperience';

type Localized = { [k: string]: string };

export interface TBooking {
  paymentId?: string;
  experienceHeadline?: string;
  variants?: { name: string | Localized; id: string }[];
  id: string;
  companyId: string;
  distributorCompanyId?: string;
  created: string;
  status: 'active' | 'cancelled' | 'moved' | 'unpaid' | 'checked-in';
  cancellationReason?: string;
  eventId: string;
  movedToEvent?: string;
  movedFromEvent?: string;
  experienceId: string;
  items: {
    [id: string]: number;
  };
  parentId?: string;
  internalNote?: string;
  startDateTime: string;
  endDateTime: string;
  customer: Customer;
  customDataInputs?: {
    guestName: string;
    inputs: ({ name: string; id: string; value: string } | null)[];
  }[];
  informationRequests?: BookingInformationRequests;
  channel?: string;
  source?: string;
  receiptId?: string;
  language?: string;
  checkIn?: CheckIn;
  tickets?: {
    id: string;
    status: 'active' | 'cancelled' | 'checked-in';
    ticketUrl: string;
    items: { [id: string]: number };
    counter: number;
    checkIn?: CheckIn;
  }[];
}

export type TCreateBookingPayload = {
  status: 'active' | 'cancelled' | 'unpaid';
  eventId: string;
  experienceId: string;
  shouldNotify?: boolean;
  items: {
    [id: string]: number;
  };
  internalNote?: string;
  startDateTime: string;
  endDateTime: string;
  language?: string;
  customer: {
    name: string;
    email: string;
    phone?: string;
    location?: {
      address: string;
      zipCode: string;
      city: string;
    };
    vatNumber?: string;
    companyName?: string;
  };
  channel: 'manual' | 'integration' | 'checkout' | 'widget';
  source: 'understory';
  metaData?: {
    skipBookingConfirmation: boolean;
  };
  customDataInputs?: {
    inputs: { name: string; value: string }[];
  }[];
  informationRequests?: BookingInformationRequests;
  paymentMethod: string;
};

const getVariants = (experience: IExperience | undefined, language: string) => {
  return (
    experience?.price?.variants?.reduce<{ name: string; id: string }[]>(
      (acc, { addons, name, id }) => {
        addons?.forEach((el) => {
          const addonName = getLocalizedString(el.name, [
            language as BackofficeLanguage,
          ]);
          if (!addonName) return;

          acc.push({
            name: addonName,
            id: el.id,
          });
        });

        const localizedName = getLocalizedString(name, [
          language as BackofficeLanguage,
        ]);
        if (localizedName) {
          acc.push({
            name: localizedName,
            id,
          });
        }

        return acc;
      },
      []
    ) ?? []
  );
};

export const useBookings = (
  eventId?: string,
  bookingId?: string,
  byDate?: string
) => {
  const queryClient = useQueryClient();

  const { experiences } = useExperience();

  const { i18n } = useTranslation('translation');

  const { getLocalizedString } = useLocale();

  const mapBooking = (booking: TBooking) => {
    const foundExperience = experiences.data?.find(
      (ex) => ex.ownerExperienceId === booking.experienceId
    );

    return {
      ...booking,
      variants: getVariants(foundExperience, i18n.language),
      experienceHeadline: getLocalizedString(foundExperience?.headline),
    };
  };

  const booking = useQuery(bookingQuery(bookingId ?? ''));

  const enrichedBooking = useMemo(() => {
    const data = booking.data;
    if (!data) return;

    const foundExperience = experiences.data?.find(
      (ex) => ex.id === data.experienceId
    );
    return {
      ...data,
      variants: getVariants(foundExperience, i18n.language),
      experienceHeadline: getLocalizedString(foundExperience?.headline),
    };
  }, [booking.data, experiences.data, getLocalizedString, i18n.language]);

  const bookingsForEvent = useQuery({
    ...bookingsForEventQuery(eventId ?? ''),
    select: (data) =>
      data.filter((el) => el.eventId === eventId).map(mapBooking),
  });

  const bookingsByDate = useQuery({
    ...bookingsForDateQuery(byDate ?? ''),
    select: (data) => data.map(mapBooking),
  });

  const cancelBooking = useMutation({
    mutationFn: (shouldRefund: boolean = false) =>
      api.cancelBooking(bookingId, shouldRefund),

    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: BOOKINGS_ALL_QUERY_KEY,
      });
      await queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['vouchers', 'bought'],
      });
      queryClient.invalidateQueries({
        queryKey: ['search'],
      });
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['audits', { object: 'Booking', objectId: bookingId }],
        });
      }, 2000);
    },
  });

  const updateBooking = useMutation({
    mutationFn: ({
      id,
      ...payload
    }: {
      id: string;
      internalNote?: string;
      paymentId?: string;
      eventId?: string;
      items?: TCreateBookingPayload['items'];
      shouldSendConfirmation?: boolean;
      language?: string;
      customer?: Customer;
    }) => {
      const existing = queryClient.getQueryData(bookingQuery(id).queryKey);

      return api.updateBooking(id, { ...(existing ?? {}), ...payload });
    },

    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: BOOKINGS_ALL_QUERY_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: ['events'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['receipt'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['search'],
        }),
      ]);
    },
  });

  const createBooking = useMutation({
    mutationFn: async ({
      id,
      ...payload
    }: TCreateBookingPayload & { id: string }) => {
      const { eventId } = await api.createBooking(id, payload);
      return eventId;
    },

    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: BOOKINGS_ALL_QUERY_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: ['events'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['search'],
        }),
      ]);
    },
  });

  const moveBooking = useMutation({
    mutationFn: async ({ id, eventId }: { id: string; eventId: string }) => {
      const { newBookingId, newEventId } = await api.moveBooking(id, eventId);
      return {
        newBookingId,
        newEventId,
      };
    },

    onSettled: async (_data, _err, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: BOOKINGS_ALL_QUERY_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: ['events'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['search'],
        }),
      ]);
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['audits', { object: 'Booking', objectId: variables?.id }],
        });
      }, 1000);
    },
  });

  const checkInBooking = useMutation({
    mutationFn: ({
      id,
      method,
      ticketId,
    }: {
      id: string;
      method: CheckIn['method'];
      ticketId?: string;
    }) => {
      return api.checkInBooking(id, method, ticketId);
    },

    onMutate: async ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: bookingQuery(id).queryKey,
      });
    },

    onSettled: async (data, err, variables) => {
      await queryClient.invalidateQueries({
        queryKey: bookingQuery(variables.id).queryKey,
      });
    },
  });

  return {
    booking,
    enrichedBooking,
    bookingsForEvent,
    bookingsByDate,
    cancelBooking,
    moveBooking,
    createBooking,
    updateBooking,
    checkInBooking,
  };
};
