import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { isBackofficeLanguage } from '../i18n/config';

export const useApplySearchParams = () => {
  const { i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const [hasAppliedLanguage, setHasAppliedLanguage] = useState(false);

  useEffect(() => {
    if (hasAppliedLanguage) return;
    const languageParam = searchParams.get('language');
    if (!languageParam || !isBackofficeLanguage(languageParam)) return;

    i18n.changeLanguage(languageParam);
    setHasAppliedLanguage(true);
  }, [hasAppliedLanguage, i18n, searchParams]);
};
