import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

import { ToggleBranding } from './branding-toggle';
import { ButtonStyleSelect } from './button-style-select';
import { FontFamilySelect } from './font-family-select';
import { PreviewStyling } from './preview-styling';
import { PrimaryColorSelect } from './primary-color-select';

export const StorefrontStyling = () => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ flexDirection: 'row', gap: 3 }}>
      <Stack sx={{ gap: 3 }}>
        <Stack sx={{ gap: 1 }}>
          <Text variant="medium" fontSize="small">
            {t('storefront.customize.styling.title')}
          </Text>
          <Text fontSize="small">
            {t('storefront.customize.styling.description')}
          </Text>
        </Stack>
        <Stack sx={{ gap: 3 }}>
          <FontFamilySelect />
          <PrimaryColorSelect />
          <ToggleBranding />
          <ButtonStyleSelect />
        </Stack>
      </Stack>
      <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
        <PreviewStyling />
      </Stack>
    </Stack>
  );
};

export const AVAILABLE_FONTS = [
  'Inter',
  'DM Sans',
  'Montserrat',
  'Arial',
  'Open Sans',
  'Raleway',
  'Poppins',
];

export const BUTTON_BORDER_STYLES = {
  sharp: '0px',
  medium: '8px',
  rounded: '9999px',
};
