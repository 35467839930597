import NiceModal from '@ebay/nice-modal-react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router';

import { TipProvider } from './Components/tip-sidebar/tip-provider';
import { TipSidebar } from './Components/tip-sidebar/tip-sidebar';
import { InvitePage } from './features/auth/invite/invite-page';
import { InviteView } from './features/auth/invite/invite-view';
import { SignupView } from './features/auth/signup/signup-view';
import {
  action as bookingDetailAction,
  BookingDetails,
  loader as bookingDetailLoader,
} from './features/bookings/details/booking-details';
import {
  action as editBookingAction,
  EditBookingForm,
  loader as editBookingLoader,
} from './features/bookings/forms/edit-booking-form';
import {
  BookingsOverview,
  loader as bookingOverviewLoader,
} from './features/bookings/overview/bookings-overview';
import {
  AcceptConnectionDialog,
  action as acceptConnectionAction,
  loader as acceptConnectionLoader,
} from './features/connect/accept/accept-dialog';
import {
  action as addConnectionAction,
  AddConnectionDialog,
  loader as addConnectionLoader,
} from './features/connect/add/add-connection-dialog';
import { ErrorElement } from './features/connect/components/error-dialog';
import {
  action as declineConnectionAction,
  DeclineConnectionDialog,
  loader as declineConnectionLoader,
} from './features/connect/decline/decline-dialog';
import {
  ConnectionDetailsPage,
  loader as connectionDetailsLoader,
} from './features/connect/details/connection-details-page';
import {
  action as disconnectConnectionAction,
  DisconnectConnectionDialog,
  loader as disconnectConnectionLoader,
} from './features/connect/details/disconnect-dialog';
import {
  action as editConnectionAction,
  EditConnectionDialog,
  loader as editConnectionLoader,
} from './features/connect/details/edit-dialog';
import {
  action as connectInvitationDialogAction,
  InvitationDialog,
  loader as connectInvitationDialogLoader,
} from './features/connect/invitation/invitation-dialog';
import {
  ConnectSettingsList,
  loader as connectSettingsLoader,
} from './features/connect/list/connect-settings-list';
import { DashboardPage } from './features/dashboard/dashboard-page';
import { EventCreate } from './features/events/event_create/ui/EventCreate';
import { EventDetailsView } from './features/events/event_details/EventDetailsView';
import { EventEdit } from './features/events/event_edit/ui/EventEdit';
import { EventUpsertContextProvider } from './features/events/event_upsert/domain/event_upsert_context';
import CreateEventPage, {
  loader as createEventPageLoader,
} from './features/events/flow/create/create-event-page';
import EditEventPage, {
  loader as editEventPageLoader,
} from './features/events/flow/edit/edit-event-page';
import {
  editCapacityAction,
  editCapacityLoader,
} from './features/experiences/details/event-list/edit-capacity';
import {
  updateEventStatusAction,
  updateEventStatusLoader,
} from './features/experiences/details/event-list/event-list-item-desktop';
import {
  ExperienceDetailsPage,
  loader as experienceDetailsLoader,
  updateStatusAction,
  updateStatusLoader,
} from './features/experiences/details/experience-details-page';
import EditExperience, {
  action as editExperienceAction,
  loader as editExperienceLoader,
  loaderName as editExperienceLoaderName,
} from './features/experiences/edit/edit-experience';
import CapacityForm, {
  action as capacityFormAction,
} from './features/experiences/edit/forms/capacity';
import CutoffTimeForm, {
  action as cutoffTimeFormAction,
} from './features/experiences/edit/forms/cutoff-time/cutoff-time';
import DescriptionDialog, {
  action as descriptionDialogAction,
} from './features/experiences/edit/forms/description';
import DisplayPriceForm, {
  action as displayPriceFormAction,
} from './features/experiences/edit/forms/display-price';
import DeleteInfoRequestForm, {
  action as deleteInfoRequestFormAction,
  loader as deleteInfoRequestFormLoader,
} from './features/experiences/edit/forms/guest-info/delete-info-request';
import GuestInfoForm, {
  action as guestInfoFormAction,
  loader as guestInfoFormLoader,
} from './features/experiences/edit/forms/guest-info/guest-info';
import PracticalInfoDialog, {
  action as practicalInfoDialogAction,
} from './features/experiences/edit/forms/practical-info';
import {
  action as deleteResourceRuleAction,
  DeleteResourceRule,
  loader as deleteResourceRuleLoader,
} from './features/experiences/edit/forms/resources/delete-resource-rule';
import {
  action as resourceRuleFormAction,
  loader as resourceRuleFormLoader,
  ResourceRuleForm,
} from './features/experiences/edit/forms/resources/resource-rule-form';
import TagsDialog, {
  action as tagsFormAction,
} from './features/experiences/edit/forms/tags/tags';
import DeleteTicketForm, {
  action as deleteTicketFormAction,
  loader as deleteTicketFormLoader,
} from './features/experiences/edit/forms/tickets/delete-ticket';
import TicketsForm, {
  action as ticketsFormAction,
  loader as ticketsFormLoader,
} from './features/experiences/edit/forms/tickets/tickets';
import WhatsIncludedDialog, {
  action as whatsIncludedDialogAction,
} from './features/experiences/edit/forms/whats-included';
import PreviewDraftDialog, {
  loader as previewDraftDialogLoader,
} from './features/experiences/edit/preview/preview-dialog';
import ExperiencesPage, {
  loader as experiencePageLoader,
} from './features/experiences/overview/experiences-page';
import AdjustValueDialog, {
  action as adjustValueAction,
} from './features/giftcards/details/adjust-value/adjust-value-dialog';
import DeactivateGiftCardDialog, {
  action as deactivateGiftCardAction,
} from './features/giftcards/details/deactivate/deactivate-gift-card-dialog';
import { ErrorDialog as GiftCardErrorDialog } from './features/giftcards/details/error-dialog';
import GiftCardDetailsPage, {
  loader as giftCardDetailsLoader,
} from './features/giftcards/details/gift-card-details-page';
import RefundGiftCarddialog, {
  action as refundGiftCardAction,
} from './features/giftcards/details/refund/refund-gift-card-dialog';
import { GiftCardsView } from './features/giftcards/ui/GiftCardsView';
import { OnboardingContextProvider } from './features/onboarding-flow/onboarding-context';
import { OnboardingFlow } from './features/onboarding-flow/onboarding-flow';
import { DecisionStep } from './features/onboarding-flow/steps/decision/decision-step';
import { HelpStep } from './features/onboarding-flow/steps/help/help-step';
import { PricingStep } from './features/onboarding-flow/steps/pricing/pricing-step';
import { UsesStep } from './features/onboarding-flow/steps/uses/uses-step';
import { GeneratingPreview } from './features/preview/generating-preview/generating-preview';
import { PreviewView } from './features/preview/ui/PreviewView';
import { RESOURCE_MANAGEMENT_SCOPES } from './features/resource-management/auth/resource-management-scopes';
import {
  action as resourceTypeDetailsAction,
  loader as resourceTypeDetailsLoader,
  ResourceTypeDetails,
} from './features/resource-management/resource-types/details/resource-type-details';
import {
  loader as resourceTypesLoader,
  ResourceTypesOverview,
} from './features/resource-management/resource-types/overview/resource-types-overview';
import { UpdateResourceTypeCapacityDialog } from './features/resource-management/resource-types/update/capacity/update-resource-type-capacity-dialog';
import {
  action as createResourceTypePageAction,
  CreateResourceTypePage,
} from './features/resource-management/resource-types/update/create-resource-type-page';
import { action as editResourceTypeAction } from './features/resource-management/resource-types/update/edit-resource-type-page';
import {
  EditResourceTypePageLoader,
  loader as editResourceTypePageLoader,
} from './features/resource-management/resource-types/update/edit-resource-type-page-loader';
import { UpdateResourceTypeRecoverTimeDialog } from './features/resource-management/resource-types/update/recover-time/update-resource-type-recover-time-dialog';
import { UpdateResourceTypeErrorPage } from './features/resource-management/resource-types/update/update-resource-type-error-page';
import {
  action as createResourceAction,
  CreateResourceDialog,
  loader as createResourceLoader,
} from './features/resource-management/resources/create/create-resource-dialog';
import {
  action as billingSectionAction,
  BillingSection,
  loader as billingSectionLoader,
} from './features/settings/billing/billing-section';
import ProfileSettings, {
  action as profileSettingsAction,
  loader as profileSettingsLoader,
} from './features/settings/profile/profile-settings';
import SettingsPageLayout, {
  loader as settingsPageLayoutLoader,
} from './features/settings/settings-page-layout';
import CompanySettings, {
  action as companySettingsAction,
  loader as companySettingsLoader,
} from './features/storefront/company/company-settings';
import {
  action as editCompanyDescriptionDialogAction,
  EditCompanyDescriptionDialog,
  loader as editCompanyDescriptionDialogLoader,
} from './features/storefront/company/edit-company-description-dialog';
import CustomizeStorefront, {
  action as customizeStorefrontAction,
  loader as customizeStorefrontLoader,
} from './features/storefront/customize/customize-storefront';
import StorefrontLanguages, {
  action as storefrontLanguagesAction,
  loader as storefrontLanguagesLoader,
} from './features/storefront/languages/storefront-language';
import StorefrontPaymentsSection, {
  action as storefrontPaymentsAction,
  loader as storefrontPaymentsLoader,
} from './features/storefront/payments/storefront-payments-section';
import StorefrontPageLayout, {
  loader as storefrontPageLayoutloader,
} from './features/storefront/storefront-page-layout';
import {
  GenericErrorPage,
  GenericErrorPageWithAppShell,
} from './generic-error-page';
import { useMenu } from './Hooks/useMenu';
import { AuthPage } from './Pages/Auth/AuthPage';
import { ForgotPasswordPage } from './Pages/Auth/ForgotPasswordPage';
import { LoginPage } from './Pages/Auth/LoginPage';
import { ResetPasswordPage } from './Pages/Auth/ResetPasswordPage';
import { BookingPage } from './Pages/BookingPage';
import { BookingsPage } from './Pages/BookingsPage';
import { CalendarPage } from './Pages/CalendarPage';
import { DiscountPage } from './Pages/DiscountPage';
import { GrowthPage } from './Pages/GrowthPage';
import { EconomicConfigurationPage } from './Pages/settings/economic-configuration-page';
import { EconomicDetailsPage } from './Pages/settings/economic-details-page';
import { EconomicFinalizeConnectionPage } from './Pages/settings/economic-finalize-connection-page';
import { QuickpayDetailsPage } from './Pages/settings/quickpay-details-page';
import { queryClient } from './query-client';
import { DiscountSyiSectionDetails } from './Sections/DiscountSyiSections/DiscountSyiSectionDetails';
import { GrowAdvertisementSection } from './Sections/GrowthSections/advertisement/grow-advertisement-section';
import { GrowthDiscountsSection } from './Sections/GrowthSections/GrowthDiscountsSection';
import { GrowthEmailsSection } from './Sections/GrowthSections/GrowthEmailSection';
import { GrowthTrackingSection } from './Sections/GrowthSections/GrowthTrackingSection';
import { GrowthOpportunitiesSection } from './Sections/GrowthSections/Opportunities/ui/growth-opportunities-section';
import { SettingsIntegrationsSection } from './Sections/SettingsSections/Integrations/SettingsIntegrationsSection';
import { SettingsNotificationsSection } from './Sections/SettingsSections/SettingsNotificationsSection';
import { SettingsTeamSection } from './Sections/SettingsSections/team/settings-team-section';
import {
  action as settingsTeamInviteDialogAction,
  SettingsTeamInviteDialog,
} from './Sections/SettingsSections/team/team-invite/team-invite-dialog';
import {
  action as roleFormDialogAction,
  loader as roleFormDialogLoader,
  RoleFormDialog,
} from './Sections/SettingsSections/team/team-roles/role-form-dialog/role-form-dialog';
import { SettingsTeamRolesDialog } from './Sections/SettingsSections/team/team-roles/settings-team-roles-dialog';
import { SettingsTermsSection } from './Sections/SettingsSections/Terms/SettingsTermsSection';
import { seoAction as StorefrontImplementationSeoAction } from './Sections/StorefrontSections/BookingFlowSection/ui/seo/seo-visibility';
import { StorefrontBookingFlowSection } from './Sections/StorefrontSections/BookingFlowSection/ui/StorefrontBookingFlowSection';
import { StorefrontFeaturesSection } from './Sections/StorefrontSections/FeaturesSection/StorefrontFeaturesSection';
import { LocationManagementSection } from './Sections/StorefrontSections/location-management/location-management-section';
import { TagManagementSection } from './Sections/StorefrontSections/tag-management/tag-management-section';
import { ExperimentProvider } from './Utils/experiments';
import { PrivateRoute } from './Utils/PrivateRoute';
import routes from './Utils/routes';

function NavigateToFrontPageBasedOnScopes() {
  const { frontPageBasedOnScopes } = useMenu();
  const frontPage = frontPageBasedOnScopes || '/dashboard';

  return <Navigate to={frontPage} />;
}

export function createRouter() {
  return createBrowserRouter([
    {
      path: '/',
      element: (
        <NiceModal.Provider>
          <TipProvider>
            <Outlet />
            <TipSidebar />
          </TipProvider>
        </NiceModal.Provider>
      ),
      errorElement: <GenericErrorPage />,
      children: [
        {
          path: '/',
          element: <NavigateToFrontPageBasedOnScopes />,
        },
        {
          path: '*',
          element: <NavigateToFrontPageBasedOnScopes />,
        },
        {
          path: 'login',
          element: (
            <AuthPage title="utils.primaryNavigation.login">
              <LoginPage />
            </AuthPage>
          ),
        },
        {
          path: 'preview',
          element: (
            <AuthPage title="auth.preview.titles.start">
              <PreviewView />
            </AuthPage>
          ),
        },
        {
          path: 'generating-preview/:id',
          element: (
            <AuthPage title="auth.preview.titles.start">
              <GeneratingPreview />
            </AuthPage>
          ),
        },
        {
          path: 'signup',
          element: (
            <AuthPage title="auth.titles.createAccount">
              <ExperimentProvider experiment="FocusedSignupPage">
                <SignupView />
              </ExperimentProvider>
            </AuthPage>
          ),
        },
        {
          path: 'invite',
          element: (
            <AuthPage title="auth.titles.invite">
              <InvitePage>
                <InviteView />
              </InvitePage>
            </AuthPage>
          ),
        },
        {
          path: 'forgot',
          element: (
            <AuthPage title="auth.titles.forgotPassword">
              <ForgotPasswordPage />
            </AuthPage>
          ),
        },
        {
          path: 'reset',
          element: (
            <AuthPage title="auth.titles.resetPassword">
              <ResetPasswordPage />
            </AuthPage>
          ),
        },
        {
          path: 'onboarding',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.onboarding"
              scopes={['company.write']}
              showAppShell={false}
            >
              <OnboardingContextProvider>
                <OnboardingFlow />
              </OnboardingContextProvider>
            </PrivateRoute>
          ),
          children: [
            {
              path: '',
              element: <Navigate to="uses" replace />,
            },
            {
              path: 'uses',
              element: <UsesStep />,
            },
            {
              path: 'pricing',
              element: <PricingStep />,
            },
            {
              path: 'decision',
              element: <DecisionStep />,
            },
            {
              path: 'help',
              element: <HelpStep />,
            },
          ],
        },
        {
          path: 'welcome',
          element: <Navigate to={'/signup'} />,
        },
        {
          path: 'create',
          element: <Navigate to={'/signup'} />,
        },
        {
          path: 'bookings',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.bookings"
              scopes={['booking']}
            >
              <BookingsPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'booking/:id',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.bookings"
              hideContainerPadding
              scopes={['booking']}
            >
              <>
                <BookingPage />
                <Outlet />
              </>
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: 'edit',
              element: <EditBookingForm />,
              loader: editBookingLoader(queryClient),
              action: editBookingAction(queryClient),
            },
          ],
        },
        {
          path: '/v2',
          element: <Outlet />,
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: routes.bookingV2.overview,
              element: (
                <PrivateRoute
                  title="utils.primaryNavigation.bookings"
                  scopes={['booking']}
                >
                  <>
                    <BookingsOverview />
                    <Outlet />
                  </>
                </PrivateRoute>
              ),
              loader: bookingOverviewLoader,
            },
            {
              path: routes.bookingV2.details(':id').index,
              element: (
                <PrivateRoute
                  title="utils.primaryNavigation.bookings"
                  scopes={['booking']}
                >
                  <>
                    <BookingDetails />
                    <Outlet />
                  </>
                </PrivateRoute>
              ),
              loader: bookingDetailLoader,
              action: bookingDetailAction,
            },
          ],
        },
        {
          path: 'calendar',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.calendar"
              scopes={['event']}
            >
              <CalendarPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: routes.experience.overview,
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.experiences"
              scopes={['experience']}
            >
              <ExperiencesPage />
            </PrivateRoute>
          ),
          loader: experiencePageLoader(queryClient),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: routes.experience.details(':id').index,
          element: (
            <PrivateRoute
              changeTitle={false}
              scopes={['experience']}
              hideContainerPadding
            >
              <ExperienceDetailsPage />
            </PrivateRoute>
          ),
          loader: experienceDetailsLoader(queryClient),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: 'update-status',
              loader: updateStatusLoader,
              action: updateStatusAction,
            },
            {
              path: 'edit-capacity',
              loader: editCapacityLoader,
              action: editCapacityAction,
            },
            {
              path: 'update-event-status',
              loader: updateEventStatusLoader,
              action: updateEventStatusAction,
            },
          ],
        },
        {
          path: routes.experience.details(':id').edit.index,
          id: editExperienceLoaderName,
          element: (
            <PrivateRoute
              changeTitle={false}
              scopes={['experience.write']}
              showAppShell={false}
            >
              <>
                <EditExperience />
                <Outlet />
              </>
            </PrivateRoute>
          ),
          loader: editExperienceLoader(queryClient),
          action: editExperienceAction(queryClient),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: routes.experience.details(':id').edit.cutoff,
              element: <CutoffTimeForm />,
              action: cutoffTimeFormAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.tags,
              element: <TagsDialog />,
              action: tagsFormAction(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.tickets.edit(':variantId?'),
              element: <TicketsForm />,
              loader: ticketsFormLoader,
              action: ticketsFormAction(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.tickets.delete(':variantId'),
              element: <DeleteTicketForm />,
              loader: deleteTicketFormLoader,
              action: deleteTicketFormAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.displayPrice,
              element: <DisplayPriceForm />,
              action: displayPriceFormAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.description,
              element: <DescriptionDialog />,
              action: descriptionDialogAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.whatsIncluded,
              element: <WhatsIncludedDialog />,
              action: whatsIncludedDialogAction(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.guestInfo.edit(':requestId?'),
              element: <GuestInfoForm />,
              loader: guestInfoFormLoader,
              action: guestInfoFormAction(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.guestInfo.delete(':requestId'),
              element: <DeleteInfoRequestForm />,
              loader: deleteInfoRequestFormLoader,
              action: deleteInfoRequestFormAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.practicalInfo,
              element: <PracticalInfoDialog />,
              action: practicalInfoDialogAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.capacity,
              element: <CapacityForm />,
              action: capacityFormAction(queryClient),
            },
            {
              path: routes.experience.details(':id').edit.resources.create,
              element: <ResourceRuleForm />,
              action: resourceRuleFormAction(queryClient),
              loader: resourceRuleFormLoader(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.resources.edit(':ruleId'),
              element: <ResourceRuleForm />,
              action: resourceRuleFormAction(queryClient),
              loader: resourceRuleFormLoader(queryClient),
            },
            {
              path: routes.experience
                .details(':id')
                .edit.resources.delete(':ruleId'),
              element: <DeleteResourceRule />,
              action: deleteResourceRuleAction(queryClient),
              loader: deleteResourceRuleLoader,
            },
            {
              path: routes.experience.details(':id').edit.preview,
              element: <PreviewDraftDialog />,
              loader: previewDraftDialogLoader(queryClient),
            },
          ],
        },
        {
          path: 'giftcards',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.giftcards"
              scopes={['giftCard']}
            >
              <GiftCardsView />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'discount/:id',
          element: (
            <PrivateRoute showAppShell={false} scopes={['marketing']}>
              <DiscountPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: 'edit',
              children: [
                {
                  index: true,
                  element: <Navigate to={'details'} />,
                },
                {
                  path: 'details',
                  element: <DiscountSyiSectionDetails />,
                },
              ],
            },
            {
              path: 'details',
              element: <DiscountSyiSectionDetails />,
            },
            {
              index: true,
              element: <Navigate to={'details'} />,
            },
          ],
        },
        {
          path: 'eventv2',
          element: <Outlet />,
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              index: true,
              element: <Navigate to={routes.experience.overview} replace />,
            },
            {
              path: 'create',
              element: (
                <PrivateRoute
                  hideContainerPadding
                  scopes={['event.write']}
                  showAppShell={false}
                >
                  <CreateEventPage />
                </PrivateRoute>
              ),
              loader: createEventPageLoader,
            },
            {
              path: ':id',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: (
                    <PrivateRoute hideContainerPadding scopes={['event']}>
                      <EventDetailsView />
                    </PrivateRoute>
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <PrivateRoute
                      hideContainerPadding
                      scopes={['event.write']}
                      showAppShell={false}
                    >
                      <EditEventPage />
                    </PrivateRoute>
                  ),
                  loader: editEventPageLoader,
                },
              ],
            },
          ],
        },
        {
          path: 'event/create',
          element: (
            <PrivateRoute showAppShell={false} scopes={['event.write']}>
              <EventUpsertContextProvider>
                <EventCreate />
              </EventUpsertContextProvider>
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'event/:id/edit',
          element: (
            <PrivateRoute showAppShell={false} scopes={['event.write']}>
              <EventUpsertContextProvider>
                <EventEdit />
              </EventUpsertContextProvider>
            </PrivateRoute>
          ),
        },
        {
          path: 'event/:id',
          element: (
            <PrivateRoute
              changeTitle={false}
              hideContainerPadding
              scopes={['event']}
            >
              <EventDetailsView />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'growth',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.growth"
              scopes={['marketing']}
            >
              <GrowthPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              index: true,
              element: <Navigate to={'opportunities'} />,
            },
            {
              path: 'opportunities',
              element: <GrowthOpportunitiesSection />,
            },
            {
              path: 'advertisement',
              element: <GrowAdvertisementSection />,
            },
            {
              path: 'discounts',
              element: <GrowthDiscountsSection />,
            },
            {
              path: 'emails',
              element: <GrowthEmailsSection />,
            },
            {
              path: 'tracking',
              element: <GrowthTrackingSection />,
            },
          ],
        },
        {
          path: 'storefront',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.storefront"
              scopes={['company.write']}
            >
              <StorefrontPageLayout />
            </PrivateRoute>
          ),
          loader: storefrontPageLayoutloader,
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              index: true,
              element: <Navigate to={'company'} />,
            },
            {
              path: 'company',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <>
                    <CompanySettings />
                    <Outlet />
                  </>
                </PrivateRoute>
              ),
              loader: companySettingsLoader(queryClient),
              action: companySettingsAction(queryClient),
              children: [
                {
                  path: 'edit',
                  children: [
                    {
                      path: 'description',
                      element: <EditCompanyDescriptionDialog />,
                      loader: editCompanyDescriptionDialogLoader(queryClient),
                      action: editCompanyDescriptionDialogAction(queryClient),
                    },
                  ],
                },
              ],
            },
            {
              path: 'customize',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <CustomizeStorefront />
                </PrivateRoute>
              ),
              loader: customizeStorefrontLoader(queryClient),
              action: customizeStorefrontAction(queryClient),
            },
            {
              path: 'features',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <StorefrontFeaturesSection />
                </PrivateRoute>
              ),
            },
            {
              path: 'bookingflow',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <StorefrontBookingFlowSection />
                </PrivateRoute>
              ),
              action: StorefrontImplementationSeoAction(queryClient),
            },
            {
              path: 'languages',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <StorefrontLanguages />
                </PrivateRoute>
              ),
              loader: storefrontLanguagesLoader(queryClient),
              action: storefrontLanguagesAction(queryClient),
            },
            {
              path: 'tags',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <TagManagementSection />
                </PrivateRoute>
              ),
            },
            {
              path: 'locations',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <LocationManagementSection />
                </PrivateRoute>
              ),
            },
            {
              path: 'payments',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <StorefrontPaymentsSection />
                </PrivateRoute>
              ),
              loader: storefrontPaymentsLoader(queryClient),
              action: storefrontPaymentsAction(queryClient),
            },
          ],
        },
        {
          path: 'settings/integrations/economic',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicDetailsPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'settings/integrations/economic/edit',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicConfigurationPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'settings/integrations/economic/finalize',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicFinalizeConnectionPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'settings/integrations/quickpay',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <QuickpayDetailsPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'dashboard',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.dashboard"
              scopes={['company']}
            >
              <DashboardPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
        },
        {
          path: 'settings',
          element: (
            <PrivateRoute
              titleTranslationsKey="utils.primaryNavigation.settings"
              scopes={['profile']}
            >
              <SettingsPageLayout />
            </PrivateRoute>
          ),
          loader: settingsPageLayoutLoader(queryClient),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            { path: '', element: <Navigate to="profile" replace /> },
            {
              path: 'profile',
              element: <ProfileSettings />,
              loader: profileSettingsLoader(queryClient),
              action: profileSettingsAction(queryClient),
            },
            {
              path: 'users',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <SettingsTeamSection />
                </PrivateRoute>
              ),
              children: [
                {
                  path: 'roles',
                  element: (
                    <PrivateRoute
                      scopes={['company.write']}
                      showAppShell={false}
                    >
                      <SettingsTeamRolesDialog />
                    </PrivateRoute>
                  ),
                  children: [
                    {
                      path: 'create',
                      element: <RoleFormDialog />,
                      loader: roleFormDialogLoader,
                      action: roleFormDialogAction,
                    },
                    {
                      path: 'edit/:id',
                      element: <RoleFormDialog />,
                      loader: roleFormDialogLoader,
                      action: roleFormDialogAction,
                    },
                  ],
                },
                {
                  path: 'invite',
                  element: <SettingsTeamInviteDialog />,
                  action: settingsTeamInviteDialogAction,
                  errorElement: <ErrorElement />,
                },
              ],
            },
            {
              path: 'billing',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <BillingSection />
                </PrivateRoute>
              ),
              loader: billingSectionLoader(queryClient),
              action: billingSectionAction(queryClient),
            },
            {
              path: 'integrations',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <SettingsIntegrationsSection />
                </PrivateRoute>
              ),
              errorElement: <GenericErrorPageWithAppShell />,
            },
            {
              path: 'notifications',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <SettingsNotificationsSection />
                </PrivateRoute>
              ),
              errorElement: <GenericErrorPageWithAppShell />,
            },
            {
              path: 'terms',
              element: (
                <PrivateRoute scopes={['company.write']} showAppShell={false}>
                  <SettingsTermsSection />
                </PrivateRoute>
              ),
              errorElement: <GenericErrorPageWithAppShell />,
            },
            {
              path: 'connect',
              element: (
                <PrivateRoute scopes={['admin']} showAppShell={false}>
                  <ConnectSettingsList />
                </PrivateRoute>
              ),
              loader: connectSettingsLoader,
              errorElement: <ErrorElement />,
              children: [
                {
                  path: 'create',
                  element: <AddConnectionDialog />,
                  action: addConnectionAction,
                  loader: addConnectionLoader,
                },
                {
                  path: 'accept/:token',
                  element: <AcceptConnectionDialog />,
                  loader: acceptConnectionLoader,
                  action: acceptConnectionAction,
                },
                {
                  path: 'decline/:token',
                  element: <DeclineConnectionDialog />,
                  action: declineConnectionAction,
                  loader: declineConnectionLoader,
                },
                {
                  path: 'invitation/:token',
                  element: <InvitationDialog />,
                  loader: connectInvitationDialogLoader,
                  action: connectInvitationDialogAction,
                },
              ],
            },
          ],
        },
        {
          path: 'settings/connect/details/:id',
          id: 'connection-details',
          element: (
            <>
              <ConnectionDetailsPage />
            </>
          ),
          loader: connectionDetailsLoader,
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              path: 'disconnect',
              element: <DisconnectConnectionDialog />,
              action: disconnectConnectionAction,
              loader: disconnectConnectionLoader,
            },
            {
              path: 'edit',
              element: <EditConnectionDialog />,
              action: editConnectionAction,
              loader: editConnectionLoader,
            },
          ],
        },

        {
          path: 'giftcard/:id',
          id: 'gift-card-details',
          element: (
            <PrivateRoute
              changeTitle={false}
              scopes={['giftCard']}
              hideContainerPadding
            >
              <GiftCardDetailsPage />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
          loader: giftCardDetailsLoader(queryClient),
          children: [
            {
              path: 'adjust-value',
              element: <AdjustValueDialog />,
              errorElement: <GiftCardErrorDialog />,
              action: adjustValueAction,
            },
            {
              path: 'deactivate',
              element: <DeactivateGiftCardDialog />,
              errorElement: <GiftCardErrorDialog />,
              action: deactivateGiftCardAction,
            },
            {
              path: 'refund',
              element: <RefundGiftCarddialog />,
              errorElement: <GiftCardErrorDialog />,
              action: refundGiftCardAction,
            },
          ],
        },
        {
          path: 'resources',
          id: 'resource-management',
          element: (
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={RESOURCE_MANAGEMENT_SCOPES.VIEW_RESOURCES}
            >
              <Outlet />
            </PrivateRoute>
          ),
          errorElement: <GenericErrorPageWithAppShell />,
          children: [
            {
              id: 'resource-type-overview',
              path: '',
              loader: resourceTypesLoader(queryClient),
              element: <ResourceTypesOverview />,
            },
            {
              id: 'resource-type-create',
              path: 'create',
              element: (
                <PrivateRoute
                  showAppShell={false}
                  changeTitle={false}
                  scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}
                >
                  <CreateResourceTypePage />
                </PrivateRoute>
              ),
              action: createResourceTypePageAction(queryClient),
              errorElement: <UpdateResourceTypeErrorPage />,
              children: [
                {
                  path: 'capacity',
                  element: <UpdateResourceTypeCapacityDialog />,
                },
                {
                  path: 'recover-time',
                  element: <UpdateResourceTypeRecoverTimeDialog />,
                },
              ],
            },
            {
              id: 'resource-type-edit',
              path: ':resourceTypeId/edit',
              element: (
                <PrivateRoute
                  showAppShell={false}
                  changeTitle={false}
                  scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}
                >
                  <EditResourceTypePageLoader />
                </PrivateRoute>
              ),
              loader: editResourceTypePageLoader(queryClient),
              action: editResourceTypeAction(queryClient),
              errorElement: <UpdateResourceTypeErrorPage />,
              children: [
                {
                  path: 'capacity',
                  element: <UpdateResourceTypeCapacityDialog />,
                },
                {
                  path: 'recover-time',
                  element: <UpdateResourceTypeRecoverTimeDialog />,
                },
              ],
            },
            {
              id: 'resource-type-details',
              path: ':resourceTypeId',
              element: <ResourceTypeDetails />,
              loader: resourceTypeDetailsLoader(queryClient),
              action: resourceTypeDetailsAction(queryClient),
              children: [
                {
                  path: 'create',
                  element: (
                    <PrivateRoute
                      showAppShell={false}
                      changeTitle={false}
                      scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}
                    >
                      <CreateResourceDialog />
                    </PrivateRoute>
                  ),
                  action: createResourceAction(queryClient),
                  loader: createResourceLoader(queryClient),
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
