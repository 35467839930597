import { CloseRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

interface TipHeaderProps {
  onClose: () => void;
}

export const TipHeader = ({ onClose }: TipHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <CloseRounded
        fontSize="small"
        onClick={onClose}
        sx={{
          color: lightTheme.palette.neutral.n300,
          cursor: 'pointer',
        }}
      />
    </Stack>
  );
};
