import { capitalize } from '@mui/material';
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getISOWeek,
  getISOWeekYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from 'date-fns';

import { TranslateFunction } from '../../../../../Hooks/useTranslate';
import { TimePeriod } from '../../../types';

type DateRanges = { [key in TimePeriod]: () => [Date, Date] };

export const getDateRanges: (today: Date) => Omit<DateRanges, 'custom'> = (
  today: Date
) => {
  return {
    today: () => [today, today],
    thisWeek: () => [startOfWeek(today), endOfWeek(today)],
    thisMonth: () => [startOfMonth(today), endOfMonth(today)],
    lastMonth: () => {
      const lastMonthDate = subMonths(today, 1);
      return [startOfMonth(lastMonthDate), endOfMonth(lastMonthDate)];
    },
    year: () => [startOfYear(today), endOfYear(today)],
  };
};

export const renderPeriod = (
  fromDate: Date,
  interval: TimePeriod,
  t: TranslateFunction
): string => {
  if (interval === 'thisWeek') {
    const weekNumber = getISOWeek(fromDate);
    const year = getISOWeekYear(fromDate);
    return `${t('week', 'utils.generic')} ${weekNumber}, ${year}`;
  }

  const formatMap: Record<string, string> = {
    year: 'yyyy',
    lastMonth: 'MMMM yyyy',
    thisMonth: 'MMMM yyyy',
  };

  const formatString = formatMap[interval] || 'do MMM yyyy';

  return capitalize(format(fromDate, formatString));
};
