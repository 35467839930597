import { t } from '../../i18n/config';
import { CustomSelectProps } from './custom-select';

export const renderSelectValue = (
  value: CustomSelectProps['selectedValue'],
  options: CustomSelectProps['options'],
  multiple: CustomSelectProps['multiple'],
  emptyLabel: CustomSelectProps['emptyLabel']
) => {
  if (!value || value.length === 0) return emptyLabel;

  if (Array.isArray(value) && multiple) {
    if (value.length > 1) {
      return `${value.length} ${t('experience.edit.details.selected')}`;
    }
    return options.find((option) => option.value === value[0])?.label;
  }

  return options.find((option) => option.value === value)?.label;
};
