import { HelpOutlineOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from '../../../../../../../Components/tooltip/tooltip';
import { TranslatableGroup } from '../../../../../../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../../../../../../Hooks/translatable/use-storefront-languages';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useErrors } from '../../../../../../../Pages/SyiPage/useErrors';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertDetailsFormInformation = () => {
  const { t } = useTranslate('events.detailsPage.details');
  const { currentEvent, updateEventField } = useEventUpsert();
  const { clearError, getError } = useErrors();

  const { getValues, clearErrors } = useFormContext();

  const storefrontLanguages = useStorefrontLanguages();

  const setLoadingState = (loading: boolean) =>
    updateEventField('loading', loading);

  const handleBlur = () => {
    updateEventField('information', getValues('information'));
  };

  if (!storefrontLanguages)
    return <Skeleton variant="rounded" height={53} width="100%" />;

  return (
    <TranslatableGroup
      langs={storefrontLanguages ?? []}
      id="information"
      label={`${t('information')} (${t('optional', 'utils.generic')})`}
      input={{
        type: 'input',
        maxLength: 200,
        props: {
          InputProps: {
            endAdornment: !currentEvent.loading && (
              <Tooltip title={t('informationTooltip')} placement="top" arrow>
                <HelpOutlineOutlined
                  fontSize="small"
                  htmlColor={lightTheme.palette.neutral.n300}
                  sx={{ cursor: 'default' }}
                />
              </Tooltip>
            ),
          },
        },
      }}
      error={getError('information')}
      clearError={clearError}
      width={'100%'}
      defaultValue={currentEvent.information}
      onTranslationStarted={() => setLoadingState(true)}
      onTranslationEnded={() => {
        setLoadingState(false);
        clearErrors('information');
      }}
      onBlur={handleBlur}
    />
  );
};
