import { ErrorOutlineOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  SxProps,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import isEqual from 'lodash.isequal';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { renderSelectValue } from './custom-select-utils';

const ICON_SIZE_PX = 18;

type CustomMenuItemOption = {
  value: string;
  icon?: ReactNode;
  label: string;
  hasError?: boolean;
};

interface FooterOptionProps extends CustomMenuItemOption {
  key?: string;
  onClick?: () => void;
  children?: ReactNode;
  removePadding?: boolean;
}

export type CustomSelectProps = {
  selectedValue: string | string[];
  options: CustomMenuItemOption[];
  footerOptions?: Omit<FooterOptionProps, 'value'>[];
  onChange?: (value: string | string[]) => void;
  onClose?: (value: string | string[]) => void;
  onOpen?: () => void;
  open?: boolean;
  multiple?: boolean;
  emptyLabel?: string;
  icon?: ReactNode;
  error?: boolean;
  helperText?: string;
  sx?: SxProps;
};

const CustomSelectWrapper = styled(Select)(({ error, sx }) => ({
  color: lightTheme.palette.neutral.n400,
  backgroundColor: lightTheme.palette.contrast.surface1,
  padding: 0,
  overflow: 'hidden',
  '& .MuiSelect-icon': {
    color: lightTheme.palette.contrast.black,
    FontSize: 12,
  },
  // Controls the inner parts of the select
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: '8px 0px 8px 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  // Controls the focus state of the select
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error
      ? lightTheme.palette.error.e300
      : lightTheme.palette.neutral.n200, // Set the border color to gray when focused
  },
  sx,
}));

export function CustomSelect({
  selectedValue,
  options,
  footerOptions = [],
  onChange,
  error = false,
  multiple = false,
  emptyLabel,
  icon,
  onClose,
  sx,
  ...props
}: CustomSelectProps) {
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    if (isEqual(selectedValue, value)) return;
    setValue(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const value = e.target.value as string | string[];
      if (Array.isArray(value)) {
        const filteredValues = value.filter((v) => v !== undefined);
        setValue(filteredValues);
        onChange?.(filteredValues);
      } else {
        setValue(value);
        onChange?.(value);
      }
    },
    [onChange]
  );

  return (
    <CustomSelectWrapper
      multiple={multiple}
      value={value}
      defaultValue={selectedValue}
      sx={{ borderRadius: 1, ...sx }}
      MenuProps={{
        disableAutoFocusItem: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        anchorPosition: {
          top: 0,
          left: 0,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: { maxHeight: 400 },
        MenuListProps: {
          sx: {
            color: lightTheme.palette.contrast.black,
          },
        },
      }}
      onClose={() => onClose?.(value)}
      // Icon to the right of the select
      IconComponent={ExpandMoreIcon}
      // IN
      renderValue={(value: unknown) => (
        <Stack
          sx={{
            color: lightTheme.palette.contrast.black,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                gap: 0.5,
                alignItems: 'center',
                paddingRight: 0.5,
              }}
            >
              <Stack
                sx={{
                  width: ICON_SIZE_PX,
                  height: ICON_SIZE_PX,
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: lightTheme.palette.neutral.n400,
                }}
              >
                {/* Either use the prefined icon or use icon from options */}
                {(Array.isArray(value) && multiple) || !value
                  ? icon
                  : options.find((option) => option.value === value)?.icon}
              </Stack>
              <Text fontSize="small" variant="medium" color="inherit">
                {renderSelectValue(
                  value as string | string[],
                  options,
                  multiple,
                  emptyLabel
                )}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      )}
      onChange={(e) => handleChange(e)}
      error={error}
      displayEmpty
      {...props}
    >
      {options.map(({ value, ...props }, index) => (
        <CustomMenuItem
          key={index}
          value={value}
          showCheckbox={multiple}
          selected={options.map(({ value }) => value).includes(value)}
          {...props}
        />
      ))}
      {/* footerOptions seperated from the primary options with a border inbetween */}
      {footerOptions.map(
        (
          { key, label, icon, hasError, children, removePadding, ...props },
          index
        ) => (
          <MenuItem
            disableRipple
            sx={{
              ...customMenuItemStyling(hasError, false, removePadding),
              ...(index === 0 && {
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderTopColor: lightTheme.palette.contrast.surface1,
              }),
            }}
            key={key}
            {...props}
          >
            {children ? (
              children
            ) : (
              <CustomMenuItemContent
                icon={icon}
                label={label}
                hasError={hasError}
                showCheckbox={false}
              />
            )}
          </MenuItem>
        )
      )}
    </CustomSelectWrapper>
  );
}

const customMenuItemStyling = (
  hasError: boolean = false,
  selected?: boolean,
  removePadding: boolean = false
): NonNullable<SxProps> => ({
  display: 'flex',
  color: hasError
    ? lightTheme.palette.error.e300
    : lightTheme.palette.contrast.black,
  paddingX: removePadding ? 0 : 1.5,
  paddingY: removePadding ? 0 : 1,
  gap: 1,
  backgroundColor: selected
    ? lightTheme.palette.contrast.surface1
    : 'transparent',
  ':focus': {
    backgroundColor: `${lightTheme.palette.neutral.n100} !important`,
  },
  '&:hover': {
    backgroundColor: `${lightTheme.palette.neutral.n100} !important`,
  },
});

type CustomMenuItemProps = CustomMenuItemOption &
  Omit<MenuItemProps, 'value'> & { showCheckbox: boolean };

function CustomMenuItem({
  value,
  label,
  icon,
  hasError,
  showCheckbox,
  selected,
  ...props
}: CustomMenuItemProps) {
  return (
    <MenuItem
      disableRipple
      sx={customMenuItemStyling(hasError, selected)}
      value={value}
      {...props}
    >
      <CustomMenuItemContent
        icon={icon}
        label={label}
        hasError={hasError}
        showCheckbox={showCheckbox}
        selected={selected}
      />
    </MenuItem>
  );
}

const CustomMenuItemContent = ({
  icon,
  label,
  hasError,
  showCheckbox,
  selected,
}: Omit<CustomMenuItemProps, 'value'>) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {icon && (
          <Stack
            sx={{
              width: ICON_SIZE_PX,
              height: ICON_SIZE_PX,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </Stack>
        )}
        <Text fontSize="small" variant="medium" color="inherit">
          {label}
        </Text>
      </Stack>
      {showCheckbox && (
        <Checkbox
          checked={selected}
          size="small"
          sx={{ padding: 0, margin: 0, justifySelf: 'flex-end' }}
        />
      )}
      {!showCheckbox && hasError && (
        <ErrorOutlineOutlined
          sx={{
            marginLeft: 'auto',
            color: lightTheme.palette.error.e300,
          }}
        />
      )}
    </Stack>
  );
};
