import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {
  RegularExperience,
  ResourceRule,
} from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { Type, Value } from '@understory-io/utils-types/typebox';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTicketsAndAddons } from '../../../../../Hooks/use-tickets-and-addons';
import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';

type Props = {
  experience: RegularExperience;
};

export const AllocationTypeSelect: FC<Props> = ({ experience }) => {
  const { t } = useTranslate(
    'experience.edit.dialog.resourceRule.allocationType'
  );

  const { watch, setValue, getValues, control } =
    useFormContext<ResourceRule>();

  const allocationType = watch('allocationType');

  const tickets = useTicketsAndAddons(experience);

  const localizer = useLocalizedStringFormatter();

  // Select the first ticket, if there is only one
  useEffect(() => {
    if (allocationType !== 'ticket' || tickets.length !== 1) {
      return;
    }

    setValue('ticketId', tickets[0].id);
  }, [allocationType, getValues, setValue, tickets]);

  return (
    <Box>
      <RadioGroup
        name="allocationType"
        value={allocationType || 'event'}
        onChange={(e) => {
          const value = e.target.value;
          if (
            !Value.Check(Type.Index(ResourceRule, ['allocationType']), value)
          ) {
            return;
          }

          setValue('allocationType', value);
        }}
      >
        <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
          {t('label')}
        </Text>
        <FormControlLabel
          value="event"
          control={<Radio size="small" />}
          label={<Text fontSize="small">{t('event')}</Text>}
        />
        <FormControlLabel
          value="booking"
          control={<Radio size="small" />}
          label={<Text fontSize="small">{t('booking')}</Text>}
        />
        <FormControlLabel
          value="ticket"
          control={<Radio size="small" />}
          label={<Text fontSize="small">{t('ticket')}</Text>}
        />
      </RadioGroup>
      {allocationType === 'ticket' && (
        <Controller
          control={control}
          name="ticketId"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ marginTop: 1, marginLeft: 3, minWidth: 200 }}
              {...field}
              error={Boolean(error)}
              InputLabelProps={{ shrink: true }}
              label={t('ticket')}
              value={field.value || ''}
              select
              placeholder={t('selectTicket')}
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => {
                  const ticket = tickets.find(({ id }) => id === value);
                  if (!ticket) {
                    return (
                      <Text
                        color={lightTheme.palette.neutral.n300}
                        fontSize="small"
                      >
                        {t('selectTicket')}
                      </Text>
                    );
                  }

                  return <>{localizer(ticket.name)}</>;
                },
              }}
            >
              {tickets.map((ticket) => (
                <MenuItem key={ticket.id} value={ticket.id}>
                  {localizer(ticket.name)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}
    </Box>
  );
};
