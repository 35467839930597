import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { CompanyProfile, Localized } from '@understory-io/utils-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  useActionData,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateCompanyProfile } from '../../../Api';
import { companyProfileQuery } from '../../../Api/queries';
import DialogBase from '../../../Components/dialog/dialog-base';
import { TranslatableInput } from '../../../Components/translatable-input';
import { StorefrontLanguage, t } from '../../../i18n/config';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = (client: QueryClient) => async () => {
  const [companyProfile] = await Promise.all([
    client.fetchQuery(companyProfileQuery()),
  ]);

  return { companyProfile };
};

export const EditCompanyDescriptionDialog = () => {
  const { t } = useTranslation();
  const actionData = useActionData<ActionData>();
  const { companyProfile } = useLoaderData<LoaderData>();
  const submit = useSubmit();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [companyDescription, setCompanyDescription] = useState(
    companyProfile.description
  );

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (actionData?.shouldClose) {
      handleClose();
    }
  }, [actionData?.shouldClose, handleClose]);

  const handleSubmit = () => {
    setIsLoading(true);
    submit(companyDescription, {
      method: 'post',
      encType: 'application/json',
    });
  };

  return (
    <DialogBase
      title={t('storefront.company.companyDescription.title')}
      open={!actionData?.shouldClose}
      primaryAction={{
        label: t('buttons.save'),
        variant: 'primary',
        type: 'button',
        loading: isLoading,
        disabled: isLoading,
        onClick: handleSubmit,
      }}
      fullHeight={true}
      onClose={handleClose}
    >
      <TranslatableInput
        value={companyDescription}
        defaultLanguage={companyProfile.defaultLanguage as StorefrontLanguage}
        availableLanguages={companyProfile.languages as StorefrontLanguage[]}
        onChange={(value) => setCompanyDescription(value)}
        placeholder={t('settings.company.about.inputPlaceholder')}
      />
    </DialogBase>
  );
};

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs): Promise<ActionData> => {
    const companyProfile = await client.fetchQuery(companyProfileQuery());

    const companyDescription = (await request.json()) as Localized;

    const updatedCompanyProfile: CompanyProfile = {
      ...companyProfile,
      description: companyDescription,
    };

    await updateCompanyProfile(updatedCompanyProfile);
    await client.invalidateQueries({
      queryKey: companyProfileQuery().queryKey,
    });

    try {
      return {
        shouldClose: true,
      };
    } catch (error) {
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
