import { useLocation } from 'react-router';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { ShowTipButton } from './show-tip-button';
import { useTip } from './tip-provider';
import { TipSidebarDesktop } from './tip-sidebar-desktop';
import { TipSidebarMobile } from './tip-sidebar-mobile';

export const TipSidebar = () => {
  const { showTip, showTipButton, currentTip, dismissTip, closeTip, openTip } =
    useTip();
  const { isMd } = useResponsive();
  const { onboardingCompleted } = useProfile();
  const location = useLocation();

  return (
    <>
      {isMd ? (
        <TipSidebarMobile
          showTip={showTip}
          currentTip={currentTip}
          onClose={closeTip}
          onDismiss={dismissTip}
        />
      ) : (
        <TipSidebarDesktop
          showTip={showTip}
          currentTip={currentTip}
          onClose={closeTip}
          onDismiss={dismissTip}
        />
      )}
      {showTipButton && !onboardingCompleted && (
        <ShowTipButton onClick={openTip} location={location.pathname} />
      )}
    </>
  );
};
