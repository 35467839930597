import { ReactElement } from 'react';

import { useEconomicIntegration } from './integrations/use-economic-integration';
import { useIcalIntegration } from './integrations/use-ical-integration';
import { useOauth2Integration } from './integrations/use-oauth2-integration';
import { usePaypalIntegration } from './integrations/use-paypal-integration';
import { useQuickpayIntegration } from './integrations/use-quickpay-integration';
import { useStripeIntegration } from './integrations/use-stripe-integration';
import { useTruestoryIntegration } from './integrations/use-truestory-integration';

export type IntegrationObject = {
  id: string;
  title: string;
  icon: string;
  description: string;
  extendedDescription: string;
  /**
   * Status of the integration
   * - `connect` - The integration has never been connected before and is available for connection
   * - `connected` - The integration is connected
   * - `anything else` - The integration has been started but not finished yet. This depends on the integration if any status is returned
   *
   * If not connected, the will appear in the available integrations list
   */
  status: 'connect' | 'connected' | string;
  openForRequest?: boolean;
  isRequested?: boolean;
  hidden?: boolean;
  warnings?: string[];
  buttonLabel: string | ((status: string) => string);
  guide?: string;
  onInstall?: (status: string) => () => Promise<void>;
  onUninstall?: () => Promise<void>;
  loading?: boolean;
  isPaymentGateway?: boolean;
  steps?: {
    title: string;
    description: string | ReactElement;
  }[];
};

export type LoadingIntegrationObject = {
  loading: true;
};

export const useIntegrations = () => {
  const truestoryIntegration = useTruestoryIntegration();

  const otherIntegrations: Array<
    IntegrationObject | LoadingIntegrationObject | null
  > = [
    useIcalIntegration(),
    useEconomicIntegration(),
    usePaypalIntegration(),
    useStripeIntegration(),
    useQuickpayIntegration(),
    ...useOauth2Integration(),
  ];

  const { activeIntegrations, availableIntegrations, loading } =
    otherIntegrations.reduce<{
      activeIntegrations: IntegrationObject[];
      availableIntegrations: IntegrationObject[];
      loading: boolean;
    }>(
      (acc, integration) => {
        if (!integration) {
          return acc;
        }

        acc.loading ||= !!integration.loading;

        if (!integration.loading) {
          integration.status === 'connected'
            ? acc.activeIntegrations.push(integration)
            : acc.availableIntegrations.unshift(integration);
        }

        return acc;
      },
      {
        activeIntegrations: [],
        availableIntegrations: [truestoryIntegration],
        loading: false,
      }
    );

  // Sort activeIntegrations alphabetically by title
  activeIntegrations.sort((a, b) => a.title.localeCompare(b.title));
  // Sort availableIntegrations alphabetically by title
  availableIntegrations.sort((a, b) => a.title.localeCompare(b.title));

  return {
    loading,
    availableIntegrations: loading ? [] : availableIntegrations,
    activeIntegrations: loading ? [] : activeIntegrations,
  };
};
