import { ReducedVat } from '@understory-io/utils-types';

import { ampli } from '../../Ampli';

export const trackTaxSetPrimaryRateEvent = (
  category: string,
  tax: ReducedVat
) => ampli.taxSetPrimaryRate(reducedVatToProperties(category, tax));

export const trackTaxAddRateEvent = (category: string, tax: ReducedVat) =>
  ampli.taxAddNewRate(reducedVatToProperties(category, tax));

export const trackTaxDeleteRateEvent = (category: string, tax: ReducedVat) =>
  ampli.taxDeleteRate(reducedVatToProperties(category, tax));

function reducedVatToProperties(category: string, tax: ReducedVat) {
  return {
    tax_rate: tax.rate,
    is_user_made: tax.isUserMade,
    tax_category_id: category,
    tax_exempt: !!tax.exempt,
    tax_label: tax.label,
  };
}
