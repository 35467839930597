import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CustomizeStorefrontFormData } from '../customize-storefront';
import { BUTTON_BORDER_STYLES } from './storefront-styling';

export const PreviewStyling = () => {
  const { t } = useTranslate('storefront.customize.preview');

  const { watch } = useFormContext<CustomizeStorefrontFormData>();

  const primaryColor = watch('customization.primaryColor');
  const fontFamily = watch('customization.fontFamily') + ', Inter, sans-serif';
  const buttonStyle = watch('customization.buttonStyle');

  const color =
    primaryColor?.length === 7 && primaryColor?.charAt(0) === '#'
      ? primaryColor
      : lightTheme.palette.action.a300;

  return (
    <Stack>
      <PreviewContainer sx={{ fontFamily }}>
        <Text variant="medium">{t('title')}</Text>
        <Text>{t('description')}</Text>
        <Button
          variant="primary"
          size="medium"
          type="button"
          style={{
            marginTop: 'auto',
            fontFamily,
            backgroundColor: color,
            color: getColorByBg(color),
            borderRadius:
              BUTTON_BORDER_STYLES[
                buttonStyle as keyof typeof BUTTON_BORDER_STYLES
              ],
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = color + 'CC';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = color;
          }}
        >
          {t('book')}
        </Button>
      </PreviewContainer>
    </Stack>
  );
};

const PreviewContainer = styled(Stack)({
  maxWidth: '432px',
  borderColor: lightTheme.palette.neutral.n200,
  borderWidth: '2px',
  borderStyle: 'dashed',
  borderRadius: '30px',
  padding: '32px',
  gap: '8px',
  '> button': {
    alignSelf: 'flex-end',
  },
});

const getColorByBg = (bgColor?: string) => {
  if (!bgColor) return '#000000';

  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
};
