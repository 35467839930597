import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { useTranslate } from '../Hooks/useTranslate';
import { Header } from './Header';

export const PaypalDialog = NiceModal.create(
  ({
    onClick,
  }: {
    onClick: (accountStatus: 'new' | 'existing' | undefined) => Promise<void>;
  }) => {
    const { t } = useTranslate('dialogs.initPaypal');

    const [loading, setLoading] = useState(false);

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    const handleClick = async () => {
      setLoading(true);
      await onClick(undefined);
      setLoading(false);
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 800 } }}
      >
        <Box pl={4}>
          <Header title={t('title')} onClose={handleClose} />

          <Stack pt={3} pb={4} pr={4} direction={'row'} spacing={3}>
            <Box maxWidth={500}>
              <Typography variant={'h4'} fontSize={'1.2em'} mb={2}>
                {t('subtitle')}
              </Typography>
              <Typography whiteSpace={'pre-wrap'}>
                {t('description')}
              </Typography>
              <Button
                sx={{ mt: 3 }}
                variant={'contained'}
                size={'large'}
                disabled={loading}
                onClick={handleClick}
              >
                {loading ? (
                  <CircularProgress color={'inherit'} size={20} sx={{ m: 0 }} />
                ) : (
                  t('actions.primary')
                )}
              </Button>
            </Box>
            <Box
              component={'img'}
              src={'/icons/paypal.png'}
              height={'100%'}
              minWidth={205}
            />
          </Stack>
        </Box>
      </Dialog>
    );
  }
);
