import * as api from '..';

/* ---- Connections ----  */
export const connectionsQueryKey = (companyId?: string) => [
  'connections',
  companyId,
];

export const connectionsQuery = (companyId?: string) => ({
  queryKey: connectionsQueryKey(companyId),
  queryFn: () => api.getConnections(),
});
