import { Stack } from '@mui/material';
import randomBytes from 'randombytes';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { saveExperienceDraft } from '../../../../Api';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { ONBOARDING_OPTIONS } from '../../config';
import { useOnboardingFlow } from '../../onboarding-context';
import { OnboardingStepLayout } from '../step-layout/step-layout';
import { DecisionOptionCard } from './decision-option-card';

export const DecisionStep = () => {
  const { t } = useTranslate('onboardingFlow.step.decision');
  const { isSm } = useResponsive();
  const { selections, updateSelections } = useOnboardingFlow();
  const navigate = useNavigate();
  const { company } = useProfile();

  const [isCreatingExperience, setIsCreatingExperience] = useState(false);
  const handleSubmit = async () => {
    ampli.onboardingFlowDecisionSelected({
      decision: selections.decision?.id ?? '',
    });

    if (selections.decision?.id === 'create') {
      handleCreateExperience();
      return;
    }

    if (selections.decision) {
      navigate(selections.decision.route);
    }
  };

  const handleCreateExperience = async () => {
    try {
      setIsCreatingExperience(true);
      const newExperienceId = randomBytes(16).toString('hex');

      await saveExperienceDraft(newExperienceId, {
        locationIds: [],
        seats: {
          type: 'single',
          seatCount: 10,
        },
        price: {
          variants: [
            {
              id: randomBytes(16).toString('hex'),
              name: {
                [company.data?.defaultLanguage ?? 'en']: t(
                  'free',
                  'utils.statusOptions'
                ),
              },
              priceBreakdown: {
                vatInclusivePriceCents: 0,
                vatSetting: {
                  vatCategory: 'n/a',
                },
              },
            },
          ],
        },
      });

      navigate(routes.experience.details(newExperienceId).edit.index, {
        state: {
          newExperience: true,
          fromOnboarding: true,
        },
      });
    } catch (error) {
      setIsCreatingExperience(false);
      toast.error(t('generic', 'utils.errors'));
    }
  };

  return (
    <>
      <OnboardingStepLayout
        title={t('title')}
        onSubmit={handleSubmit}
        isLoading={isCreatingExperience}
        showBackButton
      >
        <Stack
          gap={2}
          justifySelf={isSm ? 'center' : 'flex-start'}
          width="100%"
          height="100%"
        >
          {ONBOARDING_OPTIONS.map((option) => (
            <DecisionOptionCard
              key={option.id}
              option={option}
              selected={selections.decision?.id === option.id}
              onSelect={(option) => updateSelections({ decision: option })}
            />
          ))}
        </Stack>
      </OnboardingStepLayout>
    </>
  );
};
