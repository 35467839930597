import { ChevronLeftRounded } from '@mui/icons-material';
import { Grid, InputAdornment, Stack, TextField } from '@mui/material';
import NumberFlow from '@number-flow/react';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { FC, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { createAdvertisementPlan } from '../../../../../../Api/Marketing';
import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../../../Hooks/locales/use-locale.context';
import { useAdPlans } from '../../../../../../Hooks/use-ad-plans';
import { useProfile } from '../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../../../Utils/helpers';
import { OutcomeBox } from '../../components/outcome-box';
import { BUDGET_CURRENCY_MAP, EXPECTED_ROAS } from '../../constants';
import { useAdvertisementPlan } from '../use-advertisement-plan';
import {
  AdvertisementPlanContext,
  StepProps,
} from './grow-advertisement-flow-steps';

export const BudgetStep: FC<StepProps> = ({ onNext, onPrevious }) => {
  const { t } = useTranslate('grow.advertisement.getStarted');
  const { isSm } = useResponsive();
  const { adPlan, setAdPlan } = useContext(AdvertisementPlanContext);

  const {
    company: { data: company },
    defaultCurrency,
  } = useProfile();

  const { dateFnsLocale } = useLocale();

  const {
    adPlans: { refetch: refetchAdPlans },
  } = useAdPlans();

  const { updateBudget } = useAdvertisementPlan();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [budget, setBudget] = useState(
    (adPlan.budget || BUDGET_CURRENCY_MAP[defaultCurrency]?.default) ?? 0
  );

  const minBudget = BUDGET_CURRENCY_MAP[defaultCurrency]?.min ?? 0;
  const expectedRevenue = budget * EXPECTED_ROAS;
  const isBudgetValid = budget >= minBudget;

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (adPlan.id) {
        updateBudget(budget);
      } else {
        const createdPlan = await createAdvertisementPlan({
          budget,
          goals: adPlan.goals,
          usps: adPlan.usps,
          companyName: company?.name ?? '',
          currency: defaultCurrency,
          experiences: adPlan.experiences?.length
            ? adPlan.experiences
            : undefined,
        });
        setAdPlan({ ...createdPlan, experiences: adPlan.experiences });
        await refetchAdPlans();
      }

      onNext();
    } catch (error) {
      console.error('Error updating advertisement plan:', error);
      toast.error(t('generic', 'utils.errors'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Text variant="medium">{t('budget.fieldTitle')}</Text>
        <TextField
          type="number"
          value={budget}
          onChange={(e) => setBudget(Number(e.target.value))}
          error={!isBudgetValid}
          helperText={t('budget.fieldHelperText', {
            amount: getFormattedPrice(
              minBudget,
              defaultCurrency,
              dateFnsLocale.code
            ),
          })}
          inputProps={{
            min: minBudget,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {t(defaultCurrency, 'utils.currency')}
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text
            fontSize="xsmall"
            variant="medium"
            color={lightTheme.palette.neutral.n300}
            style={{ width: '100%', textAlign: 'left' }}
          >
            {t('budget.expectedOutcome')}
          </Text>
        </Grid>
        <Grid item xs={12} sm={6}>
          <OutcomeBox
            label={''}
            description={`${t(
              'monthlyRevenueDescription',
              'grow.advertisement.launch'
            )}*`}
            value={
              <NumberFlow
                value={expectedRevenue}
                locales={dateFnsLocale.code}
                format={{
                  style: 'currency',
                  currency: defaultCurrency,
                  maximumFractionDigits: 0,
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OutcomeBox
            label={''}
            description={`${t('roasDescription', 'grow.advertisement.launch', {
              oneLocalMoney: getFormattedPrice(
                1,
                defaultCurrency,
                dateFnsLocale.code
              ),
            })}*`}
            value={
              <NumberFlow
                value={EXPECTED_ROAS}
                locales={dateFnsLocale.code}
                format={{
                  style: 'currency',
                  currency: defaultCurrency,
                  maximumFractionDigits: 2,
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            fontSize="tiny"
            style={{ lineHeight: 0.5 }}
            color={lightTheme.palette.neutral.n300}
          >
            {'*'}
            {t('budget.estimate')}
          </Text>
        </Grid>
      </Grid>
      <Stack direction={isSm ? 'column-reverse' : 'row'} gap={2} width={'100%'}>
        <Button
          variant="secondary"
          size="large"
          leftIcon={<ChevronLeftRounded />}
          style={{ flex: !isSm ? 1 : 'unset' }}
          onClick={onPrevious}
        >
          {t('back', 'buttons')}
        </Button>
        <Button
          variant="primary"
          size="large"
          style={{ flex: !isSm ? 1 : 'unset' }}
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting || !isBudgetValid}
        >
          {t('nextStep', 'buttons')}
        </Button>
      </Stack>
    </Stack>
  );
};
