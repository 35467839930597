import CheckIcon from '@mui/icons-material/Check';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

type FeatureItemProps = {
  text: string;
};

export const PricingFeatureItem = ({ text }: FeatureItemProps) => (
  <Stack
    direction="row"
    gap={1}
    alignItems="center"
    justifyContent="space-between"
  >
    <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
      {text}
    </Text>
    <CheckIcon
      fontSize="small"
      sx={{ color: lightTheme.palette.action.a300 }}
    />
  </Stack>
);
