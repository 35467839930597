import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { ampli } from '../../Ampli';
import { SectionHeadline } from '../../Components/SectionHeadline/SectionHeadline';
import { SimpleSkeleton } from '../../Components/SimpleSkeleton/SimpleSkeleton';
import { useNotifications } from '../../Hooks/notifications/useNotifications';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { trackNotificationToggled } from '../../tracking/settings/settings-events';

export const SettingsNotificationsSection = () => {
  const { t } = useTranslate('settings.notifications');

  const {
    notifications: { data: notifications, isLoading },
    toggleNotification,
  } = useNotifications();

  const {
    me: { data: me },
  } = useProfile();

  const handleToggle =
    (groupKey: string, key: string) => async (_: unknown, checked: boolean) => {
      try {
        await toggleNotification.mutateAsync({ groupKey, key, checked });
        trackNotificationToggled?.(key, checked, me?.id);
      } catch (error) {
        console.error(error);
      }
    };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.notificationsPageViewed()), [fireOnce]);

  return (
    <>
      <SectionHeadline title={t('title')} description={t('description')} />
      <Stack mt={5} gap={5}>
        {isLoading && <SimpleSkeleton />}
        {notifications?.map((group) => (
          <Box key={group.key}>
            <Typography variant={'h5'}>{t(`groups.${group.key}`)}</Typography>
            <Stack gap={1} mt={1} alignItems={'baseline'}>
              {group.items.map((item) => (
                <FormControlLabel
                  key={item.key}
                  checked={item.enabled}
                  control={<Switch />}
                  onChange={handleToggle(group.key, item.key)}
                  label={t(`templates.${item.key}`)}
                />
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </>
  );
};
