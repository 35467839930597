import { createContext, FC, PropsWithChildren, useContext } from 'react';

import routes from '../../../../Utils/routes';

type UpdateResourceTypeContextState = {
  links: typeof routes.resourceManagement.create;
  isEdit?: boolean;
};

export const UpdateResourceTypeContext =
  createContext<UpdateResourceTypeContextState | null>(null);

export const useUpdateResourceTypeLinks = () => {
  const context = useContext(UpdateResourceTypeContext);
  if (!context) {
    throw new Error(
      'useUpdateResourceTypeLinks must be used within a UpdateResourceTypeContextProvider'
    );
  }

  return context.links;
};

export const useUpdateResourceIsEdit = (): boolean => {
  return !!useContext(UpdateResourceTypeContext)?.isEdit;
};

type Props = PropsWithChildren<{
  /** Links used throughout the flow */
  links: UpdateResourceTypeContextState['links'];

  /** Whether it is an edit or create flow */
  isEdit?: boolean;
}>;
export const UpdateResourceTypeContextProvider: FC<Props> = ({
  children,
  links,
  isEdit,
}) => {
  return (
    <UpdateResourceTypeContext.Provider value={{ links, isEdit }}>
      {children}
    </UpdateResourceTypeContext.Provider>
  );
};
