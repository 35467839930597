import { CheckRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { Text } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const SignupUsps = () => {
  const { t } = useTranslate('signup');
  const { isMd } = useResponsive();

  return (
    <Stack gap={1} flex={1}>
      {isMd && (
        <Text fontSize="medium" variant="medium">
          {t('uspTitle')}
        </Text>
      )}
      <Usp usp={t('usp1')} />
      <Usp usp={t('usp2')} />
      <Usp usp={t('usp3')} />
      <Usp usp={t('usp4')} />
    </Stack>
  );
};

const Usp = ({ usp }: { usp: string }) => {
  return (
    <Stack direction="row" gap={1} justifyContent={'space-between'}>
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {usp}
      </Text>
      <CheckRounded htmlColor={lightTheme.palette.neutral.n400} />
    </Stack>
  );
};
