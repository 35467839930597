import { AddOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  StackProps,
  SvgIconProps,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { ComponentProps, ElementType, ReactNode } from 'react';
import { Link, To } from 'react-router';

import { mergeSx } from '../../../../Utils/merge-sx';
import { EllipsedText } from '../../../ellipsed-text';
import { Tooltip } from '../../../tooltip/tooltip';

const ICON_SIZE_PX = 20;
const ICON_PADDING_PX = 6;

export type FullPageFormSettingRowProps = Omit<StackProps, 'children'> & {
  /** Title to show in the row */
  title: ReactNode;
  /** Optional description to show beneath the title  */
  description?: ReactNode;
  /** Icon to display to the left of the title*/
  Icon?: ElementType<SvgIconProps>;

  /** Value to display */
  value?: ReactNode;
  /** Tooltip for the value */
  valueTooltip?: ComponentProps<typeof Tooltip>['title'];

  /** Displays the item as an error */
  hasError?: boolean;

  /** An action is shown to the right */
  action?: {
    Icon?: ElementType<SvgIconProps>;
    to: To;
  };
};

/**
 * Row for a setting.
 */
export const FullPageFormSettingRow = ({
  sx,
  title,
  description,
  Icon,
  value,
  valueTooltip,
  hasError,
  action,
  ...props
}: FullPageFormSettingRowProps) => {
  const ActionIcon = action?.Icon ?? AddOutlined;

  return (
    <Stack
      sx={mergeSx(
        {
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%',
        },
        sx
      )}
      {...props}
    >
      <Stack
        direction="row"
        gap={1}
        flexGrow={1}
        overflow="hidden"
        marginLeft={Icon ? -1 : 0}
        alignItems="center"
      >
        {Icon && (
          <Icon
            fontSize="small"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: lightTheme.palette.action.a100,
              height: ICON_SIZE_PX + ICON_PADDING_PX * 2,
              width: ICON_SIZE_PX + ICON_PADDING_PX * 2,
              borderRadius: '50%',
              color: lightTheme.palette.action.a300,
              padding: `${ICON_PADDING_PX}px`,
            }}
          />
        )}
        <div>
          <EllipsedText fontSize="small" variant="medium" color="inherit">
            {title}
          </EllipsedText>
          {description && (
            <Box sx={{ display: 'block' }}>
              <EllipsedText
                variant="medium"
                fontSize="small"
                color={lightTheme.palette.neutral.n300}
              >
                {description}
              </EllipsedText>
            </Box>
          )}
        </div>
      </Stack>
      <Stack direction="row" gap={1} minWidth={0} alignItems="center">
        {value && (
          <Tooltip title={valueTooltip}>
            <EllipsedText
              fontSize="small"
              variant="medium"
              color={lightTheme.palette.neutral.n300}
            >
              {value}
            </EllipsedText>
          </Tooltip>
        )}
        {action && (
          <IconButton
            component={Link}
            to={action.to}
            sx={{
              padding: 0,
              color: lightTheme.palette.neutral.n300,
              transition: 'color 0.1s',
              ':hover': {
                color: lightTheme.palette.neutral.n500,
                backgroundColor: lightTheme.palette.contrast.surface1,
              },
              ':focus-visible': {
                outline: `1px solid ${lightTheme.palette.neutral.n500}`,
                outlineOffset: 4,
              },
            }}
          >
            <ActionIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
