import { Avatar, capitalize } from '@mui/material';

import { TUser } from '../../Hooks/useUsers';
import { createSumsTo100 } from '../../Utils/create-sums-to-100';
import { StatusBadge } from '../badge/status-badge';
import { CopyLink } from '../CopyLink/CopyLink';

export type MappedUser = TUser & {
  roleName: string;
  status: 'active' | 'invited';
  invitationId: string;
};

export const usersListColumns = [
  {
    key: 'avatar',
    value: (el: TUser) => (
      <Avatar src={el.pictures?.profile?.url} sx={{ width: 32, height: 32 }} />
    ),
  },
  {
    key: 'name',
    value: (el: TUser) => el.name,
  },
  {
    key: 'role',
    value: (el: MappedUser) => capitalize(el.roleName),
  },
  {
    key: 'phone',
    value: (el: TUser) => el.phone,
  },
  {
    key: 'email',
    value: (el: TUser) => (
      <CopyLink
        href={`mailto:${el.email}`}
        isEmail
        iconPosition="left"
        size="medium"
        fontSize="small"
        label={el.email}
      />
    ),
  },
  {
    key: 'status',
    value: (el: TUser & { status: 'active' | 'invited' }) => (
      <StatusBadge type="user" state={el.status} size="small" />
    ),
  },
];

export const usersListColumnWidths = createSumsTo100({
  avatar: 6,
  name: 18,
  role: 13,
  phone: 16,
  email: 35,
  status: 12,
} as const);
