import { Box, Link } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Trans } from 'react-i18next';

import { ampli } from '../../../../Ampli';

export const PricingDisclaimer = () => {
  const handleTermsClick = () => {
    ampli.onboardingFlowTermsClicked();
  };

  return (
    <Box>
      <Text variant="normal" fontSize="small" color="text.secondary">
        <Trans
          i18nKey="onboardingFlow.step.pricing.disclaimer"
          components={{
            Link: (
              <Link
                target="_blank"
                underline="always"
                onClick={handleTermsClick}
              />
            ),
          }}
        />
      </Text>
    </Box>
  );
};
