import { API } from '@understory-io/grow-types';
import { createContext, Dispatch, FC, SetStateAction } from 'react';

import { BudgetStep } from './budget-step';
import { DateStep } from './date-step';
import { GetStartedStep } from './get-started-step';
import { GoalsStep } from './goals-step';
import { MeetingStep } from './meeting-step';
import { ReviewStep } from './review-step';

export type AdvertisementPlan = API.GetAdPlans.Response['items'][number];

export type StepProps = {
  onNext: () => void;
  onPrevious: () => void;
};

type AdvertisementPlanContextType = {
  adPlan: AdvertisementPlan;
  setAdPlan: Dispatch<SetStateAction<AdvertisementPlan>>;
};

export const AdvertisementPlanContext =
  createContext<AdvertisementPlanContextType>(
    {} as AdvertisementPlanContextType
  );

export const AdvertisementPlanProvider: FC<{
  children: React.ReactNode;
  value: AdvertisementPlanContextType;
}> = ({ children, value }) => {
  return (
    <AdvertisementPlanContext.Provider value={value}>
      {children}
    </AdvertisementPlanContext.Provider>
  );
};

export const steps = {
  start: GetStartedStep,
  goals: GoalsStep,
  budget: BudgetStep,
  review: ReviewStep,
  date: DateStep,
  meeting: MeetingStep,
};

export type StepKey = keyof typeof steps;
export const stepOrder = Object.keys(steps) as StepKey[];
