import { Box } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { FC, ReactNode } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { SecondsToTimeString } from '../seconds-to-time-string';

type Props = {
  resourceType: ResourceType;
  locations: Promise<Array<Location>>;
};

export const ResourceTypeDetailsCard = ({ resourceType }: Props) => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const recoverTimeInSeconds = resourceType.recoverTimeInSeconds ?? 0;

  return (
    <Box
      display="grid"
      px={4}
      py={3}
      gap={1.5}
      gridTemplateColumns="1fr 1fr"
      sx={{
        backgroundColor: lightTheme.palette.contrast.white,
        width: {
          xs: '100%',
          md: '50%',
        },
      }}
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius={1}
    >
      <GridRow
        label={t('capacity')}
        value={t('countPersons', 'utils.generic.units', {
          count: resourceType.capacity,
        })}
      />
      {recoverTimeInSeconds > 0 && (
        <GridRow
          label={t('followUpTime')}
          value={<SecondsToTimeString seconds={recoverTimeInSeconds} />}
        />
      )}
    </Box>
  );
};

type GridRowProps = { label: string; value: ReactNode };
const GridRow: FC<GridRowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
          {label}
        </Text>
      </Box>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" variant="medium">
          {value}
        </Text>
      </Box>
    </>
  );
};
