import { RegularExperience } from '@understory-io/experiences-types';
import { CompanyProfile } from '@understory-io/utils-types';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { StorefrontLanguage } from '../../../../i18n/config';
import { getFormDataValue, hasLocalizedValue } from '../utils/form-helpers';
import { translateInput } from '../utils/translate-input';

export const autoSaveHandler = async (
  experienceId: string,
  experience: RegularExperience,
  companyProfile: CompanyProfile,
  formData: FormData,
  activeLanguage: StorefrontLanguage
) => {
  const headline = getFormDataValue(formData.get('headline'));
  const locationIds = getFormDataValue(formData.get('locationIds'));
  const visibility = getFormDataValue(formData.get('visibility'));

  const newHeadline = await translateInput(
    headline,
    experience.headline,
    activeLanguage,
    companyProfile.languages as StorefrontLanguage[],
    experience.autoTranslateEnabled
  );

  const experienceToSave = {
    ...experience,
    headline: hasLocalizedValue(newHeadline) ? newHeadline : undefined,
    locationIds: locationIds.split(',').filter(Boolean),
    visibility: ['public', 'private'].includes(visibility)
      ? (visibility as 'public' | 'private')
      : 'public',
  };

  await saveExperienceDraft(experienceId, experienceToSave);
};
