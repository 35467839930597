import { Box, Stack, styled } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { FC } from 'react';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { StepProps } from './grow-advertisement-flow-steps';

export const GetStartedStep: FC<StepProps> = ({ onNext }) => {
  const { t } = useTranslate('grow.advertisement.getStarted.start');
  const { isSm } = useResponsive();

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledBox>1</StyledBox>
          <Text variant="medium" color={lightTheme.palette.neutral.n400}>
            {t('step1Text')}
          </Text>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledBox>2</StyledBox>
          <Text variant="medium" color={lightTheme.palette.neutral.n400}>
            {t('step2Text')}
          </Text>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledBox>3</StyledBox>
          <Text variant="medium" color={lightTheme.palette.neutral.n400}>
            {t('step3Text')}
          </Text>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledBox>4</StyledBox>
          <Text variant="medium" color={lightTheme.palette.neutral.n400}>
            {t('step4Text')}
          </Text>
        </Stack>
      </Stack>

      <Box
        component="img"
        src="/graphics/growth/grow-advertisement-get-started.png"
        sx={{
          width: '100%',
          height: isSm ? '200px' : '250px',
          borderRadius: 2,
          objectFit: 'cover',
        }}
      />

      <Button variant="primary" size="large" onClick={() => onNext()}>
        {t('getStarted', 'utils.generic')}
      </Button>
    </Stack>
  );
};

const StyledBox = styled(Box)(() => ({
  minWidth: 32,
  minHeight: 32,
  borderRadius: '50%',
  color: lightTheme.palette.neutral.n400,
  border: `1px solid ${lightTheme.palette.neutral.n400}`,
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
