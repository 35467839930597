import { API } from '@understory-io/resource-management-types';
import * as yup from 'yup';

export type UpdateResourceTypeInputs = Omit<
  API.CreateResourceType.Request,
  'companyId'
>;

export const updateResourceTypeSchema = yup.object().shape({
  name: yup.string().required().min(1),
  capacity: yup.number().min(1).required().default(1),
  recoverTimeInSeconds: yup.number().min(0).default(0),
});
