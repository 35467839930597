import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../../Hooks/useTranslate';

interface OnboardingExperienceStartDialogProps {
  open: boolean;
  onClose: () => void;
}

export const OnboardingExperienceStartDialog = ({
  open,
  onClose,
}: OnboardingExperienceStartDialogProps) => {
  const { t } = useTranslate('experience.edit.dialog.onboardingStart');

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      primaryAction={{
        label: t('action'),
        variant: 'primary',
        type: 'button',
        onClick: onClose,
      }}
      maxWidth="xs"
      fullHeight={false}
      actionsInHeader={false}
    />
  );
};
