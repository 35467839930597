import { AddOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';

const BUTTON_SIZE_PX = 32;
const ICON_SIZE_PX = 16;

type AddMediaProps = {
  onClick: () => void;
  hasMedia: boolean;
};

export function AddMedia({ hasMedia, onClick }: AddMediaProps) {
  const { t } = useTranslate('experience.edit.details.media');

  return (
    <Stack
      onClick={onClick}
      sx={{
        aspectRatio: hasMedia ? '1 / 1' : 'unset',
        padding: hasMedia ? 2 : 4,
        height: hasMedia ? 'unset' : 156,
        gap: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1.5,
        backgroundColor: lightTheme.palette.contrast.surface1,
        transition: 'background-color 0.1s ease-in-out',
        cursor: 'pointer',
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: lightTheme.palette.neutral.n200,
        ':hover': {
          borderColor: lightTheme.palette.neutral.n200,
          backgroundColor: lightTheme.palette.contrast.surface2,
          '& > #add-media-button': {
            backgroundColor: lightTheme.palette.contrast.white,
          },
        },
      }}
    >
      <IconButton
        id="add-media-button"
        size="small"
        sx={{
          borderRadius: 1,
          borderColor: lightTheme.palette.neutral.n200,
          width: BUTTON_SIZE_PX,
          height: BUTTON_SIZE_PX,
        }}
      >
        <AddOutlined sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }} />
      </IconButton>
      <Stack>
        <Text
          textAlign="center"
          color={lightTheme.palette.neutral.n400}
          variant="medium"
          fontSize="small"
        >
          {hasMedia ? t('more') : t('title')}
        </Text>
        <Text
          textAlign="center"
          color={lightTheme.palette.neutral.n300}
          variant="normal"
          fontSize="xsmall"
        >
          {t('description')}
        </Text>
      </Stack>
    </Stack>
  );
}
