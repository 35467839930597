import { CheckRounded } from '@mui/icons-material';
import { Link, Stack, TextField } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { ampli } from '../../../../Ampli';
import DialogBase from '../../../../Components/dialog/dialog-base';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';

type ExperienceDraftSectionPublishSuccessDialogProps = {
  open: boolean;
  experienceId: string;
  onClose: () => void;
};

export const ExperienceDraftSectionPublishSuccessDialog = ({
  open,
  experienceId,
  onClose,
}: ExperienceDraftSectionPublishSuccessDialogProps) => {
  const { t } = useTranslate('experience.list.publishSuccessDialog');
  const [copied, setCopied] = useState(false);
  const { companyProfileUrl } = useProfile();

  const embedLink = useMemo(
    () => `${companyProfileUrl()}/experiences/${experienceId}`,
    [companyProfileUrl, experienceId]
  );

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(embedLink);
      setCopied(true);
      ampli.experienceDraftSectionPublishSuccessDialogLinkCopied();
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={t('title')}
      maxWidth="sm"
      fullHeight={false}
      actionsInHeader={false}
      primaryAction={{
        label: t('ok', 'utils.generic'),
        variant: 'secondary',
        onClick: onClose,
      }}
    >
      <Stack gap={1}>
        <Text variant="medium">{t('subtitle')}</Text>
        <Stack direction="row" gap={1}>
          <TextField value={embedLink} fullWidth disabled />
          <Button
            variant={copied ? 'secondary' : 'primary'}
            size="large"
            onClick={handleCopy}
            style={{
              height: 53,
            }}
          >
            {copied ? <CheckRounded /> : 'Copy'}
          </Button>
        </Stack>
        <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
          <Trans
            i18nKey="experience.list.publishSuccessDialog.help"
            components={{
              Link: (
                <Link
                  href="https://help.understory.io/en/articles/9235177-generate-storefront-link-for-your-website"
                  target="_blank"
                  underline="always"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </Text>
      </Stack>
    </DialogBase>
  );
};
