import NiceModal from '@ebay/nice-modal-react';
import {
  AddOutlined,
  LanguageOutlined,
  LocationOnOutlined,
  LockOutlined,
} from '@mui/icons-material';
import { Box, Stack, TextField } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Location } from '@understory-io/utils-types';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { useYupForm } from '../../../Hooks/use-yup-form';
import { StorefrontLanguage } from '../../../i18n/config';
import { CreateLocationDialog } from '../../../Sections/StorefrontSections/location-management/dialogs/create-location-dialog';
import routes from '../../../Utils/routes';
import {
  type BasicExperienceFields as BasicExperienceFieldsType,
  basicExperienceFieldsSchema,
} from '../schemas/basicExperienceFieldsSchema';
import { CustomSelect } from './components/custom-select';
import { PublishErrors } from './edit-experience';

type BasicExperienceFieldsProps = {
  locations: Location[];
  defaultValues: BasicExperienceFieldsType;
  onSubmit: (data: BasicExperienceFieldsType) => void;
  publishErrors?: PublishErrors;
  activeLanguage: StorefrontLanguage;
  isFromOnboarding?: boolean;
};

const DEBOUNCE_SUBMIT_MS = 500;
const ICON_SIZE_PX = 18;

export const BasicExperienceFields = ({
  locations,
  defaultValues,
  onSubmit,
  publishErrors,
  activeLanguage,
  isFromOnboarding,
}: BasicExperienceFieldsProps) => {
  const { t } = useTranslation();
  const formMethods = useYupForm({
    schema: basicExperienceFieldsSchema,
    defaultValues,
    mode: 'onChange',
  });
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: {
      errors: { visibility: visibilityError },
    },
    trigger,
  } = formMethods;

  const { field: visibility } = useController({
    name: `visibility`,
    control,
    rules: { required: t('experience.edit.basic.visibility.required') },
  });

  const { field: locationIds } = useController({
    name: `locationIds`,
    control,
    rules: { required: 'experience.edit.basic.visibility.required' },
  });

  const handleVisibilityChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    visibility.onChange(newValue);
  };

  const handleCreateLocation = async () => {
    setIsLocationOpen(false);
    const locationId = await NiceModal.show(CreateLocationDialog);
    if (locationId && typeof locationId === 'string') {
      locationIds.onChange([...locationIds.value, locationId]);
    }
  };

  useEffect(() => {
    // Debounce submission of form
    const debouncedSubmit = (delay: number) =>
      debounce(
        // We still want to submit on handleSubmit's onInvalid handler
        handleSubmit(onSubmit, () => onSubmit(getValues())),
        delay
      );
    // Subscribe to form changes
    const subscription = watch((_, { name }) => {
      // We handle headline submission separately
      if (name === 'headline') return;

      return debouncedSubmit(DEBOUNCE_SUBMIT_MS)();
    });

    return () => subscription.unsubscribe();
  }, [getValues, handleSubmit, onSubmit, trigger, watch]);

  const isPublishedHeadlineError = useMemo(() => {
    return (
      !!publishErrors?.general?.find((error) => error.key === 'headline') ||
      !!publishErrors?.[activeLanguage]?.find(
        (error) => error.key === 'headline'
      )
    );
  }, [publishErrors, activeLanguage]);

  // Trigger validation on mount
  useEffect(() => {
    if (
      publishErrors?.general?.find((error) => error.key === 'headline') ||
      publishErrors?.[activeLanguage]?.find((error) => error.key === 'headline')
    ) {
      trigger();
    }
  }, [activeLanguage, publishErrors, trigger]);

  const visibilityOptions = {
    public: {
      value: 'public',
      label: t('experience.card.visibility.public'),
      icon: (
        <LanguageOutlined sx={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }} />
      ),
    },
    private: {
      value: 'private',
      label: t('experience.card.visibility.private'),
      icon: <LockOutlined sx={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }} />,
    },
  };

  const locationFooterOptions = [
    {
      label: t('location.dialog.create.title'),
      onClick: handleCreateLocation,
      icon: <AddOutlined sx={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }} />,
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" noValidate sx={{ gap: 4 }}>
        <TitleInput
          onBlur={() => onSubmit(getValues())}
          error={isPublishedHeadlineError}
        />
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            alignItems: 'flex-start',
          }}
        >
          {!isFromOnboarding && (
            <CustomSelect
              selectedValue={visibility.value}
              options={Object.values(visibilityOptions)}
              onChange={handleVisibilityChange}
              error={!!visibilityError}
            />
          )}
          <CustomSelect
            multiple
            selectedValue={locationIds.value}
            open={isLocationOpen}
            onClose={(newValue) => {
              if (Array.isArray(newValue)) {
                locationIds.onChange(newValue);
              } else {
                locationIds.onChange([]);
              }
              setIsLocationOpen(false);
            }}
            onOpen={() => setIsLocationOpen(true)}
            icon={
              <LocationOnOutlined
                sx={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }}
              />
            }
            options={locations.map(({ locationId, locationName }) => ({
              value: locationId,
              label: locationName,
              icon: (
                <LocationOnOutlined
                  sx={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }}
                />
              ),
            }))}
            footerOptions={locationFooterOptions}
            emptyLabel={t(
              'location.dialog.delete.replaceLocation.selectLocation'
            )}
            error={
              !!publishErrors?.general?.find(
                (error) => error.key === 'locationIds'
              )
            }
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

interface TitleInputProps {
  onBlur: () => void;
  error: boolean;
}

const TitleInput = ({ onBlur, error }: TitleInputProps) => {
  const { t } = useTranslation();

  const {
    control,
    formState: {
      errors: { headline: headlineError },
    },
  } = useFormContext<BasicExperienceFieldsType>();

  const { field: headline } = useController({
    name: 'headline',
    control,
  });

  // Prevent onBlur being triggered when deep-linking to a
  // dialog when no title has been added.
  // ':experienceId' is used as it checks for a pattern.
  const isMatch = useMatch(
    routes.experience.details(':experienceId').edit.index
  );

  const [inputWidth, setInputWidth] = useState(0);

  const inputRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputValueWidth = ref.current?.offsetWidth ?? 0;
    setInputWidth(inputValueWidth);
  }, [headline.value]);

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        ref={inputRef}
        multiline
        fullWidth
        value={headline.value}
        onChange={(e) => headline.onChange(e.target.value.replaceAll('\n', ''))}
        onBlur={onBlur}
        autoFocus={!!isMatch && !headline.value}
        variant="standard"
        placeholder={t('experience.edit.basic.headline.placeholder')}
        sx={{
          '& .MuiInputBase-formControl:before': {
            borderColor: lightTheme.palette.neutral.n100,
          },
          '& .MuiInputBase-formControl:after': {
            borderColor: error
              ? lightTheme.palette.error.e300
              : lightTheme.palette.neutral.n100,
          },
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            paddingBottom: 0.75,
            fontSize: 44,
            lineHeight: '100%',
          },
        }}
        error={error && !!headlineError}
      />
      {error && !!headlineError?.message && (
        <Text
          fontSize="xsmall"
          color={lightTheme.palette.error.e300}
          style={{ display: 'block', marginTop: 8 }}
        >
          {t(headlineError.message, { count: 50 })}
        </Text>
      )}
      <Stack
        sx={{
          pointerEvents: 'none',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          height: '100%',
          width: '100%',
          display: 'inline-block',
          maxWidth: '100%',
          '&:after': {
            content: "''",
            display: 'block',
            width: inputWidth,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: error
              ? lightTheme.palette.error.e300
              : lightTheme.palette.neutral.n200,

            height: inputRef.current?.offsetHeight,
          },
        }}
      >
        <Stack
          ref={ref}
          sx={{
            position: 'absolute',
            visibility: 'hidden',
            fontSize: 44,
            maxWidth: '100%',
            whiteSpace: 'pre',
          }}
        >
          {headline.value || t('experience.edit.basic.headline.placeholder')}
        </Stack>
      </Stack>
    </Box>
  );
};
