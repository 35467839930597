import { captureException } from '@sentry/react';

import { getTimeZoneByCoordinates } from '../../../Api';
import { AddressSearchResult } from './use-location-search';

/**
 * Transform Place details to an address object
 * that is usable in forms
 */
export const getPlaceAddress = async (
  placeDetails: google.maps.places.PlaceResult
) => {
  const result: AddressSearchResult = {
    street: '',
    city: '',
    postCode: '',
    state: '',
    country: '',
  };

  try {
    const geoCoordinates = placeDetails.geometry?.location;
    if (geoCoordinates) {
      const latitude = geoCoordinates.lat();
      const longitude = geoCoordinates.lng();

      result.geoCoordinates = {
        latitude,
        longitude,
        source: 'google',
      };

      // Get timezone
      try {
        const timeZone = await getTimeZoneByCoordinates({
          latitude,
          longitude,
        });
        result.timezone = timeZone;
      } catch (error) {
        captureException(error);
        result.timezone = undefined;
      }
    }

    const addressComponents = placeDetails.address_components;
    if (!addressComponents) return result;

    const country =
      addressComponents.find((component) => component.types.includes('country'))
        ?.short_name ?? 'DK';

    result.country = country;
    result.street = getStreetAddressByCountry(addressComponents, country);

    for (const component of addressComponents) {
      const types = component.types;

      if (types.includes('locality') || types.includes('postal_town')) {
        result.city = component.long_name;
      } else if (types.includes('postal_code')) {
        result.postCode = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        result.state = component.long_name;
      }
    }

    return result;
  } catch (error) {
    captureException(error);
    return result;
  }
};

const getStreetAddressByCountry = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  country: string
) => {
  const road = addressComponents.find((component) =>
    component.types.includes('route')
  )?.short_name;

  const streetNumber = addressComponents.find((component) =>
    component.types.includes('street_number')
  )?.long_name;

  const floor = addressComponents.find((component) =>
    component.types.includes('subpremise')
  )?.long_name;

  // Level 5, 123 Main St
  if (['GB'].includes(country)) {
    const streetAddress = [streetNumber, road].filter(Boolean).join(' ');

    return [floor, streetAddress].filter(Boolean).join(', ');
  }

  // 123 Main St #5
  if (['US'].includes(country)) {
    const streetAddress = [streetNumber, road].filter(Boolean).join(' ');

    return [streetAddress, floor].filter(Boolean).join(' #');
  }

  // Main St 123, 5
  // ['SE', 'DK', 'NO', 'NL', 'GL']
  const streetAddress = [road, streetNumber].filter(Boolean).join(' ');

  return [streetAddress, floor].filter(Boolean).join(', ');
};
