import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const ContentCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        height: '125px',
        backgroundColor: lightTheme.palette.contrast.surface2,
      }}
    >
      {children}
    </Stack>
  );
};
