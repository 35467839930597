import { Stack, TextField } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, useSubmit } from 'react-router';

import { ampli } from '../../../../Ampli';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateResourceInputs } from './create-resource-inputs';

type Props = {
  resourceTypeId: string;
};

export const CreateResourceForm: FC<Props> = ({ resourceTypeId }) => {
  const { t } = useTranslate('resourceManagement.resource');

  const formMethods = useForm<CreateResourceInputs>({
    defaultValues: {
      resourceTypeId,
      label: '',
      available: true,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = async (data: CreateResourceInputs) => {
    submit(data, { method: 'post', encType: 'application/json' });
    ampli.resourceCreateFlowCompleted({
      resource_type_id: resourceTypeId,
      resource_status: data.available ? 'available' : 'out-of-order',
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={Form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        gap={4}
        pt={2}
      >
        <TextField
          autoFocus
          {...register('label', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="text"
          label={t('createNew.label')}
          error={!!errors.label?.message}
          helperText={errors.label?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitting}
        >
          {t('save', 'fullPageForm.generic')}
        </Button>
      </Stack>
    </FormProvider>
  );
};
