import { FC, useCallback, useState } from 'react';

import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { DEFAULT_PLAN, STEP_COMPLETION_EVENTS } from '../constants';
import {
  AdvertisementPlan,
  AdvertisementPlanProvider,
  StepKey,
  stepOrder,
  steps,
} from './steps/grow-advertisement-flow-steps';

type GrowAdvertisementFlowProps = {
  open: boolean;
  onClose: () => void;
};

export const GrowAdvertisementFlow: FC<GrowAdvertisementFlowProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslate('grow.advertisement.getStarted');
  const [currentStepKey, setCurrentStepKey] = useState<StepKey>('start');
  const [adPlan, setAdPlan] = useState<AdvertisementPlan>(DEFAULT_PLAN);

  const handlePrevious = useCallback(() => {
    const currentIndex = stepOrder.indexOf(currentStepKey);
    if (currentIndex > 0) {
      setCurrentStepKey(stepOrder[currentIndex - 1]);
    }
  }, [currentStepKey]);

  const handleNext = useCallback(() => {
    const currentIndex = stepOrder.indexOf(currentStepKey);
    if (currentIndex < stepOrder.length - 1) {
      setCurrentStepKey(stepOrder[currentIndex + 1]);
    } else {
      onClose();
    }

    STEP_COMPLETION_EVENTS[currentStepKey]();
  }, [currentStepKey, onClose]);

  const currentStep = steps[currentStepKey];
  const StepComponent = currentStep;

  return (
    <DialogBase
      title={t(`${currentStepKey}.title`)}
      description={t(`${currentStepKey}.description`)}
      open={open}
      onClose={onClose}
      maxWidth={currentStepKey === 'review' ? 'md' : 'sm'}
    >
      <AdvertisementPlanProvider value={{ adPlan, setAdPlan }}>
        <StepComponent onNext={handleNext} onPrevious={handlePrevious} />
      </AdvertisementPlanProvider>
    </DialogBase>
  );
};
