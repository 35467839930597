import { CheckRounded } from '@mui/icons-material';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';

import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../Utils/helpers';
import { growAnimation } from '../../Opportunities/ui/growth-opportunities-section';
import { slideUpAnimation } from '../../Opportunities/ui/growth-opportunities-section';
import { SERVICE_FEE_PRICE_MAP } from './constants';

export const GrowAdvertisementGetStarted = ({
  handleOpenFlow,
}: {
  handleOpenFlow: () => void;
}) => {
  const { t } = useTranslate('grow.advertisement');
  const { defaultCurrency } = useProfile();
  const { dateFnsLocale } = useLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          minHeight: { xs: 'auto', md: '500px' },
          width: '100%',
          gap: { xs: 4, md: 0 },
        }}
      >
        <motion.div
          {...growAnimation}
          style={{
            display: isMobile ? 'none' : 'flex',
            width: '40%',
            minHeight: 'auto',
          }}
        >
          <Stack
            sx={{
              background: `url('/graphics/growth/grow-advertisement.png') no-repeat`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width: '100%',
            }}
          />
        </motion.div>
        <motion.div
          {...slideUpAnimation}
          style={{
            display: 'flex',
            width: isMobile ? '100%' : '60%',
          }}
        >
          <Stack
            sx={{
              paddingX: { xs: 2, sm: 5 },
              paddingY: 2,
              gap: 3,
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Stack gap={2} width="100%">
              <Text fontSize={'h5'}>{t('title')}</Text>
              <Text>{t('description')}</Text>
            </Stack>
            <Stack gap={1}>
              <Stack direction={'row'} gap={0.5} alignItems={'flex-end'}>
                <Text
                  fontSize="xlarge"
                  variant="medium"
                  color={lightTheme.palette.neutral.n500}
                  style={{ lineHeight: 1 }}
                >
                  {getFormattedPrice(
                    SERVICE_FEE_PRICE_MAP[
                      defaultCurrency as keyof typeof SERVICE_FEE_PRICE_MAP
                    ],
                    defaultCurrency,
                    dateFnsLocale.code
                  )}
                </Text>
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {t('monthlyText')}
                </Text>
              </Stack>
              <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
                {t('priceDescription')}
              </Text>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                p: { xs: 2, md: 3 },
                background: '#ffffff80',
                borderRadius: 2,
              }}
            >
              <Stack gap={{ xs: 2, md: 1 }}>
                <Stack
                  direction={'row'}
                  gap={1}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Text color={lightTheme.palette.neutral.n400}>
                    {t('handlingAdvertising')}
                  </Text>
                  <CheckRounded />
                </Stack>
                <Stack
                  direction={'row'}
                  gap={1}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Text color={lightTheme.palette.neutral.n400}>
                    {t('aiTargetingFeature')}
                  </Text>
                  <CheckRounded />
                </Stack>
                <Stack
                  direction={'row'}
                  gap={1}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Text color={lightTheme.palette.neutral.n400}>
                    {t('experienceCreatorsFeature')}
                  </Text>
                  <CheckRounded />
                </Stack>
                <Stack
                  direction={'row'}
                  gap={1}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Text color={lightTheme.palette.neutral.n400}>
                    {t('quickSetupFeature')}
                  </Text>
                  <CheckRounded />
                </Stack>
              </Stack>
            </Stack>
            <Button
              variant="primary"
              size="large"
              onClick={handleOpenFlow}
              style={{
                padding: '0 60px',
                width: isMobile ? '100%' : 'auto',
              }}
            >
              {t('tryItOut')}
            </Button>
          </Stack>
        </motion.div>
      </Stack>
    </>
  );
};
