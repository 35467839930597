import * as yup from 'yup';

const imageType = ['image', 'video'] as const;
type ImageType = (typeof imageType)[number];

// We need to support old media that still has holdbar as provider
const imageProvider = ['understory', 'youtube', 'holdbar'] as const;
type ImageProvider = (typeof imageProvider)[number];

export const imageSchema = yup.object().shape({
  preview: yup.string().typeError('utils.validationErrors.invalidString'),
  mimeType: yup.string().typeError('utils.validationErrors.invalidString'),
  type: yup
    .mixed<ImageType>()
    .oneOf([...imageType], 'utils.validationErrors.invalidEnum')
    .default('image'),
  provider: yup
    .mixed<ImageProvider>()
    .oneOf([...imageProvider], 'utils.validationErrors.invalidEnum')
    .default('understory'),
  playback: yup
    .object()
    .shape({
      hsl: yup.string().url('utils.validationErrors.invalidUrl'),
      dash: yup.string().url('utils.validationErrors.invalidUrl'),
    })
    .optional(),
  url: yup
    .string()
    .optional()
    .url('utils.validationErrors.invalidUrl')
    .typeError('utils.validationErrors.invalidString'),
});
export type Image = yup.InferType<typeof imageSchema>;

export const mediaSchema = yup.object().shape({
  cover: yup.array().of(imageSchema).optional(),
  primaryImage: imageSchema.optional(),
});

export type Media = yup.InferType<typeof mediaSchema>;
