import { Stack, StackProps } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router';

import { mergeSx } from '../../../../Utils/merge-sx';

type Props = {
  /** If true, the setting is in an error state */
  hasError?: boolean;
} & StackProps &
  Partial<LinkProps>;

/**
 * Root wrapper for the setting boxes
 *
 * If `to` is provided, the entire component will be a link.
 */
export const FullFormPageDetailsSettingRoot = forwardRef<HTMLDivElement, Props>(
  ({ sx, to, hasError, ...props }, ref) => {
    const isLink = Boolean(to);

    return (
      <Stack
        ref={ref}
        component={isLink ? Link : 'div'}
        to={to}
        sx={mergeSx(
          {
            paddingY: 1.5,
            paddingX: 2,
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: 'solid',
            gap: { xs: 0.5, sm: 1.5 },
            backgroundColor: lightTheme.palette.contrast.white,
            borderColor: lightTheme.palette.neutral.n100,
            color: lightTheme.palette.contrast.black,
          },
          isLink && {
            transition: 'background-color 0.1s',
            ':hover': { backgroundColor: lightTheme.palette.contrast.surface1 },
            ':focus-visible': {
              outline: `1px solid ${lightTheme.palette.neutral.n500}`,
            },
          },
          hasError && {
            backgroundColor: lightTheme.palette.error.e100,
            borderColor: lightTheme.palette.error.e300,
            color: lightTheme.palette.error.e300,
          },
          sx
        )}
        {...props}
      />
    );
  }
);

FullFormPageDetailsSettingRoot.displayName = 'FullFormPageDetailsSettingRoot';
