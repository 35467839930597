import styled from '@emotion/styled';
import { CircularProgress, Skeleton, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { fadeInAnimation, flyInFromRightAnimation } from '../animations';
import { useDashboardPageContext } from '../use-dashboard-page-context';
import { DashboardHeaderActions } from './dashboard-header-actions/dashboard-header-actions';

export const DashboardHeader = () => {
  const { t } = useTranslate('dashboard');
  const { isSm } = useResponsive();

  const { isLoadingMetrics } = useDashboardPageContext();
  const {
    onboardingCompleted,
    company: { data: company },
  } = useProfile();

  const isLoadingCompany = !company;

  const title = isLoadingCompany ? (
    <Skeleton variant="rounded" width={200} height={35} />
  ) : onboardingCompleted ? (
    t('title')
  ) : (
    t('title', 'onboardingSetup')
  );

  const showMetricsLoader = isLoadingMetrics && !isLoadingCompany && isSm;

  return (
    <StyledStack>
      <Stack gap={2} direction={'row'} alignItems={'center'}>
        <motion.div {...fadeInAnimation}>
          <Text fontSize={'h5'} style={{ lineHeight: 1 }}>
            {title}
          </Text>
        </motion.div>
        {showMetricsLoader && onboardingCompleted && (
          <CircularProgress size={'1.5em'} />
        )}
      </Stack>
      {onboardingCompleted && (
        <motion.div {...flyInFromRightAnimation}>
          <DashboardHeaderActions />
        </motion.div>
      )}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
