import { FormControlLabel, Switch } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';

import { ampli } from '../../../../Ampli';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CustomizeStorefrontFormData } from '../customize-storefront';

export function ToggleBranding() {
  const { t } = useTranslate('settings.features.platformBranding');

  const { control } = useFormContext<CustomizeStorefrontFormData>();

  return (
    <Controller
      name="features.platformBranding.enabled"
      control={control}
      defaultValue={true}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          onChange={(_, enabled) => {
            field.onChange(enabled);
            enabled
              ? ampli.widgetBrandingToggledOn()
              : ampli.widgetBrandingToggledOff();
          }}
          label={<Text fontSize="small">{t('descriptionTitle')}</Text>}
          control={<Switch checked={field.value} />}
        />
      )}
    />
  );
}
