import { setDefaultOptions } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isBackofficeLanguage } from '../../i18n/config';

export const useApplyLocale = (locale: Locale) => {
  const { i18n } = useTranslation();

  // Set the default locale for date-fns
  useEffect(() => {
    setDefaultOptions({ locale });
  }, [locale]);

  // This is required to "strip" away the region part of the language code
  // when the user first visits the app. A Danish user might have da-DK, but
  // we only support 'da'. This will ensure that i18n.langauge will always
  // return a supported language - since we have a lot of dependencies on this.
  useEffect(() => {
    // Language is supported, no need to change
    if (isBackofficeLanguage(i18n.language)) return;
    // Sets the language to the first language in the priority list
    // For instance, when Backoffice is set to Danish, this would be
    // ['da', 'en-GB'] - meaning we strip away the region part, as it
    // is not supported in the rest of the app.
    i18n.changeLanguage(i18n.languages[0]);
  }, [i18n]);
};
