import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightTheme } from '@understory-io/pixel';
import { motion } from 'framer-motion';

import { STEP_ORDER } from '../../config';
import { useOnboardingFlow } from '../../onboarding-context';

export const StepLayoutIndicator = () => {
  const { currentStepKey } = useOnboardingFlow();
  const currentStep = STEP_ORDER.indexOf(currentStepKey) + 1;

  return (
    <StepIndicatorContainer>
      {[1, 2, 3].map((step) => (
        <StepBar key={step}>
          <StepBarFill
            initial={
              step === currentStep
                ? { scaleX: 0 }
                : { scaleX: step < currentStep ? 1 : 0 }
            }
            animate={{
              scaleX: step <= currentStep ? 1 : 0,
            }}
            transition={{
              duration: step === currentStep ? 0.4 : 0,
              ease: 'easeInOut',
            }}
          />
        </StepBar>
      ))}
    </StepIndicatorContainer>
  );
};

const StepIndicatorContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '8px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StepBar = styled(motion.div)(({ theme }) => ({
  width: '60px',
  height: '4px',
  borderRadius: '16px',
  backgroundColor: lightTheme.palette.neutral.n100,
  [theme.breakpoints.down('sm')]: {
    width: '33%',
  },
}));

const StepBarFill = styled(motion.div)({
  width: '100%',
  height: '100%',
  backgroundColor: lightTheme.palette.action.a300,
  transformOrigin: 'left',
  borderRadius: '16px',
});
