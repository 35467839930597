import { RegularExperience } from '@understory-io/experiences-types';

import { publishExperienceDraft } from '../../../../Api/Experience';
import { experienceSchema } from '../../schemas';

export const publishHandler = async (
  experienceId: string,
  experience: RegularExperience
) => {
  // Validate experience
  experienceSchema.validateSync(experience, { abortEarly: false });

  await publishExperienceDraft(experienceId);
};
