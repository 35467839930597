import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLanguages } from '../../../../../../../Hooks/locales/use-languages';
import { useStorefrontLanguages } from '../../../../../../../Hooks/translatable/use-storefront-languages';
import { StorefrontLanguage } from '../../../../../../../i18n/config';
import { trackEventFlowLanguageSelected } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLanguageSelected';
import { trackEventFlowLanguageSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLanguageSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

const NO_SELECTION = 'no-selection';

export const EventUpsertDetailsFormLanguageSelect = () => {
  const { t } = useTranslation();
  const { watch, register, setValue } = useFormContext();

  const selectedLanguages = watch('languages') || [];

  const { updateEventField } = useEventUpsert();

  const { languageOption } = useLanguages();
  const storefrontLanguages = useStorefrontLanguages();

  const label =
    selectedLanguages?.length > 1
      ? t('eventUpsert.flow.details.form.language.label.plural')
      : t('eventUpsert.flow.details.form.language.label.singular');

  if (storefrontLanguages?.length === 0) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="details-form-language-select-label" shrink>
        {label}
      </InputLabel>
      <Select
        {...register('languages')}
        labelId="details-form-language-select-label"
        multiple
        value={Array.isArray(selectedLanguages) ? selectedLanguages : []}
        input={<OutlinedInput notched label={label} />}
        onChange={(e) => {
          const selection = e.target.value as string[];
          const newSelection = selection.includes(NO_SELECTION)
            ? []
            : selection.filter(Boolean);
          setValue('languages', newSelection);
          updateEventField('languages', newSelection);
          trackEventFlowLanguageSelected(newSelection);
        }}
        onOpen={trackEventFlowLanguageSelectionOpened}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={(selected) =>
          !selected?.length
            ? t('eventUpsert.flow.details.form.language.noLanguage')
            : selected
                .map((language: string) => {
                  const { label, country } = languageOption(
                    language as StorefrontLanguage
                  );
                  return country ? `${label} (${country})` : label;
                })
                .join(', ')
        }
      >
        <MenuItem value={NO_SELECTION}>
          <StyledText fontSize="small">
            {t('eventUpsert.flow.details.form.language.noLanguage')}
          </StyledText>
        </MenuItem>
        <Divider variant="middle" />
        {storefrontLanguages?.map((language) => (
          <MenuItem key={language.locale} value={language.locale}>
            <Stack direction="row" alignItems="center" gap={1}>
              <StyledCheckbox
                checked={selectedLanguages?.includes(language.locale)}
              />
              <StyledText fontSize="small" textTransform="capitalize">
                {language.country
                  ? `${language.label} (${language.country})`
                  : language.label}
              </StyledText>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`;

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
