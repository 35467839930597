import {
  AddRounded,
  HelpOutlineRounded,
  KeyboardArrowDown,
  RemoveRounded,
} from '@mui/icons-material';
import {
  Box,
  BoxProps,
  capitalize,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { BookingAvailabilityManager } from '@understory-io/availability';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { EventResourceManagement } from '@understory-io/utils-types';
import { FC, ReactNode, useMemo, useState } from 'react';

import { TCreateBookingPayload } from '../../Hooks/useBookings';
import { useProducts } from '../../Hooks/useProducts';
import { useProfile } from '../../Hooks/useProfile';
import { TranslateFunction, useTranslate } from '../../Hooks/useTranslate';
import { cleanupGuestSelection, getGuestCount } from '../../Utils/eventHelpers';
import { SimpleSkeleton } from '../SimpleSkeleton/SimpleSkeleton';

export const formatMoney =
  (t: TranslateFunction, showZero = false) =>
  ({
    value,
    nativeCurrency = 'dkk',
  }: {
    value: number;
    nativeCurrency: string;
  }) => {
    if (value === undefined) return '';
    if ((value === 0 || value === null) && !showZero) return t('free');
    return value.toLocaleString(nativeCurrency, {
      style: 'currency',
      currency: nativeCurrency,
      maximumFractionDigits: 0,
    });
  };

export const getUnitCount = (count: number, seatProps?: object | null) => {
  const { max = 1, type = 'single' } = seatProps ?? ({} as any);
  const _m = type === 'couple' ? 2 : max === null ? 1 : max;
  return Math.floor(count / _m) + (count % _m > 0 ? 1 : 0);
};

type Props = {
  experienceId: string;
  required?: boolean;
  onChange: (value: { [k: string]: number }) => void;
  errorMessage?: string;
  defaultValue?: TCreateBookingPayload['items'];
  originalValue?: TCreateBookingPayload['items'];
  maxGuestCount: number;
  resourceManagement?: Pick<
    EventResourceManagement,
    'resourceAvailability' | 'ticketsRequiringResources'
  >;
};

export const VariantSelect: FC<Props> = ({
  experienceId,
  required = false,
  maxGuestCount,
  onChange,
  errorMessage,
  defaultValue,
  originalValue,
  resourceManagement,
}) => {
  const { t } = useTranslate('utils.generic');

  const { products, variants } = useProducts(experienceId);

  const [guests, setGuests] = useState<{ [k: string]: number }>(
    defaultValue ?? {}
  );

  const [showPopover, setShowPopover] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement>();

  const { defaultCurrency } = useProfile();

  const totalAmount = useMemo(() => {
    return Object.entries(guests as { [k: string]: number }).reduce(
      (acc, [id, count]) => {
        const { price = 0 } = products?.find((el) => el.id === id) ?? {};
        return acc + count * price;
      },
      0
    );
  }, [guests, products]);

  const paidAmount = useMemo(() => {
    return Object.entries(
      (originalValue as { [k: string]: number }) ?? {}
    ).reduce((acc, [id, count]) => {
      const { price = 0 } = products?.find((el) => el.id === id) ?? {};
      return acc + count * price;
    }, 0);
  }, [defaultValue, products]);

  const handleSubmit = () => {
    setShowPopover(false);
    onChange(guests);
  };

  const currentGuestCount = getGuestCount(guests);

  const minimumRequiredSeats = Math.max(
    ...variants.map((variant) => variant.seatProps.min ?? 0)
  );

  const bookingManager = useMemo(
    () =>
      new BookingAvailabilityManager({
        maxGuestsOnBooking: maxGuestCount,
        resourceManagement: resourceManagement,
      }).addTicketsUnchecked(guests),
    [guests, maxGuestCount, resourceManagement]
  );

  const handleStep =
    (key: string) =>
    (direction: 'up' | 'down', step = 1) =>
    () => {
      if (
        direction === 'up' &&
        !bookingManager.canAddTickets({ [key]: step })
      ) {
        return;
      }

      setGuests((currentGuests) => {
        const currentValue = currentGuests[key] ?? 0;
        const newValue = currentValue + (direction === 'up' ? step : -step);

        const nextGuests = {
          ...currentGuests,
          [key]: Math.max(0, newValue),
        };

        return cleanupGuestSelection(nextGuests, products);
      });
    };

  const handleClickGuests = () => {
    setShowPopover(true);
  };

  return (
    <Box position={'relative'} width={'100%'}>
      <Box
        component={FormControl}
        width={'100%'}
        onClick={handleClickGuests}
        error={!!errorMessage}
        sx={{
          cursor: 'pointer',
          '&:hover fieldset': { borderColor: '#1A2027' },
        }}
      >
        <InputLabel
          shrink
          required={required}
          sx={{ background: 'white', textTransform: 'capitalize' }}
          htmlFor="time"
        >
          {t('guests')}
        </InputLabel>
        <Box
          sx={{
            '& > *': {
              justifyContent: 'space-between',
              flexGrow: 1,
            },
          }}
          component={Button}
          ref={(ref) => setAnchor(ref as HTMLElement)}
          size="large"
          variant="secondary"
          rightIcon={<KeyboardArrowDown htmlColor="#636366" />}
        >
          <Stack
            sx={{
              gap: 1,
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text>
              {currentGuestCount > 0 ? currentGuestCount : t('selectGuests')}
            </Text>
            {currentGuestCount > 0 && (
              <>
                <Text>
                  {t(`guest`)}
                  {currentGuestCount > 1 && t('plural')}
                </Text>
                <PriceDisplay
                  defaultCurrency={defaultCurrency}
                  paidAmount={paidAmount}
                  totalAmount={totalAmount}
                  originalValue={originalValue}
                />
              </>
            )}
          </Stack>
        </Box>

        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </Box>
      <Popover
        open={showPopover}
        onClose={handleSubmit}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack spacing={2} p={3} width={anchor?.clientWidth ?? 'auto'}>
          {variants?.map((el) => {
            return (
              <Box key={el.id}>
                <ProductRow
                  ticketId={el.id}
                  label={
                    el.name ??
                    el.seatProps?.customName ??
                    capitalize(t(el.seatProps?.type ?? ''))
                  }
                  price={formatMoney(t)({
                    value: el.price,
                    nativeCurrency: el.currency,
                  })}
                  step={el.seatProps?.type === 'couple' ? 2 : 1}
                  onStep={handleStep(el.id)}
                  bookingManager={bookingManager}
                />

                {Boolean(guests?.[el.id]) &&
                  el.addons?.map((ad) => (
                    <ProductRow
                      key={ad.id}
                      ticketId={ad.id}
                      label={ad.name}
                      explanation={ad.explanation}
                      price={formatMoney(t)({
                        value: ad.price,
                        nativeCurrency: ad.currency,
                      })}
                      onStep={handleStep(ad.id)}
                      p={1.5}
                      mt={1}
                      borderRadius={1}
                      border={'1px dashed #C7C7CC'}
                      bookingManager={bookingManager}
                    />
                  ))}
              </Box>
            );
          }) ?? <SimpleSkeleton />}
          {currentGuestCount > 0 &&
            minimumRequiredSeats > currentGuestCount && (
              <Text
                textAlign="center"
                fontSize="small"
                color={lightTheme.palette.error.e200}
              >
                {t(
                  'minSeatsBookedSlots',
                  'eventUpsert.flow.details.form.errors',
                  {
                    totalBookedSlots: minimumRequiredSeats,
                  }
                )}
              </Text>
            )}
        </Stack>
      </Popover>
    </Box>
  );
};

interface DisplayPriceProps {
  totalAmount: number;
  paidAmount: number;
  originalValue?: { [k: string]: number };
  defaultCurrency: string;
}

const PriceDisplay = ({
  totalAmount,
  paidAmount,
  originalValue,
  defaultCurrency,
}: DisplayPriceProps) => {
  const { t } = useTranslate('utils.generic');

  return (
    <>
      -
      <Box
        component="span"
        sx={{
          textDecoration:
            totalAmount !== paidAmount && originalValue
              ? 'line-through'
              : 'none',
        }}
      >
        <Text>
          {formatMoney(t)({
            value: totalAmount,
            nativeCurrency: defaultCurrency,
          })}
        </Text>
      </Box>
      {totalAmount !== paidAmount && originalValue && (
        <Text>
          {formatMoney(t)({
            value: totalAmount - paidAmount,
            nativeCurrency: defaultCurrency,
          })}
        </Text>
      )}
    </>
  );
};

const ProductRow = ({
  ticketId,
  label,
  price,
  explanation,
  step = 1,
  onStep,
  bookingManager,
  ...props
}: BoxProps & {
  ticketId: string;
  label: string;
  explanation?: string;
  step?: number;
  onStep: (direction: 'up' | 'down', step?: number) => () => void;
  price: string;
  bookingManager: BookingAvailabilityManager;
}) => {
  const count = bookingManager.getCountOfTicket(ticketId);

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      {...props}
    >
      <Box>
        <Typography
          variant={'h5'}
          fontSize={'1.1em'}
          display="flex"
          alignItems="center"
        >
          {label}
          {explanation && (
            <Tooltip title={explanation}>
              <HelpOutlineRounded
                sx={{ pl: 0.5 }}
                fontSize="medium"
                htmlColor="#8E8E93"
              />
            </Tooltip>
          )}
        </Typography>
        <Typography variant={'body2'} color={'grey.700'}>
          {price}
        </Typography>
      </Box>
      <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
        <IconButton
          size={'small'}
          disabled={count === 0}
          onClick={onStep('down', step)}
          sx={{ border: '1px solid', borderColor: '#C7C7CC' }}
        >
          <RemoveRounded fontSize={'small'} />
        </IconButton>
        <Typography variant={'h4'} textAlign={'center'} width={'40px'}>
          {count}
        </Typography>
        <IconButton
          size={'small'}
          disabled={!bookingManager.canAddTickets({ [ticketId]: step })}
          onClick={onStep('up', step)}
          sx={{ border: '1px solid', borderColor: '#C7C7CC' }}
        >
          <AddRounded fontSize={'small'} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export const Row = ({
  left,
  right,
  leftProps = {},
  rightProps = {},
  ...props
}: BoxProps<
  any,
  {
    left: string | ReactNode;
    right?: string | ReactNode;
    leftProps?: TypographyProps;
    rightProps?: TypographyProps;
  }
>) => (
  <Box display={'flex'} justifyContent={'space-between'} {...props}>
    {typeof left === 'string' ? (
      <Typography {...leftProps}>{left}</Typography>
    ) : (
      left
    )}
    {typeof right === 'string' ? (
      <Typography {...rightProps}>{right}</Typography>
    ) : (
      right
    )}
  </Box>
);
