import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';

import { ContextMenu } from '../../../../../../Components/context-menu/context-menu';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { MonoText } from './mono-text';

export type Key = {
  name: string;
  clientId: string;
  createdAt: string;
  scopes: string[];
};

export const KeyItem = ({
  integrationKey,
  clientIdLabel,
  loading,
  onRevoke,
  onUpdate,
}: {
  integrationKey: Key;
  clientIdLabel: string;
  loading: boolean;
  onRevoke: (integrationKey: Key) => void;
  onUpdate: (integrationKey: Key) => void;
}) => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.dialog.main.button'
  );

  const handleRevoke = () => {
    onRevoke(integrationKey);
  };

  const handleUpdate = () => {
    onUpdate(integrationKey);
  };

  return (
    <Stack
      sx={{
        paddingTop: 2,
        paddingLeft: 3,
        paddingRight: 2,
        paddingBottom: 2,
        borderRadius: lightTheme.tokens.radii[3],
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderColor: lightTheme.palette.neutral.n100,
        borderWidth: 1,
        borderStyle: 'solid',
      }}
      direction={'row'}
      justifyContent={'space-between'}
    >
      <Stack alignSelf={'flex-start'}>
        <Text
          variant="medium"
          fontSize="small"
          color={lightTheme.palette.contrast.black}
        >
          {integrationKey.name}
        </Text>
        <MonoText>
          {clientIdLabel} {integrationKey.clientId}
        </MonoText>
      </Stack>
      <Stack>
        {!loading && (
          <ContextMenu
            open={contextMenuOpen}
            onOpenChange={setContextMenuOpen}
            options={[
              {
                label: t('update'),
                onClick: handleUpdate,
                icon: <EditOutlined />,
                textColor: lightTheme.palette.contrast.black,
              },
              {
                label: t('revoke'),
                onClick: handleRevoke,
                icon: <DeleteOutline />,
                textColor: lightTheme.palette.error.e300,
              },
            ]}
          />
        )}
      </Stack>
    </Stack>
  );
};
