import { Experience } from '@understory-io/experiences-types';

import { ampli } from '../../../../../Ampli';
import { getLocalizedString } from '../../../../../Hooks/locales/use-locale.context';

export const trackEventFlowExperienceSelected = (experience: Experience) => {
  ampli.eventFlowExperienceSelected({
    experience_id: experience.id,
    experience_name: getLocalizedString(experience.headline, ['en']) ?? '',
  });
};
