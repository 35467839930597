import { DeleteForeverRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { toISODateTime } from '@understory-io/utils-date';
import {
  getHours,
  getMinutes,
  getSeconds,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef } from 'react';
import { UseControllerReturn } from 'react-hook-form';

import { useTranslate } from '../../Hooks/useTranslate';
import { DateInput } from '../inputs/date-input';

const BoxGrid = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      display={'grid'}
      gridTemplateColumns={'50% 25% 25% 1fr'}
      alignItems={'center'}
      sx={{
        '& .MuiFormControl-root': {
          minWidth: 'auto',
        },
        '& > *:not(:first-of-type)': {
          marginLeft: '16px',
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const validDate = (val: Date | null) => (cb: () => void) => {
  try {
    new Date(val as Date);
    cb();
  } catch (err) {
    console.error(err);
  }
};

const getHourToUse = (existing: string | undefined, defaultHour: number) => {
  if (!existing) {
    return defaultHour;
  }
  try {
    new Date(existing);
    return getHours(new Date(existing));
  } catch (err) {
    return defaultHour;
  }
};

export const DateController = ({
  onDelete,
  hideFrom = false,
  hideTo = false,
  startDateTime,
  disabled = false,
  endDateTime,
  label = 'Startdato',
}: Partial<
  UseControllerReturn['field'] & {
    label: string;
    disabled?: boolean;
    hideFrom: boolean;
    hideTo: boolean;
    endDateTime?: UseControllerReturn<any, any>['field'];
    startDateTime?: UseControllerReturn<any, any>['field'];
    onDelete: () => void;
  }
>) => {
  const { t } = useTranslate('utils.generic');
  const flags = useFlags();

  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const handleChange =
    (cb?: (val: Date) => void): ((value: Date | null) => void) =>
    (value) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (cb && value && value instanceof Date) {
          const newTimeValue = updateTime(value);
          validDate(newTimeValue)(() => cb(newTimeValue));
        }
      }, 800);
    };

  const updateTime = (value: Date): Date => {
    // Ensure we keep the date and only change the time
    const currentDate = parseISO(startDateTime?.value);
    const updatedHours = setHours(currentDate, getHours(value));
    const updatedMinutes = setMinutes(updatedHours, getMinutes(value));
    return setSeconds(updatedMinutes, getSeconds(value));
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <BoxGrid width={'100%'}>
        <DateInput
          label={label}
          disabled={disabled}
          minDate={new Date()}
          value={new Date(startDateTime?.value)}
          onChange={(val: string) => {
            const date = parseISO(val);
            validDate(date)(() => {
              startDateTime?.onChange?.(
                toISODateTime(
                  setHours(date!, getHourToUse(startDateTime?.value, 9)) as Date
                )
              );
              endDateTime?.onChange?.(
                toISODateTime(
                  setHours(date!, getHourToUse(endDateTime?.value, 10)) as Date
                )
              );
            });
          }}
        />
        {!hideFrom && (
          <TimePicker
            label={t('fromTime')}
            disabled={hideFrom || !startDateTime?.value || disabled}
            value={startDateTime?.value ? new Date(startDateTime.value) : null}
            onChange={handleChange((val) =>
              startDateTime?.onChange(toISODateTime(val))
            )}
          />
        )}

        {!hideTo && (
          <TimePicker
            label={t('toTime')}
            disabled={
              hideTo ||
              !startDateTime?.value ||
              disabled ||
              endDateTime?.value === null
            }
            value={endDateTime?.value ? new Date(endDateTime.value) : null}
            onChange={handleChange((val) =>
              endDateTime?.onChange?.(toISODateTime(val))
            )}
          />
        )}

        <Box>
          <IconButton
            color={'error'}
            sx={{ visibility: onDelete ? 'auto' : 'hidden' }}
            onClick={onDelete}
          >
            <DeleteForeverRounded />
          </IconButton>
        </Box>
      </BoxGrid>
      {flags.featureEventWithoutEndTime ? (
        <FormControlLabel
          sx={{ alignSelf: 'flex-end', mr: 0 }}
          labelPlacement={'start'}
          disabled={hideTo || !startDateTime?.value || disabled}
          control={<Checkbox size={'small'} />}
          label={'No end time'}
          onChange={(e, newValue) =>
            endDateTime?.onChange(newValue ? null : undefined)
          }
          checked={endDateTime?.value === null}
          componentsProps={{
            typography: { fontWeight: 600, fontSize: '0.75em' },
          }}
        />
      ) : (
        ''
      )}
    </Box>
  );
};
