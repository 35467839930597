import { useCallback, useContext } from 'react';

import {
  AdvertisementPlan,
  AdvertisementPlanContext,
} from './steps/grow-advertisement-flow-steps';

export const useAdvertisementPlan = () => {
  const context = useContext(AdvertisementPlanContext);

  if (!context) {
    throw new Error(
      'useAdvertisementPlan must be used within an AdvertisementPlanProvider'
    );
  }

  const { setAdPlan } = context;

  const updatePlan = useCallback(
    (updates: Partial<AdvertisementPlan>) => {
      setAdPlan((prevPlan) => ({
        ...prevPlan,
        ...updates,
      }));
    },
    [setAdPlan]
  );

  const updateGoals = useCallback(
    (goals: string[]) => {
      updatePlan({ goals });
    },
    [updatePlan]
  );

  const updateUsps = useCallback(
    (usps: string[]) => {
      updatePlan({ usps });
    },
    [updatePlan]
  );

  const updateBudget = useCallback(
    (budget: number) => {
      updatePlan({ budget });
    },
    [updatePlan]
  );

  const updateExperiences = useCallback(
    (experiences: string[] | undefined) => {
      updatePlan({ experiences });
    },
    [updatePlan]
  );

  return {
    updatePlan,
    updateGoals,
    updateUsps,
    updateBudget,
    updateExperiences,
  };
};
