import { create, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';

type ConnectState = {
  invitations: Record<string, { org: string }>;
  chooseAccountForInvitation: (org: string, token: string) => void;
  removeInvitation: (token: string) => void;
};

export const connectStore = create(
  persist<ConnectState>(
    (set: StoreApi<ConnectState>['setState']) => ({
      invitations: {},
      chooseAccountForInvitation: (org: string, token: string) =>
        set((state) => ({
          invitations: {
            ...state.invitations,
            [token]: {
              org,
            },
          },
        })),
      removeInvitation: (token: string) =>
        set((state) => {
          delete state.invitations[token];
          return state;
        }),
    }),
    {
      name: 'connect-store',
      getStorage: () => sessionStorage,
    }
  )
);
