import { AutoAwesomeOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import {
  MenuButton,
  MenuButtonProps,
  useRichTextEditorContext,
} from 'mui-tiptap';
import { useMemo, useState } from 'react';
import { useRouteLoaderData } from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import { generateExperience } from '../../../../../Api';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { getLocalizedString } from '../../../../../Hooks/locales/use-locale.context';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StorefrontLanguage } from '../../../../../i18n/config';
import { LoaderData, loaderName } from '../../edit-experience';
import { TagFilterItem } from '../../forms/tags/tags';
import { TagsForm } from '../../forms/tags/tags-form';
import { hasLocalizedValue } from '../../utils/form-helpers';

interface MenuButtonAIProps extends MenuButtonProps {
  experience: RegularExperience;
  activeLanguage: StorefrontLanguage;
}

export function MenuButtonAI({
  experience,
  activeLanguage,
  sx,
  ...props
}: MenuButtonAIProps) {
  const { t } = useTranslate('utils.toolbar.ai');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isSm } = useResponsive();

  const { tags } = useRouteLoaderData(loaderName) as LoaderData;
  const { languageOption, storefrontLanguages } = useLanguages();

  const tagOptions = useMemo(() => {
    return tags
      .filter((tag) => hasLocalizedValue(tag.name))
      .map((tag) => ({
        id: tag.id,
        name: getLocalizedString(tag.name, storefrontLanguages),
        label:
          tag.name[activeLanguage] ||
          t('tagOptions.noTranslation', {
            tagName: getLocalizedString(tag.name, storefrontLanguages),
            locale: languageOption(activeLanguage).label,
          }),
      }));
  }, [tags, activeLanguage]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    ampli.generateDescriptionOpen();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const editor = useRichTextEditorContext();

  const [selectedTags, setSelectedTags] = useState<TagFilterItem[]>(
    tagOptions.filter((tagOption) => experience.tagIds.includes(tagOption.id))
  );

  const keywordOptions = useMemo(() => {
    const uniqueSelectedTags = selectedTags.filter(
      (selectedTag) =>
        !tagOptions.some((tagOption) => tagOption.id === selectedTag.id)
    );

    return [...tagOptions, ...uniqueSelectedTags];
  }, [tagOptions, selectedTags]);

  const handleSelectTag = (tagItem: TagFilterItem) => {
    selectedTags.some((tag) => tag.id === tagItem.id)
      ? setSelectedTags((prev) => prev.filter((tag) => tag.id !== tagItem.id))
      : setSelectedTags((prev) => [...prev, tagItem]);
  };

  const handleCreateTag = (tagName: string) => {
    const tempId = `temp-${selectedTags.length}`;

    const newTag = {
      id: tempId,
      name: tagName,
      label: tagName,
    };

    setSelectedTags((prev) => [...prev, newTag]);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    ampli.generateDescriptionGenerate({
      experienceId: experience.id,
      experienceName: experience.headline?.[activeLanguage] ?? '',
      numberOfKeywords: selectedTags.length,
    });

    if (selectedTags.length === 0) {
      setIsLoading(false);
      return;
    }

    const keywords = selectedTags.map((tag) => tag.name ?? tag.label);
    try {
      const { completion } = await generateExperience({
        keywords: keywords,
        language: activeLanguage,
        experienceId: experience.id,
        experienceName: experience.headline?.[activeLanguage] ?? '',
      });

      editor?.chain().clearContent().run();
      editor?.chain().insertContent(completion).focus().run();
      setAnchorEl(null);
    } catch (err) {
      ampli.generateDescriptionFailed({
        experienceId: experience.id,
        experienceName: experience.headline?.[activeLanguage] ?? '',
      });
      const errorMessage =
        (err as Error)?.message || t('toast.error', 'dialogs.generation');
      toast.error(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MenuButton
        sx={{
          paddingX: '12px !important',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          border: '1px solid',
          '&.MuiToggleButton-root': {
            border: {
              xs: 'none',
              sm: `1px solid ${lightTheme.palette.neutral.n100} !important`,
            },
          },
          ...sx,
        }}
        disableRipple
        disabled={!editor?.isEditable}
        onClick={handleClick}
        {...props}
      >
        <AutoAwesomeOutlined sx={{ fontSize: 20 }} />
        <Box
          component="span"
          sx={{
            display: {
              xs: 'none',
              sm: 'inline-flex',
            },
          }}
        >
          {t('label')}
        </Box>
      </MenuButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              left: isSm ? '0 !important' : null,
              padding: 3,
              width: '100%',
              maxWidth: { xs: '100%', md: 500 },
              boxShadow: lightTheme.shadows.medium,
            },
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mb={1}
        >
          <Text fontSize="medium">
            {t('markdownTitle', 'dialogs.generation')}
          </Text>
          <IconButton onClick={handleClose}>
            <CloseOutlined
              htmlColor={lightTheme.palette.contrast.black}
              sx={{ height: 20, width: 20 }}
            />
          </IconButton>
        </Stack>
        <Stack gap={2}>
          <TagsForm
            onCreate={handleCreateTag}
            onSelect={handleSelectTag}
            tagOptions={keywordOptions}
            selectedTags={selectedTags}
            title={t('selectedKeywords', 'dialogs.generation')}
            placeholder={t('placeholderKeywords', 'dialogs.generation')}
            createLabel={t('addKeyword', 'dialogs.generation')}
          />
          <Stack alignItems="end" justifyContent="center">
            <Box>
              <Button
                loading={isLoading}
                disabled={isLoading}
                onClick={handleSubmit}
                size="medium"
                variant="secondary"
              >
                {t('primary', 'dialogs.generation.actions')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
