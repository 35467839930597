import { CloseRounded } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useCallback } from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { NoteBox } from '../../../../../Components/note-box';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useHistory } from '../../../../../Hooks/useHistory';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import routes from '../../../../../Utils/routes';
import { useUpdateResourceIsEdit } from '../update-resource-type-context';
import { UpdateResourceTypeInputs } from '../update-resource-type-inputs';

export const UpdateResourceTypeCapacityDialog = () => {
  const { t } = useTranslate('resourceManagement.resourceType');
  const { isSm } = useResponsive();

  const { goBack } = useHistory();

  const handleClose = useCallback(() => {
    goBack(routes.resourceManagement.index);
  }, [goBack]);

  const { getValues: getOuterValues, setValue: setOuterValues } =
    useFormContext<UpdateResourceTypeInputs>();

  const formMethods = useForm<Pick<UpdateResourceTypeInputs, 'capacity'>>({
    defaultValues: {
      capacity: getOuterValues('capacity'),
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const onSubmit = useCallback(
    (data: Pick<UpdateResourceTypeInputs, 'capacity'>) => {
      setOuterValues('capacity', data.capacity);
      handleClose();
    },
    [handleClose, setOuterValues]
  );

  const isEdit = useUpdateResourceIsEdit();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('createNew.title')}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent sx={{ padding: 4 }}>
        <Stack gap={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Text fontSize="xlarge" variant="medium">
              {t('capacity')}
            </Text>
            <IconButton sx={{ padding: 0 }} onClick={handleClose} tabIndex={-1}>
              <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
            </IconButton>
          </Stack>
          <Text color={lightTheme.palette.neutral.n400} fontSize="small">
            {t('capacityDescription')}
          </Text>
          <TextField
            {...register('capacity', {
              required: {
                value: true,
                message: t('required', 'utils.errors'),
              },
              valueAsNumber: true,
            })}
            size="medium"
            fullWidth
            type="number"
            error={!!errors.capacity?.message}
            helperText={errors.capacity?.message}
            InputProps={{ sx: { maxHeight: 48 }, inputProps: { min: 1 } }}
            sx={{
              mt: 1,
              mb: 2,
            }}
          />

          {isEdit && (
            <NoteBox note={t('edit.impactDisclaimer.capacity')} mb={2} />
          )}

          <Button type="submit" variant="primary" size="large">
            {t('save', 'fullPageForm.generic')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
