import { ChevronLeftRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ANIMATIONS } from '../../config';
import { HelpEmbed } from './help-embed';

export const HelpStep = () => {
  const { isSm, isMd } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslate('onboardingFlow.step.help');

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePreviousStep = () => {
    navigate('/onboarding/decision');
  };

  return (
    <Stack
      direction={isMd ? 'column' : 'row'}
      gap={isMd ? 4 : 10}
      sx={{
        maxWidth: '860px',
        width: '100%',
        minWidth: isMd && !isSm ? '100%' : 'auto',
      }}
    >
      <Stack
        direction={isSm ? 'column-reverse' : 'column'}
        width={isMd ? '100%' : '50%'}
        alignItems="flex-start"
        justifyContent={'center'}
        gap={4}
      >
        {!isSm && (
          <Button
            variant="text"
            size="large"
            style={{
              color: lightTheme.palette.action.a300,
              padding: 0,
            }}
            onClick={handlePreviousStep}
            leftIcon={<ChevronLeftRounded fontSize="small" />}
          >
            {t('back', 'buttons')}
          </Button>
        )}
        <Stack gap={1}>
          <AnimatePresence mode="wait">
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={ANIMATIONS.title}
              style={{ minHeight: '32px' }}
            >
              <Text variant="medium" fontSize="xlarge">
                {t('title')}
              </Text>
            </motion.div>
          </AnimatePresence>
          <AnimatePresence>
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={ANIMATIONS.description}
              style={{ minHeight: '24px' }}
            >
              <Text color={lightTheme.palette.neutral.n300}>
                {t('description')}
              </Text>
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Stack>
      <HelpEmbed />
    </Stack>
  );
};
