import { API } from '@understory-io/resource-management-types';
import { useMemo } from 'react';

type ResourceReservation = API.GetReservations.Response['items'][0];

/**
 * Gets number of resources per resource type
 */
export function useGroupedResourceReservations(
  reservations: Array<
    Pick<ResourceReservation, 'resourceTypeId' | 'allocationType'>
  >
) {
  return useMemo(() => {
    const isEventCounted: { [groupId: string]: boolean } = {};

    return reservations.reduce<{
      [resourceTypeId: string]: number;
    }>((grouped, { resourceTypeId, allocationType }) => {
      grouped[resourceTypeId] ||= 0;

      if (allocationType !== 'event') {
        grouped[resourceTypeId] += 1;
        return grouped;
      }

      // Only count event reservations once
      if (!isEventCounted[resourceTypeId]) {
        isEventCounted[resourceTypeId] = true;
        grouped[resourceTypeId] += 1;
        return grouped;
      }

      return grouped;
    }, {});
  }, [reservations]);
}
