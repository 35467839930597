import { Avatar } from '@mui/material';

import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  isStorefrontLanguage,
  type StorefrontLanguage,
} from '../../../../i18n/config';
import type { PublishErrors } from '../edit-experience';
import { AutoTranslateToggle } from '../header/auto-translate-toggle';
import { CustomSelect } from './custom-select';

const ICON_SIZE_PX = 18;

interface LanguageOptionsProps {
  activeLanguage: StorefrontLanguage;
  availableLanguages: StorefrontLanguage[];
  handleChangeLanguage: (language: StorefrontLanguage) => void;
  isAutoTranslateEnabled: boolean;
  handleChangeAutoTranslate: (checked: boolean) => void;
  publishErrors?: PublishErrors;
}

export function LanguageOptions({
  activeLanguage,
  availableLanguages,
  handleChangeAutoTranslate,
  handleChangeLanguage,
  isAutoTranslateEnabled,
  publishErrors,
}: LanguageOptionsProps) {
  const { t } = useTranslate('experience.edit.details.label');
  const { languageOption } = useLanguages();
  const onLanguageChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    if (isStorefrontLanguage(newValue)) {
      handleChangeLanguage(newValue);
    }
  };

  return (
    <CustomSelect
      selectedValue={activeLanguage}
      options={availableLanguages.map((lang) => {
        const { label, country, flagSrc } = languageOption(lang);
        return {
          value: lang,
          label: country ? `${label} (${country})` : label,
          icon: (
            <Avatar
              sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }}
              src={flagSrc}
            />
          ),
          hasError: Boolean(publishErrors?.[lang]?.length),
        };
      })}
      footerOptions={[
        {
          key: 'auto-translate',
          label: t('autoTranslate'),
          removePadding: true,
          children: (
            <AutoTranslateToggle
              isAutoTranslateEnabled={isAutoTranslateEnabled}
              onChange={handleChangeAutoTranslate}
            />
          ),
        },
      ]}
      onChange={onLanguageChange}
      error={
        publishErrors
          ? Object.keys(publishErrors).some(isStorefrontLanguage)
          : false
      }
    />
  );
}
