import { MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useCurrencies } from '../../../Hooks/locales/use-currencies';
import { StorefrontPaymentsFormData } from './storefront-payments-section';

export const CurrencyInput = () => {
  const { control } = useFormContext<StorefrontPaymentsFormData>();

  const currencies = useCurrencies();

  const currencyOptions = currencies.data?.items.map(
    ({ code, symbol, flagSrc }) => ({
      key: code.toLowerCase(),
      label: code + (symbol ? ` (${symbol})` : ''),
      flag: flagSrc,
    })
  );

  return (
    <Controller
      name="defaultCurrency"
      control={control}
      render={({ field }) => (
        <Select {...field}>
          {currencyOptions?.map((currency) => (
            <MenuItem key={currency.key} value={currency.key}>
              {currency.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
