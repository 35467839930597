import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Button } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ActionFunctionArgs, Outlet, replace, useSubmit } from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { resourceTypesQuery, userInfoQuery } from '../../../../Api/queries';
import { updateResourceType } from '../../../../Api/resource-management';
import { FullPage } from '../../../../Components/full-page/full-page';
import { FullPageHeader } from '../../../../Components/full-page/full-page-header';
import { FullPageHeaderBackButton } from '../../../../Components/full-page/full-page-header-back-button';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import routes from '../../../../Utils/routes';
import { UpdateResourceTypeContextProvider } from './update-resource-type-context';
import { UpdateResourceTypeForm } from './update-resource-type-form';
import {
  UpdateResourceTypeInputs,
  updateResourceTypeSchema,
} from './update-resource-type-inputs';

type Props = {
  resourceType: ResourceType;
};

export const action =
  (queryClient: QueryClient) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const { resourceTypeId } = params;

    const toastId = toast.loading(
      t('resourceManagement.resourceType.edit.toast.loading')
    );

    try {
      const user = await queryClient.fetchQuery(userInfoQuery());
      if (!user.companyId) {
        throw new Error("User doesn't have a company ID");
      }

      if (!resourceTypeId) {
        throw new Error('Resource type ID is required');
      }

      const payload = (await request.json()) as UpdateResourceTypeInputs;

      const result = await updateResourceType({ resourceTypeId }, payload);

      await queryClient.invalidateQueries({
        queryKey: resourceTypesQuery().queryKey,
      });

      toast.dismiss(toastId);

      return replace(
        routes.resourceManagement.details(result.resourceTypeId).index
      );
    } catch (error) {
      captureException(error);
      toast.dismiss(toastId);
      toast.error(t('resourceManagement.resourceType.edit.toast.error'), {
        delay: 500,
      });
      return {
        success: false,
      };
    }
  };

export const EditResourceTypePage: FC<Props> = ({ resourceType }) => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const fireOnce = useFireOnce();
  useEffect(() => {
    fireOnce(() =>
      ampli.resourceTypeEditFlowStarted({
        resource_type_id: resourceType.resourceTypeId,
      })
    );
  }, [fireOnce, resourceType.resourceTypeId]);

  const formMethods = useForm({
    defaultValues: {
      name: resourceType.name,
      capacity: resourceType.capacity,
      recoverTimeInSeconds: resourceType.recoverTimeInSeconds ?? 0,
    },
    resolver: yupResolver(updateResourceTypeSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = useCallback(
    async (data: UpdateResourceTypeInputs) => {
      await submit(data, {
        method: 'post',
        action: '',
        encType: 'application/json',
      });
      const newFollowUp = data.recoverTimeInSeconds ?? 0;
      const capacityDelta = data.capacity - resourceType.capacity;
      const followUpTimeDelta =
        newFollowUp - (resourceType.recoverTimeInSeconds ?? 0);

      ampli.resourceTypeEditFlowCompleted({
        capacity: data.capacity,
        follow_up_time_seconds: data.recoverTimeInSeconds,
        capacity_delta: capacityDelta,
        follow_up_time_seconds_delta: followUpTimeDelta,
      });
    },
    [resourceType.capacity, resourceType.recoverTimeInSeconds, submit]
  );

  return (
    <UpdateResourceTypeContextProvider
      links={
        routes.resourceManagement.details(resourceType.resourceTypeId).edit
      }
      isEdit
    >
      <FormProvider {...formMethods}>
        <FullPage component="form" onSubmit={handleSubmit(onSubmit)}>
          <FullPageHeader>
            <FullPageHeaderBackButton
              fallbackUrl={routes.resourceManagement.index}
            />
            <Button variant="primary" size="medium" loading={isSubmitting}>
              {t('save', 'fullPageForm.generic')}
            </Button>
          </FullPageHeader>
          <UpdateResourceTypeForm />
        </FullPage>
        <Outlet />
      </FormProvider>
    </UpdateResourceTypeContextProvider>
  );
};
