import { CompanyProfile } from '@understory-io/utils-types';

import {
  isStorefrontLanguage,
  StorefrontLanguage,
} from '../../../../i18n/config';

/**
 * Determines and returns the active langauge based on the request and company profile
 * @param request - the request accessed from a loader or action function
 * @param company - a company profile
 */
export const getActiveLanguage = (
  request: Request,
  company: CompanyProfile
) => {
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const languageParams = searchParams.get('language');
  const activeLanguage =
    languageParams && isStorefrontLanguage(languageParams)
      ? languageParams
      : (company.defaultLanguage as StorefrontLanguage);

  return activeLanguage;
};
