import { QueryClient } from '@tanstack/react-query';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { draftExperienceQuery } from '../../../../../Api/queries';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params }: LoaderFunctionArgs) {
  const variantId = params.variantId;

  return { variantId };
}

export default function DeleteTicketForm() {
  const { variantId } = useLoaderData() as LoaderData;
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.deleteTicket');

  const variant = experience.price?.variants?.find(
    (variant) => variant.id === variantId
  );

  // Make sure the variant exists
  if (variantId && !variant) {
    throw new Response('Invalid variant id', { status: 404 });
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="delete-ticket"
      isDelete
    />
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params }: ActionFunctionArgs) => {
    const id = params.id;
    const variantId = params.variantId;

    if (!id || !variantId) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      const experience = await client.fetchQuery(draftExperienceQuery(id));

      const experienceToSave = {
        ...experience,
        price: {
          ...experience.price,
          // Remove deleted variant id
          variants: experience.price?.variants?.filter(
            (variant) => variant.id !== variantId
          ),
          // Remove display price if it was the deleted variant
          displayVariantId:
            experience.price?.displayVariantId === variantId
              ? undefined
              : experience.price?.displayVariantId,
          displayPriceCents:
            experience.price?.displayVariantId === variantId
              ? undefined
              : experience.price?.displayPriceCents,
        },
      };

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      return null;
    }
  };
