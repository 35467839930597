import { Box, BoxProps, TextField, TextFieldProps } from '@mui/material';
import {
  intervalToDuration,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TimeSpanPickerProps = {
  initialValueSeconds: number;
  onChange: (totalSeconds: number) => void;
  hideDays?: boolean;
  size?: TextFieldProps['size'];
} & Omit<BoxProps, 'onChange'>;

type Timespan = Required<Pick<Duration, 'days' | 'hours' | 'minutes'>>;

export const TimeSpanPicker = ({
  initialValueSeconds,
  onChange,
  hideDays,
  size,
  ...rest
}: TimeSpanPickerProps) => {
  const duration = intervalToDuration({
    start: 0,
    end: (initialValueSeconds || 0) * 1000,
  });

  const [timeSpan, setTimeSpan] = useState({
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
  });

  const { t } = useTranslation();

  const updateTimeSpan = useCallback(
    (newTimeSpan: Timespan) => {
      setTimeSpan(newTimeSpan);

      const daysSeconds = newTimeSpan.days * secondsInDay;
      const hoursSeconds = newTimeSpan.hours * secondsInHour;
      const minutesSeconds = newTimeSpan.minutes * secondsInMinute;
      const totalSeconds = daysSeconds + hoursSeconds + minutesSeconds;
      onChange(totalSeconds);
    },
    [onChange]
  );

  return (
    <Box display="flex" alignItems="center" gap={2} {...rest}>
      {!hideDays && (
        <TextField
          label={t('utils.generic.days')}
          type="number"
          value={timeSpan.days.toString()}
          fullWidth
          onChange={(e) =>
            updateTimeSpan({
              ...timeSpan,
              days: parseInt(e.target.value || '0', 10),
            })
          }
          InputProps={{ inputProps: { min: 0 } }}
          size={size}
        />
      )}
      <TextField
        label={t('utils.generic.hours')}
        type="number"
        value={timeSpan.hours.toString()}
        fullWidth
        onChange={(e) =>
          updateTimeSpan({
            ...timeSpan,
            hours: parseInt(e.target.value || '0', 10),
          })
        }
        InputProps={{ inputProps: { min: 0, max: 23 } }}
        size={size}
      />
      <TextField
        label={t('utils.generic.minutes')}
        type="number"
        value={timeSpan.minutes.toString()}
        fullWidth
        onChange={(e) =>
          updateTimeSpan({
            ...timeSpan,
            minutes: parseInt(e.target.value || '0', 10),
          })
        }
        InputProps={{ inputProps: { min: 0, max: 59 } }}
        size={size}
      />
    </Box>
  );
};
