import { replace } from 'react-router';

import { updateExperienceMetaData } from '../../../../Api/Experience';
import { convertFormDataValue } from '../utils/form-helpers';

export const AUTO_TRANSLATION_KEY = 'isAutoTranslateEnabled';
export const REDIRECT_TO_KEY = 'newLanguage';

export const toggleAutoTranslateHandler = async (
  experienceId: string,
  formData: FormData
) => {
  const isEnabled = convertFormDataValue(formData.get(AUTO_TRANSLATION_KEY));
  const redirectTo = convertFormDataValue(formData.get(REDIRECT_TO_KEY));

  if (typeof isEnabled !== 'boolean') {
    throw new Error('Invalid auto translate value');
  }

  const metaDataToSave = {
    autoTranslateEnabled: isEnabled,
  };

  await updateExperienceMetaData(experienceId, metaDataToSave);

  if (redirectTo) {
    return replace(redirectTo as string);
  }
};
