import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { RegularExperience } from '@understory-io/experiences-types';
import { InformationRequest } from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import {
  companyProfileQuery,
  draftExperienceQuery,
} from '../../../../../Api/queries';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StorefrontLanguage, t } from '../../../../../i18n/config';
import { localizedInputSchema } from '../../../schemas/genericSchemas';
import { informationRequestSchema } from '../../../schemas/information-request-schema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { createErrorObject, getFormDataValue } from '../../utils/form-helpers';
import { getActiveLanguage } from '../../utils/get-active-language';
import { translateInput } from '../../utils/translate-input';
import { RequestForm } from './request-form';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params }: LoaderFunctionArgs) {
  const requestId = params.requestId;

  return { requestId };
}

export default function GuestInfoForm() {
  const { requestId } = useLoaderData() as LoaderData;
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.guestInfo');

  const infoRequest = experience.informationRequests?.find(
    (request) => request.id === requestId
  );

  // Make sure the variant exists if an id is provided
  if (requestId && !infoRequest) {
    throw new Response('Request not found', { status: 404 });
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="guest-info"
    >
      <Stack sx={{ marginTop: 1, gap: 2, alignItems: 'start' }}>
        <RequestForm
          defaultValues={{
            required: infoRequest?.required ?? false,
            scope: infoRequest?.scope ?? 'ticket',
            request: infoRequest?.request[activeLanguage],
          }}
          errors={actionData?.errors}
        />
      </Stack>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  errors?: Partial<Record<keyof InformationRequest, string>>;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs): Promise<ActionData> => {
    const id = params.id;
    const requestId = params.requestId;

    if (!id) {
      throw new Response('Invalid id', { status: 400 });
    }

    try {
      const [experience, companyProfile] = await Promise.all([
        client.fetchQuery(draftExperienceQuery(id)),
        client.fetchQuery(companyProfileQuery()),
      ]);

      // Make sure the request exists when editing
      const existingInformationRequest = experience.informationRequests?.find(
        (request: { id: string | undefined }) => request.id === requestId
      );
      if (requestId && !existingInformationRequest) {
        throw new Response('Request not found', { status: 404 });
      }

      const activeLanguage = getActiveLanguage(request, companyProfile);

      const formData = await request.formData();
      const formEntries = Object.entries(Object.fromEntries(formData)).reduce(
        (entries, [key, value]) => ({
          ...entries,
          [key]: getFormDataValue(value),
        }),
        {} as Record<string, string>
      );

      try {
        formEntries.request = localizedInputSchema.validateSync(
          formEntries.request
        );
      } catch (error) {
        if (error instanceof ValidationError) {
          return {
            errors: {
              request: error.message,
            },
          };
        }
      }

      const localizedRequest = await translateInput(
        formEntries.request,
        existingInformationRequest?.request,
        activeLanguage,
        companyProfile.languages as StorefrontLanguage[],
        experience.autoTranslateEnabled
      );

      const newRequest: InformationRequest = {
        id: requestId ?? randomBytes(16).toString('hex'),
        request: localizedRequest,
        required: !!formEntries.required,
        scope: formEntries.scope as InformationRequest['scope'],
      };

      const result = informationRequestSchema(activeLanguage).validateSync(
        newRequest
      ) as InformationRequest;

      // Figure out the index of the existing request, if it exists
      let existingIndex = -1;
      if (experience.informationRequests && existingInformationRequest) {
        existingIndex = experience.informationRequests?.indexOf(
          existingInformationRequest
        );
      }

      // Update the existing request or add the new one
      const existingInformationRequests = experience.informationRequests ?? [];
      if (existingIndex === -1) {
        existingInformationRequests.push(result);
      } else {
        existingInformationRequests[existingIndex] = result;
      }
      const experienceToSave = {
        ...experience,
        informationRequests: existingInformationRequests,
      } as RegularExperience;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        const errorObj = createErrorObject<InformationRequest>(error);

        return {
          errors: errorObj,
        };
      }

      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
