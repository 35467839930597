import { useFormContext } from 'react-hook-form';

import { FullPageFormSettingSingleItem } from '../../../../../Components/full-page/form-components/setting/full-page-form-setting-single-item';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useUpdateResourceTypeLinks } from '../update-resource-type-context';
import { UpdateResourceTypeInputs } from '../update-resource-type-inputs';

export const UpdateResourceTypeCapacitySetting = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const {
    watch,
    formState: { errors },
  } = useFormContext<UpdateResourceTypeInputs>();

  const capacity = watch('capacity');

  const links = useUpdateResourceTypeLinks();

  return (
    <FullPageFormSettingSingleItem
      title={t('capacity')}
      value={t('countPersons', 'utils.generic.units', { count: capacity })}
      to={links.capacity}
      hasError={!!errors.capacity}
    />
  );
};
