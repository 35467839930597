import { useMemo, useState } from 'react';

import { ampli } from '../../../Ampli';
import { SimpleSkeleton } from '../../../Components/SimpleSkeleton/SimpleSkeleton';
import { useAdPlans } from '../../../Hooks/use-ad-plans';
import { GrowAdvertisementDashboard } from './dashboard/grow-advertisement-dashboard';
import { GrowAdvertisementFlow } from './get-started/flow/grow-advertisement-flow';
import { GrowAdvertisementGetStarted } from './get-started/grow-advertisement-get-started';

export const GrowAdvertisementSection = () => {
  const {
    adPlans: { data: adPlans = [], isLoading },
  } = useAdPlans();

  const [isFlowOpen, setIsFlowOpen] = useState(false);

  const handleOpenFlow = () => {
    setIsFlowOpen(true);
    ampli.growAdvertisementTryItOutClicked();
  };

  const handleCloseFlow = () => {
    setIsFlowOpen(false);
  };

  const hasActivePlans = useMemo(
    () =>
      adPlans?.length > 0 && adPlans.some((adPlan) => adPlan.state !== 'draft'),
    [adPlans]
  );

  if (isLoading) {
    return <SimpleSkeleton />;
  }

  return (
    <>
      {hasActivePlans ? (
        <GrowAdvertisementDashboard />
      ) : (
        <GrowAdvertisementGetStarted handleOpenFlow={handleOpenFlow} />
      )}
      {isFlowOpen && (
        <GrowAdvertisementFlow open={isFlowOpen} onClose={handleCloseFlow} />
      )}
    </>
  );
};
