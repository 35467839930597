import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  ActionFunctionArgs,
  redirect,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { IInvitation, inviteUser } from '../../../../Api/Invitation';
import { UserItem } from '../../../../Components/UserItem/UserItem';
import { DialogWrapper } from '../../../../features/connect/components/dialog-wrapper';
import { useInvitations } from '../../../../Hooks/data/useInvitations';
import { useRoles } from '../../../../Hooks/data/useRoles';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import { InputDialog, InputDialogProps } from '../../../../Modals/InputDialog';
import { validEmail } from '../../../../Utils/helpers';
import routes from '../../../../Utils/routes';

export async function action({ request }: ActionFunctionArgs) {
  const { users } = (await request.json()) as { users: IInvitation[] };

  toast.loading(t('toast.inviteUser.loading', { count: users.length }), {
    toastId: 'invite-users-loading',
  });

  const responses = await Promise.all(
    users.map(async (i) => {
      try {
        await inviteUser(i);
        return {
          error: false,
          email: i.claims.email,
        };
      } catch (err) {
        return {
          error: true,
          message: (err as Error).message,
          email: i.claims.email,
        };
      }
    })
  ).finally(() => {
    toast.dismiss('invite-users-loading');
  });

  if (responses.some((x) => x.error)) {
    const failed = responses.filter((x) => x.error);
    const succeeded = responses.filter((x) => !x.error);
    failed.map((x) => {
      if (!x.message) {
        toast.error(
          t('toast.inviteUser.failed', {
            count: failed.length,
            users: failed.map((x) => `${x.email}: ${x.message}`).join('\n'),
          }),
          {
            toastId: 'invite-users-failed',
            autoClose: 5000,
          }
        );
        return;
      }

      toast.error(
        t(`toast.inviteUser.status.${x.message}`, {
          email: x.email,
        }),
        {
          toastId: 'invite-users-failed-' + x.message,
          autoClose: 5000,
        }
      );
    });
    succeeded.map((x) => {
      toast.success(
        t('toast.inviteUser.status.SUCCESS', {
          email: x.email,
        }),
        {
          toastId: 'invite-users-success-' + x.email,
          autoClose: 5000,
        }
      );
    });
  } else {
    toast.success(t('toast.inviteUser.success', { count: responses.length }), {
      toastId: 'invite-users-success',
      autoClose: 5000,
    });
  }

  return redirect(routes.settings.users.index);
}

export const SettingsTeamInviteDialog = () => {
  const { t } = useTranslate('dialogs.inviteUser');
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { state } = useNavigation();

  const handleClose = () => {
    navigate(routes.settings.users.index);
  };

  const [users, setUsers] = useState<IInvitation[]>([]);
  const {
    roles: { isLoading: isLoadingRoles },
    activeRoles: roles,
  } = useRoles();

  const {
    invitations: { refetch },
  } = useInvitations();

  const isSubmitDisabled = state === 'submitting';

  const handleDelete = (index: number) => () =>
    setUsers((p) => p.filter((_, i) => i !== index));
  const handleUpdate =
    (index: number) => (key: 'email' | 'role', value: string) =>
      setUsers((p) =>
        p.map((el, i) => {
          return i === index
            ? { ...el, claims: { ...el.claims, [key]: value } }
            : el;
        })
      );

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: routes.settings.users.invite,
      encType: 'application/json',
    }).then(() => {
      refetch();
      ampli.inviteNewUserFlowCompleted();
    });
  };

  const handleCreate = async () => {
    try {
      const { email, role, name } = await NiceModal.show<
        {
          email: string;
          role: string;
          name: string;
        },
        InputDialogProps & NiceModalHocProps,
        InputDialogProps
      >(InputDialog, {
        title: t('buttons.addUser'),
        inputs: [
          {
            key: 'name',
            name: t('name', 'utils.generic'),
            props: {
              fullWidth: true,
            },
            rules: {
              required: true,
            },
          },
          {
            key: 'email',
            name: t('email', 'utils.generic'),
            props: {
              fullWidth: true,
              helperText: t('emailHelpText'),
            },
            rules: {
              required: true,
              validate: validEmail,
            },
          },
          {
            key: 'role',
            name: t('assignRole'),
            props: {
              fullWidth: true,
              options: roles?.map(({ name, id }) => ({
                label: name,
                key: id,
              })),
              type: 'select',
              helperText: t('roleHelpText'),
            },
            rules: {
              required: true,
            },
          },
        ],
      });

      setUsers((p) => [
        ...p,
        {
          shouldNotify: true,
          claims: {
            email,
            role,
            name,
          },
        },
      ]);
    } catch (err) {
      return;
    }
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <Stack gap={4}>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('description')}
        </Text>
        <Stack gap={3}>
          {users.map((el, i) => {
            return (
              <UserItem
                key={`user-item-${el.claims.email}`}
                name={el.claims.name ?? ''}
                email={el.claims.email}
                role={el.claims.role}
                id={`user-item-${i}`}
                onUpdate={handleUpdate(i)}
                onDelete={handleDelete(i)}
              />
            );
          })}
          <Stack alignItems={'start'}>
            <Button
              size="small"
              variant="secondary"
              disabled={isLoadingRoles}
              leftIcon={<AddRounded fontSize="small" />}
              onClick={handleCreate}
            >
              {users.length > 0
                ? t('buttons.addOneMoreUser')
                : t('buttons.addUser')}
            </Button>
          </Stack>
        </Stack>
        <Button
          size="large"
          variant="primary"
          disabled={users.length === 0 || isSubmitDisabled}
          onClick={() => onSubmit({ users })}
        >
          {t('buttons.sendInvitations')}
        </Button>
      </Stack>
    </DialogWrapper>
  );
};
