import { VisibilityOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { Button, lightTheme } from '@understory-io/pixel';
import { useCallback, useEffect, useState } from 'react';
import { useNavigation } from 'react-router';

import { ampli } from '../../../../Ampli';
import { StickyActionBar } from '../../../../Components/layout/full-page-form/sticky-action-bar';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { ActionKey } from '../edit-experience';
import { trackExperienceEditPreviewToggled } from '../utils/tracking-helper';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';
import { AutoSaveStatus } from './auto-save-status';
import { GoBack } from './go-back';

type EditExperienceHeaderProps = {
  experience: RegularExperience;
  handlePublish: () => void;
  unknownError?: boolean;
  flowBeingSubmitted: ActionKey | undefined;
  isFromOnboarding?: boolean;
};

export const EditExperienceHeader = ({
  experience,
  handlePublish,
  unknownError,
  flowBeingSubmitted,
  isFromOnboarding,
}: EditExperienceHeaderProps) => {
  const { isSm } = useResponsive();
  const { t } = useTranslate('experience.edit.details.label');
  const { state } = useNavigation();
  const navigateWithParams = useNavigateWithParams();

  const [isPublishing, setIsPublishing] = useState(false);

  const handleClickPublish = useCallback(() => {
    setIsPublishing(true);
    handlePublish();
    if (isFromOnboarding) ampli.experienceEditContinueClicked();
  }, [handlePublish, isFromOnboarding]);

  const handleClickPreview = useCallback(() => {
    trackExperienceEditPreviewToggled({
      experience_id: experience.id,
      preview_mode: 'show',
    });
    navigateWithParams(routes.experience.details(experience.id).edit.preview);
  }, [experience.id, navigateWithParams]);

  useEffect(() => {
    if (!isPublishing) return;

    if (state === 'idle') {
      setIsPublishing(false);
    }
  }, [isPublishing, state]);

  const isPublishDisabled =
    !experience.hasDraft ||
    (flowBeingSubmitted && flowBeingSubmitted !== 'publish');

  return (
    <StickyActionBar>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: { xs: 3, md: 5 },
          alignItems: 'center',
        }}
      >
        <GoBack
          experienceId={experience.id}
          isFromOnboarding={isFromOnboarding}
        />
        <AutoSaveStatus
          unknownError={unknownError}
          flowBeingSubmitted={flowBeingSubmitted}
        />
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        {isSm ? (
          <IconButton
            onClick={handleClickPreview}
            sx={{
              backgroundColor: lightTheme.palette.contrast.surface2,
              borderColor: lightTheme.palette.neutral.n200,
              borderWidth: 1,
              borderStyle: 'solid',
              paddingX: 1.5,
              paddingY: 2,
              borderRadius: 1,
              maxHeight: 32,
              maxWidth: 40,
            }}
          >
            <VisibilityOutlined
              fontSize="small"
              style={{
                color: lightTheme.palette.neutral.n300,
              }}
            />
          </IconButton>
        ) : (
          <Button
            variant="secondary"
            size="medium"
            onClick={handleClickPreview}
            leftIcon={
              <VisibilityOutlined
                style={{ color: lightTheme.palette.neutral.n300 }}
              />
            }
          >
            {t('preview', 'dialogs.booking.notifications')}
          </Button>
        )}
        <Button
          variant="primary"
          size={isSm ? 'small' : 'medium'}
          disabled={isPublishDisabled}
          onClick={handleClickPublish}
          loading={isPublishing}
          style={{ minWidth: isFromOnboarding && !isSm ? 200 : 'initial' }}
        >
          {isFromOnboarding ? t('continue', 'buttons') : t('publish')}
        </Button>
      </Stack>
    </StickyActionBar>
  );
};
