import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';
import { useNavigation, useParams } from 'react-router';

import {
  GoBackButton,
  StickyActionBar,
} from '../../../../Components/layout/full-page-form/sticky-action-bar';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import routes from '../../../../Utils/routes';

type EventFormProps = {
  handleSubmit: () => void;
  children?: React.ReactNode;
};

export const EventForm = ({ handleSubmit, children }: EventFormProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isSm } = useResponsive();
  const { state } = useNavigation();

  return (
    <Stack sx={{ minHeight: '100vh' }}>
      <StickyActionBar>
        <GoBackButton
          fallbackPath={
            id ? routes.event.details(id).index : routes.experience.overview
          }
        />
        <Button
          variant="primary"
          size={isSm ? 'small' : 'medium'}
          onClick={handleSubmit}
          loading={state === 'submitting'}
        >
          {t('buttons.create')}
        </Button>
      </StickyActionBar>
      <Stack
        sx={{
          width: '100%',
          maxWidth: 700,
          padding: { xs: 2, md: 4 },
          marginX: 'auto',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
