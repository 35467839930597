import { Divider, Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../Utils/helpers';
import { PRICING } from '../../config';
import { OnboardingStepLayout } from '../step-layout/step-layout';
import { PricingDisclaimer } from './pricing-disclaimer';
import { PricingFeatureItem } from './pricing-feature-item';

export const PricingStep = () => {
  const { t } = useTranslate('onboardingFlow.step.pricing');
  const { isSm } = useResponsive();
  const { defaultCurrency } = useProfile();
  const { dateFnsLocale } = useLocale();

  return (
    <OnboardingStepLayout title={t('title')}>
      <Stack gap={2} alignItems={isSm ? 'center' : 'flex-start'} width="100%">
        <StyledPricingCard divider={<Divider />}>
          <Stack gap={1}>
            <Stack direction="row" alignItems="baseline" gap={0.5}>
              <Text variant="medium" fontSize="xlarge">
                {getFormattedPrice(
                  PRICING.price,
                  defaultCurrency,
                  dateFnsLocale.code
                )}
              </Text>
              <Text variant="normal" color={lightTheme.palette.neutral.n300}>
                / {t('month', 'utils.generic').toLowerCase()}
              </Text>
            </Stack>

            <Text variant="normal" color={lightTheme.palette.neutral.n400}>
              {t('fee', {
                fee: PRICING.fee.toLocaleString(dateFnsLocale.code, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
              })}
            </Text>
          </Stack>
          <Stack gap={1}>
            {PRICING.features.map((feature) => (
              <PricingFeatureItem
                key={feature}
                text={t(`features.${feature}`)}
              />
            ))}
          </Stack>
        </StyledPricingCard>
        <PricingDisclaimer />
      </Stack>
    </OnboardingStepLayout>
  );
};

const StyledPricingCard = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  border: `2px solid ${lightTheme.palette.neutral.n100}`,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(3),
  width: '100%',
}));
