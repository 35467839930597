import styled from '@emotion/styled';
import { CheckRounded } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackStorefrontCustomizeStylingButtonChanged } from '../../../../tracking/storefront/customize-events';
import { CustomizeStorefrontFormData } from '../customize-storefront';
import { BUTTON_BORDER_STYLES } from './storefront-styling';

export const ButtonStyleSelect = () => {
  const { t } = useTranslate('storefront.customize');

  const { control, watch } = useFormContext<CustomizeStorefrontFormData>();

  const selectedButtonStyle = watch('customization.buttonStyle');

  return (
    <Stack sx={{ gap: 1.5 }}>
      <Text fontSize="small">{t('buttonShape.label')}</Text>
      <Stack direction={'row'} gap={1}>
        <Controller
          name="customization.buttonStyle"
          defaultValue={Object.keys(BUTTON_BORDER_STYLES)[0]}
          control={control}
          render={({ field }) => (
            <>
              {Object.keys(BUTTON_BORDER_STYLES).map((style: string) => (
                <StyledButtonPreviewItem
                  key={style}
                  {...field}
                  selectedButtonStyle={selectedButtonStyle}
                  styling={style}
                  onClick={() => {
                    field.onChange(style);
                    trackStorefrontCustomizeStylingButtonChanged(style);
                  }}
                  variant={'outlined'}
                  size={'small'}
                  endIcon={
                    selectedButtonStyle === style ? <CheckRounded /> : null
                  }
                >
                  {t('buttonStyle.' + style)}
                </StyledButtonPreviewItem>
              ))}
            </>
          )}
        />
      </Stack>
    </Stack>
  );
};

const StyledButtonPreviewItem = styled(Button)<{
  selectedButtonStyle: string;
  styling: string;
}>`
  padding: 16px;
  border-radius: ${({ styling }) =>
    BUTTON_BORDER_STYLES[styling as keyof typeof BUTTON_BORDER_STYLES]};
  background-color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a100 + '80'
      : lightTheme.palette.contrast.white};
  color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a300
      : 'inherit'};
  border-color: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling
      ? lightTheme.palette.action.a300
      : lightTheme.palette.neutral.n200};
  font-weight: ${({ selectedButtonStyle, styling }) =>
    selectedButtonStyle === styling ? '600' : 'unset'};
  &:hover {
    background-color: ${lightTheme.palette.action.a100} + '80';
    border-color: ${lightTheme.palette.action.a300};
  }
`;
