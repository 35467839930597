import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Localized } from '@understory-io/utils-types';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import nb from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';
import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BACKOFFICE_FALLBACK_LOCALE_KEY,
  BackofficeLanguage,
  StorefrontLanguage,
} from '../../i18n/config';
import { useApplyLocale } from './use-apply-locale';

const LOCALE_MAP: Record<string, Locale> = {
  da: da,
  en: enGB,
  de: de,
  sv: sv,
  no: nb, // nb is used for Norwegian Bokmål
  'en-US': enUS,
};

type LocaleContext = {
  dateFnsLocale: Locale;
  locale: string;
  languageNames: Intl.DisplayNames;
  regionNames: Intl.DisplayNames;
  getLocalizedString: (
    value: string | Localized | undefined | null,
    fallbackValue?: string
  ) => string;
};

const localeContext = createContext<LocaleContext | null>(null);

export const useLocale = () => {
  const context = useContext(localeContext);

  if (!context) {
    throw new Error('useLocale must be used within a LocaleContextProvider');
  }

  return context;
};

export default function LocaleContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { i18n } = useTranslation();

  const dateFnsLocale = useMemo(() => {
    const currentLanguage = i18n.language;

    // Check language code with region
    if (LOCALE_MAP[currentLanguage]) {
      return LOCALE_MAP[currentLanguage];
    }

    // Check language code without region
    const localeWithoutRegion = currentLanguage.split('-')[0];
    if (LOCALE_MAP[localeWithoutRegion]) {
      return LOCALE_MAP[localeWithoutRegion];
    }

    return LOCALE_MAP[BACKOFFICE_FALLBACK_LOCALE_KEY];
  }, [i18n.language]);

  const activeLocale = i18n.languages[0];

  const languageNames = useMemo(() => {
    return new Intl.DisplayNames([activeLocale], { type: 'language' });
  }, [activeLocale]);

  const regionNames = useMemo(() => {
    return new Intl.DisplayNames([activeLocale], { type: 'region' });
  }, [activeLocale]);

  useApplyLocale(dateFnsLocale);

  const getLocalized = useCallback(
    (value: string | Localized | undefined | null, fallbackValue = '') =>
      getLocalizedString(
        value,
        i18n.languages as BackofficeLanguage[],
        fallbackValue
      ),
    [i18n.languages]
  );

  return (
    <localeContext.Provider
      value={{
        locale: activeLocale,
        dateFnsLocale,
        languageNames,
        regionNames,
        getLocalizedString: getLocalized,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateFnsLocale}
      >
        {children}
      </LocalizationProvider>
    </localeContext.Provider>
  );
}

export const getLocalizedString = (
  value: string | Localized | undefined | null,
  languages: StorefrontLanguage[],
  fallbackValue = ''
) => {
  if (!value) return fallbackValue;

  if (typeof value === 'string') {
    return value || fallbackValue;
  }

  for (const locale of languages) {
    const foundValue = value[locale];
    if (typeof foundValue === 'string') return foundValue;
  }

  return Object.values(value)[0] || fallbackValue;
};
