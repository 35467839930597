import {
  ampli,
  ExperienceEditDialogClosedProperties,
  ExperienceEditDialogOpenedProperties,
  ExperienceEditDialogSavedProperties,
  ExperienceEditLanguageChangedProperties,
  ExperienceEditLanguageToggledProperties,
  ExperienceEditMediaDeletedProperties,
  ExperienceEditMediaUploadedProperties,
  ExperienceEditPageOpenedProperties,
  ExperienceEditPagePublishedErrorsProperties,
  ExperienceEditPagePublishedProperties,
  ExperienceEditPreviewProperties,
} from '../../../../Ampli';

export const trackExperienceEditPageOpened = (
  options: ExperienceEditPageOpenedProperties
) => {
  ampli.experienceEditPageOpened({
    ...options,
  });
};

export const trackExperienceEditDialogOpened = (
  options: ExperienceEditDialogOpenedProperties
) => {
  ampli.experienceEditDialogOpened({
    ...options,
  });
};

export const trackExperienceEditDialogClosed = (
  options: ExperienceEditDialogClosedProperties
) => {
  ampli.experienceEditDialogClosed({
    ...options,
  });
};

export const trackExperienceEditDialogSaved = (
  options: ExperienceEditDialogSavedProperties
) => {
  ampli.experienceEditDialogSaved({
    ...options,
  });
};

export const trackExperienceEditMediaUploaded = (
  options: ExperienceEditMediaUploadedProperties
) => {
  ampli.experienceEditMediaUploaded({
    ...options,
  });
};

export const trackExperienceEditMediaDeleted = (
  options: ExperienceEditMediaDeletedProperties
) => {
  ampli.experienceEditMediaDeleted({
    ...options,
  });
};

export const trackExperienceEditMediaDragged = () => {
  ampli.experienceEditMediaDragged();
};

export const trackExperienceEditLanguageChanged = (
  options: ExperienceEditLanguageChangedProperties
) => {
  ampli.experienceEditLanguageChanged({
    ...options,
  });
};

export const trackExperienceEditLanguageAutoTranslate = (
  options: ExperienceEditLanguageToggledProperties
) => {
  ampli.experienceEditLanguageToggled({
    ...options,
  });
};

export const trackExperienceEditPagePublished = (
  options: ExperienceEditPagePublishedProperties
) => {
  ampli.experienceEditFlowSaved({
    ...options,
  });
};

export const trackExperienceEditPagePublishedErrors = (
  options: ExperienceEditPagePublishedErrorsProperties
) => {
  ampli.experienceEditPagePublishedErrors({
    ...options,
  });
};

export const trackExperienceEditPreviewToggled = (
  options: ExperienceEditPreviewProperties
) => {
  ampli.experienceEditPreview({
    ...options,
  });
};
