import NiceModal from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router';
import { ValidationError } from 'yup';

import { ampli } from '../../../Ampli';
import {
  companyProfileQuery,
  draftExperienceQuery,
  locationsQuery,
  resourceTypesQuery,
  tagsQuery,
} from '../../../Api/queries';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useOnBoarding } from '../../../Hooks/useOnBoarding';
import { useProfile } from '../../../Hooks/useProfile';
import { StorefrontLanguage } from '../../../i18n/config';
import routes from '../../../Utils/routes';
import { experienceSchema } from '../schemas';
import { BasicExperienceFields as BasicExperienceFieldsType } from '../schemas/basicExperienceFieldsSchema';
import { BasicExperienceFields } from './basic-experience-fields';
import { AutoTranslateWarningDialog } from './components/dialogs/auto-translate-warning-dialog';
import EditExperienceErrorDialog from './components/dialogs/edit-experience-error-dialog';
import { OnboardingExperienceStartDialog } from './components/dialogs/onboarding-experience-start-dialog';
import { LanguageOptions } from './components/language-options';
import { Media } from './components/media/media';
import { DetailsOverview } from './details-overview/details-overview';
import { EditExperienceHeader } from './header/edit-experience-header';
import { MoreOptions } from './more-options';
import {
  AUTO_TRANSLATION_KEY,
  autoSaveHandler,
  publishHandler,
  REDIRECT_TO_KEY,
  toggleAutoTranslateHandler,
  uploadMediaHandler,
} from './submit-handlers';
import { publishOnboardingHandler } from './submit-handlers/publish-onboarding';
import { translateMissingTextsHandler } from './submit-handlers/translate-missing-texts';
import { getFormDataValue, getLanguageErrors } from './utils/form-helpers';
import { getActiveLanguage } from './utils/get-active-language';
import {
  trackExperienceEditLanguageAutoTranslate,
  trackExperienceEditLanguageChanged,
  trackExperienceEditPageOpened,
  trackExperienceEditPagePublished,
} from './utils/tracking-helper';

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loaderName = 'EditExperienceLoader';

export const loader =
  (client: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const id = params.id;
    if (!id) {
      throw new Response(null, {
        status: 404,
      });
    }

    const [experience, companyProfile, tags] = await Promise.all([
      client.fetchQuery(draftExperienceQuery(id)),
      client.fetchQuery(companyProfileQuery()),
      client.fetchQuery(tagsQuery()),
    ]);

    const locations = await client.fetchQuery(
      locationsQuery(companyProfile.id)
    );

    const activeLanguage = getActiveLanguage(request, companyProfile);
    const autoTranslate = experience.autoTranslateEnabled;

    const resourceTypesPromise = client.fetchQuery(resourceTypesQuery());

    return {
      tags,
      experience,
      activeLanguage,
      availableLanguages: companyProfile.languages as StorefrontLanguage[],
      locations,
      autoTranslate,
      defaultCurrency: companyProfile.defaultCurrency,
      vatRegistrations: companyProfile.vatCompliance.vatRegistrations,
      resourceTypesPromise,
    };
  };

export type PublishErrors = Record<
  StorefrontLanguage | 'general',
  Array<{ key: keyof Partial<RegularExperience>; index?: number }>
>;

export type ActionData = {
  publishErrors?: PublishErrors;
  unknownError?: boolean;
};

export const ACTION_KEYS = {
  publish: 'publish',
  toggleAutoTranslate: 'toggle-auto-translate',
  autosave: 'autosave',
  media: 'media',
  translateMissingText: 'translate-missing-text',
  publishOnboarding: 'publish-onboarding',
} as const;

export type ActionKey = (typeof ACTION_KEYS)[keyof typeof ACTION_KEYS];

export const action =
  (client: QueryClient) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const id = params.id;
    if (!id) {
      throw new Response(null, {
        status: 400,
      });
    }

    try {
      const [experience, companyProfile] = await Promise.all([
        client.fetchQuery(draftExperienceQuery(id)),
        client.fetchQuery(companyProfileQuery()),
      ]);

      const activeLanguage = getActiveLanguage(request, companyProfile);

      const formData = await request.formData();
      const action = getFormDataValue(formData.get('action'));

      if (action === ACTION_KEYS.publishOnboarding) {
        try {
          await publishOnboardingHandler(experience);
          return redirect(`${routes.dashboard}?fromOnboarding=true`);
        } catch (error) {
          if (error instanceof ValidationError) {
            const publishErrors = getLanguageErrors(error);
            const unexpectedErrors =
              publishErrors.general?.filter(
                (error) =>
                  ![
                    'headline',
                    'locationIds',
                    'price',
                    'resourceRules',
                  ].includes(error.key)
              ) ?? [];

            // Handle invalid/unexpected schema errors
            if (unexpectedErrors.length > 0) {
              throw new Error(
                `Unexpected error for keys: ${unexpectedErrors.map((error) => error.key).join(', ')}`
              );
            }

            return { publishErrors } as ActionData;
          }

          throw error;
        }
      }

      if (action === ACTION_KEYS.publish) {
        try {
          await publishHandler(id, experience);
          trackExperienceEditPagePublished({
            experience_id: id,
          });
          return redirect(routes.experience.details(id).index);
        } catch (error) {
          if (error instanceof ValidationError) {
            const publishErrors = getLanguageErrors(error);

            const unexpectedErrors =
              publishErrors.general?.filter(
                (error) =>
                  ![
                    'headline',
                    'locationIds',
                    'price',
                    'resourceRules',
                  ].includes(error.key)
              ) ?? [];

            // Handle invalid/unexpected schema errors
            if (unexpectedErrors.length > 0) {
              throw new Error(
                `Unexpected error for keys: ${unexpectedErrors.map((error) => error.key).join(', ')}`
              );
            }

            return { publishErrors } as ActionData;
          }
          throw error;
        }
      }

      if (action === ACTION_KEYS.media) {
        await uploadMediaHandler(id, experience, formData);
        return null;
      }

      if (action === ACTION_KEYS.toggleAutoTranslate) {
        // We show a dialog explaining the auto-translation feature when the users changes language.
        // The dialog allows turning off auto-translation, but when doing so we need to do two things;
        // - Submit this action
        // - Redirect the user to the new language
        // If doing the above separately, we can run into race conditions where the user is redirected
        // before the action is completed, causing the auto-translate toggle to show the wrong state.
        const redirect = await toggleAutoTranslateHandler(id, formData);
        if (redirect) return redirect;
        return null;
      }

      if (action === ACTION_KEYS.translateMissingText) {
        await translateMissingTextsHandler(
          id,
          experience,
          activeLanguage,
          companyProfile.languages
        );
        return null;
      }

      if (action === ACTION_KEYS.autosave) {
        await autoSaveHandler(
          id,
          experience,
          companyProfile,
          formData,
          activeLanguage
        );
        return null;
      }

      return null;
    } catch (error) {
      captureException(error);

      return { unknownError: true } as ActionData;
    }
  };

export default function EditExperience() {
  const {
    experience,
    locations,
    activeLanguage,
    availableLanguages,
    autoTranslate,
    tags,
    resourceTypesPromise,
  } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const [flowBeingSubmitted, setFlowBeingSubmitted] = useState<
    ActionKey | undefined
  >();
  const [shouldTriggerValidation, setShouldTriggerValidation] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const fireOnce = useFireOnce();

  const [publishErrors, setPublishErrors] = useState<PublishErrors | undefined>(
    actionData?.publishErrors
  );

  const { state } = useNavigation();

  useEffect(() => {
    if (state === 'idle') {
      setFlowBeingSubmitted(undefined);
    }
  }, [state]);

  useEffect(() => {
    if (!actionData) return;

    setPublishErrors(actionData.publishErrors);
    setShowErrorDialog(!!actionData.publishErrors);
  }, [actionData]);

  const submit = useSubmit();
  const location = useLocation();
  const navigate = useNavigate();

  const [isFromOnboarding] = useState(() =>
    Boolean(location.state?.fromOnboarding)
  );

  const [
    showOnboardingExperienceStartDialog,
    setShowOnboardingExperienceStartDialog,
  ] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOnboardingExperienceStartDialog(true);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const newExperience = Boolean(location.state?.newExperience);
    fireOnce(() => {
      trackExperienceEditPageOpened({
        experience_id: experience.id,
        experience_flow_type: isFromOnboarding
          ? 'onboarding'
          : newExperience
            ? 'create'
            : 'edit',
      });
    });
  }, [fireOnce, experience.id, location.state, isFromOnboarding]);

  // Validate experience after publish
  useEffect(() => {
    if (!actionData?.publishErrors && !shouldTriggerValidation) return;

    setShouldTriggerValidation(true);
    try {
      experienceSchema.validateSync(experience, { abortEarly: false });

      setPublishErrors(undefined);
      setShouldTriggerValidation(false);
    } catch (err) {
      if (err instanceof ValidationError) {
        const publishErrors = getLanguageErrors(err);

        setPublishErrors(publishErrors);
      } else {
        captureException(err);
      }
    }
  }, [actionData?.publishErrors, experience, shouldTriggerValidation]);

  const handleChangeAutoTranslate = useCallback(
    (isEnabled: boolean, redirectTo?: string) => {
      setFlowBeingSubmitted(ACTION_KEYS.toggleAutoTranslate);
      trackExperienceEditLanguageAutoTranslate({
        experience_id: experience.id,
        auto_translate: isEnabled,
      });
      submit(
        {
          action: ACTION_KEYS.toggleAutoTranslate,
          [AUTO_TRANSLATION_KEY]: isEnabled,
          ...(redirectTo && { [REDIRECT_TO_KEY]: redirectTo }),
        },
        { method: 'post' }
      );
    },
    [submit, experience.id]
  );

  const handleMissingTextTranslation = useCallback(() => {
    submit({ action: ACTION_KEYS.translateMissingText }, { method: 'post' });
  }, [submit]);

  const { me } = useProfile();

  const handleChangeLanguage = useCallback(
    async (language: StorefrontLanguage) => {
      trackExperienceEditLanguageChanged({
        experience_id: experience.id,
        language,
      });

      const newParams = new URLSearchParams(location.search);
      newParams.set('language', language);
      const redirectTo = `${location.pathname}?${newParams.toString()}`;

      if (
        autoTranslate &&
        me.data?.metadata?.preferences?.experiences
          ?.skipAutoTranslationWarning !== true
      ) {
        const choice = await NiceModal.show(AutoTranslateWarningDialog, {
          onDisableTranslations: () =>
            handleChangeAutoTranslate(false, redirectTo),
        });

        // We encounter a race condition where we would
        // navigate to the new language the action has completed
        // making the state of the auto-translate toggle incorrect
        // This lets the auto-translate changed handle the navigation
        if (choice === 'disable-translations') return;
      }

      navigate(redirectTo, { replace: true });
    },
    [
      location.search,
      location.pathname,
      autoTranslate,
      me.data?.metadata?.preferences?.experiences?.skipAutoTranslationWarning,
      experience.id,
      navigate,
      handleChangeAutoTranslate,
    ]
  );

  const handleAutoSaveSubmit = useCallback(
    (data: BasicExperienceFieldsType) => {
      setFlowBeingSubmitted(ACTION_KEYS.autosave);
      submit({ action: ACTION_KEYS.autosave, ...data }, { method: 'post' });
    },
    [submit]
  );

  const { updateStep } = useOnBoarding();

  const updateOnboardingProgress = useCallback(
    (completeStep: boolean = false) =>
      updateStep(
        experience,
        experienceSchema,
        'experience',
        'create',
        completeStep
      ),
    [experience, updateStep]
  );

  useEffect(() => {
    updateOnboardingProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience]);

  const handlePublish = useCallback(async () => {
    setFlowBeingSubmitted(ACTION_KEYS.publish);

    experienceSchema
      .validate(experience, { abortEarly: false, strict: true })
      // We only want to update the onboarding progress if the experience is valid
      .then(() => updateOnboardingProgress(true))
      // Intentionally left blank to prevent the error from being thrown
      .catch(() => {});

    if (isFromOnboarding) {
      submit({ action: ACTION_KEYS.publishOnboarding }, { method: 'post' });
    } else {
      submit({ action: ACTION_KEYS.publish }, { method: 'post' });
    }
  }, [experience, isFromOnboarding, submit, updateOnboardingProgress]);

  const handleMediaChange = useCallback(
    (data: MediaItem[]) => {
      setFlowBeingSubmitted(ACTION_KEYS.media);
      submit(
        { action: ACTION_KEYS.media, data: JSON.stringify(data) },
        {
          method: 'post',
          encType: 'multipart/form-data',
        }
      );
    },
    [submit]
  );

  return (
    <Stack sx={{ minHeight: '100vh' }}>
      <EditExperienceHeader
        experience={experience}
        handlePublish={handlePublish}
        unknownError={actionData?.unknownError}
        flowBeingSubmitted={flowBeingSubmitted}
        isFromOnboarding={isFromOnboarding}
      />
      <ExperienceFormWrapper>
        <Stack gap={{ xs: 3, md: 2 }}>
          <Stack
            sx={{
              display: isFromOnboarding ? 'none' : 'flex',
              alignItems: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <LanguageOptions
              key={activeLanguage + publishErrors}
              activeLanguage={activeLanguage}
              availableLanguages={availableLanguages}
              handleChangeLanguage={handleChangeLanguage}
              isAutoTranslateEnabled={autoTranslate}
              handleChangeAutoTranslate={handleChangeAutoTranslate}
              publishErrors={publishErrors}
            />
          </Stack>
          <Media
            experience={experience}
            handleMediaChange={handleMediaChange}
          />
        </Stack>
        <Stack sx={{ gap: 2 }}>
          <BasicExperienceFields
            key={activeLanguage + experience.headline[activeLanguage]}
            locations={locations}
            defaultValues={{
              locationIds: experience.locationIds ?? [],
              visibility: experience.visibility,
              headline: experience.headline?.[activeLanguage] ?? '',
            }}
            onSubmit={handleAutoSaveSubmit}
            publishErrors={publishErrors}
            activeLanguage={activeLanguage}
            isFromOnboarding={isFromOnboarding}
          />
          <DetailsOverview
            experience={experience}
            activeLanguage={activeLanguage}
            tags={tags}
            publishErrors={publishErrors}
            resourceTypesPromise={resourceTypesPromise}
            locations={locations}
          />
        </Stack>
        <MoreOptions experience={experience} />
      </ExperienceFormWrapper>
      {showErrorDialog && (
        <EditExperienceErrorDialog
          experienceId={experience.id}
          errors={publishErrors}
          handleMissingTextTranslation={handleMissingTextTranslation}
          closeDialog={() => setShowErrorDialog(false)}
        />
      )}
      {isFromOnboarding && (
        <OnboardingExperienceStartDialog
          open={showOnboardingExperienceStartDialog}
          onClose={() => {
            setShowOnboardingExperienceStartDialog(false);
            ampli.experienceEditOnboardingStartDialogClosed();
          }}
        />
      )}
    </Stack>
  );
}

const CONTENT_MAX_WIDTH_PX = 900;

const ExperienceFormWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        maxWidth: CONTENT_MAX_WIDTH_PX,
        padding: { xs: 2, md: 4 },
        paddingBottom: { xs: 4, md: 8 },
        backgroundColor: lightTheme.palette.contrast.surface2,
        boxShadow: { xs: 'none', sm: lightTheme.shadows.xlarge },
        borderRadius: { md: '20px 20px 0px 0px' },
        gap: { xs: 4, md: 3 },
        marginX: 'auto',
      }}
    >
      {children}
    </Stack>
  );
};
