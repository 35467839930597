import styled from '@emotion/styled';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Grid, Skeleton, Stack } from '@mui/material';
import { Experience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import {
  Domain,
  ExperiencesGetWaitlistResponse,
  PublicCompanyProfile,
} from '@understory-io/utils-types';
import { TFunction } from 'i18next';
import { ReactNode, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, Link } from 'react-router';

import { ampli } from '../../../Ampli';
import { GetEventsInExperienceResponse } from '../../../Api/Event';
import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { CopyLink } from '../../../Components/CopyLink/CopyLink';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useLanguages } from '../../../Hooks/locales/use-languages';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { StorefrontLanguage } from '../../../i18n/config';
import { trackConnectLinkClicked } from '../../../tracking/connect/connect-events';
import routes from '../../../Utils/routes';
import { renderCutoffLabel } from '../utils/render-duration';
import { ExperienceDetailsCard } from './experience-details-card';
import { WaitlistAction } from './waitlist/waitlist-action';

export type ExperienceDetailsItem = {
  key: string;
  label: string;
  value: string;
  link?: string;
  onClickTrackingFn?: () => void;
  onCopyTrackingFn?: () => void;
}[];

export const ExperienceDetailsGrid = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  const { isSm } = useResponsive();
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={1}>
      <ExperienceDetailsCard showDivider>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
            paddingY: 1,
          }}
          onClick={() => setOpen((prev) => !prev)}
        >
          <Text variant="medium" fontSize="small">
            {label}
          </Text>
          {open ? (
            <ExpandLessOutlined sx={{ display: { md: 'none' } }} />
          ) : (
            <ExpandMoreOutlined sx={{ display: { md: 'none' } }} />
          )}
        </Stack>
        {(!isSm || open) && (
          <Grid container columns={isSm ? 3 : 2} spacing={1.5}>
            {children}
          </Grid>
        )}
      </ExperienceDetailsCard>
    </Grid>
  );
};

const ExperienceDetailsGridItem = ({
  label,
  value,
  link,
  onClickTrackingFn,
  onCopyTrackingFn,
  children,
}: {
  label: string;
  value: string;
  link?: string;
  onClickTrackingFn?: () => void;
  onCopyTrackingFn?: () => void;
  children?: ReactNode;
}) => {
  return (
    <>
      <Grid item xs={1}>
        <Text fontSize="xsmall">{label}</Text>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Stack direction="row" gap={1} alignItems="top">
          <Text
            fontSize="xsmall"
            variant="medium"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
            }}
            title={value}
          >
            {link ? (
              <CopyLink
                href={link}
                label={value}
                onClickText={() => onClickTrackingFn?.()}
                onClickCopy={() => onCopyTrackingFn?.()}
              />
            ) : (
              value
            )}
          </Text>
          {children}
        </Stack>
      </Grid>
    </>
  );
};

const ExperienceDetailsGridItemSkeleton = ({ label }: { label: string }) => {
  return (
    <>
      <Grid item xs={1}>
        <Text fontSize="xsmall">{label}</Text>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Skeleton height={16} width={150} />
      </Grid>
    </>
  );
};

const StyledLink = styled(Link)({
  fontSize: '12px',
  color: lightTheme.palette.action.a200,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
});

export const ExperienceDetails = ({
  experience,
  upcomingEventsPromise,
  waitListPromise,
  sharedExperienceOwnerProfilePromise,
  distributorNamesPromise,
}: {
  experience: Experience;
  upcomingEventsPromise: Promise<GetEventsInExperienceResponse>;
  waitListPromise: Promise<ExperiencesGetWaitlistResponse>;
  sharedExperienceOwnerProfilePromise: Promise<PublicCompanyProfile> | null;
  distributorNamesPromise: Promise<string>;
}) => {
  const { t } = useTranslation();
  const { languageOption } = useLanguages();

  return (
    <>
      <Suspense
        fallback={
          <ExperienceDetailsGridItemSkeleton
            label={t('experience.details.label.events')}
          />
        }
      >
        <Await resolve={upcomingEventsPromise}>
          {({ totalCount }) => (
            <ExperienceDetailsGridItem
              label={t('experience.details.label.events')}
              value={t('experience.details.value.events', {
                count: totalCount,
              })}
            />
          )}
        </Await>
      </Suspense>
      <Suspense
        fallback={
          <ExperienceDetailsGridItemSkeleton
            label={t('experience.details.label.guests')}
          />
        }
      >
        <Await resolve={upcomingEventsPromise}>
          {({ totalBooked, totalSeats }) => {
            return (
              <ExperienceDetailsGridItem
                label={t('experience.details.label.guests')}
                value={t('experience.details.value.guests', {
                  bookedCount: totalBooked,
                  totalCount: totalSeats,
                })}
              >
                <WaitlistAction waitListPromise={waitListPromise} />
              </ExperienceDetailsGridItem>
            );
          }}
        </Await>
      </Suspense>
      <ExperienceDetailsGridItem
        label={t('experience.details.label.language')}
        value={experience.languages
          .map((locale) => {
            const { label, country } = languageOption(
              locale as StorefrontLanguage
            );
            return country ? `${label} (${country})` : label;
          })
          .join(', ')}
      />
      {(experience.cutoff || experience.cutoffTimeSeconds) && (
        <ExperienceDetailsGridItem
          label={t('experience.details.label.cutoffTime')}
          value={renderCutoffLabel(
            t as TFunction,
            experience.cutoff,
            experience.cutoffTimeSeconds
          )}
        />
      )}
      <ShowForScope scopes={['admin']}>
        {sharedExperienceOwnerProfilePromise && (
          <Suspense
            fallback={
              <ExperienceDetailsGridItemSkeleton
                label={t('experience.details.label.owner')}
              />
            }
          >
            <Await resolve={sharedExperienceOwnerProfilePromise}>
              {(ownerCompanyProfile) => (
                <ExperienceDetailsGridItem
                  label={t('experience.details.label.owner')}
                  value={ownerCompanyProfile.name ?? ''}
                >
                  <StyledLink
                    to={routes.settings.connect}
                    onClick={() => trackConnectLinkClicked('experience')}
                  >
                    {t('experience.details.value.connectLink')}
                  </StyledLink>
                </ExperienceDetailsGridItem>
              )}
            </Await>
          </Suspense>
        )}
        {/* 
          Intentionally leaving out a fallback since we don't know 
          if there will be a distributor name to display before
          getting fetching connnections
          */}
        <Suspense>
          <Await resolve={distributorNamesPromise}>
            {(distributorNames) =>
              distributorNames && (
                <ExperienceDetailsGridItem
                  label={t('experience.details.label.distributor')}
                  value={distributorNames}
                >
                  <StyledLink
                    to={routes.settings.connect}
                    onClick={() => trackConnectLinkClicked('experience')}
                  >
                    {t('experience.details.value.connectLink')}
                  </StyledLink>
                </ExperienceDetailsGridItem>
              )
            }
          </Await>
        </Suspense>
      </ShowForScope>
    </>
  );
};

export const ExperienceOtherDetails = ({
  experience,
  storefrontDetailsDomainPromise,
  storefrontBookingFlowDomainPromise,
}: {
  experience: Experience;
  storefrontDetailsDomainPromise: Promise<Domain>;
  storefrontBookingFlowDomainPromise: Promise<Domain>;
}) => {
  const { t } = useTranslation();

  const { getLocalizedString } = useLocale();

  return (
    <>
      <ExperienceDetailsGridItem
        label={t('experience.details.label.creationDate')}
        value={renderDateTime(experience.dates.created)}
      />
      <Suspense
        fallback={
          <ExperienceDetailsGridItemSkeleton
            label={t('experience.details.label.detailsPage')}
          />
        }
      >
        <Await resolve={storefrontDetailsDomainPromise}>
          {({ domain }) => (
            <ExperienceDetailsGridItem
              label={t('experience.details.label.detailsPage')}
              value={t('experience.details.value.detailsPage')}
              link={`https://${domain}/experience/${experience.id}`}
            />
          )}
        </Await>
      </Suspense>
      <Suspense
        fallback={
          <ExperienceDetailsGridItemSkeleton
            label={t('experience.details.label.bookingLink')}
          />
        }
      >
        <Await resolve={storefrontBookingFlowDomainPromise}>
          {({ domain }) => (
            <ExperienceDetailsGridItem
              label={t('experience.details.label.bookingLink')}
              value={t('experience.details.value.bookingLink')}
              link={`https://${domain}/experience/${experience.id}`}
              onCopyTrackingFn={() =>
                ampli.experienceBookingFlowLinkCopied({
                  is_private: experience.visibility === 'private',
                  experience_name: getLocalizedString(experience.headline),
                  experience_id: experience.id,
                  language_list: experience.languages,
                })
              }
              onClickTrackingFn={() =>
                ampli.experienceBookingFlowLinkOpened({
                  experience_id: experience.id,
                  experience_name: getLocalizedString(experience.headline),
                  language_list: experience.languages,
                  is_private: experience.visibility === 'private',
                })
              }
            />
          )}
        </Await>
      </Suspense>
    </>
  );
};
