import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../../Api';
import { eventsInExperienceQuery } from '../../Api/queries';
import { TEventFilter } from '../../Utils/eventHelpers';

export const EVENT_LIST_MAX_EVENTS_PER_PAGE = 100;

export const useExperienceEvents = (
  experienceId?: string,
  eventFilter: TEventFilter = { state: 'future', status: 'anyStatus' },
  page: number = 1,
  limit: number = EVENT_LIST_MAX_EVENTS_PER_PAGE
) => {
  const queryClient = useQueryClient();

  const EventsForExperienceQueryKey = ['events', experienceId];

  const eventsForExperience = useQuery(
    eventsInExperienceQuery(experienceId ?? '', { ...eventFilter, page, limit })
  );

  return {
    eventsForExperience,
    EventsForExperienceQueryKey,
    refetch: () =>
      queryClient.invalidateQueries({
        queryKey: EventsForExperienceQueryKey,
      }),
  };
};
