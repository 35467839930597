import { ArrowForwardRounded } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Link,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Button } from '@understory-io/pixel';
import { ReactNode, useState } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TERMS_URL } from '../../Utils/constants';

const Styled = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  flexGrow: 1,
  ['& > *:not(:last-child)']: {
    marginBottom: 16,
    marginTop: 0,
  },
});

export type TInput<T> = TextFieldProps & {
  key: Extract<keyof T, string>;
  isRequired: boolean;
};

interface IAuthFormProps {
  inputs: Array<
    {
      key: string;
      isRequired: boolean;
    } & TextFieldProps
  >;
  buttonLabel?: string;
  onSubmit: (data: any) => void;
  shouldAcceptTerms?: boolean;
  children?: ReactNode;
  errors?: FieldErrors;
}

export const AuthForm = ({
  children,
  inputs,
  buttonLabel,
  shouldAcceptTerms = false,
  onSubmit,
  errors,
}: IAuthFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useFormContext();

  const _onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      await onSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Set defaultValue property on TextField, as using setValue won't move the label away.
  // According to the GitHub issue posted here, https://github.com/react-hook-form/react-hook-form/issues/220#issuecomment-649174176,
  // setting the defaultValue to anything but undefined, should resolve the issue.
  return (
    <Styled onSubmit={handleSubmit(_onSubmit)}>
      <Box>
        {inputs.map(({ isRequired, key, ...el }) => {
          return (
            <TextField
              key={key}
              margin="dense"
              id={key}
              label={t(`auth.inputs.${key}`)}
              defaultValue=""
              {...register(key, {
                ...(isRequired && { required: t('utils.errors.required') }),
              })}
              error={Boolean(errors?.[key] ?? formErrors?.[key])}
              helperText={
                (errors?.[key]?.message ?? formErrors?.[key]?.message) as string
              }
              fullWidth
              variant="outlined"
              {...el}
            />
          );
        })}
        {children}
      </Box>

      {shouldAcceptTerms && (
        <Box mb={2}>
          <Typography ml={0} lineHeight={'1.5'}>
            {t('utils.generic.IAccept')}{' '}
            <Link href={TERMS_URL} target={'_blank'}>
              Holdbars {t('utils.generic.terms')}
            </Link>
          </Typography>
        </Box>
      )}

      {buttonLabel ? (
        <Button
          type={'submit'}
          disabled={isSubmitting}
          leftIcon={
            isSubmitting && <CircularProgress size={'1em'} color={'inherit'} />
          }
          size={'medium'}
          variant={'primary'}
        >
          {buttonLabel}
        </Button>
      ) : (
        <Box
          component={ButtonBase}
          p={'12px'}
          fontSize={28}
          borderRadius={'20px'}
          display={'flex'}
          height={72}
          width={72}
          color={'white'}
          type={'submit'}
          sx={{
            mx: 'auto',
            mt: { xs: '40px', md: '32px' },
            mb: 0,
            backgroundColor: 'primary.main',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.07)',
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={18} color={'inherit'} />
          ) : (
            <ArrowForwardRounded fontSize={'inherit'} htmlColor={'white'} />
          )}
        </Box>
      )}
    </Styled>
  );
};
