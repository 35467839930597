import {
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { useLocale } from '../../../../../../Hooks/locales/use-locale.context';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useResourceReservations } from '../../../../../resource-management/data/use-resource-reservations';
import { TBookingRow } from '../../../domain/types';
import { useBookingDetailsContext } from '../../../domain/use_booking_details_context';
import { useBookingDetailsActions } from '../../booking_details_actions/domain/use_booking_details_actions';

type BookingDetailsSummaryTableProps = {
  rows: TBookingRow[];
};

export const BookingDetailsSummaryTable = ({
  rows,
}: BookingDetailsSummaryTableProps) => {
  const { t, i18n } = useTranslate('dialogs.booking');

  const { booking, eventMovedFrom, eventMovedTo } = useBookingDetailsContext();
  const { handleCheckInBooking } = useBookingDetailsActions();
  const { data: resourceReservations } = useResourceReservations(
    booking?.eventId ?? '',
    booking?.id
  );

  const { getLocalizedString } = useLocale();

  return (
    <StyledTable>
      <TableBody>
        {rows.map((row) => {
          const rowValueNode = booking
            ? row.value(booking, t, {
                language: i18n.language ?? 'da',
                eventMovedFrom,
                eventMovedTo,
                handleCheckInBooking,
                getLocalizedString,
                resourceReservations: resourceReservations?.items.filter(
                  (x) => x.allocationType !== 'event'
                ),
              })
            : null;

          if (!rowValueNode) {
            return null;
          }

          return (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row" sx={{ display: 'block' }}>
                {t(row.key)}
              </TableCell>
              <TableCell>
                {booking ? rowValueNode : <Skeleton width="50%" />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export const StyledTable = styled(Table)(() => ({
  border: 'none',
  borderCollapse: 'collapse',
  '& th, td': {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    fontSize: 'inherit',
    color: '#000',
    border: 'none',
    padding: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '& td:first-of-type': {
    width: '40%',
  },
  '& td:last-child': {
    width: '60%',
  },
}));
