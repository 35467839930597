import {
  CompanyProfile,
  ReducedVat,
  VatCompliance,
} from '@understory-io/utils-types';
import isEqual from 'lodash.isequal';

import { VatFormData } from '../../../../Modals/vat-form/domain/use-vat-form-validation';
import { CountryVatRates } from './types';

export const getVatCompliance = (
  { vatCompliance }: VatFormData,
  countryVatRates?: CountryVatRates,
  company?: CompanyProfile
): VatCompliance => {
  const companyVatRegistration = company?.vatCompliance?.vatRegistrations?.[0];
  const companyLastUpdated = company?.vatCompliance?.lastUpdated;
  const hasCountry = Boolean(vatCompliance.vatRegistration?.country);

  if (!countryVatRates) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  if (!hasCountry) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  if (Object.keys(vatCompliance.vatRegistration).length === 0) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  const isSameRegistration = isEqual(
    companyVatRegistration,
    vatCompliance.vatRegistration
  );

  const lastUpdated =
    isSameRegistration && companyLastUpdated
      ? companyLastUpdated
      : new Date().toISOString();

  if (!vatCompliance?.vatRegistration) {
    return {
      lastUpdated,
      vatRegistrations: [],
    };
  }

  const rates = getRates(
    countryVatRates,
    vatCompliance.vatRegistration.country
  );

  const { country, vatNumber, defaultVatCategory } =
    vatCompliance.vatRegistration;

  return {
    lastUpdated,
    vatRegistrations: vatCompliance.isVatRegistered
      ? [
          {
            country,
            vatNumber,
            defaultVatCategory,
            rates,
          },
        ]
      : [],
  };
};

function getRates(
  countryVatRates: CountryVatRates | undefined,
  country: string
): VatCompliance['vatRegistrations'][number]['rates'] {
  const rates = countryVatRates?.[country]?.rates;
  if (!rates) {
    return {
      reduced: [],
      standard: 0,
    };
  }

  return {
    ...rates,
    reduced: rates.reduced.flatMap<{
      [key: string]: ReducedVat;
    }>((reduced) => {
      const [rateName, value] = Object.entries(reduced)[0];

      if ('exempt' in value && value.exempt) {
        return {
          [rateName]: {
            exempt: true,
            rate: 0,
          },
        };
      }

      if ('rate' in value) {
        return {
          [rateName]: {
            rate: value.rate,
          },
        };
      }

      return [];
    }),
  };
}
