import { useQuery } from '@tanstack/react-query';

import * as api from '../../../../../Api';

export const useAvailableScopes = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['public-api', 'scopes'],

    queryFn: async () => api.getScopes(),

    enabled: true,
    retry: false,
  });

  return {
    // transform the list of scopes into a map of scopes and the read/write
    // permissions. This allows for showing scope catagories based on the first
    // parts of a scope, eg. `user.read` and `user.write` will be grouped under `user
    data: data?.reduce<Record<string, { [action: string]: boolean }>>(
      (acc, scope) => {
        const [base, action] = scope.split('.');
        if (!acc[base]) {
          acc[base] = {};
        }
        acc[base][action] = true;
        return acc;
      },
      {}
    ),
    ...rest,
  };
};
