import { Divider, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  Await,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateCompanyProfile } from '../../../Api';
import { companyProfileQuery } from '../../../Api/queries';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import { trackStorefrontPaymentsViewed } from '../../../tracking/storefront/payments-events';
import { CurrencyInput } from './currency-input';
import { TaxRatesSetup } from './tax/tax-rates-setup';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const companyProfilePromise = client.fetchQuery(companyProfileQuery());

  return { companyProfilePromise };
};

export default function StorefrontPaymentsSection() {
  const { companyProfilePromise } = useLoaderData<LoaderData>();

  return (
    <Await resolve={companyProfilePromise}>
      {(companyProfile) => (
        <StorefrontPayments companyProfile={companyProfile} />
      )}
    </Await>
  );
}

export type StorefrontPaymentsFormData = Pick<
  CompanyProfile,
  'defaultCurrency' | 'vatCompliance'
>;

function StorefrontPayments({
  companyProfile,
}: {
  companyProfile: CompanyProfile;
}) {
  const actionData = useActionData<ActionData>();
  const { state } = useNavigation();

  const submit = useSubmit();

  const formMethods = useForm<StorefrontPaymentsFormData>({
    defaultValues: companyProfile,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = formMethods;

  useEffect(() => {
    if (actionData?.updatedCompanyProfile) {
      reset(actionData.updatedCompanyProfile);
    }
  }, [actionData, reset]);

  const onSubmit = (data: StorefrontPaymentsFormData) => {
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(trackStorefrontPaymentsViewed), [fireOnce]);

  const vatRegistration = watch('vatCompliance.vatRegistrations.0');

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={4}>
          <Stack gap={3}>
            <Stack gap={1}>
              <Text variant="medium">{t('storefront.payments.title')}</Text>
              <Text fontSize="small">
                {t('storefront.payments.description')}
              </Text>
            </Stack>
            <Stack>
              <Text
                style={{ paddingLeft: 8 }}
                fontSize="xsmall"
                variant="medium"
              >
                Currency
              </Text>
              <CurrencyInput />
            </Stack>
          </Stack>
          {vatRegistration && (
            <>
              <Divider />
              <TaxRatesSetup vatRegistration={vatRegistration} />
            </>
          )}
          <FixedActionsBar
            onReset={() => reset()}
            isSubmitting={state === 'submitting'}
            unsavedChanges={isDirty}
          />
        </Stack>
      </Form>
    </FormProvider>
  );
}

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { defaultCurrency, vatCompliance } =
        (await request.json()) as StorefrontPaymentsFormData;

      const companyProfile = await client.fetchQuery(companyProfileQuery());

      const updatedCompanyProfile: CompanyProfile = {
        ...companyProfile,
        vatCompliance,
        defaultCurrency,
      };

      await updateCompanyProfile(updatedCompanyProfile);

      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });

      return { updatedCompanyProfile };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
