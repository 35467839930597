import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { startTransition } from 'react';
import { useTranslation } from 'react-i18next';

export const YesNoSelect = ({
  label,
  sx = {},
  value,
  onChange,
  ...props
}: SelectProps) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        input={<OutlinedInput notched label={label} />}
        value={value}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
            },
          },
        }}
        onChange={(...params) => {
          startTransition(() => {
            onChange?.(...params);
          });
        }}
        {...props}
        sx={{
          maxHeight: 48,
          ...sx,
        }}
      >
        <MenuItem value={'true'} key={'yes'}>
          <Typography>{t('utils.generic.yes')}</Typography>
        </MenuItem>
        <MenuItem value={'false'} key={'no'}>
          <Typography>{t('utils.generic.no')}</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
