import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Oauth2Consent } from '../Api';
import DialogBase from '../Components/dialog/dialog-base';
import { useTranslate } from '../Hooks/useTranslate';
import { useUsers } from '../Hooks/useUsers';
import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

const ignoredTechnicalScopes = [
  'offline',
  'offline_access',
  'openid',
  'refresh',
];

export const PublicApiOauth2IntegrationDialog = NiceModal.create(
  ({
    client,
    consents,
    onRevoke,
  }: {
    client: Oauth2Consent['client'];
    consents: Oauth2Consent[];
    onRevoke: () => Promise<void>;
  }) => {
    const { t } = useTranslate('settings.integrations.oauth2');

    const [revoking, setRevoking] = useState(false);

    const modal = useModal();

    const [consent] = consents;
    const { users } = useUsers();

    let displayUser = consent.grantedBy.email;

    // the user can be null if the one granting OAuth2 access is no longer part
    // of the company
    const user = users.data?.find((r) => r.id === consent.grantedBy.id);
    if (user && user.name) {
      displayUser = `${user.name} (${user.email})`;
    }

    const handleRevoke = async () => {
      setRevoking(true);
      try {
        try {
          await NiceModal.show(ConfirmDialog, {
            headline: t('revokeConfirmation.title'),
            description: t('revokeConfirmation.description', {
              clientName: client.name,
            }),
            confirmLabel: t('revokeConfirmation.confirmLabel'),
            important: true,
          } as ConfirmDialogProps);
        } catch (error) {
          // the confirmation was cancelled so we do nothing
          return;
        }

        await onRevoke();

        modal.resolve();
        modal.remove();
      } catch (error) {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
        throw error;
      } finally {
        setRevoking(false);
        modal.hide();
      }
    };

    const visibleScopes = useMemo(() => {
      const scopes = consents.flatMap((consent) => consent.scopes);
      return Array.from(new Set(scopes)).filter(
        (s) => !ignoredTechnicalScopes.includes(s)
      );
    }, [consents]);

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <>
        <DialogBase
          title={t('title', {
            clientName: client.name,
          })}
          logoUri={client.logoUri}
          badge={{
            children: t('active', 'utils.stateChips'),
            appearance: 'success',
            size: 'small',
          }}
          onClose={handleClose}
          description={t('description', { clientName: client.name })}
          showSecondaryActionDesktop
          maxWidth={702}
          primaryAction={{
            label: t('revokeButton'),
            variant: 'secondary',
            onClick: handleRevoke,
            disabled: revoking,
          }}
        >
          <StyledStack>
            <Text
              variant="medium"
              fontSize="small"
              color={lightTheme.palette.contrast.black}
            >
              {t('scopesTitle', { clientName: client.name })}
            </Text>
            <StyledList>
              {visibleScopes.map((scope) => (
                <StyledListItem key={scope}>
                  <Text variant="medium" fontSize="small">
                    {/* scope '.' are replaced with '_' to make translations in POEditor easier to manage */}
                    {t('scopes.' + scope.replaceAll('.', '_'))}
                  </Text>
                </StyledListItem>
              ))}
            </StyledList>

            <Text
              fontSize="xsmall"
              variant="medium"
              color={lightTheme.palette.neutral.n300}
            >
              {t('connectedDescription', {
                date: renderDateTime(consent.grantedAt),
                user: displayUser,
              })}
            </Text>
          </StyledStack>
        </DialogBase>
      </>
    );
  }
);

const StyledList = styled('ul')({
  paddingLeft: '32px',
  paddingRight: '32px',
  marginBottom: '24px',
});

const StyledListItem = styled('li')({
  marginTop: '16px',
});

const StyledStack = styled(Stack)({
  backgroundColor: lightTheme.palette.contrast.surface2,
  padding: '24px',
  borderRadius: '16px',
  marginBottom: '8px',
});
