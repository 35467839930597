import { useQuery } from '@tanstack/react-query';
import { addDays, subDays, subMinutes } from 'date-fns';

import * as api from '../../Api';
import { EventOptions } from '../../Api/Event';
import { queryClient } from '../../query-client';
import { TEvent, transformEvents } from '../../Utils/eventHelpers';
import { getEventQueryKey } from './useGetEvent';

const EVENTS_QUERY_KEY = 'events';

export const getEventsQueryKey = (options?: EventOptions) =>
  options
    ? [
        EVENTS_QUERY_KEY,
        {
          from: options?.from?.toISOString(),
          to: options?.to?.toISOString(),
          locationIds: options?.locationIds,
          states: options?.states,
        },
      ]
    : [EVENTS_QUERY_KEY];

export const useGetEvents = (options?: EventOptions) => {
  adjustFromAndTo(options);

  const eventsQueryKey = getEventsQueryKey(options);

  const events = useQuery<TEvent[]>({
    queryKey: eventsQueryKey,
    queryFn: fetchEvents(options),
  });

  return { events };
};

export const usePrefetchEvents = (options?: EventOptions) => {
  adjustFromAndTo(options);
  const EventsQueryKey = getEventsQueryKey(options);

  queryClient.prefetchQuery({
    queryKey: EventsQueryKey,
    queryFn: fetchEvents(options),
  });
};

/**
 * To avoid issues with timezones when querying the API, we subtract one day from `from`, and adds one day to `to`.
 *
 * The problem:
 * If you are in a timezone that is behind UTC, for example New York, a from query from "2025-02-23T00:00:00.000-05:00"
 * will be converted to "2025-02-23T05:00:00.000Z" when querying to the API. This causes us to maybe not show events from 0 to 5.
 */
const adjustFromAndTo = (options?: EventOptions) => {
  if (options?.from) {
    options.from = subDays(options.from, 1);
  }

  if (options?.to) {
    options.to = addDays(options.to, 1);
  }
};

const fetchEvents = (options?: EventOptions) => async () => {
  const events = await api.getEvents(options);
  const transformed = transformEvents(events);
  for (const el of transformed) {
    queryClient.setQueryData<TEvent>(getEventQueryKey(el.id), el);
  }

  return transformed;
};
