import { Loader } from '@googlemaps/js-api-loader';
import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getPlaceAddress } from './get-place-address';
import { getPlaceDetails } from './get-place-details';

export type AddressSearchResult = {
  street: string;
  city: string;
  postCode: string;
  state: string;
  country: string;
  geoCoordinates?: {
    latitude: number;
    longitude: number;
    source: string;
  };
  timezone?: string;
};

export const useAddressSearch = (query: string) => {
  const { t, i18n } = useTranslation();
  const [service, setService] = useState<google.maps.places.PlacesService>();
  const [results, setResults] = useState<google.maps.places.PlaceResult[]>([]);

  const [searchQuery, setSearchQuery] = useState(query);

  /**
   * Debounce search query to prevent too many requests
   */
  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearchQuery(query);
    }, 50);
    return () => clearTimeout(debounce);
  }, [query]);

  /**
   * Start Google Places service
   */
  useEffect(() => {
    if (service) return;
    try {
      if (typeof process.env.REACT_APP_GOOGLE_API_KEY === 'undefined') {
        throw new Error(
          'Environment variable REACT_APP_GOOGLE_API_KEY is not defined'
        );
      }

      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['places'],
      });

      loader.importLibrary('places').then((placesModule) => {
        const { PlacesService } = placesModule;
        const service = new PlacesService(document.createElement('div'));

        setService(service);
      });
    } catch (error) {
      captureException(error);
    }
  }, [service]);

  /**
   * Get search results
   */
  useEffect(() => {
    if (!service) return;
    if (!searchQuery) return setResults([]);

    try {
      const request: google.maps.places.TextSearchRequest = {
        query: searchQuery,
        language:
          i18n.language === 'en-US'
            ? 'en'
            : i18n.language === 'en'
              ? 'en-GB'
              : i18n.language,
      };

      service.textSearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setResults(
            results?.filter((result) => Boolean(result.place_id)) ?? []
          );
        }
      });
    } catch (error) {
      captureException(error);
    }
  }, [i18n.language, searchQuery, service]);

  const getAddress = useCallback(
    async (placeId: string) => {
      const fallbackAddress: AddressSearchResult = {
        street: '',
        city: '',
        postCode: '',
        state: '',
        country: '',
      };

      try {
        if (!service) throw new Error('Service not started');

        const placeDetails = await getPlaceDetails(service, placeId);
        const address = await getPlaceAddress(placeDetails);

        return address;
      } catch (error) {
        toast.error(t('utils.error.generic'));
        captureException(error);
        return fallbackAddress;
      }
    },
    [service, t]
  );

  return {
    searchResults: results,
    getPlaceAddress: getAddress,
  };
};
