import routes from '../../../Utils/routes';

export type Suggestion = {
  id: string;
  duration: number;
  isCompleted: boolean;
  route: string;
};

export const AVAILABLE_SUGGESTIONS: Suggestion[] = [
  {
    id: 'createExperience',
    duration: 9,
    isCompleted: false,
    route: routes.experience.overview,
  },
  {
    id: 'styleElements',
    duration: 3,
    isCompleted: false,
    route: routes.storefrontSettings.customize,
  },
  {
    id: 'companyDetails',
    duration: 3,
    isCompleted: false,
    route: routes.storefrontSettings.company.index,
  },
  {
    id: 'integrateWebsite',
    duration: 10,
    isCompleted: false,
    route: routes.storefrontSettings.integration,
  },
  {
    id: 'languageAndCurrency',
    duration: 2,
    isCompleted: false,
    route: routes.storefrontSettings.languages,
  },
];

export const MAX_VISIBLE_SUGGESTIONS = 3;
