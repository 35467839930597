import * as yup from 'yup';

import { StorefrontLanguage } from '../../../i18n/config';
import { localizedSchema } from './genericSchemas';

export const vatSettingSchema = yup.object().shape({
  country: yup.string().optional(),
  vatCategory: yup
    .string()
    .typeError('utils.validationErrors.invalidString')
    .required('utils.validationErrors.required'),
  exempt: yup.boolean().optional(),
  rate: yup.number().optional(),
});
export type VatSetting = yup.InferType<typeof vatSettingSchema>;

export const priceBreakdownSchema = yup.object().shape({
  currency: yup.string().optional(),
  vatInclusivePriceCents: yup
    .number()
    .typeError('utils.validationErrors.required')
    .required('utils.validationErrors.required')
    .min(0, 'utils.validationErrors.greaterThan'),
  vatAmountCents: yup
    .number()
    .typeError('utils.validationErrors.invalidNumber')
    .optional(),
  vatExclusivePriceCents: yup
    .number()
    .typeError('utils.validationErrors.invalidNumber')
    .optional(),
  vatSetting: vatSettingSchema,
});
export type PriceBreakdown = yup.InferType<typeof priceBreakdownSchema>;

export const variantAddonSchema = (activeLanguage?: StorefrontLanguage) =>
  yup.object().shape({
    id: yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString'),
    // vatId: yup.string().optional(),
    // vatRate: yup.mixed().oneOf([yup.number(), yup.string()]).optional(),
    // hasVatRate: yup.boolean().defaulfalse).optional(),
    explanation: localizedSchema(activeLanguage, true).optional(),
    name: localizedSchema(activeLanguage),
    price: yup.number().typeError('utils.validationErrors.invalidNumber'),
    priceBreakdown: priceBreakdownSchema,
  });
export type VariantAddon = yup.InferType<ReturnType<typeof variantAddonSchema>>;

export const variantSchema = (activeLanguage?: StorefrontLanguage) =>
  yup.object().shape({
    id: yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString'),
    name: localizedSchema(activeLanguage),
    explanation: localizedSchema(activeLanguage, true).optional(),
    // vatId: yup.string().optional(),
    // vatRate: yup.string().optional(),
    isDisplayPrice: yup
      .boolean()
      .typeError('utils.validationErrors.invalidBoolean')
      .optional(),
    hasMin: yup
      .boolean()
      .typeError('utils.validationErrors.invalidBoolean')
      .optional(),
    min: yup
      .string()
      .typeError('utils.validationErrors.invalidString')
      .optional(),
    // hasExplanation: yup.boolean().defaulfalse),
    price: yup
      .number()
      .required()
      .typeError('utils.validationErrors.invalidNumber'),
    priceBreakdown: priceBreakdownSchema,
    addons: yup.array().of(variantAddonSchema(activeLanguage)),
  });
export type Variant = yup.InferType<ReturnType<typeof variantSchema>>;

export const ticketSchema = yup.object().shape({
  // hasWeekdayVariants: yup.boolean().defaulfalse),
  // hasVariants: yup.boolean().defaultrue),
  // vatRate: yup.mixed().oneOf([yup.number(), yup.string()]).optional(),
  displayPriceCents: yup
    .number()
    .typeError('utils.validationErrors.invalidNumber'),
  displayVariantId: yup
    .string()
    .typeError('utils.validationErrors.invalidString'),
  // priceBreakdown: priceBreakdownSchema,
  variants: yup.array().of(variantSchema()).min(1),
  // value: yup.number()
});

export type Ticket = yup.InferType<typeof ticketSchema>;
