import { Divider, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  Outlet,
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../Ampli';
import { updateCompanyProfile } from '../../../Api';
import { companyProfileQuery, userProfileQuery } from '../../../Api/queries';
import { DetailItem } from '../../../Components/detail-item/detail-item';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import PreviewMarkdown from '../../../Components/preview-markdown/preview-markdown';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import routes from '../../../Utils/routes';
import { CompanyDetailsInputs } from './company-details-inputs';
import { StorefrontCover } from './storefront-cover';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const [companyProfile] = await Promise.all([
    client.fetchQuery(companyProfileQuery()),
  ]);

  return { companyProfile };
};

export type CompanyFormData = Pick<
  CompanyProfile,
  | 'name'
  | 'website'
  | 'pictures'
  | 'companyEmail'
  | 'companyPhone'
  | 'vatCompliance'
>;

export default function CompanySettings() {
  const { companyProfile } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const { state } = useNavigation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getLocalizedString } = useLocale();

  const submit = useSubmit();

  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      ...companyProfile,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (actionData?.updatedCompanyProfile) {
      reset({
        ...actionData.updatedCompanyProfile,
      });
    }
  }, [actionData, reset]);

  const onSubmit = (data: CompanyFormData) => {
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.companyPageViewed()), [fireOnce]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={2} pb={3}>
          <Text variant="medium">{t('storefront.navigation.company')}</Text>
          <Text variant="normal">{t('settings.company.explainer')}</Text>
        </Stack>
        <Stack sx={{ gap: 4 }}>
          <StorefrontCover companyId={companyProfile.id} />
          <DetailItem
            title={t('storefront.company.companyDescription.title')}
            onClick={() =>
              navigate(routes.storefrontSettings.company.edit.description)
            }
          >
            {companyProfile.description && (
              <PreviewMarkdown>
                {getLocalizedString(
                  companyProfile.description,
                  companyProfile.description[0]
                )}
              </PreviewMarkdown>
            )}
          </DetailItem>
          <Stack pb={1}>
            <Divider />
          </Stack>
          <CompanyDetailsInputs companyId={companyProfile.id} />
        </Stack>
        <FixedActionsBar
          onReset={() => reset()}
          isSubmitting={state === 'submitting'}
          unsavedChanges={isDirty}
        />
      </Form>
    </FormProvider>
  );
}

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { name, website, pictures, companyEmail, companyPhone } =
        (await request.json()) as CompanyFormData;

      const [companyProfile] = await Promise.all([
        client.fetchQuery(companyProfileQuery()),
      ]);

      const updatedCompanyProfile: CompanyProfile = {
        ...companyProfile,
        name,
        website,
        pictures,
        companyEmail,
        companyPhone,
      };

      await updateCompanyProfile(updatedCompanyProfile);

      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });
      client.invalidateQueries({
        queryKey: userProfileQuery().queryKey,
      });

      return { updatedCompanyProfile };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
