import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Checkbox, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DialogBase from '../../../../Components/dialog/dialog-base';
import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { StorefrontLanguage } from '../../../../i18n/config';

export type AddLanguageDialogProps = {
  selectedLanguages: StorefrontLanguage[];
};

export const AddLanguageDialog = NiceModal.create(
  ({ selectedLanguages }: AddLanguageDialogProps) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleClose = useCallback(
      (newLanguages?: StorefrontLanguage[]) => {
        modal.resolve(newLanguages);
        modal.remove();
      },
      [modal]
    );

    const formMethods = useForm<{ languages: StorefrontLanguage[] }>({
      defaultValues: { languages: selectedLanguages },
    });

    const {
      handleSubmit,
      control,
      formState: {
        errors: { languages: languageError },
      },
    } = formMethods;

    const { field: langauges } = useController({
      name: 'languages',
      control: control,
      rules: {
        validate: (value) =>
          value.length > 0 ? true : t('storefront.languages.add.required'),
      },
    });

    const onSubmit = useCallback(
      (data: { languages: StorefrontLanguage[] }) => {
        handleClose(data.languages);
      },
      [handleClose]
    );

    const { languageOption, storefrontLanguages } = useLanguages();

    return (
      <DialogBase
        open={modal.visible}
        onClose={() => handleClose()}
        maxWidth={700}
        title={t('storefront.languages.add.title')}
        fullHeight={false}
        actionsInHeader={false}
        primaryAction={{
          label: t('experience.edit.dialog.actions.save'),
          variant: 'primary',
          type: 'button',
          onClick: handleSubmit(onSubmit),
        }}
      >
        <Stack sx={{ gap: { xs: 1, sm: 2 } }}>
          {storefrontLanguages.map((language) => {
            const { locale, label, regionLabel } = languageOption(language);

            return (
              <LanguageItem
                key={locale}
                label={label}
                regionLabel={regionLabel}
                isSelected={langauges.value.includes(locale)}
                handleSelect={() =>
                  langauges.value.includes(locale)
                    ? langauges.onChange(
                        langauges.value.filter((lang) => lang !== locale)
                      )
                    : langauges.onChange([...langauges.value, locale])
                }
              />
            );
          })}
          {languageError && (
            <Text color={lightTheme.palette.error.e400}>
              {languageError.message}
            </Text>
          )}
        </Stack>
      </DialogBase>
    );
  }
);

const LanguageItem = ({
  label,
  regionLabel,
  isSelected,
  handleSelect,
}: {
  label: string;
  regionLabel?: string;
  isSelected: boolean;
  handleSelect: () => void;
}) => {
  return (
    <Stack
      role="button"
      tabIndex={0}
      sx={{
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 3,
        paddingY: { xs: 1, sm: 2 },
        paddingX: { xs: 2, sm: 3 },
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: lightTheme.palette.neutral.n100,
        borderRadius: 2,
        transition: 'background-color 0.1s ease-in-out',
        '&:hover': {
          backgroundColor: lightTheme.palette.contrast.surface1,
        },
        ':focus-visible': {
          outlineOffset: -1,
          outlineWidth: 1,
          outlineStyle: 'solid',
          outlineColor: lightTheme.palette.neutral.n500,
        },
      }}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          handleSelect();
        }
      }}
      onClick={handleSelect}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1.5,
          maxWidth: '75%',
          '& > *:last-child': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      >
        <Text variant="medium">{label}</Text>
        {regionLabel && (
          <Text color={lightTheme.palette.neutral.n300}>{regionLabel}</Text>
        )}
      </Stack>
      <Stack>
        <Checkbox
          tabIndex={-1}
          checked={isSelected}
          size="small"
          disableRipple
          disableFocusRipple
        />
      </Stack>
    </Stack>
  );
};
