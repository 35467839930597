import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../../../../Api';

export const useInternalIntegrations = () => {
  const queryClient = useQueryClient();

  const queryKey = ['public-api', 'oauth2-client-credentials'];

  const clients = useQuery({
    queryKey: queryKey,

    queryFn: async () => api.getOauth2Clients(),

    enabled: true,
    retry: false,
  });

  const createClient = useMutation({
    mutationFn: (args: { name: string; scopes: string[] }) =>
      api.createOauth2Client(args.name, args.scopes),

    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: queryKey,
      }),
  });

  const deleteClient = useMutation({
    mutationFn: (clientId: string) => api.deleteOauth2Client(clientId),

    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: queryKey,
      }),
  });

  const updateClient = useMutation({
    mutationFn: (args: { clientId: string; name: string; scopes: string[] }) =>
      api.updateOauth2Client(args.clientId, args.name, args.scopes),

    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: queryKey,
      }),
  });

  return {
    loading:
      clients.isLoading ||
      deleteClient.isPending ||
      updateClient.isPending ||
      createClient.isPending,
    clients,
    revoke: deleteClient,
    update: updateClient,
    create: createClient,
  };
};
