import { CheckOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReducedVat } from '@understory-io/utils-types';
import { FC, useState } from 'react';

import { Badge } from '../../../../Components/badge/badge';
import { ContextMenu } from '../../../../Components/context-menu/context-menu';
import { ContextMenuItemProps } from '../../../../Components/context-menu/context-menu-item';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getTaxRateLabel } from '../../../../Utils/tax/tax';

type Props = {
  taxCountry: string;
  category: string;
  tax: ReducedVat;
  contextMenuItems?: ContextMenuItemProps[];
  isDefault?: boolean;
};

export const TaxRateItem: FC<Props> = ({
  taxCountry,
  category,
  tax,
  contextMenuItems,
  isDefault,
}) => {
  const { t } = useTranslate('');

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const { label, rateLabel } = getTaxRateLabel(
    taxCountry,
    {
      category,
      value: tax,
    },
    t
  );

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 3,
        py: { xs: 1, sm: 2 },
        px: { xs: 2, sm: 3 },
        backgroundColor: lightTheme.palette.contrast.white,
        border: `1px solid ${lightTheme.palette.neutral.n100}`,
        borderRadius: 2,
      }}
    >
      <Stack direction="row" gap={1.5}>
        <Text variant="medium">{label}</Text>
        <Text color={lightTheme.palette.neutral.n300}>{rateLabel}</Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {isDefault && (
          <Badge appearance="default" size="small">
            <CheckOutlined sx={{ height: 16, width: 16 }} />
            <Text variant="medium" fontSize="xsmall">
              {t('default', 'utils.generic')}
            </Text>
          </Badge>
        )}
        <ContextMenu
          open={contextMenuOpen}
          onOpenChange={setContextMenuOpen}
          options={contextMenuItems ?? []}
        />
      </Stack>
    </Stack>
  );
};
