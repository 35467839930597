import { lightTheme } from '@understory-io/pixel';
import { Image as ImageType } from '@understory-io/utils-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Image } from '../../../Components/media/image';
import { ImageUpload } from '../../../Components/media-upload/image-upload';
import { CompanyFormData } from './company-settings';

const COLLECTION = 'company';
const TYPE = 'cover';

export const StorefrontCover = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();

  const { control } = useFormContext<CompanyFormData>();
  const { field: coverUrl } = useController({
    name: 'pictures.cover.url',
    control,
  });

  const handleUpload = (image: ImageType) => {
    coverUrl.onChange(image.url);
  };

  const handleDelete = () => {
    coverUrl.onChange(null);
  };

  return (
    <ImageUpload
      id={companyId}
      collection={COLLECTION}
      type={TYPE}
      onUploaded={handleUpload}
      label={t('storefront.customize.uploadCover.label')}
      description={t('storefront.customize.uploadCover.description')}
      handleDelete={handleDelete}
      badgeLabel={t('experience.edit.details.media.cover')}
    >
      {coverUrl.value && (
        <Image
          src={coverUrl.value}
          sx={{
            height: 225,
            backgroundColor: lightTheme.palette.contrast.white,
            borderRadius: 2,
            border: '1px solid',
            borderColor: lightTheme.palette.neutral.n200,
          }}
        />
      )}
    </ImageUpload>
  );
};
