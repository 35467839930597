import styled from '@emotion/styled';
import { Button, LinkProps, Menu, MenuProps, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { lightTheme } from '@understory-io/pixel';
import react, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguages } from '../../Hooks/locales/use-languages';
import { LANGUAGES_PREFERENCES_KEY } from '../../Hooks/use-apply-preferences';
import { useProfile } from '../../Hooks/useProfile';
import { BackofficeLanguage } from '../../i18n/config';

export const LanguageSelect = ({
  shouldSetTranslation = true,
  onChange,
  renderComp,
}: {
  shouldSetTranslation?: boolean;
  renderComp?: (onClick: LinkProps['onClick']) => ReactNode;
  onChange?: (key: string) => void;
}) => {
  const { t, i18n } = useTranslation();

  const _language = i18n.language;

  const { updatePreferences } = useProfile();

  const [selected, setSelected] = useState(_language);

  useEffect(() => {
    setSelected(_language);
  }, [_language]);

  const { backofficeLanguages, languageOption } = useLanguages();

  const supportedLanguages = backofficeLanguages;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: react.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (key: string) => () => {
    setSelected(key);
    if (shouldSetTranslation) {
      updatePreferences(
        LANGUAGES_PREFERENCES_KEY,
        (previous: Record<string, string> | undefined) => ({
          ...previous,
          defaultLanguage: key,
        })
      );
      i18n.changeLanguage(key);
    }
    onChange?.(key);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentLanguageOption = useMemo(
    () => languageOption(selected as BackofficeLanguage),
    [selected, languageOption]
  );

  return (
    <>
      {renderComp ? (
        renderComp(handleClick)
      ) : (
        <Tooltip title={t('utils.generic.selectLanguage')}>
          <Button
            key={selected}
            sx={{
              borderRadius: 100,
              color: lightTheme.palette.contrast.black,
              p: '8px 24px',
              borderColor: '#C7C7CC',
              boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.08)',
              borderWidth: 1,
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: lightTheme.palette.neutral.n100,
              },
            }}
            variant={'outlined'}
            onClick={handleClick}
            startIcon={
              <StyledFlagImage
                src={currentLanguageOption.flagSrc}
                height={24}
                width={24}
              />
            }
          >
            {currentLanguageOption.country
              ? `${currentLanguageOption.label} (${currentLanguageOption.country})`
              : currentLanguageOption.label}
          </Button>
        </Tooltip>
      )}
      <StyledMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {supportedLanguages?.map((locale) => {
          const { label, country, flagSrc } = languageOption(locale);
          return (
            <MenuItem
              key={locale}
              selected={locale === selected}
              onClick={handleSelect(locale)}
              sx={{ textTransform: 'capitalize' }}
            >
              <StyledFlagImage alt="" src={flagSrc} width={32} height={32} />
              {country ? `${label} (${country})` : label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

const StyledMUIButton = styled(Menu)(() => ({
  ['&.MuiButton-root']: {
    boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.5)',
    borderColor: 'red',
  },
}));

const StyledMenu = ({ children, ...props }: MenuProps) => {
  return (
    <StyledMUIButton
      sx={{
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.5)',
        borderColor: 'red',
      }}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      {...props}
    >
      {children}
    </StyledMUIButton>
  );
};

const StyledFlagImage = styled('img')({
  marginRight: 8,
});
