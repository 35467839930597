import { DraftExperience } from '@understory-io/experiences-types';
import { MediaItem } from '@understory-io/utils-types';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { Image as ImageType } from '../../schemas/mediaSchema';

export const uploadMediaHandler = async (
  experienceId: string,
  experience: DraftExperience,
  formData: FormData
) => {
  const medias = JSON.parse(
    formData.get('data') as string
  ) as unknown as MediaItem[];

  const convertedMedias = medias?.map((media) => {
    if (media.type === 'video') {
      // Currently we dont support youtube videos
      if (media.provider === 'youtube') return;

      return {
        // For our own videos we return the mimeType together with the video
        mimeType: (media as MediaItem & { mimeType: string }).mimeType,
        playback: media.playback,
        preview: '',
        provider: media.provider,
        type: media.type,
      } as ImageType;
    }

    // default to an image
    return {
      mimeType: media.mimeType,
      provider: 'understory',
      type: media.type,
      url: media.url,
      preview: media.preview,
    } as ImageType;
  });

  const experienceToSave = {
    ...experience,
    media: {
      ...experience.media,
      cover: convertedMedias ?? [],
    },
  } as DraftExperience;

  await saveExperienceDraft(experienceId, experienceToSave);
};
