import useResponsive from '../../Hooks/layout/useResponsive';
import { useOnBoarding } from '../../Hooks/useOnBoarding';
import { useProfile } from '../../Hooks/useProfile';
import { useSuggestions } from './dashboard-suggestions/use-suggestions';

export const useDashboardPageState = () => {
  const { isSm } = useResponsive();
  const {
    onBoarding: { isLoading: onboardingIsLoading },
  } = useOnBoarding();
  const {
    onboardingCompleted,
    company: { isLoading: companyIsLoading },
  } = useProfile();
  const { hasPaymentsEnabled } = useSuggestions();

  const showGraphs = onboardingCompleted && hasPaymentsEnabled;
  const showSuggestions = !showGraphs;
  const showDivider = !isSm && showGraphs;
  const showGraphsOrLoading =
    showGraphs || onboardingIsLoading || companyIsLoading;

  return {
    showSuggestions,
    showDivider,
    showGraphsOrLoading,
  };
};
