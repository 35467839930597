import { Divider, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Experience } from '@understory-io/experiences-types';
import { Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { Suspense, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Await,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateCompanyProfile, updateExperienceMetaData } from '../../../Api';
import { companyProfileQuery, experiencesQuery } from '../../../Api/queries';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import { trackStorefrontCustomizeViewed } from '../../../tracking/storefront/customize-events';
import { ExperienceOrder } from './experience-order';
import { StorefrontStyling } from './styling/storefront-styling';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const companyProfile = await client.fetchQuery(companyProfileQuery());

  const experiencesPromise = client.fetchQuery(
    experiencesQuery({
      defaultLanguage: companyProfile.defaultLanguage,
      type: 'owned',
    })
  );

  return { companyProfile, experiencesPromise };
};

export type CustomizeStorefrontFormData = Pick<
  CompanyProfile,
  'domains' | 'customization' | 'sortExperiencesBy' | 'features'
> & { sortOrder: string[] };

export default function CustomizeStorefront() {
  const { t } = useTranslation();
  const { companyProfile, experiencesPromise } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const { state } = useNavigation();

  const submit = useSubmit();

  const formMethods = useForm<CustomizeStorefrontFormData>({
    defaultValues: companyProfile,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (actionData?.updatedCompanyProfile && actionData?.sortOrder) {
      reset({
        ...actionData.updatedCompanyProfile,
        sortOrder: actionData.sortOrder,
      });
    }
  }, [actionData, reset]);

  const onSubmit = (data: CustomizeStorefrontFormData) => {
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(trackStorefrontCustomizeViewed), [fireOnce]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack sx={{ gap: 3 }}>
          <Stack sx={{ gap: 1 }}>
            <Text variant="medium">{t('storefront.customize.title')}</Text>
            <Text fontSize="small">
              {t('storefront.customize.description')}
            </Text>
          </Stack>
          <Divider />
          <StorefrontStyling />
          <Divider />
          <Suspense>
            <Await resolve={experiencesPromise}>
              {(experiences) => <ExperienceOrder experiences={experiences} />}
            </Await>
          </Suspense>
        </Stack>
        <FixedActionsBar
          onReset={() => reset()}
          isSubmitting={state === 'submitting'}
          unsavedChanges={isDirty}
        />
      </Form>
    </FormProvider>
  );
}

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { customization, sortExperiencesBy, sortOrder, domains, features } =
        (await request.json()) as CustomizeStorefrontFormData;

      const [companyProfile, experiences] = await Promise.all([
        client.fetchQuery(companyProfileQuery()),
        client.fetchQuery(experiencesQuery({ type: 'owned' })),
      ]);

      const updatedCompanyProfile: CompanyProfile = {
        ...companyProfile,
        customization,
        sortExperiencesBy,
        domains,
        features,
      };

      const experienceMap = experiences.reduce(
        (experiences, experience) => {
          return {
            ...experiences,
            [experience.id]: experience,
          };
        },
        {} as Record<string, Experience>
      );

      await Promise.all([
        updateCompanyProfile(updatedCompanyProfile),
        // We don't need to map all experiences if we don't use custom sort order
        ...(sortExperiencesBy === 'sortOrder' && sortOrder
          ? sortOrder.map((experienceId, index) => {
              const experience = experienceMap?.[experienceId];
              if (!experience) return;

              const newIndex = index + 1;
              // Only update if position is changed
              if (experience.metadata?.sortOrder === newIndex) return;

              return updateExperienceMetaData(experienceId, {
                sortOrder: newIndex,
              });
            })
          : []),
      ]);

      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });

      return { updatedCompanyProfile, sortOrder };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
