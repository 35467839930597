import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { ExperienceTag } from '@understory-io/utils-types';
import { FormProvider, useForm } from 'react-hook-form';

import { TranslatableGroup } from '../../../../Components/TranslatableGroup/translatable-group';
import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { useStorefrontLanguages } from '../../../../Hooks/translatable/use-storefront-languages';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontLanguage } from '../../../../i18n/config';

export const ExperienceTagForm = ({
  onSubmit,
  defaultValues,
  handleCancel,
  isSubmitting,
}: {
  onSubmit: (data: ExperienceTag['name']) => void;
  defaultValues?: ExperienceTag['name'];
  handleCancel: () => void;
  isSubmitting: boolean;
}) => {
  const { t } = useTranslate('experience.tags.dialog.form');

  const formMethods = useForm<{ name: ExperienceTag['name'] }>({
    defaultValues: { name: defaultValues ?? {} },
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const storefrontLanguages = useStorefrontLanguages();

  const { languageOption } = useLanguages();

  const errorMessage = (
    [
      errors.name?.message,
      ...Object.values(errors.name ?? {}).map((a) => a?.message),
    ] as Array<string | undefined>
  ).filter(Boolean)?.[0];

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        onSubmit={handleSubmit((data) => {
          if (!Object.entries(data.name)?.length) {
            return setError('name', { message: t('error.noInput') });
          }
          for (const [language, value] of Object.entries(data.name)) {
            if (!value) {
              return setError(`name.${language}`, {
                message: t('error.missingTranslation', {
                  language: languageOption(language as StorefrontLanguage)
                    .label,
                }),
              });
            }
          }

          onSubmit(data.name);
        })}
        sx={{ flexGrow: 1, justifyContent: 'space-between', gap: 4 }}
      >
        <TranslatableGroup
          mt={0}
          langs={storefrontLanguages ?? []}
          id={'name'}
          label={t(`placeholder`)}
          input={{
            type: 'input',
            maxLength: 25,
            placeholder: t(`title`),
            props: {
              InputLabelProps: {
                shrink: true,
              },
            },
          }}
          clearError={() => clearErrors('name')}
          error={errorMessage}
        />
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            '& > button': {
              flexGrow: 1,
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleCancel}
          >
            {t('cancel', 'tags.dialog.action')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={isSubmitting}
          >
            {t('save', 'tags.dialog.action')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
