import { QueryClient } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Await, LoaderFunctionArgs, useLoaderData } from 'react-router';

import { resourceTypesQuery } from '../../../../Api/queries';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { EditResourceTypePage } from './edit-resource-type-page';
import { EditResourceTypeSkeletonPage } from './edit-resource-type-skeleton-page';
import { UpdateResourceTypeErrorPage } from './update-resource-type-error-page';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const resourceTypePromise = client
      .fetchQuery(resourceTypesQuery())
      .then((resourceTypes) => {
        const item = resourceTypes.find(
          (resourceType) =>
            resourceType.resourceTypeId === params.resourceTypeId
        );
        if (!item) throw new Error("Resource type wasn't found");

        return item;
      });

    return {
      resourceTypePromise,
    };
  };

export const EditResourceTypePageLoader = () => {
  const { t } = useTranslate('resourceManagement.resourceType.errors.notFound');

  const { resourceTypePromise } = useLoaderData<LoaderData>();

  return (
    <Suspense fallback={<EditResourceTypeSkeletonPage />}>
      <Await
        resolve={resourceTypePromise}
        errorElement={
          <UpdateResourceTypeErrorPage
            errorTexts={{
              title: t('title'),
              description: t('description'),
            }}
          />
        }
      >
        {(resourceType) => <EditResourceTypePage resourceType={resourceType} />}
      </Await>
    </Suspense>
  );
};
