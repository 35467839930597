import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, Text } from '@understory-io/pixel';
import { addDays } from 'date-fns';
import { FC, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { updateAdvertisementPlan } from '../../../../../../Api/Marketing';
import { useAdPlans } from '../../../../../../Hooks/use-ad-plans';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import {
  AdvertisementPlanContext,
  StepProps,
} from './grow-advertisement-flow-steps';

export const DateStep: FC<StepProps> = ({ onNext }) => {
  const { t } = useTranslate('grow.advertisement.getStarted');
  const { adPlan } = useContext(AdvertisementPlanContext);

  const {
    adPlans: { refetch: refetchAdPlans },
  } = useAdPlans();

  const [startDate, setStartDate] = useState<Date | null>(
    addDays(new Date(), 7)
  );

  const handleSubmit = async () => {
    onNext();
    try {
      await updateAdvertisementPlan({
        ...adPlan,
        startDate: startDate?.toISOString(),
      });
      await refetchAdPlans();
    } catch (error) {
      console.error('Error updating advertisement plan start date:', error);
      toast.error(t('generic', 'utils.errors'));
    }
  };

  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <Stack gap={2}>
          <Text variant="medium">{t('date.whenStart')}</Text>
          <DatePicker
            value={startDate}
            onChange={(newValue) => setStartDate(newValue ?? null)}
            disablePast
            sx={{ width: '100%' }}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
          />
        </Stack>
      </Stack>

      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit}
        disabled={!startDate}
      >
        {t('done', 'utils.generic')}
      </Button>
    </Stack>
  );
};
