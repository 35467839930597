import { Avatar } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { AutoTranslateToggle } from '../../features/experiences/edit/header/auto-translate-toggle';
import { useLanguages } from '../../Hooks/locales/use-languages';
import { useTranslate } from '../../Hooks/useTranslate';
import { isStorefrontLanguage, StorefrontLanguage } from '../../i18n/config';
import { CustomSelect } from '../CustomSelect/custom-select';

const ICON_SIZE_PX = 18;

interface LanguageSelectProps {
  activeLanguage: StorefrontLanguage;
  availableLanguages: StorefrontLanguage[];
  handleChangeLanguage: (language: StorefrontLanguage) => void;
  isAutoTranslateEnabled: boolean;
  handleChangeAutoTranslate: (checked: boolean) => void;
}

export function LanguageSelect({
  activeLanguage,
  availableLanguages,
  handleChangeAutoTranslate,
  handleChangeLanguage,
  isAutoTranslateEnabled,
}: LanguageSelectProps) {
  const { t } = useTranslate('experience.edit.details.label');
  const { languageOption } = useLanguages();
  const onLanguageChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    if (isStorefrontLanguage(newValue)) {
      handleChangeLanguage(newValue);
    }
  };

  return (
    <CustomSelect
      sx={{
        backgroundColor: lightTheme.palette.contrast.white,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: lightTheme.palette.neutral.n100,
        },
      }}
      selectedValue={activeLanguage}
      options={availableLanguages.map((lang) => {
        const { label, country, flagSrc } = languageOption(lang);
        return {
          value: lang,
          label: country ? `${label} (${country})` : label,
          icon: (
            <Avatar
              sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }}
              src={flagSrc}
            />
          ),
        };
      })}
      footerOptions={[
        {
          key: 'auto-translate',
          label: t('autoTranslate'),
          removePadding: true,
          children: (
            <AutoTranslateToggle
              isAutoTranslateEnabled={isAutoTranslateEnabled}
              onChange={handleChangeAutoTranslate}
            />
          ),
        },
      ]}
      onChange={onLanguageChange}
    />
  );
}
