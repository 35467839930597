import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { ReducedVat } from '@understory-io/utils-types';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import DialogBase from '../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useTaxLabel } from '../../../../Utils/tax/use-tax-label';

type CustomTaxFormType = {
  label: string;
  rate: number;
  exempt: boolean;
};

type Props = {
  taxCountry: string;
};

export const AddCustomTaxRateDialog = NiceModal.create(
  ({ taxCountry }: Props) => {
    const { t } = useTranslate('');

    const modal = useModal();

    const {
      register,
      formState: { errors },
      handleSubmit,
      watch,
    } = useForm<CustomTaxFormType>({
      defaultValues: {
        label: '',
        rate: 0,
        exempt: false,
      },
    });

    const handleClose = useCallback(
      (reducedTax?: ReducedVat) => {
        modal.resolve(reducedTax);
        modal.remove();
      },
      [modal]
    );

    const handleAdd = useCallback(
      ({ label, rate, exempt }: CustomTaxFormType) => {
        const reducedTaxRate: ReducedVat = exempt
          ? { isUserMade: true, label, exempt: true, rate: 0 }
          : { isUserMade: true, label, exempt: false, rate: rate / 100 };

        handleClose(reducedTaxRate);
      },
      [handleClose]
    );

    const exempt = watch('exempt');

    const taxLabel = useTaxLabel(taxCountry);

    return (
      <DialogBase
        title={taxLabel('tax.registration.setup.addRate')}
        description={taxLabel('tax.registration.setup.addRateDescription')}
        onClose={handleClose}
        primaryAction={{
          label: t('add', 'buttons'),
          variant: 'primary',
          onClick: handleSubmit(handleAdd),
        }}
      >
        <Stack mt={1} direction="row" gap={2}>
          <TextField
            {...register('label', {
              required: {
                value: true,
                message: t('required', 'utils.errors'),
              },
              maxLength: {
                value: 30,
                message: '',
              },
            })}
            required
            label={t('name', 'utils.generic')}
            InputLabelProps={{ shrink: true }}
            error={!!errors.label}
            helperText={errors.label?.message}
            fullWidth
            sx={{
              flex: '2 1 auto',
            }}
          />
          <Stack sx={{ flex: '1 1 auto', width: '70%' }}>
            <TextField
              {...register('rate', {
                required: {
                  value: true,
                  message: t('required', 'utils.errors'),
                },
                valueAsNumber: true,
              })}
              required
              label={t('rateInPercentage', 'tax.registration.setup')}
              InputLabelProps={{ shrink: true }}
              error={!!errors.rate}
              helperText={errors.rate?.message}
              type="number"
              disabled={exempt}
            />

            <FormControlLabel
              control={<Checkbox size={'small'} />}
              label={taxLabel('tax.exempt')}
              {...register('exempt')}
            />
          </Stack>
        </Stack>
      </DialogBase>
    );
  }
);
