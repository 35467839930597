/* eslint-disable no-prototype-builtins */
import { RegularExperience } from '@understory-io/experiences-types';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { StorefrontLanguage } from '../../../../i18n/config';
import { experienceSchema } from '../../schemas';
import { getMissingLanguageErrors } from '../utils/form-helpers';
import { translateMissingInputs } from '../utils/translate-input';

export const translateMissingTextsHandler = async (
  experienceId: string,
  experience: RegularExperience,
  activeLanguage: StorefrontLanguage,
  languages: string[]
) => {
  try {
    // We are expecting this to fail
    experienceSchema.validateSync(experience, { abortEarly: false });
  } catch (error) {
    if (error instanceof ValidationError) {
      const languageLocations = getMissingLanguageErrors(error);

      await Promise.all(
        Object.entries(languageLocations).map(async ([key, arr]) => {
          const translationObj = getNestedValue(experience, arr) as {
            [x: string]: string;
          };

          const localizedObj = await translateMissingInputs({
            activeLanguage,
            existingValues: translationObj,
            storefrontLanguages: languages,
          });

          setNestedValue(experience, arr, localizedObj);
        })
      );

      await saveExperienceDraft(experienceId, experience);
      return;
    }

    throw error;
  }
};

type NestedObject = { [key: string]: any };

function getNestedValue(obj: NestedObject, keys: string[]): any {
  return keys.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
}

function setNestedValue(obj: NestedObject, keys: string[], value: any): void {
  // Traverse to the second-to-last key in the path
  const lastKey = keys[keys.length - 1];
  const target = keys.slice(0, -1).reduce((acc, key) => {
    // If the key doesn't exist, create an empty object
    if (acc[key] === undefined) {
      acc[key] = {};
    }
    return acc[key];
  }, obj);

  // Set the value at the final key
  target[lastKey] = value;
}
