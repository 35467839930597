import { ArrowBackOutlined } from '@mui/icons-material';
import { alpha, Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useHistory } from '../../../Hooks/useHistory';

export const StickyActionBar = ({ children }: PropsWithChildren) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = useCallback(() => {
    setIsScrolling(window.scrollY > 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollOpacity = useMemo(() => (isScrolling ? 0.5 : 1), [isScrolling]);

  return (
    <Stack
      sx={{
        position: 'sticky',
        backdropFilter: isScrolling ? 'blur(20px)' : undefined,
        backgroundColor: {
          xs: alpha(lightTheme.palette.contrast.surface2, scrollOpacity),
          md: alpha(lightTheme.palette.contrast.surface1, scrollOpacity),
        },
        top: 0,
        zIndex: 15,
        padding: { xs: 2, md: 4 },
        paddingBottom: { md: 3 },
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 5,
      }}
    >
      {children}
    </Stack>
  );
};

type GoBackButtonProps = {
  fallbackPath: string;
};

const ICON_SIZE_PX = { xs: 24, md: 32 };

export const GoBackButton = ({ fallbackPath }: GoBackButtonProps) => {
  const { goBack } = useHistory();

  const handleBack = () => goBack(fallbackPath);

  return (
    <IconButton
      size="small"
      onClick={handleBack}
      sx={{
        width: ICON_SIZE_PX,
        height: ICON_SIZE_PX,
        ':focus-visible': { outline: 1 },
      }}
    >
      <ArrowBackOutlined />
    </IconButton>
  );
};
