import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackStorefrontImplementationFlowTypeChanged } from '../../../../../tracking/storefront/implementation-events';
import { ActionTypeEnum, FlowTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

interface FlowTypeSelectProps {
  type: FlowTypeEnum;
}

export const FlowTypeSelect = ({ type }: FlowTypeSelectProps) => {
  const { t } = useTranslate('storefront.bookingflow');
  const { selectedFlowType, dispatch } = useBookingFlowSectionContext();
  const isSelected = selectedFlowType === type;

  return (
    <Stack
      data-intercom-target={`flow-type-${type}`}
      onClick={() => {
        trackStorefrontImplementationFlowTypeChanged(type);
        dispatch({ type: ActionTypeEnum.SetFlowType, payload: type });
      }}
      sx={{
        gap: { xs: 2, sm: 1 },
        backgroundColor: lightTheme.palette.contrast.white,
        border: `2px solid ${isSelected ? lightTheme.palette.action.a300 : 'transparent'}`,
        boxShadow: `0 0 2px ${lightTheme.palette.neutral.n100}`,
        borderRadius: '20px',
        width: { sm: 200 },
        padding: { xs: 2, md: 2 },
        cursor: 'pointer',
        userSelect: 'none',
        flexDirection: { xs: 'row-reverse', sm: 'column' },
        justifyContent: { xs: 'space-between', sm: 'center' },
        alignItems: 'center',
        textAlign: { sm: 'center' },
        '&:hover': {
          boxShadow: `0 0 4px ${lightTheme.palette.neutral.n100}`,
        },
      }}
    >
      <Box
        component={'img'}
        src={`/graphics/storefront-bookingflow-section/graphic-flowtype-${type}.svg`}
        sx={{
          width: { xs: '15%', sm: '100%' },
          maxWidth: '100px',
          height: 'auto',
        }}
      />
      <Stack>
        <Text
          variant={'medium'}
          style={{
            color: isSelected ? lightTheme.palette.action.a300 : '#000000',
          }}
        >
          {t(`flowType.${type}.title`)}
        </Text>
        <Text fontSize={'xsmall'}>{t(`flowType.${type}.subtitle`)}</Text>
      </Stack>
    </Stack>
  );
};
