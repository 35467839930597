import { getGiftCard } from '../../../Api';
import { companyUsersQuery } from '../../../Api/queries';
import { queryClient } from '../../../query-client';

export async function getCompanyUsersQuery() {
  const companyProfile = await queryClient.fetchQuery(companyUsersQuery());

  return companyProfile;
}

export async function getGiftCardQuery(id: string) {
  const receipt = await queryClient.fetchQuery({
    queryKey: ['giftcard', id],
    queryFn: () => getGiftCard(id),
  });

  return receipt;
}
