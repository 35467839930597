import { HandshakeRounded, Image as MuiImage } from '@mui/icons-material';
import { Badge, Box, Grid, Skeleton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useMemo } from 'react';
import { Link } from 'react-router';

import { Image } from '../../../Components/media/image';
import { useExperienceEvents } from '../../../Hooks/events/useExperienceEvents';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useAuth } from '../../../Hooks/useAuth';
import type { IExperience } from '../../../Hooks/useExperience';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';
import { isSharedExperience } from '../utils/is-shared-experience';
import { NoExperienceTitle } from './experience-item';

interface ExperienceCardProps {
  experience: IExperience;
}

export default function ExperienceCard({ experience }: ExperienceCardProps) {
  const {
    eventsForExperience: { data: eventsForExperience },
  } = useExperienceEvents(
    experience.ownerExperienceId,
    {
      state: 'future',
      status: 'statusIsActive',
    },
    1,
    0
  );

  const hasUnpublishedChanges = useMemo(
    () => experience.status !== 'draft' && experience.hasDraft,
    [experience]
  );

  const isDraft = useMemo(() => experience.status === 'draft', [experience]);

  const { canAccess } = useAuth();

  return (
    <Stack
      to={routes.experience.details(experience.id).index}
      component={Link}
      sx={{
        position: 'relative',
        display: 'flex',
        backgroundColor: lightTheme.palette.contrast.white,
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        borderRadius: { xs: 2, md: 1 },
        paddingY: { xs: 0, md: 1 },
        paddingX: { xs: 0, md: 2 },
        gap: { xs: 0, md: 3 },
        overflow: 'hidden',
        transition: 'background-color 0.1s ease-in-out',
        ':hover': {
          backgroundColor: lightTheme.palette.contrast.surface2,
        },
      }}
    >
      <Box sx={{ position: { xs: 'initial', md: 'relative' } }}>
        <ExperienceImage src={experience.media.primaryImage?.url} />
        {isSharedExperience(experience) && <SharedExperienceIcon />}
      </Box>
      <Grid
        container
        sx={{
          display: { xs: 'block', md: 'flex' },
          marginY: { xs: 'auto', md: 0 },
          width: '100%',
          gap: { xs: 0, md: 1 },
          flexGrow: 1,
          padding: { xs: 2, md: 0 },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={2}>
          <Stack flexDirection="column">
            <ExperienceHeadline headline={experience.headline} />
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <VisibilityLabel visibility={experience.visibility} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={2}>
          {eventsForExperience ? (
            <UpcommingEventsLabel totalCount={eventsForExperience.totalCount} />
          ) : (
            <Skeleton sx={{ width: 130 }} />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          {eventsForExperience ? (
            <BookingCountLabel
              seats={{
                totalSeats: eventsForExperience.totalSeats,
                seatsBooked: eventsForExperience.totalBooked,
              }}
            />
          ) : (
            <Skeleton sx={{ width: 130 }} />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          {!isDraft && <StorefrontLinkLabel id={experience.id} />}
        </Grid>
        <Grid
          sx={{
            position: { xs: 'absolute', md: 'initial' },
            top: 16,
            right: 16,
          }}
          item
          xs={12}
          md={2}
        >
          <Stack justifyContent="end" flexDirection="row" gap={1}>
            {canAccess('experience.write') && !isDraft && (
              <UnpublishedChanges
                hasUnpublishedChanges={hasUnpublishedChanges}
              />
            )}
            <BadgeLabel status={experience.status} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

function ExperienceImage({ src }: { src: string | undefined }) {
  return (
    <>
      {src ? (
        <Image
          src={src}
          sx={{
            aspectRatio: '1 / 1',
            height: { xs: 140, md: 'auto' },
            width: { xs: 90, md: 80 },
            borderRadius: { xs: 0, md: 1 },
          }}
        />
      ) : (
        <ImagePlaceholder />
      )}
    </>
  );
}

function ImagePlaceholder() {
  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        objectFit: 'cover',
        aspectRatio: { xs: 1 / 1 },
        borderRadius: { xs: 0, md: 1 },
        width: { xs: 90, md: 80 },
        height: { xs: 140, md: 'auto' },
        maxHeight: { xs: 'auto', md: 'auto' },
        backgroundColor: lightTheme.palette.neutral.n100,
      }}
    >
      <MuiImage
        sx={{ color: lightTheme.palette.neutral.n200, width: 24, height: 24 }}
      />
    </Stack>
  );
}

function ExperienceHeadline({ headline }: Pick<IExperience, 'headline'>) {
  const { getLocalizedString } = useLocale();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: { xs: 220, md: '100%' },
        display: 'block',
      }}
    >
      <Text fontSize="small" variant="medium">
        {getLocalizedString(headline) || <NoExperienceTitle />}
      </Text>
    </Box>
  );
}

function UnpublishedChanges({
  hasUnpublishedChanges,
}: {
  hasUnpublishedChanges: boolean;
}) {
  const { t } = useTranslate('experience.list.item');

  if (!hasUnpublishedChanges) return null;

  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5}>
      <Text
        color={lightTheme.palette.neutral.n400}
        variant="normal"
        fontSize="xsmall"
      >
        {t('unsavedChanges')}
      </Text>
    </Stack>
  );
}

function UpcommingEventsLabel({
  totalCount,
}: {
  totalCount: number | undefined;
}) {
  const { t } = useTranslate('experience.list.item');
  return (
    <Text fontSize="small" variant="normal">
      {t('upcomingEvents', { count: totalCount ?? 0 })}
    </Text>
  );
}

function BookingCountLabel({
  seats,
}: {
  seats: { totalSeats: number; seatsBooked: number };
}) {
  const { t } = useTranslate('experience.list.item');

  return (
    <Text fontSize="small" variant="normal">
      {t('guestCount', {
        seatsBooked: seats?.seatsBooked,
        totalSeats: seats?.totalSeats,
      })}
    </Text>
  );
}

function StorefrontLinkLabel({ id }: { id: string }) {
  const { t } = useTranslate('experience.list.item');
  const { companyProfileUrl } = useProfile();
  const storefrontUrl = `${companyProfileUrl()}/experience/${id}`;

  return (
    <Stack
      component="a"
      target="_blank"
      sx={{
        display: { xs: 'none', md: 'inline-flex' },
        textDecoration: 'underline',
      }}
      href={storefrontUrl}
      onClick={(e) => e.stopPropagation()}
    >
      <Text fontSize="small" variant="normal">
        {t('storefrontLink')}
      </Text>
    </Stack>
  );
}

function VisibilityLabel({ visibility }: Pick<IExperience, 'visibility'>) {
  const { t } = useTranslate('experience.list.item');

  return (
    <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
      {t(visibility, 'experience.card.visibility')}
    </Text>
  );
}

function BadgeLabel({ status }: { status: string }) {
  const { t } = useTranslate('utils.statusOptions');

  const statusColor = useMemo(() => {
    if (status === 'active') {
      return {
        backgroundColor: lightTheme.palette.success.s100,
        color: lightTheme.palette.neutral.n400,
      };
    }

    return {
      backgroundColor: lightTheme.palette.neutral.n100,
      color: lightTheme.palette.neutral.n400,
    };
  }, [status]);

  return (
    <Badge
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        marginY: 'auto',
        paddingY: { xs: 0.25, md: 0.5 },
        paddingX: { xs: 1, md: 1.25 },
        backgroundColor: statusColor.backgroundColor,
        color: statusColor.color,
      }}
    >
      <Text fontSize="xsmall">{t(status)}</Text>
    </Badge>
  );
}

function SharedExperienceIcon() {
  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: 1,
        bottom: { xs: 16, md: 0 },
        right: { xs: 16, md: -16 },
        padding: 1,
        backgroundColor: lightTheme.palette.action.a400,
        color: lightTheme.palette.contrast.white,
        // backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '100%',
      }}
    >
      <HandshakeRounded
        sx={{ width: { xs: 16, md: 20 }, height: { xs: 16, md: 20 } }}
        color="inherit"
        fontSize="small"
      />
    </Stack>
  );
}
