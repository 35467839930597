import { SentimentSatisfiedOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { ExperienceTag, Location } from '@understory-io/utils-types';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Await } from 'react-router';

import { Badge } from '../../../../Components/badge/badge';
import { DetailItem } from '../../../../Components/detail-item/detail-item';
import PreviewMarkdown from '../../../../Components/preview-markdown/preview-markdown';
import { formatMoney } from '../../../../Components/VariantSelect/VariantSelect';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTicketsAndAddons } from '../../../../Hooks/use-tickets-and-addons';
import { useHasPaymentsEnabled } from '../../../../Hooks/useHasPaymentsEnabled';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontLanguage } from '../../../../i18n/config';
import { TicketsIcon } from '../../../../Icons';
import routes from '../../../../Utils/routes';
import { renderCutoffLabel } from '../../utils/render-duration';
import { PublishErrors } from '../edit-experience';
import { hasLocalizedValue, isUnlimited } from '../utils/form-helpers';
import { getTagsLabel, getTagsLocaliced } from '../utils/get-tags-label';
import { getTicketAddonLabel } from '../utils/get-ticket-label';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';
import { ElementItem, ElementItemContent, ElementList } from './element-list';
import { ResourceRuleListItem } from './resource-rule-list-item';

type DetailsOverviewProps = {
  experience: RegularExperience;
  activeLanguage: StorefrontLanguage;
  tags: ExperienceTag[];
  publishErrors?: PublishErrors;
  resourceTypesPromise: Promise<ResourceType[]>;
  locations: Location[];
};

export const DetailsOverview = ({
  experience,
  activeLanguage,
  tags,
  publishErrors,
  resourceTypesPromise,
  locations,
}: DetailsOverviewProps) => {
  const { t: baseT } = useTranslation();
  const { isSm } = useResponsive();
  const { t } = useTranslate('experience.edit.details');

  const { hasPaymentsEnabled } = useHasPaymentsEnabled(true);

  const baseRoute = routes.experience.details(experience.id).edit;

  const navigateWithParams = useNavigateWithParams();

  const variants = experience.price?.variants;
  const hasVariants = variants && variants.length > 0;
  const hasMultipleVariants = hasVariants && variants.length > 1;
  const displayVariant = experience.price?.variants?.find(
    (variant) => variant.id === experience.price?.displayVariantId
  );

  const MAX_TAGS_DISPLAYED = isSm ? 1 : 3;

  const allTickets = useTicketsAndAddons(experience);

  return (
    <Stack sx={{ gap: 1 }}>
      {/* Capacity */}
      <DetailItem
        title={t('label.capacity')}
        onClick={() => navigateWithParams(baseRoute.capacity)}
        selectedValue={
          isUnlimited(experience.seats.seatCount)
            ? t('capacity.unlimited')
            : t('capacity.description', {
                count: experience.seats.seatCount,
              })
        }
        hasError={
          !!publishErrors?.general?.find((error) => error.key === 'seats')
        }
      />

      {/* Close for bookings */}
      <DetailItem
        title={t('title', 'experience.edit.dialog.cutoff')}
        onClick={() => navigateWithParams(baseRoute.cutoff)}
        selectedValue={renderCutoffLabel(
          baseT,
          experience.cutoff,
          experience.cutoffTimeSeconds
        )}
      />

      {/* Tickets & price */}
      <DetailItem
        title={t('label.tickets')}
        onClick={() => navigateWithParams(baseRoute.tickets.index)}
        showAddButton
        hasError={
          !!publishErrors?.general?.find((error) => error.key === 'price')
        }
      >
        {hasVariants && (
          <>
            <ElementList>
              {variants.map((variant, index) => {
                const hasError = !!publishErrors?.[activeLanguage]?.find(
                  (error) => error.key === 'price' && error.index === index
                );

                return (
                  <ElementItem
                    key={variant.id}
                    onEdit={() =>
                      navigateWithParams(baseRoute.tickets.edit(variant.id))
                    }
                    onDelete={() =>
                      navigateWithParams(baseRoute.tickets.delete(variant.id))
                    }
                    badgeValue={getTicketAddonLabel(variant.addons?.length)}
                    Icon={TicketsIcon}
                    hasError={hasError}
                    hideDelete={!hasPaymentsEnabled}
                  >
                    <ElementItemContent
                      value={variant.name[activeLanguage]}
                      description={formatMoney(
                        t,
                        true
                      )({
                        value:
                          variant.priceBreakdown.vatInclusivePriceCents / 100,
                        nativeCurrency: variant.priceBreakdown.currency,
                      })}
                      errorPlaceholder={t('tickets.errorPlaceholder')}
                      hasError={hasError}
                    />
                  </ElementItem>
                );
              })}
            </ElementList>
            {!!displayVariant && hasMultipleVariants && (
              <Text
                fontSize="small"
                variant="medium"
                color={lightTheme.palette.action.a300}
              >
                {t('label.displayPrice', {
                  ticketName: displayVariant.name[activeLanguage],
                })}{' '}
                <Text
                  fontSize="small"
                  variant="medium"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateWithParams(baseRoute.displayPrice);
                  }}
                  textDecorationLine="underline"
                  color="inherit"
                  style={{
                    outlineColor: lightTheme.palette.neutral.n500,
                    cursor: 'pointer',
                  }}
                >
                  {t('displayPrice.changeLabel')}
                </Text>
              </Text>
            )}
          </>
        )}
      </DetailItem>

      {/* Guest information */}
      {!!experience.informationRequests?.length && (
        <DetailItem
          title={t('label.guestInfo')}
          onClick={() => navigateWithParams(baseRoute.guestInfo.index)}
          showAddButton
          hasError={
            !!publishErrors?.general?.find(
              (error) => error.key === 'informationRequests'
            )
          }
        >
          <ElementList>
            {experience.informationRequests?.map((item, index) => {
              const hasError = !!publishErrors?.[activeLanguage]?.find(
                (error) =>
                  error.key === 'informationRequests' && error.index === index
              );

              return (
                <ElementItem
                  key={item.id}
                  onEdit={() =>
                    navigateWithParams(baseRoute.guestInfo.edit(item.id))
                  }
                  onDelete={() =>
                    navigateWithParams(baseRoute.guestInfo.delete(item.id))
                  }
                  Icon={SentimentSatisfiedOutlined}
                  hasError={hasError}
                >
                  <ElementItemContent
                    value={item.request[activeLanguage]}
                    description={`${t('infoRequest.scope.selection')} ${t(`infoRequest.scope.${item.scope as string}`)}`}
                    errorPlaceholder={t('infoRequest.errorPlaceholder')}
                    hasError={hasError}
                  />
                </ElementItem>
              );
            })}
          </ElementList>
        </DetailItem>
      )}

      {/* Description */}
      {hasLocalizedValue(experience.practicalInfo) && (
        <DetailItem
          title={
            experience.practicalInfo[activeLanguage]
              ? t('label.description')
              : t('label.addDescription')
          }
          onClick={() => navigateWithParams(baseRoute.description)}
          sx={{ gap: 0.5 }}
        >
          {experience.practicalInfo[activeLanguage] && (
            <PreviewMarkdown>
              {experience.practicalInfo[activeLanguage]}
            </PreviewMarkdown>
          )}
        </DetailItem>
      )}

      {/* Whats Included */}
      {hasLocalizedValue(experience.whatsIncluded) && (
        <DetailItem
          title={t('label.whatsIncluded')}
          onClick={() => navigateWithParams(baseRoute.whatsIncluded)}
          sx={{ gap: 0.5 }}
        >
          {experience.whatsIncluded?.[activeLanguage] && (
            <PreviewMarkdown>
              {experience.whatsIncluded?.[activeLanguage]}
            </PreviewMarkdown>
          )}
        </DetailItem>
      )}

      {/* Practical Information */}
      {hasLocalizedValue(experience.infoForGuests?.yes) && (
        <DetailItem
          title={t('label.practicalInfo')}
          onClick={() => navigateWithParams(baseRoute.practicalInfo)}
          sx={{ gap: 0.5 }}
        >
          <PreviewMarkdown>
            {experience.infoForGuests?.yes?.[activeLanguage] ?? ''}
          </PreviewMarkdown>
        </DetailItem>
      )}

      {/* Tags */}
      {experience.tagIds?.length > 0 && (
        <DetailItem
          title={t('label.tags')}
          onClick={() => navigateWithParams(baseRoute.tags)}
          selectedValue={
            <Stack flexDirection="row" gap={1}>
              {getTagsLocaliced(
                experience.tagIds,
                tags,
                activeLanguage,
                false,
                MAX_TAGS_DISPLAYED
              ).map((tag) => (
                <Badge appearance="action" size="small" key={tag}>
                  {tag}
                </Badge>
              ))}
            </Stack>
          }
          tooltip={getTagsLabel(experience.tagIds, tags, activeLanguage, true)}
        />
      )}

      {/* Resources */}
      {!!experience.resourceRules?.rules.length && (
        <Suspense>
          <Await resolve={resourceTypesPromise}>
            {(resourceTypes) => (
              <DetailItem
                title={t('label.resourceRules')}
                onClick={() => navigateWithParams(baseRoute.resources.create)}
                showAddButton
              >
                <ElementList>
                  {experience.resourceRules?.rules.map((rule, ruleIndex) => (
                    <ResourceRuleListItem
                      key={rule.ruleId}
                      experienceId={experience.id}
                      rule={rule}
                      resourceTypes={resourceTypes}
                      locations={locations}
                      allTickets={allTickets}
                      hasError={
                        !!publishErrors?.general?.find(
                          (error) =>
                            error.key === 'resourceRules' &&
                            error.index === ruleIndex
                        )
                      }
                    />
                  ))}
                </ElementList>
              </DetailItem>
            )}
          </Await>
        </Suspense>
      )}
    </Stack>
  );
};
