import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { lightTheme, Skeleton, Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useAvailableScopes } from '../use-available-scopes';
import { FormSchema } from '../use-form-validation';

export const ScopesTable = () => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.scopes'
  );
  const { watch, setValue, clearErrors } = useFormContext<FormSchema>();
  const availableScopes = useAvailableScopes();
  const scopes = watch('scopes');

  const handleScopeChange = (
    scopeKey: string | undefined,
    checked: boolean
  ) => {
    if (!scopeKey) {
      return;
    }

    clearErrors('scopes');

    const newScopes = new Set(scopes);

    if (checked) {
      newScopes.add(scopeKey);
    } else {
      newScopes.delete(scopeKey);
    }

    setValue('scopes', Array.from(newScopes));
  };

  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          '& .MuiTableCell-root': {
            paddingX: 0,
          },
        }}
      >
        <TableHead>
          <TableRow sx={{ '& .MuiTableCell-root': { borderTop: 'none' } }}>
            <TableCell width={'70%'} />
            <TableCell align="center">
              <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                {t('readAccess')}
              </Text>
            </TableCell>
            <TableCell align="center" color={lightTheme.palette.neutral.n400}>
              <Text fontSize="small">{t('writeAccess')}</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availableScopes.isLoading && (
            <>
              <TableRow>
                <TableCell colSpan={3}>
                  <Skeleton variant="rectangular" width={'100%'} height={38} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>
                  <Skeleton variant="rectangular" width={'100%'} height={38} />
                </TableCell>
              </TableRow>
            </>
          )}
          {!availableScopes.isLoading &&
            availableScopes.data &&
            Object.entries(availableScopes.data).map(([scope, actions]) => (
              <TableRow key={scope}>
                <TableCell>
                  <Text fontSize="small">{t(`category.${scope}`)}</Text>
                </TableCell>
                <TableCell align="center">
                  {actions['read'] && (
                    <Checkbox
                      size="small"
                      checked={scopes.includes(`${scope}.read`)}
                      onChange={(e) =>
                        handleScopeChange(`${scope}.read`, e.target.checked)
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {actions['write'] && (
                    <Checkbox
                      size="small"
                      checked={scopes.includes(`${scope}.write`)}
                      onChange={(e) =>
                        handleScopeChange(`${scope}.write`, e.target.checked)
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
