import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { DraftExperience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../Api/Experience';
import {
  companyProfileQuery,
  draftExperienceQuery,
} from '../../../../Api/queries';
import MarkdownEditor from '../../../../Components/markdown-editor/markdown-editor';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontLanguage, t } from '../../../../i18n/config';
import { EditExperienceDialog } from '../components/dialogs/edit-experience-dialog';
import { MenuButtonAI } from '../components/menu-button-ai/menu-button-ai';
import { LoaderData, loaderName } from '../edit-experience';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { translateInput } from '../utils/translate-input';

const PROPERTY_NAME = 'practicalInfo';

export default function DescriptionDialog() {
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.description');
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;

  const [description, setDescription] = useState(
    experience.practicalInfo?.[activeLanguage]
  );

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="description"
    >
      <input name={PROPERTY_NAME} type="hidden" value={description} />
      <MarkdownEditor
        autoFocus={true}
        placeholder={t('input.placeholder')}
        value={description}
        onChange={(e) => {
          setDescription(e);
        }}
      >
        <MenuButtonAI
          experience={experience}
          activeLanguage={activeLanguage}
          tooltipLabel={t('description', 'utils.toolbar.ai')}
        />
      </MarkdownEditor>
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e400} variant="normal">
          {t(actionData.error)}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs): Promise<ActionData> => {
    const id = params.id;

    if (!id) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      // Validate input
      const formData = await request.formData();
      const description = getFormDataValue(formData.get(PROPERTY_NAME)) as
        | string
        | undefined;

      const [experience, companyProfile] = await Promise.all([
        client.fetchQuery(draftExperienceQuery(id)),
        client.fetchQuery(companyProfileQuery()),
      ]);

      const activeLanguage = getActiveLanguage(request, companyProfile);

      const translateddescription = await translateInput(
        description ?? '',
        experience.practicalInfo,
        activeLanguage,
        companyProfile.languages as StorefrontLanguage[],
        experience.autoTranslateEnabled
      );

      const experienceToSave = {
        ...experience,
        practicalInfo: translateddescription,
      } as DraftExperience;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        return {
          error: error.message,
        };
      }

      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
