import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import {
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  List,
  ListItem,
  Stack,
  TextField,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router';

import { ampli } from '../../Ampli';
import { states } from '../../Components/CustomCard/CustomCard';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useLocale } from '../../Hooks/locales/use-locale.context';
import { TBookingResult } from '../../Hooks/useSearch';
import { useTranslate } from '../../Hooks/useTranslate';
import { SearchDialogContextProvider, useSearchDialog } from './context';

export const SearchDialog = NiceModal.create(() => {
  return (
    <SearchDialogContextProvider>
      <SearchDialogContent />
    </SearchDialogContextProvider>
  );
});

const SearchDialogContent = () => {
  const { handleClose } = useSearchDialog();
  const { isMd } = useResponsive();
  const modal = useModal();

  const trackClose = () => {
    ampli.searchUserCloseDialog({ location: window.location.pathname });
    handleClose();
  };

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth={'md'}
      fullScreen={isMd}
      open={modal.visible}
      PaperProps={{
        sx: {
          p: 2,
          pb: 0,
          gap: 2,
          height: !isMd ? '480px' : '100%',
          maxHeight: '480px',
          background: 'linear-gradient(to bottom, white 90%, #eeeeee 100%)',
          display: 'flex',
        },
      }}
      onClose={trackClose}
    >
      {isMd && <CloseRounded onClick={trackClose} sx={{ cursor: 'pointer' }} />}
      <SearchField />
      <SearchResultsList />
    </Dialog>
  );
};

const SearchField = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const modal = useModal();

  const { setInput, input } = useSearchDialog();

  const { t } = useTranslate('dialogs.search');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchInputRef.current?.focus();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [modal.visible]);

  return (
    <TextField
      inputRef={searchInputRef}
      InputProps={{
        startAdornment: <SearchRounded fontSize="small" sx={{ mr: 1 }} />,
      }}
      inputProps={{
        style: {
          fontSize: lightTheme.typography.medium.normal.fontSize,
        },
      }}
      size="medium"
      placeholder={t('fieldPlaceholder')}
      value={input}
      onChange={(e) => {
        setInput(e.target.value);
      }}
    />
  );
};

const SearchResultsList = () => {
  const { listItemsRef, searchResults } = useSearchDialog();

  if (searchResults.length === 0) return <EmptyStates />;

  return (
    <List
      ref={listItemsRef}
      sx={{
        overflowY: 'scroll',
        pb: 2,
      }}
    >
      {searchResults.map((result, index) => (
        <Stack key={result.id}>
          <SearchDialogResultItem index={index} booking={result} />
          {index < searchResults.length - 1 && (
            <Divider key={`divider-${index}`} sx={{ mx: 1 }} />
          )}
        </Stack>
      ))}
    </List>
  );
};

export type ChipState =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'default';

const SearchDialogResultItem = ({
  index,
  booking,
}: {
  index: number;
  booking: TBookingResult;
}) => {
  const { selectedIndex, setSelectedIndex, usingArrowKeys, goToBooking } =
    useSearchDialog();

  const selected = selectedIndex === index;

  const { getLocalizedString } = useLocale();

  const experienceName = getLocalizedString(booking.experienceName);

  const eventDate = renderDateTime(booking.startDateTime, booking.endDateTime, {
    standard: 'ddmyyyyhh',
    sameDay: 'dmhh',
  });

  return (
    <Link to={`/booking/${booking.id}`} style={{ textDecoration: 'none' }}>
      <ListItem
        key={booking.id}
        sx={{
          alignItems: 'flex-start',
          flexDirection: 'column',
          backgroundColor: selected
            ? lightTheme.palette.neutral.n100 + '20'
            : undefined,
          p: 2,
          borderRadius: 1,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={goToBooking}
        onMouseMove={() => {
          if (!usingArrowKeys && !selected) setSelectedIndex(index);
        }}
      >
        <Stack gap={0.5} width="100%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Chip
                color={(states[booking.status][1] as ChipState) ?? 'default'}
                sx={{
                  height: 10,
                  width: 10,
                  borderRadius: 12,
                }}
              />
              <Text variant="medium">{booking.customer.name}</Text>
            </Stack>
            <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
              {booking.id}
            </Text>
          </Stack>
          <Text fontSize="small">{booking.customer.email}</Text>
        </Stack>
        <Stack direction="row" gap={1}>
          <Text fontSize="small">{experienceName},</Text>
          <Text fontSize="small">{eventDate}</Text>
        </Stack>
      </ListItem>
    </Link>
  );
};

const EmptyStates = () => {
  const { t } = useTranslate('dialogs.search');

  const { query, isFetching } = useSearchDialog();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ flexGrow: 1, paddingBottom: 2 }}
      position="relative"
      textAlign="center"
    >
      {isFetching ? (
        <CircularProgress sx={{ color: lightTheme.palette.neutral.n500 }} />
      ) : query ? (
        t('noResults', { query })
      ) : (
        t('noSearchQuery')
      )}
    </Stack>
  );
};
