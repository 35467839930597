import { alpha, Stack, StackProps, StackTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme } from '@understory-io/pixel';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { mergeSx } from '../../Utils/merge-sx';

export const FullPageHeader: OverridableComponent<StackTypeMap> = ({
  sx,
  ...rest
}: StackProps) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollOpacity = useMemo(() => (isScrolling ? 0.5 : 1), [isScrolling]);

  const handleScroll = useCallback(() => {
    setIsScrolling(window.scrollY > 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Stack
      sx={mergeSx(
        {
          position: 'sticky',
          backdropFilter: isScrolling ? 'blur(20px)' : undefined,
          backgroundColor: {
            xs: alpha(lightTheme.palette.contrast.surface2, scrollOpacity),
            md: alpha(lightTheme.palette.contrast.surface1, scrollOpacity),
          },
          top: 0,
          zIndex: 15,
          padding: { xs: 2, md: 4 },
          paddingBottom: { md: 3 },
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 5,
        },
        sx
      )}
      {...rest}
    />
  );
};
