import { ampli, MarkdownEditorButtonClickedProperties } from '../../Ampli';

export enum MARKDOWN_BUTTON_TYPE {
  SELECT_HEADING = 'select_heading',
  BOLD = 'bold',
  ITALIC = 'italic',
  LINK = 'link',
  BULLETED_LIST = 'bulleted_list',
  ORDERED_LIST = 'ordered_list',
}

export const trackMarkdownEditorButtonClicked = (
  options: MarkdownEditorButtonClickedProperties
) => {
  ampli.markdownEditorButtonClicked({ ...options });
};
