import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useState } from 'react';

import { ampli } from '../../../../../Ampli';
import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';

type AutoTranslateWarningDialogProps = {
  onDisableTranslations: () => void;
  onKeepTranslationsEnabled: () => void;
};

const EXPERIENCE_PREFERENCES_KEY = 'experiences';

export const AutoTranslateWarningDialog = NiceModal.create(
  ({ onDisableTranslations }: AutoTranslateWarningDialogProps) => {
    const { t } = useTranslate('experience.edit.dialog.autoTranslationWarning');
    const modal = useModal();
    const { updatePreferences } = useProfile();
    const [skipAutoTranslationWarning, setSkipAutoTranslationWarning] =
      useState(false);

    const handleClose = useCallback(
      (choice: 'dismiss' | 'disable-translations' | 'keep-translations') => {
        ampli.experienceEditAutoTranslationWarningClosed({ choice });

        updatePreferences(
          EXPERIENCE_PREFERENCES_KEY,
          (previous: Record<string, string | boolean> | undefined) => {
            return {
              ...previous,
              skipAutoTranslationWarning,
            };
          }
        );

        modal.resolve(choice);
        modal.remove();
      },
      [modal, skipAutoTranslationWarning, updatePreferences]
    );

    const handleDisableTranslations = () => {
      onDisableTranslations();
      handleClose('disable-translations');
    };

    const handleKeepTranslationsEnabled = () => {
      handleClose('keep-translations');
    };

    return (
      <DialogBase
        open={modal.visible}
        onClose={() => handleClose('dismiss')}
        maxWidth="sm"
        title={t('title')}
        description={t('description')}
        fullHeight={false}
        actionsInHeader={false}
        showSecondaryActionDesktop
        secondaryAction={{
          label: t('disableTranslations'),
          variant: 'secondary',
          onClick: handleDisableTranslations,
        }}
        primaryAction={{
          label: t('keepTranslationsEnabled'),
          variant: 'primary',
          onClick: handleKeepTranslationsEnabled,
        }}
      >
        <FormControlLabel
          value={skipAutoTranslationWarning}
          onChange={() => setSkipAutoTranslationWarning((prev) => !prev)}
          label={t('hideThisMessage')}
          control={<Checkbox disableRipple size="small" />}
        />
      </DialogBase>
    );
  }
);
