import { Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';

import { useTranslate } from '../../Hooks/useTranslate';
import { TipContainer } from './tip-container';
import { TipContent } from './tip-content';
import { TipHeader } from './tip-header';
import { Tip } from './use-tips';

interface TipSidebarDesktopProps {
  showTip: boolean;
  currentTip: Tip | null;
  onClose: () => void;
  onDismiss: () => void;
}

export const TipSidebarDesktop = ({
  showTip,
  currentTip,
  onClose,
  onDismiss,
}: TipSidebarDesktopProps) => {
  const { t } = useTranslate('tips');

  return (
    <TipContainer show={showTip && !!currentTip}>
      <Stack
        gap={4}
        sx={{
          height: '100%',
        }}
      >
        <TipHeader onClose={onClose} />
        {currentTip && (
          <TipContent
            currentTip={currentTip}
            showImage={true}
            showTitle={true}
          />
        )}
        <Button
          variant="text"
          size="small"
          onClick={onDismiss}
          style={{
            color: lightTheme.palette.neutral.n300,
            padding: 0,
            paddingBottom: 16,
            marginTop: 'auto',
            textDecoration: 'underline',
            alignSelf: 'center',
          }}
        >
          {t('dontShowAgain')}
        </Button>
      </Stack>
    </TipContainer>
  );
};
