import NiceModal from '@ebay/nice-modal-react';
import {
  CampaignOutlined,
  DraftsOutlined,
  GroupAddOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { sendSlackNotification } from '../../../../Api/Notifications';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../Modals/confirm-dialog';
import { useGrowthOpportunityRequestStore } from '../../../../Store/useGrowthOpportunityRequestsStore';
import {
  trackGrowthCampaignManagementRequestCompleted,
  trackGrowthCampaignManagementRequestStarted,
  trackGrowthConnectRequestCompleted,
  trackGrowthConnectRequestStarted,
  trackGrowthEmailMarketingRequestCompleted,
  trackGrowthEmailMarketingRequestStarted,
  trackGrowthIntegrationsRequestCompleted,
  trackGrowthIntegrationsRequestStarted,
} from '../../../../tracking/growth/growth-events';
import { OpportunityDialog } from '../ui/opportunity-dialog';
import { Opportunity } from './types';

export const useGrowthOpportunities = () => {
  const { t } = useTranslate('opportunities');
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);
  const fireOnce = useFireOnce();

  const [requestState, setRequestState] = useState(
    useGrowthOpportunityRequestStore.getState()
  );

  useEffect(() => {
    const unsubscribe =
      useGrowthOpportunityRequestStore.subscribe(setRequestState);
    return () => unsubscribe();
  }, []);

  const {
    me: { data: me },
    company: { data: company },
  } = useProfile();

  const handleInterest = useCallback(
    async (id: string, title: string, onCompleted?: () => void) => {
      const message = `Growth Opportunity requested:\n>Opportunity: ${title}\n>Name: ${me?.name}\n>Email: ${me?.email}\n>Company: ${company?.name}`;

      let attempt = 0;
      const maxAttempts = 3;

      while (attempt < maxAttempts) {
        try {
          await sendSlackNotification(message, '#growth-opportunity-requests');

          useGrowthOpportunityRequestStore
            .getState()
            .addGrowthOpportunityRequest(id);

          onCompleted?.();

          NiceModal.show(ConfirmDialog, {
            title: t('request.beInTouch'),
            description: t('request.success'),
            cta: t('ok', 'utils.generic'),
            hideCancel: true,
            onConfirm: () => Promise.resolve(),
          });

          return;
        } catch (error) {
          attempt++;

          if (attempt === maxAttempts) {
            toast.error(t('request.error'));
            return;
          }

          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
      }
    },
    [t, me, company]
  );

  const getStartedAction = async (opportunity: Opportunity) => {
    if (opportunity.destinationUrl) {
      if (isNavigating) return;
      setIsNavigating(true);
      opportunity.onCompleted?.();
      return navigate(opportunity.destinationUrl);
    }

    await handleInterest(
      opportunity.id,
      opportunity.name,
      opportunity.onCompleted
    );
  };

  const readMoreAction = (opportunity: Opportunity) => {
    fireOnce(() => opportunity.onReadMore?.());

    NiceModal.show(OpportunityDialog, {
      opportunity,
      onInterest: async () => await getStartedAction(opportunity),
    });
  };

  const opportunities: Opportunity[] = useMemo(
    () => [
      {
        id: 'campaignManagement',
        name: t('campaignManagement.name'),
        teaser: t('campaignManagement.teaser'),
        icon: CampaignOutlined,
        graphic: '/graphics/growth/campaign-management.svg',
        title: t('campaignManagement.title'),
        subtitle: t('campaignManagement.subtitle'),
        description: t('campaignManagement.description'),
        resource: {
          en: 'https://26131226.fs1.hubspotusercontent-eu1.net/hubfs/26131226/growth-resources/hojriis-case-study/H%C3%B8jriis%20Slot%20-%20Case%20Story%20-%20English%20.pdf',
          da: 'https://26131226.fs1.hubspotusercontent-eu1.net/hubfs/26131226/growth-resources/hojriis-case-study/H%C3%B8jriis%20Slot%20-%20Case%20Story%20-%20Danish%20.pdf',
          sv: 'https://26131226.fs1.hubspotusercontent-eu1.net/hubfs/26131226/growth-resources/hojriis-case-study/H%C3%B8jriis%20Slot%20-%20Case%20Story%20-%20Swedish.pdf',
        },
        isRequested: requestState.isRequestInStore('campaignManagement'),
        destinationUrl: '/growth/advertisement',
        onReadMore: () => trackGrowthCampaignManagementRequestStarted(me?.id),
        onCompleted: () =>
          trackGrowthCampaignManagementRequestCompleted(me?.id),
      },
      {
        id: 'emailMarketing',
        name: t('emailMarketing.name'),
        teaser: t('emailMarketing.teaser'),
        icon: DraftsOutlined,
        graphic: '/graphics/growth/email-marketing.svg',
        title: t('emailMarketing.title'),
        subtitle: t('emailMarketing.subtitle'),
        description: t('emailMarketing.description'),
        isRequested: requestState.isRequestInStore('emailMarketing'),
        onReadMore: () => trackGrowthEmailMarketingRequestStarted(me?.id),
        onCompleted: () => trackGrowthEmailMarketingRequestCompleted(me?.id),
      },
      {
        id: 'marketplaces',
        name: t('marketplaces.name'),
        teaser: t('marketplaces.teaser'),
        icon: ShareOutlined,
        graphic: '/graphics/growth/integrations.svg',
        title: t('marketplaces.title'),
        subtitle: t('marketplaces.subtitle'),
        description: t('marketplaces.description'),
        destinationUrl: '/settings/integrations',
        onReadMore: () => trackGrowthIntegrationsRequestStarted(me?.id),
        onCompleted: () => trackGrowthIntegrationsRequestCompleted(me?.id),
      },
      {
        id: 'connect',
        name: t('connect.name'),
        teaser: t('connect.teaser'),
        icon: GroupAddOutlined,
        graphic: '/graphics/growth/connect.svg',
        title: t('connect.title'),
        subtitle: t('connect.subtitle'),
        description: t('connect.description'),
        destinationUrl: '/settings/connect',
        onReadMore: () => trackGrowthConnectRequestStarted(me?.id),
        onCompleted: () => trackGrowthConnectRequestCompleted(me?.id),
      },
    ],
    [t, requestState, me]
  );

  useEffect(() => {
    opportunities.forEach((opportunity) => {
      new Image().src = opportunity.graphic;
    });
  }, [opportunities]);

  return {
    opportunities,
    isNavigating,
    handleInterest,
    getStartedAction,
    readMoreAction,
  };
};
