import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { lightTheme, Text } from '@understory-io/pixel';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  replace,
  useLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { resourceTypesQuery, userInfoQuery } from '../../../../Api/queries';
import { createResource } from '../../../../Api/resource-management';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useHistory } from '../../../../Hooks/useHistory';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import routes from '../../../../Utils/routes';
import { resourcesQuery } from '../../data/use-resources';
import { CreateResourceForm } from './create-resource-form';
import { CreateResourceInputs } from './create-resource-inputs';

type LoaderData = {
  resourceTypeId: string;
};

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const user = await client.fetchQuery(userInfoQuery());
    if (!user.companyId) {
      return redirect('/login');
    }

    const { resourceTypeId } = params;

    return { resourceTypeId };
  };

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const loadingToastId = randomBytes(16).toString('hex');
    toast.loading(
      t('resourceManagement.resourceType.createNew.toast.loading'),
      {
        toastId: loadingToastId,
      }
    );

    try {
      const user = await queryClient.fetchQuery(userInfoQuery());
      if (!user.companyId) {
        throw new Error("User doesn't have a company ID");
      }

      const payload = (await request.json()) as CreateResourceInputs;

      await createResource({
        ...payload,
        companyId: user.companyId,
      });

      await queryClient.invalidateQueries({
        queryKey: resourcesQuery(payload.resourceTypeId).queryKey,
      });

      // Since the type will have total resources incremented,
      // we either have to invalidate all types or manually update the cache.
      await queryClient.invalidateQueries({
        queryKey: resourceTypesQuery().queryKey,
      });

      toast.dismiss(loadingToastId);

      return replace(
        routes.resourceManagement.details(payload.resourceTypeId).index
      );
    } catch (error) {
      captureException(error);

      toast.dismiss(loadingToastId);
      toast.error(t('resourceManagement.resource.createNew.toast.error'), {
        delay: 500,
      });
      return {
        success: false,
      };
    }
  };

export const CreateResourceDialog = () => {
  const { t } = useTranslate('resourceManagement.resource');
  const { isSm } = useResponsive();
  const { resourceTypeId } = useLoaderData() as LoaderData;
  const fireOnce = useFireOnce();

  const { goBack } = useHistory();

  const handleClose = () => {
    goBack(routes.resourceManagement.index);
  };

  useEffect(() => {
    fireOnce(() => ampli.resourceCreateFlowStarted());
  }, [fireOnce]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('createNew.title')}
      /**
       * `disableRestoreFocus` is needed for the input to be able to focus - at least in dev mode.
       * @see https://github.com/mui/material-ui/issues/33004
       */
      disableRestoreFocus
    >
      <DialogContent sx={{ padding: 4 }}>
        <Stack direction="row" justifyContent="space-between" marginBottom={1}>
          <Text fontSize="xlarge" variant="medium">
            {t('createNew.title')}
          </Text>
          <IconButton sx={{ padding: 0 }} onClick={handleClose} tabIndex={-1}>
            <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
          </IconButton>
        </Stack>

        <Box mt={0.5} mb={1}>
          <Text color={lightTheme.palette.neutral.n400} fontSize="small">
            {t('createNew.description')}
          </Text>
        </Box>

        <CreateResourceForm resourceTypeId={resourceTypeId} />
      </DialogContent>
    </Dialog>
  );
};
