import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as api from '../../Api';
import {
  eventQueryKey,
  eventsInExperienceQueryKey,
  userInfoQuery,
} from '../../Api/queries';
import { eventSchema } from '../../Pages/SyiPage/config';
import { IEvent, stripGuides } from '../../Utils/eventHelpers';
import { useOnBoarding } from '../useOnBoarding';
import { useProfile } from '../useProfile';
import { getEventsQueryKey } from './useGetEvents';

export const useUpdateEvent = (eventId?: string, experienceId?: string) => {
  const queryClient = useQueryClient();
  const { company } = useProfile();
  const { updateStep } = useOnBoarding();

  const updateEvent = useMutation({
    mutationFn: async ({ org, assignedGuides, slots, ...data }: IEvent) => {
      const userinfo = queryClient.getQueryData<{ org?: string }>(
        userInfoQuery().queryKey
      );

      const santizedEvent = {
        ...data,
        org: org ?? company?.data?.id ?? userinfo?.org,
        assignedGuides: stripGuides(assignedGuides),
      };

      const { id } = await api.updateEvent(santizedEvent, data.id ?? eventId);

      return { ...santizedEvent, id };
    },

    onSettled: async (data, variables) => {
      updateStep(
        { ...variables, ...data, id: eventId },
        eventSchema,
        'event',
        'create'
      );
      await queryClient.invalidateQueries({
        queryKey: getEventsQueryKey(),
      });
      await queryClient.invalidateQueries({
        queryKey: eventQueryKey(eventId ?? ''),
      });
      await queryClient.invalidateQueries({
        queryKey: eventsInExperienceQueryKey(experienceId ?? ''),
      });
    },
  });

  return { updateEvent };
};
