export type TaxType = 'vat' | 'sales-tax' | 'generic';

export function countryToTaxType(countryCode?: string): TaxType {
  if (countryCode === 'US') {
    return 'sales-tax';
  }

  if (countryCode && VAT_COUNTRIES.includes(countryCode)) {
    return 'vat';
  }

  return 'generic';
}

const VAT_COUNTRIES = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'GR',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'NO',
  'CH',
  'GB',
  'IS',
  'LI',
  'TR',
  'MC',
  'IM',
];
