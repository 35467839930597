import { ReducedVat, VatCompliance } from '@understory-io/utils-types';

import { TranslateFunction } from '../../Hooks/useTranslate';
import { CountryVatRates } from '../../Sections/SettingsSections/Company/domain/types';
import { countryToTaxType } from './country-to-tax-type';

export const getDisplayVatNumber = (
  vatNumber: string,
  prefix?: string,
  suffix?: string
) => {
  return vatNumber.replace(prefix ?? '', '').replace(suffix ?? '', '');
};

export const getFullVatNumber = (
  vatNumber: string,
  prefix?: string,
  suffix?: string
) => {
  return `${prefix ?? ''}${vatNumber}${suffix ?? ''}`;
};

/**
 * Vat rates we filter out - usually these are not relevant, and we want to keep the list short
 */
const filteredVatRates = [
  'periodicals',
  'newspapers',
  'writers-composers',
  'hairdressing',
  'repair-of-shoes-and-leather-goods',
  'repair-of-clothing-and-household-linen',
  'medical-equipment-for-disabled-persons',
  'tv-licenses',
  'childrens-car-seats',
  'water-supplies',
  'pharmaceutical-products',
  'medical-and-dental-care',
];

/**
 * Get the tax rate label based on a category and tax rate model.
 */
export function getTaxRateLabel(
  taxCountry: string | undefined,
  tax: {
    category: string;
    value: ReducedVat;
  },
  t: TranslateFunction
): { label: string; rateLabel: string } {
  const rateLabel = tax.value.exempt
    ? t(
        countryToTaxType(taxCountry) === 'vat' ? 'vat' : 'generic',
        'tax.exempt'
      )
    : `${(tax.value.rate * 100).toFixed(1)}%`;

  const label =
    tax.value.label ?? t(tax.category, 'vat.categories', { defaultValue: '-' });
  return { label, rateLabel };
}

type GetVatRateAsArrayResult = Array<{
  category: string;
  value: ReducedVat;
}>;
export function getVatRatesAsArray(
  rates?: Partial<
    | VatCompliance['vatRegistrations'][0]['rates']
    | CountryVatRates[number]['rates']
  >
): GetVatRateAsArrayResult {
  if (!rates) return [];

  const result: GetVatRateAsArrayResult = [];
  if (rates.standard !== undefined) {
    result.push({
      category: 'standard',
      value: { rate: rates.standard, exempt: false },
    });
  }

  if (rates.superReduced !== undefined) {
    result.push({
      category: 'superReduced',
      value: { rate: rates.superReduced, exempt: false },
    });
  }

  if (rates.reduced) {
    result.push(
      ...rates.reduced.flatMap((rate) =>
        Object.entries(rate).map(([key, value]) => {
          if (!('rate' in value) || ('exempt' in value && value.exempt)) {
            return {
              category: key,
              value: {
                ...value,
                exempt: true,
                rate: 0,
              },
            } as const;
          }

          return {
            category: key,
            value: {
              ...value,
              exempt: false,
              rate: value.rate,
            },
          } as const;
        })
      )
    );
  }

  return result.filter((rate) => !filteredVatRates.includes(rate.category));
}
