/**
 * Get Place details
 */
export const getPlaceDetails = async (
  service: google.maps.places.PlacesService,
  placeId: string
) => {
  return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    service.getDetails(
      {
        placeId,
        fields: ['address_component', 'geometry'],
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          resolve(place);
        } else {
          reject('Error getting place address');
        }
      }
    );
  });
};
