import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { countries } from '@understory-io/utils-countries';
import { startTransition, useMemo } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';

export const CountrySelect = ({
  label,
  sx = {},
  value = '',
  onChange,
  ...props
}: SelectProps) => {
  const { t, i18n } = useTranslate('countryCodes');

  const sortedCountries = useMemo(() => {
    return Object.entries(countries)
      .map(
        ([code, { name }]) =>
          [code, { name: t(code, { defaultValue: name }) }] as const
      )
      .sort((a, b) =>
        a[1].name.localeCompare(b[1].name, i18n.resolvedLanguage)
      );
  }, [i18n.resolvedLanguage, t]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink required={props.required}>
        {label}
      </InputLabel>
      <Select
        input={<OutlinedInput notched label={label} />}
        value={value}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
            },
          },
        }}
        onChange={(...params) => {
          startTransition(() => {
            onChange?.(...params);
          });
        }}
        {...props}
        sx={{
          maxHeight: 48,
          ...sx,
        }}
      >
        {sortedCountries.map(([code, { name }]) => (
          <MenuItem value={code} key={code}>
            <Typography>{name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
