import { ButtonBase, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ampli } from '../../Ampli';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { useTranslate } from '../../Hooks/useTranslate';
import { CloseIcon } from '../../Icons';

type ProductAnnouncementBannerProps = {
  id: string;
  url?: string;
  onClick?: () => void;
};

export const ProductAnnouncementBanner = ({
  id,
  url,
  onClick,
}: ProductAnnouncementBannerProps) => {
  const { t } = useTranslate(`productAnnouncementBanner.${id}`);
  const [isVisible, setIsVisible] = useState(false);
  const fireOnce = useFireOnce();
  const navigate = useNavigate();

  useEffect(() => {
    const bannerState = localStorage.getItem(`productAnnouncementBanner.${id}`);
    const visible = bannerState !== 'false';
    setIsVisible(visible);

    if (visible) {
      fireOnce(() =>
        ampli.sidebarBannerRendered({
          feature: id,
        })
      );
    }
  }, [id, fireOnce]);

  const hideBanner = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsVisible(false);
    setTimeout(() => {
      localStorage.setItem(`productAnnouncementBanner.${id}`, 'false');
    }, 300);
    ampli.sidebarBannerClosed({
      feature: id,
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          exit={{ opacity: 0, scale: 0.9 }}
        >
          <ButtonBase
            onClick={() => {
              ampli.sidebarBannerClicked({
                feature: id,
              });
              if (url) navigate(url);
              onClick?.();
            }}
            sx={{
              display: 'block',
              width: '100%',
              textAlign: 'left',
              padding: 2,
              borderRadius: 2,
              overflow: 'hidden',
              fontFamily: 'Inter',
              backgroundColor: lightTheme.palette.contrast.white,
              outline: `1px solid ${lightTheme.palette.contrast.surface1}`,
              transition: 'all 0.2s ease-in-out',
              boxShadow: lightTheme.shadows.large,
              '&:hover': {
                boxShadow: lightTheme.shadows.medium,
                outline: `1px solid ${lightTheme.palette.contrast.surface2}`,
              },
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={1}>
                <Text
                  fontSize="small"
                  variant="medium"
                  style={{ lineHeight: '1.2' }}
                >
                  {t('title')}
                </Text>
                <Text
                  fontSize="xsmall"
                  color={lightTheme.palette.neutral.n400}
                  style={{ lineHeight: '1.2' }}
                >
                  {t('description')}
                </Text>
              </Stack>
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  color: lightTheme.palette.neutral.n400,
                  '&:hover': {
                    color: lightTheme.palette.neutral.n500,
                  },
                  fontSize: '16px',
                }}
                onClick={(e) => hideBanner(e)}
              />
            </Stack>
          </ButtonBase>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
