import NiceModal from '@ebay/nice-modal-react';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledPhoneInput from '../../../Components/PhoneInput/ControlledPhoneInput';
import { CompanyLogo } from './company-logo';
import { CompanyFormData } from './company-settings';
import { StorefrontCompanyNameInfoDialog } from './storefront-company-name-info-dialog';

export const CompanyDetailsInputs = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<CompanyFormData>();

  const onInfoClick = () => {
    NiceModal.show(StorefrontCompanyNameInfoDialog);
  };

  return (
    <Stack
      sx={{
        flexDirection: { sm: 'row' },
        gap: { xs: 2 },
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Stack sx={{ gap: 3, maxWidth: { sm: '60%' }, flexGrow: 1 }}>
        <Stack>
          <InfoOutlined
            sx={{
              color: lightTheme.palette.neutral.n300,
              width: '16px',
              alignSelf: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={onInfoClick}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('settings.company.companyName')}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  sx: { maxHeight: 48 },
                }}
              />
            )}
          />
        </Stack>

        <Controller
          name="website"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t('settings.company.website')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { maxHeight: 48 } }}
            />
          )}
        />
        <Controller
          name="companyEmail"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t('settings.company.email')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { maxHeight: 48 } }}
            />
          )}
        />
        <ControlledPhoneInput
          name="companyPhone"
          control={control}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      </Stack>
      <Stack
        sx={{
          maxWidth: { xs: 250, sm: '35%' },
          alignItems: 'center',
          flexGrow: 1,
          marginX: { xs: 'auto', sm: 'unset' },
        }}
      >
        <CompanyLogo companyId={companyId} />
      </Stack>
    </Stack>
  );
};
