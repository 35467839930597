import { BookingAvailabilityManager } from '@understory-io/availability';
import { Experience } from '@understory-io/experiences-types';

import { getResourceAvailability } from '../../../../Api/resource-management';

type Props = {
  resourceRules: NonNullable<Experience['resourceRules']>;
  tickets: { [ticketId: string]: number };
  startDateTime: string;
  endDateTime: string;
  locationId: string;
  eventId: string;
};

export async function checkResourceAvailability({
  resourceRules,
  startDateTime,
  endDateTime,
  tickets,
  locationId,
  eventId,
}: Props) {
  const { queries, resourceRequirements } = await getResourceAvailability({
    rule: {
      operator: 'and',
      rules: resourceRules.rules.flatMap((rule) => {
        const resourceTypeId = rule.resourceTypeIdByLocation[locationId];

        if (!resourceTypeId) {
          return [];
        }

        return {
          ...rule,
          resourceTypeId,
        };
      }),
    },
    queries: [
      {
        eventId,
        startsAtMs: new Date(startDateTime).getTime(),
        endsAtMs: new Date(endDateTime).getTime(),
      },
    ],
  });
  const [availabilityResult] = queries;

  const manager = new BookingAvailabilityManager({
    resourceManagement: {
      resourceAvailability: availabilityResult.resourceAvailability,
      ticketsRequiringResources: resourceRequirements.tickets,
    },
    maxGuestsOnBooking: availabilityResult.availability.booking,
  });

  const canAddTickets = manager.canAddTickets(tickets);

  return {
    canAddTickets,
  };
}
