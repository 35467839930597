import {
  API,
  PlatformCustomer,
} from '@understory-io/customer-management-types';

import api from '.';
import { apiServices } from './apiServices';

export const getPlatformCustomer = async (
  companyId: string
): Promise<PlatformCustomer> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<API.GetCustomer.Response>(
    `${apiServices.internalApi}/v1/customer-management/customers/${companyId}`
  );

  return data.item;
};

export const validateCustomerVatNumber = async (
  companyId: string,
  request: API.ValidateCustomerVat.Request
): Promise<API.ValidateCustomerVat.Response> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.post<API.ValidateCustomerVat.Response>(
    `${apiServices.internalApi}/v1/customer-management/customers/${companyId}/vat-validations`,
    request
  );

  return data;
};

export const updatePlatformCustomer = async (
  companyId: string,
  requestBody: API.UpdateCustomer.Request
): Promise<void> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.put(
    `${apiServices.internalApi}/v1/customer-management/customers/${companyId}`,
    requestBody
  );
};
