import { TextField, TextFieldProps } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { forwardRef } from 'react';

import { mergeSx } from '../../../Utils/merge-sx';

export const FullPageFormTitleInput = forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ sx, ...props }, ref) => {
  return (
    <TextField
      ref={ref}
      autoFocus={!props.value}
      variant="standard"
      sx={mergeSx(
        {
          '& .MuiInputBase-formControl:before': {
            borderColor: lightTheme.palette.neutral.n100,
          },
          '& .MuiInputBase-formControl:after': {
            borderColor: props.error
              ? lightTheme.palette.error.e300
              : lightTheme.palette.neutral.n500,
          },
        },
        sx
      )}
      inputProps={{
        sx: {
          paddingBottom: 0.75,
          fontSize: 44,
          lineHeight: '100%',
        },
      }}
      {...props}
    />
  );
});

FullPageFormTitleInput.displayName = 'FullFormPageTitleInput';
