import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { ampli } from '../../../../../Ampli';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { VideoContainer, VideoIframe, VideoIframeContainer } from './styles';

declare global {
  interface Window {
    Vimeo: {
      Player: new (element: HTMLIFrameElement) => {
        on: (
          event: string,
          callback: (data: { seconds: number }) => void
        ) => void;
        off: (event: string) => void;
        getDuration: () => Promise<number>;
      };
    };
  }
}

type VideoSectionProps = {
  onHide: () => void;
};

export const VideoSection = ({ onHide }: VideoSectionProps) => {
  const { t } = useTranslate('dashboard.suggestions.video');
  const { isMd } = useResponsive();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [lastTrackedProgress, setLastTrackedProgress] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (window.Vimeo) {
      setScriptLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!scriptLoaded || !iframeRef.current) return;

    const player = new window.Vimeo.Player(iframeRef.current);

    player.on('play', () => {
      if (!hasStarted) {
        ampli.dashboardSuggestionsVideoStarted();
        setHasStarted(true);
      }
    });

    player.on('timeupdate', async (data: { seconds: number }) => {
      const progress = Math.floor(
        (data.seconds / (await player.getDuration())) * 100
      );

      const milestones = [25, 50, 75, 100];
      const nextMilestone = milestones.find(
        (m) => progress === m && lastTrackedProgress < m
      );

      if (nextMilestone) {
        ampli.dashboardSuggestionsVideoProgress({
          progress: nextMilestone,
        });
        setLastTrackedProgress(nextMilestone);
      }
    });

    return () => {
      player.off('play');
      player.off('timeupdate');
    };
  }, [scriptLoaded, lastTrackedProgress, hasStarted]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
      }}
    >
      <VideoContainer direction={isMd ? 'column' : 'row'}>
        <VideoIframeContainer>
          <VideoIframe
            ref={iframeRef}
            src="https://player.vimeo.com/video/1064240147?h=7becea38c7&badge=0&autopause=0&player_id=0&app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
            allowFullScreen
            title="Understory Demo"
            onClick={() => {
              ampli.dashboardSuggestionsVideoDialogOpened();
            }}
          />
        </VideoIframeContainer>
        <Stack
          sx={{ gap: 2, py: isMd ? 0 : 2, justifyContent: 'space-between' }}
        >
          <Stack sx={{ gap: 1 }}>
            <Text fontSize="large" variant="medium">
              {t('title')}
            </Text>
            <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
              {t('description')}
            </Text>
          </Stack>
          <Button
            size="small"
            variant={'secondary'}
            onClick={onHide}
            style={{ width: 'fit-content' }}
          >
            {t('hideAction')}
          </Button>
        </Stack>
      </VideoContainer>
    </motion.div>
  );
};
