import { MenuItem, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import {
  ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { draftExperienceQuery } from '../../../../Api/queries';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import routes from '../../../../Utils/routes';
import { EditExperienceDialog } from '../components/dialogs/edit-experience-dialog';
import { LoaderData, loaderName } from '../edit-experience';
import { getFormDataValue } from '../utils/form-helpers';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';

const PROPERTY_NAME = 'displayVariantId';

export default function DisplayPriceForm() {
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.displayPrice');
  const navigateWithParams = useNavigateWithParams();

  if (experience.price.variants.length <= 1) {
    navigateWithParams(routes.experience.details(experience.id).edit.index);
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="display-price"
    >
      <TextField
        select
        name={PROPERTY_NAME}
        fullWidth
        defaultValue={experience.price?.displayVariantId}
      >
        {experience.price?.variants?.map((variant) => (
          <MenuItem key={variant.id} value={variant.id}>
            {`${variant.name[activeLanguage]} (${variant.priceBreakdown.vatInclusivePriceCents / 100} ${t(variant.priceBreakdown.currency?.toUpperCase(), 'utils.currency')})`}
          </MenuItem>
        ))}
      </TextField>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const id = params.id;

    if (!id) {
      throw new Response('Invalid id', { status: 400 });
    }

    const experience = await client.fetchQuery(draftExperienceQuery(id));
    if (!experience) {
      throw new Response('Experience not found', { status: 404 });
    }

    try {
      const formData = await request.formData();
      const displayVariantId = getFormDataValue(formData.get(PROPERTY_NAME));

      const variant = experience.price?.variants?.find(
        (variant) => variant.id === displayVariantId
      );

      if (!variant) {
        throw new Response('Invalid variant id', { status: 400 });
      }

      const experienceToSave = {
        ...experience,
        price: {
          ...experience.price,
          displayVariantId: variant.id,
          displayPriceCents: variant.priceBreakdown.vatInclusivePriceCents,
        },
      };

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
