import { ListExperiencesQuery } from '@understory-io/experiences-types';

import * as api from '..';

/* ---- Experiences ----  */
// We leave out params from this query key, as we will use this one
// for invalidating the query, since all variations should be affected
export const experiencesQueryKey = ['experiences'];

export const experiencesQuery = (params?: ListExperiencesQuery) => ({
  queryKey: [...experiencesQueryKey, params],
  queryFn: () => api.getExperiences(params),
});

/* ---- Experience ----  */
const experienceQueryKey = (experienceId: string) => [
  'experience',
  experienceId,
];

export const experienceQuery = (experienceId: string) => ({
  queryKey: experienceQueryKey(experienceId),
  queryFn: () => api.getExperience(experienceId),
  enabled: !!experienceId,
});

/* ---- Draft Experience ----  */
const draftExperienceQueryKey = (experienceId: string) => [
  'experience',
  experienceId,
  'draft',
];

export const draftExperienceQuery = (experienceId: string) => ({
  queryKey: draftExperienceQueryKey(experienceId),
  queryFn: () => api.getDraftExperience(experienceId),
  enabled: !!experienceId,
});
