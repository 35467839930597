import { Box, Stack, Tab, Tabs } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { Page } from '../Page/Page';

export type TabConfig = {
  key: string;
  label: string;
  href: string;
};

export const TabLayout = ({
  title,
  tabs,
  shouldUseOldLayout = false,
  actions,
}: {
  title: string;
  tabs: TabConfig[];
  shouldUseOldLayout: boolean;
  actions?: React.ReactNode;
}) => {
  return (
    <Page maxWidth={920} pb={10}>
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Text fontSize="h5">{title}</Text>
            {actions}
          </Stack>
          {tabs.length > 0 && <TabBar tabs={tabs} />}
        </Stack>
        <Box
          data-intercom-target="tab-content"
          sx={
            shouldUseOldLayout
              ? {}
              : {
                  paddingX: { xs: 2, sm: 6 },
                  paddingY: { xs: 2, sm: 4, md: 6 },
                  borderRadius: 2,
                  backgroundColor: lightTheme.palette.contrast.surface2,
                }
          }
        >
          <Outlet />
        </Box>
      </Stack>
    </Page>
  );
};

const TabBar = ({ tabs }: { tabs: TabConfig[] }) => {
  const location = useLocation();

  const value = useMemo(
    () => tabs.findIndex((tab) => location.pathname.includes(tab.href)),
    [location.pathname, tabs]
  );

  const navigate = useNavigate();

  return (
    <Tabs
      value={value}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        mx: { xs: -2, sm: 0 },
        minHeight: 32,
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
          borderBottom: `2px solid ${lightTheme.palette.action.a300}`,
          borderRadius: 0,
        },
      }}
    >
      {tabs.map(({ key, href, label }, index) => (
        <Tab
          key={key}
          data-intercom-target={`tab-${key}`}
          value={index}
          label={label}
          onClick={() => navigate(href)}
          sx={{
            fontWeight: 600,
            minHeight: 32,
            minWidth: 100,
            '&.Mui-selected': {
              color: lightTheme.palette.action.a300,
            },
          }}
        />
      ))}
    </Tabs>
  );
};
