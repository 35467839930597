import { formatDuration, intervalToDuration } from 'date-fns';
import { FC } from 'react';

type SecondsToTimeStringProps = { seconds: number };
export const SecondsToTimeString: FC<SecondsToTimeStringProps> = ({
  seconds,
}) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });

  return (
    <>
      {formatDuration(duration, {
        format: ['days', 'hours', 'minutes'],
        delimiter: ' ',
      })}
    </>
  );
};
