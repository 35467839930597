import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as api from '../../Api';
import {
  CancellationStates,
  ICancelEventData,
} from '../../Modals/cancel_event/domain/types';
import { getEventsQueryKey, useGetEvents } from './useGetEvents';

export const useCancelEvent = (experienceId?: string) => {
  const queryClient = useQueryClient();

  const { events } = useGetEvents();
  const EventsQueryKey = getEventsQueryKey();

  const cancelEvent = useMutation({
    mutationFn: async ({
      payload,
      eventId,
    }: {
      payload: ICancelEventData;
      eventId: string;
    }) => {
      const response = await api.cancelEvent(payload, eventId);

      if (response.event.status === CancellationStates.FAILED_ENTIRELY) {
        throw new Error(CancellationStates.FAILED_ENTIRELY);
      } else if (response.event.status === CancellationStates.FAILED_PARTLY) {
        throw new Error(CancellationStates.FAILED_PARTLY);
      }

      return response;
    },

    onMutate: async ({ eventId }) => {
      return {
        experienceId: events.data?.find((el) => el.id === eventId)
          ?.experienceId,
        eventId: eventId,
      };
    },

    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: EventsQueryKey });
      await queryClient.invalidateQueries({
        queryKey: ['getBookingsForEvent', experienceId ?? context?.eventId],
      });
    },
  });

  return { cancelEvent };
};
