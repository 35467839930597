import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SeoSitemap } from './seo-sitemap';
import { SeoVisibility } from './seo-visibility';

export function SeoWidget() {
  const { t } = useTranslate('storefront.bookingflow.seo');
  return (
    <Stack gap={2}>
      <Text variant="medium" fontSize="small">
        {t('title')}
      </Text>
      <SeoVisibility />
      <SeoSitemap />
    </Stack>
  );
}
