import { capitalize, Stack } from '@mui/material';
import { addDays, eachDayOfInterval, startOfWeek } from 'date-fns';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Weekday, WeekdayStr } from 'rrule';
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday';

import { EventUpsertTimeFormRecurringWeeklyOption } from './EventUpsertTimeFormRecurringWeeklyOption';
import { EventUpsertRecurringCustomFormTypes } from './use_recurring_custom_form_validation';

export const EventUpsertTimeFormRecurringWeeklySelect = () => {
  const { t } = useTranslation();

  const { setValue, watch } =
    useFormContext<Pick<EventUpsertRecurringCustomFormTypes, 'byweekday'>>();

  const selectedWeekdays: Weekday[] =
    watch('byweekday', []) ??
    [].map((day: Weekday) => new Weekday(day.weekday));

  const isWeekdaySelected = (weekday: Weekday) =>
    selectedWeekdays?.some(
      (selectedDay) => selectedDay.weekday === weekday.weekday
    );

  const handleSelect = (weekdayStr: WeekdayStr) => {
    if (!ALL_WEEKDAYS.includes(weekdayStr)) {
      console.error(`Invalid weekday string: ${weekdayStr}`);
      return;
    }

    const weekday = Weekday.fromStr(weekdayStr);

    // Prevent deselecting weekday if only one is selected
    if (
      selectedWeekdays.length === 1 &&
      selectedWeekdays[0].weekday === weekday.weekday &&
      isWeekdaySelected(weekday)
    ) {
      return;
    }

    const updatedWeekdays = isWeekdaySelected(weekday)
      ? selectedWeekdays.filter((day) => day.weekday !== weekday.weekday)
      : [...selectedWeekdays, weekday];

    setValue('byweekday', updatedWeekdays, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const weekDays = useMemo(() => {
    const today = startOfWeek(new Date());
    return eachDayOfInterval({ start: today, end: addDays(today, 6) });
  }, []);

  return (
    <Stack direction="row" gap={1}>
      {weekDays.map((day) => {
        const { rrule, localized } = WEEKDAY_MAP[day.getDay()];
        const weekday = Weekday.fromStr(rrule);

        return (
          <EventUpsertTimeFormRecurringWeeklyOption
            key={rrule}
            label={formatWeekdayOption(localized, t)}
            isSelected={isWeekdaySelected(weekday)}
            onSelect={() => handleSelect(rrule)}
          />
        );
      })}
    </Stack>
  );
};

const formatWeekdayOption = (localized: string, t: TFunction) => {
  return capitalize(t(localized));
};

const WEEKDAY_MAP: ReadonlyArray<{ localized: string; rrule: WeekdayStr }> = [
  {
    localized: 'Sunday',
    rrule: 'SU',
  },
  {
    localized: 'Monday',
    rrule: 'MO',
  },
  {
    localized: 'Tuesday',
    rrule: 'TU',
  },
  {
    localized: 'Wednesday',
    rrule: 'WE',
  },
  {
    localized: 'Thursday',
    rrule: 'TH',
  },
  {
    localized: 'Friday',
    rrule: 'FR',
  },
  {
    localized: 'Saturday',
    rrule: 'SA',
  },
];
