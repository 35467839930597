import { Role, SavedRole } from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import { FieldValues } from 'react-hook-form';
import {
  ActionFunctionArgs,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import { createRole } from '../../../../../Api';
import { getRole, updateRole } from '../../../../../Api/Roles';
import { DialogWrapper } from '../../../../../features/connect/components/dialog-wrapper';
import { useRoles } from '../../../../../Hooks/data/useRoles';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import routes from '../../../../../Utils/routes';
import { RoleForm } from './form/role-form';
import { RoleFormSchema } from './form/use-role-form-validation';

type LoaderData = {
  role?: Role;
};

export const loader: LoaderFunction<LoaderData> = async ({ params }) => {
  if (params.id) {
    const role = await getRole(params.id);
    return { role };
  }
  return { role: undefined };
};

export async function action({ request, params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('settings.team.roles.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const { name, description, scopes, experienceAccessIds, guideAccess } =
      (await request.json()) as RoleFormSchema;

    const filteredScopes = scopes.filter(
      (scope) => scope !== undefined
    ) as string[];

    const role: Omit<SavedRole, 'roleId' | 'companyId'> = {
      name,
      description,
      scopes: filteredScopes,
      status: 'active',
      claims: [
        ...(guideAccess === 'own'
          ? [
              {
                key: 'event:assignedGuides',
                value: 'me',
              },
            ]
          : []),
        ...(experienceAccessIds.length > 0
          ? [
              {
                key: 'experience:id',
                value: experienceAccessIds.join(','),
              },
            ]
          : []),
      ],
    };

    if (params.id) {
      await updateRole(params.id, role);
    } else {
      await createRole(role);
    }

    toast.dismiss(loadingToastId);
    toast.success(t('settings.team.roles.toast.success'), {
      delay: 500,
    });

    return redirect(routes.settings.users.roles.index);
  } catch {
    toast.dismiss(loadingToastId);
    toast.error(t('settings.team.roles.toast.error'), { delay: 500 });
    return { success: false };
  }
}

export const RoleFormDialog = () => {
  const { t } = useTranslate('settings.team.roles');
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { id } = useParams();
  const { role } = useLoaderData() as LoaderData;

  const {
    roles: { refetch },
  } = useRoles();

  const mode = id ? 'edit' : 'create';

  const handleClose = () => {
    navigate(routes.settings.users.roles.index);
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action:
        mode === 'edit'
          ? routes.settings.users.roles.edit(id as string)
          : routes.settings.users.roles.create,
      encType: 'application/json',
    }).then(() => {
      refetch();
      ampli.rolesFlowFinished({
        experiences: data.experienceAccessIds,
        permissions: data.scopes,
        view_events_setting: data.guideAccess,
      });
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t(mode === 'create' ? 'addNewRole' : 'editRole')}
    >
      <RoleForm initialData={role} mode={mode} onSubmit={onSubmit} />
    </DialogWrapper>
  );
};
