import { useFormContext } from 'react-hook-form';

import { FullPageFormTitleInput } from '../../../../Components/full-page/form-components/full-page-form-title-input';
import { FullPageForm } from '../../../../Components/full-page/full-page-form';
import { FullPageFormSettings } from '../../../../Components/full-page/full-page-form-settings';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { UpdateResourceTypeCapacitySetting } from './capacity/update-resource-type-capacity-setting';
import { UpdateResourceTypeRecoverTimeMoreOption } from './recover-time/update-resource-type-recover-time-more-option';
import { UpdateResourceTypeRecoverTimeSetting } from './recover-time/update-resource-type-recover-time-setting';
import { UpdateResourceTypeInputs } from './update-resource-type-inputs';

export const UpdateResourceTypeForm = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<UpdateResourceTypeInputs>();

  const recoverTimeInSeconds = watch('recoverTimeInSeconds');

  return (
    <FullPageForm>
      <FullPageFormTitleInput
        sx={{ mt: 5 }}
        {...register('name')}
        placeholder={t('createNew.placeholder.name')}
        error={!!errors.name}
      />
      <FullPageFormSettings
        settings={[
          {
            Setting: <UpdateResourceTypeCapacitySetting />,
          },
          {
            Setting: <UpdateResourceTypeRecoverTimeSetting />,
            MoreOption: <UpdateResourceTypeRecoverTimeMoreOption />,
            showAsMoreOption: !recoverTimeInSeconds,
          },
        ]}
      />
    </FullPageForm>
  );
};
