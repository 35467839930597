import { useQuery } from '@tanstack/react-query';

import { eventQuery, eventQueryKey } from '../../Api/queries';

export const getEventQueryKey = eventQueryKey;

export const useGetEvent = (id: string) => {
  const event = useQuery(eventQuery(id));

  return { event };
};
