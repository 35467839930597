import * as yup from 'yup';

import { CountryVatRates } from '../Company/domain/types';
import { countryCodesWithStates } from './Countries';

export const shareCompanyDetailsSchema = (
  countryVatRates: CountryVatRates | undefined
) =>
  yup.object().shape({
    vatNumber: yup.string().when('isVatExempt', {
      is: (isVatExempt: boolean) => !isVatExempt,
      then: (schema) =>
        schema.test({
          message: 'utils.validationErrors.invalidVatNumberFormat',
          test: (value, context) => {
            const vatRates = countryVatRates?.[context.parent.countryCode];
            const pattern = vatRates?.vatNumberRules[0].pattern;

            if (pattern === undefined) {
              return !!value?.length;
            }

            const result = value?.match(new RegExp(pattern));
            return result !== null;
          },
        }),
      otherwise: (schema) => schema.nullable(),
    }),
    isVatExempt: yup
      .boolean()
      .default(false)
      .required('utils.validationErrors.required'),
    companyName: yup.string().required('utils.validationErrors.required'),
    address: yup.string().required('utils.validationErrors.required'),
    zipCode: yup.string().required('utils.validationErrors.required'),
    city: yup.string().required('utils.validationErrors.required'),
    countryCode: yup
      .string()
      .length(2)
      .required('utils.validationErrors.required'),
    state: yup.string().when('countryCode', {
      is: (countryCode: string) => countryCodesWithStates.includes(countryCode),
      then: (schema) => schema.required('utils.validationErrors.required'),
      otherwise: (schema) => schema.nullable(),
    }),
  });

export type ShareCompanyDetailsInputs = yup.InferType<
  ReturnType<typeof shareCompanyDetailsSchema>
>;
