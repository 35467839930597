import { Box, Grid, Stack, styled } from '@mui/material';
import { motion } from 'framer-motion';

import useResponsive from '../../../../Hooks/layout/useResponsive';

export const SignupLogos = () => {
  const { isMd } = useResponsive();

  if (isMd) {
    return (
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {logos.slice(0, 6).map((logo, index) => (
          <Grid
            item
            xs={4}
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LogoImage
              src={logo.src}
              alt="Customer logo"
              style={{
                width: logo.width,
                height: logo.height,
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <CarouselContainer>
      <LogoTrack>
        {[...logos, ...logos].map((logo, index) => (
          <motion.div
            key={index}
            style={{
              position: 'relative',
              minWidth: '200px',
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            animate={{
              x: [0, -200 * logos.length],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: 'linear',
              repeatType: 'loop',
            }}
          >
            <LogoImage
              src={logo.src}
              alt="Customer logo"
              style={{
                width: logo.width,
                height: logo.height,
              }}
            />
          </motion.div>
        ))}
      </LogoTrack>
    </CarouselContainer>
  );
};

const CarouselContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 2%,
    rgba(255, 255, 255, 1) 98%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const LogoTrack = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoImage = styled('img')`
  opacity: 0.6;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1;
  }
`;

const logos = [
  {
    src: '/customers/cph-cooking-class.svg',
    width: 109,
    height: 78,
  },
  {
    src: '/customers/craft.svg',
    width: 109,
    height: 20,
  },
  {
    src: '/customers/letsclay.svg',
    width: 118,
    height: 84,
  },
  {
    src: '/customers/raw-arctic.svg',
    width: 67,
    height: 63,
  },
  {
    src: '/customers/sightseeing-ride.svg',
    width: 129,
    height: 80,
  },
  {
    src: '/customers/solab.svg',
    width: 86,
    height: 61,
  },
  {
    src: '/customers/startup-aarhus.svg',
    width: 135,
    height: 19,
  },
  {
    src: '/customers/stauning-whisky.svg',
    width: 127,
    height: 32,
  },
];
