import {
  Box,
  BoxProps,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ampli } from '../../Ampli';
import { getTranslation } from '../../Api';
import { useTranslate } from '../../Hooks/useTranslate';
import {
  StorefrontLanguage,
  supportedStorefrontLanguages,
} from '../../i18n/config';
import { ErrorLabel } from '../../Pages/SyiPage/ErrorLabel';
import { LanguageTabItem, LanguageTabs } from '../LanguageTabs/CustomTabs';
import { RichEditor } from '../RichEditor/RichEditor';
import { Tip } from '../Tip/Tip';
import { getDefaultFieldValue, useFieldStateReducer } from './reducer';

type Language = {
  locale: StorefrontLanguage;
  flagSrc: string;
};

interface TranslatableGroupProps {
  id: string;
  /**
   * Renders a title above the input fields
   */
  title?: string | ReactNode;
  /**
   * Renders a notched label
   */
  label?: string | ReactNode;
  input: {
    type: 'input' | 'editor';
    placeholder?: string;
    maxLength?: number;
    props?: TextFieldProps;
  };
  tip?: string;
  langs: Language[];
  values?: (string | undefined)[];
  error?: string;
  clearError?: (key: string) => void;
  disabled?: boolean;
  disableTranslation?: boolean;
  renderControls?: {
    position: 'above' | 'below';
    render: (
      lang: string,
      onUpdate: (value: string, done: boolean) => void
    ) => ReactNode;
  }[];
  onUpdate?: (value: string, language: string) => void;
  onTranslationStarted?: () => void;
  onTranslationEnded?: () => void;
  defaultValue?: {
    [language: string]: string;
  };
}

export function TranslatableGroup({
  id,
  title,
  label,
  error,
  clearError,
  disabled = false,
  tip,
  input,
  langs: languages,
  disableTranslation,
  renderControls,
  onUpdate,
  onTranslationStarted,
  onTranslationEnded,
  defaultValue,
  ...props
}: Omit<BoxProps, 'title' | 'defaultValue'> & TranslatableGroupProps) {
  const { i18n } = useTranslation();
  const { t } = useTranslate('utils.generic');

  const form = useFormContext();

  // useReducer is used to manage state
  // This makes it a lot easier to handle all the different connected _things_ that happens in the UI
  const {
    state,
    changeLanguage,
    changeValue,
    startTranslating,
    endTranslating,
    setAutoTranslation,
  } = useFieldStateReducer({
    translatableLanguages: languages.map((x) => x.locale),
    userLanguage: i18n.language,
    maxLength: input.maxLength,
    initialValues: () => {
      const formValues = defaultValue ?? form.getValues(id) ?? {};
      return languages.reduce((previous, current) => {
        return {
          ...previous,
          [current.locale]: formValues[current.locale],
        };
      }, {});
    },
  });

  // Sync state to form on mount.
  // This uses the initial values
  // and is useful when editing.
  useEffect(() => {
    synchronizeDefaultFormState(languages, state.values);
  }, []);

  languages = sortCurrentLanguageFirst(
    languages,
    state.primaryLanguage,
    supportedStorefrontLanguages
  );

  async function translateFields() {
    // Only start translating if translations are enabled and the primary language was changed
    if (
      disableTranslation ||
      !state.didPrimaryLanguageChange ||
      state.values[state.primaryLanguage].text === ''
    ) {
      return;
    }

    // Pick only the languages that aren't empty and have not been touched by the user
    const languagesThatShouldBeTranslated = languages.filter((l) => {
      // Only translate FROM primary language, not to.
      if (l.locale === state.primaryLanguage) {
        return false;
      }

      const currentValue = state.values[l.locale];

      // If a value hasn't been set yet we can translate to this language
      if (!currentValue || currentValue.text === '') {
        return true;
      }

      /**
       * If the current value is different from the last auto translation we don't translate
       * This can happen when:
       * - The user has manually changed the value for a language
       * - The component has been unmounted and mounted again with initial state from the form (ie. last auto translation is undefined and currentValue.text is exists)
       *
       * In these cases we don't want to overwrite the user's changes
       */
      if (currentValue.lastAutoTranslation !== currentValue.text) {
        return false;
      }

      // Finally, if the field hasn't been touched by the user, we can translate to this language
      return true;
    });

    try {
      onTranslationStarted?.();
      startTranslating();
      await Promise.allSettled(
        languagesThatShouldBeTranslated.map((l) =>
          getTranslation({
            text: state.values[state.primaryLanguage].text,
            targetLanguageCode: l.locale,
            sourceLanguageCode: state.primaryLanguage,
          })
            .then(({ text }) => {
              // Update the value in the reducer
              setAutoTranslation(l.locale, text);
              synchronizeFormState(l.locale, text);
            })
            .catch((error) => {
              captureException(error, (scope) => {
                scope.setTransactionName(`Failed to translate text field`);
                return scope;
              });
            })
        )
      );
    } finally {
      endTranslating();
      onTranslationEnded?.();
    }
  }

  function synchronizeDefaultFormState(
    languages: Language[],
    values: { [key: string]: { text: string } }
  ) {
    form.setValue(
      id,
      languages.reduce(
        (translations, language) => ({
          ...translations,
          [language.locale]: values[language.locale].text ?? '',
        }),
        {}
      ),
      {
        shouldDirty: false,
        shouldTouch: false,
      }
    );
  }

  function synchronizeFormState(language: string, value: string) {
    form.setValue(
      id,
      {
        ...form.getValues(id),
        [language]: value,
      },
      {
        shouldDirty: true,
        shouldTouch: true,
      }
    );
  }

  return (
    <Box {...props}>
      <Stack spacing={2}>
        {title && (
          <Box
            display={'flex'}
            position={'relative'}
            justifyContent={'space-between'}
            alignItems="center"
          >
            <Typography variant={'h5'}>{title}</Typography>
          </Box>
        )}
        {tip && <Tip label={tip} />}
        {renderControls
          ?.filter((c) => c.position === 'above')
          .map((c) => {
            return c.render(state.activeLanguage, (value, done) => {
              changeValue(state.activeLanguage, value);

              if (done) {
                synchronizeFormState(state.activeLanguage, value);
              }
            });
          })}
        <Box>
          {languages.map((language, i) => {
            const value =
              state.values[language.locale] ??
              getDefaultFieldValue(input.maxLength);

            return (
              <div
                key={language.locale}
                style={{
                  display:
                    state.activeLanguage === language.locale ? 'block' : 'none',
                }}
                data-intercom-target={`translatable-group-${id}-${i}`}
              >
                {input.type === 'input' ? (
                  <TextField
                    fullWidth
                    placeholder={
                      state.isTranslating ? t('translating') : input.placeholder
                    }
                    value={value.text}
                    disabled={disabled || state.isTranslating}
                    label={label}
                    inputProps={{ maxLength: input.maxLength }}
                    onChange={(e) => {
                      changeValue(language.locale, e.target.value);

                      onUpdate?.(e.target.value, language.locale);
                      // Synchronize form state from reducer
                      clearError?.(id);
                      synchronizeFormState(language.locale, e.target.value);
                    }}
                    onBlur={() => {
                      // Trigger translation of other fields than primary
                      translateFields();
                    }}
                    {...input.props}
                    InputProps={
                      state.isTranslating
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CircularProgress
                                  size={16}
                                  sx={{
                                    color: lightTheme.palette.neutral.n500,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: input.props?.InputProps?.endAdornment,
                          }
                    }
                  />
                ) : (
                  <RichEditor
                    placeholder={
                      state.isTranslating ? t('translating') : input.placeholder
                    }
                    value={value.text}
                    onChange={(nextValue: string) => {
                      if (nextValue !== value.text) {
                        ampli.editorChangeValue({
                          editorKey: language.locale,
                        });

                        changeValue(language.locale, nextValue);
                        onUpdate?.(nextValue, language.locale);
                      }
                    }}
                    onBlur={() => {
                      // Synchronize form state from reducer
                      clearError?.(id);
                      synchronizeFormState(language.locale, value.text);
                      // Trigger translation of other fields than primary
                      translateFields();
                    }}
                  />
                )}
              </div>
            );
          })}
          <ErrorLabel mt={1} label={error} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
              sx={(theme) => ({
                paddingY: theme.spacing(1),
                paddingRight: theme.spacing(1),
              })}
            >
              {!!languages?.length && (
                <div>
                  <LanguageTabs
                    onChange={(_, value) => {
                      changeLanguage(value);

                      // Starting translations here is only to solve for the generation tool.
                      // When the AI result comes back, the text field is not focused or blurred.
                      translateFields();
                      clearError?.(id);
                    }}
                    value={state.activeLanguage}
                  >
                    {languages.map((l) => (
                      <LanguageTabItem
                        key={l.locale}
                        value={l.locale}
                        label={
                          <>
                            {
                              <img
                                src={l.flagSrc}
                                alt={l.locale}
                                width={20}
                                height={20}
                              />
                            }
                            {state.values[l.locale]?.seen ? null : (
                              <NotSeenIndicator />
                            )}
                          </>
                        }
                      />
                    ))}
                  </LanguageTabs>
                </div>
              )}
            </Grid>
            {state.values[state.activeLanguage]?.lengthHelperText && (
              <Grid item>
                <Text fontSize="xsmall">
                  {state.values[state.activeLanguage]?.lengthHelperText}
                </Text>
              </Grid>
            )}
            {renderControls && (
              <Grid item xs={12} flexGrow={1}>
                {renderControls
                  ?.filter((c) => c.position === 'below')
                  .map((c) => {
                    return c.render(state.activeLanguage, (value, done) => {
                      changeValue(state.activeLanguage, value);

                      if (done) {
                        synchronizeFormState(state.activeLanguage, value);
                      }
                    });
                  })}
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
}

const NotSeenIndicator = styled('span')({
  position: 'absolute',
  top: 0,
  right: 8,
  display: 'block',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: lightTheme.palette.warning.w300,
});

function sortCurrentLanguageFirst(
  languages: Language[],
  firstLanguage: string,
  supportedLanguagesKeys: readonly StorefrontLanguage[]
) {
  return languages.sort((a, b) => {
    if (a.locale === firstLanguage) return -1;
    if (b.locale === firstLanguage) return 1;

    const aIndex = supportedLanguagesKeys.indexOf(a.locale);
    const bIndex = supportedLanguagesKeys.indexOf(b.locale);
    return aIndex > bIndex ? 1 : -1;
  });
}
