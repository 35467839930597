import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import Key from '../../../../../../Components/icons/key';

export const NoKeys = ({ label }: { label: string }) => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        height: '125px',
        backgroundColor: lightTheme.palette.contrast.surface2,
      }}
    >
      <Box
        sx={{
          color: lightTheme.palette.neutral.n300,
        }}
      >
        <Key size={32} />
      </Box>
      <Text
        fontSize="small"
        variant="normal"
        color={lightTheme.palette.neutral.n300}
      >
        {label}
      </Text>
    </Stack>
  );
};
