import styled from '@emotion/styled';
import { AddOutlined } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Location } from '@understory-io/utils-types';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, Link, useLocation } from 'react-router';

import { EVENT_LIMIT } from '../../../../Api/queries';
import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { IExperience } from '../../../../Hooks/useExperience';
import { TUser } from '../../../../Hooks/useUsers';
import { TEvent } from '../../../../Utils/eventHelpers';
import { isSharedExperience } from '../../utils/is-shared-experience';
import { ExperienceDetailsCard } from '../experience-details-card';
import {
  ExperienceEventTableNotCreatedSkeleton,
  ExperienceEventTableSkeleton,
} from '../skeletons/experience-details-events-skeletons';
import { EventList } from './event-list';
import { EventListFilter } from './event-list-filter';
import { EventListForm, EventListFormInput } from './event-list-form';
import { EventListPagination } from './event-list-pagination';

type EventListCardProps = {
  eventFilters: EventListFormInput;
  experience: IExperience;
  eventsPromise: Promise<{ events: TEvent[]; totalCount: number }>;
  usersPromise: Promise<TUser[]>;
  locationsPromise: Promise<Location[]>;
  hasPublishedExperience: boolean;
};

export const EventListCard = ({
  eventFilters,
  experience,
  eventsPromise,
  usersPromise,
  locationsPromise,
  hasPublishedExperience,
}: EventListCardProps) => {
  const { t } = useTranslation();
  const { isSm } = useResponsive();
  const location = useLocation();

  const returnURL = location.pathname + location.search;

  return (
    <EventListForm eventFilters={eventFilters}>
      <ExperienceDetailsCard>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: { xs: 1, sm: 'unset' }, flexWrap: 'wrap' }}
          gap={1}
        >
          <Text variant="medium" fontSize="small">
            {t('experience.details.eventList.title')}
          </Text>
          {hasPublishedExperience && (
            <>
              <EventListFilter locationsPromise={locationsPromise} />
              {isSm && !isSharedExperience(experience) && (
                <ShowForScope scopes={['event.write']}>
                  <StyledButton
                    to="/event/create"
                    state={{
                      experienceId: experience.id,
                      returnUrl: returnURL,
                    }}
                  >
                    <AddOutlined fontSize="small" />
                  </StyledButton>
                </ShowForScope>
              )}
            </>
          )}
        </Stack>
        <Divider sx={{ display: { sm: 'none' } }} />
        {hasPublishedExperience ? (
          <Suspense fallback={<ExperienceEventTableSkeleton />}>
            <Await resolve={eventsPromise}>
              {({ events, totalCount }) => {
                return (
                  <>
                    <EventList
                      events={events}
                      usersPromise={usersPromise}
                      experience={experience}
                    />
                    {events.length > 0 && (
                      <EventListPagination
                        currentPage={eventFilters.page}
                        maxPage={Math.max(
                          Math.ceil(totalCount / EVENT_LIMIT),
                          1
                        )}
                      />
                    )}
                  </>
                );
              }}
            </Await>
          </Suspense>
        ) : (
          <ExperienceEventTableNotCreatedSkeleton />
        )}
      </ExperienceDetailsCard>
    </EventListForm>
  );
};

const StyledButton = styled(Link)({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 8,
  borderColor: lightTheme.palette.neutral.n200,
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
});
