import { Box, Card, Stack, Typography } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { StorefrontFeatures } from '@understory-io/utils-types';
import { Children, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useTranslate } from '../../Hooks/useTranslate';
import { FeatureSettingContextProvider } from '../../Sections/StorefrontSections/FeaturesSection/context/feature_setting_context';
import { FeatureEnabledSwitch } from '../../Sections/StorefrontSections/FeaturesSection/inputs/FeatureEnabledSwitch';
import { CopyLink } from '../CopyLink/CopyLink';

export type TFeatureItemProps = PropsWithChildren<{
  link?: string;
  feature: keyof StorefrontFeatures;
  disabled?: boolean;
  onToggle?: (enabled: boolean) => void;
}>;

const FeatureItem = ({
  feature,
  children,
  link,
  onToggle,
  disabled,
}: TFeatureItemProps) => {
  const { t } = useTranslate('settings.features');
  const location = useLocation();
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);

      const highlight = id === feature;
      setIsHighlighted(highlight);

      if (highlight) {
        document.getElementById(feature)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [location.hash, feature]);

  return (
    <FeatureSettingContextProvider feature={feature}>
      <Box
        id={feature}
        component={Card}
        pt={4}
        pb={4}
        pr={4}
        display={'inline-flex'}
        alignItems={'flex-start'}
        sx={{
          outline: isHighlighted
            ? `2px solid ${lightTheme.palette.success.s300}`
            : 'none',
        }}
      >
        <Box ml={2} width={'100%'}>
          <Stack maxWidth={480} gap={4}>
            <Typography variant={'h5'} fontSize={'1.111em'}>
              {t(`${feature}.name`)}
            </Typography>
            <Stack flexDirection={'row'}>
              <FeatureEnabledSwitch
                disabled={disabled}
                disabledReason={t(`${feature}.disabledReason`)}
                onToggle={onToggle}
              />
              <Stack>
                <Typography variant={'body1'}>
                  {t(`${feature}.descriptionTitle`)}
                </Typography>
                <Typography variant={'body2'}>
                  {t(`${feature}.descriptionBody`)}
                </Typography>
              </Stack>
            </Stack>
            {link && <CopyLink href={link} label={t(`${feature}.linkText`)} />}
            {Children.count(children) > 0 && (
              <Stack spacing={3}>{children}</Stack>
            )}
          </Stack>
        </Box>
      </Box>
    </FeatureSettingContextProvider>
  );
};

export default FeatureItem;
