import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { useMemo } from 'react';
import { ActionFunctionArgs, Form } from 'react-router';

import { updateCompanyProfile } from '../../../../../Api';
import { companyProfileQuery } from '../../../../../Api/queries';
import { convertFormDataValue } from '../../../../../features/experiences/edit/utils/form-helpers';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';

const FORM_FIELDS = {
  INDEXING_RULE: 'indexingRule',
} as const;
export const seoAction =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const [companyProfile] = await Promise.all([
      client.fetchQuery(companyProfileQuery()),
    ]);

    const formData = await request.formData();

    const indexingRule = convertFormDataValue(
      formData.get(FORM_FIELDS.INDEXING_RULE)
    ) as 'all' | 'noindex';

    const updatedCompanyProfile = {
      ...companyProfile,
      seo: {
        ...companyProfile.seo,
        indexingRule: indexingRule,
      },
    };

    try {
      await updateCompanyProfile(updatedCompanyProfile);
      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });

      return { updatedCompanyProfile };
    } catch (error) {
      captureException(error);
    }
  };

export function SeoVisibility() {
  const { t } = useTranslate('storefront.bookingflow.seo.indexingRules');
  const {
    company: { data: company },
  } = useProfile();

  // FOLLOWING THE STANDARDS FOR VALID INDEXING AND SERVING RULES
  // https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#directives
  const SEO_VISIBILITY_OPTIONS = useMemo(() => {
    return [
      {
        value: 'all',
        label: t('all.label'),
        description: t('all.description'),
      },
      {
        value: 'noindex',
        label: t('noindex.label'),
        description: t('noindex.description'),
      },
    ];
  }, [t]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.form?.requestSubmit();
  };

  return (
    <Form method="post">
      <FormControl>
        <RadioGroup
          key={company?.seo?.indexingRule}
          onChange={handleChange}
          name={FORM_FIELDS.INDEXING_RULE}
          sx={{ gap: 1 }}
          defaultValue={company?.seo?.indexingRule ?? 'all'}
        >
          {SEO_VISIBILITY_OPTIONS.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={
                <Stack>
                  <Text variant="medium" fontSize="xsmall">
                    {option.label}
                  </Text>
                  <Text variant="normal" fontSize="xsmall">
                    {option.description}
                  </Text>
                </Stack>
              }
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Form>
  );
}
