import { Image as ImageType } from '@understory-io/utils-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Image } from '../../../Components/media/image';
import { ImageUpload } from '../../../Components/media-upload/image-upload';
import { ProfileFormData } from './profile-settings';

const COLLECTION = 'me';
const TYPE = 'profile';

export const ProfilePicture = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();

  const { control } = useFormContext<ProfileFormData>();
  const { field: avatarUrl } = useController({
    name: 'pictures.profile.url',
    control,
  });

  const handleUpload = (image: ImageType) => {
    avatarUrl.onChange(image.url);
  };

  const handleDelete = () => {
    avatarUrl.onChange(null);
  };

  return (
    <ImageUpload
      id={userId}
      collection={COLLECTION}
      type={TYPE}
      onUploaded={handleUpload}
      variant="circle"
      label={t('settings.profile.uploadImage.label')}
      handleDelete={handleDelete}
    >
      {avatarUrl.value && (
        <Image
          src={avatarUrl.value}
          sx={{
            aspectRatio: '1 / 1',
            borderRadius: '100%',
          }}
        />
      )}
    </ImageUpload>
  );
};
