import styled from '@emotion/styled';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import {
  Experience,
  ExperienceSortOption,
} from '@understory-io/experiences-types';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContextMenu } from '../../../Components/context-menu/context-menu';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  trackStorefrontCustomizeOrderDropdownOpened,
  trackStorefrontCustomizeOrderDropdownSelected,
} from '../../../tracking/storefront/customize-events';
import { getOrder, getSortOrder } from '../../../Utils/experience-order';
import { NoExperienceTitle } from '../../experiences/overview/experience-item';
import { CustomizeStorefrontFormData } from './customize-storefront';

export const ExperienceOrder = ({
  experiences,
}: {
  experiences: Experience[];
}) => {
  const { t } = useTranslation();
  const { resetField, control } = useFormContext<CustomizeStorefrontFormData>();

  const initialSortOrder = useMemo(
    () => getOrder(getSortOrder(experiences)),
    [experiences]
  );

  const {
    field: { value: currentOrder, onChange: updateOrder },
  } = useController<CustomizeStorefrontFormData, 'sortOrder'>({
    name: 'sortOrder',
    control,
    defaultValue: initialSortOrder,
  });

  const {
    field: { value: sortBy, onChange: updateSortBy },
  } = useController<CustomizeStorefrontFormData, 'sortExperiencesBy'>({
    name: 'sortExperiencesBy',
    defaultValue: 'alphanumeric',
    control,
  });

  const handleMoveUp = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index - 1];
      temp[index - 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveDown = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index + 1];
      temp[index + 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([experienceToMove, ...temp]);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToBottom = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([...temp, experienceToMove]);
    },
    [currentOrder, updateOrder]
  );

  const experienceMap = useMemo(
    () =>
      experiences.reduce(
        (experiences, experience) => {
          return {
            ...experiences,
            [experience.id]: experience,
          };
        },
        {} as Record<string, Experience>
      ),
    [experiences]
  );

  return (
    <Stack sx={{ gap: 3 }}>
      <Stack sx={{ gap: 1 }}>
        <Text variant="medium" fontSize="small">
          {t('storefront.customize.order.title')}
        </Text>
        <Text fontSize="small">
          {t('storefront.customize.order.description')}
        </Text>
      </Stack>
      <Stack sx={{ gap: 2 }}>
        <SelectSortBy
          value={sortBy ?? 'alphanumeric'}
          onChange={(selected) => {
            updateSortBy(selected);
            resetField('sortOrder', { defaultValue: initialSortOrder });
          }}
        />
        {sortBy === 'sortOrder' && (
          <Stack gap={1} flexGrow={1}>
            {currentOrder.map((experienceId, index) => {
              const experience = experienceMap[experienceId];

              if (!experience) return null;

              return (
                <ExperienceItem
                  key={experience.id}
                  experience={experience}
                  handleMoveUp={() => handleMoveUp(index)}
                  handleMoveDown={() => handleMoveDown(index)}
                  handleMoveToTop={() => handleMoveToTop(index)}
                  handleMoveToBottom={() => handleMoveToBottom(index)}
                  isMoveUpDisabled={index === 0}
                  isMoveDownDisabled={index >= currentOrder.length - 1}
                />
              );
            })}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export const SelectSortBy = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (selectedOption: ExperienceSortOption) => void;
}) => {
  const { t } = useTranslate('experience.order');

  const label = t('label');

  return (
    <FormControl fullWidth size="small">
      <InputLabel shrink id="sortExperiencesBy">
        {label}
      </InputLabel>
      <Select
        size="small"
        labelId="sortExperiencesBy"
        input={<OutlinedInput notched label={label} />}
        MenuProps={{
          autoFocus: false,
        }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value as ExperienceSortOption);
          trackStorefrontCustomizeOrderDropdownSelected(e.target.value);
        }}
        onOpen={trackStorefrontCustomizeOrderDropdownOpened}
      >
        <MenuItem value="alphanumeric">
          {t('alphanumeric', 'experience.sort')}
        </MenuItem>
        <MenuItem value="createdAtDesc">
          {t('createdAtDesc', 'experience.sort')}
        </MenuItem>
        <MenuItem value="createdAtAsc">
          {t('createdAtAsc', 'experience.sort')}
        </MenuItem>
        <MenuItem value="sortOrder">{t('sortOrder')}</MenuItem>
      </Select>
    </FormControl>
  );
};

const ExperienceItem = ({
  experience,
  isMoveUpDisabled,
  isMoveDownDisabled,
  handleMoveUp,
  handleMoveDown,
  handleMoveToTop,
  handleMoveToBottom,
}: {
  experience: Experience;
  isMoveUpDisabled: boolean;
  isMoveDownDisabled: boolean;
  handleMoveUp: () => void;
  handleMoveDown: () => void;
  handleMoveToTop: () => void;
  handleMoveToBottom: () => void;
}) => {
  const { t } = useTranslate('storefront.experiences');
  const [isOpen, setIsOpen] = useState(false);

  const { getLocalizedString } = useLocale();

  return (
    <Stack
      key={experience.id}
      component={Card}
      alignItems="center"
      direction="row"
      padding={1}
      justifyContent="space-between"
      gap={3}
      boxShadow="none"
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius="8px"
    >
      <Stack alignItems="center" direction="row" gap={3}>
        <Stack flexShrink={0} gap={0.5}>
          <StyledButton
            type="button"
            size="medium"
            variant="secondary"
            onClick={handleMoveUp}
            disabled={isMoveUpDisabled}
          >
            <KeyboardArrowUpRounded />
          </StyledButton>
          <StyledButton
            type="button"
            size="medium"
            variant="secondary"
            onClick={handleMoveDown}
            disabled={isMoveDownDisabled}
          >
            <KeyboardArrowDownRounded />
          </StyledButton>
        </Stack>
        <ClampedText fontSize="xsmall">
          {getLocalizedString(experience.headline) || <NoExperienceTitle />}
        </ClampedText>
      </Stack>

      <ContextMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        options={[
          {
            label: t('moveToTop'),
            onClick: handleMoveToTop,
            disabled: isMoveUpDisabled,
          },
          {
            label: t('moveToBottom'),
            onClick: handleMoveToBottom,
            disabled: isMoveDownDisabled,
          },
        ]}
      />
    </Stack>
  );
};

const StyledButton = styled(Button)({
  height: '24px',
  borderRadius: '4px',
  aspectRatio: 1,
  padding: 0,
  span: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ClampedText = styled(Text)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
