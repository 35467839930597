import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Cutoff } from '@understory-io/experiences-types';
import { Experience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { useCallback, useState } from 'react';
import {
  ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { draftExperienceQuery } from '../../../../../Api/queries';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import { RelativeToType } from '../../../schemas/cutoffSchema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import { loaderName } from '../../edit-experience';
import { convertFormDataValue } from '../../utils/form-helpers';
import { useTimeSpan } from './use-time-span';

const FORM_FIELDS = {
  RELATIVE_TO: 'relativeTo',
  TIME_IN_SECONDS: 'timeInSeconds',
} as const;

const RELATIVE_TO_OPTIONS = [
  'eventStart',
  'eventEnd',
  'beforeEventStart',
  'afterEventStart',
] as const;

export default function CutoffTimeForm() {
  const actionData = useActionData() as ActionData;

  const { t } = useTranslate('experience.edit.dialog.cutoff');

  const { experience } = useRouteLoaderData(loaderName) as {
    experience: Experience;
  };

  const { timeSpan, totalSeconds, updateTimeSpan, resetTimeSpan } = useTimeSpan(
    experience.cutoff?.timeInSeconds ?? experience.cutoffTimeSeconds ?? 0
  );

  const [relativeToType, setRelativeToType] = useState(
    experience.cutoff?.relativeTo ?? 'beforeEventStart'
  );

  const [relativeToOptionKey, setRelativeToOptionKey] = useState(() => {
    const timeInSeconds =
      experience.cutoff?.timeInSeconds ?? experience.cutoffTimeSeconds ?? 0;
    const relativeTo = experience.cutoff?.relativeTo ?? 'beforeEventStart';

    if (timeInSeconds === 0) {
      return relativeTo === 'beforeEventStart'
        ? 'eventStart'
        : relativeTo === 'beforeEventEnd'
          ? 'eventEnd'
          : relativeTo;
    }
    return relativeTo;
  });

  const handleRelativeToChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setRelativeToOptionKey(newValue);

      if (newValue === 'eventStart') {
        resetTimeSpan();
        setRelativeToType('beforeEventStart');
        return;
      }

      if (newValue === 'eventEnd') {
        resetTimeSpan();
        setRelativeToType('beforeEventEnd');
        return;
      }

      if (newValue === 'afterEventStart') {
        updateTimeSpan('days', '0');
      }

      setRelativeToType(newValue as RelativeToType);
    },
    [resetTimeSpan, updateTimeSpan]
  );

  const showTimeFields = !['eventStart', 'eventEnd'].includes(
    relativeToOptionKey
  );
  const showDaysField = relativeToOptionKey !== 'afterEventStart';

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="cutoff-time"
    >
      <Stack sx={{ gap: 2, alignItems: 'flex-start' }}>
        <input
          name={FORM_FIELDS.TIME_IN_SECONDS}
          type="number"
          value={totalSeconds}
          hidden
        />
        <input
          name={FORM_FIELDS.RELATIVE_TO}
          type="text"
          value={relativeToType}
          hidden
        />

        <RadioGroup
          onChange={handleRelativeToChange}
          defaultValue={experience.cutoff?.relativeTo ?? 'eventStart'}
          value={relativeToOptionKey}
        >
          {RELATIVE_TO_OPTIONS.map((type) => (
            <FormControlLabel
              key={type}
              label={<Text fontSize="small">{t(`options.${type}`)}</Text>}
              value={type}
              control={<Radio disableRipple size="small" />}
            />
          ))}
        </RadioGroup>

        {showTimeFields && (
          <Stack flexDirection="row" gap={2} mt={2}>
            {showDaysField && (
              <TextField
                label={t('days', 'utils.generic')}
                type="number"
                value={timeSpan.days.toString()}
                fullWidth
                onChange={(e) => updateTimeSpan('days', e.target.value)}
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
            <TextField
              label={t('hours', 'utils.generic')}
              type="number"
              value={timeSpan.hours.toString()}
              fullWidth
              onChange={(e) => updateTimeSpan('hours', e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 23 } }}
            />
            <TextField
              label={t('minutes', 'utils.generic')}
              type="number"
              value={timeSpan.minutes.toString()}
              fullWidth
              onChange={(e) => updateTimeSpan('minutes', e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 59 } }}
            />
          </Stack>
        )}
      </Stack>
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e400} variant="normal">
          {t(actionData.error)}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const id = params.id;

    if (!id) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      // Validate input
      const formData = await request.formData();
      const relativeTo = convertFormDataValue(
        formData.get(FORM_FIELDS.RELATIVE_TO)
      );
      const timeInSeconds = convertFormDataValue(
        formData.get(FORM_FIELDS.TIME_IN_SECONDS)
      );

      if (
        typeof relativeTo !== 'string' ||
        typeof timeInSeconds !== 'number' ||
        timeInSeconds < 0
      ) {
        throw new Error('Invalid input');
      }

      // Update experience
      const experience = await client.fetchQuery(draftExperienceQuery(id));

      const experienceToSave = {
        ...experience,
        cutoff: {
          relativeTo: relativeTo as Cutoff['relativeTo'],
          timeInSeconds,
        },
        cutoffTimeSeconds: timeInSeconds as number,
      };

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        return {
          error: error.message,
        };
      }

      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
