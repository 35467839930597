import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { useIntercom } from 'react-use-intercom';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { INTERCOM_SETUP_TOURS } from '../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const DashboardHeaderActionsExperienceModal = NiceModal.create(() => {
  const { t } = useTranslate('dialogs.noExperiences');
  const { isSm } = useResponsive();
  const modal = useModal();

  const { startTour } = useIntercom();

  const handleClose = () => {
    modal.resolve();
    modal.hide();
  };

  const showProductTour = () => {
    handleClose();
    startTour(INTERCOM_SETUP_TOURS.experience);
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={handleClose}
      fullWidth
      maxWidth={'sm'}
      fullScreen={isSm}
    >
      <Stack gap={3} p={4}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Text fontSize={'xlarge'} variant="medium">
            {t('title')}
          </Text>
          <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Stack>
        <Text>{t('description')}</Text>
        <Button
          variant="primary"
          size="medium"
          onClick={showProductTour}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {t('showMeHow', 'utils.generic')}
        </Button>
      </Stack>
    </Dialog>
  );
});
