import { ExperienceTag } from '@understory-io/utils-types';

import { getLocalizedString } from '../../../../Hooks/locales/use-locale.context';
import { StorefrontLanguage, t } from '../../../../i18n/config';
import { hasLocalizedValue } from './form-helpers';

export const getTagsLabel = (
  tagIds: string[],
  tags: ExperienceTag[],
  activeLanguage: StorefrontLanguage,
  showAll: boolean = false
) => {
  const tagsMap = tags.reduce(
    (acc, tag) => ({ ...acc, [tag.id]: tag }),
    {} as Record<string, ExperienceTag>
  );

  const usedTags = tagIds.map((id) => tagsMap[id]).filter(Boolean);

  if (!showAll && usedTags.length > 3) {
    return (
      usedTags
        .slice(0, 2)
        .map((tag) => getLocalizedString(tag.name, [activeLanguage]))
        .join(', ') + `, + ${usedTags.length - 2} more`
    );
  }

  return usedTags
    .map((tag) => getLocalizedString(tag.name, [activeLanguage]))
    .join(', ');
};

export const getTagsLocaliced = (
  tagIds: string[],
  tags: ExperienceTag[],
  activeLanguage: StorefrontLanguage,
  showAll: boolean = false,
  maxLength: number = 3
) => {
  const tagsMap = tags.reduce(
    (acc, tag) => ({ ...acc, [tag.id]: tag }),
    {} as Record<string, ExperienceTag>
  );

  const usedTags = tagIds
    .map((id) => tagsMap[id])
    .filter((tag) => tag && hasLocalizedValue(tag.name));

  if (!showAll && usedTags.length > maxLength) {
    const displayedTags = usedTags
      .slice(0, maxLength)
      .map((tag) => getLocalizedString(tag.name, [activeLanguage]));
    displayedTags.push(
      `+${t('experience.edit.details.tags.more', {
        count: usedTags.length - maxLength,
      })}`
    );

    return displayedTags;
  }

  return usedTags.map((tag) => getLocalizedString(tag.name, [activeLanguage]));
};
