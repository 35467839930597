import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useApplySearchParams } from '../../../Hooks/useApplySearchParams';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { SignupType } from '../../../Hooks/useProfile';
import { trackSignUpViewed } from '../../../tracking/signup/trackSignUpViewed';
import { useExperimentVariant } from '../../../Utils/experiments';
import { SignupCard } from './signup-card';
import { SignUpHero } from './signup-hero';
import { SignupViewFocused } from './signup-view-focused';
import { LoginPrompt } from './ui/login-prompt';
import { SignupLogos } from './ui/signup-logos';
import { SignupUsps } from './ui/signup-usps';

export const SignupView = () => {
  const { isMd, isSm } = useResponsive();
  const fireOnce = useFireOnce();
  const [searchParams] = useSearchParams();
  const prefilledEmail = searchParams.get('email') || undefined;

  useApplySearchParams();

  useEffect(() => {
    const type =
      searchParams.has('preview') || searchParams.get('source') === 'generic' // signed up from AI but chose generic storefront
        ? SignupType.AISignup
        : SignupType.SelfSignup;

    fireOnce(() => trackSignUpViewed(type));
  }, [fireOnce, searchParams]);

  const experimentVariant = useExperimentVariant('FocusedSignupPage', {
    variant: 'plg-focused-signup-page',
    fallback: { value: 'control' },
  });

  // Return null while experiment is loading
  if (experimentVariant === undefined) {
    return null;
  }

  if (experimentVariant?.value === 'treatment') {
    return <SignupViewFocused />;
  }

  if (isMd)
    return (
      <Stack gap={3} padding={2}>
        {!prefilledEmail && !isSm && (
          <LoginPrompt
            color={lightTheme.palette.contrast.white}
            dropShadow={true}
          />
        )}
        <SignupCard />
        <Stack
          sx={{
            backgroundColor: lightTheme.palette.contrast.white,
            padding: 3,
            borderRadius: 2,
            boxShadow: lightTheme.shadows.small,
          }}
        >
          <SignupUsps />
        </Stack>
        <SignupLogos />
      </Stack>
    );

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        backgroundColor: lightTheme.palette.contrast.white,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        py: 6,
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      {!prefilledEmail && !isSm && (
        <LoginPrompt
          color={lightTheme.palette.contrast.white}
          dropShadow={true}
        />
      )}
      <Grid>
        <SignUpHero />
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: lightTheme.palette.contrast.white,
            maxWidth: 500,
            borderRadius: 4,
          }}
        >
          <SignupCard />
        </Stack>
      </Grid>
      <SignupLogos />
    </Stack>
  );
};

const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
});
