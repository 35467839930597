import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { Localized } from '@understory-io/utils-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router';

import {
  companyProfileQuery,
  experienceLocationsQuery,
  experiencesQuery,
} from '../../../../Api/queries';
import DialogBase from '../../../../Components/dialog/dialog-base';
import { TranslatableInput } from '../../../../Components/translatable-input';
import { StorefrontLanguage } from '../../../../i18n/config';
import { queryClient } from '../../../../query-client';
import routes from '../../../../Utils/routes';
import { EventForm } from '../form/event-form';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const [experiences, companyProfile] = await Promise.all([
    queryClient.fetchQuery(experiencesQuery({ type: 'owned' })),
    queryClient.fetchQuery(companyProfileQuery()),
  ]);

  const url = new URL(request.url);
  const selectedExperience = url.searchParams.get('experience');
  const eventLocationsPromise = selectedExperience
    ? queryClient.fetchQuery(experienceLocationsQuery(selectedExperience))
    : null;

  return { experiences, eventLocationsPromise, companyProfile };
};

export default function CreateEventPage() {
  const [openExtraInfo, setOpenExtraInfo] = useState(false);
  const { experiences, eventLocationsPromise, companyProfile } =
    useLoaderData<LoaderData>();
  const [description, setDescription] = useState<Localized>({
    da: 'Dansk',
    en: 'English',
    sv: 'Svenska',
  });

  const [translating, setTranslating] = useState(false);

  const flags = useFlags();
  const navigate = useNavigate();
  if (!flags?.featureNewEventFlow) {
    navigate(routes.event.create);
    return null;
  }

  return (
    <EventForm handleSubmit={() => console.log('submit create')}>
      <Button
        onClick={() => setOpenExtraInfo(true)}
        size="small"
        variant="secondary"
      >
        Hello
      </Button>
      <Stack spacing={2}>
        <DialogBase
          title="Extra info"
          open={openExtraInfo}
          primaryAction={{
            label: 'Save',
            variant: 'primary',
            type: 'submit',
            onClick: () => {
              setOpenExtraInfo(false);
            },
          }}
          fullHeight={false}
          onClose={() => setOpenExtraInfo(false)}
        >
          <TranslatableInput
            value={description}
            defaultLanguage={
              companyProfile.defaultLanguage as StorefrontLanguage
            }
            availableLanguages={
              companyProfile.languages as StorefrontLanguage[]
            }
            onChange={(value) => {
              setDescription(value);
            }}
            onTranslate={(isTranslating) => setTranslating(isTranslating)}
          />
        </DialogBase>
        <pre>{JSON.stringify(description, null, 2)}</pre>
        {translating && <span>Translating ...</span>}

        <Button
          onClick={() => {
            setDescription({
              sv: 'Exempel på innehåll som genereras av ChatGPT för att sätta i inmatningsfältet...',
              da: 'Eksempel på indhold genereret af ChatGPT til at sætte i inputfeltet...',
              en: 'Example content generated by ChatGPT to put in the input field...',
            });
          }}
          size="medium"
          variant="primary"
        >
          Inject content into the input field
        </Button>
      </Stack>
    </EventForm>
  );
}
