const routes = {
  login: '/login',
  signup: '/signup',
  dashboard: '/dashboard',
  experience: {
    overview: '/experiences',
    details: (experienceId: string) => {
      const experienceIndex = `/experience/${experienceId}`;

      const editIndex = `/experience/${experienceId}/edit`;
      return {
        index: experienceIndex,
        edit: {
          index: editIndex,
          cutoff: editIndex + '/cutoff',
          description: editIndex + '/description',
          whatsIncluded: editIndex + '/whats-included',
          practicalInfo: editIndex + '/practical-info',
          tags: editIndex + '/tags',
          tickets: {
            index: editIndex + '/tickets',
            edit: (variantId: string) => editIndex + `/tickets/${variantId}`,
            delete: (variantId: string) =>
              editIndex + `/tickets/${variantId}/delete`,
          },
          displayPrice: editIndex + '/display-price',
          capacity: editIndex + '/capacity',
          guestInfo: {
            index: editIndex + '/guest-info',
            edit: (requestId: string) => editIndex + `/guest-info/${requestId}`,
            delete: (requestId: string) =>
              editIndex + `/guest-info/${requestId}/delete`,
          },
          resources: {
            create: editIndex + '/resources/create',
            edit: (resourceId: string) =>
              editIndex + `/resources/${resourceId}/edit`,
            delete: (resourceId: string) =>
              editIndex + `/resources/${resourceId}/delete`,
          },
          preview: editIndex + '/preview',
        },
      };
    },
  },
  event: {
    create: '/event/create',
    details: (eventId: string) => {
      const eventIndex = `/event/${eventId}`;
      return {
        index: eventIndex,
        edit: eventIndex + '/edit',
      };
    },
  },
  eventV2: {
    create: '/eventv2/create',
    details: (eventId: string) => {
      const eventIndex = `/eventv2/${eventId}`;
      return {
        index: eventIndex,
        edit: eventIndex + '/edit',
      };
    },
  },
  booking: {
    overview: '/bookings',
    details: (bookingId: string) => {
      const bookingIndex = `/booking/${bookingId}`;
      return {
        index: bookingIndex,
        edit: bookingIndex + '/edit',
      };
    },
  },
  bookingV2: {
    overview: '/v2/bookings',
    details: (bookingId: string) => {
      const bookingIndex = `/v2/booking/${bookingId}`;
      return {
        index: bookingIndex,
        edit: bookingIndex + '/edit',
      };
    },
  },
  calendar: '/calendar',
  giftCard: {
    overview: '/giftcards',
    details: (giftCardId: string) => {
      const giftCardIndex = `/giftcard/${giftCardId}`;
      return {
        index: giftCardIndex,
        adjustValue: giftCardIndex + '/adjust-value',
        refund: giftCardIndex + '/refund',
        deactivate: giftCardIndex + '/deactivate',
      };
    },
  },
  storefrontSettings: {
    index: '/storefront',
    company: {
      index: '/storefront/company',
      edit: {
        description: '/storefront/company/edit/description',
      },
    },
    customize: '/storefront/customize',
    features: '/storefront/features',
    integration: '/storefront/bookingflow',
    locations: '/storefront/locations',
    tags: '/storefront/tags',
    languages: '/storefront/languages',
    payments: '/storefront/payments',
  },
  growth: {
    index: '/growth',
    opportunities: '/growth/opportunities',
    tracking: '/growth/tracking',
    discounts: '/growth/discounts',
    emails: '/growth/emails',
  },
  resourceManagement: {
    index: '/resources',
    create: {
      index: '/resources/create',
      capacity: '/resources/create/capacity',
      recoverTime: '/resources/create/recover-time',
    },
    details: (resourceTypeId: string) => {
      return {
        index: `/resources/${resourceTypeId}`,
        edit: {
          index: `/resources/${resourceTypeId}/edit`,
          capacity: `/resources/${resourceTypeId}/edit/capacity`,
          recoverTime: `/resources/${resourceTypeId}/edit/recover-time`,
        },
        createResource: `/resources/${resourceTypeId}/create`,
      };
    },
  },
  settings: {
    index: '/settings',
    profile: '/settings/profile',
    users: {
      index: '/settings/users',
      invite: '/settings/users/invite',
      roles: {
        index: '/settings/users/roles',
        create: '/settings/users/roles/create',
        edit: (roleId: string) => `/settings/users/roles/edit/${roleId}`,
      },
    },
    billing: '/settings/billing',
    connect: '/settings/connect',
    integrations: '/settings/integrations',
    notifications: '/settings/notifications',
    terms: '/settings/terms',
  },
  connect: {
    index: '/settings/connect',
    invitation: (invitationId: string) => {
      const invitationIndex = '/settings/connect';
      return {
        accept: invitationIndex + `/accept/${invitationId}`,
        decline: invitationIndex + `/decline/${invitationId}`,
      };
    },
    details: (connectionId: string) => {
      const connectionIndex = '/settings/connect/details';
      return {
        index: connectionIndex,
        edit: connectionIndex + `/${connectionId}/edit`,
        disconnect: connectionIndex + `/${connectionId}/disconnect`,
      };
    },
  },
  onboarding: {
    index: '/onboarding',
    uses: '/onboarding/uses',
    pricing: '/onboarding/pricing',
    decision: '/onboarding/decision',
    help: '/onboarding/help',
  },
};

export default routes;
