import { Stack, StackProps, StackTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme } from '@understory-io/pixel';

import { mergeSx } from '../../Utils/merge-sx';

const CONTENT_MAX_WIDTH_PX = 900;

export const FullPageForm: OverridableComponent<StackTypeMap> = ({
  sx,
  ...rest
}: StackProps) => {
  return (
    <Stack
      sx={mergeSx(
        {
          width: '100%',
          flexGrow: 1,
          maxWidth: CONTENT_MAX_WIDTH_PX,
          padding: { xs: 2, md: 4 },
          paddingBottom: { xs: 4, md: 8 },
          mb: 2,
          backgroundColor: lightTheme.palette.contrast.surface2,
          boxShadow: { xs: 'none', sm: lightTheme.shadows.xlarge },
          borderRadius: { md: '20px' },
          gap: 4,
          marginX: 'auto',
        },
        sx
      )}
      {...rest}
    />
  );
};
