import { Grid, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { ampli } from '../../../../Ampli';
import DialogBase from '../../../../Components/dialog/dialog-base';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { flyInFromRightAnimation } from '../../animations';
import {
  DashboardSuggestionCard,
  DashboardSuggestionSkeleton,
} from '../dashboard-suggestion-card';
import { useSuggestions } from '../use-suggestions';

export const SuggestionsSection = () => {
  const { t } = useTranslate('dashboard.suggestions.nextSteps');
  const [showHideTipsDialog, setShowHideTipsDialog] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const { finishOnboarding } = useProfile();
  const { suggestions, isLoading } = useSuggestions();

  const handleHideTips = async () => {
    setFinishing(true);
    await finishOnboarding.mutateAsync();
    setFinishing(false);
    setShowHideTipsDialog(false);
    ampli.dashboardSuggestionsTipsHidden();
  };

  return (
    <>
      <DialogBase
        open={showHideTipsDialog}
        onClose={() => setShowHideTipsDialog(false)}
        title={t('hideDialog.title')}
        description={t('hideDialog.description')}
        maxWidth="xs"
        fullHeight={false}
        actionsInHeader={false}
        showSecondaryActionDesktop
        secondaryAction={{
          label: t('hideDialog.keepAction'),
          variant: 'secondary',
          onClick: () => setShowHideTipsDialog(false),
        }}
        primaryAction={{
          label: t('hideDialog.hideAction'),
          variant: 'danger',
          onClick: handleHideTips,
          loading: finishing,
        }}
      />

      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Text
            variant="medium"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {t('title')}
          </Text>

          <motion.div {...flyInFromRightAnimation}>
            <Button
              size="small"
              variant="text"
              style={{
                color: lightTheme.palette.neutral.n300,
                textDecoration: 'underline',
              }}
              onClick={() => setShowHideTipsDialog(true)}
              loading={finishing}
            >
              {t('hideAction')}
            </Button>
          </motion.div>
        </Stack>
        <Grid container spacing={2}>
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <Grid item xs={12} sm={4} md={4} key={`skeleton-${index}`}>
                  <DashboardSuggestionSkeleton />
                </Grid>
              ))
            : suggestions.map((suggestion) => (
                <DashboardSuggestionCard
                  key={suggestion.id}
                  suggestion={suggestion}
                />
              ))}
        </Grid>
      </Stack>
    </>
  );
};
