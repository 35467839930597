import { RegularExperience } from '@understory-io/experiences-types';

import { experienceSchema } from '../../schemas';

export const publishOnboardingHandler = async (
  experience: RegularExperience
) => {
  // Validate experience
  experienceSchema.validateSync(experience, { abortEarly: false });
};
