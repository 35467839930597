import DialogBase from '../../../../../Components/dialog/dialog-base';
import type { PublishErrors } from '../../edit-experience';
import { CustomError, ErrorContext } from '../error-context';

interface EditExperienceErrorDialogProps {
  errors?: PublishErrors;
  customError?: CustomError;
  experienceId: string;
  closeDialog: () => void;
  handleMissingTextTranslation?: () => void;
}

export default function EditExperienceErrorDialog({
  closeDialog,
  errors,
  customError,
  experienceId,
  handleMissingTextTranslation,
}: EditExperienceErrorDialogProps) {
  return (
    <ErrorContext
      experienceId={experienceId}
      errors={errors}
      customError={customError}
      closeDialog={closeDialog}
      handleMissingTextTranslation={handleMissingTextTranslation}
      render={({ ...props }) => (
        <DialogBase
          open={true}
          onClose={closeDialog}
          showSecondaryActionDesktop={true}
          {...props}
        />
      )}
    />
  );
}
