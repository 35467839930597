import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { Avatar } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { ChangeEvent } from 'react';

import { useRoles } from '../../Hooks/data/useRoles';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { Input } from '../Input/Input';
import { MappedUser } from './users-list-columns';

export const UserPopover = ({
  id,
  name,
  email,
  pictures,
  phone,
  role,
  status,
  invitationId,
  onUpdateRole,
  onDelete,
}: MappedUser & {
  onDelete: (id: string, type: string, email: string) => void;
  onUpdateRole: (
    id: string,
    type: string
  ) => (evt: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslate('settings.team.user');
  const { me } = useProfile();
  const { roles } = useRoles();

  return (
    <Stack p={3} gap={2.5} minWidth={280}>
      <Stack direction={'row'} gap={1}>
        <Avatar src={pictures?.profile?.url} sx={{ width: 64, height: 64 }} />
        <Stack gap={0.5} justifyContent={'center'}>
          <Text variant="medium">{name}</Text>
          <Text fontSize={'small'}>{email}</Text>
          <Text fontSize={'small'}>{phone}</Text>
        </Stack>
      </Stack>
      <Divider />
      <Input
        fullWidth
        type={'select'}
        size={'small'}
        InputLabelProps={{ shrink: Boolean(role) }}
        onChange={onUpdateRole(
          id ?? invitationId,
          status === 'active' ? 'user' : 'invitation'
        )}
        helperText={t('changeRoleHelperText')}
        label={t('role')}
        defaultValue={role}
        disabled={me.data.id === id}
        options={roles.data
          ?.filter((r) => r.status === 'active' || r.id === role)
          .map(({ name, id }) => ({ label: name, key: id }))}
      />
      <Divider />
      <Stack spacing={1}>
        {status === 'active' && me.data.id !== id && (
          <a href={`mailto:${email}`}>
            <Button size="medium" variant="primary" fullWidth>
              {t('actions.writeTo', { userName: name?.split(' ')?.[0] ?? '' })}
            </Button>
          </a>
        )}

        <Button
          size="medium"
          variant="secondary"
          disabled={me.data.id === id}
          fullWidth
          onClick={() =>
            onDelete(
              id ?? invitationId,
              status === 'active' ? 'user' : 'invitation',
              email
            )
          }
          leftIcon={<DeleteOutlineOutlined fontSize="small" />}
        >
          {status === 'active'
            ? t('actions.delete')
            : t('actions.revokeInvitation')}
        </Button>
      </Stack>
    </Stack>
  );
};
