import { Stack } from '@mui/material';
import { API } from '@understory-io/grow-types';
import { Text } from '@understory-io/pixel';
import { renderDate } from '@understory-io/utils-date';

import { StatusBadge } from '../../../../Components/badge/status-badge';
import {
  StyledListItem,
  StyledListItemText,
} from '../../../../features/events/event_details/BookingList/BookingListItem';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../Utils/helpers';
import {
  planListColumns,
  planListColumnWidths,
} from './grow-advertisement-dashboard-plan-list';

type UsersListItemProps = {
  plan: API.GetAdPlans.Response['items'][number];
  index: number;
};

export const PlanListItem = ({ plan, index }: UsersListItemProps) => {
  const { t } = useTranslate('grow.advertisement.dashboard.listItem');
  const { isSm } = useResponsive();
  const { defaultCurrency } = useProfile();
  const { dateFnsLocale } = useLocale();

  if (isSm) {
    return (
      <StyledListItem key={plan.adPlanId} tabIndex={0}>
        <Stack width="100%" gap={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="small" variant="medium">
              {plan.companyName} / Understory Ads {index + 1}
            </Text>
            <StatusBadge type="advertisement" state={plan.state} size="small" />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack gap={0.5}>
              <Text fontSize="xsmall" color="neutral.n400">
                {t('startDate')}
              </Text>
              <Text fontSize="small">
                {plan.startDate ? renderDate(plan.startDate) : '-'}
              </Text>
            </Stack>
            <Stack gap={0.5} alignItems="flex-end">
              <Text fontSize="xsmall" color="neutral.n400">
                {t('budget')}
              </Text>
              <Text fontSize="small">
                {getFormattedPrice(
                  plan.budget,
                  defaultCurrency,
                  dateFnsLocale.code
                )}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem key={plan.adPlanId} tabIndex={0} py={1.5} px={4}>
      {planListColumns.map((el) => (
        <StyledListItemText
          key={el.key}
          fontSize="small"
          style={{
            width: `${
              planListColumnWidths[el.key as keyof typeof planListColumnWidths]
            }%`,
            textAlign: 'left',
          }}
        >
          {el.value(plan, defaultCurrency, dateFnsLocale, index)}
        </StyledListItemText>
      ))}
    </StyledListItem>
  );
};
