import * as api from '..';

const LOCATIONS_QUERY_KEY = 'locations';
const LOCATION_QUERY_KEY = 'location';
export const LOCATIONS_QUERY_KEY_ALL = [LOCATIONS_QUERY_KEY];

/* ---- Locations ----  */
export const locationsQuery = (companyId: string) => ({
  queryKey: [LOCATIONS_QUERY_KEY, companyId],
  queryFn: () => api.getLocations(companyId),
  enabled: !!companyId,
});

/* ---- Experience Locations ----  */
export const experienceLocationsQuery = (experienceId: string) => ({
  queryKey: [LOCATIONS_QUERY_KEY, experienceId],
  queryFn: () => api.getLocationsByExperienceId(experienceId),
  enabled: !!experienceId,
});

/* ---- Location ----  */
export const locationQuery = (locationId: string) => ({
  queryKey: [LOCATION_QUERY_KEY, locationId],
  queryFn: () => api.getLocation(locationId),
  enabled: !!locationId,
});

/* ---- Time Zones ----  */
export const timezonesQuery = (regionNames: Intl.DisplayNames) => ({
  queryKey: ['timezones'],
  queryFn: async () => {
    const timeszones = await api.getTimeZones();
    return timeszones
      .map(({ IANATimezone, city, countryCode, utcOffset }) => ({
        label: `${regionNames.of(countryCode)}, ${city} (UTC${utcOffset})`,
        value: IANATimezone,
        countryCode,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  },
});
