import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ArrowOutwardRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updatePlatformCustomer } from '../../../Api/customer-management';
import DialogBase from '../../../Components/dialog/dialog-base';
import { useYupForm } from '../../../Hooks/use-yup-form';
import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { usePlatformCustomer } from '../../../Hooks/usePlatformCustomer';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackCompanyVatSettingsUpdated } from '../../../tracking/settings/settings-events';
import { CompanyDetailsVatForm } from './CompanyDetailsVatForm';
import {
  ShareCompanyDetailsInputs,
  shareCompanyDetailsSchema,
} from './ShareCompanyDetailsInputs';

export type CompanyDetailsVatDialogProps = {
  paymentProvider: string;
};

export type CompanyDetailsVatDialogResponse =
  | {
      didCompleteCompanyDetails: false;
    }
  | {
      didCompleteCompanyDetails: true;
      isVatExempt: boolean;
    };

export const CompanyDetailsVatDialog =
  NiceModal.create<CompanyDetailsVatDialogProps>(({ paymentProvider }) => {
    const { t } = useTranslate('customerManagement.companyDetails.dialog');
    const modal = useModal();
    const {
      company,
      me: { data: userProfile },
    } = useProfile();
    const platformCustomerQuery = usePlatformCustomer(company.data?.id);
    const countryVatRatesQuery = useCountryVatRates();

    const handleClose = (result: CompanyDetailsVatDialogResponse) => {
      modal.resolve(result);
      modal.remove();
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isValidatingVat, setIsValidatingVat] = useState(false);

    const formMethods = useYupForm({
      schema: shareCompanyDetailsSchema(countryVatRatesQuery.data),
      defaultValues: {
        isVatExempt: false,
        countryCode: company.data?.location?.country,
        companyName: company.data?.name,
      },
    });

    const onSubmit = async (data: ShareCompanyDetailsInputs) => {
      try {
        setIsLoading(true);

        trackCompanyVatSettingsUpdated(
          {
            isTaxRegistered: !data.isVatExempt,
            taxCountry: data.countryCode,
          },
          userProfile?.id ?? ''
        );
        await updatePlatformCustomer(company.data?.id ?? '', {
          companyInfo: {
            isTaxRegistered: !data.isVatExempt,
            vatNumber: data.vatNumber,
            legalName: data.companyName,
            legalLocation: {
              countryCode: data.countryCode,
              address: data.address,
              city: data.city,
              zipCode: data.zipCode,
              state: data.state,
            },
          },
        });

        await platformCustomerQuery.refetch();

        handleClose({
          didCompleteCompanyDetails: true,
          isVatExempt: formMethods.getValues('isVatExempt'),
        });
      } catch (error) {
        toast.error(t('generic', 'utils.errors'));
        setIsLoading(false);
        captureException(error);
      }
    };

    return (
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <DialogBase
          open={modal.visible}
          onClose={() => handleClose({ didCompleteCompanyDetails: false })}
          maxWidth="md"
          title={t('title')}
          description={t('description')}
          fullHeight={true}
          primaryAction={{
            label: t('submit', {
              paymentProvider,
            }),
            variant: 'primary',
            type: 'submit',
            disabled: isLoading || isValidatingVat,
            loading: isLoading,
            rightIcon: <ArrowOutwardRounded />,
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <FormProvider {...formMethods}>
              <CompanyDetailsVatForm
                setIsValidatingVat={setIsValidatingVat}
                isValidatingVat={isValidatingVat}
              />
            </FormProvider>
          </Box>
        </DialogBase>
      </form>
    );
  });
