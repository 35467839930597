import NiceModal from '@ebay/nice-modal-react';
import { Divider, Popover, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { StyledListItemText } from '../../features/events/event_details/BookingList/BookingListItem';
import { StyledInfoBox } from '../../features/events/event_details/shared';
import { useInvitations } from '../../Hooks/data/useInvitations';
import { useRoles } from '../../Hooks/data/useRoles';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { useUsers } from '../../Hooks/useUsers';
import { ConfirmDialog } from '../../Modals/ConfirmDialog';
import { ListSkeleton } from '../ListSkeleton/ListSkeleton';
import {
  MappedUser,
  usersListColumns,
  usersListColumnWidths,
} from './users-list-columns';
import { UsersListItem } from './users-list-item';
import { UserPopover } from './users-list-popover';

export const UsersList = () => {
  const { t } = useTranslate('settings.team');

  const { users, remove, updateRole: updateUserRole } = useUsers();
  const { invitations, revoke, updateRole } = useInvitations();
  const { company } = useProfile();
  const { roles } = useRoles();
  const { isSm } = useResponsive();

  const [openUser, setOpenUser] = useState(-1);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [_users, setUsers] = useState<MappedUser[]>([]);

  useEffect(() => {
    const list: MappedUser[] = [];

    if (users.data) {
      list.push(
        ...users.data.map(
          (el) =>
            ({
              id: el.id,
              email: el.email,
              name: el.name,
              phone: el.phone,
              roleName:
                roles.data?.find((r) => r.id === el.role)?.name ?? el.role,
              role: el.role,
              status: 'active',
              pictures: el.pictures,
            }) as MappedUser
        )
      );
    }

    if (invitations.data) {
      list.push(
        ...invitations.data.map(
          (el) =>
            ({
              id: undefined,
              invitationId: el.id,
              email: el.claims.email,
              name: el.claims.name,
              phone: '',
              roleName:
                roles.data?.find((r) => r.id === el.claims.role)?.name ?? '',
              role: el.claims.role,
              status: 'invited',
            }) as MappedUser
        )
      );
    }

    setUsers(list.sort((a, b) => a.status.localeCompare(b.status)));
  }, [invitations.data, users.data]);

  const handleClick =
    (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenUser(index);
    };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUser(-1);
  };

  const handleUpdateRole =
    (id: string, type: string) =>
    async (evt: ChangeEvent<HTMLInputElement>) => {
      if (type === 'user') {
        // Update invitation role

        handleClose();

        toast.loading(t('loading', 'toast.updateUserRole'), {
          toastId: `start-update-user-role-${id}`,
        });

        await updateUserRole(id, evt.target.value).then(
          () => {
            // Success
            toast.dismiss(`start-update-user-role-${id}`);
            toast.success(t('success', 'toast.updateUserRole'), {
              autoClose: 5000,
            });
          },
          (err) => {
            // Failure
            console.log('Failed to update user role', err);
            toast.dismiss(`start-update-user-role-${id}`);
            toast.error(t('failed', 'toast.updateUserRole'), {
              autoClose: 5000,
            });
          }
        );
      } else {
        // Update invitation role
        handleClose();

        toast.loading(t('loading', 'toast.updateInvitationRole'), {
          toastId: `start-update-invitation-role-${id}`,
        });

        // Update invitation role
        await updateRole(id, evt.target.value).then(
          () => {
            // Success
            toast.dismiss(`start-update-invitation-role-${id}`);
            toast.success(t('success', 'toast.updateInvitationRole'), {
              autoClose: 5000,
            });
          },
          (err) => {
            // Failure
            console.log('Failed to update invitation role', err);
            toast.dismiss(`start-update-invitation-role-${id}`);
            toast.error(t('failed', 'toast.updateInvitationRole'), {
              autoClose: 5000,
            });
          }
        );
      }
    };

  const handleDeleteUser = (id: string, type: string, email: string) => {
    let headline = '';
    let title = '';
    let toastLoading = '';
    let toastSuccess = '';
    let toastError = '';
    let fn: (id: string) => Promise<void>;

    if (type === 'user') {
      headline = t('headlineRevokeAccess', 'dialogs.confirmDelete', { email });
      title = t('titleRevokeAccess', 'dialogs.confirmDelete');
      toastLoading = t('loading', 'toast.revokeUserAccess');
      toastSuccess = t('success', 'toast.revokeUserAccess');
      toastError = t('failed', 'toast.revokeUserAccess');
      fn = remove;
    } else {
      headline = t('headlineRevokeInvitation', 'dialogs.confirmDelete', {
        email,
      });
      title = t('titleRevokeInvitation', 'dialogs.confirmDelete');
      toastLoading = t('loading', 'toast.revokeInvitation');
      toastSuccess = t('success', 'toast.revokeInvitation');
      toastError = t('failed', 'toast.revokeInvitation');
      fn = revoke;
    }

    NiceModal.show(ConfirmDialog, {
      headline,
      title: title,
      confirmLabel: t('actions.primary', 'dialogs.confirmDelete'),
    }).then(() => {
      // Start loading toast
      toast.loading(toastLoading, {
        toastId: `start-remove-user-invitation-${id}`,
      });

      // Begin removal of invitation/user
      fn(id).then(
        () => {
          // Success!
          toast.dismiss(`start-remove-user-invitation-${id}`);
          toast.success(toastSuccess, {
            toastId: `remove-success-${id}`,
            autoClose: 5000,
          });
        },
        () => {
          toast.dismiss(`start-remove-user-invitation-${id}`);
          toast.warn(toastError, {
            toastId: `remove-error-${id}`,
            autoClose: 5000,
          });
        }
      );
    });

    // Close popover
    handleClose();
  };

  const UsersListPopover = (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <UserPopover
        {..._users[openUser]}
        onDelete={handleDeleteUser}
        onUpdateRole={handleUpdateRole}
      />
    </Popover>
  );

  if (invitations.isLoading || users.isLoading) {
    return <ListSkeleton />;
  }

  if (isSm) {
    return (
      <>
        <Stack>
          {(users.isLoading || company.isLoading) && <ListSkeleton />}
          {_users.map((user, index) => (
            <UsersListItem
              key={user.email}
              user={user}
              handleClick={handleClick(index)}
            />
          ))}
        </Stack>
        {UsersListPopover}
      </>
    );
  }

  return (
    <>
      <StyledInfoBox>
        <Stack fontSize={lightTheme.typography.small.normal.fontSize}>
          <Stack direction="row" justifyContent="space-between" py={1} px={4}>
            {usersListColumns.map((el) => (
              <StyledListItemText
                key={el.key}
                fontSize="small"
                variant="medium"
                style={{
                  width: `${
                    usersListColumnWidths[
                      el.key as keyof typeof usersListColumnWidths
                    ]
                  }%`,
                }}
              >
                {el.key !== 'avatar' && t(el.key, 'utils.tables.header')}
              </StyledListItemText>
            ))}
          </Stack>
          <Divider />
          <Stack divider={<Divider />}>
            {(users.isLoading || company.isLoading) && <ListSkeleton />}
            {_users.map((user, index) => (
              <UsersListItem
                key={user.email}
                user={user}
                handleClick={handleClick(index)}
              />
            ))}
          </Stack>
        </Stack>
      </StyledInfoBox>
      {UsersListPopover}
    </>
  );
};
