import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { FormSchema } from '../use-form-validation';
import { ScopesTable } from './scopes-table';

export const Scopes = () => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.scopes'
  );
  const {
    formState: { errors },
  } = useFormContext<FormSchema>();

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} alignItems="center">
        <Text
          fontSize="large"
          variant="medium"
          color={lightTheme.palette.neutral.n400}
        >
          {t('title')}
        </Text>
        {errors.scopes && (
          <Text fontSize="xsmall" color={lightTheme.palette.error.e300}>
            {t(errors.scopes.message as string)}
          </Text>
        )}
      </Stack>
      <ScopesTable />
    </Stack>
  );
};
