import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import {
  ActionFunctionArgs,
  useActionData,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { draftExperienceQuery } from '../../../../../Api/queries';
import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { useLocale } from '../../../../../Hooks/locales/use-locale.context';
import { useExperienceTags } from '../../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import { tagSchema } from '../../../schemas/tagSchema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import { LoaderData, loaderName } from '../../edit-experience';
import { hasLocalizedValue } from '../../utils/form-helpers';
import { translateInput } from '../../utils/translate-input';
import { TagsForm } from './tags-form';

const PROPERTY_NAME = 'tagIds';

export type TagFilterItem = {
  id: string;
  /**
   * `name` is the value for the active language,
   * which can be empty if there is no translation
   */
  name?: string;
  /**
   * `label` is the displayed value of the tag.
   * If the translation is missing, we show a fallback name
   * from a different language, and an alert that the translation is missing.
   */
  label: string;
};

export default function TagsDialog() {
  const { experience, activeLanguage, availableLanguages, tags } =
    useRouteLoaderData(loaderName) as LoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.tags');
  const revalidator = useRevalidator();

  const { createTag } = useExperienceTags();
  const { languageOption } = useLanguages();

  const { getLocalizedString } = useLocale();

  const tagOptions = useMemo(
    () =>
      tags
        .filter((tag) => hasLocalizedValue(tag.name))
        .map((tag) => ({
          id: tag.id,
          name: getLocalizedString(tag.name, activeLanguage),
          label:
            tag.name[activeLanguage] ||
            t('tagOptions.noTranslation', {
              tagName: getLocalizedString(tag.name, activeLanguage),
              locale: languageOption(activeLanguage).label,
            }),
        })),
    [activeLanguage, getLocalizedString, languageOption, t, tags]
  );

  const [selectedTags, setSelectedTags] = useState<TagFilterItem[]>(
    tagOptions.filter((tagOption) => experience.tagIds.includes(tagOption.id))
  );

  const handleCreateTag = async (tagName: string) => {
    try {
      const localizedTag = await translateInput(
        tagName,
        undefined,
        activeLanguage,
        availableLanguages,
        true
      );

      const { item: newTag } = await createTag.mutateAsync(localizedTag);

      // Since we are not submitting an action, we need to tell
      // the loader to revalidate the data so the new tag appears
      revalidator.revalidate();

      setSelectedTags((prev) => [
        ...prev,
        {
          id: newTag.id,
          name: newTag.name[activeLanguage],
          label: newTag.name[activeLanguage],
        },
      ]);
    } catch (error) {
      toast.error(t('generic', 'utils.errors'));
    }
  };

  const handleSelectTag = (tagItem: TagFilterItem) => {
    selectedTags.some((tag) => tag.id === tagItem.id)
      ? setSelectedTags((prev) => prev.filter((tag) => tag.id !== tagItem.id))
      : setSelectedTags((prev) => [...prev, tagItem]);
  };

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="tags"
    >
      <input
        type="hidden"
        name={PROPERTY_NAME}
        value={selectedTags.map((tag) => tag.id).join(',')}
      />
      <TagsForm
        tagOptions={tagOptions}
        selectedTags={selectedTags}
        onCreate={handleCreateTag}
        onSelect={handleSelectTag}
      />
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const id = params.id;

    if (!id) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      const formData = await request.formData();
      const tagIdsInput = formData.get(PROPERTY_NAME);

      if (typeof tagIdsInput !== 'string') {
        throw new Error('Invalid input');
      }

      const tagIds = tagIdsInput ? tagIdsInput.split(',') : [];
      const result = tagSchema.validateSync(tagIds);

      const experience = await client.fetchQuery(draftExperienceQuery(id));

      const experienceToSave = {
        ...experience,
        tagIds: result,
      };

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        return {
          error: error.message,
        };
      }
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
