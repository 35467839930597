import { Search } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { validateCustomerVatNumber } from '../../../Api/customer-management';
import { CountrySelect } from '../../../Components/CountrySelect/CountrySelect';
import { Tooltip } from '../../../Components/tooltip/tooltip';
import { AddressSearchField } from '../../../features/locations/location-search/location-search-input';
import { AddressSearchResult } from '../../../features/locations/location-search/use-location-search';
import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { useProfile } from '../../../Hooks/useProfile';
import { countryCodesWithStates, europeanCountryCodes } from './Countries';
import { ShareCompanyDetailsInputs } from './ShareCompanyDetailsInputs';

type Props = {
  setIsValidatingVat: (value: boolean) => void;
  isValidatingVat: boolean;
};

export const CompanyDetailsVatForm = ({
  setIsValidatingVat,
  isValidatingVat,
}: Props) => {
  const { t } = useTranslation();
  const tError = (message: string | undefined) => t(message ?? '');
  const { company } = useProfile();
  const countryVatRatesQuery = useCountryVatRates();

  const {
    register,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<ShareCompanyDetailsInputs>();

  const { isVatExempt, vatNumber, countryCode } = watch();

  const vatNumberPlaceHolder = useMemo(() => {
    const vatRates = countryVatRatesQuery.data?.[countryCode];
    return vatRates?.vatNumberRules[0].example ?? '';
  }, [countryVatRatesQuery.data, countryCode]);

  const isEuMember = useMemo(() => {
    return europeanCountryCodes.includes(countryCode);
  }, [countryCode]);

  const validateVat = async () => {
    if (isVatExempt || !vatNumber || !countryCode) {
      return;
    }

    setIsValidatingVat(true);

    try {
      const result = await validateCustomerVatNumber(company.data?.id ?? '', {
        countryCode,
        vatNumber,
      });
      clearErrors();

      if (result.succeeded) {
        setValue('companyName', result.companyName);
        setValue('address', result.companyLocation.address);
        setValue('zipCode', result.companyLocation.zipCode);
        setValue('city', result.companyLocation.city);
        setValue('state', result.companyLocation.state);
      } else {
        const errorMessage =
          result.errorCode === 'VAT_NUMBER_INVALID'
            ? 'customerManagement.companyDetails.invalidVatNumber'
            : 'customerManagement.companyDetails.vatNumberValidationFailed';
        setError('vatNumber', { message: t(errorMessage) });
      }
    } catch (error) {
      setError('vatNumber', {
        message: t(
          'customerManagement.companyDetails.vatNumberValidationFailed'
        ),
      });
    } finally {
      setIsValidatingVat(false);
    }
  };

  return (
    <Stack gap={4} pt={2}>
      <Stack>
        <Stack direction="row" gap={2}>
          <Stack sx={{ width: '50%' }}>
            <Controller
              name="countryCode"
              control={control}
              defaultValue={company.data?.location?.country}
              render={({ field }) => (
                <CountrySelect
                  value={field.value}
                  disabled={isValidatingVat}
                  onChange={field.onChange}
                  error={!!errors.countryCode?.message}
                  sx={{ maxHeight: 48 }}
                />
              )}
            />
          </Stack>
          <TextField
            {...register('vatNumber')}
            disabled={isVatExempt || isValidatingVat}
            size="medium"
            fullWidth
            type="text"
            label={t('utils.generic.vatNumber')}
            placeholder={vatNumberPlaceHolder}
            InputLabelProps={{ shrink: true }}
            error={!!errors.vatNumber?.message}
            helperText={tError(errors.vatNumber?.message)}
            InputProps={{ sx: { maxHeight: 48 } }}
          />
          <Tooltip
            title={
              isEuMember
                ? t('customerManagement.companyDetails.searchVatNumberTooltip')
                : t(
                    'customerManagement.companyDetails.searchVatNumberTooltipNonEu'
                  )
            }
          >
            {/* {disabled elements may not be in tooltip} */}
            <span>
              <Button
                variant={'secondary'}
                size={'large'}
                type="button"
                onClick={validateVat}
                loading={isValidatingVat}
                disabled={!vatNumber || isVatExempt || !isEuMember}
              >
                <Search />
              </Button>
            </span>
          </Tooltip>
        </Stack>

        <FormControlLabel
          {...register('isVatExempt')}
          label={t('customerManagement.companyDetails.isVatExempt')}
          disabled={Boolean(vatNumber) || isValidatingVat}
          control={<Checkbox />}
        />
      </Stack>
      <CompanyInfo isValidatingVat={isValidatingVat} />
    </Stack>
  );
};

const CompanyInfo = ({ isValidatingVat }: Pick<Props, 'isValidatingVat'>) => {
  const { t } = useTranslation();
  const tError = (message: string | undefined) => t(message ?? '');

  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext<ShareCompanyDetailsInputs>();

  const countryCode = watch('countryCode');

  const handleSelect = (result: AddressSearchResult) => {
    setValue('address', result.street);
    setValue('city', result.city);
    setValue('countryCode', result.country);
    setValue('zipCode', result.postCode);
    setValue('state', result.state);
  };

  return (
    <>
      <TextField
        {...register('companyName')}
        disabled={isValidatingVat}
        size="medium"
        fullWidth
        type="text"
        label={t('utils.generic.companyName')}
        InputLabelProps={{ shrink: true }}
        error={!!errors.companyName?.message}
        helperText={
          errors.companyName?.message
            ? tError(errors.companyName?.message)
            : t('customerManagement.companyDetails.companyNameHelperText')
        }
        InputProps={{ sx: { maxHeight: 48 } }}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressSearchField
            {...field}
            disabled={isValidatingVat}
            fullWidth
            label={t('utils.generic.address')}
            InputLabelProps={{ shrink: true }}
            error={!!errors.address?.message}
            helperText={tError(errors.address?.message)}
            InputProps={{ sx: { maxHeight: 48 } }}
            onSelectResult={handleSelect}
          />
        )}
      />
      <Stack direction="row" gap={2}>
        <TextField
          {...register('zipCode')}
          disabled={isValidatingVat}
          sx={{ width: '50%' }}
          size="medium"
          type="text"
          label={t('utils.generic.zipCode')}
          InputLabelProps={{ shrink: true }}
          error={!!errors.zipCode?.message}
          helperText={tError(errors.zipCode?.message)}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
        <TextField
          {...register('city')}
          disabled={isValidatingVat}
          size="medium"
          fullWidth
          type="text"
          label={t('utils.generic.city')}
          InputLabelProps={{ shrink: true }}
          error={!!errors.city?.message}
          helperText={tError(errors.city?.message)}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      </Stack>
      {countryCodesWithStates.includes(countryCode) && (
        <TextField
          {...register('state')}
          disabled={isValidatingVat}
          size="medium"
          type="text"
          label={t('utils.generic.state')}
          InputLabelProps={{ shrink: true }}
          error={!!errors.state?.message}
          helperText={tError(errors.state?.message)}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      )}
    </>
  );
};
