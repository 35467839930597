import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { Location } from '@understory-io/utils-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  useGetTimeZones,
  useUpdateLocation,
} from '../../../../Hooks/data/useLocations';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ILocationView } from '../../../../types/api/location';
import { TagDialogWrapper } from '../../tag-management/dialogs/tag-dialog-wrapper';
import { LocationForm } from './location-form';

export const EditLocationDialog = NiceModal.create(
  ({ location }: { location: Location }) => {
    const { t } = useTranslate('location.dialog.edit');
    const updateLocation = useUpdateLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { timezones } = useGetTimeZones();

    const handleSubmit = (data: ILocationView) => {
      setIsSubmitting(true);
      updateLocation
        .mutateAsync(data)
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          toast.error(t('generic', 'utils.errors'));
          captureException(error);
        })
        .finally(() => setIsSubmitting(false));
    };

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.hide();
    };

    if (!location || !timezones.data) return null;

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('title')}
      >
        <LocationForm
          onSubmit={handleSubmit}
          handleCancel={handleClose}
          isSubmitting={isSubmitting}
          defaultCountry={location.address.country}
          defaultValues={location as ILocationView}
          isEdit={true}
          timezones={timezones.data}
        />
      </TagDialogWrapper>
    );
  }
);
