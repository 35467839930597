import { Box, BoxProps, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useState } from 'react';

type ImageProps = {
  src: string;
  alt?: string;
  sx?: BoxProps['sx'];
};

export const Image = ({ src, alt, sx }: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        component="img"
        src={src}
        alt={alt ?? ''}
        sx={{
          visibility: isLoading ? 'hidden' : 'visible',
          display: 'block',
          objectFit: 'cover',
          height: '100%',
          width: '100%',
        }}
        onLoad={() => setIsLoading(false)}
      />
      <Stack
        sx={{
          position: 'absolute',
          inset: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: lightTheme.palette.contrast.surface1,
          animation: isLoading
            ? 'loading 3s ease-in-out infinite'
            : 'loading 3s ease-in-out infinite, fade-out 0.05s ease forwards',
          '@keyframes loading': {
            '0%': {
              backgroundColor: lightTheme.palette.contrast.surface1,
            },
            '50%': {
              backgroundColor: lightTheme.palette.neutral.n100,
            },
            '100%': {
              backgroundColor: lightTheme.palette.contrast.surface1,
            },
          },
          '@keyframes fade-out': {
            '0%': {
              opacity: 1,
            },
            '100%': {
              opacity: 0,
            },
          },
        }}
      />
    </Box>
  );
};
