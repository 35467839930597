import { useCallback } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';
import { countryToTaxType } from './country-to-tax-type';

export function useTaxLabel(taxCountry?: string): (prefix: string) => string {
  const { t } = useTranslate('');

  const taxType = countryToTaxType(taxCountry);

  return useCallback(
    (prefix: string) => {
      return (
        t(taxType, prefix, {
          defaultValue: '',
        }) || t('generic', prefix)
      );
    },
    [t, taxType]
  );
}
