import { Check, ContentCopy } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../Link/Link';

type CopyLinkProps = {
  href: string;
  label?: string | React.ReactNode;
  color?: string;
  iconPosition?: 'left' | 'right';
  size?: keyof typeof lightTheme.tokens.font.presets;
  fontSize?: keyof typeof lightTheme.tokens.font.presets;
  isEmail?: boolean;
  onClickText?: () => void;
  onClickCopy?: () => void;
};

export const CopyLink = ({
  href,
  label,
  color,
  iconPosition = 'right',
  size = 'large',
  fontSize = 'xsmall',
  isEmail = false,
  onClickText,
  onClickCopy,
}: CopyLinkProps) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  return (
    <Link
      fontSize={fontSize}
      href={href}
      target={'_blank'}
      iconPosition={iconPosition}
      onClick={(e) => {
        e.stopPropagation();
        onClickText?.();
      }}
      icon={
        <Tooltip
          title={t('utils.generic.linkCopied')}
          placement={'top'}
          open={copied}
          arrow
          componentsProps={{
            arrow: {
              sx: {
                color: lightTheme.palette.success.s300,
              },
            },
            tooltip: {
              sx: {
                backgroundColor: lightTheme.palette.success.s300,
              },
            },
          }}
        >
          {copied ? (
            <Check
              sx={{
                color: lightTheme.palette.success.s300,
                ...iconStyles(iconPosition, size),
              }}
            />
          ) : (
            <ContentCopy
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickCopy?.();

                navigator.clipboard
                  .writeText(isEmail ? href.replace('mailto:', '') : href)
                  .then(() => setCopied(true))
                  .then(() => setTimeout(() => setCopied(false), 2000));
              }}
              sx={{
                cursor: 'pointer',
                color: color ?? lightTheme.palette.neutral.n400,
                transition: 'all 0.1s ease-in-out',
                '&:hover': { color: color ?? lightTheme.palette.neutral.n500 },
                ...iconStyles(iconPosition, size),
              }}
            />
          )}
        </Tooltip>
      }
    >
      {label ?? t('utils.generic.goToLink')}
    </Link>
  );
};

const iconStyles = (
  iconPosition: 'left' | 'right',
  size: keyof typeof lightTheme.tokens.font.presets
) => ({
  marginLeft: iconPosition === 'right' ? lightTheme.tokens.spaces[1] : 0,
  marginRight: iconPosition === 'left' ? lightTheme.tokens.spaces[1] : 0,
  fontSize: lightTheme.tokens.font.presets[size].fontSize,
});
