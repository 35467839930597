import { Grid, Stack } from '@mui/material';

import { ampli } from '../../../../Ampli';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { INTENDED_USES } from '../../config';
import { useOnboardingFlow } from '../../onboarding-context';
import { OnboardingStepLayout } from '../step-layout/step-layout';
import { UsesChip } from './uses-chip';

export const UsesStep = () => {
  const { t } = useTranslate('onboardingFlow.step.uses');
  const { selections, updateSelections } = useOnboardingFlow();
  const { company, updateCompany } = useProfile();

  const handleToggleUse = (use: string) => {
    const isAdding = !selections.uses.includes(use);
    updateSelections({
      uses: isAdding
        ? [...selections.uses, use]
        : selections.uses.filter((item) => item !== use),
    });

    if (isAdding) {
      ampli.onboardingFlowIntendedUseAdded({ use });
    } else {
      ampli.onboardingFlowIntendedUseRemoved({ use });
    }
  };

  const handleSubmit = () => {
    if (!company.data?.id) return;

    updateCompany.mutate({
      ...company.data,
      onboarding: {
        intendedUses: selections.uses,
      },
    });
  };

  return (
    <OnboardingStepLayout
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      showBackButton={false}
    >
      <Stack width="100%">
        <Grid container spacing={1.5} width="100%">
          {INTENDED_USES.map((use) => (
            <Grid item key={use}>
              <UsesChip
                label={t(`uses.${use}`)}
                isSelected={selections.uses.includes(use)}
                onClick={() => handleToggleUse(use)}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </OnboardingStepLayout>
  );
};
