import { ChevronRightRounded } from '@mui/icons-material';
import { Avatar, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import {
  StyledListItem,
  StyledListItemText,
} from '../../features/events/event_details/BookingList/BookingListItem';
import useResponsive from '../../Hooks/layout/useResponsive';
import { StatusBadge } from '../badge/status-badge';
import {
  MappedUser,
  usersListColumns,
  usersListColumnWidths,
} from './users-list-columns';

type UsersListItemProps = {
  user: MappedUser;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const UsersListItem = ({ user, handleClick }: UsersListItemProps) => {
  const { isSm } = useResponsive();

  if (isSm) {
    return (
      <StyledListItem
        key={user.email}
        tabIndex={0}
        py={2}
        px={2}
        mx={-2}
        onClick={handleClick}
      >
        <Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              src={user.pictures?.profile?.url}
              sx={{ width: 20, height: 20 }}
            />
            <Text fontSize="small" variant="medium">
              {user.name}
            </Text>
          </Stack>
          <Text fontSize="small">{user.email}</Text>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <StatusBadge type="user" state={user.status} size="small" />
          <ChevronRightRounded />
        </Stack>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      key={user.email}
      tabIndex={0}
      py={1.5}
      px={4}
      onClick={handleClick}
    >
      {usersListColumns.map((el) => (
        <StyledListItemText
          key={el.key}
          fontSize="small"
          style={{
            width: `${
              usersListColumnWidths[
                el.key as keyof typeof usersListColumnWidths
              ]
            }%`,
          }}
        >
          {el.value(user)}
        </StyledListItemText>
      ))}
    </StyledListItem>
  );
};
