import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Children, PropsWithChildren } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

type Props = PropsWithChildren;

export const FullPageMoreOptions = ({ children }: Props) => {
  const { t } = useTranslate('fullPageForm.generic');

  const count = Children.count(Children.toArray(children));
  if (!count) return null;

  return (
    <Stack sx={{ gap: 1 }}>
      <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
        {t('moreOptions')}
      </Text>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 1fr',
          sm: '1fr 1fr 1fr',
        }}
        gap={2}
      >
        {children}
      </Box>
    </Stack>
  );
};
