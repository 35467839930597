import NiceModal from '@ebay/nice-modal-react';
import { PaymentOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';

import { ampli } from '../../../../Ampli';
import { useOnBoarding } from '../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useStripeIntegration } from '../../../../Sections/SettingsSections/Integrations/domain/integrations/use-stripe-integration';
import {
  AvailableIntegrationDialog,
  AvailableIntegrationDialogProps,
} from '../../../../Sections/SettingsSections/Integrations/ui/available-integrations-list/available-integration-dialog';
import { fadeInAnimation } from '../../animations';
import { useSuggestions } from '../use-suggestions';

export const PaymentSection = () => {
  const { t } = useTranslate('dashboard.suggestions.payment');
  const { onBoarding } = useOnBoarding();
  const { hasPaymentsEnabled } = useSuggestions();
  const stripeIntegration = useStripeIntegration();

  const handleConnectPayments = () => {
    if (stripeIntegration) {
      const onAddIntegration = async () => {
        await stripeIntegration.onInstall?.(stripeIntegration.status)();
      };

      NiceModal.show(AvailableIntegrationDialog, {
        integration: {
          ...stripeIntegration,
          buttonLabel:
            typeof stripeIntegration.buttonLabel === 'function'
              ? stripeIntegration.buttonLabel(stripeIntegration.status)
              : stripeIntegration.buttonLabel,
        },
        onAddIntegration,
      } as AvailableIntegrationDialogProps);
    }

    ampli.dashboardSuggestionsConnectPaymentsClicked();
  };

  if (hasPaymentsEnabled || onBoarding.isLoading) {
    return null;
  }

  return (
    <motion.div {...fadeInAnimation}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Text
            variant="medium"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {t('title')}
          </Text>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            p: 2,
            gap: { xs: 2, sm: 4 },
            borderRadius: 2,
            bgcolor: lightTheme.palette.contrast.white,
            boxShadow: lightTheme.shadows.small,
          }}
        >
          <Stack
            sx={{
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 2,
              bgcolor: `${lightTheme.palette.primary.p300}40`,
            }}
          >
            <PaymentOutlined
              sx={{
                fontSize: 24,
                color: lightTheme.palette.neutral.n400,
              }}
            />
          </Stack>
          <Stack
            gap={2}
            direction={{ xs: 'column', sm: 'row' }}
            flex={1}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent="space-between"
          >
            <Stack gap={0.5} maxWidth={{ xs: '100%', sm: '60%' }}>
              <Text variant="medium" fontSize="small">
                {t('subtitle')}
              </Text>
              <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
                {t('description')}
              </Text>
            </Stack>
            <Button
              variant="secondary"
              size="small"
              onClick={handleConnectPayments}
            >
              {t('action')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};
