import styled from '@emotion/styled';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { Text } from '@understory-io/pixel';
import React, { forwardRef, useState } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';
import { ContextMenu } from '../context-menu/context-menu';
import { ContextMenuItemProps } from '../context-menu/context-menu-item';
import {
  chipConfigs,
  ChipType,
  colorVariants,
  getTranslationBase,
  sizeVariants,
} from './chip-config';

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'appearance' && prop !== 'size',
})<Pick<Required<ChipProps>, 'appearance' | 'size'>>(
  {
    display: 'inline-flex',
    gap: 6,
    alignItems: 'center',
    lineHeight: '100%',
  },
  (props) => ({
    ...colorVariants[props.appearance],
    ...sizeVariants[props.size],
  })
);

const StyledSpan = styled('span')({
  display: 'inline-flex',
});

function Container({
  children,
  appearance = 'default',
  size = 'medium',
}: Pick<ChipProps, 'appearance' | 'size'> & { children: React.ReactNode }) {
  return (
    <StyledText
      appearance={appearance}
      size={size}
      variant="medium"
      fontSize={size === 'medium' ? 'medium' : 'small'}
    >
      {React.Children.map(children, (child) =>
        child === null ||
        (typeof child === 'string' && child.trim().length === 0) ? null : (
          <StyledSpan>{child}</StyledSpan>
        )
      )}
    </StyledText>
  );
}

export type ChipProps = ChipType & {
  size?: 'small' | 'medium';
  appearance?: 'default' | 'success' | 'error' | 'warning' | 'action';
  options?: ContextMenuItemProps[];
};

export const Chip = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ options, type, state, ...props }: ChipProps, _ref) => {
    const { t } = useTranslate(getTranslationBase(type));
    const [isOpen, setIsOpen] = useState(false);
    const chip = chipConfigs[type][state];

    if (!options) {
      return null;
    }

    return (
      <ContextMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        element={
          <ButtonBase
            disableRipple
            sx={{
              ':focus-visible': {
                outline: 1,
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Container appearance={chip.appearance} {...props}>
              {chip.icon}
              {t(state)}
              {isOpen ? (
                <KeyboardArrowUpRounded fontSize="small" />
              ) : (
                <KeyboardArrowDownRounded fontSize="small" />
              )}
            </Container>
          </ButtonBase>
        }
        options={options}
      />
    );
  }
);

Chip.displayName = 'Chip';
