import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { GiftCard, GiftCardHistory, Receipt } from '@understory-io/utils-types';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLoaderData,
} from 'react-router';

import { receiptQuery } from '../../../Api/queries';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { GiftCardsItemBreadCrumb } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/gift-card/gift-card-item-breadcrumb';
import { GiftCardsListBreadCrumb } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/gift-card/gift-cards-list-breadcrumb';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { TUser } from '../../../Hooks/useUsers';
import { giftCardDetailsOpened } from '../../../tracking/giftCards/giftCardEvents';
import routes from '../../../Utils/routes';
import {
  ActivityLog,
  GiftCardActivity,
} from './gift-card-details/acitivity-log';
import { CustomerInformation } from './gift-card-details/customer-information';
import { GiftCardDetails } from './gift-card-details/gift-card-details';
import {
  GiftCardAction,
  GiftCardDetailsHeader,
} from './gift-card-details-header';
import { getCompanyUsersQuery, getGiftCardQuery } from './queries';

export type LoaderData = {
  giftCard: GiftCard;
  activities: Promise<GiftCardActivity[]>;
  actions: Promise<GiftCardAction[]>;
  isRefundable: Promise<boolean>;
};

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = params.id;

    // HB-2649 - Redirect to correct URL if the id contains dashes
    if (id?.includes('-')) {
      return redirect(
        routes.giftCard.details(id.replaceAll('-', '')).index,
        301
      );
    }

    if (!id) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      const giftCard = await getGiftCardQuery(id);

      if (!giftCard) throw new Response('Gift card not found', { status: 404 });

      // `history` can be undefined
      const sortedHistory = (giftCard.history ?? [])
        .filter(Boolean)
        .sort(
          (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
        );

      const historyReceiptIds: string[] = [];
      for (const historyEntry of sortedHistory) {
        if ('receiptId' in historyEntry) {
          historyReceiptIds.push(historyEntry.receiptId);
        }
      }

      const receipts = Promise.all(
        historyReceiptIds.map((receiptId) =>
          client.fetchQuery(receiptQuery(receiptId))
        )
      );
      const users = getCompanyUsersQuery();
      const receipt = giftCard.receiptId
        ? await client.fetchQuery(receiptQuery(giftCard.receiptId))
        : Promise.resolve(null);

      const isRefundable = async () => {
        const r = await receipt;
        const isRefunded =
          !!r &&
          giftCard.amountLeftCents ===
            r.refunds.reduce(
              (totalRefunded, refund) =>
                (totalRefunded += refund.amountRefundedCents),
              0
            );

        return !isRefunded;
      };

      const actions = async () => {
        const actions: GiftCardAction[] = [];

        if (giftCard.status !== 'cancelled') {
          actions.push('deactivate');
        }

        if ((await isRefundable()) && giftCard.amountLeftCents > 0) {
          actions.push('refund');
        }

        if (giftCard.status !== 'cancelled') {
          actions.push('adjustValue');
        }

        return actions;
      };

      return {
        giftCard,
        activities: mapHistoryToActivities(
          giftCard.history || [],
          users,
          receipts
        ),
        actions: actions(),
        isRefundable: isRefundable(),
      } as LoaderData;
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        throw error;
      }

      captureException(error);
      throw new Response(error as string);
    }
  };

async function mapHistoryToActivities(
  history: GiftCardHistory[],
  usersPromise: Promise<TUser[]>,
  receiptsPromise: Promise<Receipt[]>
) {
  const [users, receipts] = await Promise.all([usersPromise, receiptsPromise]);

  return (
    history
      .map<GiftCardActivity | null>((entry) => {
        const receipt =
          entry.type === 'giftcard-used' || entry.type === 'giftcard-filled'
            ? receipts.find((receipt) => receipt.id === entry.receiptId)
            : null;

        const bookingId =
          receipt?.subject === 'booking' ? receipt?.metadata.booking.id : '';

        const user =
          'userId' in entry
            ? users.find((user: { id?: string }) => user.id === entry.userId)
            : null;

        switch (entry.type) {
          case 'giftcard-used':
            return {
              type: entry.type,
              date: entry.date,
              amountUsedCents: entry.amountUsedCents,
              bookingId,
              note: entry.note,
            };
          case 'giftcard-value-adjusted':
            return {
              type: entry.type,
              date: entry.date,
              amountUsedCents: entry.amountUsedCents,
              userName: user?.name,
              note: entry.note,
            };
          case 'giftcard-cancelled':
            return {
              type: entry.type,
              date: entry.date,
              userName: user?.name,
            };
          case 'giftcard-refunded':
            return {
              type: entry.type,
              date: entry.date,
              refundedAmountCents: entry.refundedAmountCents ?? 0,
            };
          case 'giftcard-filled': {
            return {
              type: entry.type,
              date: entry.date,
              amountFilledCents: entry.amountFilledCents,
              bookingId,
            };
          }
          default:
            return null;
        }
      })
      // Filter out invalid entries not following history pattern
      .filter(Boolean) as GiftCardActivity[]
  );
}
export default function GiftCardDetailsPage() {
  const { t } = useTranslation();
  const fireOnce = useFireOnce();

  const { giftCard, activities, actions } = useLoaderData<LoaderData>();

  const queryClient = useQueryClient();

  useEffect(() => {
    // Make sure that the query is invalidated if any changes
    // occur to the gift card.
    queryClient.invalidateQueries({
      queryKey: ['vouchers'],
    });
  }, [queryClient]);

  useEffect(() => {
    fireOnce(() => {
      giftCardDetailsOpened({
        status: giftCard.status,
      });
    });
  }, [giftCard.status, fireOnce]);

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <GiftCardsListBreadCrumb />
        <GiftCardsItemBreadCrumb id={giftCard.id} code={giftCard.code} />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack width="100%" maxWidth={1200} minWidth={350} gap={4}>
          <GiftCardDetailsHeader
            giftCardId={giftCard.id}
            title={giftCard.code}
            subtitle={t('giftCard.details.header.subtitle', {
              date: format(new Date(giftCard.created), 'PPP'),
            })}
            status={giftCard.status}
            actions={actions}
          />
          <Stack
            sx={{ gap: 2, flexDirection: { xs: 'column-reverse', md: 'row' } }}
          >
            <Stack sx={{ gap: 2, flexGrow: 1, maxWidth: { md: '60%' } }}>
              <GiftCardDetails
                balanceCents={giftCard.amountLeftCents}
                initialValueCents={giftCard.originalAmountCents}
                currency={giftCard.currency}
                expiryDate={new Date(giftCard.expiresAt)}
                code={giftCard.code}
              />
              <ActivityLog
                activities={activities}
                currency={giftCard.currency}
                purchaseDate={new Date(giftCard.created)}
              />
            </Stack>
            <Stack sx={{ gap: 2, flexGrow: 1, maxWidth: { md: '40%' } }}>
              <CustomerInformation
                address={[
                  giftCard.customer.address?.line1,
                  giftCard.customer.address?.line2,
                ]
                  .filter(Boolean)
                  .join(', ')}
                postalCode={giftCard.customer.address?.postalCode ?? ''}
                city={giftCard.customer.address?.city ?? ''}
                country={giftCard.customer.address?.country ?? ''}
                name={giftCard.customer.name}
                phone={giftCard.customer.phone}
                email={giftCard.customer.email}
              />
            </Stack>
          </Stack>
        </Stack>
        <Outlet />
      </PageBreadcrumb>
    </>
  );
}
