import { ArrowOutwardRounded } from '@mui/icons-material';
import { Button } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DashboardRequestReport } from './dashboard-request-report';
import { useDashboardHeaderActions } from './use-dashboard-header-actions';

type DashboardHeaderActionsProps = {
  isNavDrawer?: boolean;
};

export const DashboardHeaderActions = ({
  isNavDrawer,
}: DashboardHeaderActionsProps) => {
  const { t } = useTranslate('dashboard');
  const { isSm } = useResponsive();

  const { stripeCompleted, handleStripeClick } = useDashboardHeaderActions();

  if (isSm && !isNavDrawer) {
    return <DashboardRequestReport />;
  }

  if (!stripeCompleted) {
    return null;
  }

  return (
    <Button
      rightIcon={<ArrowOutwardRounded />}
      variant="secondary"
      size="medium"
      onClick={handleStripeClick}
    >
      {t('buttons.goToStripe')}
    </Button>
  );
};
