import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { createContext, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

type MediaDropzoneProps = {
  isMediaUploadDisabled: boolean;
  amountOfFilesToAccept: number;
  onDrop: (files: File[]) => void;
  children: ReactNode;
};

export const mediaDropZoneContext = createContext<{
  handleOpen?: () => void;
}>({});

const MediaDropzoneContextProvider = mediaDropZoneContext.Provider;

export const MediaDropzone = ({
  isMediaUploadDisabled,
  amountOfFilesToAccept,
  onDrop,
  children,
}: MediaDropzoneProps) => {
  const { getInputProps, isDragActive, getRootProps, open } = useDropzone({
    preventDropOnDocument: true,
    noClick: true,
    disabled: isMediaUploadDisabled,
    onDrop,
    maxFiles: amountOfFilesToAccept,
    useFsAccessApi: true,
    multiple: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.webp', '.gif'],
      'video/*': ['.mp4', '.mov', '.webm'],
    },
  });

  return (
    <Stack
      sx={{
        borderRadius: 2,
        backgroundColor: isDragActive
          ? lightTheme.palette.contrast.surface1
          : 'transparent',
        border: !isDragActive ? 'none' : null,
        borderColor: isDragActive
          ? lightTheme.palette.neutral.n200
          : 'transparent',
        borderStyle: 'dashed',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <MediaDropzoneContextProvider value={{ handleOpen: open }}>
        {children}
      </MediaDropzoneContextProvider>
    </Stack>
  );
};
