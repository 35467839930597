import { Stack, StackProps, StackTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { mergeSx } from '../../Utils/merge-sx';

export const FullPage: OverridableComponent<StackTypeMap> = ({
  sx,
  ...rest
}: StackProps) => {
  return (
    <Stack
      sx={mergeSx(
        {
          minHeight: '100vh',
        },
        sx
      )}
      {...rest}
    />
  );
};
