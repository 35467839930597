import { Divider, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { InfoBox } from '../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useIntegrations } from '../../domain/use-integrations';
import { ActiveIntegrationsListItem } from './active-integrations-list-item';

export const ActiveIntegrationsList = () => {
  const { t } = useTranslate('settings.integrations');
  const { activeIntegrations, loading } = useIntegrations();
  const { isSm } = useResponsive();

  if (loading || activeIntegrations.length === 0) return null;

  if (isSm) {
    return (
      <Stack divider={<Divider />} gap={1}>
        <Text fontSize="small" variant="medium">
          {t('enabledIntegrations')}
        </Text>
        {activeIntegrations
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((integration) => (
            <ActiveIntegrationsListItem
              key={integration.id}
              integration={integration}
            />
          ))}
      </Stack>
    );
  }

  return (
    <InfoBox title={t('enabledIntegrations')}>
      <Stack gap={1}>
        {activeIntegrations.map((integration) => (
          <ActiveIntegrationsListItem
            key={integration.id}
            integration={integration}
          />
        ))}
      </Stack>
    </InfoBox>
  );
};
