import { Box, CircularProgress, Skeleton, Stack, styled } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useEffect, useState } from 'react';

import { useAdPlans } from '../../../../../Hooks/use-ad-plans';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';

const StyledBox = styled(Box)(() => ({
  backgroundColor: 'white',
  borderRadius: 16,
  width: '100%',
  overflow: 'hidden',
  boxShadow: lightTheme.shadows.medium,
}));

const StyledStack = styled(Stack)(() => ({
  gap: 8,
  alignItems: 'center',
  width: '90%',
}));

const StyledContent = styled(Stack)(() => ({
  padding: 12,
}));

type AdPreviewProps = {
  companyName: string;
  adPlanId: string;
};

export const AdPreview = ({ companyName, adPlanId }: AdPreviewProps) => {
  const { t } = useTranslate('grow.advertisement.launch');
  const { company } = useProfile();
  const {
    adPreview: { data: adPreview },
  } = useAdPlans(adPlanId);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!adPreview) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }
  }, [adPreview]);

  return (
    <StyledStack>
      <Text
        fontSize="xsmall"
        variant="medium"
        color={lightTheme.palette.neutral.n300}
        style={{ width: '100%', textAlign: 'left' }}
      >
        {t('adPreview')}
      </Text>
      <StyledBox>
        <StyledContent direction="row" gap={1} alignItems="center">
          {company?.data?.pictures?.logo?.url ? (
            <Box
              component="img"
              src={company?.data?.pictures?.logo?.url ?? ''}
              sx={{ width: 24, height: 24, borderRadius: '50%' }}
            />
          ) : null}
          <Stack>
            <Text fontSize="small" variant="medium">
              {companyName}
            </Text>
            <Text fontSize="tiny" color={lightTheme.palette.neutral.n300}>
              {t('sponsored')}
            </Text>
          </Stack>
        </StyledContent>
        <StyledContent gap={1}>
          {adPreview?.headline ? (
            <Text fontSize="tiny">{adPreview?.headline}</Text>
          ) : (
            <Skeleton
              variant="text"
              width={100}
              height={20}
              animation={false}
            />
          )}
          {adPreview?.description ? (
            <Text
              fontSize="tiny"
              color={lightTheme.palette.neutral.n300}
              style={{
                whiteSpace: 'pre-wrap',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {adPreview?.description}
            </Text>
          ) : (
            <Skeleton
              variant="text"
              width={100}
              height={20}
              animation={false}
            />
          )}
        </StyledContent>
        {adPreview?.mediaUrl ? (
          <>
            <Box
              component="img"
              src={adPreview?.mediaUrl ?? ''}
              sx={{
                width: '100%',
                height: 150,
                objectFit: 'cover',
              }}
            />
          </>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150}>
            <Stack
              height={150}
              width={'100%'}
              flexGrow={1}
              visibility="visible"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Text>{t('creatingPreview')}</Text>
              <CircularProgress
                size={24}
                variant="determinate"
                value={progress}
              />
            </Stack>
          </Skeleton>
        )}
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text
            fontSize="xsmall"
            variant="medium"
            color={lightTheme.palette.neutral.n300}
          >
            {companyName}
          </Text>
          <Button
            variant="primary"
            size="small"
            style={{ backgroundColor: '#F2F2F2' }}
            disabled
          >
            {t('bookNow', 'settings.widget.buttons')}
          </Button>
        </Box>
      </StyledBox>
    </StyledStack>
  );
};
