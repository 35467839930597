import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Button } from '@understory-io/pixel';
import { countries } from '@understory-io/utils-countries';
import { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { CountrySelect } from '../../../../Components/CountrySelect/CountrySelect';
import { AddressSearchField } from '../../../../features/locations/location-search/location-search-input';
import { AddressSearchResult } from '../../../../features/locations/location-search/use-location-search';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ILocationView } from '../../../../types/api/location';

export const LocationForm = ({
  onSubmit,
  defaultValues,
  handleCancel,
  isSubmitting,
  isEdit = false,
  defaultCountry,
  timezones,
}: {
  onSubmit: (data: ILocationView) => void;
  defaultValues?: Partial<ILocationView>;
  handleCancel: () => void;
  isSubmitting: boolean;
  isEdit?: boolean;
  defaultCountry?: string;
  timezones: { label: string; value: string; countryCode: string }[];
}) => {
  const { t } = useTranslate('location.dialog.form');

  const formMethods = useForm<ILocationView>({
    defaultValues: {
      locationId: defaultValues?.locationId,
      locationName: defaultValues?.locationName,
      address: {
        line1: defaultValues?.address?.line1,
        line2: defaultValues?.address?.line2,
        city: defaultValues?.address?.city,
        state: defaultValues?.address?.state,
        postalCode: defaultValues?.address?.postalCode,
        country: defaultValues?.address?.country ?? defaultCountry,
        geoCoordinates: defaultValues?.address?.geoCoordinates,
      },
      timezone: defaultValues?.timezone,
      revision: defaultValues?.revision,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = formMethods;

  const handleSelect = useCallback(
    (result: AddressSearchResult) => {
      setValue('address.line1', result.street);
      setValue('address.city', result.city);
      setValue('address.country', result.country);
      setValue('address.state', result.state);
      setValue('address.postalCode', result.postCode);
      setValue('address.geoCoordinates', result.geoCoordinates);
      setValue('timezone', result.timezone);
    },
    [setValue]
  );

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ flexGrow: 1, justifyContent: 'space-between', gap: 4 }}
      >
        <Stack sx={{ gap: 3 }}>
          <TextField
            {...register('locationName', {
              required: {
                value: true,
                message: t('locationName.error.required'),
              },
              maxLength: {
                value: 20,
                message: t('locationName.error.maxLength', { amount: 20 }),
              },
            })}
            required
            label={t('locationName.label')}
            placeholder={t('locationName.placeholder')}
            InputLabelProps={{ shrink: true }}
            error={!!errors.locationName?.message}
            helperText={errors.locationName?.message}
            sx={{ marginBottom: 4 }}
          />

          <Controller
            name={'address.line1'}
            render={({ field }) => (
              <AddressSearchField
                {...field}
                InputLabelProps={{ shrink: true }}
                label={t('address', 'utils.generic')}
                onSelectResult={handleSelect}
              />
            )}
          />
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <TextField
              {...register('address.postalCode')}
              fullWidth
              label={t('address.postalCode.label')}
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: '35%' }}
            />
            <TextField
              {...register('address.city')}
              fullWidth
              label={t('address.city.label')}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <TextField
              {...register('address.state')}
              fullWidth
              label={t('address.state.label')}
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: '35%', maxHeight: 48 }}
            />

            <Controller
              name={'address.country'}
              rules={{
                required: {
                  value: true,
                  message: t('address.country.error.required'),
                },
              }}
              render={({ field }) => (
                <CountrySelect
                  label={t('address.country.label')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    const newTimezone = timezones.find(
                      (tz) => tz.countryCode === e.target.value
                    )?.value;
                    setValue('timezone', newTimezone ?? '');
                  }}
                  fullWidth
                  error={!!errors.address?.country?.message}
                  sx={{ maxHeight: 53 }}
                />
              )}
            />
          </Stack>
          <Controller
            name="timezone"
            rules={{
              required: {
                value: true,
                message: t('required', 'utils.errors'),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <FormControl {...field} required>
                <InputLabel id="timezone" shrink>
                  {t('timezone.label')}
                </InputLabel>
                <Select
                  displayEmpty
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    const timezone = timezones.find(
                      (tz) => tz.value === e.target.value
                    );
                    const newCountry = Object.keys(countries).find(
                      (countryCode) => countryCode === timezone?.countryCode
                    );
                    if (newCountry) {
                      setValue('address.country', newCountry);
                    }
                  }}
                  labelId="timezone"
                  input={<OutlinedInput notched label="Timezone" />}
                >
                  <MenuItem value="" selected disabled>
                    {t('timezone.placeholder')}
                  </MenuItem>
                  {timezones.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            '& > button': {
              flexGrow: 1,
              flexBasis: { md: 0 },
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleCancel}
          >
            {t('cancel', 'location.dialog.action')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="large"
            loading={isSubmitting}
          >
            {t(isEdit ? 'save' : 'create', 'location.dialog.action')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
