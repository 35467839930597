import { Stack } from '@mui/material';

import { useDashboardPageContext } from '../use-dashboard-page-context';
import { DashboardGraphsFilter } from './dashboard-graphs-filter/dashboard-graphs-filter';
import { DashboardGraphsLoader } from './dashboard-graphs-loader/dashboard-graphs-loader';
import { DashboardGraphsSkeleton } from './dashboard-graphs-skeleton';

export const DashboardGraphs = () => {
  const { isError, categoryTotals, isLoadingMetrics } =
    useDashboardPageContext();

  // Show skeleton while metrics are loading
  if (isLoadingMetrics) {
    return <DashboardGraphsSkeleton />;
  }

  // Show skeleton if we don't have data yet and there's no error
  if (categoryTotals === undefined && !isError) {
    return <DashboardGraphsSkeleton />;
  }

  return (
    <Stack gap={4}>
      <DashboardGraphsFilter />
      <DashboardGraphsLoader />
    </Stack>
  );
};
