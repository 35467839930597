import { LocationOn } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate, useRevalidator } from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { ampli } from '../../../../Ampli';
import { publishExperienceDraft } from '../../../../Api/Experience';
import { StatusBadge } from '../../../../Components/badge/status-badge';
import { useGetLocations } from '../../../../Hooks/data/useLocations';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { IExperience } from '../../../../Hooks/useExperience';
import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { experienceSchema } from '../../schemas';

interface ExperienceDraftSectionProps {
  experience: IExperience;
  onPublishSuccess: (experienceId: string) => void;
}

export const ExperienceDraftSection = ({
  experience,
  onPublishSuccess,
}: ExperienceDraftSectionProps) => {
  const { t } = useTranslate('experience');
  const { getLocalizedString } = useLocale();
  const navigate = useNavigate();
  const { isSm } = useResponsive();
  const { tags } = useExperienceTags();
  const { locations } = useGetLocations(experience.companyId);
  const revalidator = useRevalidator();
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const publishMutation = useMutation({
    mutationFn: () => publishExperienceDraft(experience.id),
    onSuccess: () => {
      onPublishSuccess(experience.id);
      revalidator.revalidate();
    },
  });

  const handlePublish = async () => {
    try {
      await experienceSchema.validate(experience, { abortEarly: false });
      await publishMutation.mutateAsync();
    } catch (error) {
      if (error instanceof ValidationError) {
        toast.error(t('edit.dialog.errors.generic.title'));
      } else {
        toast.error(t('generic', 'utils.error'));
      }
    } finally {
      ampli.experienceDraftSectionPublishClicked();
    }
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 0.4,
        ease: [0.4, 0, 0.2, 1],
        layout: { duration: 0.4, ease: [0.4, 0, 0.2, 1] },
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          p: 3,
          gap: 4,
          borderRadius: 3,
          bgcolor: lightTheme.palette.contrast.white,
          boxShadow: lightTheme.shadows.small,
          minHeight: 250,
          maxWidth: 800,
        }}
      >
        <img
          src={
            experience.media.primaryImage?.url ??
            '/graphics/placeholder-graphic.png'
          }
          style={{
            width: isSm ? '100%' : '40%',
            objectFit: 'cover',
            borderRadius: 16,
            opacity: 0.5,
          }}
        />
        <Stack gap={2} justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack gap={1}>
            <Box>
              <StatusBadge
                type="experience"
                state={experience.status}
                size="small"
              />
            </Box>
            <Text variant="medium" fontSize="large">
              {getLocalizedString(experience.headline)}
            </Text>
            <Text
              fontSize="small"
              color={lightTheme.palette.neutral.n300}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {getLocalizedString(experience.practicalInfo)}
            </Text>
            <Stack gap={1}>
              {experience?.locationIds && locations.data && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <LocationOn
                    sx={{
                      fontSize: 16,
                      color: lightTheme.palette.neutral.n300,
                    }}
                  />
                  <Text
                    fontSize="small"
                    color={lightTheme.palette.neutral.n300}
                  >
                    {experience.locationIds
                      .map((locationId) => {
                        const location = locations.data?.find(
                          (l) => l.locationId === locationId
                        );
                        return location?.displayName ?? location?.locationName;
                      })
                      .join(', ')}
                  </Text>
                </Stack>
              )}
              {experience.tagIds && !tags.isLoading && (
                <Stack direction="row" gap={1} flexWrap="wrap" sx={{ mt: 1 }}>
                  {experience.tagIds.map((tagId) => {
                    const tag = tags?.data?.find((t) => t.id === tagId);
                    return (
                      <Text
                        key={tagId}
                        fontSize="small"
                        style={{
                          padding: '4px 12px',
                          border: `1px solid ${lightTheme.palette.neutral.n200}`,
                          borderRadius: 16,
                          color: lightTheme.palette.contrast.black,
                        }}
                      >
                        {tag ? getLocalizedString(tag.name) : tagId}
                      </Text>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            <Button
              size="small"
              variant="secondary"
              style={{ flex: 1 }}
              onClick={() => {
                ampli.experienceDraftSectionEditClicked();
                navigate(routes.experience.details(experience.id).edit.index);
              }}
            >
              {t('draft.editDraft')}
            </Button>
            <Tooltip
              open={showTooltip}
              title={t('draft.tooltip')}
              placement="top"
              arrow
              slotProps={{
                tooltip: {
                  sx: {
                    '& .MuiTooltip-arrow': {
                      color: lightTheme.palette.action.a400,
                    },
                    backgroundColor: lightTheme.palette.action.a400,
                    p: 1.5,
                    fontSize: lightTheme.typography.small.normal.fontSize,
                  },
                },
              }}
            >
              <Button
                size="small"
                variant="primary"
                style={{ flex: 1 }}
                onClick={handlePublish}
                loading={publishMutation.isPending}
              >
                {t('edit.details.label.publish')}
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};
