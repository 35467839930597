import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';

import { ampli } from '../../Ampli';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { useTips } from './use-tips';

const STORAGE_KEYS = {
  DISMISSED_TIPS: 'understory_dismissed_tips',
  CLOSED_TIPS: 'understory_closed_tips',
} as const;

type TipType = ReturnType<typeof useTips>['navbarTips'][string];

type TipContextType = {
  showTip: boolean;
  showTipButton: boolean;
  currentTip: TipType | null;
  dismissTip: () => void;
  closeTip: () => void;
  openTip: () => void;
};

const TipContext = createContext<TipContextType>({
  showTip: false,
  showTipButton: false,
  currentTip: null,
  dismissTip: () => {},
  closeTip: () => {},
  openTip: () => {},
});

export const useTip = () => useContext(TipContext);

const useTipStorage = () => {
  const getStoredPaths = useCallback((key: keyof typeof STORAGE_KEYS) => {
    try {
      const stored = localStorage.getItem(STORAGE_KEYS[key]);
      return new Set(stored ? (JSON.parse(stored) as string[]) : []);
    } catch {
      return new Set<string>();
    }
  }, []);

  const storePaths = useCallback(
    (key: keyof typeof STORAGE_KEYS, paths: Set<string>) => {
      try {
        localStorage.setItem(
          STORAGE_KEYS[key],
          JSON.stringify(Array.from(paths))
        );
      } catch (error) {
        console.error(`Failed to save ${key}:`, error);
      }
    },
    []
  );

  const hasPath = useCallback(
    (key: keyof typeof STORAGE_KEYS, path: string) => {
      return getStoredPaths(key).has(path);
    },
    [getStoredPaths]
  );

  const addPath = useCallback(
    (key: keyof typeof STORAGE_KEYS, path: string) => {
      const paths = getStoredPaths(key);
      paths.add(path);
      storePaths(key, paths);
    },
    [getStoredPaths, storePaths]
  );

  const removePath = useCallback(
    (key: keyof typeof STORAGE_KEYS, path: string) => {
      const paths = getStoredPaths(key);
      paths.delete(path);
      storePaths(key, paths);
    },
    [getStoredPaths, storePaths]
  );

  return { addPath, removePath, hasPath };
};

export const TipProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const { onboardingCompleted } = useProfile();
  const { navbarTips, suggestionTips } = useTips();
  const storage = useTipStorage();
  const [storageVersion, setStorageVersion] = useState(0);
  const { isSm } = useResponsive();

  const [manuallyOpenedOnMobile, setManuallyOpenedOnMobile] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const fromSuggestion = searchParams.get('from') === 'suggestion';
  const pathname = location.pathname;

  const currentTip = useMemo(() => {
    if (fromSuggestion) {
      return suggestionTips[pathname] || null;
    }
    return navbarTips[pathname] || suggestionTips[pathname] || null;
  }, [pathname, fromSuggestion, navbarTips, suggestionTips]);

  useEffect(() => {
    setManuallyOpenedOnMobile(false);
  }, [pathname]);

  const tipState = useMemo(() => {
    if (storage.hasPath('DISMISSED_TIPS', pathname)) {
      return 'DISMISSED';
    }

    if (
      storage.hasPath('CLOSED_TIPS', pathname) ||
      onboardingCompleted ||
      (isSm && !manuallyOpenedOnMobile)
    ) {
      return 'CLOSED';
    }

    return 'VISIBLE';
  }, [
    pathname,
    onboardingCompleted,
    storage,
    storageVersion,
    isSm,
    manuallyOpenedOnMobile,
  ]);

  useEffect(() => {
    if (fromSuggestion) {
      window.scrollTo(0, 0);
    }
  }, [fromSuggestion]);

  // Computed states for UI
  const showTip = useMemo(() => {
    return Boolean(currentTip && tipState === 'VISIBLE');
  }, [currentTip, tipState]);

  const showTipButton = useMemo(() => {
    return Boolean(
      currentTip &&
        tipState === 'CLOSED' &&
        !storage.hasPath('DISMISSED_TIPS', pathname)
    );
  }, [currentTip, tipState, pathname, storage]);

  // Actions
  const dismissTip = useCallback(() => {
    storage.addPath('DISMISSED_TIPS', pathname);
    setStorageVersion((v) => v + 1);
    setManuallyOpenedOnMobile(false);
    ampli.tipSidebarDismissed({
      tip: pathname,
    });
  }, [pathname, storage]);

  const closeTip = useCallback(() => {
    storage.addPath('CLOSED_TIPS', pathname);
    setStorageVersion((v) => v + 1);
    setManuallyOpenedOnMobile(false);
    ampli.tipSidebarClosed({
      tip: pathname,
    });
  }, [pathname, storage]);

  const openTip = useCallback(() => {
    storage.removePath('CLOSED_TIPS', pathname);
    setStorageVersion((v) => v + 1);
    if (isSm) {
      setManuallyOpenedOnMobile(true);
    }
    ampli.tipSidebarOpened({
      tip: pathname,
    });
  }, [pathname, storage, isSm]);

  const value = useMemo(
    () => ({
      showTip,
      showTipButton,
      currentTip,
      dismissTip,
      closeTip,
      openTip,
    }),
    [showTip, showTipButton, currentTip, dismissTip, closeTip, openTip]
  );

  return <TipContext.Provider value={value}>{children}</TipContext.Provider>;
};
