import { createContext, ReactNode, useContext, useState } from 'react';

type MenuNotification = {
  menuKey: string;
  message?: string;
  timestamp: number;
  count: number;
};

type MenuNotificationContextType = {
  notifications: MenuNotification[];
  addNotification: (menuKey: string, message?: string) => void;
  clearNotification: (menuKey: string) => void;
  clearAllNotifications: () => void;
};

const MenuNotificationContext = createContext<
  MenuNotificationContextType | undefined
>(undefined);

export const MenuNotificationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [notifications, setNotifications] = useState<MenuNotification[]>([]);

  const addNotification = (menuKey: string, message?: string) => {
    setNotifications((prev) => {
      const existingNotification = prev.find((n) => n.menuKey === menuKey);
      if (existingNotification) {
        return prev.map((n) =>
          n.menuKey === menuKey
            ? { ...n, count: n.count + 1, timestamp: Date.now() }
            : n
        );
      }
      return [...prev, { menuKey, message, timestamp: Date.now(), count: 1 }];
    });
  };

  const clearNotification = (menuKey: string) => {
    setNotifications((prev) => prev.filter((n) => n.menuKey !== menuKey));
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  return (
    <MenuNotificationContext.Provider
      value={{
        notifications,
        addNotification,
        clearNotification,
        clearAllNotifications,
      }}
    >
      {children}
    </MenuNotificationContext.Provider>
  );
};

export const useMenuNotification = () => {
  const context = useContext(MenuNotificationContext);
  if (context === undefined) {
    throw new Error(
      'useMenuNotification must be used within a MenuNotificationProvider'
    );
  }
  return context;
};
