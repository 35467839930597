import { MenuItem, Stack, TextField } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../Hooks/useTranslate';

type Props = {
  locationId: string;
  locations: Location[];
  resourceTypes: ResourceType[];
};

export const ResourceTypeSelect: FC<Props> = ({
  resourceTypes,
  locations,
  locationId,
}) => {
  const { t } = useTranslate(
    'experience.edit.dialog.resourceRule.selectResourceType'
  );

  const { control, setValue, getValues } = useFormContext();

  const locationName =
    locations.find((location) => location.locationId === locationId)
      ?.locationName ?? locationId;

  // Select resource type if there is only one
  useEffect(() => {
    const fieldName = `resourceTypeIdByLocation.${locationId}`;
    if (resourceTypes.length !== 1 || !!getValues(fieldName)) return;

    setValue(fieldName, resourceTypes[0].resourceTypeId);
  }, [getValues, locationId, resourceTypes, setValue]);

  return (
    <Stack gap={2} sx={{ width: '100%' }}>
      <Text
        variant="medium"
        fontSize="small"
        color={lightTheme.palette.neutral.n500}
      >
        {locationName}
      </Text>
      <Controller
        control={control}
        name={`resourceTypeIdByLocation.${locationId}`}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            error={Boolean(error)}
            InputLabelProps={{ shrink: true }}
            label={t('label')}
            value={field.value || ''}
            select
            placeholder={t('placeholder')}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) => {
                const resourceType = resourceTypes.find(
                  ({ resourceTypeId }) => resourceTypeId === value
                );

                if (!resourceType) {
                  return (
                    <Text
                      color={lightTheme.palette.neutral.n300}
                      fontSize="small"
                    >
                      {t('placeholder')}
                    </Text>
                  );
                }

                return <>{resourceType.name}</>;
              },
            }}
          >
            {resourceTypes.map((resourceType) => (
              <MenuItem
                key={resourceType.resourceTypeId}
                value={resourceType.resourceTypeId}
              >
                {resourceType.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Stack>
  );
};
