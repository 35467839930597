import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateCompanyProfile } from '../../../Api';
import { companyProfileQuery } from '../../../Api/queries';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import { trackStorefrontLanguagesViewed } from '../../../tracking/storefront/languages-events';
import { LanguageList } from './languages-section/language-list';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const companyProfile = await client.fetchQuery(companyProfileQuery());

  return { companyProfile };
};

export type StorefrontLanguagesFormData = Pick<
  CompanyProfile,
  'defaultLanguage' | 'languages'
>;

export default function StorefrontLanguages() {
  const { t } = useTranslation();
  const { companyProfile } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const { state } = useNavigation();

  const submit = useSubmit();

  const formMethods = useForm<StorefrontLanguagesFormData>({
    defaultValues: companyProfile,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (actionData?.updatedCompanyProfile) {
      reset(actionData.updatedCompanyProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, reset]);

  const onSubmit = (data: StorefrontLanguagesFormData) => {
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(trackStorefrontLanguagesViewed), [fireOnce]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack sx={{ gap: 4 }}>
          <InputSection
            title={t('storefront.languages.title')}
            description={t('storefront.languages.description')}
          >
            <LanguageList />
          </InputSection>
        </Stack>
        <FixedActionsBar
          onReset={() => reset()}
          isSubmitting={state === 'submitting'}
          unsavedChanges={isDirty}
        />
      </Form>
    </FormProvider>
  );
}

const InputSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: ReactNode;
}) => (
  <Stack gap={3}>
    <Stack gap={1}>
      <Text variant="medium">{title}</Text>
      <Text fontSize="small">{description}</Text>
    </Stack>
    {children}
  </Stack>
);

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { defaultLanguage, languages } =
        (await request.json()) as StorefrontLanguagesFormData;

      const companyProfile = await client.fetchQuery(companyProfileQuery());

      const updatedCompanyProfile: CompanyProfile = {
        ...companyProfile,
        defaultLanguage,
        languages,
      };

      await updateCompanyProfile(updatedCompanyProfile);

      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });

      return { updatedCompanyProfile };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
