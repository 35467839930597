import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { useEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router';

import { ampli } from '../../../../Ampli';
import { resourceTypesQuery } from '../../../../Api/queries';
import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import { AppShell } from '../../../../Components/AppShell/AppShell';
import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { Page } from '../../../../Components/Page/Page';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { ResourceTypesList } from './resource-types-list';

type LoaderData = {
  resourceTypesPromise: Promise<ResourceType[]>;
};

export const loader =
  (client: QueryClient) => async (): Promise<LoaderData> => {
    const resourceTypesPromise = client.fetchQuery(resourceTypesQuery());

    return {
      resourceTypesPromise,
    };
  };

export const ResourceTypesOverview = () => {
  const { t } = useTranslate('resourceManagement.overview');
  const { resourceTypesPromise } = useLoaderData<LoaderData>();

  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => ampli.resourceManagementPageViewed());
  }, [fireOnce]);

  return (
    <AppShell>
      <Page
        sx={{
          maxWidth: '1400px',
          pr: { xs: 0, md: 2 },
          pb: { xs: 0, md: 10 },
        }}
      >
        <Stack gap={2} direction="row" justifyContent="space-between" mb={4}>
          <Text fontSize="h5">{t('title')}</Text>
          <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <RouterLinkButton
                href={routes.resourceManagement.create.index}
                variant="primary"
                leftIcon={<AddRounded fontSize="small" sx={{ mr: 1 }} />}
              >
                {t('newResourceType')}
              </RouterLinkButton>
            </Stack>
          </ShowForScope>
        </Stack>
        <ResourceTypesList resourceTypesPromise={resourceTypesPromise} />
        <Outlet />
      </Page>
    </AppShell>
  );
};
