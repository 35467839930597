import { useMemo } from 'react';

import { OnBoardingData } from '../Api/OnBoarding';
import { useOnBoarding } from './useOnBoarding';
import { useProfile } from './useProfile';

export const useHasPaymentsEnabled = (
  overrideWithInternalEmail: boolean = false
) => {
  const { onBoarding } = useOnBoarding();
  const {
    me: { data: me },
  } = useProfile();

  const hasPaymentsEnabled = useMemo(() => {
    if (overrideWithInternalEmail && isInternalEmail(me?.email)) {
      return true;
    }

    if (!onBoarding.data?.onboarding) return false;

    return Object.entries(onBoarding.data.response).some(([key, items]) => {
      if (key.startsWith('payment')) {
        return isPaymentConnected(items);
      }
      return false;
    });
  }, [onBoarding.data, me?.email, overrideWithInternalEmail]);

  return {
    hasPaymentsEnabled,
    isLoadingPayments: onBoarding.isLoading,
  };
};

const isPaymentConnected = (items: OnBoardingData[]) => {
  return items.some(
    (item) => item.key === 'setup' && item.metadata?.status === 'connected'
  );
};

const isInternalEmail = (email: string) => {
  return email.endsWith('@understory.io');
};
