import { Localized } from '@understory-io/utils-types';
import { useCallback, useRef } from 'react';

import { getTranslation } from '../../Api';

export function useAbortableTranslations(
  currentLanguage: string,
  allLanguages: string[],
  shouldTranslate: boolean,
  onChange: (translations: Localized) => void,
  toggleTranslating?: (isTranslating: boolean) => void
) {
  const abortController = useRef(new AbortController());

  const translate = useCallback(
    async (value: string) => {
      if (allLanguages.length === 1 || !shouldTranslate) {
        return;
      }

      toggleTranslating?.(true);

      abortController.current.abort();
      abortController.current = new AbortController();

      try {
        const result = await performTranslations(
          value,
          currentLanguage,
          allLanguages,
          abortController.current.signal
        );
        onChange({
          ...result,
          [currentLanguage]: value,
        });
      } catch (error) {
        if (error instanceof DOMException && error.name === 'AbortError') {
          // Ignore AbortError
        } else {
          throw error;
        }
      } finally {
        toggleTranslating?.(false);
      }
    },
    [
      allLanguages,
      shouldTranslate,
      toggleTranslating,
      currentLanguage,
      onChange,
    ]
  );

  return translate;
}

async function performTranslations(
  input: string,
  currentLanguage: string,
  languages: string[],
  signal: AbortSignal
) {
  const translations = await Promise.all(
    languages
      .filter((language) => language !== currentLanguage)
      .map(async (language) => ({
        language,
        translation: await getTranslation(
          {
            text: input,
            targetLanguageCode: language,
            sourceLanguageCode: currentLanguage,
          },
          signal
        ),
      }))
  );

  return translations.reduce(
    (finalTranslations, result) => ({
      ...finalTranslations,
      [result.language]: result.translation.text,
    }),
    {}
  );
}
