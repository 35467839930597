import * as api from '..';

const BOOKINGS_QUERY_KEY = 'bookings';
export const BOOKINGS_ALL_QUERY_KEY = [BOOKINGS_QUERY_KEY];

/* ---- Booking ----  */
export const bookingQuery = (bookingId: string) => ({
  queryKey: [BOOKINGS_QUERY_KEY, bookingId],
  queryFn: () => api.getBooking(bookingId),
  enabled: !!bookingId,
});

/* ---- Bookings ----  */
export const bookingsQuery = () => ({
  queryKey: [BOOKINGS_QUERY_KEY],
  queryFn: () => api.getBookings(),
});

/* ---- Bookings for Event ----  */
export const bookingsForEventQuery = (eventId: string) => ({
  queryKey: [BOOKINGS_QUERY_KEY, eventId],
  queryFn: () => api.getBookings(eventId),
  enabled: !!eventId,
});

/* ---- Bookings for Date ----  */
export const bookingsForDateQuery = (date: string) => ({
  queryKey: [BOOKINGS_QUERY_KEY, date],
  queryFn: async () => {
    if (!date) return [];

    return await api.getBookingsByDate(date);
  },
  enabled: !!date,
});
