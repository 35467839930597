import { AddRounded } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { Button, lightTheme, Text, TextProps } from '@understory-io/pixel';
import { ReactNode } from 'react';

import { ShowForScope } from '../Components/AllowForScope/AllowForScope';
import { Page } from '../Components/Page/Page';
import useResponsive from '../Hooks/layout/useResponsive';

interface ICrudPage {
  title: string;
  titleProps?: TextProps;
  description?: string;
  createButtonLabel?: string;
  createEventButtonLabel?: string;
  filters?: ReactNode;
  children?: ReactNode;
  contextMenu?: ReactNode;
  baseScope: string;
  handleCreate?: () => void;
  handleCreateEvent?: () => void;
  isCreatingExperience?: boolean;
}

export const CrudPage = ({
  title,
  titleProps,
  description,
  filters,
  createButtonLabel = 'Opret',
  createEventButtonLabel = 'Opret begivenhed',
  baseScope,
  children,
  contextMenu,
  handleCreate,
  handleCreateEvent,
  isCreatingExperience,
}: ICrudPage) => {
  const { isMd } = useResponsive();

  return (
    <Page
      sx={{
        maxWidth: '1400px',
        pr: { xs: 0, md: 2 },
        pb: { xs: 0, md: 10 },
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Stack>
          <Text fontSize={'h5'} {...titleProps}>
            {title}
          </Text>
          {description && (
            <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
              {description}
            </Text>
          )}
        </Stack>
        <Stack flexDirection="row" gap={0}>
          {isMd && contextMenu && contextMenu}
          {handleCreate && (
            <ShowForScope scopes={[`${baseScope}.write`]}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Stack direction={'row'} spacing={1}>
                  {filters}
                </Stack>
                {handleCreateEvent && !isMd && (
                  <Button
                    onClick={handleCreateEvent}
                    variant="secondary"
                    size="medium"
                    leftIcon={<StyledAddIcon />}
                  >
                    {createEventButtonLabel}
                  </Button>
                )}
                {isMd ? (
                  <IconButton
                    disabled={isCreatingExperience}
                    onClick={handleCreate}
                    sx={{
                      backgroundColor: lightTheme.palette.primary.p300,
                      color: lightTheme.palette.contrast.black,
                    }}
                    data-intercom-target="create-button-action"
                  >
                    {isCreatingExperience ? (
                      <CircularProgress size={16} />
                    ) : (
                      <AddRounded fontSize="small" />
                    )}
                  </IconButton>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleCreate}
                    size="medium"
                    data-intercom-target="create-button-action"
                    color="primary"
                    leftIcon={<AddRounded fontSize="small" />}
                    loading={isCreatingExperience}
                  >
                    {createButtonLabel}
                  </Button>
                )}
              </Stack>
            </ShowForScope>
          )}
        </Stack>
      </Stack>
      <Box sx={{ marginTop: 3 }}>{children}</Box>
    </Page>
  );
};

const StyledAddIcon = () => <AddRounded fontSize="small" sx={{ mr: 1 }} />;
