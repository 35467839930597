import { DoneRounded } from '@mui/icons-material';
import { Grid, GridProps } from '@mui/material';

import { CustomChip } from '../CustomChip/CustomChip';

export const LanguageChips = ({
  multiple = true,
  allowEmpty = false,
  languages = [],
  languageOptions,
  disabled,
  onChange,
  ...props
}: Omit<GridProps, 'onChange'> & {
  multiple?: boolean;
  allowEmpty?: boolean;
  languageOptions?: Array<{
    key: string;
    label: string;
    flag?: string;
  }>;
  languages?: string[];
  disabled?: boolean;
  onChange: (keys: string[]) => void;
}) => {
  const handleToggle = (key: string) => () => {
    if (multiple) {
      const _new = languages.includes(key)
        ? languages.filter((el: string) => el !== key)
        : [...languages, key];
      if (_new.length === 0 && allowEmpty) {
        return onChange([]);
      }
      if (languages.length > 0 && _new.length > 0) {
        return onChange(_new);
      }
    }
    onChange([key]);
  };
  return (
    <Grid container gap={1} {...props}>
      {languageOptions?.map(({ key, label, flag }) => {
        return (
          <Grid item key={key}>
            <CustomChip
              onClick={handleToggle(key)}
              selected={languages.includes(key)}
              disabled={disabled}
              label={label}
              icon={
                flag ? (
                  <img src={flag} width={20} height={20} alt={key} />
                ) : undefined
              }
              deleteIcon={<DoneRounded />}
              sx={{
                height: 38,
                fontSize: '0.88em',
                fontWeight: '600',
                borderRadius: 1,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
