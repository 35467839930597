import { capitalize } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ampli } from '../../../../Ampli';
import { getReceipt } from '../../../../Api';
import { formatMoney } from '../../../../Components/VariantSelect/VariantSelect';
import { getLocalizedString } from '../../../../Hooks/locales/use-locale.context';
import { TBooking } from '../../../../Hooks/useBookings';
import { IExperience, Variant } from '../../../../Hooks/useExperience';
import { BackofficeLanguage } from '../../../../i18n/config';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import {
  renderGuestTypes,
  TEventWithTitle,
} from '../../../../Utils/eventHelpers';

export const usePrintParticipants = (
  source: EventSourceType,
  language: BackofficeLanguage,
  event?: TEventWithTitle
) => {
  const { t } = useTranslation();
  const moneyFormatter = formatMoney(t, true);

  const handlePrintParticipants = async (
    bookings: TBooking[],
    experience: IExperience,
    callback?: () => void
  ) => {
    ampli.eventButtonClicked({
      action: 'printParticipants',
      location: source,
    });

    const receiptIds = bookings
      .map(({ receiptId }) => receiptId)
      .filter(Boolean);

    const receipts = await Promise.all(
      receiptIds.map((receiptId) =>
        receiptId
          ? getReceipt(receiptId)
              .then((receipt) => receipt)
              .catch(() => null)
          : null
      )
    );

    const receiptPriceMap = receipts.reduce<Record<string, string>>(
      (receipts, receipt) => {
        if (!receipt) return receipts;

        if (!receipt.id) return receipts;

        const amountPaidCents =
          receipt.financials.totalVatInclusiveCents - receipt.amountDueCents;

        return {
          ...receipts,
          [receipt.id]: moneyFormatter({
            value: amountPaidCents / 100,
            nativeCurrency: receipt.financials.currency,
          }),
        };
      },
      {}
    );

    if (bookings) {
      import('xlsx').then(({ utils, writeFileXLSX }) => {
        const ws = utils.json_to_sheet(
          prepareSheet(
            bookings,
            language,
            receiptPriceMap,
            experience?.price?.variants
          )
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, `ParticipantList_${event?.id}.xlsx`);
      });
    }

    callback?.();
  };

  const isDisabled = event?.slots?.booked === 0;

  return {
    handlePrintParticipants,
    isDisabled,
  };
};

const prepareSheet = (
  bookings: TBooking[],
  language: BackofficeLanguage,
  receiptPriceMap: Record<string, string>,
  variants?: Variant[]
) => {
  const addons = variants?.flatMap(({ addons }) => addons ?? []) ?? [];

  return bookings
    .filter((x) => x.status !== 'cancelled' && x.status !== 'moved')
    .reduce<TBooking[]>((acc, el) => {
      return [
        ...acc,
        ...Object.entries(el.items ?? {})
          .filter(([id]) => id.includes('variant'))
          .reduce<any[]>((all, [, count], index) => {
            return [
              ...all,
              ...Array.from(Array(count).keys()).map((__, i) => {
                return {
                  Time: el.startDateTime,
                  Name: el.customer.name,
                  Phone: el.customer.phone,
                  Email: el.customer.email,
                  Status: capitalize(el.status),
                  Reference: el.id,
                  Variants:
                    index === 0 && i === 0
                      ? renderGuestTypes(
                          el,
                          'variant',
                          undefined,
                          variants
                        ).join(', ')
                      : '-',
                  ...(index === 0 && i === 0 ? addons : []).reduce(
                    (acc, addon) => {
                      const addonName = getLocalizedString(
                        addon.name,
                        [language],
                        'Unknown addon(s)'
                      );

                      const addonId = `addon/${addon.id}`;
                      const count = el.items?.[addonId] ?? 0;

                      return {
                        ...acc,
                        [addonName]: (acc[addonName] ?? 0) + count,
                      };
                    },
                    {} as Record<string, number>
                  ),
                  Paid:
                    el.receiptId && index === 0 && i === 0
                      ? receiptPriceMap[el.receiptId] ?? ''
                      : '-',
                  Note: el.internalNote,
                  Source: capitalize(el.source ?? 'checkout'),
                  ...(el.informationRequests?.bookingRequests
                    ? el.informationRequests.bookingRequests
                        .sort((a, b) =>
                          getLocalizedString(a.request, [
                            language,
                          ]).localeCompare(
                            getLocalizedString(b.request, [language])
                          )
                        )
                        .reduce((acc, bookingRequest) => {
                          const request = getLocalizedString(
                            bookingRequest.request,
                            [language]
                          );

                          // Booking requests are only filled out once, so
                          // we only add the answer to the first ticket
                          const answer =
                            index === 0 && i === 0
                              ? bookingRequest.answer
                              : '-';

                          return {
                            ...acc,
                            [request]: answer,
                          };
                        }, {})
                    : {}),
                  ...(el.informationRequests?.ticketRequests
                    ? el.informationRequests.ticketRequests[i].requests
                        .sort((a, b) =>
                          getLocalizedString(a.request, [
                            language,
                          ]).localeCompare(
                            getLocalizedString(b.request, [language])
                          )
                        )
                        .reduce((acc, ticketEntry) => {
                          const request = getLocalizedString(
                            ticketEntry.request,
                            [language]
                          );

                          const answer = ticketEntry.answer || '-';

                          return {
                            ...acc,
                            [request]: answer,
                          };
                        }, {})
                    : {}),
                  // TODO: Deprecate customDataInputs in favor of informationRequests
                  ...(el.customDataInputs
                    ? el.customDataInputs.reduce((acc, i) => {
                        const sorted = i.inputs
                          .filter(
                            (input): input is NonNullable<typeof input> =>
                              !!input
                          )
                          .sort((a, b) => a?.name.localeCompare(b?.name));

                        return {
                          ...acc,
                          ...sorted.reduce((acc, i) => {
                            return {
                              ...acc,
                              [i.name]: i.value,
                            };
                          }, {}),
                        };
                      }, {})
                    : {}),
                };
              }),
            ];
          }, []),
      ];
    }, []);
};
