import { Skeleton } from '@mui/material';
import {
  EconomicConfiguration,
  VatRegistration,
} from '@understory-io/utils-types';
import { FC } from 'react';

import { SERIALIZED_PREFIX, SERIALIZED_SYMBOL } from '../../../Api';
import { EconomicDetailsTableRow } from './economic-details-table-row';

type Props = {
  configuration: EconomicConfiguration;
  vatRegistrations: VatRegistration[];
};

export const EconomicDetailsVatCodes: FC<Props> = ({
  configuration,
  vatRegistrations,
}) => {
  const countryVatCodes = Array.from(
    new Set(
      vatRegistrations
        .flatMap(({ rates }) => rates)
        .flatMap(({ reduced, superReduced, standard }) =>
          [standard, superReduced ?? []]
            .concat(
              reduced.flatMap((reduce) => Object.values(reduce)[0]?.rate ?? [])
            )
            .flat()
        )
    )
  ).sort();

  if (!countryVatCodes.length) {
    return <Skeleton width={100} />;
  }

  return (
    <>
      {countryVatCodes.map((vatRate) => (
        <EconomicDetailsTableRow
          key={vatRate}
          title={`${vatRate * 100}%`}
          value={
            configuration.vatCodes?.[
              `${SERIALIZED_PREFIX}${vatRate.toString().replaceAll('.', SERIALIZED_SYMBOL)}`
            ]
          }
        />
      ))}
    </>
  );
};
