import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useCallback } from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { NoteBox } from '../../../../../Components/note-box';
import { TimeSpanPicker } from '../../../../../Components/TimeSpanPicker/timeSpanPicker';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useHistory } from '../../../../../Hooks/useHistory';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import routes from '../../../../../Utils/routes';
import { useUpdateResourceIsEdit } from '../update-resource-type-context';
import { UpdateResourceTypeInputs } from '../update-resource-type-inputs';

const TIMESPAN_PICKER_WIDTH = '300px';

type Inputs = Pick<UpdateResourceTypeInputs, 'recoverTimeInSeconds'>;

export const UpdateResourceTypeRecoverTimeDialog = () => {
  const { t } = useTranslate('resourceManagement.resourceType');
  const { isSm } = useResponsive();
  const { goBack } = useHistory();

  const handleClose = useCallback(() => {
    goBack(routes.resourceManagement.index);
  }, [goBack]);

  const { getValues: getOuterValues, setValue: setOuterValues } =
    useFormContext<UpdateResourceTypeInputs>();

  const initialSeconds = getOuterValues('recoverTimeInSeconds');

  const formMethods = useForm<Inputs>({
    defaultValues: {
      recoverTimeInSeconds: initialSeconds,
    },
  });

  const { handleSubmit, setValue } = formMethods;

  const onSubmit = useCallback(
    (data: Inputs) => {
      setOuterValues('recoverTimeInSeconds', data.recoverTimeInSeconds);
      handleClose();
    },
    [handleClose, setOuterValues]
  );

  const isEdit = useUpdateResourceIsEdit();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('createNew.title')}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent sx={{ padding: 4 }}>
        <Stack gap={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Text fontSize="xlarge" variant="medium">
              {t('followUpTime')}
            </Text>
            <IconButton sx={{ padding: 0 }} onClick={handleClose} tabIndex={-1}>
              <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
            </IconButton>
          </Stack>
          <Text color={lightTheme.palette.neutral.n400} fontSize="small">
            {t('followUpTimeDescription')}
          </Text>
          <TimeSpanPicker
            maxWidth={TIMESPAN_PICKER_WIDTH}
            mt={2}
            mb={2}
            size="small"
            initialValueSeconds={initialSeconds ?? 0}
            onChange={(seconds) => {
              setValue('recoverTimeInSeconds', seconds);
            }}
          />

          {isEdit && (
            <NoteBox note={t('edit.impactDisclaimer.followUpTime')} mb={2} />
          )}

          <Button type="submit" variant="primary" size="large">
            {t('save', 'fullPageForm.generic')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
