import { Stack, StackProps, SvgIconProps } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ElementType } from 'react';
import { Link } from 'react-router';

import { mergeSx } from '../../../../Utils/merge-sx';

type FullPageMoreOptionsItemProps = StackProps<
  typeof Link,
  {
    Icon: ElementType<SvgIconProps>;
    title: string;
    description: string;
  }
>;

export const FullPageMoreOptionsItem = ({
  Icon,
  title,
  description,
  sx,
  ...rest
}: FullPageMoreOptionsItemProps) => {
  return (
    <Stack
      component={Link}
      sx={mergeSx(
        {
          minHeight: 100,
          justifyContent: 'space-between',
          cursor: 'pointer',
          backgroundColor: lightTheme.palette.contrast.surface1,
          borderRadius: 2,
          padding: 2,
          gap: 1,
          transition: 'background-color 0.1s',
          ':hover': {
            backgroundColor: lightTheme.palette.neutral.n100,
          },
        },
        sx
      )}
      {...rest}
    >
      <Icon sx={{ color: lightTheme.palette.neutral.n400 }} />
      <Stack sx={{ gap: 0.25 }}>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {title}
        </Text>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};
