import { UserProfile } from '@understory-io/utils-types';
import axios, { AxiosError } from 'axios';

import { apiServices } from './apiServices';
import api, { authApi, IShallowSuccessResponse } from './index';

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  orgs: {
    id: string;
    name: string;
    logo?: string;
  }[];
}

export const signIn = async (username: string, password: string) => {
  const url = apiServices.accounts;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  try {
    const response = await authApi.post<TokenResponse>(`${url}/oauth/token`, {
      username,
      password,
      grant: 'password',
    });

    return response.data;
  } catch (err) {
    throw getApiError(err, (axiosError) => {
      const serverError = axiosError.response?.data.message;
      if (!serverError) {
        throw new Error('cannotLogin');
      }

      throw new Error(serverError);
    });
  }
};

export const refreshToken = async (refreshToken: string, org?: string) => {
  const url = apiServices.accounts;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.post<TokenResponse>(`${url}/oauth/token`, {
    refresh_token: refreshToken,
    org,
    grant: 'refresh_token',
  });

  return data;
};

export const createAccount = async (email: string, password: string) => {
  try {
    const url = apiServices.accounts;

    if (typeof url === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const response = await authApi.post<TokenResponse>(`${url}/users`, {
      password,
      claims: { email },
    });

    return response.data;
  } catch (err: unknown) {
    throw getAuthError(err);
  }
};

/**
 * Add account to company. This only works for preview companies.
 * @param userId
 * @param companyId
 * @param role
 * @param name
 * @returns
 */
export const addAccountToPreviewCompany = async (
  userId: string,
  companyId: string,
  role: 'admin' | 'manager' | 'guide',
  name: string = ''
) => {
  try {
    const url = apiServices.accounts;

    if (typeof url !== 'string') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const signupType: NonNullable<UserProfile['metadata']>['signupType'] =
      'AI-signup';

    const response = await api.post<TokenResponse>(
      `${url}/companies/${companyId}/users`,
      {
        userId,
        role,
        name,
        signupType,
      }
    );

    return response.data;
  } catch (err: unknown) {
    throw getAuthError(err);
  }
};

export const userExists = async (email: string, invitationId: string) => {
  const url = apiServices.accounts;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { status } = await authApi.get(
    `${url}/users?email=${email}&invitationId=${invitationId}`
  );

  return status === 204;
};

export const acceptInvitation = async (
  email: string,
  invitationId: string,
  password?: string
) => {
  try {
    const url = apiServices.accounts;

    if (typeof url === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const { data } = await authApi.post<TokenResponse>(
      `${url}/invitations/${invitationId}/accept`,
      {
        password,
        claims: { email },
      }
    );

    return data;
  } catch (err) {
    throw getAuthError(err);
  }
};

export const resetPassword = async (email: string, language: string = 'da') => {
  const url = apiServices.accounts;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.post(`${url}/reset`, {
    username: email,
    language,
  });

  return data;
};

export const updateResetPassword = async (
  email: string,
  password: string,
  id: string,
  token: string
) => {
  const url = apiServices.accounts;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.put<TokenResponse>(
    `${url}/users/${id}`,
    {
      password,
      claims: { email },
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

function getAuthError(error: unknown): Error {
  return getApiError(error, (error) => {
    const authError = error.response?.data?.error;
    if (authError) {
      return new Error(authError);
    }
  });
}

/**
 * Handles API error
 * onAxiosError callback can be used to handle AxiosError
 */
function getApiError(
  error: unknown,
  onAxiosError: (error: AxiosError) => Error | undefined
): Error {
  return (
    (axios.isAxiosError(error) && onAxiosError(error)) ||
    new Error('SERVER_ERROR')
  );
}
