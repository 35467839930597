import { Divider, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getTaxRateLabel, getVatRatesAsArray } from '../../../../Utils/tax/tax';
import { useIntegrations } from '../../../SettingsSections/Integrations/domain/use-integrations';
import FeatureSelect from '../inputs/FeatureSelect';

const FEATURE_ID = 'passOnBookingFee';

export const PassOnBookingFeeFeature = () => {
  const { t } = useTranslate('settings.features');
  const {
    company: { data: company },
  } = useProfile();
  const flags = useFlags();

  const configuredVatRate =
    company?.features.passOnBookingFee?.params?.feeVatCategory;

  const isDisabled = !useAllowedIntegrations();

  const showVat = (company?.vatCompliance?.vatRegistrations?.length ?? 0) > 0;

  const trackToggling = (enabled: boolean) => {
    if (enabled) {
      ampli.passOnBookingFeeToggledOn({ feature_enabled: 'passOnBookingFee' });
    } else {
      ampli.passOnBookingFeeToggledOff();
    }
  };
  const trackOpening = () => {
    ampli.passOnBookingFeeVatDropdownOpened();
  };
  const trackApplied = (e: SelectChangeEvent<unknown>) => {
    ampli.passOnBookingFeeVatDropdownApplied({
      // set a synthetic default value to represent the company default select option within our analytics
      pass_on_vat_category: (e.target.value as string) || 'company-default',
    });
  };

  if (!flags.featurePassOnBookingFee) {
    return null;
  }

  const vatRegistration = company?.vatCompliance?.vatRegistrations[0];

  return (
    <FeatureItem
      disabled={isDisabled}
      feature={FEATURE_ID}
      onToggle={trackToggling}
    >
      {showVat && (
        <FeatureSelect
          name="feeVatCategory"
          disabled={isDisabled}
          defaultValue={configuredVatRate}
          displayEmpty
          labelProps={{ shrink: true }}
          onOpen={trackOpening}
          onChange={trackApplied}
        >
          <MenuItem value="">
            {t('passOnBookingFee.feeVatCategory.companyDefaultLabel')}
          </MenuItem>
          <Divider variant="middle" />
          {getVatRatesAsArray(vatRegistration?.rates).map((rate) => {
            const { label, rateLabel } = getTaxRateLabel(
              vatRegistration?.country,
              rate,
              t
            );
            return (
              <MenuItem key={rate.category} value={rate.category}>
                {label} ({rateLabel})
              </MenuItem>
            );
          })}
        </FeatureSelect>
      )}
    </FeatureItem>
  );
};

// we only allow the feature if a single payment provider is active and it is
// stripe. This array should be updated if we add more payment providers.
const paymentIntegrationIds = ['stripe', 'quickpay', 'paypal'];

const useAllowedIntegrations = () => {
  const integrations = useIntegrations();

  const paymentProviders = integrations.activeIntegrations.filter(
    (integration) => paymentIntegrationIds.includes(integration.id)
  );

  const isUsingAllowedProvidersOnly =
    paymentProviders.length > 0 &&
    paymentProviders.every(
      (provider) => provider.id === 'stripe' || provider.id === 'paypal'
    );

  // we do not allow this feature if economic is enabled
  const hasEconomicEnabled = integrations.activeIntegrations.some(
    (integration) => integration.id === 'economic'
  );

  return isUsingAllowedProvidersOnly && !hasEconomicEnabled;
};
