import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import * as api from '../../../../../Api';
import { domainQueryKey } from '../../../../../Api/queries/domains';
import { CopyLink } from '../../../../../Components/CopyLink/CopyLink';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
export function SeoSitemap() {
  const {
    company: { data: company },
  } = useProfile();
  const { t } = useTranslate('storefront.bookingflow.seo.sitemap');

  const { data: companyDomain } = useQuery({
    queryKey: domainQueryKey(company?.id),
    queryFn: () => api.getCompanyDomain(company!.id!),
    enabled: !!company?.id,
  });

  if (!companyDomain) return <Skeleton sx={{ width: 184 }} />;

  return (
    <CopyLink
      href={new URL(
        `/sitemap.txt`,
        `https://${companyDomain.domain}`
      ).toString()}
      label={t('label')}
    />
  );
}
