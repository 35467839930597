import { MotionProps } from 'framer-motion';

import routes from '../../Utils/routes';

export interface OnboardingOption {
  id: string;
  route: string;
  recommended?: boolean;
}

export const ONBOARDING_OPTIONS: OnboardingOption[] = [
  {
    id: 'create',
    route: routes.experience.overview,
    recommended: true,
  },
  {
    id: 'explore',
    route: routes.dashboard,
  },
  {
    id: 'help',
    route: routes.onboarding.help,
  },
];

export const STEP_ORDER = ['uses', 'pricing', 'decision'] as const;

export const ANIMATIONS = {
  title: {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6, ease: 'easeOut' } },
    exit: { opacity: 0, transition: { duration: 0.3 } },
  } as MotionProps['variants'],
  description: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.6, delay: 0.2, ease: 'easeOut' },
    },
    exit: { opacity: 0, transition: { duration: 0.3 } },
  } as MotionProps['variants'],
  content: {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6, delay: 0.2 },
  },
};

export const STYLES = {
  buttons: {
    primary: {
      mobile: {
        width: '100%',
        padding: 'unset',
      },
      desktop: {
        width: 'auto',
        padding: '0px 60px',
      },
    },
    back: {
      mobile: {
        variant: 'secondary' as const,
        width: '100%',
        color: 'unset',
      },
      desktop: {
        variant: 'text' as const,
        width: 'auto',
        color: 'action.a300',
      },
    },
  },
};

export const INTENDED_USES = [
  'getMoreBookings',
  'createGreatExperiences',
  'runAdsToGrowMyBusiness',
  'avoidOverBookings',
  'manageEquipment',
  'manageMyTeam',
  'getSafeCheckoutSolution',
];

export const PRICING = {
  price: 0,
  fee: 3.5,
  features: [
    'unlimitedBookings',
    'safeCheckout',
    'teamManagement',
    'seamlessIntegration',
    'muchMore',
  ],
};
