import { API } from '@understory-io/resource-management-types';
import { FC } from 'react';

import { useGroupedResourceReservations } from '../../../Hooks/use-grouped-resource-reservations';
import { useResourceTypes } from '../../resource-management/data/use-resource-types';

type Props = {
  reservations: API.GetReservations.Response['items'];
};

export const ResourceReservationsDetails: FC<Props> = ({ reservations }) => {
  const resourceTypes = useResourceTypes();
  const groupedReservations = useGroupedResourceReservations(reservations);

  return (
    <>
      {Object.entries(groupedReservations).map(([resourceTypeId, quantity]) => {
        const resourceType = resourceTypes.data?.find(
          ({ resourceTypeId: id }) => id === resourceTypeId
        );

        if (!resourceType) return null;

        return (
          <div key={resourceTypeId}>
            {quantity}x {resourceType.name}
          </div>
        );
      })}
    </>
  );
};
