import { CheckRounded } from '@mui/icons-material';
import { Box, Link, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { Tip } from './use-tips';

interface TipContentProps {
  currentTip: Tip;
  showImage?: boolean;
  showTitle?: boolean;
}

export const TipContent = ({
  currentTip,
  showImage = false,
  showTitle = false,
}: TipContentProps) => {
  return (
    <Stack gap={4}>
      {showImage && currentTip.image && (
        <Box
          component={'img'}
          src={currentTip.image}
          alt={currentTip.title}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderRadius: '14px',
          }}
        />
      )}
      {showTitle && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Stack gap={1}>
            <Text variant="medium" fontSize="large">
              {currentTip.title}
            </Text>
            <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
              {currentTip.description}
            </Text>
          </Stack>
        </Stack>
      )}
      <Stack gap={2}>
        {currentTip.stepTitle && (
          <Text variant="medium" fontSize="medium">
            {currentTip.stepTitle}
          </Text>
        )}
        {currentTip.steps.map((step, index) => (
          <Stack direction="row" gap={1.5} key={index}>
            {step.icon ? (
              <Box
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  backgroundColor: lightTheme.palette.primary.p300,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
              >
                <step.icon sx={{ fontSize: '16px', color: '#022C12' }} />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  backgroundColor: lightTheme.palette.primary.p300,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
              >
                <CheckRounded sx={{ fontSize: '16px', color: '#022C12' }} />
              </Box>
            )}
            <Stack gap={1}>
              {step.title && (
                <Text variant="medium" fontSize="small">
                  {step.title}
                </Text>
              )}
              <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
                {step.description}
              </Text>
              {step.resources && (
                <Stack gap={1}>
                  {step.resources.map((resource, index) => (
                    <Link
                      key={index}
                      fontWeight="medium"
                      fontSize="small"
                      href={resource.url}
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {resource.title}
                    </Link>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {currentTip.resources && (
        <Stack gap={2}>
          <Text variant="medium" fontSize="medium">
            {currentTip.resources.title}
          </Text>
          <Stack gap={1.5}>
            {currentTip.resources.list.map((resource, index) => (
              <Link
                key={index}
                fontSize="small"
                href={resource.url}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
                onClick={resource.onClick}
              >
                {resource.title}
              </Link>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
