import { CompanyProfile } from '@understory-io/utils-types';

import { ampli } from '../../../../Ampli';
import {
  AdvertisementPlan,
  StepKey,
} from './flow/steps/grow-advertisement-flow-steps';

export const EXPECTED_ROAS = 4.12;

export const SERVICE_FEE_PRICE_MAP = {
  DKK: 1499,
  EUR: 199,
  SEK: 2399,
  NOK: 2399,
  GBP: 159,
  USD: 199,
};

export const BUDGET_CURRENCY_MAP: Record<
  CompanyProfile['defaultCurrency'],
  {
    min: number;
    default: number;
  }
> = {
  DKK: {
    min: 3500,
    default: 5000,
  },
  EUR: {
    min: 470,
    default: 700,
  },
  SEK: {
    min: 5400,
    default: 7500,
  },
  NOK: {
    min: 5500,
    default: 7800,
  },
  GBP: {
    min: 400,
    default: 600,
  },
  USD: {
    min: 515,
    default: 700,
  },
};

export const DEFAULT_PLAN: AdvertisementPlan = {
  budget: 0,
  goals: [],
  usps: [],
  experiences: [],
  state: 'active',
  companyId: '',
  companyName: '',
  currency: '',
  id: '',
  adPlanId: 0,
};

export const STEP_COMPLETION_EVENTS: Record<StepKey, () => void> = {
  start: () => ampli.growAdvertisementGetStartedStartCompleted(),
  goals: () => ampli.growAdvertisementGetStartedGoalCompleted(),
  budget: () => ampli.growAdvertisementGetStartedBudgetCompleted(),
  review: () => ampli.growAdvertisementGetStartedReviewCompleted(),
  date: () => ampli.growAdvertisementGetStartedDateCompleted(),
  meeting: () => ampli.growAdvertisementGetStartedMeetingCompleted(),
};
