import { Grid, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { ExperienceStatus } from '@understory-io/utils-types';
import { AxiosError } from 'axios';
import randomBytes from 'randombytes';
import { Suspense } from 'react';
import {
  ActionFunctionArgs,
  Await,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useRouteError,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateExperienceMetaData } from '../../../Api/Experience';
import {
  companyDomainQuery,
  companyUsersQuery,
  connectionsQuery,
  eventsInExperienceQuery,
  experienceLocationsQuery,
  experienceQuery,
  experienceWaitlistQuery,
  publicCompanyProfileQuery,
} from '../../../Api/queries';
import { AppShell } from '../../../Components/AppShell/AppShell';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsExperience } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { t } from '../../../i18n/config';
import { ErrorPage } from '../../../Pages/error-page';
import { trackExperienceDetailsPageOpened } from '../../../tracking/experiences/details/card/trackExperienceDetailsPageOpened';
import { isEventState, isEventStatus } from '../../../Utils/eventHelpers';
import { getDistributorNames } from '../utils/experience-details-helpers';
import { isSharedExperience } from '../utils/is-shared-experience';
import { EventListCard } from './event-list/event-list-card';
import { EventListFormInput } from './event-list/event-list-form';
import { getExperienceActions } from './experience-details-actions';
import {
  ExperienceDetails,
  ExperienceDetailsGrid,
  ExperienceOtherDetails,
} from './experience-details-grid';
import { ExperienceDetailsHeader } from './experience-details-header';

export type User = {
  id: string;
  name?: string;
  email?: string;
};

const DEFAULT_FILTER: EventListFormInput = {
  state: 'future',
  status: 'anyStatus',
  page: 1,
};

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader =
  (client: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const id = params.id;
    if (!id) throw new Response('Invalid id', { status: 404 });

    const url = new URL(request.url);
    const searchParams = url.searchParams;
    const locationIdParam = searchParams.get('locationId');
    const stateParam = searchParams.get('state');
    const statusParam = searchParams.get('status');
    const pageParam = searchParams.get('page');
    const pageNumber = pageParam ? parseInt(pageParam, 10) : null;

    const eventFilters: EventListFormInput = {
      state:
        stateParam && isEventState(stateParam)
          ? stateParam
          : DEFAULT_FILTER['state'],
      status:
        statusParam && isEventStatus(statusParam)
          ? statusParam
          : DEFAULT_FILTER['status'],
      page: pageNumber ? Math.max(pageNumber, 1) : DEFAULT_FILTER['page'],
      ...(locationIdParam && { locationId: locationIdParam }),
    };

    // Critical data promises
    const experience = await client.fetchQuery(experienceQuery(id));
    const isShared = isSharedExperience(experience);

    const hasPublishedExperience = experience.status !== 'draft';

    // Tracking
    trackExperienceDetailsPageOpened(experience);

    // Non-critical data promises
    const waitListPromise = client.fetchQuery(experienceWaitlistQuery(id));
    const sharedExperienceOwnerProfilePromise = isShared
      ? client.fetchQuery(publicCompanyProfileQuery(experience.ownerCompanyId))
      : null;
    const storefrontDetailsDomainPromise = client.fetchQuery(
      companyDomainQuery(experience.companyId)
    );
    const storefrontBookingFlowDomainPromise = client.fetchQuery(
      isShared
        ? companyDomainQuery(experience.ownerCompanyId)
        : companyDomainQuery(experience.companyId)
    );
    const distributorNamesPromise = client
      .fetchQuery(connectionsQuery(experience.companyId))
      .then((connections) => getDistributorNames(connections, id));
    const upcomingEventsPromise = client.fetchQuery(
      eventsInExperienceQuery(id, {
        limit: 0,
        page: 1,
        state: 'future',
        status: 'anyStatus',
      })
    );
    const locationsPromise = client.fetchQuery(
      experienceLocationsQuery(experience.ownerExperienceId)
    );
    const eventsPromise = client.fetchQuery(
      eventsInExperienceQuery(id, eventFilters)
    );
    const usersPromise = client.fetchQuery(companyUsersQuery());

    return {
      experienceId: id,
      experience,
      eventFilters,
      isShared,
      experienceActions: getExperienceActions(experience, isShared),
      waitListPromise,
      sharedExperienceOwnerProfilePromise,
      storefrontDetailsDomainPromise,
      storefrontBookingFlowDomainPromise,
      distributorNamesPromise,
      upcomingEventsPromise,
      locationsPromise,
      eventsPromise,
      usersPromise,
      hasPublishedExperience,
    };
  };

export const ExperienceDetailsPage = () => {
  const {
    eventFilters,
    experience,
    experienceId,
    experienceActions,
    sharedExperienceOwnerProfilePromise,
    waitListPromise,
    distributorNamesPromise,
    upcomingEventsPromise,
    eventsPromise,
    usersPromise,
    locationsPromise,
    hasPublishedExperience,
    storefrontDetailsDomainPromise,
    storefrontBookingFlowDomainPromise,
  } = useLoaderData() as LoaderData;

  const { getLocalizedString } = useLocale();

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
        <PageBreadcrumbBreadcrumbsExperienceDetails
          experienceId={experienceId}
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack width="100%" maxWidth={1200} minWidth={350} gap={2}>
          {/* Suspense is used to show the header while the company profile is loading */}
          <Suspense
            fallback={
              <ExperienceDetailsHeader
                title={getLocalizedString(experience.headline)}
                subtitle={t(
                  `experience.details.header.subtitle.${!hasPublishedExperience ? 'draft' : experience.visibility}`
                )}
                status={experience.status}
                actions={experienceActions}
                experienceId={experienceId}
                experience={experience}
                hasPublishedExperience={hasPublishedExperience}
              />
            }
          >
            <Await resolve={sharedExperienceOwnerProfilePromise}>
              {(profile) => {
                const subtitle = profile
                  ? t('experience.details.header.subtitle.sharedBy', {
                      companyName: profile?.name,
                    })
                  : t(
                      `experience.details.header.subtitle.${!hasPublishedExperience ? 'draft' : experience.visibility}`
                    );

                return (
                  <ExperienceDetailsHeader
                    title={getLocalizedString(experience.headline)}
                    subtitle={subtitle}
                    status={experience.status}
                    actions={experienceActions}
                    experienceId={experienceId}
                    experience={experience}
                    hasPublishedExperience={hasPublishedExperience}
                  />
                );
              }}
            </Await>
          </Suspense>
          <Stack gap={2}>
            <Grid
              container
              columns={{ xs: 1, sm: 2 }}
              columnSpacing={2}
              rowSpacing={1.5}
            >
              <ExperienceDetailsGrid
                label={t('experience.details.section.label.details')}
              >
                <ExperienceDetails
                  experience={experience}
                  upcomingEventsPromise={upcomingEventsPromise}
                  waitListPromise={waitListPromise}
                  sharedExperienceOwnerProfilePromise={
                    sharedExperienceOwnerProfilePromise
                  }
                  distributorNamesPromise={distributorNamesPromise}
                />
              </ExperienceDetailsGrid>
              <ExperienceDetailsGrid
                label={t('experience.details.section.label.otherDetails')}
              >
                <ExperienceOtherDetails
                  experience={experience}
                  storefrontDetailsDomainPromise={
                    storefrontDetailsDomainPromise
                  }
                  storefrontBookingFlowDomainPromise={
                    storefrontBookingFlowDomainPromise
                  }
                />
              </ExperienceDetailsGrid>
            </Grid>
            <EventListCard
              key={JSON.stringify(eventFilters)}
              eventFilters={eventFilters}
              experience={experience}
              eventsPromise={eventsPromise}
              usersPromise={usersPromise}
              locationsPromise={locationsPromise}
              hasPublishedExperience={hasPublishedExperience}
            />
          </Stack>
        </Stack>
      </PageBreadcrumb>
    </>
  );
};

export const ExperienceDetailsErrorPage = () => {
  const error = useRouteError();

  const isAxiosError = (error as AxiosError).isAxiosError;

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <ErrorPage
          resource="experience"
          error={
            isAxiosError
              ? (error as AxiosError)
              : ({ request: { status: 0 } } as AxiosError)
          }
        />
      </PageBreadcrumb>
    </AppShell>
  );
};

export async function updateStatusLoader({ params }: LoaderFunctionArgs) {
  const id = params.id;

  if (!id) {
    return redirect('/experiences');
  }

  return redirect(`/experience/${id}`);
}

export async function updateStatusAction({
  request,
  params,
}: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('experience.details.updateStatus.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const id = params.id;
    if (!id) throw new Error('Missing id');

    const formData = await request.formData();
    const action = formData.get('action');
    if (!action) throw new Error('Missing action');
    if (action !== 'activate' && action !== 'deactivate') {
      throw new Error('Invalid action');
    }

    const newStatus: ExperienceStatus =
      action === 'activate' ? 'active' : 'inactive';

    await updateExperienceMetaData(id, { status: newStatus });

    toast.dismiss(loadingToastId);
    toast.success(t('experience.details.updateStatus.toast.success'), {
      delay: 500,
      autoClose: 5000,
    });

    const url = new URL(request.url);

    return redirect(`/experience/${id}${url.search}`);
  } catch (error) {
    captureException(error);
    toast.dismiss(loadingToastId);
    toast.error(t('experience.details.updateStatus.toast.error'), {
      delay: 500,
    });
    const url = new URL(request.url);

    return redirect(url.toString().replace('/update-status', ''));
  }
}
