import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackStorefrontCustomizeStylingFontOpened,
  trackStorefrontCustomizeStylingFontSelected,
} from '../../../../tracking/storefront/customize-events';
import { CustomizeStorefrontFormData } from '../customize-storefront';
import { AVAILABLE_FONTS } from './storefront-styling';

export const FontFamilySelect = () => {
  const { t } = useTranslate('storefront.customize');

  const {
    control,
    formState: { errors },
  } = useFormContext<CustomizeStorefrontFormData>();

  return (
    <Controller
      name="customization.fontFamily"
      defaultValue={AVAILABLE_FONTS[0]}
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined">
          <InputLabel id="font-select-label">{t('font')}</InputLabel>
          <Select
            {...field}
            label={t('font')}
            labelId="font-select-label"
            error={!!errors.customization?.fontFamily}
            onOpen={trackStorefrontCustomizeStylingFontOpened}
            onChange={(e) => {
              field.onChange(e.target.value);
              trackStorefrontCustomizeStylingFontSelected(e.target.value);
            }}
            sx={{ maxHeight: 48 }}
          >
            {AVAILABLE_FONTS.map((font) => (
              <MenuItem key={font} value={font}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
