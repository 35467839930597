import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../Hooks/useTranslate';

export const NoExperienceTitle = () => {
  const { t } = useTranslate('experience.list.item');

  return (
    <Text
      style={{
        fontStyle: 'italic',
        fontSize: 'inherit',
        color: lightTheme.palette.neutral.n300,
      }}
    >
      {t('noTitle')}
    </Text>
  );
};

export const DesktopCard = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr));',
  gap: '8px',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  boxShadow: 'none',
  padding: '8px 16px 8px 16px',
  alignItems: 'center',
  justifyItems: 'center',
  overflow: 'hidden',
  background: lightTheme.palette.contrast.white,
  transition: 'background 0.1s ease-in-out',
  '& > span': {
    maxWidth: '100%',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  cursor: 'pointer',
  '&:hover': {
    background: lightTheme.palette.neutral.n100,
  },
});

export const MobileCard = styled(Stack)({
  flexDirection: 'row',
  background: lightTheme.palette.contrast.white,
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  overflow: 'hidden',
  cursor: 'pointer',
  height: '100%',
});
