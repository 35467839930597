import { AddOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, Stack, styled, SxProps } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { KeyboardEvent, PropsWithChildren, ReactNode } from 'react';

import { Tooltip } from '../tooltip/tooltip';

type DetailItemProps = PropsWithChildren & {
  title: string;
  onClick: () => void;
  selectedValue?: ReactNode;
  tooltip?: string;
  showAddButton?: boolean;
  hasError?: boolean;
  sx?: SxProps;
};

const DEFAULT_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.contrast.white,
  borderColor: lightTheme.palette.neutral.n100,
  color: lightTheme.palette.contrast.black,
};

const ERROR_ITEM_STYLE = {
  backgroundColor: lightTheme.palette.error.e100,
  borderColor: lightTheme.palette.error.e300,
  color: lightTheme.palette.error.e300,
};

export const DetailItem = ({
  title,
  onClick,
  selectedValue,
  children,
  tooltip,
  showAddButton = false,
  hasError = false,
  sx,
}: DetailItemProps) => {
  const hasValue = selectedValue || children;
  const isEntireElementClickable = !showAddButton || !hasValue;

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <Stack
      role={isEntireElementClickable ? 'button' : undefined}
      tabIndex={!hasValue ? 0 : undefined}
      sx={{
        cursor: isEntireElementClickable ? 'pointer' : 'default',
        paddingY: 1.5,
        paddingX: 2,
        borderRadius: 1.25,
        borderWidth: 1,
        borderStyle: 'solid',
        gap: { xs: 0.5, sm: 1.5 },
        transition: 'background-color 0.1s',
        ':hover': {
          backgroundColor: isEntireElementClickable
            ? lightTheme.palette.contrast.surface1
            : undefined,
        },
        ':focus-visible': {
          outline: `1px solid ${lightTheme.palette.neutral.n500}`,
        },
        ...(hasError ? ERROR_ITEM_STYLE : DEFAULT_ITEM_STYLE),
        ...sx,
      }}
      onClick={isEntireElementClickable ? onClick : undefined}
      onKeyDown={isEntireElementClickable ? handleKeyDown : undefined}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <Text fontSize="small" variant="medium" color="inherit">
          {title}
        </Text>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            minWidth: 0,
            alignItems: 'center',
            paddingRight: 4.5,
            position: 'relative',
          }}
        >
          {selectedValue && (
            <Tooltip title={tooltip}>
              <EllipsedText
                fontSize="small"
                variant="medium"
                color={lightTheme.palette.neutral.n300}
              >
                {selectedValue}
              </EllipsedText>
            </Tooltip>
          )}
          {!showAddButton && hasValue ? (
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                padding: 0.5,
                color: lightTheme.palette.neutral.n300,
                transition: 'color 0.1s',
                ':hover': {
                  color: lightTheme.palette.neutral.n500,
                  backgroundColor: lightTheme.palette.contrast.surface1,
                },
                ':focus-visible': {
                  outline: `1px solid ${lightTheme.palette.neutral.n500}`,
                  outlineOffset: 4,
                },
              }}
              onClick={onClick}
            >
              <EditOutlined fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                padding: 0.5,
                color: lightTheme.palette.neutral.n300,
                transition: 'color 0.1s',
                ':hover': {
                  color: lightTheme.palette.neutral.n500,
                  backgroundColor: lightTheme.palette.contrast.surface1,
                },
                ':focus-visible': {
                  outline: `1px solid ${lightTheme.palette.neutral.n500}`,
                  outlineOffset: 4,
                },
              }}
              onClick={onClick}
            >
              <AddOutlined fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

const EllipsedText = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
