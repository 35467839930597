import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { SyncAlt } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useCountryVatRates } from '../../../../../Hooks/useCountryVatRates';
import { usePlatformCustomer } from '../../../../../Hooks/usePlatformCustomer';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { FAVICON_MOSS } from '../../../../../Utils/constants';
import { getVatCompliance } from '../../../Company/domain/helpers';
import {
  CompanyDetailsVatDialog,
  CompanyDetailsVatDialogProps,
  CompanyDetailsVatDialogResponse,
} from '../../../Vat/CompanyDetailsVatDialog';
import { IntegrationObject } from '../../domain/use-integrations';

export interface AvailableIntegrationDialogProps {
  integration: Pick<
    IntegrationObject,
    | 'icon'
    | 'title'
    | 'description'
    | 'extendedDescription'
    | 'openForRequest'
    | 'isRequested'
    | 'guide'
    | 'isPaymentGateway'
    | 'steps'
  > & {
    buttonLabel?: string;
  };
  onAddIntegration: () => Promise<void>;
}

export const AvailableIntegrationDialog =
  NiceModal.create<AvailableIntegrationDialogProps>(
    ({ integration, onAddIntegration }) => {
      const { t } = useTranslate('settings.integrations');
      const modal = useModal();
      const flags = useFlags();
      const countryVatRatesQuery = useCountryVatRates();
      const {
        company: { data: companyProfile },
        updateCompany,
      } = useProfile();
      const platformCustomerQuery = usePlatformCustomer(companyProfile?.id);
      const { data: platformCustomer, isLoading: isLoadingPlatformCustomer } =
        platformCustomerQuery;

      const [isLoading, setIsLoading] = useState(false);
      const [hasSubmitted, setHasSubmitted] = useState(false);

      const handleClose = () => {
        modal.hide();
      };

      const handleSubmit = async () => {
        setIsLoading(true);

        const hasMissingCompanyDetails =
          !platformCustomer?.companyInfo ||
          !platformCustomer?.vatValidations ||
          platformCustomer.vatValidations.every((x) => !x.valid);

        if (
          flags.featureShareCompanyVatDetails &&
          integration.isPaymentGateway &&
          hasMissingCompanyDetails
        ) {
          modal.hide();
          const { didCompleteCompanyDetails } = await handleVatValidation();
          modal.show({
            integration,
            onAddIntegration,
          } as AvailableIntegrationDialogProps & { [k: string]: string });

          if (!didCompleteCompanyDetails) {
            setIsLoading(false);
            modal.hide();
            return;
          }
        }

        await onAddIntegration();
        setIsLoading(false);

        if (integration.openForRequest) {
          setHasSubmitted(true);
          await new Promise((resolve: any) =>
            setTimeout(() => {
              handleClose();
              resolve();
            }, 2000)
          );
        } else {
          handleClose();
        }
      };

      const handleVatValidation =
        async (): Promise<CompanyDetailsVatDialogResponse> => {
          const result = (await NiceModal.show(CompanyDetailsVatDialog, {
            paymentProvider: integration.title,
          } as CompanyDetailsVatDialogProps)) as CompanyDetailsVatDialogResponse;

          if (!result.didCompleteCompanyDetails) {
            return result;
          }

          // pass vat number to company profile setup too so they are the same
          const vatCompliance = getVatCompliance(
            {
              vatCompliance: {
                isVatRegistered: !result.isVatExempt,
                vatRegistration: {
                  country:
                    platformCustomer?.companyInfo?.legalLocation.countryCode ??
                    '',
                  defaultVatCategory: 'standard',
                  vatNumber: platformCustomer?.companyInfo?.vatNumber ?? '',
                },
              },
            },
            countryVatRatesQuery.data,
            companyProfile
          );

          await updateCompany.mutateAsync({ ...companyProfile, vatCompliance });

          return result;
        };

      return (
        <DialogBase
          open={modal.visible}
          onClose={handleClose}
          maxWidth="sm"
          fullHeight={true}
          primaryAction={{
            label:
              integration.isRequested || hasSubmitted
                ? t('willContact', 'utils.generic')
                : integration.buttonLabel || t('getStarted', 'utils.generic'),
            variant: 'primary',
            loading: isLoading,
            disabled:
              isLoading ||
              integration.isRequested ||
              hasSubmitted ||
              isLoadingPlatformCustomer,
            onClick: handleSubmit,
          }}
        >
          <Stack gap={3} pt={6} justifyContent={'center'} textAlign={'center'}>
            <Stack
              gap={2}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box
                component={'img'}
                src={FAVICON_MOSS}
                sx={{
                  width: 74,
                  height: 74,
                }}
              />
              <SyncAlt
                sx={{
                  width: 41,
                  height: 41,
                }}
              />
              <Box
                component={'img'}
                src={integration.icon}
                sx={{
                  width: 74,
                  height: 74,
                }}
              />
            </Stack>
            <Text variant="normal" fontSize="xlarge">
              {t('connectWith', {
                paymentProvider: integration.title,
              })}
            </Text>
            <Text variant="normal" color={lightTheme.palette.neutral.n400}>
              {integration.extendedDescription}
            </Text>
          </Stack>
          {integration.steps?.length && (
            <Stack gap={3} pt={4} pb={2}>
              <Text variant="medium" fontSize="large">
                {t('howItWorks')}
              </Text>
              <Stack gap={4}>
                {integration.steps.map((step, index) => (
                  <Stack key={index} gap={2} direction={'row'}>
                    <NumberedItem>{index + 1}</NumberedItem>
                    <Stack direction={'column'}>
                      <Text variant="normal">{step.title}</Text>
                      <Text variant="normal">{step.description}</Text>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </DialogBase>
      );
    }
  );

const NumberedItem = styled(Box)({
  width: 32,
  height: 32,
  border: `2px solid ${lightTheme.palette.neutral.n200}`,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '16px',
  fontWeight: 400,
});
