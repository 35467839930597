export const DEFAULT_PAGE = 'experiences';
export const DEFAULT_AUTH_IMAGE = '/login/animation/bg.png';
export const LOGO_DEFAULT = '/logo-moss.svg';
export const LOGO_WHITE = '/logo-lightgrey.svg';
export const FAVICON_MOSS = '/favicon-moss.svg';
export const DEFAULT_FORM_SEPARATOR = '.';
export const DEFAULT_FORM_PREFIX = '#';
export const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_BASE_URL;
export const EXPERIENCE_URL =
  process.env.REACT_APP_EXPERIENCE_BASE_URL ??
  'http://{{domain}}.localhost:3005';
export const TERMS_URL = process.env.REACT_APP_TERMS_URL;
