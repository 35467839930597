import NiceModal from '@ebay/nice-modal-react';
import { lightTheme } from '@understory-io/pixel';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import { useCalendarFeed } from '../../../../../Hooks/useCalendarFeed';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IcalDialog } from '../../../../../Modals/ical-dialog/ical-dialog';
import { IntegrationObject } from '../use-integrations';

export const useIcalIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');

  const { feed, deleteFeed } = useCalendarFeed();

  const handleIcalFeedFlow = (status: string) => async () => {
    if (status === 'connect' || status === 'connected') {
      NiceModal.show(IcalDialog);
      ampli.integrationsCalendarSyncConnectFlowStarted();
    }
  };

  const handleDisconnectIcalFeed = async () => {
    toast.loading(t('removingIntegration'), {
      toastId: 'disconnectIcal',
    });
    await deleteFeed.mutateAsync();
    toast.dismiss('disconnectIcal');
    toast.success(t('integrationRemoved'));
  };

  const linkToGuide =
    'https://help.understory.io/en/articles/9158272-calendar-synchronization';

  return {
    id: 'ical',
    title: t('calendars.ical.title'),
    description: t('calendars.ical.description'),
    extendedDescription: t('calendars.ical.extendedDescription'),
    status: feed.data?.accessKey ? 'connected' : 'connect',
    icon: '/integrations/calendars/ical.png',
    guide: linkToGuide,
    onInstall: handleIcalFeedFlow,
    buttonLabel: (status: string) => t(`calendars.status.${status}`),
    onUninstall: handleDisconnectIcalFeed,
    loading: feed.isLoading,
    steps: [
      {
        title: t('calendars.ical.steps1.title'),
        description: (
          <Trans
            i18nKey="settings.integrations.calendars.ical.steps1.description"
            components={{
              Link: (
                <a
                  href={linkToGuide}
                  target="_blank"
                  style={{
                    color: lightTheme.palette.action.a300,
                  }}
                  rel="noreferrer"
                />
              ),
            }}
          />
        ),
      },
    ],
  };
};
