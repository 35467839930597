import { Skeleton, Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import { Link } from 'react-router';

import { useLanguages } from '../../../Hooks/locales/use-languages';
import { trackEventDetailsViewed } from '../../../tracking/events/trackEventDetailsViewed';
import { TEventWithTitle } from '../../../Utils/eventHelpers';
import { useCalendar } from '../context';
import { ListEmpty } from '../shared';
import { EventStatus } from '../shared/EventStatus';
import { GuestCountText } from '../shared/GuestCountText';
import { StrikeOutText } from '../shared/StrikeOutText';

export const CalendarMobileList = () => {
  const { events, isLoading } = useCalendar();

  if (isLoading || !events) return <ListLoading />;

  if (!events?.length) return <ListEmpty />;

  return (
    <Stack gap={1}>
      {events.map((event) => (
        <ListItem key={event.id} event={event} />
      ))}
    </Stack>
  );
};

type ListItemProps = {
  event: TEventWithTitle;
};

const ListItem = ({ event }: ListItemProps) => {
  const handleClick = () => {
    trackEventDetailsViewed('Calendar');
  };

  const { languageOption } = useLanguages();

  const strikeOut = event.status === 'cancelled' || event.status === 'inactive';

  const PossiblyStrikeOutText = strikeOut ? StrikeOutText : Text;

  return (
    <StyledLink
      to={`/event/${event.id}`}
      sx={{ backgroundColor: event.colors }}
      onClick={handleClick}
    >
      <EventStatus event={event} />
      <PossiblyStrikeOutText
        variant="medium"
        fontSize="small"
        strikeOut={strikeOut}
      >
        {renderDateTime(event.startDateTime, event.endDateTime)}
      </PossiblyStrikeOutText>

      <Stack direction="row" alignItems="center" gap={0.5}>
        <PossiblyStrikeOutText variant="medium" fontSize="small">
          {event.title}
        </PossiblyStrikeOutText>
        {!!event.languages?.length && (
          <Stack direction="row" gap={0.5}>
            {event.languages.map((language, index) => (
              <img
                src={languageOption(language).flagSrc}
                key={index}
                alt={language}
                width={16}
                height={16}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <GuestCountText
          as={PossiblyStrikeOutText}
          {...event.slots}
          eventVisibility={event.visibility}
        />
      </Stack>
    </StyledLink>
  );
};

const ListLoading = () => {
  return (
    <Stack gap={1}>
      <ListItemLoading />
      <ListItemLoading />
      <ListItemLoading />
    </Stack>
  );
};

const ListItemLoading = () => {
  return (
    <StyledStack sx={{ backgroundColor: lightTheme.palette.neutral.n100 }}>
      <Skeleton height={21} width={100} />
      <Skeleton height={26} width={250} />
      <Skeleton height={24} width={60} />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  padding: 16,
  borderRadius: 8,
});

const StyledLink = styled(Link)({
  padding: 16,
  borderRadius: 8,
});
