import { Box, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { Button, Text } from '@understory-io/pixel';
import { useEffect } from 'react';
import { Link, useRouteError } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import { AppShell } from './Components/AppShell/AppShell';
import { useFireOnce } from './Hooks/useFireOnce';
import { useTranslate } from './Hooks/useTranslate';
import { FAVICON_MOSS } from './Utils/constants';
import routes from './Utils/routes';

const LOGO_SIZE_PX = 60;
export function GenericErrorPage() {
  const { t } = useTranslate('errorPage.generic');
  const fireOnce = useFireOnce();
  const error = useRouteError();
  const { showNewMessage } = useIntercom();

  useEffect(() => {
    fireOnce(() => {
      captureException(error);
    });
  }, [fireOnce, error]);

  return (
    <Stack
      sx={{
        padding: 2,
        gap: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100dvh',
      }}
    >
      <Box
        component={'img'}
        src={FAVICON_MOSS}
        height={LOGO_SIZE_PX}
        width={LOGO_SIZE_PX}
        alt={'understory logo'}
      />
      <Stack
        sx={{
          maxWidth: 500,
          gap: 1,
        }}
      >
        <Text textAlign="center" variant="medium" fontSize="h5">
          {t('title')}
        </Text>
        <Text textAlign="center" variant="normal" fontSize="large">
          {t('description')}
        </Text>
      </Stack>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Link to={routes.dashboard}>
          <Button size="medium" variant="secondary">
            {t('returnHome')}
          </Button>
        </Link>
        <Button onClick={() => showNewMessage()} size="medium" variant="text">
          {t('contactSupport')}
        </Button>
      </Stack>
    </Stack>
  );
}

export function GenericErrorPageWithAppShell() {
  return (
    <AppShell hideContainerPadding>
      <GenericErrorPage />
    </AppShell>
  );
}
