import { CSSObject } from '@emotion/styled';
import { lightTheme } from '@understory-io/pixel';

import { badgeConfigs } from '../badge/badge-config';
import { ChipProps } from './Chip';

// reexporting badge stuff to make things work without too much rewiring. This
// is a candidate for a refactor to ensure only the minumim is exposed, ie., no
// reason for exporting all badge types for chips.

export const chipConfigs = badgeConfigs;
export { getTranslationBase } from '../badge/badge-config';
export type { StatusBadgeType as ChipType } from '../badge/badge-config';

export const colorVariants: Record<
  Required<ChipProps>['appearance'],
  CSSObject
> = {
  success: {
    color: lightTheme.palette.success.s300,
    backgroundColor: lightTheme.palette.success.s100,
  },
  error: {
    color: lightTheme.palette.error.e300,
    backgroundColor: lightTheme.palette.error.e100,
  },
  default: {
    color: lightTheme.palette.neutral.n300,
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  warning: {
    color: lightTheme.palette.warning.w400,
    backgroundColor: lightTheme.palette.warning.w100,
  },
  action: {
    color: lightTheme.palette.action.a300,
    backgroundColor: lightTheme.palette.action.a100,
  },
};

export const sizeVariants: Record<Required<ChipProps>['size'], CSSObject> = {
  medium: {
    height: 32,
    borderRadius: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 1,
  },
  small: {
    height: 24,
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 1,
  },
};
