import { Grid } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { useAdPlans } from '../../../../Hooks/use-ad-plans';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../Utils/helpers';
import { OutcomeBox } from '../get-started/components/outcome-box';

export const GrowAdvertisementDashboardMetrics = () => {
  const { t } = useTranslate('grow.advertisement.dashboard.metrics');
  const {
    metrics: { data: metrics },
  } = useAdPlans();
  const { defaultCurrency } = useProfile();
  const { dateFnsLocale } = useLocale();
  const { isSm } = useResponsive();

  return (
    <Grid container spacing={isSm ? 1.5 : 2} sx={{ height: '100%' }}>
      <Grid item xs={12} sm={3} flexGrow={1}>
        <OutcomeBox
          label={t('roasLabel')}
          description={t('roasDescription', {
            value: metrics?.roas?.toFixed(2) ?? '0',
            currency: getFormattedPrice(1, defaultCurrency, dateFnsLocale.code),
          })}
          value={metrics?.roas?.toFixed(2) ?? '0'}
          backgroundColor={lightTheme.palette.contrast.white}
        />
      </Grid>
      <Grid item xs={12} sm={3} flexGrow={1}>
        <OutcomeBox
          label={t('revenueLabel')}
          description={t('revenueDescription')}
          value={getFormattedPrice(
            metrics?.revenue ?? 0,
            defaultCurrency,
            dateFnsLocale.code
          )}
          backgroundColor={lightTheme.palette.contrast.white}
        />
      </Grid>
      <Grid item xs={12} sm={3} flexGrow={1}>
        <OutcomeBox
          label={t('purchasesLabel')}
          description={t('purchasesDescription')}
          value={metrics?.purchases?.toLocaleString() ?? '0'}
          backgroundColor={lightTheme.palette.contrast.white}
        />
      </Grid>
      <Grid item xs={12} sm={3} flexGrow={1}>
        <OutcomeBox
          label={t('trafficLabel')}
          description={t('trafficDescription')}
          value={metrics?.traffic?.toLocaleString() ?? '0'}
          backgroundColor={lightTheme.palette.contrast.white}
        />
      </Grid>
    </Grid>
  );
};
