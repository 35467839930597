import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Location } from '@understory-io/utils-types';

import {
  createLocation,
  deleteLocation,
  updateLocation,
} from '../../Api/Locations';
import {
  experienceLocationsQuery,
  locationQuery,
  LOCATIONS_QUERY_KEY_ALL,
  locationsQuery,
  timezonesQuery,
} from '../../Api/queries';
import { ILocationView } from '../../types/api/location';
import { useLocale } from '../locales/use-locale.context';

export const useGetLocations = (
  companyId: string
): { locations: UseQueryResult<Location[]> } => {
  const locations = useQuery(locationsQuery(companyId));

  return { locations };
};

export const useGetLocationsByExperienceId = (
  experienceId: string
): { locations: UseQueryResult<Location[]> } => {
  const locations = useQuery(experienceLocationsQuery(experienceId));

  return { locations };
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createLocation,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: LOCATIONS_QUERY_KEY_ALL,
      });
    },
  });
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ILocationView) => updateLocation(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: LOCATIONS_QUERY_KEY_ALL });

      if (!data?.locationId) return;

      queryClient.cancelQueries({
        queryKey: locationQuery(data.locationId).queryKey,
      });
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: LOCATIONS_QUERY_KEY_ALL });

      if (!data?.locationId) return;

      queryClient.invalidateQueries({
        queryKey: locationQuery(data.locationId).queryKey,
      });
    },
  });
};

export const useDeleteLocation = (locationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteLocation(locationId),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: LOCATIONS_QUERY_KEY_ALL });
      await queryClient.cancelQueries({
        queryKey: locationQuery(locationId).queryKey,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: LOCATIONS_QUERY_KEY_ALL,
      });
      queryClient.invalidateQueries({
        queryKey: locationQuery(locationId).queryKey,
      });
    },
  });
};

export const useGetTimeZones = (): {
  timezones: UseQueryResult<
    { label: string; value: string; countryCode: string }[]
  >;
} => {
  const { regionNames } = useLocale();
  const timezones = useQuery(timezonesQuery(regionNames));

  return { timezones };
};
