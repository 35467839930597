import { CompanyProfile } from '@understory-io/utils-types';
import { array, boolean, mixed, number, object, string } from 'yup';

export const experienceSyiSteps = [
  {
    key: 'description',
    label: 'Beskrivelse',
    formKeys: [
      'headline',
      'headline.da',
      'headline.en',
      'whatsIncluded',
      'practicalInfo',
    ],
  },
  {
    key: 'media',
    label: 'Medier',
    formKeys: ['media', 'media.cover'],
  },
  {
    key: 'practicalities',
    label: 'Det praktiske',
    formKeys: [
      'cutoffTimeSeconds',
      'addresses',
      'addresses.custom',
      'addresses.multiple',
      'infoForGuests',
      'locationIds',
    ],
  },
  {
    key: 'seats',
    label: 'Pladser',
    formKeys: ['seatCount'],
  },
  {
    key: 'price',
    label: 'Pris',
    formKeys: ['price', 'hasVariants', 'variants'],
  },
  {
    key: 'customer-info',
    label: 'Kundeinformationer',
    formKeys: ['customData', 'notifications'],
  },
];

export const discountSyiSteps = [
  {
    key: 'details',
    label: 'Detaljer',
  },
];

export const localizedSchema = (
  required = false as boolean,
  maxLength50 = false as boolean
) => {
  return object().test({
    name: 'localized',
    test: (value, ctx: any) => {
      if (!value) {
        return required ? ctx.createError({ message: `required` }) : true;
      }

      const allValues = Object.values(value as Record<string, string>);

      const missingAllValues = allValues.every(
        (val) => !val || val.length === 0
      );

      if (missingAllValues) {
        return required ? ctx.createError({ message: `required` }) : true;
      }

      for (const valueForLanguage of allValues) {
        if (!valueForLanguage || valueForLanguage === '') {
          return ctx.createError({ message: `requiredLanguage` });
        }

        if (maxLength50 && valueForLanguage.length > 50) {
          return ctx.createError({ message: `maxLength50` });
        }
      }

      return true;
    },
  });
};

export const withOptionSchema = object().shape({
  selectedOptionKey: string().required(),
});

export const eventSchema = object().shape({
  startDateTime: string().required(
    'startDateTimeRequiredStart tidspunkt er påkrævet'
  ),
  endDateTime: mixed().nullable().defined('endDateTimeRequired'),
  /*recurring: object().shape({
        frequency: object({
            value: string().oneOf(['daily','weekly','allBusinessDays','allWeekends','custom','no'])
        }),
        untilType: string().when('recurring.frequency.value', {
            is: (val: string) => val !== 'custom' && val !== 'no',
            then: (schema) => schema.required()
        }),
        type: string().when('recurring.frequency.value', {
            is: (val: string) => val !== 'custom' && val !== 'no',
            then: (schema) => schema.required()
        })
    }),*/
  assignedGuides: array(),
  locationId: string().required('dig'),
});

export const discountSchema = object().shape({
  currency: string(),
  scope: string().required(),
  percentage: boolean().required(),
  rate: number().required(),
  code: string().min(1, 'required').required(),
  _applyForAll: boolean().required(),
  _shouldExpire: boolean().required(),
  _limitTimesRedeemed: boolean().required(),
  overrides: array().when('_applyForAll', {
    is: false,
    then: (schema) =>
      schema
        .required()
        .of(
          object()
            .shape({
              enabled: boolean(),
              rate: mixed().when('enabled', {
                is: (val: boolean) => val === false,
                then: () => number().min(0),
                otherwise: () => mixed().nullable(),
              }),
            })
            .nullable()
        )
        .min(1, 'required'),
    otherwise: (schema) => schema.min(0).nullable(),
  }),
});

const priceSchema = (vatCompliance?: CompanyProfile['vatCompliance']) => {
  const hasVatRegistrations =
    (vatCompliance?.vatRegistrations?.length ?? 0) > 0;

  return object({
    hasVariants: boolean().required(),
    min: string(),
    displayVariantId: string().test(
      'matchVariantId',
      'required',
      function (value) {
        const { variants } = this.parent;
        if (variants?.length === 1) {
          return true;
        }

        return variants?.some((variant: any) => variant.id === value);
      }
    ),
    variants: array()
      .of(
        object({
          name: localizedSchema(true, false),
          priceBreakdown: object().shape({
            vatInclusivePriceCents: number()
              .typeError('required')
              .required()
              .min(0),
            vatSetting: object().shape({
              vatCategory: string().when([], {
                is: () => hasVatRegistrations,
                then: () => string().required('required'),
                otherwise: () => string(),
              }),
            }),
          }),
          addons: array()
            .of(
              object({
                name: localizedSchema(true, false),
                priceBreakdown: object().shape({
                  vatInclusivePriceCents: number().required().min(0),
                  vatSetting: object().shape({
                    vatCategory: string().when([], {
                      is: () => hasVatRegistrations,
                      then: () => string().required('required'),
                      otherwise: () => string(),
                    }),
                  }),
                }),
              })
            )
            .min(0)
            .nullable(),
        })
      )
      .min(1, 'provideMinOnePriceVariant')
      .required(),
  });
};

export const experienceSchema = (company?: CompanyProfile) =>
  object().shape({
    whatsIncluded: localizedSchema(),
    cutoffTimeSeconds: number().min(0).required(),
    addresses: object().shape({
      custom: string().when('selectedOptionKey', {
        is: (val: string) => val === 'custom',
        then: () => string().required(),
        otherwise: () => string().notRequired(),
      }),
      multiple: array().when('selectedOptionKey', {
        is: (val: string) => val === 'multiple',
        then: () =>
          array()
            .of(
              object().shape({
                value: string(),
              })
            )
            .test('notEmpty', 'required', (value) => {
              if (Array.isArray(value) && value.length > 0) {
                return value.every((obj) => obj.value && obj.value.length > 0);
              }
              return false;
            }),
        otherwise: () => array().notRequired(),
      }),
      selectedOptionKey: string().required(),
    }),
    infoForGuests: withOptionSchema.required(),
    languages: array().of(string()).ensure(),
    price: priceSchema(company?.vatCompliance).required(),
    headline: localizedSchema(true, true),
    practicalInfo: localizedSchema(),
    seats: object()
      .shape({
        type: string().required(),
        seatCount: number().required(),
      })
      .required(),
    media: object()
      .shape({
        cover: array(),
      })
      .required(),
    customData: withOptionSchema.required(),
    notifications: withOptionSchema,
    locationIds: array().min(1).required(),
    visibility: string().oneOf(['public', 'private']).required(),
  });
