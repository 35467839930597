import { Box, Typography } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { useMenuNotification } from './menu-notification-context';

export const MenuNotificationIndicator = () => {
  const { notifications } = useMenuNotification();
  const totalCount = notifications.reduce(
    (sum, notification) => sum + notification.count,
    0
  );

  const styles = {
    position: 'absolute',
    bottom: -4,
    left: -8,
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: lightTheme.palette.error.e300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box component="span" sx={styles}>
      <Typography
        variant="caption"
        sx={{
          color: 'white',
          fontSize: '8px',
          fontWeight: 'bold',
        }}
      >
        {totalCount}
      </Typography>
    </Box>
  );
};
