import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Check, ContentCopy } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';

import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { MonoText } from './components/mono-text';

export interface KeyReadyDialogProps {
  name: string;
  clientId: string;
  secretKey: string;
}

export const KeyReadyDialog = NiceModal.create<KeyReadyDialogProps>((input) => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.dialog.keyReady'
  );

  const modal = useModal();

  const handleClose = () => {
    modal.resolve();
    modal.remove();
  };

  return (
    <DialogBase
      open={modal.visible}
      onClose={handleClose}
      maxWidth="md"
      fullHeight={true}
      title={t('title')}
      description={t('description')}
      primaryAction={{
        label: t('done', 'utils.generic'),
        variant: 'primary',
        type: 'button',
        onClick: handleClose,
      }}
    >
      <Stack gap={2}>
        <Field label={t('clientIdLabel')} value={input.clientId} />
        <Field label={t('secretKeyLabel')} value={input.secretKey} />
      </Stack>
    </DialogBase>
  );
});

export const Field = ({ label, value }: { label: string; value: string }) => {
  const { t } = useTranslate('');

  const [copied, setCopied] = useState(false);

  return (
    <Tooltip
      title={t('copied', 'utils.generic')}
      placement={'top'}
      open={copied}
      arrow
      componentsProps={{
        arrow: {
          sx: {
            color: lightTheme.palette.success.s300,
          },
        },
        tooltip: {
          sx: {
            backgroundColor: lightTheme.palette.success.s300,
          },
        },
      }}
    >
      <Stack
        sx={{
          paddingTop: 2,
          paddingLeft: 3,
          paddingRight: 2,
          paddingBottom: 2,
          borderRadius: lightTheme.tokens.radii[3],
          backgroundColor: lightTheme.palette.contrast.surface1,
          borderColor: lightTheme.palette.neutral.n100,
          borderWidth: 1,
          borderStyle: 'solid',
          cursor: 'pointer',
          color: lightTheme.palette.action.a300,
          transition: 'all 0.1s ease-in-out',
          ':hover': { backgroundColor: lightTheme.palette.neutral.n100 },
        }}
        direction={'row'}
        justifyContent={'space-between'}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();

          await navigator.clipboard.writeText(value);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
      >
        <Stack direction={'row'} gap={1}>
          <Text
            variant="medium"
            fontSize="small"
            color={lightTheme.palette.contrast.black}
          >
            {label}
          </Text>
          <MonoText>{value}</MonoText>
        </Stack>

        {copied ? (
          <Check
            sx={{
              color: lightTheme.palette.success.s300,
            }}
          />
        ) : (
          <ContentCopy
            sx={{
              color: lightTheme.palette.action.a300,
              transition: 'all 0.1s ease-in-out',
              '&:hover': { color: lightTheme.palette.action.a500 },
            }}
          />
        )}
      </Stack>
    </Tooltip>
  );
};
