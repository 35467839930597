import { QueryClient } from '@tanstack/react-query';
import { ExperienceTag } from '@understory-io/utils-types';

import * as api from '..';

/* ---- Tags ----  */
const tagsQueryKey = ['tags'];

export const tagsQuery = () => ({
  queryKey: tagsQueryKey,
  queryFn: () => api.getExperienceTags(),
});

/* ---- Tag ----  */
const tagQueryKey = (tagId: string) => ['tag', tagId];

export const tagQuery = (client: QueryClient, tagId: string) => ({
  queryKey: tagQueryKey(tagId),
  queryFn: () => {
    const tags = client.getQueryData<ExperienceTag[]>(tagsQueryKey);
    return tags?.find((el) => el.id === tagId) ?? ({} as ExperienceTag);
  },
  enabled: !!tagId,
});
