import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router';

import { useExperience } from '../../../../Hooks/useExperience';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import { trackEventEditStarted } from '../../../../tracking/events/trackEventEditStarted';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import routes from '../../../../Utils/routes';
import { isCompleted } from './shared';

export const useEditEventAction = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const navigate = useNavigate();
  const flags = useFlags();

  const {
    experience: { data: experience },
  } = useExperience(event?.experienceId);

  const goToEditEvent = () => {
    trackEventEditStarted(event!, experience, source);

    navigate(
      flags.featureNewEventFlow
        ? routes.eventV2.details(event?.id ?? '').edit
        : routes.event.details(event?.id ?? '').edit
    );
  };

  const isDisabled =
    !['active', 'inactive'].includes(event?.status as string) &&
    isCompleted(event);

  return {
    goToEditEvent,
    isDisabled,
  };
};
