import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { MediaPreview } from './media-preview';

export const MovableMedia = (
  props: React.ComponentPropsWithoutRef<typeof MediaPreview>
) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
    isSorting,
  } = useSortable({ id: props.url });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <>
      <MediaPreview
        {...props}
        ref={setNodeRef}
        sx={style}
        isMoving={isSorting || isDragging || isOver}
        attributes={attributes}
        listeners={listeners}
      />
    </>
  );
};
