import {
  Location,
  UnderstoryApiCollection,
  UnderstoryApiSingleResource,
} from '@understory-io/utils-types';

import { handleApiError } from '../types/api/error';
import { ILocationView } from '../types/api/location';
import { apiServices } from './apiServices';
import api from './index';

export const getLocations = async (companyId: string) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get<UnderstoryApiCollection<Location>>(
      `${apiServices.locations}/locations?companyId=${companyId}`
    );

    return data.items;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocationsByExperienceId = async (experienceId: string) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get<UnderstoryApiCollection<Location>>(
      `${apiServices.locations}/locations?experienceId=${experienceId}`
    );

    return data.items;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocation = async (locationId: string) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get<UnderstoryApiSingleResource<Location>>(
      `${apiServices.locations}/locations/${locationId}`
    );

    return data.item;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createLocation = async (location: ILocationView) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.post<UnderstoryApiSingleResource<ILocationView>>(
      `${apiServices.locations}/locations`,
      location
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateLocation = async (location: ILocationView) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.put<UnderstoryApiSingleResource<Location>>(
      `${apiServices.locations}/locations/${location.locationId}`,
      location
    );

    return data.item;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteLocation = async (locationId: string) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.delete(
      `${apiServices.locations}/locations/${locationId}`
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getTimeZoneByCoordinates = async (payload: {
  latitude: number;
  longitude: number;
}) => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.post<{ timezone: string }>(
      `${apiServices.locations}/locations/timezone`,
      payload
    );

    return data.timezone;
  } catch (error) {
    throw handleApiError(error);
  }
};

export type TimeZoneItem = {
  IANATimezone: string;
  city: string;
  countryCode: string;
  utcOffset: string;
};

export const getTimeZones = async () => {
  try {
    if (typeof apiServices.locations === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get<TimeZoneItem[]>(
      `${apiServices.locations}/locations/timezones`
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
