import { ChevronLeftRounded } from '@mui/icons-material';
import { alpha, CircularProgress, Stack, styled } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useOnboardingFlow } from '../../onboarding-context';
import { StepLayoutIndicator } from './step-layout-indicator';

type StepLayoutActionsProps = {
  showBackButton?: boolean;
  onSubmit?: () => void;
  isLoading?: boolean;
};

export const StepLayoutActions = ({
  showBackButton = true,
  onSubmit,
  isLoading = false,
}: StepLayoutActionsProps) => {
  const { isSm } = useResponsive();
  const { t } = useTranslate('onboardingFlow');
  const { nextStep, previousStep } = useOnboardingFlow();

  const handleSubmit = () => {
    onSubmit?.();
    nextStep();
  };

  return (
    <ButtonContainer>
      <StepLayoutIndicator />
      <ButtonWrapper>
        {showBackButton && !isSm && (
          <Button
            variant="text"
            size="large"
            onClick={previousStep}
            leftIcon={<ChevronLeftRounded fontSize="small" />}
            style={{ color: lightTheme.palette.action.a300 }}
          >
            {t('back', 'buttons')}
          </Button>
        )}
        <StyledButton
          variant="primary"
          size="large"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('continue', 'buttons')
          )}
        </StyledButton>
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backdropFilter: 'blur(20px)',
    backgroundColor: alpha(lightTheme.palette.contrast.surface2, 0.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(3),
    paddingBottom: `calc(${theme.spacing(3)} + env(safe-area-inset-bottom))`,
    zIndex: 1000,
  },
}));

const ButtonWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '16px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '0px 60px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: 'unset',
    width: '100%',
  },
}));
