import { InventoryOutlined } from '@mui/icons-material';

import { FullPageMoreOptionsItem } from '../../../../../Components/full-page/form-components/more-options/full-page-more-options-item';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useUpdateResourceTypeLinks } from '../update-resource-type-context';

export const UpdateResourceTypeRecoverTimeMoreOption = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const links = useUpdateResourceTypeLinks();

  return (
    <FullPageMoreOptionsItem
      Icon={InventoryOutlined}
      title={t('followUpTime')}
      description={t('followUpTimeDescription')}
      to={links.recoverTime}
    />
  );
};
