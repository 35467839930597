import { Divider, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useEffect } from 'react';

import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackStorefrontImplementationViewed } from '../../../../tracking/storefront/implementation-events';
import { BookingFlowSectionContextProvider } from '../domain/use_booking_flow_section_context';
import { DomainWidget } from './domain/domain-widget';
import { FlowType } from './flow_type/FlowType';
import { IntegrationLinks } from './integration_links/IntegrationLinks';
import { SeoWidget } from './seo';

export const StorefrontBookingFlowSection = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const fireOnce = useFireOnce();
  useEffect(
    () => fireOnce(() => trackStorefrontImplementationViewed()),
    [fireOnce]
  );

  return (
    <BookingFlowSectionContextProvider>
      <Stack gap={3}>
        <Stack gap={0.5}>
          <Text fontSize={'large'} variant={'medium'}>
            {t('title')}
          </Text>
          <Text fontSize={'small'}>{t('description')}</Text>
        </Stack>
        <DomainWidget />
        <Divider />
        <FlowType />
        <IntegrationLinks />
        <Divider />
        <SeoWidget />
      </Stack>
    </BookingFlowSectionContextProvider>
  );
};
