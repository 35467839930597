import {
  CameraAltOutlined,
  CodeOutlined,
  ColorLensOutlined,
  CreateOutlined,
  DescriptionOutlined,
  ImageRounded,
  LanguageOutlined,
  LinkOutlined,
  LocationOnOutlined,
  PageviewOutlined,
  PreviewOutlined,
  StyleOutlined,
  SvgIconComponent,
  VisibilityOutlined,
} from '@mui/icons-material';

import { ampli } from '../../Ampli';
import { useTranslate } from '../../Hooks/useTranslate';
import routes from '../../Utils/routes';

export type Tip = {
  title: string;
  description: string;
  stepTitle?: string;
  steps: {
    icon?: SvgIconComponent;
    title?: string;
    description: string;
    resources?: {
      title: string;
      url: string;
    }[];
  }[];
  resources?: {
    title: string;
    list: {
      title: string;
      url: string;
      onClick?: () => void;
    }[];
  };
  image?: string;
};

export type TipConfig = {
  [key: string]: Tip;
};

export const useTips = () => {
  const { t } = useTranslate('tips');

  const navbarTips: TipConfig = {
    [routes.experience.overview]: {
      title: t('experiences.overview.title'),
      description: t('experiences.overview.description'),
      stepTitle: t('experiences.overview.stepTitle'),
      steps: [
        {
          icon: CreateOutlined,
          description: t('experiences.overview.steps.draft'),
        },
        {
          icon: LinkOutlined,
          description: t('experiences.overview.steps.publish'),
        },
        {
          icon: LanguageOutlined,
          description: t('experiences.overview.steps.share'),
        },
      ],
      image: '/graphics/suggestions/createExperience.png',
    },
    [routes.storefrontSettings.customize]: {
      title: t('storefront.customize.title'),
      description: t('storefront.customize.description'),
      steps: [
        {
          icon: StyleOutlined,
          title: t('storefront.customize.steps.style.title'),
          description: t('storefront.customize.steps.style.description'),
        },
        {
          icon: LinkOutlined,
          title: t('storefront.customize.steps.connect.title'),
          description: t('storefront.customize.steps.connect.description'),
        },
        {
          icon: LanguageOutlined,
          title: t('storefront.customize.steps.languages.title'),
          description: t('storefront.customize.steps.languages.description'),
        },
        {
          icon: LocationOnOutlined,
          title: t('storefront.customize.steps.locations.title'),
          description: t('storefront.customize.steps.locations.description'),
        },
      ],
      resources: {
        title: t('storefront.customize.resources.title'),
        list: [
          {
            title: "Let's Clay",
            url: 'https://letsclay.understory.io',
            onClick: () =>
              ampli.tipSidebarHostExampleClicked({ host: 'letsclay' }),
          },
          {
            title: 'Thy Whisky',
            url: 'https://thy-whisky.understory.io/en',
            onClick: () =>
              ampli.tipSidebarHostExampleClicked({ host: 'thy-whisky' }),
          },
        ],
      },
      image: '/graphics/suggestions/styleElements.png',
    },
  };

  const suggestionTips: TipConfig = {
    [routes.experience.overview]: {
      title: t('suggestions.experiences.overview.title'),
      description: t('suggestions.experiences.overview.description'),
      steps: [
        {
          icon: ImageRounded,
          title: t('suggestions.experiences.overview.steps.images.title'),
          description: t(
            'suggestions.experiences.overview.steps.images.description'
          ),
        },
        {
          icon: DescriptionOutlined,
          title: t('suggestions.experiences.overview.steps.description.title'),
          description: t(
            'suggestions.experiences.overview.steps.description.description'
          ),
        },
        {
          icon: PreviewOutlined,
          title: t('suggestions.experiences.overview.steps.preview.title'),
          description: t(
            'suggestions.experiences.overview.steps.preview.description'
          ),
        },
        {
          icon: LinkOutlined,
          title: t('suggestions.experiences.overview.steps.resources.title'),
          description: t(
            'suggestions.experiences.overview.steps.resources.description'
          ),
        },
      ],
    },
    [routes.storefrontSettings.integration]: {
      title: t('suggestions.storefront.integration.title'),
      description: t('suggestions.storefront.integration.description'),
      steps: [
        {
          icon: CodeOutlined,
          title: t(
            'suggestions.storefront.integration.steps.implementation.title'
          ),
          description: t(
            'suggestions.storefront.integration.steps.implementation.description'
          ),
        },
        {
          icon: LinkOutlined,
          title: t('suggestions.storefront.integration.steps.link.title'),
          description: t(
            'suggestions.storefront.integration.steps.link.description'
          ),
          resources: [
            {
              title: t(
                'suggestions.storefront.integration.steps.link.resources.howTo.title'
              ),
              url: 'https://help.understory.io/en/articles/9235177-generate-storefront-link-for-your-website',
            },
          ],
        },
        {
          icon: VisibilityOutlined,
          title: t('suggestions.storefront.integration.steps.seo.title'),
          description: t(
            'suggestions.storefront.integration.steps.seo.description'
          ),
          resources: [
            {
              title: t(
                'suggestions.storefront.integration.steps.seo.resources.whatIs.title'
              ),
              url: 'https://developers.google.com/search/docs',
            },
          ],
        },
      ],
      image: '/graphics/suggestions/integrateWebsite.png',
    },
    [routes.storefrontSettings.customize]: {
      title: t('suggestions.storefront.customize.title'),
      description: t('suggestions.storefront.customize.description'),
      steps: [
        {
          icon: CameraAltOutlined,
          title: t('suggestions.storefront.customize.steps.cover.title'),
          description: t(
            'suggestions.storefront.customize.steps.cover.description'
          ),
        },
        {
          icon: ColorLensOutlined,
          title: t('suggestions.storefront.customize.steps.style.title'),
          description: t(
            'suggestions.storefront.customize.steps.style.description'
          ),
        },
        {
          icon: LanguageOutlined,
          title: t('suggestions.storefront.customize.steps.languages.title'),
          description: t(
            'suggestions.storefront.customize.steps.languages.description'
          ),
          resources: [
            {
              title: t(
                'suggestions.storefront.customize.steps.languages.resources.autoTranslate.title'
              ),
              url: 'https://help.understory.io/en/articles/9246275-create-experience',
            },
          ],
        },
        {
          icon: PageviewOutlined,
          title: t(
            'suggestions.storefront.customize.steps.implementation.title'
          ),
          description: t(
            'suggestions.storefront.customize.steps.implementation.description'
          ),
        },
      ],
      image: '/graphics/suggestions/styleElements.png',
    },
  };

  return {
    navbarTips,
    suggestionTips,
  };
};
