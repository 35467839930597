import NiceModal from '@ebay/nice-modal-react';
import { Box, Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Location } from '@understory-io/utils-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextMenu } from '../../../Components/context-menu/context-menu';
import { IExperience } from '../../../Hooks/useExperience';
import { useTranslate } from '../../../Hooks/useTranslate';
import { BulkAddLocationDialog } from './dialogs/bulk-add-location-dialog';
import { DeleteLocationDialog } from './dialogs/delete-location-dialog';
import { EditLocationDialog } from './dialogs/edit-location-dialog';

export const LocationList = ({
  locations,
  experiences,
}: {
  locations?: Location[];
  experiences: IExperience[];
}) => {
  const { t } = useTranslation();

  const usedLocationsMap = useMemo(
    () =>
      experiences
        ?.flatMap(({ locationIds }) => locationIds)
        .reduce(
          (total, locationId) => {
            if (!locationId) return total;
            return {
              ...total,
              [locationId]: (total[locationId] ?? 0) + 1,
            };
          },
          {} as Record<string, number>
        ),
    [experiences]
  );

  if (!locations || !locations.length) {
    return (
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {t('storefront.locations.list.empty')}
      </Text>
    );
  }

  return (
    <Stack
      sx={{ gap: 3 }}
      divider={<Divider sx={{ display: { md: 'none' } }} />}
    >
      {locations.map((location) => {
        const usedOnExperiencesCount =
          usedLocationsMap?.[location.locationId] ?? 0;

        return (
          <LocationItem
            key={location.locationId}
            location={location}
            experienceCount={usedOnExperiencesCount}
            experiences={experiences ?? []}
            locations={locations}
          />
        );
      })}
    </Stack>
  );
};

const LocationItem = ({
  location,
  experienceCount,
  experiences,
  locations,
}: {
  location: Location;
  experienceCount: number;
  experiences: IExperience[];
  locations: Location[];
}) => {
  const { t } = useTranslate('storefront.location.list');

  const [open, setOpen] = useState(false);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Text variant="medium" fontSize="small">
          {location.locationName}
        </Text>
        <Text
          variant="medium"
          fontSize="small"
          color={lightTheme.palette.neutral.n300}
          style={{ marginLeft: 8 }}
        >
          {location.displayName.join(', ')}
        </Text>
      </Box>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Text
            variant="medium"
            fontSize="small"
            color={lightTheme.palette.neutral.n300}
          >
            {t('experienceCount', 'storefront.tags.list', {
              count: experienceCount,
            })}
          </Text>
        </Box>
        <ContextMenu
          open={open}
          onOpenChange={setOpen}
          options={[
            {
              label: t('context.add'),
              onClick: () =>
                NiceModal.show(BulkAddLocationDialog, {
                  locationId: location.locationId,
                  locationName: location.locationName,
                  experiences,
                }),
            },
            {
              label: t('context.edit'),
              onClick: () =>
                NiceModal.show(EditLocationDialog, {
                  location,
                }),
            },
            {
              label: t('context.delete'),
              onClick: () =>
                NiceModal.show(DeleteLocationDialog, {
                  location,
                  experiences,
                  locations,
                }),
              textColor: lightTheme.palette.error.e300,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};
