import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, lightTheme } from '@understory-io/pixel';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import { ampli } from '../../Ampli';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';

export const OnboardingFlow = () => {
  const { t } = useTranslate('utils.generic');
  const { show } = useIntercom();
  const navigate = useNavigate();
  const { onboardingCompleted } = useProfile();
  const { isSm } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (onboardingCompleted) {
      navigate('/dashboard', { replace: true });
    }
  }, [onboardingCompleted, navigate]);

  useEffect(() => {
    const hideLauncher = () => {
      const launcher = document.querySelector('.intercom-launcher');
      if (launcher instanceof HTMLElement) {
        launcher.style.display = 'none';
      }
    };

    hideLauncher();

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length) {
          hideLauncher();
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      const launcher = document.querySelector('.intercom-launcher');
      if (launcher instanceof HTMLElement && !isSm) {
        launcher.style.display = '';
      }
    };
  }, [isSm]);

  return (
    <MainContainer>
      <Header>
        <img
          src={'/favicon-moss.svg'}
          alt="Understory Favicon"
          width={40}
          height={40}
        />
        <Button
          variant="text"
          size="small"
          style={{
            textDecoration: 'underline',
            color: lightTheme.palette.action.a300,
          }}
          onClick={() => {
            show();
            ampli.onboardingFlowHelpClicked();
          }}
        >
          {t('help')}
        </Button>
      </Header>
      <StepContainer>
        <Outlet />
      </StepContainer>
    </MainContainer>
  );
};

const MainContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: lightTheme.palette.contrast.white,
  minHeight: '100vh',
  height: '100%',
  position: 'relative',
  overflowY: 'auto',
  paddingBottom: 'env(safe-area-inset-bottom)',
  [theme.breakpoints.up('md')]: {
    paddingBottom: 0,
  },
}));

const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  position: 'sticky',
  top: 0,
  backgroundColor: lightTheme.palette.contrast.white,
  zIndex: 1,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    padding: theme.spacing(4),
  },
}));

const StepContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing(3),
  flex: 1,
  minHeight: 'calc(100vh - 80px)',
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2)}`,
    minHeight: 'auto',
  },
}));
