import { Stack } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';

import { FullPageMoreOptions } from './form-components/more-options/full-page-more-options';

type Setting = {
  Setting: ReactNode;
  MoreOption?: ReactNode;
  showAsMoreOption?: boolean;
};

type Props = {
  settings: Array<Setting>;
};

/**
 * Convenience wrapper to either show settings or put them in MoreOptions section.
 */
export const FullPageFormSettings: FC<Props> = ({ settings }) => {
  const { activeSettings, moreOptions } = useMemo(() => {
    const activeSettings: ReactNode[] = [];
    const moreOptions: ReactNode[] = [];

    for (const setting of settings) {
      if (setting.showAsMoreOption) {
        moreOptions.push(setting.MoreOption);
      } else {
        activeSettings.push(setting.Setting);
      }
    }

    return { activeSettings, moreOptions };
  }, [settings]);

  return (
    <>
      <Stack gap={1}>{activeSettings}</Stack>
      <FullPageMoreOptions>{moreOptions}</FullPageMoreOptions>
    </>
  );
};
