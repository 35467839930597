import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { Stack, styled, TextField } from '@mui/material';
import { PlatformCustomer } from '@understory-io/customer-management-types';
import { lightTheme } from '@understory-io/pixel';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../../../Components/tooltip/tooltip';
import { YesNoSelect } from '../../../Components/YesNoSelect/YesNoSelect';
import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { europeanCountryCodes } from '../../../Sections/SettingsSections/Vat/Countries';
import { BillingFormData } from './billing-schema';

type Props = {
  platformCustomer: PlatformCustomer;
};

type VatValidationStatus = 'valid' | 'invalid' | 'unknown';

export const BillingSectionTaxRegistration = ({ platformCustomer }: Props) => {
  const { t } = useTranslation();
  const countryVatRatesQuery = useCountryVatRates();

  const {
    control,
    formState: { errors },
    register,
    watch,
  } = useFormContext<BillingFormData>();

  const { isTaxRegistered, vatNumber, legalLocation } = watch();

  const vatNumberPlaceHolder = useMemo(() => {
    if (!legalLocation?.countryCode) {
      return '';
    }

    const vatRates = countryVatRatesQuery.data?.[legalLocation.countryCode];
    return vatRates?.vatNumberRules[0].example ?? '';
  }, [countryVatRatesQuery.data, legalLocation?.countryCode]);

  const vatValidationStatus = useMemo<VatValidationStatus>(() => {
    const { companyInfo, vatValidations } = platformCustomer;

    // Only show validation status if the company is in the EU outside DK
    // DK is ignored because the validation only accounts for VAT across countries
    // and not within DK
    if (
      companyInfo?.legalLocation.countryCode === 'DK' ||
      !europeanCountryCodes.includes(
        companyInfo?.legalLocation.countryCode ?? ''
      )
    ) {
      return 'unknown';
    }

    if (!companyInfo?.vatNumber || companyInfo.vatNumber !== vatNumber) {
      return 'unknown';
    }

    if (
      vatValidations?.some(
        (x) => x.vatNumber === companyInfo.vatNumber && x.valid
      )
    ) {
      return 'valid';
    }

    return 'invalid';
  }, [platformCustomer, vatNumber]);

  return (
    <Stack direction={'row'} gap={2}>
      <Stack sx={{ width: '33%' }}>
        <Controller
          name="isTaxRegistered"
          control={control}
          render={({ field }) => (
            <YesNoSelect
              value={field.value}
              label={t('settings.billing.isVatRegistered')}
              onChange={(x) =>
                !x.target.value || x.target.value == 'false'
                  ? field.onChange(false)
                  : field.onChange(true)
              }
              error={!!errors.isTaxRegistered?.message}
              sx={{ maxHeight: 48 }}
            />
          )}
        />
      </Stack>
      <TextField
        {...register('vatNumber')}
        disabled={!isTaxRegistered}
        size="medium"
        fullWidth
        type="text"
        placeholder={vatNumberPlaceHolder}
        label={t('utils.generic.vatNumber')}
        InputLabelProps={{ shrink: true }}
        error={!!errors.vatNumber?.message}
        helperText={t(errors.vatNumber?.message ?? '')}
        InputProps={{
          sx: { maxHeight: 48 },
          endAdornment: vatValidationStatus !== 'unknown' && (
            <Tooltip
              title={t(
                `settings.billing.vat.validation.${vatValidationStatus}`
              )}
            >
              {vatValidationStatus === 'invalid' ? (
                <InvalidVatIcon />
              ) : (
                <ValidVatIcon />
              )}
            </Tooltip>
          ),
        }}
      />
    </Stack>
  );
};

const InvalidVatIcon = styled(ErrorOutline)({
  color: lightTheme.palette.error.e200,
  width: '18px',
  height: '18px',
});

const ValidVatIcon = styled(CheckCircleOutline)({
  color: lightTheme.palette.primary.p200,
  width: '18px',
  height: '18px',
});
