import { Skeleton, Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';

import { FullPage } from '../../../../Components/full-page/full-page';
import { FullPageForm } from '../../../../Components/full-page/full-page-form';
import { FullPageHeader } from '../../../../Components/full-page/full-page-header';
import { FullPageHeaderBackButton } from '../../../../Components/full-page/full-page-header-back-button';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const EditResourceTypeSkeletonPage = () => {
  const { t } = useTranslate('resourceManagement.resourceType');
  return (
    <FullPage>
      <FullPageHeader>
        <FullPageHeaderBackButton />
        <Button variant="primary" size="medium" disabled>
          {t('save', 'fullPageForm.generic')}
        </Button>
      </FullPageHeader>
      <FullPageForm>
        <Stack mt={5} gap={4}>
          <Skeleton variant="rounded" width="100%" height={72} />
          <Stack gap={1}>
            <Skeleton variant="rounded" width="100%" height={48} />
            <Skeleton variant="rounded" width="100%" height={48} />
          </Stack>
        </Stack>
      </FullPageForm>
    </FullPage>
  );
};
