import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { ampli } from '../Ampli';
import DialogBase from '../Components/dialog/dialog-base';
import { useTranslate } from '../Hooks/useTranslate';

type PaymentProviderId =
  | 'mobilepay'
  | 'mastercard'
  | 'visa'
  | 'paypal'
  | 'applepay'
  | 'klarna'
  | 'vipps'
  | 'cashapp'
  | 'revolut'
  | 'swish';

const getProviderLogoPath = (providerId: PaymentProviderId) => {
  return `/payment-providers/${providerId}.png`;
};

const PAYMENT_PROVIDERS: Record<string, PaymentProviderId[]> = {
  da: ['mobilepay', 'applepay', 'paypal', 'visa', 'mastercard'],
  no: ['vipps', 'applepay', 'visa', 'mastercard', 'paypal'],
  sv: ['klarna', 'swish', 'visa', 'mastercard', 'paypal'],
  en: ['applepay', 'paypal', 'visa', 'mastercard'],
  'en-US': ['cashapp', 'applepay', 'visa', 'mastercard', 'paypal'],
  'en-GB': ['revolut', 'applepay', 'visa', 'mastercard', 'paypal'],
  de: ['klarna', 'visa', 'mastercard', 'paypal'],
};

export const PaymentProvidersDisclaimerDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t, i18n } = useTranslate(
    'experience.edit.dialog.tickets.paymentDisclaimer'
  );

  const locale = i18n.language;
  const providers = PAYMENT_PROVIDERS[locale] || PAYMENT_PROVIDERS.en;

  useEffect(() => {
    if (open) {
      ampli.experienceEditPaymentProviderDialogOpened();
    }
  }, [open]);

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('title')}
      description={t('description')}
      primaryAction={{
        label: t('action'),
        variant: 'primary',
        onClick: onClose,
      }}
      actionsInHeader={false}
      fullHeight={false}
    >
      <Stack gap={6}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            minHeight: 160,
            '& > *': {
              borderRadius: '50%',
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            },
          }}
        >
          {providers.map((id, index) => {
            const isEven = providers.length % 2 === 0;
            const isMiddle =
              !isEven && index === Math.floor(providers.length / 2);
            const imgSize = isMiddle ? 120 : 80;
            const zIndex = isMiddle ? 3 : 2;

            return (
              <img
                key={id}
                src={getProviderLogoPath(id)}
                alt={id}
                width={imgSize}
                height={imgSize}
                style={{
                  zIndex,
                  objectFit: 'contain',
                  marginLeft: !isEven && index > 0 ? -10 : 0,
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </DialogBase>
  );
};
