import { TFunction } from 'i18next';
import * as yup from 'yup';

export const useFormValidation = (t: TFunction) =>
  yup.object({
    keyName: yup.string().required(t('required', 'utils.errors')),
    scopes: yup
      .array()
      .of(yup.string().required())
      .min(1, 'atLeastOne')
      .required(),
  });

export type FormSchema = yup.InferType<ReturnType<typeof useFormValidation>>;
