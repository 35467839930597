import NiceModal from '@ebay/nice-modal-react';
import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { ReducedVat, VatRegistration } from '@understory-io/utils-types';
import { FC, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ContextMenuItemProps } from '../../../../Components/context-menu/context-menu-item';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackTaxAddRateEvent,
  trackTaxDeleteRateEvent,
  trackTaxSetPrimaryRateEvent,
} from '../../../../tracking/tax/tax-rates-events';
import { getVatRatesAsArray } from '../../../../Utils/tax/tax';
import { useTaxLabel } from '../../../../Utils/tax/use-tax-label';
import { CompanyFormData } from '../../company/company-settings';
import { AddCustomTaxRateDialog } from './add-custom-tax-rate-dialog';
import { TaxRateItem } from './tax-rate-item';

type Props = {
  vatRegistration: VatRegistration;
};

export const TaxRatesSetup: FC<Props> = ({ vatRegistration }) => {
  const { t } = useTranslate('');

  const { country, rates, defaultVatCategory } = vatRegistration;

  const taxLabel = useTaxLabel(country);

  const { append, remove } = useFieldArray<CompanyFormData>({
    name: 'vatCompliance.vatRegistrations.0.rates.reduced',
  });

  const { setValue } = useFormContext<CompanyFormData>();

  const removeItem = useCallback(
    (category: string, value: ReducedVat) => {
      const index = rates.reduced.findIndex((rate) => rate[category]);
      remove(index);

      trackTaxDeleteRateEvent(category, value);

      if (defaultVatCategory === category) {
        setValue(
          'vatCompliance.vatRegistrations.0.defaultVatCategory',
          'standard',
          { shouldDirty: true }
        );
      }
    },
    [defaultVatCategory, rates?.reduced, remove, setValue]
  );

  const setDefault = useCallback(
    (category: string, value: ReducedVat) => {
      trackTaxSetPrimaryRateEvent(category, value);

      setValue(
        'vatCompliance.vatRegistrations.0.defaultVatCategory',
        category,
        { shouldDirty: true }
      );
    },
    [setValue]
  );

  // Allow custom rates if there are no tax rates, or all are user made
  const allowCustomTaxRates =
    !rates?.reduced.length ||
    rates?.reduced.every((rate) => {
      const rateValues = Object.values(rate);
      return rateValues.every((value) => value.isUserMade);
    });

  const allRates = getVatRatesAsArray(rates);

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Text variant="medium">{taxLabel('tax.rates')}</Text>
        <Text fontSize="small">
          {taxLabel('tax.registration.setup.addRateDescription')}
        </Text>
      </Stack>

      <Stack gap={1}>
        {allRates.map(({ category, value }) => {
          const isDefault = category === defaultVatCategory;

          const items: ContextMenuItemProps[] = [];
          if (!isDefault) {
            items.push({
              label: t('setAsDefault', 'utils.generic'),
              onClick: () => setDefault(category, value),
            });
          }

          items.push({
            label: t('remove', 'utils.generic'),
            onClick: () => removeItem(category, value),
            textColor: lightTheme.palette.error.e300,
            disabled: !value.isUserMade,
          });

          return (
            <TaxRateItem
              key={category}
              taxCountry={country}
              category={category}
              tax={value}
              contextMenuItems={items}
              isDefault={defaultVatCategory === category}
            />
          );
        })}
      </Stack>
      {allowCustomTaxRates && (
        <Button
          type="button"
          variant="secondary"
          size="medium"
          width="fit-content"
          leftIcon={<AddOutlined />}
          onClick={async () => {
            const result = await NiceModal.show(AddCustomTaxRateDialog);
            if (!result) return;

            const id = Math.random().toString(36).substring(7);
            append({ [id]: result as ReducedVat });

            trackTaxAddRateEvent(id, result as ReducedVat);
          }}
        >
          {taxLabel('tax.registration.setup.addRate')}
        </Button>
      )}
    </Stack>
  );
};
