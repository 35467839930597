import {
  BrandingWatermarkOutlined,
  ChatBubbleOutlineOutlined,
  DashboardOutlined,
  MapOutlined,
  PeopleOutlined,
  SvgIconComponent,
  TodayOutlined,
} from '@mui/icons-material';
import { Divider, Grid, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';

import { EmptyScreen } from '../../../../Components/EmptyScreen/EmptyScreen';
import { useAuth } from '../../../../Hooks/useAuth';
import { IExperience } from '../../../../Hooks/useExperience';
import { TUser } from '../../../../Hooks/useUsers';
import { TEvent } from '../../../../Utils/eventHelpers';
import { EventListItemDesktop } from './event-list-item-desktop';
import { EventListItemMobile } from './event-list-item-mobile';

export const EVENT_LIST_COLUMNS = [
  { key: 'type', icon: DashboardOutlined, span: 1 },
  { key: 'date', icon: TodayOutlined, span: 2 },
  { key: 'guests', icon: PeopleOutlined, span: 1 },
  { key: 'guide', icon: MapOutlined, span: 2 },
  { key: 'language', icon: ChatBubbleOutlineOutlined, span: 1 },
  { key: 'status', icon: BrandingWatermarkOutlined, span: 1 },
];

type EventListProps = {
  events: TEvent[];
  usersPromise: Promise<TUser[]>;
  experience: IExperience;
};

export const EventList = ({
  events,
  usersPromise,
  experience,
}: EventListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canAccess } = useAuth();

  const allowCreatingEvent = canAccess('event.write');

  return (
    <Stack>
      <Stack
        sx={{
          paddingY: 1,
          paddingX: 2,
          paddingRight: 8,
          backgroundColor: lightTheme.palette.neutral.n100,
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <Grid container columns={8} columnSpacing={3}>
          {EVENT_LIST_COLUMNS.map(({ key, icon, span }) => (
            <Grid key={key} item xs={span}>
              <EventListLabel
                label={t(`experience.details.eventList.label.${key}`)}
                Icon={icon}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack divider={<Divider sx={{ display: { md: 'none' } }} />}>
        {events.length === 0 && (
          <EmptyScreen
            mt={6}
            mb={2}
            imageSrc={'/empty-state-nature.svg'}
            title={t('events.emptyState.title')}
            description={t('events.emptyState.description')}
            buttonLabel={
              allowCreatingEvent
                ? t('experience.details.action.createEventLabel')
                : undefined
            }
            onClick={
              allowCreatingEvent
                ? () =>
                    navigate('/event/create', {
                      state: { experienceId: experience.id },
                    })
                : undefined
            }
          />
        )}

        {events.map((event) => {
          return (
            <Link key={event.id} to={`/event/${event.id}`}>
              <EventListItemDesktop
                event={event}
                experience={experience}
                usersPromise={usersPromise}
              />
              <EventListItemMobile event={event} experience={experience} />
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};

export const EventListLabel = ({
  label,
  Icon,
}: {
  label: string;
  Icon: SvgIconComponent;
}) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        color: lightTheme.palette.neutral.n500,
      }}
    >
      <Icon sx={{ height: 16, width: 16, color: 'inherit' }} />
      <Text fontSize="xsmall" color="inherit">
        {label}
      </Text>
    </Stack>
  );
};
