import { useTranslate } from '../../Hooks/useTranslate';
import { Badge, BadgeProps } from './badge';
import {
  badgeConfigs,
  getTranslationBase,
  StatusBadgeType,
} from './badge-config';

type StatusBadgeProps = StatusBadgeType & {
  size?: BadgeProps['size'];
};

export const StatusBadge = ({ type, state, ...props }: StatusBadgeProps) => {
  const { t } = useTranslate(getTranslationBase(type));
  const badge = badgeConfigs[type][state];

  return (
    <Badge appearance={badge.appearance} {...props}>
      {badge.icon}
      {t(state)}
    </Badge>
  );
};
