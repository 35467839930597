import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { ampli } from '../../../Ampli';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import {
  AllowGuestsCancelBookingFeature,
  AllowGuestsMoveBookingFeature,
  BookingReminderFeature,
  EventRequestsFeature,
  GiftCardsFeature,
  PassOnBookingFeeFeature,
  ShowUpcomingEventsInStorefrontFeature,
  TicketingFeature,
} from './features';

export const StorefrontFeaturesSection = () => {
  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.storefrontFeaturesViewed()), [fireOnce]);

  return (
    <Stack gap={3}>
      <GiftCardsFeature />
      <EventRequestsFeature />
      <AllowGuestsMoveBookingFeature />
      <AllowGuestsCancelBookingFeature />
      <ShowUpcomingEventsInStorefrontFeature />
      <BookingReminderFeature />
      <PassOnBookingFeeFeature />
      <TicketingFeature />
    </Stack>
  );
};
