import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { RegularExperience } from '@understory-io/experiences-types';
import { useEffect } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import { saveExperienceDraft } from '../../../../../Api/Experience';
import { draftExperienceQuery } from '../../../../../Api/queries';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import routes from '../../../../../Utils/routes';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { useNavigateWithParams } from '../../utils/use-navigate-with-params';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export async function loader({ params }: LoaderFunctionArgs) {
  const { ruleId } = params;

  return { ruleId };
}

export function DeleteResourceRule() {
  const navigate = useNavigateWithParams();

  const { ruleId } = useLoaderData<LoaderData>();
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.deleteResourceRule');

  const rule = experience.resourceRules?.rules?.find(
    (rule) => rule.ruleId === ruleId
  );

  useEffect(() => {
    if (actionData?.shouldClose) {
      ampli.experienceEditFlowResourceDeleted({
        assignment: rule?.allocationType,
      });
    }
  }, [actionData?.shouldClose, rule?.allocationType]);

  if (!rule) {
    navigate(routes.experience.details(experience.id).edit.index);
    return null;
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="delete-resource-rule"
      isDelete
    />
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params }: ActionFunctionArgs) => {
    const id = params.id;
    const ruleId = params.ruleId;

    if (!id) {
      return redirect(routes.experience.overview);
    }

    if (!ruleId) {
      return redirect(routes.experience.details(id).edit.index);
    }

    try {
      const experience = await client.fetchQuery(draftExperienceQuery(id));

      const rules = experience.resourceRules?.rules ?? [];

      const experienceToSave: RegularExperience = {
        ...experience,
        resourceRules: {
          version: 1,
          rules: rules.filter((rule) => rule.ruleId !== ruleId),
        },
      };

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
