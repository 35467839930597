import { Stack } from '@mui/material';
import { differenceInDays } from 'date-fns';

import { useAdPlans } from '../../../../Hooks/use-ad-plans';
import { GrowAdvertisementDashboardBanner } from './grow-advertisement-dashboard-banner';
import { GrowAdvertisementDashboardFooter } from './grow-advertisement-dashboard-footer';
import { GrowAdvertisementDashboardMetrics } from './grow-advertisement-dashboard-metrics';
import { GrowAdvertisementDashboardPlanList } from './grow-advertisement-dashboard-plan-list';

export const GrowAdvertisementDashboard = () => {
  const { adPlans } = useAdPlans();

  const plan = adPlans.data?.[0];

  const isLearningPhase =
    plan?.state === 'active' &&
    differenceInDays(new Date(), new Date(plan?.startDate ?? '')) > 28;

  return (
    <Stack gap={4}>
      {isLearningPhase && <GrowAdvertisementDashboardBanner />}
      <GrowAdvertisementDashboardMetrics />
      <GrowAdvertisementDashboardPlanList />
      <GrowAdvertisementDashboardFooter />
    </Stack>
  );
};
