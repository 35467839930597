import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { LoadingFullscreen } from '../../../../Components/Loading/LoadingFullscreen';
import { View } from '../../../../Components/View';
import { useGetEvent } from '../../../../Hooks/events/useGetEvent';
import { useUpdateEvent } from '../../../../Hooks/events/useUpdateEvent';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { OptionsDialog } from '../../../../Modals/OptionsDialog';
import { useEventUpsert } from '../../event_upsert/domain/event_upsert_context';
import { EventStepTypes, getEventSteps } from '../../event_upsert/domain/types';
import { useEventInitial } from '../../event_upsert/domain/use_event_initial_values';
import { EventUpsert } from '../../event_upsert/ui/EventUpsert';

type LocationState = {
  returnUrl?: string;
};

export const EventEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isSm } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { returnUrl } = useMemo(() => {
    const state = location?.state as LocationState;
    return {
      returnUrl: state?.returnUrl,
    };
  }, [location.state]);

  const { currentEvent, updateEventField, initializeEvent } = useEventUpsert();
  const {
    event: { data: event, isError },
  } = useGetEvent(id ?? '');
  const { updateEvent } = useUpdateEvent(id);

  const initialValues = useEventInitial({
    id: event?.id,
    experienceId: event?.experienceId,
    parentId: event?.parentId,
    assignedGuides: event?.assignedGuides,
    bookings: event?.bookings as [],
    endDateTime: event?.endDateTime as any,
    startDateTime: event?.startDateTime,
    seatCount: event?.seatCount as any,
    recurring: event?.recurring as any,
    intervals: event?.intervals as any,
    visibility: event?.visibility,
    locationId: event?.locationId,
    languages: event?.languages,
    status: event?.status,
    dates: event?.dates,
    addresses: event?.addresses,
    information: event?.information,
    variant: 'edit',
  });

  useEffect(() => {
    if (!event) return;
    initializeEvent(initialValues);
    setHasInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, id]);

  const handleBack = () => {
    const isNotDetailsStep = currentEvent.step !== 'details';

    if (isSm && isNotDetailsStep) {
      const currentIndex = EventStepTypes.indexOf(currentEvent.step);
      const stepDecrement = currentEvent.visibility === 'private' ? 1 : 2;
      const newStep = EventStepTypes[currentIndex - stepDecrement];

      if (newStep) {
        updateEventField('step', newStep);
        return; // Exit early to avoid navigating away
      }
    }

    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async () => {
    try {
      // Omit helper fields
      const {
        variant,
        step,
        startEndTimeDiff,
        booking,
        endDateTimeDisabled,
        loading,
        ...eventData
      } = currentEvent;

      const isParentEvent =
        !eventData.parentId && eventData.recurring?.selectedOptionKey !== 'no';

      let choice: 'cancel' | 'confirm' = 'confirm';

      if (isParentEvent) {
        choice = await NiceModal.show(OptionsDialog, {
          title: t('eventUpsert.editRecurringDialog.title'),
          description: t('eventUpsert.editRecurringDialog.description'),
          buttons: [
            {
              key: 'cancel',
              label: t('eventUpsert.editRecurringDialog.actions.cancel'),
              props: { variant: 'outlined', color: 'secondary' },
            },
            {
              key: 'confirm',
              label: t('eventUpsert.editRecurringDialog.actions.confirm'),
              props: { variant: 'contained' },
            },
          ],
        });
      }

      (eventData.endDateTime as any) = currentEvent.endDateTimeDisabled
        ? null
        : eventData.endDateTime;

      setIsLoading(true);
      if (choice === 'confirm') {
        await updateEvent.mutateAsync(eventData).then((response) => {
          toast.success(t('eventUpsert.toast.success.editEvent'));
          navigate(`/event/${response.id}`, {
            replace: true,
            // If the virtual event is persisted, we need to tell useHistory
            // to go back twice, to skip the virtual id in the history stack
            state: { goBackTwice: eventData.id !== response.id },
          });
        });
      }
    } catch (error) {
      toast.error(t('eventUpsert.toast.error.editEvent'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!event && isLoading) return null;
  if (!event || isError) return null;

  const viewSubtitle = {
    current:
      getEventSteps(currentEvent.visibility === 'public').indexOf(
        currentEvent.step
      ) + 1,
    total: getEventSteps(currentEvent.visibility === 'public').length,
  };

  return (
    <View back={handleBack}>
      {hasInitialized && (
        <EventUpsert
          title={t('eventUpsert.title.editEvent')}
          subtitle={t('eventUpsert.subtitle', {
            current: viewSubtitle.current,
            total: viewSubtitle.total,
          })}
          event={currentEvent}
          onBack={handleBack}
          onSubmit={handleSubmit}
        />
      )}
      <LoadingFullscreen isLoading={isLoading} />
    </View>
  );
};
