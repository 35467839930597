import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useSearchParams } from 'react-router';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { SignupCard } from './signup-card';
import { LoginPrompt } from './ui/login-prompt';

export const SignupViewFocused = () => {
  const [searchParams] = useSearchParams();
  const prefilledEmail = searchParams.get('email') || undefined;
  const { isSm } = useResponsive();

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        backgroundImage: `url('/graphics/citrus-pine-green-background.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        py: 6,
        px: 2,
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      {!prefilledEmail && !isSm && <LoginPrompt />}
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: lightTheme.palette.contrast.white,
          maxWidth: 500,
          borderRadius: 4,
        }}
      >
        <SignupCard isFocused />
      </Stack>
    </Stack>
  );
};
