import * as api from '..';

/* ---- Experience Waitlist ----  */
const experienceWaitlistQueryKey = (experienceId: string) => [
  'waitlist',
  experienceId,
];

export const experienceWaitlistQuery = (experienceId: string) => ({
  queryKey: experienceWaitlistQueryKey(experienceId),
  queryFn: () => api.getExperienceWaitlist(experienceId),
  enabled: !!experienceId,
});
