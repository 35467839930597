import { CloseOutlined } from '@mui/icons-material';
import {
  alpha,
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  Fade,
  IconButton,
  Slide,
  Stack,
  SxProps,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Badge, BadgeProps } from '../badge/badge';

const DIALOG_BORDER_RADIUS = 16;

export type DialogAction = {
  label: string | ReactNode;
  type?: 'button' | 'submit';
  variant: 'primary' | 'secondary' | 'danger';
  disabled?: boolean;
  loading?: boolean;
  rightIcon?: ReactNode;
  onClick?: () => void;
};

export type DialogBaseProps = PropsWithChildren & {
  title?: string;
  description?: string;
  /**
   * Whether or not the dialog is open
   * Defaults to `true`
   */
  open?: boolean;
  /** Close handler for the dialog */
  onClose?: () => void;
  /**
   * Max width of the dialog on desktop, either
   * use breakpoints or a number for pixels
   * Defaults to `'sm'`
   */
  maxWidth?: DialogProps['maxWidth'] | number;
  /**
   * Whether or not the dialog should
   * take up the full height on mobile
   * Defaults to `true`
   */
  fullHeight?: boolean;
  /**
   * Whether or not the actions
   * should be added to the header
   * of the dialog on mobile.
   * Defaults to `false`
   */
  actionsInHeader?: boolean;
  /**
   * Whether or not to show the secondary action
   * on desktop, if added. The reason for having
   * this is due to always showing the secondary
   * action on mobile - but not on desktop.
   * Defaults to `false`
   */
  showSecondaryActionDesktop?: boolean;
  primaryAction?: DialogAction;
  secondaryAction?: DialogAction;

  /**
   * Optional badge to display in the header next to the title.
   */
  badge?: BadgeProps;

  /**
   * Optional URI for a logo to display.
   */
  logoUri?: string;
};

export default function DialogBase({
  title,
  description,
  open = true,
  onClose,
  maxWidth = 'sm',
  fullHeight = true,
  children,
  primaryAction,
  secondaryAction,
  actionsInHeader = true,
  showSecondaryActionDesktop = false,
  badge = undefined,
  logoUri = undefined,
}: DialogBaseProps) {
  const isMobile = useMediaQuery('@media(max-width: 749px)');

  // Determine if breakpoint or pixels should be used
  const isMaxWidthInPixels = typeof maxWidth === 'number';

  const hasPrimaryAction = !!primaryAction;
  const showSecondaryAction = showSecondaryActionDesktop && !!secondaryAction;
  const hasActions = hasPrimaryAction || showSecondaryAction;
  const showActionsInHeader = isMobile && actionsInHeader;
  const isTitleSticky = isMobile && showActionsInHeader;

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);

  const [scrolledPastTitle, setScrolledPastTitle] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const title = titleRef.current;

    const handleScroll = () => {
      if (!container || !title) return;
      setScrolledPastTitle(container.scrollTop > title.scrollHeight);
    };

    if (container && title) container.addEventListener('scroll', handleScroll);

    return () => {
      if (container && title) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, titleRef.current]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // Max width on desktop
      maxWidth={isMaxWidthInPixels ? false : maxWidth}
      // Show sheet when below 750px
      fullWidth
      disablePortal
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : Fade}
      PaperProps={{
        sx: isMobile
          ? {
              ...MOBILE_STYLES,
              // On mobile we can opt to not have a full sheet.
              // Useful for e.g.  delete dialogs
              height: fullHeight ? '90%' : 'auto',
            }
          : {
              ...DESKTOP_STYLES,
              // We need to set the max width here if using pixels
              // instead of a breakpoint
              maxWidth: isMaxWidthInPixels ? maxWidth : undefined,
            },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(11px)',
            backgroundColor: alpha('#000000', 0.3),
          },
        },
      }}
    >
      {isTitleSticky && (
        <DialogHeader
          title={title}
          showActionsInHeader={showActionsInHeader}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          showTitle={scrolledPastTitle}
        />
      )}
      {!isTitleSticky && (
        <DialogTitle
          title={title}
          description={description}
          isMobile={false}
          onClose={onClose}
          badge={badge}
          logoUri={logoUri}
        />
      )}
      <DialogContent sx={{ padding: 0 }} ref={containerRef}>
        {isTitleSticky && (
          <DialogTitle
            title={title}
            description={description}
            isMobile={true}
            onClose={onClose}
            ref={titleRef}
            badge={badge}
            logoUri={logoUri}
          />
        )}
        {children}
      </DialogContent>
      {hasActions && !showActionsInHeader && (
        <Stack
          sx={{
            marginTop: 3,
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: isMobile ? 1.5 : 2,
          }}
        >
          {showSecondaryActionDesktop && secondaryAction && (
            <Button
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
              {...secondaryAction}
            >
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <Button
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
              {...primaryAction}
            >
              {primaryAction.label}
            </Button>
          )}
        </Stack>
      )}
    </Dialog>
  );
}

const MOBILE_STYLES: SxProps = {
  position: 'fixed',
  bottom: 0,
  paddingY: 3,
  paddingX: 2,
  borderTopLeftRadius: DIALOG_BORDER_RADIUS,
  borderTopRightRadius: DIALOG_BORDER_RADIUS,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  boxShadow: 'none',
} as const;

const DESKTOP_STYLES: SxProps = {
  width: '100%',
  padding: 4,
  boxShadow: 'none',
  borderRadius: 2,
} as const;

type DialogHeaderProps = Pick<
  DialogBaseProps,
  'title' | 'secondaryAction' | 'primaryAction'
> & {
  showTitle: boolean;
  showActionsInHeader: boolean;
};

function DialogHeader({
  title,
  showTitle,
  secondaryAction,
  primaryAction,
  showActionsInHeader,
}: DialogHeaderProps) {
  const hasActions = useMemo(
    () => !!primaryAction || !!secondaryAction,
    [primaryAction, secondaryAction]
  );
  const showActions = useMemo(
    () => hasActions && showActionsInHeader,
    [hasActions, showActionsInHeader]
  );

  if (!showActions) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        marginBottom: 3,
      }}
    >
      {showActions && secondaryAction && (
        <Stack sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          <Button
            variant="text"
            size="small"
            onClick={() => secondaryAction.onClick?.()}
            type={secondaryAction.type}
            disabled={secondaryAction.disabled || secondaryAction.loading}
            style={{
              height: 'auto',
              padding: 0,
              minWidth: 0,
              color: lightTheme.palette.action.a300,
            }}
          >
            {secondaryAction.label}
          </Button>
        </Stack>
      )}
      {title && (
        <Text
          style={{
            transition: 'opacity 0.1s ease-in-out',
            opacity: showTitle ? 1 : 0,
            gridColumn: '2 / span 3',
          }}
          textAlign="center"
        >
          {title}
        </Text>
      )}
      {showActions && primaryAction && (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            gridColumnStart: 5,
          }}
        >
          <Button
            variant="text"
            size="small"
            onClick={() => primaryAction.onClick?.()}
            type={primaryAction.type}
            disabled={primaryAction.disabled || primaryAction.loading}
            style={{
              height: 'auto',
              padding: 0,
              minWidth: 0,
              fontWeight: 500,
              color:
                primaryAction.variant === 'danger'
                  ? lightTheme.palette.error.e300
                  : lightTheme.palette.action.a300,
            }}
          >
            {primaryAction.label}
          </Button>
        </Stack>
      )}
    </Box>
  );
}

type DialogTitleProps = Pick<
  DialogBaseProps,
  'title' | 'description' | 'onClose' | 'badge' | 'logoUri'
> & {
  isMobile: boolean;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = forwardRef(function DialogTitle(
  { title, description, onClose, isMobile, badge, logoUri }: DialogTitleProps,
  ref: Ref<HTMLSpanElement>
) {
  const hasContent = useMemo(
    () => !!title || !!description,
    [title, description]
  );

  if (!hasContent && isMobile) {
    return null;
  }

  if (!hasContent && !isMobile) {
    return (
      <Stack position="relative">
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            display: 'flex',
            position: 'absolute',
            zIndex: 10,
            top: 0,
            right: 0,
            color: lightTheme.palette.contrast.black,
          }}
        >
          <CloseOutlined sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        gap: 1,
        marginBottom: 3,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: logoUri || title ? 'space-between' : 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        <Stack>
          {logoUri && (
            <Box
              component="img"
              src={logoUri}
              sx={{
                height: '74px',
                width: '74px',
                borderRadius: '10px',
                boxShadow: lightTheme.shadows.medium,
                marginBottom: '32px',
              }}
            />
          )}
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Stack>
              {title && (
                <Text variant="medium" fontSize="xlarge" ref={ref}>
                  {title}
                </Text>
              )}
            </Stack>
            {badge && (
              <Box component="span" sx={{ marginLeft: '8px' }}>
                <Badge {...badge} />
              </Box>
            )}
          </Stack>
        </Stack>
        {!isMobile && (
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              display: 'flex',
              color: lightTheme.palette.contrast.black,
            }}
          >
            <CloseOutlined sx={{ height: 20, width: 20 }} />
          </IconButton>
        )}
      </Stack>
      {description && (
        <Text
          color={lightTheme.palette.neutral.n400}
          variant="normal"
          fontSize="small"
        >
          {description}
        </Text>
      )}
    </Stack>
  );
});
