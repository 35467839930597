import { CheckOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router';

import { ActionKey } from '../edit-experience';

type AutoSaveStatusProps = {
  flowBeingSubmitted: ActionKey | undefined;
  unknownError?: boolean;
};

export const AutoSaveStatus = ({
  flowBeingSubmitted,
  unknownError,
}: AutoSaveStatusProps) => {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState(false);
  const { state } = useNavigation();

  const isSaving = useMemo(() => {
    if (
      !flowBeingSubmitted ||
      flowBeingSubmitted === 'publish' ||
      flowBeingSubmitted === 'toggle-auto-translate'
    )
      return false;
    return state === 'submitting';
  }, [flowBeingSubmitted, state]);

  // We do not want to show the status immediately when the
  // component is mounted because the animation will trigger
  useEffect(() => {
    if (showStatus) return;
    if (isSaving) setShowStatus(true);
  }, [isSaving, showStatus]);

  if (unknownError) {
    return (
      <StatusWrapper
        label={t('experience.edit.autoSave.label.error')}
        icon={<WarningAmberOutlined fontSize="inherit" />}
        isError
      />
    );
  }

  if (!showStatus) return null;

  if (isSaving) {
    return (
      <StatusWrapper
        label={t('experience.edit.autoSave.label.saving')}
        icon={<CircularProgress size={12} />}
      />
    );
  }

  return (
    <StatusWrapper
      label={t('experience.edit.autoSave.label.saved')}
      icon={<CheckOutlined fontSize="inherit" />}
      fadeOut
    />
  );
};

type StatusWrapperProps = {
  label: string;
  icon: ReactNode;
  fadeOut?: boolean;
  isError?: boolean;
};

const StatusWrapper = ({
  label,
  icon,
  fadeOut,
  isError,
}: StatusWrapperProps) => {
  return (
    <Stack
      sx={{
        color: isError
          ? lightTheme.palette.error.e300
          : lightTheme.palette.neutral.n400,
        gap: 0.5,
        alignItems: 'center',
        flexDirection: 'row',
        animation: fadeOut ? 'fade-out 0.25s forwards ease-out 2s' : undefined,
        '@keyframes fade-out': {
          '0%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0,
          },
        },
      }}
    >
      <Text fontSize="small" color="inherit">
        {label}
      </Text>
      {icon}
    </Stack>
  );
};
