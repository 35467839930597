import type { API } from '@understory-io/grow-types';

import type { TProvider } from '../Hooks/useMarketing';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export type TPermission = {
  created: string;
  name: string;
  email: string;
  customerId: string;
  source: string;
};

export const getProviders = async () => {
  if (typeof apiServices.marketing === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.get<TProvider[]>(
    `${apiServices.marketing}/providers`
  );

  return data;
};

export const updateProvider = async (payload: Record<string, any>) => {
  if (typeof apiServices.marketing === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${apiServices.marketing}/provider`,
    payload
  );

  return data;
};

export const getPermissions = async () => {
  if (typeof apiServices.marketing === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.get<TPermission[]>(
    `${apiServices.marketing}/consents`
  );

  return data;
};

export const getAdvertisementPlans = async () => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_INTERNAL_URL is not defined'
    );
  }

  const { data } = await api.get<API.GetAdPlans.Response>(
    `${apiServices.internalApi}/v1/grow/ad-plans`
  );

  return data.items;
};

export const createAdvertisementPlan = async (
  payload: API.CreateAdPlan.Request
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_INTERNAL_URL is not defined'
    );
  }

  const { data } = await api.post<API.CreateAdPlan.Response>(
    `${apiServices.internalApi}/v1/grow/ad-plans`,
    payload
  );

  return data.item;
};

export const updateAdvertisementPlan = async (
  payload: API.GetAdPlans.Response['items'][number]
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_INTERNAL_URL is not defined'
    );
  }

  const { data } = await api.put<API.CreateAdPlan.Response>(
    `${apiServices.internalApi}/v1/grow/ad-plans/${payload.id}`,
    payload
  );

  return data.item;
};

export const getAdvertisementMetrics = async () => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_INTERNAL_URL is not defined'
    );
  }

  const { data } = await api.get<API.GetAdPlansMetrics.Response>(
    `${apiServices.internalApi}/v1/grow/ad-plans/metrics`
  );

  return data.item;
};

export const getAdPreview = async (adPlanId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_INTERNAL_URL is not defined'
    );
  }

  const { data } = await api.get<API.GetAdPreview.Response>(
    `${apiServices.internalApi}/v1/grow/ad-plans/${adPlanId}/preview`
  );

  return data.item;
};
