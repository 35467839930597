import { Box, Radio, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { OnboardingOption } from '../../config';

interface DecisionOptionCardProps {
  option: OnboardingOption;
  selected: boolean;
  onSelect: (option: OnboardingOption) => void;
}

export const DecisionOptionCard = ({
  option,
  selected,
  onSelect,
}: DecisionOptionCardProps) => {
  const { t } = useTranslate(`onboardingFlow.step.decision.${option.id}`);

  return (
    <Box
      onClick={() => onSelect(option)}
      sx={{
        userSelect: 'none',
        outlineWidth: selected ? '2px' : '1px',
        outlineStyle: 'solid',
        outlineColor: selected
          ? lightTheme.palette.action.a300
          : lightTheme.palette.neutral.n100,
        borderRadius: '24px',
        padding: '24px 16px',
        cursor: 'pointer',
        position: 'relative',
        backgroundColor: 'white',
        minHeight: '120px',
        display: 'flex',
        '&:hover': {
          outlineColor: selected
            ? lightTheme.palette.action.a300
            : lightTheme.palette.neutral.n200,
        },
      }}
    >
      {option.recommended && (
        <Box
          sx={{
            position: 'absolute',
            top: -12,
            left: 12,
            backgroundColor: lightTheme.palette.action.a300,
            color: 'white',
            padding: '4px 12px',
            borderRadius: 4,
            fontSize: lightTheme.typography.xsmall.normal.fontSize,
            fontWeight: 600,
          }}
        >
          {t('recommended', 'utils.generic')}
        </Box>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack gap={0.5} flex={1}>
          <Text variant="medium" fontSize="large">
            {t('title')}
          </Text>
          <Text
            color={lightTheme.palette.neutral.n300}
            variant="medium"
            fontSize="small"
          >
            {t('description')}
          </Text>
        </Stack>
        <Radio
          checked={selected}
          sx={{
            color: lightTheme.palette.neutral.n200,
            '&.Mui-checked': {
              color: lightTheme.palette.action.a300,
            },
          }}
        />
      </Stack>
    </Box>
  );
};
