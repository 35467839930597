import { Box, Link, Stack, styled } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../Ampli';
import { updateMyProfile } from '../../../Api';
import { companyUsersQuery, userProfileQuery } from '../../../Api/queries';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { LanguageSelect } from '../../../Components/LanguageSelect/LanguageSelect';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import {
  trackProfileLanguageSelected,
  trackProfileLanguageSettingsOpened,
} from '../../../tracking/settings/settings-events';
import { ProfileSettingsInputs } from './profile-settings-inputs';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const userProfile = await client.fetchQuery(userProfileQuery());

  return { userProfile };
};

export type ProfileFormData = {
  name: string;
  phone: string;
  pictures: { profile: { key: string; url: string } } | null;
};

export default function ProfileSettings() {
  const { userProfile } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const { state } = useNavigation();

  const { t } = useTranslation();
  const submit = useSubmit();

  const formMethods = useForm<ProfileFormData>({
    defaultValues: userProfile as ProfileFormData,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (actionData?.updatedUserProfile) {
      reset(actionData.updatedUserProfile);
    }
  }, [actionData, reset]);

  const onSubmit = (data: ProfileFormData) => {
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.profilePageViewed()), [fireOnce]);

  return (
    <Stack sx={{ gap: 5 }}>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ProfileSettingsInputs
            userId={userProfile.id}
            email={userProfile.email}
          />
          <FixedActionsBar
            onReset={() => reset()}
            isSubmitting={state === 'submitting'}
            unsavedChanges={isDirty}
          />
        </Form>
      </FormProvider>
      <Stack sx={{ gap: 1 }}>
        <Box>
          <LanguageSelect
            onChange={(key) =>
              trackProfileLanguageSelected(key, userProfile.id)
            }
            renderComp={(onClick) => (
              <StyledLink
                fontSize="0.88em"
                onClick={(e) => {
                  onClick?.(e);
                  trackProfileLanguageSettingsOpened();
                }}
              >
                {t('settings.profile.advancedSettings.changeLanguage')}
              </StyledLink>
            )}
          />
        </Box>
        <StyledLink href="javascript:CookieConsent.renew()" fontSize="0.88em">
          {t('settings.profile.advancedSettings.cookiePreferences')}
        </StyledLink>
      </Stack>
    </Stack>
  );
}

const StyledLink = styled(Link)({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { name, phone, pictures } =
        (await request.json()) as ProfileFormData;
      const userProfile = await client.fetchQuery(userProfileQuery());

      const updatedUserProfile = {
        ...userProfile,
        name,
        phone,
        pictures,
      };

      await updateMyProfile(updatedUserProfile);

      client.invalidateQueries({
        queryKey: companyUsersQuery().queryKey,
      });
      client.invalidateQueries({
        queryKey: userProfileQuery().queryKey,
      });

      return { updatedUserProfile };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
