import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { Role } from '@understory-io/utils-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigation } from 'react-router';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { RoleFormCapabilities } from './capabilities/role-form-capabilities';
import { RoleFormPermissions } from './permissions/role-form-permissions';
import { RoleFormDetails } from './role-form-details';
import {
  RoleFormSchema,
  useRoleFormSchemaValidation,
} from './use-role-form-validation';

export const RoleForm = ({
  initialData,
  mode = 'create',
  onSubmit,
}: {
  initialData?: Role;
  mode?: 'create' | 'edit';
  onSubmit: (values: RoleFormSchema) => void;
}) => {
  const { t } = useTranslate('settings.roles.dialog.form');

  const { state } = useNavigation();

  const roleFormSchemaValidation = useRoleFormSchemaValidation(t);

  const form = useForm<RoleFormSchema>({
    defaultValues: getRoleFormDefaultValues(initialData),
    resolver: yupResolver(roleFormSchemaValidation),
  });

  const isSubmitDisabled = state === 'submitting';

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
        gap={5}
      >
        <RoleFormDetails />
        <RoleFormPermissions />
        <RoleFormCapabilities />
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitDisabled}
        >
          {mode === 'create'
            ? t('addThisRole', 'settings.team.roles')
            : t('save', 'buttons')}
        </Button>
      </Stack>
    </FormProvider>
  );
};

const getRoleFormDefaultValues = (initialData?: Role): RoleFormSchema => ({
  name: initialData?.name || '',
  description: initialData?.description || '',
  scopes: initialData?.scopes || ['experience', 'event', 'booking'],
  guideAccess:
    initialData?.claims?.find((claim) => claim.key === 'event:assignedGuides')
      ?.value === 'me'
      ? 'own'
      : 'all',
  experienceAccessIds:
    initialData?.claims
      ?.find((claim) => claim.key === 'experience:id')
      ?.value.split(',') || [],
});
