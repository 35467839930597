import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { FormSchema } from '../use-form-validation';

export const NameField = () => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.dialog.create'
  );
  const {
    register,
    formState: { errors },
  } = useFormContext<FormSchema>();

  return (
    <TextField
      label={t('nameInputLabel')}
      fullWidth
      variant="outlined"
      sx={{
        marginTop: '4px',
      }}
      InputProps={{
        sx: {
          paddingBottom: 0.75,
          fontSize: 44,
          lineHeight: '100%',
        },
      }}
      {...register('keyName')}
      error={!!errors.keyName}
      helperText={errors.keyName ? t('required', 'utils.errors') : ''}
    />
  );
};
