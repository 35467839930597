import { endOfWeek, startOfWeek } from 'date-fns';
import { createContext, useContext, useEffect, useState } from 'react';

import { OverviewPageViewedProperties } from '../../Ampli';
import { useProfile } from '../../Hooks/useProfile';
import { trackOverviewPageViewed } from '../../tracking/overview/trackOverviewPageViewed';
import { DashboardPageState } from './types';
import { useDashboardMetrics } from './use-dashboard-metrics';

export const DashboardPageContext = createContext<DashboardPageState | null>(
  null
);

export const useDashboardPageContext = () => {
  const context = useContext(DashboardPageContext);
  if (!context) {
    throw new Error(
      'useDashboardPageContext must be used within a DashboardPageContextProvider'
    );
  }
  return context;
};

interface DashboardPageContextProviderProps {
  children: React.ReactNode;
}

export const DashboardPageContextProvider = ({
  children,
}: DashboardPageContextProviderProps) => {
  const { defaultCurrency, me } = useProfile();

  const [overviewTrackingData, setOverviewTrackingData] =
    useState<OverviewPageViewedProperties>();

  const [fromDate, setFromDate] = useState(() => startOfWeek(new Date()));
  const [toDate, setToDate] = useState(() => endOfWeek(new Date()));
  const [experienceFilter, setExperienceFilter] = useState<string[]>([]);

  const [hasTrackedView, setHasTrackedView] = useState(false);

  const {
    isLoading: isLoadingMetrics,
    isError,
    graphSeries,
    categoryTotals,
    metadata,
  } = useDashboardMetrics({
    from: fromDate,
    to: toDate,
    experience: experienceFilter,
  });

  useEffect(() => {
    if (categoryTotals && !isLoadingMetrics && !hasTrackedView) {
      setHasTrackedView(true);
      const properties = trackOverviewPageViewed(
        categoryTotals,
        defaultCurrency,
        fromDate,
        toDate,
        me.data?.id
      );
      setOverviewTrackingData(properties);
    }
  }, [
    categoryTotals,
    isLoadingMetrics,
    defaultCurrency,
    fromDate,
    toDate,
    me,
    hasTrackedView,
  ]);

  return (
    <DashboardPageContext.Provider
      value={{
        isLoadingMetrics,
        isError,
        graphSeries,
        categoryTotals,
        metadata,
        fromDate,
        toDate,
        experienceFilter,
        overviewTrackingData,
        setFromDate,
        setToDate,
        setExperienceFilter,
        setOverviewTrackingData,
      }}
    >
      {children}
    </DashboardPageContext.Provider>
  );
};
