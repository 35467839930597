import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router';

import { bookingQuery } from '../../../Api/queries';
import { queryClient } from '../../../query-client';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (!id) {
    throw new Response('Booking id is required', { status: 400 });
  }

  const booking = await queryClient.fetchQuery(bookingQuery(id));

  return { booking };
};

export function BookingDetails() {
  const { booking } = useLoaderData<LoaderData>();

  console.log(booking);

  return <div>Booking detail</div>;
}

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const { id } = params;
  if (!id) {
    throw new Response('Booking id is required', { status: 400 });
  }

  return {};
};
