import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Experience,
  ListExperiencesQuery,
} from '@understory-io/experiences-types';

import * as api from '../Api';
import { useProfile } from './useProfile';

export type Localized = Record<string, string>;

/** This is just an alias for now to limit number of files changed here */
export type IExperience = Experience;

export type Variant = IExperience['price']['variants'][number];

export const useExperience = (id?: string, options?: ListExperiencesQuery) => {
  const queryClient = useQueryClient();

  const {
    company: { data: company },
  } = useProfile();

  const ExperienceQueryKey = ['experience', id];
  const ExperiencesQueryKey = ['experiences', company?.id, options];

  const experience = useQuery({
    queryKey: ExperienceQueryKey,
    queryFn: async () => {
      return await api.getExperience(id!);
    },
    enabled: !!id,
  });

  const experiences = useQuery({
    queryKey: ExperiencesQueryKey,
    queryFn: async () => {
      const experiences = await api.getExperiences(options);
      for (const experience of experiences) {
        queryClient.setQueryData(
          ['experience', company?.id, experience.id],
          experience
        );
      }
      return experiences;
    },

    enabled: Boolean(company?.id),
  });

  const deleteExperience = useMutation({
    mutationFn: (id: string) => api.deleteExperience(id),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ExperienceQueryKey,
      });

      queryClient.setQueryData<IExperience[]>(ExperiencesQueryKey, (prev) => {
        return prev?.filter((el) => el.id !== id) ?? [];
      });
    },

    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ExperienceQueryKey,
      });
      experiences.refetch();
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events', 'bookings'],
      });
    },
  });

  const discardExperience = useMutation({
    mutationFn: (id: string) => api.discardExperience(id),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ExperienceQueryKey,
      });
    },

    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ExperienceQueryKey,
      });
      experiences.refetch();
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
      queryClient.invalidateQueries({
        queryKey: ['experience', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['events', 'bookings'],
      });
    },
  });

  return {
    experience,
    experiences,
    deleteExperience,
    discardExperience,
  };
};
