import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useInternalIntegrations } from '../../domain/use-internal-integrations';
import { Key } from './components/key-item';
import { KeyList } from './components/key-list';
import { LoadingKeysError } from './components/loading-error';
import { LoadingKeys } from './components/loading-keys';
import { NoKeys } from './components/no-keys';
import { CreateKeyDialog, CreateKeyDialogResult } from './create-key-dialog';
import { KeyReadyDialog, KeyReadyDialogProps } from './key-ready-dialog';
import { UpdateKeyDialog } from './update-key-dialog';

export const InternalIntegrationKeysDialog = NiceModal.create(() => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.dialog.main'
  );

  const { clients, revoke, loading } = useInternalIntegrations();
  const [loadedInitially, setLoadedInitially] = useState(false);

  useEffect(() => {
    if (clients.data) {
      setLoadedInitially(true);
    }
  }, [clients.data]);

  useEffect(() => {
    if (clients.isError) {
      console.error('Failed to fetch internal integration keys', clients.error);
      toast.error(t('generic', 'utils.errors'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients.error, clients.isError]);

  const modal = useModal();

  const handleClose = () => {
    modal.remove();
  };

  const handleCreateNewKey = async () => {
    modal.hide();
    try {
      const response = await NiceModal.show<
        CreateKeyDialogResult,
        void & NiceModalHocProps,
        void & NiceModalHocProps
      >(CreateKeyDialog);

      await NiceModal.show<
        void,
        KeyReadyDialogProps & NiceModalHocProps,
        KeyReadyDialogProps
      >(KeyReadyDialog, response);
    } catch (e) {
      /* empty */
    } finally {
      modal.show();
    }
  };

  const handleRevoke = async (integrationKey: Key) => {
    try {
      await revoke.mutateAsync(integrationKey.clientId);
      toast.success(t('toastKeyRevoked'));

      ampli.internalIntegrationKeyInteraction({
        action: 'revoked',
      });
    } catch (e) {
      console.error('Failed to revoke key', e);
      toast.error(t('generic', 'utils.errors'));
    }
  };

  const handleUpdate = async (integrationKey: Key) => {
    modal.hide();
    await NiceModal.show(UpdateKeyDialog, integrationKey);
    modal.show();
  };

  const loadingFirstTime = loading && !loadedInitially;

  return (
    <DialogBase
      open={modal.visible}
      onClose={handleClose}
      maxWidth="md"
      fullHeight={true}
      title={t('title')}
      description={t('description')}
      primaryAction={{
        label: t('button.create'),
        variant: 'primary',
        onClick: handleCreateNewKey,
      }}
      secondaryAction={{
        label: t('back', 'utils.generic'),
        variant: 'secondary',
        onClick: handleClose,
      }}
    >
      <Stack
        sx={{
          marginTop: 1,
          marginBottom: 2,
        }}
      >
        {loadingFirstTime && <LoadingKeys />}
        {!loadingFirstTime &&
          (clients.data?.clients?.length === 0 ? (
            <NoKeys label={t('noKeys')} />
          ) : clients.isError ? (
            <LoadingKeysError />
          ) : (
            <KeyList
              keys={clients.data?.clients || []}
              loading={loading && loadedInitially}
              title={t('activeKeysTitle')}
              clientIdLabel={t('clientIdLabel')}
              onRevoke={handleRevoke}
              onUpdate={handleUpdate}
            />
          ))}
      </Stack>
    </DialogBase>
  );
});
