import { SxProps } from '@mui/material';
import { captureException } from '@sentry/react';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { useMemo, useState } from 'react';

import { getURLFromMediaItem } from '../../../../../Components/DraggableGallery/Media';
import { useUploader } from '../../../../onboarding-deprecated/media-upload/use-uploader';
import {
  trackExperienceEditMediaDeleted,
  trackExperienceEditMediaUploaded,
} from '../../utils/tracking-helper';
import { MediaDropzone } from './media-dropzone';
import { MediaList } from './media-list';

type MediaUploadProps = {
  experience: RegularExperience;
  handleMediaChange: (media: MediaItem[]) => void;
};

export const MAX_FILES_LIMIT = 10;

export const Media = ({ experience, handleMediaChange }: MediaUploadProps) => {
  const [covers, setCovers] = useState(
    experience.media.cover?.slice(0, MAX_FILES_LIMIT) as MediaItem[]
  );

  const handleChange = (media: MediaItem[]) => {
    setCovers(media);
    handleMediaChange(media);
  };

  const { onDrop, filestoBeUploaded } = useUploader({
    id: experience.id,
    fileType: 'cover',
    modelType: 'experience',
    onUploaded: (medias) => {
      medias.map((media) => {
        trackExperienceEditMediaUploaded({
          experience_id: experience.id,
          media_type: media.type,
        });
      });
      const newMedia = [...covers, ...medias];
      handleChange(newMedia);
    },
  });

  const amountOfFilesToAccept = useMemo(
    () => MAX_FILES_LIMIT - (covers.length + filestoBeUploaded.length),
    [covers, filestoBeUploaded]
  );

  const isDisabled = useMemo(
    () => amountOfFilesToAccept === 0,
    [amountOfFilesToAccept]
  );

  const handleDelete = async (url: string) => {
    const filteredMedia = covers.filter(
      (media) => getURLFromMediaItem(media) !== url
    );

    const deletedMedia = covers.find(
      (media) => getURLFromMediaItem(media) === url
    );

    try {
      handleChange(filteredMedia);

      // TODO: Handle delete in publish
      // await deleteUpload(undefined, url);

      if (!deletedMedia) return;

      trackExperienceEditMediaDeleted({
        experience_id: experience.id,
        media_type: deletedMedia.type,
      });
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <MediaDropzone
      isMediaUploadDisabled={isDisabled}
      amountOfFilesToAccept={amountOfFilesToAccept}
      onDrop={onDrop}
    >
      <MediaList
        uploadedMedia={covers}
        mediaBeingUploaded={filestoBeUploaded}
        onChangeOrder={handleChange}
        onDelete={handleDelete}
        isDisabled={isDisabled}
      />
    </MediaDropzone>
  );
};

export const defaultItemStyling: SxProps = {
  overflow: 'hidden',
  position: 'relative',
  gap: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 1.5,
  backgroundColor: lightTheme.palette.contrast.surface1,
};
