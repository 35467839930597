import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { lightTheme, SpinnerIcon } from '@understory-io/pixel';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ampli } from '../../../../../Ampli';
import DialogBase from '../../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useInternalIntegrations } from '../../domain/use-internal-integrations';
import { NameField } from './components/name-field';
import { Scopes } from './components/scopes';
import { FormSchema, useFormValidation } from './use-form-validation';

export type CreateKeyDialogResult = {
  name: string;
  clientId: string;
  secretKey: string;
  scopes: string[];
};

export const CreateKeyDialog = NiceModal.create(() => {
  const { t } = useTranslate(
    'settings.integrations.internalIntegrationKeys.dialog.create'
  );

  const modal = useModal();
  const { create } = useInternalIntegrations();

  const handleClose = () => {
    modal.reject();
    modal.remove();
  };

  const onSubmit = async (data: FormSchema) => {
    try {
      const client = await create.mutateAsync({
        name: data.keyName,
        scopes: data.scopes,
      });

      ampli.internalIntegrationKeyInteraction({
        action: 'created',
      });

      modal.resolve({
        name: data.keyName,
        clientId: client.clientId,
        secretKey: client.clientSecret!,
        scopes: data.scopes,
      } as CreateKeyDialogResult);
      modal.remove();
    } catch (e) {
      console.error('Failed to create internal integration key', e);
      toast.error(t('generic', 'utils.errors'));
    }
  };

  const form = useForm<FormSchema>({
    resolver: yupResolver(useFormValidation(t)),
    defaultValues: {
      keyName: '',
      scopes: [],
    },
  });

  const {
    formState: { isSubmitting },
  } = form;

  return (
    <DialogBase
      open={modal.visible}
      onClose={handleClose}
      maxWidth="sm"
      fullHeight={true}
      title={t('title')}
      primaryAction={{
        label: isSubmitting ? (
          <SpinnerIcon theme={lightTheme} />
        ) : (
          t('create', 'buttons')
        ),
        variant: 'primary',
        type: 'submit',
        onClick: form.handleSubmit(onSubmit),
        loading: isSubmitting,
      }}
      secondaryAction={{
        label: t('back', 'buttons'),
        variant: 'secondary',
        type: 'button',
        onClick: handleClose,
        loading: isSubmitting,
      }}
    >
      <FormProvider {...form}>
        <Stack
          component="form"
          noValidate
          gap={3}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <NameField />
          <Scopes />
        </Stack>
      </FormProvider>
    </DialogBase>
  );
});
