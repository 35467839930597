import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { FC, useEffect } from 'react';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { StepProps } from './grow-advertisement-flow-steps';

export const MeetingStep: FC<StepProps> = ({ onNext }) => {
  const { t } = useTranslate('grow.advertisement.getStarted.meeting');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Stack gap={4}>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/emil-understory/onboarding-meeting?primary_color=022c12"
        style={{ minWidth: '320px', height: '400px' }}
      />

      <Button variant="primary" size="large" onClick={() => onNext()}>
        {t('done', 'utils.generic')}
      </Button>
    </Stack>
  );
};
