import { useQuery } from '@tanstack/react-query';

import * as api from '../Api';

export const useAdPlans = (adPlanId?: string) => {
  const AdPlansQueryKey = ['ad-plans'];
  const MetricsQueryKey = ['ad-metrics'];
  const PreviewQueryKey = ['ad-preview', adPlanId];

  const adPlans = useQuery({
    queryKey: AdPlansQueryKey,
    queryFn: async () => {
      const adPlans = await api.getAdvertisementPlans();
      return adPlans;
    },
  });

  const metrics = useQuery({
    queryKey: MetricsQueryKey,
    queryFn: async () => {
      const metrics = await api.getAdvertisementMetrics();
      return metrics;
    },
  });

  const adPreview = useQuery({
    queryKey: PreviewQueryKey,
    queryFn: async () => {
      const adPreview = await api.getAdPreview(adPlanId ?? '');
      return adPreview;
    },
    enabled: !!adPlanId,
  });

  return { adPlans, metrics, adPreview };
};
