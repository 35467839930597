import { TEventFilter, transformEvents } from '../../Utils/eventHelpers';
import * as api from '..';

export const EVENT_LIMIT = 100;

/* ---- Experience Events ----  */
type ExperienceQuery = { page?: number; limit?: number } & TEventFilter;

export const eventsInExperienceQueryKey = (experienceId: string) => [
  'events',
  experienceId,
];

export const eventsInExperienceQuery = (
  experienceId: string,
  options: ExperienceQuery
) => ({
  queryKey: [...eventsInExperienceQueryKey(experienceId), options],
  queryFn: async () => {
    const { events, ...responseHeaders } = await api.getEventsInExperience(
      experienceId,
      options.page ? (options.page - 1) * (options.limit ?? EVENT_LIMIT) : 0,
      options.limit ?? EVENT_LIMIT,
      options
    );
    return { events: transformEvents(events), ...responseHeaders };
  },
  enabled: !!experienceId,
});

/* Event */
export const eventQueryKey = (eventId: string) => ['event', eventId];

export const eventQuery = (eventId: string) => ({
  queryKey: eventQueryKey(eventId),
  queryFn: async () => {
    const fetched = await api.getEvent(eventId);
    return transformEvents([fetched])[0];
  },
  enabled: !!eventId,
});
