import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useNavigateWithParams } from '../../features/experiences/edit/utils/use-navigate-with-params';
import { useTranslate } from '../../Hooks/useTranslate';
import DialogBase, { DialogBaseProps } from './dialog-base';

type RouteDialogsProps = Omit<DialogBaseProps, 'secondaryAction'> & {
  fallbackPath?: string;
};

export default function RouteDialog({
  fallbackPath = '../',
  children,
  onClose,
  ...props
}: RouteDialogsProps) {
  const { t } = useTranslate('utils.generic');

  const location = useLocation();
  const navigate = useNavigate();
  const navigateWithParams = useNavigateWithParams();

  const handleClose = useCallback(() => {
    onClose?.();
    if (location.key === 'default') {
      navigateWithParams(fallbackPath, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  }, [fallbackPath, location.key, navigate, navigateWithParams, onClose]);

  return (
    <DialogBase
      onClose={handleClose}
      secondaryAction={{
        label: t('cancel'),
        variant: 'secondary',
        type: 'button',
        onClick: handleClose,
      }}
      {...props}
    >
      {children}
    </DialogBase>
  );
}
