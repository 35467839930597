import { useCallback } from 'react';

import {
  BackofficeLanguage,
  StorefrontLanguage,
  supportedBackofficeLanguages,
  supportedStorefrontLanguages,
} from '../../i18n/config';
import { useLocale } from './use-locale.context';

export const useLanguages = () => {
  const { languageNames, regionNames } = useLocale();

  const languageOption = useCallback(
    (locale: BackofficeLanguage | StorefrontLanguage) => {
      const language = locale.split('-')[0];
      // Manually add region when locale is 'en'
      const region = locale === 'en' ? 'GB' : locale.split('-')[1];

      const label = languageNames.of(language);

      return {
        locale,
        language,
        country: region,
        label: label ? label.charAt(0).toUpperCase() + label.slice(1) : '',
        regionLabel: region ? regionNames.of(region) : undefined,
        flagSrc: `/flags/svg/${region ? region.toLowerCase() : language}.svg`,
      };
    },
    [languageNames, regionNames]
  );

  const storefrontLanguages = supportedStorefrontLanguages;

  const backofficeLanguages = supportedBackofficeLanguages;

  return {
    languageOption,
    storefrontLanguages,
    backofficeLanguages,
  };
};
