import { QueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLoaderData, useMatch } from 'react-router';

import { userInfoQuery } from '../../Api/queries';
import { TabLayout } from '../../Components/layout/tab-layout';
import { launchDarklyClient } from '../../launch-darkly';
import routes from '../../Utils/routes';

const tabKeys = [
  'profile',
  'users',
  'billing',
  'connect',
  'integrations',
  'notifications',
  'terms',
] as const;
type TabKey = (typeof tabKeys)[number];

type TabConfig = {
  key: TabKey;
  label: string;
  href: string;
};

const TAB_CONFIG = (t: TFunction): Record<TabKey, TabConfig> => ({
  profile: {
    key: 'profile',
    label: t('settings.navigation.profile'),
    href: routes.settings.profile,
  },
  users: {
    key: 'users',
    label: t('settings.navigation.users'),
    href: routes.settings.users.index,
  },
  billing: {
    key: 'billing',
    label: t('settings.navigation.billing'),
    href: routes.settings.billing,
  },
  connect: {
    key: 'connect',
    label: t('settings.navigation.connect'),
    href: routes.settings.connect,
  },
  integrations: {
    key: 'integrations',
    label: t('settings.navigation.integrations'),
    href: routes.settings.integrations,
  },
  notifications: {
    key: 'notifications',
    label: t('settings.navigation.notifications'),
    href: routes.settings.notifications,
  },
  terms: {
    key: 'terms',
    label: t('settings.navigation.terms'),
    href: routes.settings.terms,
  },
});

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const user = await client.fetchQuery(userInfoQuery());

  await launchDarklyClient.waitForInitialization();
  const isBillingSectionEnabled = launchDarklyClient.variation(
    'feature-billing-settings',
    false
  );

  const isAdmin = user.scope?.includes('admin');
  const canEditCompany = user.scope?.includes('organization.write');

  const tabs: TabKey[] = ['profile'];
  if (canEditCompany) {
    tabs.push('users');
  }

  if (isBillingSectionEnabled && isAdmin) {
    tabs.push('billing');
  }

  if (isAdmin) {
    tabs.push('connect');
  }

  if (canEditCompany) {
    tabs.push('integrations', 'notifications', 'terms');
  }

  return { tabs };
};

export default function SettingsPageLayout() {
  const { tabs } = useLoaderData<LoaderData>();
  const { t } = useTranslation();

  const tabConfig = useMemo(() => TAB_CONFIG(t), [t]);

  const isTeamActive = useMatch(routes.settings.users.index + '/*');
  const isConnectActive = useMatch(routes.settings.connect + '/*');
  const isIntegrationsActive = useMatch(routes.settings.integrations);

  const shouldUseOldLayout = useMemo(
    () => !!isTeamActive || !!isConnectActive || !!isIntegrationsActive,
    [isTeamActive, isConnectActive, isIntegrationsActive]
  );

  return (
    <TabLayout
      title={t('settings.title')}
      tabs={tabs.map((key) => tabConfig[key])}
      shouldUseOldLayout={shouldUseOldLayout}
    />
  );
}
