import { Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CountrySelect } from '../../../../Components/CountrySelect/CountrySelect';
import ControlledPhoneInput from '../../../../Components/PhoneInput/ControlledPhoneInput';
import { AddressSearchField } from '../../../../features/locations/location-search/location-search-input';
import { AddressSearchResult } from '../../../../features/locations/location-search/use-location-search';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { emailRegex } from '../../../../Utils/helpers';
import { CreateBookingFormInputs } from '../create-booking-form';

type CustomerInformationProps = {
  payment: string;
};

export const CustomerInformation: FC<CustomerInformationProps> = ({
  payment,
}) => {
  const { t } = useTranslate('dialogs.createBooking');

  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<CreateBookingFormInputs>();
  const { company } = useProfile();

  const handleSelect = (result: AddressSearchResult) => {
    setValue('customer.location.address', result.street);
    setValue('customer.location.city', result.city);
    setValue('customer.location.country', result.country);
    setValue('customer.location.state', result.state);
    setValue('customer.location.zipCode', result.postCode);
  };

  return (
    <Stack gap={3}>
      <TextField
        {...register('customer.name', {
          required: t('required', 'utils.errors'),
        })}
        label={t('name', 'utils.generic')}
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={Boolean(errors.customer?.name?.message)}
        helperText={errors.customer?.name?.message}
        required
      />
      <Controller
        name={'customer.location.address'}
        control={control}
        render={({ field }) => (
          <AddressSearchField
            {...field}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={t('address', 'utils.generic')}
            error={Boolean(errors.customer?.location?.address?.message)}
            helperText={errors.customer?.location?.address?.message}
            onSelectResult={handleSelect}
          />
        )}
      />
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <TextField
          {...register('customer.location.zipCode')}
          label={t('zipCode', 'utils.generic')}
          sx={{ width: '50%' }}
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.customer?.location?.zipCode?.message)}
          helperText={errors.customer?.location?.zipCode?.message}
        />
        <TextField
          {...register('customer.location.city')}
          label={t('city', 'utils.generic')}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={Boolean(errors.customer?.location?.city?.message)}
          helperText={errors.customer?.location?.city?.message}
        />
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <Controller
          name={'customer.location.state'}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              sx={{ width: '50%' }}
              InputLabelProps={{ shrink: true }}
              label={t('label', 'location.dialog.form.address.state')}
            />
          )}
        />
        <Controller
          name="customer.location.country"
          control={control} // Add control to destructured values from useFormContext
          defaultValue={company.data?.location?.country}
          render={({ field }) => (
            <CountrySelect
              label={t('country', 'utils.generic')}
              value={field.value}
              onChange={field.onChange}
              sx={{ maxHeight: 53 }}
            />
          )}
        />
      </Stack>

      <Stack direction="column" spacing={2}>
        <TextField
          {...register('customer.email', {
            required: t('required', 'utils.errors'),
            pattern: {
              value: emailRegex,
              message: t('invalidEmail', 'auth.errors'),
            },
          })}
          type="email"
          label={t('email', 'utils.generic')}
          sx={{ width: '100%' }}
          error={Boolean(errors.customer?.email?.message)}
          helperText={errors.customer?.email?.message}
          required
        />
        <ControlledPhoneInput
          name="customer.phone"
          control={control}
          sx={{ width: '100%' }}
        />
      </Stack>

      {payment === 'invoice' && (
        <>
          <Stack direction="row" spacing={2}>
            <TextField
              {...register('customer.companyName')}
              label={t('companyName', 'utils.generic')}
              sx={{ width: '60%' }}
              error={Boolean(errors.customer?.companyName?.message)}
              helperText={errors.customer?.companyName?.message}
            />

            <TextField
              {...register('customer.vatNumber')}
              label={t('vatNumber', 'utils.generic')}
              sx={{ width: '40%' }}
              error={Boolean(errors.customer?.vatNumber?.message)}
              helperText={errors.customer?.vatNumber?.message}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
