import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Stack,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';

type DialogWrapperProps = PropsWithChildren &
  Pick<DialogProps, 'maxWidth' | 'fullWidth' | 'fullScreen' | 'open'> & {
    title: string;
    onClose?: () => void;
  };

export const DialogWrapper = ({
  title,
  onClose,
  children,
  ...props
}: DialogWrapperProps) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogContent sx={{ padding: 4 }}>
        <DialogHeader title={title} onClose={onClose} />
        {children}
      </DialogContent>
    </Dialog>
  );
};

type DialogHeaderProps = Pick<DialogWrapperProps, 'title' | 'onClose'>;

const DialogHeader = ({ title, onClose }: DialogHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginBottom={1}>
      <Text fontSize="xlarge" variant="medium">
        {title}
      </Text>
      {onClose ? (
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            position: 'unset',
            zIndex: 10,
            top: 8,
            right: 0,
            color: lightTheme.palette.contrast.black,
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </Stack>
  );
};
