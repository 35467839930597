import { DoneRounded } from '@mui/icons-material';
import { Avatar, Divider, Grid, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CustomChip } from '../../../../../Components/CustomChip/CustomChip';
import { LanguageChips } from '../../../../../Components/LanguageChips/LanguageChips';
import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../../../../Components/OptionGroup/OptionGroup';
import { SimpleSkeleton } from '../../../../../Components/SimpleSkeleton/SimpleSkeleton';
import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useUsers } from '../../../../../Hooks/useUsers';
import { SyiSection } from '../../../../../Pages/SyiPage/SyiSection';
import {
  IcalFormValues,
  useIcalDialogCreateStep,
} from '../use-ical-dialog-create-step';

export const IcalDialogCreateStepForm = () => {
  const { t } = useTranslate('dialogs.ical');

  const {
    users: { data: users, isLoading: usersLoading },
  } = useUsers();

  const {
    onlyEventsWithBookingsOptions,
    filterGuidesOptions,
    languageOptions,
  } = useIcalDialogCreateStep();

  const { control, setValue } = useFormContext<IcalFormValues>();

  const selectedGuides = useWatch({ name: 'filterGuides.value', control });
  const language = useWatch({ name: 'language.value', control });

  const { backofficeLanguages, languageOption } = useLanguages();

  const handleToggleGuide = useCallback(
    (id: string) => () => {
      if (selectedGuides?.some((v) => v === id)) {
        return setValue(
          'filterGuides.value',
          [...(selectedGuides?.filter((f) => f !== id) ?? [])],
          { shouldDirty: true }
        );
      }
      setValue('filterGuides.value', [...(selectedGuides ?? []), id], {
        shouldDirty: true,
      });
    },
    [selectedGuides, setValue]
  );

  const handleChangeLanguage = (values: string[]) => {
    const [newLanguage] = values;
    if (newLanguage) {
      setValue('language.value', newLanguage, { shouldDirty: true });
    }
  };

  return (
    <Stack divider={<Divider />} gap={3}>
      <SyiSection title={t('steps.create.eventsWithBookings.title')}>
        <OptionGroup
          name={'onlyEventsWithBookings.selectedOptionKey'}
          options={onlyEventsWithBookingsOptions}
        />
      </SyiSection>
      <SyiSection title={t('steps.create.filterGuides.title')}>
        <OptionGroup
          name={'filterGuides.selectedOptionKey'}
          options={filterGuidesOptions}
        >
          <OptionGroupSelectedSection optionKey={true} mt={2}>
            <Grid container gap={1}>
              {usersLoading ? (
                <SimpleSkeleton />
              ) : (
                users?.map((el) => {
                  return (
                    <Grid
                      item
                      key={el.id}
                      component={CustomChip}
                      avatar={
                        <Avatar alt={el.name} src={el.pictures?.profile?.url} />
                      }
                      label={el.name}
                      onClick={handleToggleGuide(el.id!)}
                      selected={selectedGuides?.includes(el.id!)}
                      deleteIcon={<DoneRounded />}
                      sx={{ height: 48, borderRadius: 100 }}
                    />
                  );
                })
              )}
            </Grid>
          </OptionGroupSelectedSection>
        </OptionGroup>
      </SyiSection>
      <SyiSection title={t('steps.create.language.title')}>
        <OptionGroup
          name={'language.selectedOptionKey'}
          options={languageOptions}
        >
          <OptionGroupSelectedSection optionKey={true} mt={2}>
            <LanguageChips
              onChange={handleChangeLanguage}
              languages={[language ?? '']}
              languageOptions={backofficeLanguages.map((locale) => {
                const option = languageOption(locale);
                return {
                  key: option.locale,
                  label: option.country
                    ? `${option.label} (${option.country})`
                    : option.label,
                  flag: option.flagSrc,
                };
              })}
              multiple={false}
            />
          </OptionGroupSelectedSection>
        </OptionGroup>
      </SyiSection>
    </Stack>
  );
};
