import { Box, Stack } from '@mui/material';
import { lightTheme, SpinnerIcon, Text } from '@understory-io/pixel';

import { Key, KeyItem } from './key-item';

export const KeyList = ({
  keys,
  title,
  clientIdLabel,
  onRevoke,
  onUpdate,
  loading,
}: {
  keys: Key[];
  title: string;
  clientIdLabel: string;
  loading: boolean;
  onRevoke: (integrationKey: Key) => void;
  onUpdate: (integrationKey: Key) => void;
}) => {
  return (
    <Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text
          variant="medium"
          fontSize="small"
          color={lightTheme.palette.neutral.n400}
        >
          {title}
        </Text>
        {loading && (
          <Box
            width={15}
            height={15}
            sx={{
              marginRight: '8px',
            }}
          >
            <SpinnerIcon theme={lightTheme} />
          </Box>
        )}
      </Stack>
      <Stack gap={2} marginTop={2}>
        {keys
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((key) => (
            <KeyItem
              key={key.clientId}
              integrationKey={key}
              clientIdLabel={clientIdLabel}
              loading={loading}
              onRevoke={onRevoke}
              onUpdate={onUpdate}
            />
          ))}
      </Stack>
    </Stack>
  );
};
