import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ampli } from '../../Ampli';
import routes from '../../Utils/routes';
import { ONBOARDING_OPTIONS, OnboardingOption, STEP_ORDER } from './config';

type OnboardingSelections = {
  uses: string[];
  decision: OnboardingOption | null;
};

type OnboardingContextType = {
  currentStepKey: (typeof STEP_ORDER)[number];
  nextStep: () => void;
  previousStep: () => void;
  selections: OnboardingSelections;
  updateSelections: (update: Partial<OnboardingSelections>) => void;
};

export const OnboardingContext = createContext<OnboardingContextType | null>(
  null
);

export const useOnboardingFlow = () => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error(
      'useOnboardingFlow must be used within an OnboardingContextProvider'
    );
  }

  return context;
};

export const OnboardingContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selections, setSelections] = useState<OnboardingSelections>({
    uses: [],
    decision: ONBOARDING_OPTIONS.find((option) => option.recommended) || null,
  });

  const currentStepKey = (location.pathname.split('/').pop() ||
    'uses') as (typeof STEP_ORDER)[number];

  const handleNextStep = () => {
    const currentIndex = STEP_ORDER.indexOf(currentStepKey);
    const nextStep = STEP_ORDER[currentIndex + 1];
    if (!nextStep) return;

    ampli.onboardingFlowContinueClicked({
      next_step: nextStep,
    });

    navigate(routes.onboarding[nextStep]);
  };

  const handlePreviousStep = () => {
    const currentIndex = STEP_ORDER.indexOf(currentStepKey);
    const previousStep = STEP_ORDER[currentIndex - 1];
    if (!previousStep) return;

    ampli.onboardingFlowBackClicked({
      previous_step: previousStep,
    });

    navigate(routes.onboarding[previousStep]);
  };

  const updateSelections = (update: Partial<OnboardingSelections>) => {
    setSelections((prev) => ({ ...prev, ...update }));
    ampli.onboardingFlowDecisionClicked({
      decision: update.decision?.id ?? '',
    });
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentStepKey,
        nextStep: handleNextStep,
        previousStep: handlePreviousStep,
        selections,
        updateSelections,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
