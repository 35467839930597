import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import { useStorefrontLanguages } from '../../../../../../../Hooks/translatable/use-storefront-languages';
import { useProfile } from '../../../../../../../Hooks/useProfile';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertBookingFormLanguageSelect = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { currentEvent, updateEventField } = useEventUpsert();

  const error = errors['language'] as FieldError | undefined;

  const {
    company: { data: company, isLoading, isError },
  } = useProfile();

  const storefrontLanguages = useStorefrontLanguages();

  useEffect(() => {
    if (company && !currentEvent.booking?.language) {
      const defaultLanguage = company.defaultLanguage;
      setValue('language', defaultLanguage);
      updateEventField('booking', {
        ...currentEvent.booking,
        language: defaultLanguage,
      });
    }
  }, [company, currentEvent, setValue, updateEventField]);

  if (isLoading && !company) {
    return <Skeleton variant="rounded" height={56} width="100%" />;
  }

  if (isError || !company) {
    return null;
  }

  return (
    <FormControl fullWidth required error={!!error}>
      <Select
        {...register('language')}
        name="booking-form-language-select"
        labelId="booking-form-language-select-label"
        defaultValue={company.defaultLanguage}
        value={currentEvent.booking?.language}
        onChange={(event) => {
          setValue('language', event.target.value);
          updateEventField('booking', {
            ...currentEvent.booking,
            language: event.target.value,
          });
        }}
        error={!!error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {storefrontLanguages?.map(({ locale, label, country }) => (
          <MenuItem key={locale} value={locale}>
            {country ? `${label} (${country})` : label}
          </MenuItem>
        ))}
      </Select>

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};
