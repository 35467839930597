import { AddOutlined, ExpandMore, RemoveOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  SxProps,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { BookingAvailabilityManager } from '@understory-io/availability';
import { lightTheme, Text } from '@understory-io/pixel';
import { useCallback, useMemo, useState } from 'react';
import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  replace,
  useLoaderData,
  useNavigation,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateBooking } from '../../../Api';
import {
  companyProfileQuery,
  experienceQuery,
  receiptQuery,
} from '../../../Api/queries';
import {
  bookingQuery,
  BOOKINGS_ALL_QUERY_KEY,
} from '../../../Api/queries/bookings';
import { initiatePartialRefund } from '../../../Api/Receipt';
import RouteDialog from '../../../Components/dialog/route-dialog';
import { useGetEvent } from '../../../Hooks/events/useGetEvent';
import { useProducts } from '../../../Hooks/useProducts';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import { getGuestCount } from '../../../Utils/eventHelpers';
import routes from '../../../Utils/routes';
import { convertFormDataValue } from '../../experiences/edit/utils/form-helpers';
import {
  adjustResourceAvailability,
  calculateBookingTotal,
  calculateMaxBookingSize,
  updateGuests,
  UpdateGuestsParams,
} from '../booking-utils';

const GUEST_STEP_COUNT = 1;

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;
export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = params.id;
    if (!id) {
      throw new Response(null, {
        status: 404,
      });
    }

    const [booking, companyProfile] = await Promise.all([
      client.fetchQuery(bookingQuery(id)),
      client.fetchQuery(companyProfileQuery()),
    ]);

    const [reciept, experience] = await Promise.all([
      client.fetchQuery(receiptQuery(booking.receiptId)),
      client.fetchQuery(experienceQuery(booking.experienceId)),
    ]);
    return {
      bookingId: id,
      booking,
      companyProfile,
      reciept,
      experience,
    };
  };

const VARIANT_ID_PROPERTY_NAME = 'variantId';
const ADDON_ID_PROPERTY_NAME = 'addonId';
const QUANTITY_PROPERTY_NAME = 'quantity';
const SEND_EMAIL_PROPERTY_NAME = 'shouldSendConfirmation';
const SHOULD_REFUND_AMOUNT_PROPERTY_NAME = 'shouldRefundAmount';
const REFUND_AMOUNT_CENTS_PROPERTY_NAME = 'refundAmount';

export const action =
  (client: QueryClient) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const id = params.id;
    if (!id) {
      throw new Response(null, {
        status: 400,
      });
    }

    const formData = await request.formData();
    const booking = await client.fetchQuery(bookingQuery(id));
    try {
      const sendConfirmation = convertFormDataValue(
        formData.get(SEND_EMAIL_PROPERTY_NAME)
      );

      const shouldRefundAmount = convertFormDataValue(
        formData.get(SHOULD_REFUND_AMOUNT_PROPERTY_NAME)
      );

      const newGuests: {
        [id: string]: number;
      } = {};
      const variantIds = formData.getAll(VARIANT_ID_PROPERTY_NAME) as string[];
      if (variantIds) {
        variantIds.forEach((variantId) => {
          const quantity = formData.get(
            `${variantId}:${QUANTITY_PROPERTY_NAME}`
          );
          if (quantity) {
            newGuests[variantId] = Number(quantity);
          }
        });
      }
      const addonIds = formData.getAll(ADDON_ID_PROPERTY_NAME) as string[];
      if (addonIds) {
        addonIds.forEach((addonId) => {
          const quantity = formData.get(`${addonId}:${QUANTITY_PROPERTY_NAME}`);
          if (quantity) {
            newGuests[addonId] = Number(quantity);
          }
        });
      }

      const bookingPayload = {
        ...booking,
        items: newGuests,
        metaData: {
          skipBookingConfirmation: false,
        },
        shouldSendConfirmation: sendConfirmation === 'on',
      };

      await updateBooking(id, bookingPayload);
      client.invalidateQueries(bookingQuery(id));
      client.invalidateQueries({
        queryKey: BOOKINGS_ALL_QUERY_KEY,
      });

      client.invalidateQueries({
        queryKey: ['events'],
      });
      client.invalidateQueries({
        queryKey: ['receipt'],
      });
      client.invalidateQueries({
        queryKey: ['search'],
      });

      if (shouldRefundAmount === 'on') {
        const refundAmountCents = Number(
          formData.get(REFUND_AMOUNT_CENTS_PROPERTY_NAME)
        );
        if (refundAmountCents > 0 && booking.receiptId) {
          await initiatePartialRefund(booking.receiptId, refundAmountCents);
        }
      }

      toast.success(t('dialogs.editBooking.toast.success'));
    } catch (error) {
      captureException(error);
      toast.error(t('dialogs.editBooking.toast.error'));
    }

    return replace(routes.booking.details(id).index);
  };

export function EditBookingForm() {
  const { t } = useTranslate('dialogs.editBooking');
  const { bookingId, booking, reciept, experience } =
    useLoaderData<LoaderData>();
  const { state } = useNavigation();
  const [guests, setGuests] = useState(booking.items);
  const { event } = useGetEvent(booking.eventId);

  const disableButton = useMemo(
    () => state === 'submitting' || state === 'loading',
    [state]
  );

  const experienceMaxCount =
    experience.seats.type === 'group' && experience.seats.maxParticipants;
  const currentGuestCount = getGuestCount(booking.items);

  const maxBookingSize = calculateMaxBookingSize({
    experienceMaxCount,
    currentGuestCount,
    eventMaxCapacity: event.data?.computed.capacity.booking.max,
  });

  // Since the returned resource availability takes the current booking into account,
  // we have to "revert" the availability of the current booking.
  // This is done by adding the current booking back to the availability.
  const adjustedResourceManagement = useMemo(
    () =>
      adjustResourceAvailability(booking.items, event.data?.resourceManagement),
    [booking.items, event.data?.resourceManagement]
  );

  const bookingManager = useMemo(
    () =>
      new BookingAvailabilityManager({
        maxGuestsOnBooking: maxBookingSize,
        resourceManagement: adjustedResourceManagement,
      }).addTicketsUnchecked(guests),
    [guests, maxBookingSize, adjustedResourceManagement]
  );

  const { variants } = useProducts(booking.experienceId);

  const handleChange = useCallback(
    ({ id, direction }: Pick<UpdateGuestsParams, 'id' | 'direction'>) => {
      setGuests((prevGuests) => {
        const nextGuests = updateGuests({
          id,
          direction,
          prevGuests,
          bookingManager,
        });

        return nextGuests ?? prevGuests;
      });
    },
    [bookingManager]
  );

  // Amount that has already been paid
  const amountPaid = useMemo(
    () =>
      (reciept.transactions.reduce(
        (prev, curr) => prev + curr.totalAmountChargedCents,
        0
      ) ?? 0) -
      (reciept.refunds.reduce(
        (prev, curr) => prev + curr.amountRefundedCents,
        0
      ) ?? 0),
    [reciept.refunds, reciept.transactions]
  );

  const bookingTotal = useMemo(
    () => calculateBookingTotal(guests, variants),
    [guests, variants]
  );

  const hasPriceChanged = useMemo(
    () => amountPaid - bookingTotal !== 0,
    [amountPaid, bookingTotal]
  );

  const isRefund = useMemo(
    () => amountPaid - bookingTotal > 0,
    [amountPaid, bookingTotal]
  );

  return (
    <Form method="post">
      <RouteDialog
        title={t('title')}
        fallbackPath={routes.booking.details(bookingId).index}
        primaryAction={{
          label: t('buttonLabel'),
          variant: 'primary',
          loading: disableButton,
          disabled: disableButton,
        }}
        open={true}
      >
        {variants.map((variant) => {
          const hasAddons = variant.addons.length > 0;

          return (
            <Stack
              sx={{
                flexDirection: 'column',
                paddingY: 2,
                gap: 1,
                borderBottom: 1,
                borderColor: lightTheme.palette.neutral.n200,
              }}
              key={variant.id}
            >
              <Variant
                key={variant.id}
                id={variant.id}
                name={variant.name}
                vatInclusivePriceCents={
                  variant.priceBreakdown.vatInclusivePriceCents
                }
                resourceRestricted={
                  !bookingManager.canAddTickets({
                    [variant.id]: 1,
                  })
                }
                quantity={guests[variant.id]}
                currency={variant.priceBreakdown.currency}
                handleChange={handleChange}
              />
              <input
                type="hidden"
                name={VARIANT_ID_PROPERTY_NAME}
                value={variant.id}
              />
              <input
                type="hidden"
                name={`${variant.id}:${QUANTITY_PROPERTY_NAME}`}
                value={guests[variant.id]}
              />
              {hasAddons && (
                <Accordion
                  sx={{
                    padding: 0,
                    margin: 0,
                    boxShadow: 'none',
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore
                        htmlColor={lightTheme.palette.contrast.black}
                      />
                    }
                    sx={{
                      flexGrow: 0,
                      gap: 1,
                      justifyContent: 'start',
                      padding: 0,
                      '& .MuiAccordionSummary-content': {
                        flexGrow: 0,
                      },
                    }}
                  >
                    <Text variant="medium" fontSize="medium">
                      {t('addons')}
                    </Text>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Stack gap={2}>
                      {variant.addons.map((addon) => {
                        return (
                          <>
                            <Variant
                              key={addon.id}
                              id={addon.id}
                              handleChange={handleChange}
                              currency={addon.priceBreakdown.currency}
                              name={addon.name}
                              vatInclusivePriceCents={
                                addon.priceBreakdown.vatInclusivePriceCents
                              }
                              resourceRestricted={
                                !bookingManager.canAddTickets({
                                  [addon.id]: GUEST_STEP_COUNT,
                                })
                              }
                              quantity={guests[addon.id]}
                            />
                            <input
                              type="hidden"
                              name={ADDON_ID_PROPERTY_NAME}
                              value={addon.id}
                            />
                            <input
                              type="hidden"
                              name={`${addon.id}:${QUANTITY_PROPERTY_NAME}`}
                              value={guests[addon.id]}
                            />
                          </>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}
            </Stack>
          );
        })}
        <Collapse in={hasPriceChanged}>
          <Stack>
            <Stack
              sx={{
                paddingY: 2,
                gap: 1,
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text variant="medium" fontSize="large">
                  {isRefund ? t('refundAmount') : t('outstandingAmount')}
                </Text>
                <Text
                  textTransform="uppercase"
                  variant="medium"
                  fontSize="large"
                >
                  {reciept.financials.currency}{' '}
                  {Math.abs((amountPaid - bookingTotal) / 100)}
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <FormGroup>
                {isRefund ? (
                  <>
                    <input
                      hidden
                      name={REFUND_AMOUNT_CENTS_PROPERTY_NAME}
                      value={Math.abs(amountPaid - bookingTotal)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          defaultChecked
                          name={SHOULD_REFUND_AMOUNT_PROPERTY_NAME}
                        />
                      }
                      label={t('shouldRefund')}
                    />
                  </>
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        defaultChecked
                        name={SEND_EMAIL_PROPERTY_NAME}
                      />
                    }
                    label={t('sendPayment')}
                  />
                )}
              </FormGroup>
            </Stack>
          </Stack>
        </Collapse>
      </RouteDialog>
    </Form>
  );
}

interface VariantProps {
  id: string;
  name: string;
  currency: string;
  vatInclusivePriceCents: number;
  quantity?: number;
  resourceRestricted?: boolean;
  handleChange: ({
    id,
    direction,
  }: Pick<UpdateGuestsParams, 'id' | 'direction'>) => void;
}

function Variant({
  id,
  name,
  currency,
  vatInclusivePriceCents,
  resourceRestricted = false,
  quantity = 0,
  handleChange,
}: VariantProps) {
  const type = useMemo(() => id.split('/')[0] as 'variant' | 'addon', [id]);

  return (
    <Stack
      key={id}
      sx={{
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Stack flexGrow={1}>
        <Text
          color={
            type === 'variant'
              ? lightTheme.palette.contrast.black
              : lightTheme.palette.neutral.n300
          }
          variant="medium"
          fontSize={type === 'variant' ? 'medium' : 'small'}
        >
          {name}
        </Text>
        <Text
          color={lightTheme.palette.neutral.n300}
          textTransform="uppercase"
          variant="medium"
          fontSize="small"
        >
          {currency} {vatInclusivePriceCents / 100}
        </Text>
      </Stack>
      <Stack
        sx={{
          gap: 1,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton
          disabled={quantity === 0}
          onClick={() => handleChange({ id, direction: 'down' })}
          sx={BUTTON_STYLES}
        >
          <RemoveOutlined
            sx={{
              width: ICON_SIZE_PX,
              height: ICON_SIZE_PX,
            }}
          />
        </IconButton>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 25,
          }}
        >
          <Text variant="medium" fontSize="large">
            {quantity}
          </Text>
        </Stack>
        <IconButton
          disabled={resourceRestricted}
          onClick={() => handleChange({ id, direction: 'up' })}
          sx={BUTTON_STYLES}
        >
          <AddOutlined
            sx={{
              width: ICON_SIZE_PX,
              height: ICON_SIZE_PX,
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
}

const ICON_SIZE_PX = 18;
const BUTTON_STYLES: SxProps = {
  color: lightTheme.palette.neutral.n300,
  borderRadius: '50%',
  borderColor: lightTheme.palette.neutral.n300,
  padding: 0.75,
};
