import * as yup from 'yup';

import { localizedSchema } from './genericSchemas';

export const seatTypes = ['single', 'couple', 'group'] as const;
export type SeatType = (typeof seatTypes)[number];
export const seatSchema = yup.object().shape({
  type: yup
    .mixed<SeatType>()
    .oneOf([...seatTypes], 'utils.validationErrors.invalidEnum')
    .default('single'),
  customName: yup
    .string()
    .typeError('utils.validationErrors.invalidString')
    .optional(),
  minParticipants: yup.number().when('type', {
    is: (type: SeatType) => type === 'group',
    then: (schema) =>
      schema
        .required('utils.validationErrors.required')
        .max(yup.ref('seatCount'), 'utils.validationErrors.lessThanOrEqualTo'),
    otherwise: (schema) => schema.optional(),
  }),
  maxParticipants: yup.number().when('type', {
    is: (type: SeatType) => type === 'group',
    then: (schema) =>
      schema
        .required('utils.validationErrors.required')
        .min(yup.ref('minParticipants'), 'utils.validationErrors.greaterThan')
        .max(yup.ref('seatCount'), 'utils.validationErrors.lessThanOrEqualTo'),
    otherwise: (schema) => schema.optional(),
  }),

  seatCount: yup
    .number()
    .required('utils.validationErrors.required')
    .typeError('utils.validationErrors.invalidNumber')
    .min(1, 'utils.validationErrors.greaterThan'),
  explanation: localizedSchema(undefined, true).optional(),
});
export type Seat = yup.InferType<typeof seatSchema>;
