import { ArrowBackOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router';

import routes from '../../../../Utils/routes';

type GoBackProps = {
  experienceId: string;
  isFromOnboarding?: boolean;
};

const ICON_SIZE_PX = { xs: 24, md: 32 };

export const GoBack = ({ experienceId, isFromOnboarding }: GoBackProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (isFromOnboarding) {
      return navigate(routes.onboarding.decision);
    }

    navigate(routes.experience.details(experienceId).index, {
      replace: true,
    });
  };

  return (
    <IconButton
      size="small"
      onClick={handleBack}
      sx={{
        width: ICON_SIZE_PX,
        height: ICON_SIZE_PX,
        ':focus-visible': { outline: 1 },
      }}
    >
      <ArrowBackOutlined />
    </IconButton>
  );
};
