import * as api from '..';

/* ---- Booking  ----  */
export const receiptQueryKey = (receiptId: string | undefined) => [
  'receipt',
  receiptId,
];

export const receiptQuery = (receiptId: string | undefined) => ({
  queryKey: receiptQueryKey(receiptId),
  queryFn: () => api.getReceipt(receiptId!),
  enabled: !!receiptId,
});
