/**
 * Key Icon from design library.
 *
 * https://www.figma.com/design/FrfbK6tYxdwtUGQhPveCJA/Icons?node-id=0-1&p=f&t=wvLbD2VbhZEk5NeN-0
 */
export default function Key({ size = 14 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4852 12.3639C13.0473 13.926 13.0473 16.4587 11.4852 18.0208C9.92307 19.5829 7.39041 19.5829 5.82831 18.0208C4.26621 16.4587 4.26621 13.926 5.82831 12.3639C7.39041 10.8019 9.92307 10.8019 11.4852 12.3639ZM11.4852 12.3639L17.4956 6.3536C17.6909 6.15834 18.0075 6.15834 18.2028 6.3536L19.9705 8.12137M15.0208 8.82861L17.1421 10.9499"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
