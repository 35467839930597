import * as api from '..';

export const domainQueryKey = (companyId?: string) => ['domains', companyId];

export const domainQuery = (companyId: string) => ({
  queryKey: domainQueryKey(companyId),
  queryFn: () => api.getCompanyDomain(companyId),
  enabled: !!companyId,
});

const domainAvailabilityQueryKey = (domain: string) => [
  'domainAvailability',
  domain,
];

export const domainAvailabilityQuery = (domain: string) => ({
  queryKey: domainAvailabilityQueryKey(domain),
  queryFn: () => api.domainAvailable(domain),
  enabled: !!domain,
});
