import {
  type DraftExperience,
  type Experience,
  ListExperiencesQuery,
  type RegularExperience,
} from '@understory-io/experiences-types';
import {
  UnderstoryApiCollection,
  UnderstoryApiSingleResource,
} from '@understory-io/utils-types';

import { queryClient } from '../query-client';
import { apiServices } from './apiServices';
import api from './index';
import { experienceLocationsQuery } from './queries';
import { experienceQuery, experiencesQueryKey } from './queries/experiences';

export const getExperience = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { item },
  } = await api.get<UnderstoryApiSingleResource<Experience>>(
    `${apiServices.internalApi}/v1/experiences/${experienceId}`
  );

  return item;
};

export const getDraftExperience = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { item },
  } = await api.get<UnderstoryApiSingleResource<RegularExperience>>(
    `${apiServices.internalApi}/v1/experiences/${experienceId}?includeDrafts=true`
  );

  return item;
};

export const getExperiences = async (
  params?: Partial<ListExperiencesQuery>
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { items },
  } = await api.get<UnderstoryApiCollection<Experience>>(
    `${apiServices.internalApi}/v1/experiences/`,
    { params }
  );

  return items;
};

export const saveExperienceDraft = async (
  experienceId: string,
  data: DraftExperience
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.put(
    `${apiServices.internalApi}/v1/experiences/${experienceId}/draft`,
    {
      item: data,
    }
  );

  handleExperienceUpdated(experienceId);
};

export const updateExperienceMetaData = async (
  experienceId: string,
  data: Experience['metadata']
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(
    `${apiServices.internalApi}/v1/experiences/${experienceId}/metadata`,
    data
  );

  handleExperienceUpdated(experienceId);
};

export type ExperiencePatch = {
  op: 'add' | 'remove' | string;
  path: '/locationIds' | '/tagIds';
  value: string;
};
export const patchExperience = async (
  experienceId: string,
  body: ExperiencePatch[]
) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.patch(
    `${apiServices.internalApi}/v1/experiences/${experienceId}`,
    body
  );

  handleExperienceUpdated(experienceId);
};

export const publishExperienceDraft = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(
    `${apiServices.internalApi}/v1/experiences/${experienceId}/publish`
  );

  handleExperienceUpdated(experienceId);
};

export const deleteExperience = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.delete(`${apiServices.internalApi}/v1/experiences/${experienceId}`);

  handleExperienceUpdated(experienceId);
};

export const discardExperience = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.delete(
    `${apiServices.internalApi}/v1/experiences/${experienceId}/draft`
  );

  handleExperienceUpdated(experienceId);
};

const handleExperienceUpdated = async (experienceId: string) => {
  queryClient.invalidateQueries({
    queryKey: experienceQuery(experienceId).queryKey,
  });
  queryClient.invalidateQueries({
    queryKey: experiencesQueryKey,
  });
  queryClient.invalidateQueries({
    queryKey: experienceLocationsQuery(experienceId).queryKey,
  });
};
