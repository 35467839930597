import * as yup from 'yup';

import { CountryVatRates } from '../../../Sections/SettingsSections/Company/domain/types';
import { countryCodesWithStates } from '../../../Sections/SettingsSections/Vat/Countries';

export const billingFormSchema = (
  countryVatRates: CountryVatRates | undefined
) =>
  yup.object().shape({
    vatNumber: yup.string().when('isTaxRegistered', {
      is: (value: boolean) => value === true,
      then: (schema) =>
        schema.test({
          message: 'utils.validationErrors.invalidVatNumberFormat',
          test: (value, context) => {
            const vatRates =
              countryVatRates?.[context.parent.legalLocation.countryCode];
            const pattern = vatRates?.vatNumberRules[0].pattern;

            if (pattern === undefined) {
              return !!value?.length;
            }

            const result = value?.match(new RegExp(pattern));
            return !!result;
          },
        }),
      otherwise: (schema) => schema.nullable(),
    }),
    legalName: yup.string().required('utils.validationErrors.required'),
    legalLocation: yup.object().shape({
      countryCode: yup
        .string()
        .length(2)
        .required('utils.validationErrors.required'),
      address: yup.string().required('utils.validationErrors.required'),
      zipCode: yup.string().required('utils.validationErrors.required'),
      city: yup.string().required('utils.validationErrors.required'),
      state: yup.string().when('countryCode', {
        is: (countryCode: string) =>
          countryCodesWithStates.includes(countryCode),
        then: (schema) => schema.required('utils.validationErrors.required'),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    isTaxRegistered: yup.boolean(),
  });

export type BillingFormData = yup.InferType<
  ReturnType<typeof billingFormSchema>
>;
