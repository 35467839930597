import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Form, useLocation, useNavigate, useNavigation } from 'react-router';

import RouteDialog from '../../../../../Components/dialog/route-dialog';
import { useFireOnce } from '../../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import routes from '../../../../../Utils/routes';
import {
  trackExperienceEditDialogClosed,
  trackExperienceEditDialogOpened,
} from '../../utils/tracking-helper';
import { useNavigateWithParams } from '../../utils/use-navigate-with-params';

type DialogContentProps = {
  title?: string;
  description?: string;
  shouldClose?: boolean;
  experienceId: string;
  isDelete?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  noValidate?: boolean;
  type: string;
};

type EditExperienceDialogProps = PropsWithChildren & DialogContentProps;

export const EditExperienceDialog = ({
  title,
  description,
  children,
  shouldClose,
  experienceId,
  isDelete,
  onSubmit,
  noValidate,
  type,
}: EditExperienceDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateWithParams = useNavigateWithParams();
  const { state } = useNavigation();
  const fireOnce = useFireOnce();

  const disableButton = state === 'submitting' || state === 'loading';

  const handleClose = useCallback(() => {
    trackExperienceEditDialogClosed({
      experience_id: experienceId,
      dialog_type: type,
    });
    if (location.key === 'default') {
      navigateWithParams(routes.experience.details(experienceId).edit.index, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  }, [navigate, navigateWithParams, experienceId, location.key, type]);

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    fireOnce(() => {
      trackExperienceEditDialogOpened({
        experience_id: experienceId,
        dialog_type: type,
      });
    });
  }, [experienceId, type, fireOnce]);

  useEffect(() => {
    if (isClosing || !shouldClose) return;
    setIsClosing(true);
    handleClose();
  }, [handleClose, isClosing, shouldClose]);

  const { t } = useTranslate('experience.edit.dialog.actions');

  return (
    <Form method="post" onSubmit={onSubmit} noValidate={noValidate}>
      <RouteDialog
        title={title}
        description={description}
        fallbackPath={routes.experience.details(experienceId).edit.index}
        actionsInHeader={!isDelete}
        showSecondaryActionDesktop={isDelete}
        fullHeight={!isDelete}
        maxWidth={750}
        primaryAction={{
          type: 'submit',
          label: isDelete ? t('delete') : t('save'),
          variant: isDelete ? 'danger' : 'primary',
          loading: disableButton,
        }}
      >
        {children}
      </RouteDialog>
    </Form>
  );
};
