import NiceModal from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ExperienceTag } from '@understory-io/utils-types';
import { useState } from 'react';

import { ContextMenu } from '../../../../Components/context-menu/context-menu';
import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { IExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackStorefrontTagManagementTagAddToClicked,
  trackStorefrontTagManagementTagDeleteClicked,
  trackStorefrontTagManagementTagEditClicked,
} from '../../../../tracking/storefront/tags-events';
import { AddTagToExperiencesDialog } from '../dialogs/add-tag-to-experiences';
import { DeleteTagDialog } from '../dialogs/delete-tag';
import { UpdateTagDialog } from '../dialogs/update-tag';

export const TagItem = ({
  tag,
  experienceCount,
  experiences,
}: {
  tag: ExperienceTag;
  experienceCount: number;
  experiences: IExperience[];
}) => {
  const { t } = useTranslate('storefront.tags.list');

  const [open, setOpen] = useState(false);

  const { getLocalizedString } = useLocale();

  return (
    <Stack
      key={tag.id}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'baseline' }}>
        <Text variant="medium" fontSize="small">
          {getLocalizedString(tag.name)}
        </Text>
        <Text
          variant="medium"
          fontSize="small"
          color={lightTheme.palette.neutral.n300}
        >
          {t('experienceCount', { count: experienceCount })}
        </Text>
      </Stack>
      <ContextMenu
        open={open}
        onOpenChange={setOpen}
        options={[
          {
            label: t('context.add'),
            onClick: () => {
              NiceModal.show(AddTagToExperiencesDialog, {
                tagId: tag.id,
                tagName: getLocalizedString(tag.name),
                experiences,
              });
              trackStorefrontTagManagementTagAddToClicked();
            },
          },
          {
            label: t('context.edit'),
            onClick: () => {
              NiceModal.show(UpdateTagDialog, { tag });
              trackStorefrontTagManagementTagEditClicked();
            },
          },
          {
            label: t('context.delete'),
            onClick: () => {
              NiceModal.show(DeleteTagDialog, { tagId: tag.id });
              trackStorefrontTagManagementTagDeleteClicked();
            },
            textColor: lightTheme.palette.error.e300,
          },
        ]}
      />
    </Stack>
  );
};
