import { Stack } from '@mui/material';
import { FC } from 'react';

import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { FullPage } from '../../../../Components/full-page/full-page';
import { FullPageForm } from '../../../../Components/full-page/full-page-form';
import { FullPageHeader } from '../../../../Components/full-page/full-page-header';
import { FullPageHeaderBackButton } from '../../../../Components/full-page/full-page-header-back-button';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { ResourceManagementEmptyList } from '../list/resource-management-empty-list';

type Props = {
  errorTexts?: {
    title: string;
    description: string;
  };
};

export const UpdateResourceTypeErrorPage: FC<Props> = ({ errorTexts }) => {
  const { t } = useTranslate('resourceManagement.resourceType');

  errorTexts ||= {
    title: t('errorTitle', 'dialogs.errorDialog'),
    description: t('errorSubtitle', 'dialogs.errorDialog'),
  };

  return (
    <FullPage>
      <FullPageHeader>
        <FullPageHeaderBackButton />
      </FullPageHeader>
      <FullPageForm>
        <Stack mt={5} gap={4}>
          <ResourceManagementEmptyList
            title={errorTexts.title}
            description={errorTexts.description}
            action={
              <RouterLinkButton
                variant="secondary"
                size="medium"
                href={routes.resourceManagement.index}
              >
                {t('goToResources')}
              </RouterLinkButton>
            }
          />
        </Stack>
      </FullPageForm>
    </FullPage>
  );
};
