import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  useCreateLocation,
  useGetTimeZones,
} from '../../../../Hooks/data/useLocations';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ILocationView } from '../../../../types/api/location';
import { TagDialogWrapper } from '../../tag-management/dialogs/tag-dialog-wrapper';
import { LocationForm } from './location-form';

export const CreateLocationDialog = NiceModal.create(() => {
  const { t } = useTranslate('location.dialog.create');
  const createLocation = useCreateLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { company } = useProfile();

  const { timezones } = useGetTimeZones();

  const handleSubmit = (data: ILocationView) => {
    setIsSubmitting(true);
    createLocation
      .mutateAsync(data)
      .then(({ item: newLocation }) => {
        handleClose(newLocation.locationId);
      })
      .catch((error) => {
        toast.error(t('generic', 'utils.errors'));
        captureException(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  const modal = useModal();

  const handleClose = (newLocationId?: string) => {
    modal.resolve(newLocationId);
    modal.hide();
  };

  if (!timezones.data || !company.data) return null;

  const defaultCountry = company.data?.location?.country;
  const defaultTimezone =
    company.data.location?.timezone ??
    timezones.data.find((tz) => tz.countryCode === defaultCountry)?.value;

  return (
    <TagDialogWrapper
      open={modal.visible}
      handleClose={handleClose}
      title={t('title')}
    >
      <LocationForm
        onSubmit={handleSubmit}
        handleCancel={handleClose}
        isSubmitting={isSubmitting}
        defaultCountry={defaultCountry}
        defaultValues={{
          timezone: defaultTimezone,
        }}
        timezones={timezones.data}
      />
    </TagDialogWrapper>
  );
});
