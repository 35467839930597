import { Divider, Stack } from '@mui/material';
import { API } from '@understory-io/grow-types';
import { lightTheme } from '@understory-io/pixel';
import { renderDate } from '@understory-io/utils-date';

import { StatusBadge } from '../../../../Components/badge/status-badge';
import { StyledListItemText } from '../../../../features/events/event_details/BookingList/BookingListItem';
import { StyledInfoBox } from '../../../../features/events/event_details/shared';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useAdPlans } from '../../../../Hooks/use-ad-plans';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../Utils/helpers';
import { PlanListItem } from './grow-advertisement-dashboard-plan-list-item';

export const planListColumns = [
  {
    key: 'planName',
    value: (
      plan: API.GetAdPlans.Response['items'][number],
      defaultCurrency: string,
      _locale: Locale,
      index: number
    ) => `${plan.companyName} / Understory Ads ${index + 1}`,
  },
  {
    key: 'startDate',
    value: (plan: API.GetAdPlans.Response['items'][number]) =>
      plan.startDate ? renderDate(plan.startDate) : '-',
  },
  {
    key: 'budget',
    value: (
      plan: API.GetAdPlans.Response['items'][number],
      defaultCurrency: string,
      _locale: Locale
    ) => getFormattedPrice(plan.budget, defaultCurrency, _locale.code),
  },
  {
    key: 'state',
    value: (plan: API.GetAdPlans.Response['items'][number]) => (
      <StatusBadge type="advertisement" state={plan.state} />
    ),
  },
];

export const planListColumnWidths = {
  planName: 40,
  startDate: 20,
  budget: 20,
  state: 20,
};

export const GrowAdvertisementDashboardPlanList = () => {
  const { t } = useTranslate('grow.advertisement.dashboard.listItem');
  const { adPlans } = useAdPlans();
  const { isSm } = useResponsive();

  const filteredAdPlans = adPlans.data?.filter((plan) =>
    ['pending', 'active', 'inactive'].includes(plan.state)
  );

  if (isSm) {
    return (
      <StyledInfoBox sx={{ px: 2, py: 2 }}>
        <Stack gap={2} divider={<Divider />}>
          {filteredAdPlans?.map((plan, index) => (
            <PlanListItem key={plan.adPlanId} plan={plan} index={index} />
          ))}
        </Stack>
      </StyledInfoBox>
    );
  }

  return (
    <StyledInfoBox>
      <Stack fontSize={lightTheme.typography.small.normal.fontSize}>
        <Stack direction="row" justifyContent="space-between" py={1} px={4}>
          {planListColumns.map((el) => (
            <StyledListItemText
              key={el.key}
              fontSize="small"
              variant="medium"
              style={{
                width: `${
                  planListColumnWidths[
                    el.key as keyof typeof planListColumnWidths
                  ]
                }%`,
              }}
            >
              {t(el.key)}
            </StyledListItemText>
          ))}
        </Stack>
        <Divider />
        <Stack divider={<Divider />}>
          {filteredAdPlans?.map((plan, index) => (
            <PlanListItem key={plan.adPlanId} plan={plan} index={index} />
          ))}
        </Stack>
      </Stack>
    </StyledInfoBox>
  );
};
