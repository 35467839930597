import NiceModal from '@ebay/nice-modal-react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { Card, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ampli } from '../../../../../Ampli';
import { SectionHeadline } from '../../../../../Components/SectionHeadline/SectionHeadline';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { InternalIntegrationKeysDialog } from './internal-integration-keys-dialog';

export const InternalIntegrationKeys = () => {
  const flags = useFlags();
  const { isSm } = useResponsive();

  const { t } = useTranslate('settings.integrations.internalIntegrationKeys');

  const handleOnClick = () => {
    ampli.internalIntegrationKeyInteraction({
      action: 'opened',
    });
    NiceModal.show(InternalIntegrationKeysDialog);
  };

  if (!flags.featureInternalIntegrationKeys) {
    return null;
  }

  return (
    <>
      <SectionHeadline title={t('title')} description="" />
      <Card
        onClick={handleOnClick}
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
          backgroundColor: lightTheme.palette.contrast.surface2,
          ':hover': {
            backgroundColor: lightTheme.palette.neutral.n100,
          },
          minHeight: '56px',
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Text fontSize={isSm ? 'small' : 'medium'} variant="medium">
              {t('open.title')}
            </Text>
            <Text fontSize="small">{t('open.description')}</Text>
          </Stack>
          <Stack direction={'row'} gap={3} alignItems={'center'}>
            <ArrowForwardIosRounded
              fontSize="small"
              htmlColor={lightTheme.palette.neutral.n200}
            />
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
