import { Divider, Stack } from '@mui/material';
import { Button, lightTheme, LinkButton } from '@understory-io/pixel';
import { Link } from 'react-router';

import { ampli } from '../../../../Ampli';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useDashboardPageState } from '../../use-dashboard-page-state';

export const DashboardSuggestionsHelp = () => {
  const { t } = useTranslate('dashboard.suggestions.help');
  const { company, updateCompany, onboardingCompleted } = useProfile();

  const { showGraphsOrLoading } = useDashboardPageState();

  const handleShowTips = async () => {
    if (company.data) {
      await updateCompany.mutateAsync({
        ...company.data,
        onboardingCompleted: false,
      });
    }
  };

  if (company.isLoading) return null;

  return (
    <Stack gap={1} maxWidth={showGraphsOrLoading ? '98%' : 850}>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={2} alignItems="center">
          <LinkButton
            href="https://help.understory.io"
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            variant="text"
            onClick={() => ampli.dashboardSuggestionsHelpClicked()}
            style={{
              fontSize: '0.875rem',
              cursor: 'pointer',
              width: 'fit-content',
              padding: 0,
              color: lightTheme.palette.action.a300,
              textDecoration: 'underline',
            }}
          >
            {t('helpSupport')}
          </LinkButton>
          {onboardingCompleted && (
            <Button
              size="small"
              variant="text"
              onClick={handleShowTips}
              style={{
                fontSize: '0.875rem',
                cursor: 'pointer',
                width: 'fit-content',
                padding: 0,
                color: lightTheme.palette.action.a300,
                textDecoration: 'underline',
              }}
            >
              {t('showTips')}
            </Button>
          )}
        </Stack>
        <Link to="https://www.instagram.com/understoryofficial" target="_blank">
          <img
            src="/icons/instagram.svg"
            alt="Instagram"
            width={24}
            height={24}
            style={{
              color: lightTheme.palette.action.a300,
            }}
          />
        </Link>
      </Stack>
    </Stack>
  );
};
