import { captureException } from '@sentry/react';
import { Localized } from '@understory-io/utils-types';

import { getTranslation } from '../../../../Api';
import { StorefrontLanguage } from '../../../../i18n/config';

/**
 * Translates the input to the storefront languages
 * @param input - the input to translate
 * @param existingValues - the existing values
 * @param activeLanguage - the source language of the input
 * @param storefrontLanguages - the languages to translate to
 * @param isTranslateEnabled - whether auto-translation is enabled
 * @returns a `Localized` object with the input and translated values,
 * if translation is enabled
 */
export const translateInput = async (
  input: string,
  existingValues: Localized = {},
  activeLanguage: StorefrontLanguage,
  storefrontLanguages: StorefrontLanguage[],
  isTranslateEnabled: boolean
): Promise<Localized> => {
  const languagesToTranslateTo = storefrontLanguages.filter(
    (l) => l !== activeLanguage
  );

  const result = {
    [activeLanguage]: input ?? '',
  };

  languagesToTranslateTo.forEach((l) => (result[l] = existingValues[l] || ''));

  if (!isTranslateEnabled) {
    return result;
  }

  // We don't need to translate an empty string
  if (!input) {
    languagesToTranslateTo.forEach((l) => (result[l] = ''));
  } else {
    await Promise.all(
      languagesToTranslateTo.map((l) =>
        getTranslation({
          text: input,
          targetLanguageCode: l,
          sourceLanguageCode: activeLanguage,
        }).then(({ text }) => (result[l] = text))
      )
    );
  }

  return result;
};

interface TranslateMissingInputsProps {
  existingValues: {
    [x: string]: string;
  };
  activeLanguage: StorefrontLanguage;
  storefrontLanguages: string[];
}

/**
 * Loops through the storefront languages and translates the input
 * @returns a `Localized` object with the input and translated values,
 * if translation is enabled
 */
export async function translateMissingInputs({
  existingValues,
  activeLanguage,
  storefrontLanguages,
}: TranslateMissingInputsProps): Promise<Localized | undefined> {
  let input = existingValues[activeLanguage];
  let sourceLanguage = activeLanguage;

  if (!input) {
    const firstExistingValue = Object.entries(existingValues).find(
      ([, value]) => value && value.trim() !== ''
    );

    if (firstExistingValue) {
      sourceLanguage = firstExistingValue[0] as StorefrontLanguage;
      input = firstExistingValue[1];
    } else {
      // Nothing to be translated
      return existingValues;
    }
  }

  const result = { ...existingValues, [sourceLanguage]: input };

  const languagesToTranslateTo = storefrontLanguages.filter(
    (l) => l !== sourceLanguage
  );

  try {
    await Promise.all(
      languagesToTranslateTo.map(async (language) => {
        if (!result[language] || result[language].trim() === '') {
          const translation = await getTranslation({
            text: input,
            targetLanguageCode: language,
            sourceLanguageCode: sourceLanguage,
          });
          result[language] = translation.text;
        }
      })
    );
  } catch (error) {
    captureException(error);
    return result;
  }

  return result;
}
