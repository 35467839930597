import * as yup from 'yup';

import { StorefrontLanguage } from '../../../i18n/config';
import { localizedSchema } from './genericSchemas';

export const informationRequestSchema = (activeLanguage?: StorefrontLanguage) =>
  yup.object().shape({
    id: yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString'),
    request: localizedSchema(activeLanguage),
    scope: yup
      .mixed()
      .required('utils.validationErrors.required')
      .oneOf(['ticket', 'booking'], 'utils.validationErrors.invalidEnum'),
    required: yup
      .boolean()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidBoolean')
      .default(false),
  });

export type InformationRequest = yup.InferType<
  ReturnType<typeof informationRequestSchema>
>;
