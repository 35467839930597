import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { endOfWeek, format, isSameMonth, startOfWeek } from 'date-fns';
import { FC, useMemo } from 'react';

import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useTranslate } from '../../../Hooks/useTranslate';
import { CalendarView, useCalendar } from '../context';

const DATE_WITHOUT_MONTH = 'd.';
const DATE_WITH_SHORT_MONTH = `${DATE_WITHOUT_MONTH} MMM`;

type Props = {
  interval: CalendarView;
};

export const MonthSelectorLabel: FC<Props> = ({ interval }) => {
  const { selectedDate } = useCalendar();

  const { t } = useTranslate('utils.generic');

  const { dateFnsLocale } = useLocale();

  const monthSelectorLabel = useMemo(() => {
    if (interval === 'day') {
      return format(selectedDate, 'eeee, PP');
    }

    if (interval === 'week') {
      const weekStart = startOfWeek(selectedDate, {
        weekStartsOn: dateFnsLocale.options?.weekStartsOn,
      });
      const weekEnd = endOfWeek(selectedDate, {
        weekStartsOn: dateFnsLocale.options?.weekStartsOn,
      });

      const sameMonth = isSameMonth(weekStart, weekEnd);

      return (
        <Stack direction="row" gap={1.5} alignItems="center">
          <Text>
            {t('week')} {format(weekStart, 'w')}
          </Text>
          {dateFnsLocale.code === 'en-US'
            ? sameMonth
              ? `${format(weekStart, 'MMM d')}-${format(weekEnd, `d yyyy`)}`
              : `${format(weekStart, 'MMM d')}-${format(weekEnd, `MMM d yyyy`)}`
            : `${format(
                weekStart,
                sameMonth ? DATE_WITHOUT_MONTH : DATE_WITH_SHORT_MONTH
              )}-${format(weekEnd, `${DATE_WITH_SHORT_MONTH} yyyy`)}`}
        </Stack>
      );
    }

    return format(selectedDate, 'LLLL yyyy');
  }, [interval, selectedDate, dateFnsLocale.options?.weekStartsOn, t]);

  return (
    <Text
      fontSize="large"
      variant="medium"
      textTransform="capitalize"
      style={{ lineHeight: 1 }}
    >
      {monthSelectorLabel}
    </Text>
  );
};
