import {
  CircularProgress,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import {
  FocusEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AddressSearchResult, useAddressSearch } from './use-location-search';

type AddressSearchFieldProps = TextFieldProps & {
  value: string;
  onChange: (value: string) => void;
  onSelectResult: (result: AddressSearchResult) => void;
};

export const AddressSearchField = forwardRef(
  (
    { value, onSelectResult, ...props }: AddressSearchFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [open, setOpen] = useState(false);
    const { searchResults, getPlaceAddress } = useAddressSearch(value);
    const [cursor, setCursor] = useState<number>(-1);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isKeyboardNavigating, setIsKeyboardNavigating] = useState(false);
    const [isLoading, setIsLoading] = useState<string | undefined>(undefined);

    /**
     * Close dropdown and reset cursor
     */
    const handleClose = useCallback(() => {
      setCursor(-1);
      setOpen(false);
    }, []);

    /**
     * Get address details for a placeId and return result
     * - also closes the dropdown on selection
     */
    const handleSelect = useCallback(
      async (placeId: string) => {
        setIsLoading(placeId);
        const result = await getPlaceAddress(placeId);
        handleClose();
        onSelectResult(result);
        setIsLoading(undefined);
      },
      [getPlaceAddress, handleClose, onSelectResult]
    );

    /**
     * Close dropdown when clicking outside of container element
     * and reset using keyboard state, to "re-enable" mouse hover state
     */
    useEffect(() => {
      const handleClick = (event: Event) => {
        if (!containerRef.current?.contains(event.target as Node)) {
          handleClose();
        }
      };

      const handleMouseMove = () => {
        setIsKeyboardNavigating(false);
      };

      document.addEventListener('click', handleClick);
      document.addEventListener('mouseMove', handleMouseMove);
      return () => {
        document.removeEventListener('click', handleClick);
        document.addEventListener('mousemove', handleMouseMove);
      };
    }, [containerRef, handleClose]);

    /**
     * Reset cursor and open dropdown when search results change
     */
    useEffect(() => {
      setCursor(-1);
      if (!containerRef.current?.contains(document.activeElement)) return;
      setOpen(true);
    }, [searchResults]);

    /**
     * Handle keyboard navigation for dropdown
     * Keyboard navigation is set to disable mouse hover state
     */
    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'ArrowUp') {
          e.preventDefault();
          e.stopPropagation();
          if (cursor === -1) return;
          setIsKeyboardNavigating(true);
          return setCursor((prev) => prev - 1);
        } else if (e.code === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
          if (cursor === searchResults.length - 1) return;
          setIsKeyboardNavigating(true);
          setCursor((prev) => prev + 1);
        } else if (e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          setIsKeyboardNavigating(false);
          handleClose();
        } else if (e.code === 'Enter') {
          if (cursor === -1) return;
          e.preventDefault();
          e.stopPropagation();
          const placeId = searchResults[cursor].place_id;
          setIsKeyboardNavigating(false);
          if (placeId) {
            handleSelect(placeId);
          }
        }
      },
      [cursor, handleClose, handleSelect, searchResults]
    );

    /**
     * Close dropdown when focus leaves container, but
     * make sure to not open it when clicking an item
     * in the dropdown.
     */
    const handleBlur = useCallback(
      (e: FocusEvent<HTMLDivElement, Element>) => {
        if (containerRef.current?.contains(e.target as Node)) return;
        handleClose();
      },
      [containerRef, handleClose]
    );

    return (
      <Stack
        ref={containerRef}
        sx={{ position: 'relative' }}
        onBlur={handleBlur}
      >
        <TextField
          {...props}
          ref={ref}
          value={value}
          onFocus={(e) => {
            setOpen(true);
            props.onFocus?.(e);
          }}
          onClick={(e) => {
            setOpen(true);
            props.onClick?.(e);
          }}
          onKeyDown={(e) => {
            handleKeyDown(e);
            props.onKeyDown?.(e);
          }}
        />
        {open && (
          <AddressResults
            cursor={cursor}
            searchResults={searchResults}
            onSelect={handleSelect}
            isKeyboardNavigating={isKeyboardNavigating}
            setCursor={setCursor}
            isLoading={isLoading}
          />
        )}
      </Stack>
    );
  }
);

AddressSearchField.displayName = 'AddressSearchField';

const AddressResults = ({
  cursor,
  searchResults,
  onSelect,
  isKeyboardNavigating,
  setCursor,
  isLoading,
}: {
  cursor: number;
  searchResults: google.maps.places.PlaceResult[];
  onSelect: (placeId: string) => void;
  isKeyboardNavigating: boolean;
  setCursor: (cursor: number) => void;
  isLoading: string | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 'calc(100% + 2px)',
        width: '100%',
        zIndex: 99,
        backgroundColor: lightTheme.palette.contrast.white,
        maxHeight: 250,
        overflowY: 'auto',
        boxShadow: lightTheme.shadows.medium,
        borderRadius: 1,
      }}
    >
      {searchResults.length ? (
        searchResults.map((result, index) => {
          const id = result.place_id;
          // Appease TypeScript - results are filtered in useAddressSearch
          if (!id) return null;
          return (
            <AddressItem
              key={id}
              label={result.formatted_address ?? ''}
              onClick={() => onSelect(id)}
              selected={cursor === index}
              isKeyboardNavigating={isKeyboardNavigating}
              onMouseEnter={() => !isKeyboardNavigating && setCursor(index)}
              isLoading={isLoading === id}
              disabled={!!isLoading}
            />
          );
        })
      ) : (
        <AddressItem label={t('location.search.startTyping')} />
      )}
    </Stack>
  );
};

const AddressItem = ({
  label,
  onClick,
  selected,
  isKeyboardNavigating,
  onMouseEnter,
  isLoading,
  disabled,
}: {
  label: string;
  onClick?: () => void;
  selected?: boolean;
  isKeyboardNavigating?: boolean;
  onMouseEnter?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isKeyboardNavigating && selected)
      itemRef.current?.scrollIntoView({ block: 'nearest' });
  }, [isKeyboardNavigating, itemRef, selected]);

  return (
    <Stack
      ref={itemRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      sx={{
        pointerEvents: disabled ? 'none' : undefined,
        paddingX: 2,
        paddingY: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        cursor: onClick ? 'pointer' : undefined,
        backgroundColor: selected ? lightTheme.palette.neutral.n100 : undefined,
        transition: 'background-color 0.1s ease-in-out',
        ':hover': {
          backgroundColor:
            onClick && !isKeyboardNavigating
              ? lightTheme.palette.neutral.n100
              : undefined,
        },
      }}
    >
      <Text
        fontSize="small"
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {label}
      </Text>
      {isLoading && <CircularProgress size={16} />}
    </Stack>
  );
};
