import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { generate } from 'random-words';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { createDomain, domainAvailable } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useAuth } from '../../../Hooks/useAuth';
import { SignupType, useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackSignUpFlowCompleted } from '../../../tracking/signup/trackSignUpFlowCompleted';
import routes from '../../../Utils/routes';
import { SignupForm } from './signup-form';
import { LoginPrompt } from './ui/login-prompt';

export type InvitationData = {
  email: string;
  companyName: string;
  inviterName: string;
  name?: string;
  id: string;
};

type SignupCardProps = {
  isFocused?: boolean;
};

export const SignupCard = ({ isFocused }: SignupCardProps) => {
  const { t, i18n } = useTranslate('signup');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isAuthenticated, createAccount, addAccountToCompany } = useAuth();
  const { updateCompany } = useProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isMd, isSm } = useResponsive();
  const { locale } = useLocale();

  if (
    isAuthenticated &&
    !isSubmitting &&
    !location.pathname.includes('/onboarding')
  ) {
    navigate('/dashboard', { replace: true });
  }

  const prefilledEmail = searchParams.get('email') || undefined;
  const showLoginPrompt = !prefilledEmail && isSm;

  const handleSubmit = async (password?: string, email?: string) => {
    if (!email || !password) {
      return;
    }

    const profileId = await handleCreateAccount(email, password);
    trackSignUpFlowCompleted(profileId, false);
  };

  const handleCreateAccount = async (
    email: string,
    password: string
  ): Promise<string> => {
    const aiGeneratedCompanyId = searchParams.get('preview');
    const fromGenericStorefront = searchParams.get('source') === 'generic';
    const signupType =
      aiGeneratedCompanyId || fromGenericStorefront
        ? SignupType.AISignup
        : SignupType.SelfSignup;
    const profileId = await createAccount(email, password, signupType);

    if (aiGeneratedCompanyId) {
      const userId = await addAccountToCompany(profileId, aiGeneratedCompanyId);
      return userId;
    }

    const generatedDomain = await generateRandomDomain();

    const defaultCurrency = localeToCurrency[locale] || 'EUR';

    const payload: Partial<CompanyProfile> = {
      users: [profileId],
      languages: [i18n.language],
      defaultLanguage: i18n.language,
      domains: [generatedDomain],
      defaultCurrency,
      features: {
        giftCards: {
          enabled: true,
          params: {},
        },
      },
    };
    await updateCompany.mutateAsync(payload);
    await createDomain({ type: 'subdomain', domain: generatedDomain });

    navigate(routes.onboarding.index, { replace: true });
    return profileId;
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderRadius: 4,
      }}
    >
      <Stack
        gap={4}
        sx={{
          maxWidth: { md: isFocused ? '100%' : '450px' },
          width: '100%',
          padding: isMd ? 3 : 4,
          borderRadius: showLoginPrompt ? `16px 16px 0 0` : 2,
          boxShadow: showLoginPrompt
            ? undefined
            : {
                xs: lightTheme.shadows.small,
                md: lightTheme.shadows.medium,
              },
          backgroundColor: lightTheme.palette.contrast.white,
        }}
      >
        <Stack gap={4} alignItems={'center'}>
          <img
            src={'/icon-moss.svg'}
            alt="Understory Icon"
            width={80}
            height={80}
            style={{ borderRadius: '10px' }}
          />
          {(isMd || isFocused) && (
            <Stack gap={1} textAlign={'center'}>
              <Text fontSize="h5">{t('newHeader')}</Text>
              <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
                {t('newSubtitle1')} {t('newSubtitle2')}
              </Text>
            </Stack>
          )}
        </Stack>
        <SignupForm
          isSubmitting={isSubmitting}
          prefilledEmail={prefilledEmail}
          setIsSubmitting={setIsSubmitting}
          callback={handleSubmit}
        />
      </Stack>
      {showLoginPrompt && <LoginPrompt variant="inline" />}
    </Stack>
  );
};

async function generateRandomDomain(): Promise<string> {
  let isAvailable = false;
  let generatedDomain: string | undefined = undefined;
  do {
    generatedDomain = generate({
      exactly: 3,
      join: '-',
    });

    const { available } = await domainAvailable(generatedDomain);
    isAvailable = available;
  } while (!isAvailable);

  return generatedDomain;
}

const localeToCurrency: Record<string, string> = {
  da: 'dkk',
  sv: 'sek',
  no: 'nok',
  nb: 'nok',
  en: 'usd',
  'en-GB': 'gbp',
  'en-US': 'usd',
  de: 'eur',
};
