import { Link as MuiLink, styled } from '@mui/material';
import { lightTheme, Text, TextProps } from '@understory-io/pixel';
import { forwardRef, HTMLAttributeAnchorTarget, ReactNode } from 'react';

export type LinkProps = {
  href: string;
  fontSize?: TextProps['fontSize'];
  rel?: string;
  className?: string;
  isExternal?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  style?: React.CSSProperties;
  target?: HTMLAttributeAnchorTarget;
  label?: string;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      fontSize,
      rel: relProp,
      isExternal: isExternalProp,
      target: targetProp,
      icon,
      iconPosition = 'right',
      className,
      style,
      href,
      label,
      ...otherProps
    },
    ref
  ) => {
    const getProp = <P,>(value: P, fallback: P) => {
      if (value !== undefined) return value;

      return fallback;
    };

    const isExternal = getProp(isExternalProp, href.startsWith('http'));
    const target = getProp(targetProp, isExternal ? '_blank' : undefined);
    const rel = getProp(relProp, isExternal ? 'noreferrer noopener' : '');

    return (
      <StyledLink>
        <MuiLink
          href={href}
          target={target}
          rel={rel}
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          {...otherProps}
        >
          {icon && iconPosition === 'left' && icon}
          <StyledLinkText
            fontSize={fontSize}
            className={className}
            style={style}
          >
            {children}
          </StyledLinkText>
        </MuiLink>
        {icon && iconPosition === 'right' && icon}
      </StyledLink>
    );
  }
);

Link.displayName = 'Link';

const StyledLink = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: lightTheme.tokens.spaces['0.5'],
});

const StyledLinkText = styled(Text)({
  color: lightTheme.palette.action.a300,
  '&:hover': { textDecoration: 'underline' },
});
