import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Close, OpenWithOutlined } from '@mui/icons-material';
import {
  alpha,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { forwardRef } from 'react';

import { DraggableVideoPreview } from '../../../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';
import { Image } from '../../../../../Components/media/image';
import { useTranslate } from '../../../../../Hooks/useTranslate';

type MediaPreviewProps = {
  url: string;
  type: MediaItem['type'];
  onDelete?: (url: string) => void;
  isCover?: boolean;
  sx?: SxProps;
  isMoving?: boolean;
  listeners?: DraggableSyntheticListeners;
  attributes?: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
  progress?: number;
  mimeType?: string;
  isLocal?: boolean;
};

const BUTTON_SIZE_PX = { xs: 32, md: 24 };
const ICON_SIZE_PX = { xs: 16, md: 12 };

export const MediaPreview = forwardRef<HTMLDivElement, MediaPreviewProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (
    {
      url,
      type,
      isCover,
      onDelete,
      sx,
      isMoving,
      attributes,
      listeners,
      progress,
      mimeType,
      isLocal,
      ...props
    }: MediaPreviewProps,
    ref
  ) => {
    const { t } = useTranslate('experience.edit.details.media');

    return (
      <Stack
        ref={ref}
        sx={{
          ...sx,
          backgroundColor: lightTheme.palette.contrast.surface2,
          borderRadius: 1.5,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 10,
          maxWidth: '100%',
          '&:hover .move': {
            opacity: 1,
          },
        }}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        {!isMoving && onDelete && (
          <IconButton
            onClick={() => onDelete(url)}
            sx={{
              position: 'absolute',
              width: BUTTON_SIZE_PX,
              height: BUTTON_SIZE_PX,
              top: 8,
              right: 8,
              zIndex: 10,
              borderWidth: 0.75,
              borderColor: lightTheme.palette.contrast.surface1,
              color: lightTheme.palette.contrast.black,
              backgroundColor: lightTheme.palette.contrast.white,
              '&:hover': {
                backgroundColor: lightTheme.palette.contrast.surface1,
              },
            }}
          >
            <Close sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }} />
          </IconButton>
        )}
        {isCover && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 12,
              left: 12,
              borderRadius: 1.5,
              paddingX: 1,
              paddingY: 0.5,
              backgroundColor: lightTheme.palette.contrast.surface1,
            }}
          >
            <Text
              color={lightTheme.palette.neutral.n400}
              fontWeight="medium"
              fontSize="xsmall"
            >
              {t('cover')}
            </Text>
          </Box>
        )}
        {type === 'image' ? (
          <Image src={url} sx={{ aspectRatio: '1 / 1' }} />
        ) : (
          <Stack sx={{ aspectRatio: '1 / 1' }}>
            <DraggableVideoPreview
              styles={videoJSStyles}
              options={{
                autoplay: listeners ? false : true,
                loop: true,
                muted: true,
                sources: [
                  {
                    src: url,
                    type: isLocal ? mimeType : 'application/x-mpegURL',
                  },
                ],
              }}
            />
          </Stack>
        )}
        {!!listeners && (
          <IconButton
            className="move"
            sx={{
              display: { xs: 'none', md: 'flex' },
              transition: 'opacity 0.1s ease-in-out',
              opacity: 0,
              backgroundColor: lightTheme.palette.contrast.black,
              color: lightTheme.palette.contrast.white,
              width: 48,
              height: 48,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              touchAction: 'none',
            }}
            {...attributes}
            {...listeners}
            size="large"
          >
            <OpenWithOutlined sx={{ width: 24, height: 24 }} />
          </IconButton>
        )}
        {progress && (
          <Stack
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: alpha(lightTheme.palette.contrast.black, 0.75),
            }}
          >
            <CircularProgress
              sx={{ color: lightTheme.palette.contrast.white }}
              variant="determinate"
              value={progress < 20 ? 20 : progress}
            />
          </Stack>
        )}
      </Stack>
    );
  }
);

MediaPreview.displayName = 'MediaPreview';

const videoJSStyles = {
  parent: {},
  video: {
    width: '100%',
    height: '100%',
  },
};
