import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { LinkButton, Text } from '@understory-io/pixel';
import { useEffect } from 'react';
import { Outlet, useHref, useLinkClickHandler } from 'react-router';

import { ampli } from '../../../Ampli';
import { UsersList } from '../../../Components/users-list/users-list';
import { useAuth } from '../../../Hooks/useAuth';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';

export const SettingsTeamSection = () => {
  const { t } = useTranslate('settings.team');

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="medium" fontSize="large" as={'h2'}>
          {t('title')}
        </Text>
        <Stack gap={1.5} direction={'row'}>
          <EditRolesButton />
          <InviteButton />
        </Stack>
      </Stack>
      <UsersList />
      <Outlet />
    </Stack>
  );
};

const EditRolesButton = () => {
  const { t } = useTranslate('settings.team.roles');

  const editRolesLink = useHref(routes.settings.users.roles.index);
  const editRolesClickHandler = useLinkClickHandler(editRolesLink);

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.teamPageViewed()), [fireOnce]);

  return (
    <LinkButton
      href={editRolesLink}
      variant="secondary"
      size="small"
      onClick={editRolesClickHandler}
    >
      {t('editRoles')}
    </LinkButton>
  );
};

const InviteButton = () => {
  const { t } = useTranslate('settings.team');
  const { canAccess } = useAuth();

  const inviteLink = useHref(routes.settings.users.invite);
  const inviteClickHandler = useLinkClickHandler(inviteLink);

  if (!canAccess('role.write')) {
    return null;
  }

  return (
    <LinkButton
      href={inviteLink}
      variant="primary"
      size="small"
      leftIcon={<AddRounded />}
      onClick={(e) => {
        ampli.inviteNewUserFlowStarted();
        inviteClickHandler(e);
      }}
    >
      {t('buttonLabel')}
    </LinkButton>
  );
};
