import { AddOutlined, ClearOutlined } from '@mui/icons-material';
import { Chip, Stack, TextField } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { filterOptions } from './filter-options';
import { TagFilterItem } from './tags';

interface TagsFormProps {
  tagOptions: TagFilterItem[];
  selectedTags: TagFilterItem[];
  onSelect: (tag: TagFilterItem) => void;
  onCreate: (value: string) => void;
  title?: string;
  placeholder?: string;
  createLabel?: string;
}

export const TagsForm = ({
  tagOptions,
  selectedTags,
  onSelect,
  onCreate,
  title,
  placeholder,
  createLabel,
}: TagsFormProps) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const searchResults = useMemo(
    () =>
      filterOptions(tagOptions, {
        inputValue: searchValue,
        getOptionLabel: (option) => option.label,
      }),
    [searchValue, tagOptions]
  );

  const searchResultIds = useMemo(
    () => searchResults.map(({ id }) => id),
    [searchResults]
  );
  const selectedTagIds = useMemo(
    () => selectedTags.map(({ id }) => id),
    [selectedTags]
  );

  const sortedTags = useMemo(
    () =>
      tagOptions
        .sort((a, b) => sortTagOptions(a, b, selectedTagIds))
        .sort((a, b) =>
          sortTagsBasedOnSearch(a, b, searchValue, searchResultIds)
        ),
    [searchResultIds, searchValue, selectedTagIds, tagOptions]
  );

  const isSearchResultAnExistingTag = useMemo(
    () =>
      tagOptions.some(
        (tag) =>
          searchValue.toLocaleLowerCase() === tag.label.toLocaleLowerCase()
      ),
    [searchValue, tagOptions]
  );

  return (
    <Stack sx={{ gap: 2 }}>
      <TextField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={
          placeholder ||
          t('experience.edit.dialog.tags.autoComplete.placeholder')
        }
        InputProps={{
          endAdornment: searchValue && !isSearchResultAnExistingTag && (
            <Chip
              role="button"
              icon={<AddOutlined />}
              size="small"
              sx={{
                backgroundColor: lightTheme.palette.contrast.surface1,
              }}
              label={
                createLabel ||
                t('experience.edit.dialog.tags.autoComplete.createTag', {
                  value: `"${searchValue}"`,
                })
              }
              onClick={() => {
                setSearchValue('');
                onCreate(searchValue);
              }}
            />
          ),
        }}
      />
      {tagOptions.length > 0 && (
        <Stack sx={{ gap: 1.5 }}>
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {title || t('experience.edit.dialog.tags.list.title')}
          </Text>
          <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 1.5 }}>
            {sortedTags.map((tag) => {
              const isSelected = selectedTagIds.includes(tag.id);
              const isSearchResult = searchResultIds.includes(tag.id);

              return (
                <TagChip
                  key={tag.id}
                  id={tag.id}
                  label={tag.label}
                  isSelected={isSelected}
                  isSearchResult={isSearchResult}
                  onAdd={() => onSelect(tag)}
                  onDelete={() => onSelect(tag)}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const TagChip = ({
  id,
  label,
  isSelected,
  isSearchResult,
  onAdd,
  onDelete,
}: {
  id: string;
  label: string;
  isSelected: boolean;
  isSearchResult: boolean;
  onAdd: (id: string) => void;
  onDelete: (id: string) => void;
}) => {
  return (
    <Chip
      key={id}
      label={label}
      icon={!isSelected ? <AddOutlined /> : undefined}
      deleteIcon={
        isSelected ? (
          <ClearOutlined
            sx={{
              color: lightTheme.palette.neutral.n300 + ' !important',
              ':hover': {
                color: lightTheme.palette.neutral.n500 + ' !important',
              },
            }}
          />
        ) : undefined
      }
      onClick={!isSelected ? () => onAdd?.(id) : undefined}
      onDelete={isSelected ? () => onDelete?.(id) : undefined}
      size="small"
      sx={{
        opacity: !isSearchResult ? 0.5 : undefined,
        backgroundColor: isSelected
          ? lightTheme.palette.action.a100
          : lightTheme.palette.contrast.surface1,
      }}
    />
  );
};

const sortTagsBasedOnSearch = (
  a: TagFilterItem,
  b: TagFilterItem,
  searchValue: string,
  searchResultIds: string[]
) => {
  if (!searchValue) return 0;
  if (searchResultIds.includes(a.id) && searchResultIds.includes(b.id)) {
    return 0;
  }

  if (searchResultIds.includes(a.id)) {
    return -1;
  }

  if (searchResultIds.includes(b.id)) {
    return 1;
  }

  return 0;
};

const sortTagOptions = (
  a: TagFilterItem,
  b: TagFilterItem,
  selectedTagIds: string[]
) => {
  if (selectedTagIds.includes(a.id) && selectedTagIds.includes(b.id)) {
    return 0;
  }

  if (selectedTagIds.includes(a.id)) {
    return -1;
  }

  if (selectedTagIds.includes(b.id)) {
    return 1;
  }

  return a.label.localeCompare(b.label);
};
