import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';

import DialogBase from '../../../Components/dialog/dialog-base';

export const StorefrontCompanyNameInfoDialog = NiceModal.create(() => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleClose = () => {
    modal.resolve();
    modal.remove();
  };

  return (
    <DialogBase
      open={modal.visible}
      onClose={handleClose}
      maxWidth="xs"
      title={t('settings.company.storefrontCompanyName.infoDialog.title')}
      description={t(
        'settings.company.storefrontCompanyName.infoDialog.description'
      )}
      primaryAction={{
        label: t('utils.generic.gotIt'),
        onClick: handleClose,
        variant: 'primary',
      }}
    />
  );
});
