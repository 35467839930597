import { FC } from 'react';

import { NoExperienceTitle } from '../../../../../../features/experiences/overview/experience-item';
import { useLocale } from '../../../../../../Hooks/locales/use-locale.context';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { PageBreadcrumbItem } from '../page-breadcrumb-item';

type Props = {
  experienceId: string | undefined;
};

export const PageBreadcrumbBreadcrumbsExperienceDetails: FC<Props> = ({
  experienceId,
}) => {
  const {
    experience: { data: experience },
  } = useExperience(experienceId);

  const { getLocalizedString } = useLocale();

  const link = experience?.id ? `/experience/${experience.id}` : '/experiences';
  const headline = getLocalizedString(experience?.headline);

  return (
    <PageBreadcrumbItem title={headline || <NoExperienceTitle />} href={link} />
  );
};
