import { AddOutlined, EditOutlined } from '@mui/icons-material';
import { StackProps } from '@mui/material';
import { LinkProps } from 'react-router';

import { FullFormPageDetailsSettingRoot } from './full-page-form-setting-root';
import {
  FullPageFormSettingRow,
  FullPageFormSettingRowProps,
} from './full-page-form-setting-row';

type Props = Omit<StackProps<'a', LinkProps>, 'component' | 'children'> &
  Omit<FullPageFormSettingRowProps, 'action'> & {
    /** The icon used for an action to the right in the row */
    ActionIcon?: NonNullable<FullPageFormSettingRowProps['action']>['Icon'];
  };

/**
 * A setting with the entire row as a link.
 */
export const FullPageFormSettingSingleItem = ({
  title,
  value,
  valueTooltip,
  ActionIcon,
  Icon,
  description,
  ...props
}: Props) => {
  ActionIcon ??= value ? EditOutlined : AddOutlined;

  return (
    <FullFormPageDetailsSettingRoot {...props}>
      <FullPageFormSettingRow
        title={title}
        description={description}
        Icon={Icon}
        value={value}
        valueTooltip={valueTooltip}
        action={{ Icon: ActionIcon, to: props.to }}
      />
    </FullFormPageDetailsSettingRoot>
  );
};
