import { Experience } from '@understory-io/experiences-types';

import { ampli } from '../../../../Ampli';
import { getLocalizedString } from '../../../../Hooks/locales/use-locale.context';

export const trackExperienceDetailsPageOpened = (experience: Experience) => {
  ampli.experienceDetailsPageOpened({
    experience_id: experience.id,
    experience_name: getLocalizedString(experience.headline, ['en']) ?? '',
    is_private: experience.visibility === 'private',
    language_list: experience.languages,
  });
};
