import {
  Box,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useContext } from 'react';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useLanguages } from '../../../Hooks/locales/use-languages';
import { useLocale } from '../../../Hooks/locales/use-locale.context';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useUsers } from '../../../Hooks/useUsers';
import { mapAssignableGuidesToUsers } from '../../../Utils/map-assignable-guides-to-users';
import { useResourceReservations } from '../../resource-management/data/use-resource-reservations';
import { EventDetailsContext } from './EventDetailsView';
import { ResourceReservationsDetails } from './resource-reservations-details';
import { ResourceRulesDetails } from './resource-rules-details';
import { Accordion, StyledTable } from './shared';

export const Details = () => {
  const { experience, event, isLoading, eventId } =
    useContext(EventDetailsContext);
  const { t } = useTranslate('events.detailsPage.details');

  const { isMd } = useResponsive();
  const { users } = useUsers();
  const { data: resourceReservations } = useResourceReservations(eventId);

  const address = event?.addresses?.value;

  const { getLocalizedString } = useLocale();

  const { languageOption } = useLanguages();

  const guides =
    event && users.data
      ? mapAssignableGuidesToUsers(event.assignedGuides, users.data)
          .map((guide) => guide.name)
          .join(', ')
      : undefined;

  const languages = event?.languages
    .map((locale) => {
      const { label, country } = languageOption(locale);
      return country ? `${label} (${country})` : label;
    })
    .join(', ');

  const rows = [
    {
      key: 'location',
      value: address,
    },
    {
      key: 'guides',
      value: guides,
    },
    { key: 'languages', value: languages },
    {
      key: 'information',
      value: getLocalizedString(event?.information),
    },
    {
      key: 'resourceRules',
      value: event?.resourceManagementConfig && (
        <ResourceRulesDetails
          experience={experience}
          rules={event.resourceManagementConfig.rules}
        />
      ),
    },
    {
      key: 'resources',
      value: resourceReservations?.items.length && (
        <ResourceReservationsDetails
          reservations={resourceReservations.items}
        />
      ),
    },
  ];

  if (isMd)
    return (
      <Stack>
        <Accordion title={t('title')}>
          <Stack gap={2} pb={3}>
            {rows.map((row) => {
              if (!row || !row?.value) return null;

              return (
                <Stack key={row.key}>
                  <Text variant="medium"> {t(row.key)}</Text>
                  <Text>{row.value}</Text>
                </Stack>
              );
            })}
          </Stack>
        </Accordion>
      </Stack>
    );

  return (
    <Box flexGrow={1} display="flex" width={'60%'}>
      <InfoBox title={t('title')}>
        <StyledTable>
          <TableBody>
            {rows.map((row) => {
              if (!row) return;

              const value = isLoading ? (
                <Skeleton height={19.6} width={50} />
              ) : (
                row.value
              );

              if (!value) return;

              return (
                <TableRow key={row.key}>
                  <TableCell
                    component="th"
                    scope="row"
                    size="small"
                    padding="none"
                  >
                    {t(row.key)}
                  </TableCell>
                  <TableCell size="small" padding="none">
                    {value}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </InfoBox>
    </Box>
  );
};
