import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useTips } from '../../../Components/tip-sidebar/use-tips';
import { useExperience } from '../../../Hooks/useExperience';
import { useHasPaymentsEnabled } from '../../../Hooks/useHasPaymentsEnabled';
import { useProfile } from '../../../Hooks/useProfile';
import { useTerms } from '../../../Hooks/useTerms';
import {
  AVAILABLE_SUGGESTIONS,
  MAX_VISIBLE_SUGGESTIONS,
  Suggestion,
} from './config';

export const useSuggestions = () => {
  const navigate = useNavigate();
  const { company } = useProfile();
  const { experiences } = useExperience(undefined, {
    includeDrafts: true,
  });
  const { hasPaymentsEnabled, isLoadingPayments } = useHasPaymentsEnabled();
  const { terms } = useTerms();
  const { suggestionTips } = useTips();

  const hasExperience = useMemo(() => {
    return (experiences.data?.length ?? 0) > 0;
  }, [experiences.data]);

  const hasCompanyDetails = useMemo(() => {
    if (!company.data) return false;
    return Boolean(
      company.data.name &&
        (company.data.companyEmail || company.data.companyPhone)
    );
  }, [company.data]);

  const getIncompleteSuggestions = useCallback(() => {
    return AVAILABLE_SUGGESTIONS.filter((suggestion) => {
      switch (suggestion.id) {
        case 'createExperience':
          return !hasExperience;
        case 'enablePayments':
          return !hasPaymentsEnabled;
        case 'companyDetails':
          return !hasCompanyDetails;
        // Style elements and integrate website are always shown until manually dismissed
        default:
          return true;
      }
    });
  }, [hasExperience, hasPaymentsEnabled, hasCompanyDetails]);

  const handleSuggestionClick = useCallback(
    (suggestion: Suggestion) => {
      const hasTip = suggestion.route in suggestionTips;
      if (hasTip) {
        const searchParams = new URLSearchParams();
        searchParams.set('from', 'suggestion');
        navigate({
          pathname: suggestion.route,
          search: searchParams.toString(),
        });
      } else {
        navigate(suggestion.route);
      }
    },
    [navigate, suggestionTips]
  );

  const visibleSuggestions = useMemo(() => {
    const incompleteSuggestions = getIncompleteSuggestions();
    return incompleteSuggestions
      .slice(0, MAX_VISIBLE_SUGGESTIONS)
      .map((suggestion) => ({
        ...suggestion,
        onClick: () => handleSuggestionClick(suggestion),
      }));
  }, [getIncompleteSuggestions, handleSuggestionClick]);

  const isLoading =
    company.isLoading ||
    experiences.isLoading ||
    isLoadingPayments ||
    terms.isLoading;

  return {
    suggestions: visibleSuggestions,
    isLoading,
    hasPaymentsEnabled,
  };
};
