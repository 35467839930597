import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { DraftExperience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../Api/Experience';
import {
  companyProfileQuery,
  draftExperienceQuery,
} from '../../../../Api/queries';
import MarkdownEditor from '../../../../Components/markdown-editor/markdown-editor';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontLanguage, t } from '../../../../i18n/config';
import type { Experience } from '../../schemas';
import { whatsIncludedSchema } from '../../schemas/whatsIncludedSchema';
import { EditExperienceDialog } from '../components/dialogs/edit-experience-dialog';
import { loaderName } from '../edit-experience';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { translateInput } from '../utils/translate-input';

const PROPERTY_NAME = 'whatsIncluded';

export default function WhatsIncludedDialog() {
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.whatsIncluded');
  const { experience, activeLanguage } = useRouteLoaderData(loaderName) as {
    experience: Experience;
    activeLanguage: StorefrontLanguage;
    autoTranslate: boolean;
  };

  const [whatsIncluded, setWhatsIncluded] = useState(
    experience.whatsIncluded?.[activeLanguage]
  );

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      type="whats-included"
    >
      <input name={PROPERTY_NAME} type="hidden" value={whatsIncluded} />
      <MarkdownEditor
        autoFocus={true}
        placeholder={t('input.placeholder')}
        value={whatsIncluded}
        onChange={(e) => {
          setWhatsIncluded(e);
        }}
      />
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e300} variant="normal">
          {t(actionData.error)}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs): Promise<ActionData> => {
    const id = params.id;

    if (!id) {
      throw new Response('Invalid id', { status: 404 });
    }

    try {
      // Validate input
      const formData = await request.formData();
      const whatsIncluded = getFormDataValue(formData.get(PROPERTY_NAME));

      const [experience, companyProfile] = await Promise.all([
        client.fetchQuery(draftExperienceQuery(id)),
        client.fetchQuery(companyProfileQuery()),
      ]);

      const activeLanguage = getActiveLanguage(request, companyProfile);

      const translatedWhatsIncluded = await translateInput(
        whatsIncluded,
        experience.whatsIncluded,
        activeLanguage,
        companyProfile.languages as StorefrontLanguage[],
        experience.autoTranslateEnabled
      );

      const result = whatsIncludedSchema.validateSync(translatedWhatsIncluded);

      const experienceToSave = {
        ...experience,
        whatsIncluded: result,
      } as DraftExperience;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        return {
          error: error.message,
        };
      }

      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
