import NiceModal from '@ebay/nice-modal-react';
import { isVirtualId } from '@understory-io/utils-events';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useCancelEvent } from '../../../../Hooks/events/useCancelEvent';
import { useErrorRetry } from '../../../../Hooks/useErrorRetry';
import { useExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CancelEventDialog } from '../../../../Modals/cancel_event';
import {
  CancellationStates,
  ICancelEventData,
} from '../../../../Modals/cancel_event/domain/types';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import { trackEventCancellationStarted } from '../../../../tracking/events/trackEventCancellationStarted';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { isCompleted } from './shared';

export const useCancelEventAction = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const { t } = useTranslate('events.detailsPage.card');

  const { cancelEvent } = useCancelEvent();
  const {
    experience: { data: experience },
  } = useExperience(event?.experienceId);

  const { showErrorRetryDialog } = useErrorRetry();

  const navigate = useNavigate();

  const tryCancelling = async (payload: ICancelEventData) => {
    try {
      const cancelPromise = cancelEvent.mutateAsync({
        payload,
        eventId: event!.id,
      });
      const response = await toast.promise(cancelPromise, {
        pending: t('toast.cancellingLoading'),
        success: t('toast.cancellingSuccess'),
      });

      if (isVirtualId(event!.id)) {
        navigate(`/event/${response.event.id}`);
      }
    } catch (error) {
      let retry = false;
      const errorCode = error instanceof Error ? error.message : '';

      if (errorCode === CancellationStates.FAILED_PARTLY) {
        retry = await showErrorRetryDialog(
          t('dialogs.cancellingError.titlePartly'),
          t('dialogs.cancellingError.descriptionPartly')
        );
      } else {
        retry = await showErrorRetryDialog(
          t('dialogs.cancellingError.titleGeneric'),
          t('dialogs.cancellingError.descriptionGeneric')
        );
      }

      if (retry) {
        tryCancelling(payload);
      }
    }
  };

  const showCancelDialog = () => {
    trackEventCancellationStarted(event!, experience, source);

    NiceModal.show(CancelEventDialog, {
      booked: Boolean(event!.slots?.booked),
      eventId: event!.id,
    })
      .then((noteAndRefund: unknown) => {
        if (noteAndRefund) {
          tryCancelling(noteAndRefund as ICancelEventData);
        }
      })
      // Intentionally left empty to prevent logging errors
      .catch();
  };

  const isDisabled = event
    ? isCompleted(event) || ['cancelled', 'draft'].includes(event?.status)
    : false;

  return { showCancelDialog, isDisabled };
};
