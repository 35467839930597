import { Stack } from '@mui/material';
import { Localized } from '@understory-io/utils-types';
import { RichTextEditorRef } from 'mui-tiptap';
import { useEffect, useRef, useState } from 'react';

import useResponsive from '../../Hooks/layout/useResponsive';
import { StorefrontLanguage } from '../../i18n/config';
import MarkdownEditor from '../markdown-editor/markdown-editor';
import { LanguageNotification } from './language-notification';
import { LanguageSelect } from './language-select';
import { useAbortableTranslations } from './use-abortable-translations';

export type TranslatableInputProps = {
  value: Localized;
  onChange: (value: Localized) => void;
  onTranslate?: (isTranslating: boolean) => void;
  defaultLanguage: StorefrontLanguage;
  availableLanguages: StorefrontLanguage[];
  showAutoTranslateTooltip?: boolean;
  placeholder?: string;
};

export const DEBOUNCE_TIMEOUT_MS = 1000;

export function TranslatableInput({
  value,
  defaultLanguage,
  availableLanguages,
  onChange,
  onTranslate,
  showAutoTranslateTooltip = true,
  placeholder,
}: TranslatableInputProps) {
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
  const { isSm } = useResponsive();
  const editorRef = useRef<RichTextEditorRef>(null);

  const [hasUntranslatedValues, setHasUntranslatedValues] = useState(false);
  const [autoTranslateOn, setAutoTranslateOn] = useState(true);
  const [showLanguageAlert, setShowLanguageAlert] = useState(
    showAutoTranslateTooltip
  );

  const triggerTranslate = useAbortableTranslations(
    currentLanguage,
    availableLanguages,
    autoTranslateOn && hasUntranslatedValues,
    (results) => {
      setHasUntranslatedValues(false);
      onChange(results);
    },
    onTranslate
  );

  const timeoutRef = useRef<NodeJS.Timeout>();
  const handleChange = (fieldValue: string) => {
    onChange({
      ...value,
      [currentLanguage]: fieldValue,
    });
    setHasUntranslatedValues(true);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      triggerTranslate(fieldValue);
    }, DEBOUNCE_TIMEOUT_MS);
  };

  // Handles language change in the editor because when the value changes,
  // the editor errors out due to character location shifts, causing it to
  // throw and error: Failed to execute 'removeChild' on 'Node'
  useEffect(() => {
    const editor = editorRef.current?.editor;
    if (editor) {
      editor.commands.setContent(value[currentLanguage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Stack gap={2}>
      {isSm && (
        <LanguageSelect
          activeLanguage={currentLanguage}
          availableLanguages={availableLanguages}
          isAutoTranslateEnabled={autoTranslateOn}
          handleChangeLanguage={(value) => {
            setCurrentLanguage(value);
          }}
          handleChangeAutoTranslate={(value) => {
            setAutoTranslateOn(value);
          }}
        />
      )}
      <MarkdownEditor
        ref={editorRef}
        autoFocus={true}
        value={value[defaultLanguage]}
        placeholder={placeholder}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {!isSm && (
          <LanguageSelect
            activeLanguage={currentLanguage}
            availableLanguages={availableLanguages}
            isAutoTranslateEnabled={autoTranslateOn}
            handleChangeLanguage={(value) => {
              setCurrentLanguage(value);
            }}
            handleChangeAutoTranslate={(value) => {
              setAutoTranslateOn(value);
              setShowLanguageAlert(value);
            }}
          />
        )}
      </MarkdownEditor>
      {showLanguageAlert && (
        <LanguageNotification onClick={() => setShowLanguageAlert(false)} />
      )}
    </Stack>
  );
}
