import { MenuItem, Skeleton, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useCountryVatRates } from '../../../../../../Hooks/useCountryVatRates';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import {
  getTaxRateLabel,
  getVatRatesAsArray,
} from '../../../../../../Utils/tax/tax';
import { StyledTextField } from '../../../../shared/StyledTextField';

type CompanyFormVatRegistrationCategoryProps = {
  fixedWidth?: boolean;
  disabled?: boolean;
};

export const CompanyFormVatRegistrationCategory = ({
  fixedWidth,
  disabled,
}: CompanyFormVatRegistrationCategoryProps) => {
  const { t } = useTranslate('');

  const { data: countryVatRates } = useCountryVatRates();

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedVatCountry = watch('vatCompliance.vatRegistration.country');

  const Field = fixedWidth ? StyledTextField : TextField;

  if (!countryVatRates)
    return (
      <Skeleton
        variant="rectangular"
        width={fixedWidth ? 320 : '100%'}
        height={56}
      />
    );

  return (
    <Controller
      name={'vatCompliance.vatRegistration.defaultVatCategory'}
      defaultValue={''}
      render={({ field }) => (
        <Field
          {...field}
          fullWidth={!fixedWidth}
          value={field.value || ''}
          error={
            !!(errors.vatCompliance as any)?.vatRegistration?.defaultVatCategory
          }
          helperText={
            (errors.vatCompliance as any)?.vatRegistration?.defaultVatCategory
              ?.message || ''
          }
          select
          required
          label={t('defaultRate', 'settings.company.vatSettings')}
          disabled={!selectedVatCountry || disabled}
        >
          {getVatRatesAsArray(countryVatRates[selectedVatCountry]?.rates).map(
            (rate) => {
              const name = rate.category;

              const { label, rateLabel } = getTaxRateLabel(
                selectedVatCountry,
                rate,
                t
              );

              return (
                <MenuItem key={name} value={name} style={{ display: 'block' }}>
                  {label} ({rateLabel})
                </MenuItem>
              );
            }
          )}
        </Field>
      )}
    />
  );
};
