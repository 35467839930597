import { ampli } from '../../Ampli';

// Profile
export const trackProfileLanguageSelected = (
  language: string,
  userId: string
) => {
  ampli.profileLanguageSettingsSelected({ language });
  ampli.identify(userId, { 'User Profile Language': language });
};

export const trackProfileLanguageSettingsOpened = () =>
  ampli.profileLanguageSettingsOpened();

// Company
export const trackCompanyVatSettingsUpdated = (
  data: { isTaxRegistered: boolean; taxCountry: string },
  userId: string
) => {
  ampli.identify(userId, {
    'Vat Status': data.isTaxRegistered ? 'Registered' : 'Not Registered',
    'Vat Country': data.isTaxRegistered ? data.taxCountry : 'Not Registered',
  });

  if (data.isTaxRegistered) {
    ampli.vatCountrySelectorCompleted({
      vat_country: data.taxCountry,
    });
  }
};

// Notifications
const trackNotificationTipsSelected = (is_enabled: boolean, userId: string) => {
  ampli.notificationTipsSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled Tips': is_enabled });
};

const trackNotificationBookingSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationBookingSelected({ is_enabled });
  ampli.identify(userId, { 'Notifications Enabled Bookings': is_enabled });
};

const trackNotificationEventsSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationEventsSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled Events': is_enabled });
};

const trackNotificationGiftcardSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationGiftcardSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled GiftCard': is_enabled });
};

export const trackNotificationToggled = (
  notification: string,
  is_enabled: boolean,
  userId: string
) => {
  notificationTrackingFns[notification as keyof typeof notificationTrackingFns](
    is_enabled,
    userId
  );
};

const notificationTrackingFns = {
  'b-account-welcome-email': trackNotificationTipsSelected,
  'b-booking-new-email': trackNotificationBookingSelected,
  'b-events-upcoming-event-reminder-email': trackNotificationEventsSelected,
  'b-voucher-sold-email': trackNotificationGiftcardSelected,
};

export const trackBillingPageViewed = () => {
  return ampli.billingPageViewed();
};

export const trackBillingSettingsUpdated = ({
  isTaxRegistered,
}: {
  isTaxRegistered: boolean;
}) => {
  return ampli.billingSettingsUpdated({
    is_tax_registered: isTaxRegistered,
  });
};
