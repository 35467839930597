import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { useTranslate } from '../../../Hooks/useTranslate';
import { SignupUsps } from './ui/signup-usps';

export const SignUpHero = () => {
  const { t } = useTranslate('signup');

  return (
    <Stack
      sx={{
        p: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          maxWidth: '420px',
          gap: 5,
        }}
      >
        <Stack sx={{ gap: 1.5 }}>
          <Text fontSize="h3">{t('newHeader')}</Text>
          <Text>
            {t('newSubtitle1')}
            <br />
            {t('newSubtitle2')}
          </Text>
        </Stack>
        <SignupUsps />
      </Stack>
    </Stack>
  );
};
