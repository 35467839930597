import { ArrowOutwardRounded } from '@mui/icons-material';
import { LinkButton } from '@understory-io/pixel';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLoaderData, useMatch } from 'react-router';

import { TabLayout } from '../../Components/layout/tab-layout';
import { useProfile } from '../../Hooks/useProfile';
import routes from '../../Utils/routes';

const tabKeys = [
  'company',
  'customize',
  'features',
  'bookingflow',
  'locations',
  'tags',
  'payments',
  'languages',
] as const;
type TabKey = (typeof tabKeys)[number];

type TabConfig = {
  key: TabKey;
  label: string;
  href: string;
};

const TAB_CONFIG = (t: TFunction): Record<TabKey, TabConfig> => ({
  company: {
    key: 'company',
    label: t('storefront.navigation.company'),
    href: routes.storefrontSettings.company.index,
  },
  customize: {
    key: 'customize',
    label: t('storefront.navigation.customize'),
    href: routes.storefrontSettings.customize,
  },
  features: {
    key: 'features',
    label: t('storefront.navigation.features'),
    href: routes.storefrontSettings.features,
  },
  bookingflow: {
    key: 'bookingflow',
    label: t('storefront.navigation.bookingflow'),
    href: routes.storefrontSettings.integration,
  },
  locations: {
    key: 'locations',
    label: t('storefront.navigation.locations'),
    href: routes.storefrontSettings.locations,
  },
  tags: {
    key: 'tags',
    label: t('storefront.navigation.tags'),
    href: routes.storefrontSettings.tags,
  },
  payments: {
    key: 'payments',
    label: t('storefront.payments.title'),
    href: routes.storefrontSettings.payments,
  },
  languages: {
    key: 'languages',
    label: t('storefront.navigation.languages'),
    href: routes.storefrontSettings.languages,
  },
});

type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async () => {
  const tabs: TabKey[] = [
    'company',
    'customize',
    'features',
    'languages',
    'bookingflow',
    'locations',
    'tags',
    'payments',
  ];

  return { tabs };
};

export default function StorefrontPageLayout() {
  const { tabs } = useLoaderData<LoaderData>();
  const { t } = useTranslation();
  const { companyProfileUrl } = useProfile();

  const tabConfig = useMemo(() => TAB_CONFIG(t), [t]);

  const isCutomizeActive = useMatch(routes.storefrontSettings.customize);
  const isLanguagesActive = useMatch(routes.storefrontSettings.languages);
  const isImplementationActive = useMatch(
    routes.storefrontSettings.integration
  );

  const isPaymentsActive = useMatch(routes.storefrontSettings.payments);
  const isCompanyActive = useMatch(routes.storefrontSettings.company.index);
  const isEditCompanyDescription = useMatch(
    routes.storefrontSettings.company.edit.description
  );

  const shouldUseOldLayout = useMemo(
    () =>
      !isCutomizeActive &&
      !isLanguagesActive &&
      !isImplementationActive &&
      !isPaymentsActive &&
      !isCompanyActive &&
      !isEditCompanyDescription,
    [
      isCutomizeActive,
      isImplementationActive,
      isLanguagesActive,
      isPaymentsActive,
      isCompanyActive,
      isEditCompanyDescription,
    ]
  );

  return (
    <TabLayout
      title={t('storefront.title')}
      tabs={tabs.map((key) => tabConfig[key])}
      shouldUseOldLayout={shouldUseOldLayout}
      actions={
        <LinkButton
          variant="primary"
          size="medium"
          href={companyProfileUrl()}
          target="_blank"
          rightIcon={<ArrowOutwardRounded />}
        >
          {t('dashboard.buttons.goToHoldbar')}
        </LinkButton>
      }
    />
  );
}
