import {
  addYears,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameWeek,
  isWithinInterval,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
} from 'react';

import { useCalendarEvents } from '../../Hooks/events/useCalendarEvents';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useLocale } from '../../Hooks/locales/use-locale.context';
import { trackBookingStatusFilterApplied } from '../../tracking/calendar/filters/trackBookingStatusFilterApplied';
import { trackExperienceFilterApplied } from '../../tracking/calendar/filters/trackExperienceFilterApplied';
import { trackGuideFilterApplied } from '../../tracking/calendar/filters/trackGuideFilterApplied';
import { trackLanguageFilterApplied } from '../../tracking/calendar/filters/trackLanguageFilterApplied';
import { trackLocationFilterApplied } from '../../tracking/calendar/filters/trackLocationFilterApplied';
import { trackEventStatusFilterApplied } from '../../tracking/calendar/filters/trackStatusFilterApplied copy';
import { TEventWithTitle } from '../../Utils/eventHelpers';
import { FILTER_NO_VALUES } from './constants';
import { useCalendarSearchParam } from './use-calendar-search-params';
import { useRestoreUserPreferences } from './use-restore-user-preferences';

export type EventStatus = 'active' | 'cancelled' | 'inactive';
export type BookingStatus = 'fully-booked' | 'booked' | 'no-bookings';

export const CALENDAR_VIEWS = ['day', 'week', 'month'] as const;

export type CalendarView = (typeof CALENDAR_VIEWS)[number];

export const isCalendarView = (value: string): value is CalendarView =>
  CALENDAR_VIEWS.some((interval) => interval === value);

type CalenderContextType = {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;

  minimumDate: Date;
  maximumDate: Date;

  selectedView: CalendarView;
  setSelectedView: Dispatch<SetStateAction<CalendarView>>;

  events?: TEventWithTitle[];
  isLoading: boolean;

  experienceFilters: string[];
  setExperienceFilters: Dispatch<SetStateAction<string[]>>;

  languageFilters: string[];
  setLanguageFilters: Dispatch<SetStateAction<string[]>>;

  locationFilters: string[];
  setLocationFilters: Dispatch<SetStateAction<string[]>>;

  eventStatusFilters: EventStatus[];
  setEventStatusFilters: Dispatch<SetStateAction<EventStatus[]>>;

  bookingStatusFilters: BookingStatus[];
  setBookingStatusFilters: Dispatch<SetStateAction<BookingStatus[]>>;

  guideFilters: string[];
  setGuideFilters: Dispatch<SetStateAction<string[]>>;

  groupByExperience: boolean;
  setGroupByExperience: Dispatch<SetStateAction<boolean>>;
};

export const CalendarContext = createContext<CalenderContextType | null>(null);

export const useCalendar = () => {
  const context = useContext(CalendarContext);

  if (!context) {
    throw new Error(
      'useCalendar must be used within a CalendarContextProvider'
    );
  }

  return context;
};

const MIN_DATE_PICKER = new Date('2022-01-01');
const MAX_DATE_PICKER = addYears(new Date(), 3);

export const CalendarContextProvider = ({ children }: PropsWithChildren) => {
  const { isSm } = useResponsive();

  useRestoreUserPreferences();

  const [selectedDate, setSelectedDate] = useCalendarSearchParam('date');

  const [preferredView, setSelectedView] = useCalendarSearchParam('view');

  const selectedView = useMemo(
    () => (isSm ? 'day' : preferredView),
    [isSm, preferredView]
  );

  const [groupByExperience, setGroupByExperience] =
    useCalendarSearchParam('grouped');

  const [experienceFilters, setExperienceFilters] = useCalendarSearchParam(
    'experiences',
    {
      trackingFn: trackExperienceFilterApplied,
    }
  );

  const [languageFilters, setLanguageFilters] = useCalendarSearchParam(
    'languages',
    {
      trackingFn: trackLanguageFilterApplied,
    }
  );
  const [locationFilters, setLocationFilters] = useCalendarSearchParam(
    'locations',
    {
      trackingFn: trackLocationFilterApplied,
    }
  );
  const [guideFilters, setGuideFilters] = useCalendarSearchParam('guides', {
    trackingFn: trackGuideFilterApplied,
  });
  const [eventStatusFilters, setEventStatusFilters] = useCalendarSearchParam(
    'eventStatus',
    {
      trackingFn: trackEventStatusFilterApplied,
    }
  );
  const [bookingStatusFilters, setBookingStatusFilters] =
    useCalendarSearchParam('bookingStatus', {
      trackingFn: trackBookingStatusFilterApplied,
    });

  const { events, isLoading } = useCalendarEvents(selectedDate);
  const { dateFnsLocale } = useLocale();

  const filteredEvents = useMemo(() => {
    let result = events ?? [];

    result = result.filter((event) => {
      const eventDate = parseISO(event.startDateTime);

      if (selectedView === 'day') {
        return isSameDay(selectedDate, eventDate);
      }

      if (selectedView === 'week') {
        return isSameWeek(selectedDate, eventDate, {
          weekStartsOn: dateFnsLocale.options?.weekStartsOn,
        });
      }

      if (selectedView === 'month') {
        const monthStart = startOfMonth(selectedDate);
        const dateStart = startOfWeek(monthStart, {
          weekStartsOn: dateFnsLocale.options?.weekStartsOn,
        });

        const monthEnd = endOfMonth(selectedDate);
        const dateEnd = endOfWeek(monthEnd, {
          weekStartsOn: dateFnsLocale.options?.weekStartsOn,
        });

        return isWithinInterval(eventDate, { start: dateStart, end: dateEnd });
      }
    });

    if (experienceFilters.length > 0) {
      result = result.filter((event) =>
        experienceFilters.includes(event.experienceId)
      );
    }

    if (languageFilters.length > 0) {
      result = result.filter((event) =>
        filterArrayValues(event.languages, languageFilters)
      );
    }

    if (locationFilters.length > 0) {
      result = result.filter((event) =>
        locationFilters.includes(event.locationId)
      );
    }

    if (guideFilters.length > 0) {
      result = result.filter((event) =>
        filterArrayValues(event.assignedGuides, guideFilters)
      );
    }

    if (eventStatusFilters.length > 0) {
      result = result.filter((event) =>
        eventStatusFilters.some((filter) =>
          filterEventByEventState(event, filter)
        )
      );
    }

    if (bookingStatusFilters.length > 0) {
      result = result.filter((event) =>
        bookingStatusFilters.some((filter) =>
          filterEventByBookingStatus(event, filter)
        )
      );
    }

    return result;
  }, [
    events,
    experienceFilters,
    languageFilters,
    locationFilters,
    guideFilters,
    eventStatusFilters,
    bookingStatusFilters,
    selectedView,
    selectedDate,
    dateFnsLocale.options?.weekStartsOn,
  ]);

  return (
    <CalendarContext.Provider
      value={{
        selectedDate,
        setSelectedDate,

        minimumDate: MIN_DATE_PICKER,
        maximumDate: MAX_DATE_PICKER,

        selectedView,
        setSelectedView,

        events: filteredEvents,
        isLoading,

        experienceFilters,
        setExperienceFilters,

        languageFilters,
        setLanguageFilters,

        locationFilters,
        setLocationFilters,

        eventStatusFilters,
        setEventStatusFilters,

        bookingStatusFilters,
        setBookingStatusFilters,

        groupByExperience,
        setGroupByExperience,

        guideFilters,
        setGuideFilters,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

const filterEventByEventState = (
  event: TEventWithTitle,
  filter: EventStatus
) => {
  switch (filter) {
    case 'active':
      return event.states.statusIsActive === true;

    case 'cancelled':
      return event.states.statusIsCancelled === true;

    case 'inactive':
      return event.states.statusIsInactive === true;

    default:
      return true;
  }
};

const filterEventByBookingStatus = (
  event: TEventWithTitle,
  filter: BookingStatus
) => {
  const totalSeats = event.slots.total;
  const bookedSeats = event.slots.booked ?? 0;

  switch (filter) {
    case 'fully-booked':
      return bookedSeats >= totalSeats;

    case 'booked':
      return bookedSeats > 0;

    case 'no-bookings':
      return bookedSeats === 0;

    default:
      return true;
  }
};

const filterArrayValues = (
  values: string[],
  filterValues: string[]
): boolean => {
  if (values.length === 0) return filterValues.includes(FILTER_NO_VALUES);

  return filterValues.some((value) => values.includes(value));
};
