import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from '../Api';
import { useAuthStore } from '../Store/useAuthStore';
import { LANGUAGES_PREFERENCES_KEY } from './use-apply-preferences';
import { SignupType, useProfile } from './useProfile';

export enum AuthStatus {
  AUTHENTICATED,
  SELECT_ORG,
  FAILED,
}
export interface GetInvitationResult {
  status: InvitationStatus;
}
export enum InvitationStatus {
  NOT_FOUND,
  EXPIRED,
  INVALID,
  VALID,
}
export interface SignInResult {
  status: AuthStatus;
  companies?: { id: string; name: string; logo?: string }[];
  message?: string;
}

export const useAuth = () => {
  const { i18n } = useTranslation();

  const { updateMe } = useProfile();
  const { setAuth, auth, clearAuth } = useAuthStore();
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth) {
      const { sub, email, companyId, scope } = jwtDecode(auth.access_token) as {
        sub: string;
        companyId?: string;
        email: string;
        scope: string;
      };

      auth.refresh_token && setRefreshToken(auth.refresh_token);

      Sentry.setUser({ id: sub, email });
      Sentry.setContext('holdbar', {
        companyId,
        scope,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [auth]);

  const signIn = async (
    username: string,
    password: string
  ): Promise<SignInResult> => {
    try {
      setIsLoading(true);

      const data = await api.signIn(username.toLowerCase(), password);
      const { org } = jwtDecode(data.access_token) as { org?: string };

      if (org) {
        setAuth(data);
        return Promise.resolve({ status: AuthStatus.AUTHENTICATED });
      } else {
        setRefreshToken(data.refresh_token);
        return Promise.resolve({
          status: AuthStatus.SELECT_ORG,
          companies: data.orgs,
        });
      }
    } catch (err: any) {
      clearAuth();
      return Promise.reject({
        status: AuthStatus.FAILED,
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      setIsLoading(true);
      await api.resetPassword(username.toLowerCase(), i18n.language);
      return Promise.resolve();
    } catch (err) {
      clearAuth();
      return Promise.reject();
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (password: string) => {
    try {
      setIsLoading(true);
      await api.resetPassword(password, i18n.language);
    } catch (err) {
      clearAuth();
      return Promise.reject();
    } finally {
      setIsLoading(false);
    }
  };

  const updateResetPassword = async (password: string, token: string) => {
    try {
      const { sub, email } = jwtDecode(token) as { sub: string; email: string };
      if (!sub || !email) {
        return Promise.reject();
      }
      try {
        setIsLoading(true);
        await api.updateResetPassword(email, password, sub, token);
        const data = await api.signIn(email.toLowerCase(), password);
        setAuth(data);
        return Promise.resolve();
      } catch (err) {
        clearAuth();
        return Promise.reject();
      } finally {
        setIsLoading(false);
      }
    } catch (err) {
      return Promise.reject();
    }
  };

  const switchToOrg = async (companyId: string): Promise<SignInResult> => {
    if (!refreshToken) {
      clearAuth();
      return Promise.reject({
        status: AuthStatus.FAILED,
        message: 'INVALID_REFRESH_TOKEN',
      });
    }
    const data = await api.refreshToken(refreshToken, companyId);
    setAuth(data);
    return Promise.resolve({ status: AuthStatus.AUTHENTICATED });
  };

  const acceptInvitation = async (
    email: string,
    invitationId: string,
    password?: string,
    name?: string
  ) => {
    try {
      setIsLoading(true);
      const data = await api.acceptInvitation(
        email.toLowerCase(),
        invitationId,
        password
      );
      setAuth(data);
      const jwtData = jwtDecode(data.access_token) as { sub: string };
      await updateMe.mutateAsync({
        email,
        id: jwtData.sub,
        name,
        metadata: {
          preferences: {
            [LANGUAGES_PREFERENCES_KEY]: {
              defaultLanguage: i18n.language,
            },
          },
          signupType: SignupType.InviteSignup,
        },
      });
      return Promise.resolve({ profileId: jwtData.sub });
    } catch (err: any) {
      clearAuth();
      return Promise.reject({ message: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  const createAccount = async (
    email: string,
    password: string,
    signupType: SignupType
  ) => {
    try {
      setIsLoading(true);
      const data = await api.createAccount(email.toLowerCase(), password);
      setAuth(data);
      const jwtData = jwtDecode(data.access_token) as { sub: string };
      await updateMe.mutateAsync({
        email,
        id: jwtData.sub,
        metadata: {
          preferences: {
            [LANGUAGES_PREFERENCES_KEY]: {
              defaultLanguage: i18n.language,
            },
          },
          signupType,
        },
      });
      return Promise.resolve(jwtData.sub);
    } catch (err: any) {
      clearAuth();
      return Promise.reject({ message: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  const addAccountToCompany = async (userId: string, companyId: string) => {
    try {
      setIsLoading(true);
      const data = await api.addAccountToPreviewCompany(
        userId,
        companyId,
        'admin'
      );
      setAuth(data);
      const jwtData = jwtDecode(data.access_token) as { sub: string };
      return jwtData.sub;
    } catch (err: any) {
      clearAuth();
      return Promise.reject({ message: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  const getEmailFromToken = (token: string) => {
    try {
      const { email } = jwtDecode(token) as { email: string };
      return email;
    } catch (err) {
      console.log('Not a valid email');
    }
  };

  const getInvitation = async (id: string) => {
    try {
      return await api.getInvitationData(id);
    } catch (err: any) {
      if (err.response?.status === 404) {
        return Promise.reject({
          status: InvitationStatus.NOT_FOUND,
        } as GetInvitationResult);
      }
      if (err.response?.status === 410) {
        return Promise.reject({
          status: InvitationStatus.EXPIRED,
        } as GetInvitationResult);
      }
      return Promise.reject({
        status: InvitationStatus.INVALID,
      } as GetInvitationResult);
    }
  };

  const userScopes = useMemo(() => {
    if (!auth?.access_token) {
      return [];
    }
    const { scope } = jwtDecode(auth?.access_token) as { scope: string };
    return scope.split(' ');
  }, [auth?.access_token]);

  const canAccess = useCallback(
    (scope: string) => {
      return userScopes.some((s) => s === scope || s === `${scope}.write`);
    },
    [userScopes]
  );

  const isAuthenticated = useMemo(() => Boolean(auth), [auth]);

  return {
    isAuthenticated,
    isLoading,
    signIn,
    forgotPassword,
    updateResetPassword,
    createAccount,
    addAccountToCompany,
    acceptInvitation,
    resetPassword,
    getEmailFromToken,
    getInvitation,
    switchToOrg,
    userScopes,
    canAccess,
  };
};
