import { useQuery } from '@tanstack/react-query';

import { getResourceReservations } from '../../../Api/resource-management';
import { useProfile } from '../../../Hooks/useProfile';

export const resourceReservationsQuery = (
  eventId: string,
  bookingId?: string
) => ({
  queryKey: ['resource-management', 'reservations', eventId, bookingId],
  queryFn: () => getResourceReservations({ eventId, bookingId }),
});

export const useResourceReservations = (
  eventId: string,
  bookingId?: string
) => {
  const { company } = useProfile();

  return useQuery({
    ...resourceReservationsQuery(eventId, bookingId),
    enabled: !!company.data?.id && !!eventId,
  });
};
