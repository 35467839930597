import styled from '@emotion/styled';
import { TuneRounded } from '@mui/icons-material';
import { AddRounded } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import {
  Experience,
  ExperienceSortOption,
} from '@understory-io/experiences-types';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import randomBytes from 'randombytes';
import { Suspense, useCallback, useState } from 'react';
import {
  Await,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router';
import { toast } from 'react-toastify';

import { saveExperienceDraft } from '../../../Api';
import {
  companyProfileQuery,
  experiencesQuery,
  locationsQuery,
  userInfoQuery,
  userProfileQuery,
} from '../../../Api/queries';
import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { ContextMenu } from '../../../Components/context-menu/context-menu';
import { EmptyScreen } from '../../../Components/EmptyScreen/EmptyScreen';
import { Page } from '../../../Components/Page/Page';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { isStorefrontLanguage } from '../../../i18n/config';
import { trackEventFlowOpened } from '../../../tracking/events/flow/trackEventFlowOpened';
import routes from '../../../Utils/routes';
import ExperienceCard from './experience-card';
import { ExperienceDraftSection } from './experience-draft-section/experience-draft-section';
import { ExperienceDraftSectionPublishSuccessDialog } from './experience-draft-section/experience-draft-section-publish-success-dialog';
import { DesktopCard, MobileCard } from './experience-item';

// Alphabetical order is used as fallback in the backend so we that as the default setting
export const DEFAULT_EXPERIENCE_SORTING: ExperienceSortOption = 'alphanumeric';

const SORT_OPTIONS: ExperienceSortOption[] = [
  'alphanumeric',
  'createdAtDesc',
  'createdAtAsc',
  'sortOrder',
];

export const isSortOption = (value: string): value is ExperienceSortOption =>
  SORT_OPTIONS.includes(value as ExperienceSortOption);

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader =
  (client: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const [userProfile, companyProfile, userAuth] = await Promise.all([
      client.fetchQuery(userProfileQuery()),
      client.fetchQuery(companyProfileQuery()),
      client.fetchQuery(userInfoQuery()),
    ]);

    const url = new URL(request.url);
    const searchParams = url.searchParams;
    const sortByParam = searchParams.get('sortBy');
    // Attempt to get the sort by from query, otherwise use the user profile or fallback
    const sortBy =
      sortByParam && isSortOption(sortByParam)
        ? sortByParam
        : userProfile?.metadata?.preferences?.experiences?.sortBy ??
          DEFAULT_EXPERIENCE_SORTING;

    // When selecting Storefront order, we use what is saved on the company profile
    const appliedSortOrder =
      sortBy === 'sortOrder'
        ? companyProfile.sortExperiencesBy ?? DEFAULT_EXPERIENCE_SORTING
        : sortBy;

    const currentLanguage =
      userProfile?.metadata?.preferences?.settings?.defaultLanguage;

    const experiencesPromise = client.fetchQuery(
      experiencesQuery({
        sortExperiencesBy: appliedSortOrder,
        defaultLanguage: companyProfile.defaultLanguage,
        language:
          currentLanguage && isStorefrontLanguage(currentLanguage)
            ? currentLanguage
            : undefined,
        // If the user cannot edit experiences, there is no need to include drafts
        includeDrafts: !!userAuth.scope?.includes('experience.write'),
      })
    );

    // We need locations when creating a new experience
    // but the call can be deferred as we only need it
    // when creating a new experience
    const locationsPromise = client.fetchQuery(
      locationsQuery(companyProfile.id)
    );

    return {
      sortBy,
      locationsPromise,
      experiencesPromise,
    };
  };

const EXPERIENCE_PREFERENCES_KEY = 'experiences';

export default function ExperiencesPage() {
  const { sortBy, experiencesPromise, locationsPromise } =
    useLoaderData<LoaderData>();

  const navigate = useNavigate();
  const { isMd } = useResponsive();
  const { t } = useTranslate('experience');
  const { company, updatePreferences } = useProfile();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCreatingExperience, setIsCreatingExperience] = useState(false);

  const handleChange = useCallback(
    (newValue: ExperienceSortOption) => {
      setIsPopoverOpen(false);
      updatePreferences(
        EXPERIENCE_PREFERENCES_KEY,
        (previous: Record<string, string> | undefined) => {
          return {
            ...previous,
            sortBy: newValue,
          };
        }
      );
      const newParams = new URLSearchParams(location.search);
      newParams.set('sortBy', newValue);
      navigate(`${routes.experience.overview}?${newParams.toString()}`, {
        replace: true,
      });
    },
    [navigate, updatePreferences]
  );

  const handleCreateExperience = useCallback(async () => {
    try {
      setIsCreatingExperience(true);
      const locations = await locationsPromise;
      const newExperienceId = randomBytes(16).toString('hex');

      await saveExperienceDraft(newExperienceId, {
        locationIds: locations.length === 1 ? [locations[0].locationId] : [],
        seats: {
          type: 'single',
          seatCount: 10,
        },
        price: {
          variants: [
            {
              id: randomBytes(16).toString('hex'),
              name: {
                [company.data?.defaultLanguage ?? 'en']: t(
                  'free',
                  'utils.statusOptions'
                ),
              },
              priceBreakdown: {
                vatInclusivePriceCents: 0,
                vatSetting: {
                  vatCategory:
                    company.data?.vatCompliance.vatRegistrations[0]
                      ?.defaultVatCategory ?? 'standard',
                },
              },
            },
          ],
        },
      });

      navigate(routes.experience.details(newExperienceId).edit.index, {
        state: {
          newExperience: true,
        },
      });
    } catch (error) {
      setIsCreatingExperience(false);
      toast.error(t('utils.errors.generic'));
    }
  }, [locationsPromise, navigate, t, company.data]);

  return (
    <Page
      sx={{
        maxWidth: '1400px',
        pr: { xs: 0, md: 2 },
        pb: { xs: 0, md: 10 },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Stack>
          <Text
            fontSize={'h5'}
            {...(isMd ? { fontSize: 'large', variant: 'medium' } : undefined)}
          >
            {t('title')}
          </Text>
          {isMd && (
            <Suspense>
              <Await resolve={experiencesPromise}>
                {(experiences) => (
                  <Text
                    fontSize="xsmall"
                    color={lightTheme.palette.neutral.n300}
                  >
                    {t('description', { count: experiences.length })}
                  </Text>
                )}
              </Await>
            </Suspense>
          )}
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          {isMd && (
            <ContextMenu
              open={isPopoverOpen}
              onOpenChange={setIsPopoverOpen}
              options={SORT_OPTIONS.map((option) => ({
                label: t(`sort.${option}`),
                onClick: () => handleChange(option),
              }))}
              element={
                <StyledIconButton variant="text" size="medium">
                  <TuneRounded
                    fontSize="small"
                    sx={{
                      color: 'black',
                      display: 'flex',
                    }}
                  />
                </StyledIconButton>
              }
            />
          )}
          <ShowForScope scopes={[`experience.write`]}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Suspense>
                <Await resolve={experiencesPromise}>
                  {(experiences) => (
                    <>
                      {!!experiences.length &&
                        !isMd &&
                        !(
                          experiences.length === 1 &&
                          experiences[0].status === 'draft'
                        ) && (
                          <Button
                            onClick={() => {
                              navigate(routes.event.create, {
                                replace: true,
                                state: {
                                  returnUrl: routes.experience.overview,
                                },
                              });
                              trackEventFlowOpened('/experiences', 'create');
                            }}
                            variant="secondary"
                            size="medium"
                            leftIcon={<StyledAddIcon />}
                          >
                            {t('createEventButtonLabel')}
                          </Button>
                        )}
                    </>
                  )}
                </Await>
              </Suspense>
              {isMd ? (
                <IconButton
                  disabled={isCreatingExperience}
                  onClick={handleCreateExperience}
                  sx={{
                    backgroundColor: lightTheme.palette.primary.p300,
                    color: lightTheme.palette.contrast.black,
                  }}
                  data-intercom-target="create-button-action"
                >
                  {isCreatingExperience ? (
                    <CircularProgress size={16} />
                  ) : (
                    <AddRounded fontSize="small" />
                  )}
                </IconButton>
              ) : (
                <Button
                  variant="primary"
                  onClick={handleCreateExperience}
                  size="medium"
                  data-intercom-target="create-button-action"
                  color="primary"
                  leftIcon={<AddRounded fontSize="small" />}
                  loading={isCreatingExperience}
                >
                  {t('buttonLabel')}
                </Button>
              )}
            </Stack>
          </ShowForScope>
        </Stack>
      </Stack>
      <Box sx={{ marginTop: 3 }}>
        <Stack sx={{ gap: 2 }}>
          {!isMd && (
            <Select
              size="small"
              labelId="sortBy"
              sx={{
                minWidth: 220,
                height: 40,
                alignSelf: 'flex-start',
              }}
              MenuProps={{
                autoFocus: false,
              }}
              value={sortBy}
              renderValue={(value) => (
                <Text fontSize="small">
                  <Text
                    fontSize="small"
                    color={lightTheme.palette.neutral.n300}
                  >
                    {t('sort.label')}:
                  </Text>{' '}
                  {t(`sort.${value}`)}
                </Text>
              )}
              onChange={(e) =>
                isSortOption(e.target.value) && handleChange(e.target.value)
              }
            >
              {SORT_OPTIONS.map((key) => (
                <MenuItem key={key} value={key}>
                  {t(`sort.${key}`)}
                </MenuItem>
              ))}
            </Select>
          )}
          <Suspense fallback={<ExperienceListLoading />}>
            <Await resolve={experiencesPromise}>
              {(experiences) => <ExperienceList experiences={experiences} />}
            </Await>
          </Suspense>
        </Stack>
      </Box>
    </Page>
  );
}
const StyledAddIcon = () => <AddRounded fontSize="small" sx={{ mr: 1 }} />;

const ExperienceList = ({ experiences }: { experiences: Experience[] }) => {
  const { t } = useTranslate('experience');
  const [publishedExperienceId, setPublishedExperienceId] = useState<
    string | null
  >(null);

  if (!experiences?.length)
    return (
      <EmptyScreen
        imageSrc="/empty-state-nature.svg"
        title={t('emptyState.title')}
        description={t('emptyState.description')}
      />
    );

  // Show draft experience section if there's only one experience and it's a draft
  if (experiences.length === 1 && experiences[0].status === 'draft') {
    return (
      <ExperienceDraftSection
        experience={experiences[0]}
        onPublishSuccess={(experienceId) =>
          setPublishedExperienceId(experienceId)
        }
      />
    );
  }

  return (
    <>
      <ExperienceDraftSectionPublishSuccessDialog
        open={!!publishedExperienceId}
        experienceId={publishedExperienceId!}
        onClose={() => setPublishedExperienceId(null)}
      />
      <Stack flexDirection="column" gap={2}>
        {experiences.map((experience) => (
          <ExperienceCard key={experience.id} experience={experience} />
        ))}
      </Stack>
    </>
  );
};

const ExperienceListLoading = () => {
  const { isMd } = useResponsive();

  if (isMd) {
    return (
      <ExperienceGrid>
        {Array(10)
          .fill(undefined)
          .map((_, index) => (
            <MobileCard key={index}>
              <Skeleton height={152} width={108} />
              <Stack padding={2} paddingBottom={5} flexGrow={1}>
                <Skeleton
                  height={17}
                  width={50}
                  sx={{ alignSelf: 'flex-end', marginBottom: '4px' }}
                />
                <Skeleton height={21} width={100} />
                <Skeleton height={21} width={100} />
                <Skeleton height={21} width={100} />
              </Stack>
            </MobileCard>
          ))}
      </ExperienceGrid>
    );
  }

  return (
    <Stack gap={1}>
      {Array(10)
        .fill(undefined)
        .map((_, index) => (
          <DesktopCard key={index}>
            <Skeleton width={80} height={67} />
            <Skeleton
              height={19.2}
              width={100}
              sx={{ gridColumn: 'span 3', justifySelf: 'flex-start' }}
            />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton
              height={19.2}
              width={100}
              sx={{ gridColumn: 'span 2', justifySelf: 'flex-end' }}
            />
          </DesktopCard>
        ))}
    </Stack>
  );
};

const ExperienceGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
  gap: '16px',
});

const StyledIconButton = styled(Button)({
  padding: 8,
  aspectRatio: 1,
});
