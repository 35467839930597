import React, { useMemo } from 'react';

import { DialogAction } from '../../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { PublishErrors } from '../edit-experience';
import { trackExperienceEditPagePublishedErrors } from '../utils/tracking-helper';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';

interface ErrorReturnOptions {
  title: string;
  description: string;
  secondaryAction?: DialogAction;
  primaryAction?: DialogAction;
}

interface ErrorContextProps {
  errors: PublishErrors | undefined;
  customError: CustomError | undefined;
  experienceId: string;
  closeDialog: () => void;
  handleMissingTextTranslation?: () => void;
  render: (options: ErrorReturnOptions) => React.ReactNode;
}

export type CustomError = {
  title: string;
  description: string;
  secondaryAction?: DialogAction;
  primaryAction?: DialogAction;
};

export function ErrorContext({
  errors,
  customError,
  experienceId,
  render,
  handleMissingTextTranslation,
  closeDialog,
}: ErrorContextProps) {
  const navigateWithParams = useNavigateWithParams();
  const { t } = useTranslate('experience.edit.dialog.errors');

  const options: ErrorReturnOptions = useMemo(() => {
    const generalTypes: string[] =
      errors?.general?.map((item) => item.key) || [];

    const totalErrorCount = Object.values(errors ?? {}).reduce(
      (sum, value) => sum + value.length,
      0
    );

    const hasGeneralError = generalTypes.length > 0;
    const hasTranslationError = !errors?.general && totalErrorCount > 0;
    const hasLocationError = generalTypes.includes('locationIds');
    const hasTicketError = generalTypes.includes('price');

    const optionsObj = {
      totalErrorCount,
      generalTypes,
      hasTranslationError,
      hasGeneralError,
      hasLocationError,
      hasTicketError,
    };

    const errorTracking = (type: string) => {
      trackExperienceEditPagePublishedErrors({
        error_type: type,
        experience_id: experienceId,
      });
    };

    if (customError) {
      return {
        title: customError.title,
        description: customError.description,
        secondaryAction: customError.secondaryAction,
        primaryAction: customError.primaryAction,
      };
    }

    if (hasTranslationError && !hasGeneralError) {
      errorTracking('translation');
      return {
        title: t('translation.title'),
        description: t('translation.description'),
        options: optionsObj,
        secondaryAction: {
          label: t('tickets.fixManually'),
          variant: 'secondary',
          onClick: closeDialog,
        },
        primaryAction: {
          label: t('translation.autoTranslate'),
          variant: 'primary',
          onClick: () => {
            handleMissingTextTranslation?.();
            closeDialog();
          },
        },
      };
    }

    if (generalTypes?.length === 1 && totalErrorCount === 1) {
      if (generalTypes[0] === 'locationIds') {
        errorTracking('location');
        return {
          title: t('location.title'),
          description: t('location.description'),
          primaryAction: {
            label: t('buttonLabel'),
            variant: 'primary',
            onClick: closeDialog,
          },
        };
      }

      if (generalTypes[0] === 'price') {
        errorTracking('ticket');
        return {
          title: t('tickets.title'),
          description: t('tickets.description'),
          secondaryAction: {
            label: t('buttonLabel'),
            variant: 'secondary',
            onClick: () => {
              closeDialog();
            },
          },
          primaryAction: {
            label: t('tickets.fixManually'),
            variant: 'primary',
            onClick: () => {
              navigateWithParams(
                routes.experience.details(experienceId).edit.tickets.index
              );
              closeDialog();
            },
          },
        };
      }
    }

    errorTracking('generic');
    return {
      title: t('generic.title'),
      description: t('generic.description'),
      primaryAction: {
        label: t('buttonLabel'),
        variant: 'primary',
        onClick: closeDialog,
      },
    };
  }, [
    errors,
    customError,
    t,
    closeDialog,
    experienceId,
    handleMissingTextTranslation,
    navigateWithParams,
  ]);

  return <>{render(options)}</>;
}
