import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import useResponsive from '../../Hooks/layout/useResponsive';
import { MenuItem as MenuItemType } from '../../Hooks/useMenu';
import { useMenuNotification } from '../menu-notification/menu-notification-context';
import { MenuNotificationIndicator } from '../menu-notification/menu-notification-indicator';
import { Tooltip } from '../tooltip/tooltip';

type MenuItemProps = {
  item: MenuItemType;
  locationKey: string | undefined;
  title?: string;
  endNode?: ReactNode;
  onClick?: () => void;
};

export const MenuItem = ({
  item,
  locationKey,
  title,
  endNode,
  onClick,
}: MenuItemProps) => {
  const { t } = useTranslation();
  const { notifications, clearNotification } = useMenuNotification();
  const { isLg } = useResponsive();
  const [showTooltip, setShowTooltip] = useState(true);
  const timerRef = useRef<number>();

  const notification = notifications.find((n) => n.menuKey === item.key);

  const startTooltipTimer = useCallback(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (notification?.message) {
      startTooltipTimer();
    }
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, [notification?.message, startTooltipTimer]);

  const handleClick = useCallback(() => {
    if (notification) {
      clearNotification(item.key);
    }
    onClick?.();
  }, [notification, onClick, clearNotification, item.key]);

  const menuText = (
    <ListItemText
      primaryTypographyProps={{
        fontSize: lightTheme.typography.small.normal.fontSize,
      }}
      primary={title ?? t(`utils.primaryNavigation.${item.key}`)}
    />
  );

  return (
    <Link to={item.path} onClick={handleClick}>
      <ListItem
        data-intercom-target={`navbar-${item.path}`}
        sx={{
          p: 0,
          borderRadius: 1,
          color: lightTheme.palette.neutral.n500,
        }}
      >
        <ListItemButton
          sx={{
            '&:active': {
              color: `${lightTheme.palette.action.a500} !important`,
              backgroundColor: lightTheme.palette.neutral.n100 + ' !important',
              '& .MuiSvgIcon-root': {
                color: `${lightTheme.palette.action.a500} !important`,
              },
            },
            py: 1.1,
            px: 2,
            borderRadius: 1,
            color: lightTheme.palette.neutral.n500,
            border: 'none',
            '&.Mui-selected': {
              borderRadius: 1,
              color: lightTheme.palette.action.a500,
              backgroundColor: lightTheme.palette.neutral.n100,
              '& .MuiSvgIcon-root': {
                color: lightTheme.palette.action.a500,
              },
            },
            '&:hover': {
              backgroundColor: lightTheme.palette.neutral.n100,
              '&.Mui-selected': {
                backgroundColor: lightTheme.palette.neutral.n100,
              },
              '&:not(.Mui-selected)': {
                color: lightTheme.palette.neutral.n500,
                backgroundColor: lightTheme.palette.neutral.n100,
              },
            },
          }}
          selected={
            locationKey === item.path ||
            item?.matches?.includes(locationKey as string)
          }
        >
          <ListItemIcon
            sx={{
              position: 'relative',
              minWidth: '30px',
              color: lightTheme.palette.neutral.n500,
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                color: lightTheme.palette.neutral.n500,
              },
            }}
          >
            {item.icon}
            {notification && <MenuNotificationIndicator />}
          </ListItemIcon>
          {notification?.message && !isLg ? (
            <Tooltip
              title={notification.message}
              placement="bottom"
              open={showTooltip}
              arrow
              slotProps={{
                tooltip: {
                  sx: {
                    '& .MuiTooltip-arrow': {
                      left: '-15% !important',
                      color: lightTheme.palette.action.a400,
                    },
                    backgroundColor: lightTheme.palette.action.a400,
                    p: 1.5,
                    fontSize: lightTheme.typography.small.normal.fontSize,
                  },
                },
              }}
            >
              {menuText}
            </Tooltip>
          ) : (
            menuText
          )}
          {endNode}
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
