import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useController } from 'react-hook-form';

import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useFeatureSetting } from '../context/feature_setting_context';

export type TFeatureInput = TextFieldProps & {
  name: string;
  as?: FC<TextFieldProps>;
};

const FeatureInput = ({
  name,
  as,
  defaultValue: propsDefaultValue,
  ...props
}: TFeatureInput) => {
  const { control, save, feature } = useFeatureSetting();

  const { t } = useTranslate(`settings.features.${feature}.${name}`);

  const {
    company: { data: company },
  } = useProfile();

  const isFeatureEnabled = company?.features?.[feature]?.enabled;
  const required = isFeatureEnabled && props.required;

  const featureParams = company?.features?.[feature]?.params as Record<
    string,
    string | number
  >;
  const defaultValue = propsDefaultValue || featureParams?.[name];

  const { field, formState } = useController({
    name: `params.${name}`,
    defaultValue: defaultValue ?? '',
    rules: { required },
    control,
  });

  const Element = as ?? TextField;

  return (
    <Element
      type="input"
      sx={{ minWidth: { xs: '100%', md: 480 } }}
      label={t('label')}
      placeholder={t('placeholder')}
      error={Boolean((formState as any)?.errors?.params?.[name])}
      {...field}
      onBlur={() => save()}
      {...props}
      required={required}
    />
  );
};

export default FeatureInput;
