import 'react-toastify/dist/ReactToastify.min.css';
import './i18n/config';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { ampli } from './Ampli';
import { MenuNotificationProvider } from './Components/menu-notification/menu-notification-context';
import { useReturnUrls } from './features/auth/use-return-urls';
import LocaleContextProvider from './Hooks/locales/use-locale.context';
import { useApplyPreferences } from './Hooks/use-apply-preferences';
import { useIntercom } from './Hooks/use-intercom';
import { useAmplitude } from './Hooks/useAmplitude';
import { useGlobalShortcuts } from './Hooks/useGlobalShortcuts';
import { useToastStyling } from './Hooks/useToastStyling';
import { useUsers } from './Hooks/useUsers';
import { useLaunchDarklyIdentify } from './launch-darkly';
import { createRouter } from './router';
import { environment } from './Utils/environment';

ampli.load({
  environment: environment,
});
ampli.client?.add(
  sessionReplayPlugin({
    sampleRate: 1,
  })
);

const router = createRouter();

export default function App() {
  useReturnUrls();
  useGlobalShortcuts();
  useToastStyling();

  // Plugins
  useIntercom();
  useAmplitude();
  useLaunchDarklyIdentify();

  // Prefetch users
  useUsers();

  useApplyPreferences();

  return (
    <LocaleContextProvider>
      <MenuNotificationProvider>
        <ToastContainer />
        <RouterProvider router={router} />
      </MenuNotificationProvider>
    </LocaleContextProvider>
  );
}
