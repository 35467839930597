import { Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledPhoneInput from '../../../Components/PhoneInput/ControlledPhoneInput';
import { ProfilePicture } from './profile-picture';
import { ProfileFormData } from './profile-settings';

export const ProfileSettingsInputs = ({
  userId,
  email,
}: {
  userId: string;
  email: string;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProfileFormData>();

  return (
    <Stack
      sx={{
        flexDirection: { sm: 'row' },
        gap: { xs: 2 },
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Stack sx={{ gap: 3, maxWidth: { sm: '60%' }, flexGrow: 1 }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t('settings.profile.fullName')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { maxHeight: 48 } }}
            />
          )}
        />
        {/* This is only for display - user cannot update email */}
        <TextField
          value={email}
          fullWidth
          label={t('settings.profile.email')}
          InputLabelProps={{ shrink: true }}
          InputProps={{ sx: { maxHeight: 48 } }}
          disabled
        />
        <ControlledPhoneInput
          name="phone"
          control={control}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      </Stack>
      <Stack
        sx={{
          maxWidth: 165,
          alignItems: 'center',
          flexGrow: 1,
          marginX: { xs: 'auto', sm: 'unset' },
        }}
      >
        <ProfilePicture userId={userId} />
      </Stack>
    </Stack>
  );
};
