import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { ResourceRule as ResourceRuleType } from '@understory-io/experiences-types';
import { Value } from '@understory-io/utils-types/typebox';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useNavigation,
  useRevalidator,
  useRouteLoaderData,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';
import { InferType } from 'yup';

import { ampli } from '../../../../../Ampli';
import { saveExperienceDraft } from '../../../../../Api/Experience';
import {
  draftExperienceQuery,
  locationsQuery,
  resourceTypesQuery,
  userInfoQuery,
} from '../../../../../Api/queries';
import { NoteBox } from '../../../../../Components/note-box';
import { useTicketsAndAddons } from '../../../../../Hooks/use-tickets-and-addons';
import { useFireOnce } from '../../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import routes from '../../../../../Utils/routes';
import { getAllTicketsFromVariants } from '../../../../../Utils/ticket';
import { resourceRuleSchema } from '../../../schemas/resourceRuleSchema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import EditExperienceErrorDialog from '../../components/dialogs/edit-experience-error-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { useNavigateWithParams } from '../../utils/use-navigate-with-params';
import { AllocationTypeSelect } from './assignment-type-select';
import { ResourceTypeSelectLoader } from './resource-type-select/resource-type-select-loader';

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { ruleId } = params;

    const { companyId } = await client.fetchQuery(userInfoQuery());
    if (!companyId) {
      throw new Response('Unauthorized', { status: 401 });
    }

    const resourceTypesPromise = client.fetchQuery(resourceTypesQuery());
    const locationsPromise = client.fetchQuery(locationsQuery(companyId));

    const resourceTypesAndLocationsPromise = Promise.all([
      resourceTypesPromise,
      locationsPromise,
    ]);

    return { ruleId, resourceTypesAndLocationsPromise };
  };

export function ResourceRuleForm() {
  const { ruleId, resourceTypesAndLocationsPromise } =
    useLoaderData<LoaderData>();
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.resourceRule');
  const fireOnce = useFireOnce();
  const navigateWithParams = useNavigateWithParams();
  const { state } = useNavigation();
  const revalidator = useRevalidator();

  const resourceRule = experience.resourceRules?.rules?.find(
    (rule) => rule.ruleId === ruleId
  ) ?? {
    ruleId: randomBytes(16).toString('hex'),
    allocationType: 'event',
    resourceTypeIdByLocation: {},
  };

  const tickets = useTicketsAndAddons(experience);

  const isEditFlow = !!ruleId;

  const schema = resourceRuleSchema({
    locationIds: experience.locationIds,
    tickets,
  });

  const formMethods = useForm<InferType<typeof schema>>({
    defaultValues: resourceRule,
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    fireOnce(() => {
      if (isEditFlow) {
        ampli.experienceEditFlowEditResourceOpened();
        return;
      }
      ampli.experienceEditFlowAddResourceOpened();
    });

    if (!isEditFlow) return;

    formMethods.trigger();
  }, [formMethods, isEditFlow, fireOnce]);

  useEffect(() => {
    if (state === 'idle') {
      revalidator.revalidate();
    }
  }, [state, revalidator]);

  const submit = useSubmit();

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: '',
      encType: 'application/json',
    });
    ampli.experienceEditFlowResourceFormCompleted({
      assignment: data.allocationType,
      resource_types: Object.values(data.resourceTypeIdByLocation),
    });
  };

  const closeDialog = () => {
    navigateWithParams(routes.experience.details(experience.id).edit.index, {
      replace: true,
    });
  };

  if (!experience.locationIds?.length) {
    return (
      <EditExperienceErrorDialog
        experienceId={experience.id}
        closeDialog={closeDialog}
        customError={{
          title: t('errorTitle'),
          description: t('errorDescription'),
          primaryAction: {
            label: t('buttonLabel', 'experience.edit.dialog.errors'),
            variant: 'primary',
            onClick: closeDialog,
          },
        }}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <EditExperienceDialog
        title={t('title')}
        description={t('description')}
        shouldClose={actionData?.shouldClose}
        experienceId={experience.id}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        type="resources"
        noValidate
      >
        <input hidden {...formMethods.register('ruleId')} />
        <Stack sx={{ gap: 3, width: '100%', alignItems: 'start' }}>
          {experience.locationIds?.map((locationId) => (
            <ResourceTypeSelectLoader
              key={locationId}
              resourceTypesAndLocationsPromise={
                resourceTypesAndLocationsPromise
              }
              locationId={locationId}
            />
          ))}
          <AllocationTypeSelect experience={experience} />
          <NoteBox note={t('availabilityInfo')} />
        </Stack>
      </EditExperienceDialog>
    </FormProvider>
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { id } = params;

    try {
      if (!id) {
        throw new Response('Invalid id', { status: 400 });
      }

      const experience = await client.fetchQuery(draftExperienceQuery(id));

      const unknownPayload = await request.json();

      const payload = Value.Clean(ResourceRuleType, unknownPayload);

      if (!Value.Check(ResourceRuleType, payload)) {
        throw new Response('Incorrect payload', { status: 400 });
      }

      resourceRuleSchema({
        locationIds: experience.locationIds,
        tickets: getAllTicketsFromVariants(experience.price.variants),
      }).validateSync(payload);

      const rules = (experience.resourceRules?.rules ?? []).map((rule) => {
        if (rule.ruleId === payload.ruleId) {
          return payload;
        }

        return rule;
      });

      if (!rules.some((rule) => rule.ruleId === payload.ruleId)) {
        rules.push(payload);
      }

      const experienceToSave = {
        ...experience,
        resourceRules: {
          version: 1,
          rules,
        },
      } as const;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
