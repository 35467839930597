import { Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { format } from 'date-fns';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useDashboardPageContext } from '../../use-dashboard-page-context';

export const DashboardGraphsLoaderFooter = () => {
  const { t } = useTranslate('dashboard');

  const { isMd: isMobile } = useResponsive();

  const { metadata } = useDashboardPageContext();

  return (
    <Stack
      gap={2}
      direction={isMobile ? 'column' : 'row'}
      justifyContent={metadata ? 'space-between' : 'flex-end'}
      sx={{ userSelect: 'none' }}
    >
      {metadata && (
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={1}
          divider={!isMobile && <Divider sx={{ borderWidth: '0.5px' }} />}
        >
          <Text fontSize={'xsmall'} color={lightTheme.palette.neutral.n300}>
            {t('metadata.lastUpdated')}:{' '}
            {format(new Date(metadata.dataUpdateTimeUtc), 'PPp')}
          </Text>
          <Text fontSize={'xsmall'} color={lightTheme.palette.neutral.n300}>
            {t('metadata.nextUpdate')}:{' '}
            {format(new Date(metadata.nextDataUpdateTimeUtc), 'PPp')}
          </Text>
        </Stack>
      )}
      <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
        {t('statistics.cards.tooltipDisclaimer')}
      </Text>
    </Stack>
  );
};
