import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';

import { ampli } from '../../../Ampli';
import { SectionHeadline } from '../../../Components/SectionHeadline/SectionHeadline';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { ActiveIntegrationsList } from './ui/active-integrations-list/active-integrations-list';
import { AvailableIntegrationsList } from './ui/available-integrations-list/available-integrations-list';
import { InternalIntegrationKeys } from './ui/internal-integration-keys/internal-integration-keys';

export const SettingsIntegrationsSection = () => {
  const { t } = useTranslate('settings.integrations');

  const fireOnce = useFireOnce();
  useEffect(() => fireOnce(() => ampli.integrationsPageViewed()), [fireOnce]);

  return (
    <Stack gap={3}>
      <SectionHeadline title={t('title')} description={t('description')} />
      <AvailableIntegrationsList />
      <Box mt={2}>
        <ActiveIntegrationsList />
      </Box>
      <InternalIntegrationKeys />
    </Stack>
  );
};
