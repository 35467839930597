import NiceModal from '@ebay/nice-modal-react';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CountrySelect } from '../../../Components/CountrySelect/CountrySelect';
import { AddressSearchField } from '../../locations/location-search/location-search-input';
import { AddressSearchResult } from '../../locations/location-search/use-location-search';
import { BillingFormData } from './billing-schema';
import { LegalCompanyNameInfoDialog } from './billing-section-legal-company-name-info-dialog';

export const BasicBillingCompanyInfo = () => {
  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<BillingFormData>();

  const handleSelect = (result: AddressSearchResult) => {
    setValue('legalLocation.address', result.street);
    setValue('legalLocation.city', result.city);
    setValue('legalLocation.countryCode', result.country);
    setValue('legalLocation.zipCode', result.postCode);
    setValue('legalLocation.state', result.state);
  };

  const onInfoClick = () => {
    NiceModal.show(LegalCompanyNameInfoDialog);
  };

  return (
    <>
      <Stack>
        <InfoOutlined
          sx={{
            color: lightTheme.palette.neutral.n300,
            width: '16px',
            alignSelf: 'flex-end',
            cursor: 'pointer',
          }}
          onClick={onInfoClick}
        />
        <TextField
          {...register('legalName')}
          fullWidth
          label={t('utils.generic.legalCompanyName')}
          error={!!errors.legalName?.message}
          helperText={t(errors.legalName?.message ?? '')}
          InputLabelProps={{ shrink: true }}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      </Stack>
      <Controller
        name="legalLocation.address"
        control={control}
        render={({ field }) => (
          <AddressSearchField
            {...field}
            fullWidth
            label={t('utils.generic.address')}
            InputLabelProps={{ shrink: true }}
            error={!!errors?.legalLocation?.address?.message}
            helperText={t(errors.legalLocation?.address?.message ?? '')}
            InputProps={{ sx: { maxHeight: 48 } }}
            onSelectResult={handleSelect}
          />
        )}
      />
      <Stack direction="row" gap={2}>
        <TextField
          {...register('legalLocation.zipCode')}
          sx={{ width: '50%' }}
          size="medium"
          type="text"
          label={t('utils.generic.zipCode')}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.legalLocation?.zipCode?.message}
          helperText={t(errors?.legalLocation?.zipCode?.message ?? '')}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
        <TextField
          {...register('legalLocation.city')}
          size="medium"
          fullWidth
          type="text"
          label={t('utils.generic.city')}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.legalLocation?.city?.message}
          helperText={t(errors?.legalLocation?.city?.message ?? '')}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <TextField
          {...register('legalLocation.state')}
          sx={{ width: '50%' }}
          size="medium"
          type="text"
          label={t('utils.generic.state')}
          InputLabelProps={{ shrink: true }}
          error={!!errors.legalLocation?.state?.message}
          helperText={t(errors?.legalLocation?.state?.message ?? '')}
          InputProps={{ sx: { maxHeight: 48 } }}
        />
        <Controller
          name="legalLocation.countryCode"
          control={control}
          render={({ field }) => (
            <CountrySelect
              label={t('utils.generic.country')}
              value={field.value}
              onChange={field.onChange}
              error={!!errors.legalLocation?.countryCode?.message}
              sx={{ maxHeight: 48 }}
            />
          )}
        />
      </Stack>
    </>
  );
};
