import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { DraftExperience } from '@understory-io/experiences-types';
import { Localized } from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import { useMemo, useState } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import {
  companyProfileQuery,
  draftExperienceQuery,
} from '../../../../../Api/queries';
import { useHasPaymentsEnabled } from '../../../../../Hooks/useHasPaymentsEnabled';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StorefrontLanguage, t } from '../../../../../i18n/config';
import { PaymentProvidersDisclaimerDialog } from '../../../../../Modals/PaymentProviderDisclaimerDialog';
import {
  getTaxRateLabel,
  getVatRatesAsArray,
} from '../../../../../Utils/tax/tax';
import {
  Variant,
  VariantAddon,
  variantSchema,
} from '../../../schemas/ticketSchema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import {
  convertFormDataValue,
  createErrorObject,
  getFormDataValue,
} from '../../utils/form-helpers';
import { getActiveLanguage } from '../../utils/get-active-language';
import { translateInput } from '../../utils/translate-input';
import { CreateTicketAddon } from './create-ticket-addon';
import {
  TICKET_ADDON_EXPLANATION_PROPERTY_NAME,
  TICKET_ADDON_ID_PROPERTY_NAME,
  TICKET_ADDON_NAME_PROPERTY_NAME,
  TICKET_ADDON_PRICE_PROPERTY_NAME,
  TICKET_ADDON_VAT_CATEGORY_PROPERTY_NAME,
  TicketAddon,
} from './ticket-addon';
import {
  TICKET_EXPLANATION_PROPERTY_NAME,
  TICKET_PRICE_PROPERTY_NAME,
  TICKET_PROPERTY_NAME,
  TICKET_VAT_CATEGORY_PROPERTY_NAME,
  TicketInfo,
} from './ticket-info';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params }: LoaderFunctionArgs) {
  const variantId = params.variantId;

  return { variantId };
}

export default function TicketsForm() {
  const { variantId } = useLoaderData() as LoaderData;
  const { experience, defaultCurrency, vatRegistrations, activeLanguage } =
    useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.tickets');

  const { hasPaymentsEnabled } = useHasPaymentsEnabled(true);

  const [isPaymentProvidersDialogOpen, setIsPaymentProvidersDialogOpen] =
    useState(false);

  const handleOpenPaymentProvidersDialog = () => {
    if (!hasPaymentsEnabled) {
      setIsPaymentProvidersDialogOpen(true);
    }
  };

  const variant = experience.price?.variants?.find(
    (variant) => variant.id === variantId
  );

  // Make sure the variant exists if an id is provided
  if (variantId && !variant) {
    throw new Response('Invalid variant id', { status: 404 });
  }

  const options = useMemo(() => {
    if (vatRegistrations.length === 0) return [];

    return getVatRatesAsArray(vatRegistrations[0]?.rates).map((tax) => {
      const { label, rateLabel } = getTaxRateLabel(
        vatRegistrations[0].country,
        tax,
        t
      );

      return {
        key: tax.category,
        label: `${label} (${rateLabel})`,
      };
    });
  }, [vatRegistrations, t]);

  const [addons, setAddons] = useState<Partial<VariantAddon>[]>(
    variant?.addons ?? []
  );

  const handleCreateAddon = () => {
    setAddons((prev) => [
      ...prev,
      {
        id: randomBytes(16).toString('hex'),
        name: undefined,
        explanation: undefined,
        price: 0,
        priceBreakdown: {
          vatInclusivePriceCents: 0,
          vatSetting: {
            vatCategory: vatRegistrations[0]?.defaultVatCategory,
          },
        },
      },
    ]);
  };

  const handleDeleteAddon = (id: string | undefined) => {
    setAddons((prev) => prev.filter((addon) => addon.id !== id));
  };

  const hasAddons = useMemo(() => addons.length > 0, [addons.length]);

  return (
    <>
      <EditExperienceDialog
        title={t('title')}
        description={t('description')}
        shouldClose={actionData?.shouldClose}
        experienceId={experience.id}
        type="tickets"
      >
        <Stack sx={{ marginTop: 1, gap: 2, alignItems: 'start' }}>
          <TicketInfo
            variant={variant}
            defaultCurrency={defaultCurrency}
            defaultVatCategory={vatRegistrations[0]?.defaultVatCategory}
            vatCategories={options}
            activeLanguage={activeLanguage}
            errors={actionData?.errors}
            hasPaymentsEnabled={hasPaymentsEnabled}
            onPricingClick={handleOpenPaymentProvidersDialog}
          />

          <CreateTicketAddon handleCreateAddon={handleCreateAddon} />
          {hasAddons && (
            <Stack
              sx={{
                gap: 1,
              }}
            >
              {addons?.map((addon, index) => (
                <TicketAddon
                  key={addon.id}
                  addon={addon}
                  vatCategories={options}
                  defaultCurrency={defaultCurrency}
                  defaultVatCategory={vatRegistrations[0]?.defaultVatCategory}
                  activeLanguage={activeLanguage}
                  errors={actionData?.errors}
                  index={index}
                  onDelete={handleDeleteAddon}
                  hasPaymentsEnabled={hasPaymentsEnabled}
                  onPricingClick={handleOpenPaymentProvidersDialog}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </EditExperienceDialog>
      <PaymentProvidersDisclaimerDialog
        open={isPaymentProvidersDialogOpen}
        onClose={() => {
          setIsPaymentProvidersDialogOpen(false);
        }}
      />
    </>
  );
}

type ActionData = {
  shouldClose?: boolean;
  errors?: ReturnType<typeof createErrorObject<Variant>>;
} | null;

export const action =
  (client: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const id = params.id;
    const variantId = params.variantId;

    if (!id) {
      throw new Response('Invalid id', { status: 400 });
    }

    try {
      const [experience, companyProfile] = await Promise.all([
        client.fetchQuery(draftExperienceQuery(id)),
        client.fetchQuery(companyProfileQuery()),
      ]);

      const activeLanguage = getActiveLanguage(request, companyProfile);

      const existingVariant = experience.price?.variants?.find(
        (variant) => variant.id === variantId
      );

      const formData = await request.formData();

      const translate = (input: string, existingValues?: Localized) => {
        return translateInput(
          input,
          existingValues,
          activeLanguage,
          companyProfile.languages as StorefrontLanguage[],
          experience.autoTranslateEnabled
        );
      };

      const addonIds = formData.getAll(
        TICKET_ADDON_ID_PROPERTY_NAME
      ) as string[];

      const addons = [] as VariantAddon[];
      // Create an addon for each addon id
      for (const addonId of addonIds) {
        // Get addon fields
        const addonName = getFormDataValue(
          formData.get(`${addonId}:${TICKET_ADDON_NAME_PROPERTY_NAME}`)
        ) as string;
        const addonPrice = convertFormDataValue(
          formData.get(`${addonId}:${TICKET_ADDON_PRICE_PROPERTY_NAME}`)
        ) as number;
        const addonVatCategory = getFormDataValue(
          formData.get(`${addonId}:${TICKET_ADDON_VAT_CATEGORY_PROPERTY_NAME}`)
        ) as string;
        const addonExplanation = getFormDataValue(
          formData.get(`${addonId}:${TICKET_ADDON_EXPLANATION_PROPERTY_NAME}`)
        ) as string;

        // Get existing addon info
        const existingAddon = existingVariant?.addons?.find(
          (addon) => addon.id === id
        );

        const translatedName = await translate(addonName, existingAddon?.name);

        // Translate addon explanation
        const translatedExplanation = await translate(
          addonExplanation,
          existingAddon?.explanation
        );

        const addon = {
          id: addonId,
          name: translatedName,
          explanation: translatedExplanation,
          priceBreakdown: {
            vatInclusivePriceCents: addonPrice * 100,
            vatSetting: {
              vatCategory: addonVatCategory || 'n/a',
            },
          },
        };

        // add adddon to addons array
        addons.push(addon);
      }

      // Get fields for ticket info
      const ticketName = getFormDataValue(
        formData.get(TICKET_PROPERTY_NAME)
      ) as string;
      const ticketPrice = convertFormDataValue(
        formData.get(TICKET_PRICE_PROPERTY_NAME)
      ) as number;
      const ticketVatCategory = getFormDataValue(
        formData.get(TICKET_VAT_CATEGORY_PROPERTY_NAME)
      ) as string;
      const ticketExplanation = getFormDataValue(
        formData.get(TICKET_EXPLANATION_PROPERTY_NAME)
      ) as string;

      // Translate ticket explanation
      const translatedVariantExplanation = await translate(
        ticketExplanation,
        existingVariant?.explanation
      );

      // Create ticket object
      const updatedVariant = {
        id: variantId ?? randomBytes(16).toString('hex'),
        name: await translate(ticketName, existingVariant?.name),
        // Only set explanation if the object isn't empty strings
        explanation: translatedVariantExplanation,
        price: ticketPrice,
        isDisplayPrice: false,
        hasMin: false,
        priceBreakdown: {
          vatInclusivePriceCents: ticketPrice * 100,
          vatSetting: {
            vatCategory: ticketVatCategory || 'n/a',
          },
        },
        addons,
      } as Variant;

      // Valdiate whole variant object
      const validatedVariant = variantSchema(activeLanguage).validateSync(
        updatedVariant,
        {
          abortEarly: false,
        }
      );

      // Figure out the index of the existing request, if it exists
      let existingIndex = -1;
      if (existingVariant) {
        existingIndex = experience.price.variants?.indexOf(existingVariant);
      }

      // Update the existing request or add the new one
      const existingVariants: NonNullable<
        DraftExperience['price']
      >['variants'] = experience.price.variants;
      if (existingIndex === -1) {
        existingVariants.push(validatedVariant);
      } else {
        existingVariants[existingIndex] = validatedVariant;
      }
      const experienceToSave = {
        ...experience,
        price: {
          ...experience.price,
          variants: existingVariants,
        },
      } as DraftExperience;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    } catch (error) {
      if (error instanceof ValidationError) {
        const errorObj = createErrorObject<Variant>(error);

        return {
          errors: errorObj,
        };
      }
      captureException(error);
      toast.error(t('utils.errors.generic'), { autoClose: 5000 });
      return null;
    }
  };
