import styled from '@emotion/styled';
import { ChevronLeftRounded } from '@mui/icons-material';
import { Box, Chip, css, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useEffect, useMemo, useState } from 'react';

import { ampli } from '../../../../../Ampli';
import { states } from '../../../../../Components/CustomCard/CustomCard';
import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { TBooking } from '../../../../../Hooks/useBookings';
import { IExperience } from '../../../../../Hooks/useExperience';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StorefrontLanguage } from '../../../../../i18n/config';
import { ChipState } from '../../../../../Modals/search/SearchDialog';
import { renderGuestTypes } from '../../../../../Utils/eventHelpers';
import { StyledLink } from '../../../shared';

export const GuestListAccordion = ({
  bookingsForEvent,
  experience,
  updatePopoverPosition,
}: {
  bookingsForEvent?: TBooking[];
  experience?: IExperience;
  updatePopoverPosition: () => void;
}) => {
  const { t, i18n } = useTranslate('calendar.popover');
  const [open, setOpen] = useState(false);

  const { languageOption } = useLanguages();

  const handleClick = () => {
    if (!open) ampli.guestListOpened({ source: 'Event Details Popup' });
    setOpen((open) => !open);
  };

  useEffect(() => {
    updatePopoverPosition();
  }, [open, updatePopoverPosition]);

  const filteredBookings = useMemo(() => {
    const validBookings = bookingsForEvent?.filter(
      (booking) => booking.status !== 'cancelled' && booking.status !== 'moved'
    );

    return validBookings?.sort((a, b) =>
      a.customer.name.localeCompare(b.customer.name, undefined, {
        sensitivity: 'base',
      })
    );
  }, [bookingsForEvent]);

  return (
    <Box>
      <StyledAccordionButton
        variant="text"
        size="medium"
        rightIcon={<StyledChevronLeftRounded $open={open} />}
        onClick={handleClick}
      >
        {t('guestList')}
      </StyledAccordionButton>
      {open && (
        <StyledStack>
          {filteredBookings?.map((booking) => {
            const guestCount = Object.entries(booking.items ?? {}).reduce(
              (total, [name, count]) =>
                name.includes('variant') ? total + count : total,
              0
            );

            return (
              <Stack key={booking.id}>
                <StyledBookingStack>
                  <StyledChip
                    color={
                      (states[booking.status][1] as ChipState) ?? 'default'
                    }
                  />
                  <StyledLink to={`/booking/${booking.id}`}>
                    <Text>{booking.customer.name}</Text>
                  </StyledLink>
                  {booking.language && (
                    <StyledLanguage>
                      <img
                        src={
                          languageOption(booking.language as StorefrontLanguage)
                            .flagSrc
                        }
                        alt={booking.language}
                        width={16}
                        height={16}
                      />
                    </StyledLanguage>
                  )}
                </StyledBookingStack>
                <StyledText fontSize="small">
                  {`${guestCount} ${t('guests', 'utils.generic')}`}
                  {' - '}
                  {renderGuestTypes(
                    booking,
                    'variant',
                    i18n.language,
                    experience?.price?.variants
                  ).join(', ')}
                </StyledText>
              </Stack>
            );
          })}
        </StyledStack>
      )}
    </Box>
  );
};

const StyledStack = styled(Stack)({
  gap: 16,
  marginTop: 8,
});

const StyledBookingStack = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const StyledChip = styled(Chip)({
  height: 8,
  width: 8,
  borderRadius: '100%',
  margin: 4,
});

const StyledLanguage = styled('span')({
  display: 'flex',
  alignItems: 'center',
  fontSize: lightTheme.typography.xsmall.normal.fontSize,
});

const StyledText = styled(Text)({
  marginLeft: 24,
});

const StyledChevronLeftRounded = styled(ChevronLeftRounded)<{
  $open?: boolean;
}>`
  ${({ $open }) => css`
    transform: rotate(${$open ? '90' : '-90'}deg);
  `};
`;

const StyledAccordionButton = styled(Button)({
  borderRadius: 0,
  marginTop: 8,
  height: 'unset',
  padding: 0,
  fontWeight: 400,
});
