import { apiServices } from './apiServices';
import api from './index';

export type GetOauth2ConsentsResponse = {
  consents?: Oauth2Consent[];
};

export type Oauth2Consent = {
  id: string;
  grantedAt: string;
  grantedBy: {
    id: string;
    email: string;
  };
  scopes: string[];
  client: {
    id: string;
    name: string;
    logoUri: string;
    owner: string;
  };
};

export const getOauth2Consents =
  async (): Promise<GetOauth2ConsentsResponse> => {
    if (typeof apiServices.internalApi === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
      );
    }

    const { data } = await api.get(
      `${apiServices.internalApi}/v1/public-api/oauth2-consents`
    );

    return data;
  };

export const deleteOauth2Consent = async (clientId: string): Promise<void> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.delete(`${apiServices.internalApi}/v1/public-api/oauth2-consents`, {
    data: {
      clientId,
    },
  });
};

export type GetOauth2ClientsResponse = {
  clients: Oauth2Client[];
};

export type Oauth2Client = {
  clientId: string;
  clientSecret?: string;
  name: string;
  createdAt: string;
  scopes: string[];
};

export const getOauth2Clients = async (): Promise<GetOauth2ClientsResponse> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get(
    `${apiServices.internalApi}/v1/public-api/credentials/client`
  );

  return data;
};

export const updateOauth2Client = async (
  clientId: string,
  name: string,
  scopes: string[]
): Promise<Oauth2Client> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.put(
    `${apiServices.internalApi}/v1/public-api/credentials/client/${clientId}`,
    {
      name,
      scopes,
    }
  );

  return data;
};

export const deleteOauth2Client = async (clientId: string): Promise<void> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.delete(
    `${apiServices.internalApi}/v1/public-api/credentials/client/${clientId}`
  );
};

export const createOauth2Client = async (
  name: string,
  scopes: string[]
): Promise<Oauth2Client> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.post(
    `${apiServices.internalApi}/v1/public-api/credentials/client`,
    {
      name,
      scopes,
    }
  );

  return data as Oauth2Client;
};

export const getScopes = async (): Promise<string[]> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<{ scopes: string[] }>(
    `${apiServices.internalApi}/v1/public-api/scopes`
  );

  return data.scopes;
};
