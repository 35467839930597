import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { Dispatch, SetStateAction, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackSignUpEmailFieldOpened } from '../../../tracking/signup/trackSignUpEmailFieldOpened';
import { trackSignUpPasswordFieldOpened } from '../../../tracking/signup/trackSignUpPasswordFieldOpened';
import { trackSignUpTermsClicked } from '../../../tracking/signup/trackSignUpTermsClicked';
import { emailRegex } from '../../../Utils/helpers';

type SignupFormInputs = {
  email: string;
  password: string;
};

export const SignupForm = ({
  isSubmitting,
  prefilledEmail,
  disableEmailField,
  submitLabel,
  setIsSubmitting,
  callback,
}: {
  isSubmitting: boolean;
  prefilledEmail?: string;
  disableEmailField?: boolean;
  submitLabel?: string;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  callback: (password?: string, email?: string) => Promise<void>;
}) => {
  const { t } = useTranslate('signup.form');

  const [showPassword, setShowPassword] = useState(false);

  const formMethods = useForm<SignupFormInputs>({
    defaultValues: {
      email: prefilledEmail ?? '',
      password: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: {
      errors: { email: emailError, password: passwordError },
    },
    setError,
  } = formMethods;

  const onSubmit = async ({ email, password }: SignupFormInputs) => {
    try {
      setIsSubmitting(true);
      await callback(password, email);
    } catch (error) {
      const { message } = error as { message?: string };
      if (message === 'USER_ALREADY_EXISTS') {
        setError('email', { message: t('validation.emailAlreadyUsed') });
      } else {
        console.error('An error occured', error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
      >
        <Stack gap={1}>
          <Stack gap={2}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('validation.emailRequired'),
                },
                pattern: {
                  value: emailRegex,
                  message: t('validation.emailInvalid'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  error={!!emailError}
                  helperText={emailError?.message}
                  label={t('label.email')}
                  placeholder={t('placeholder.email')}
                  InputLabelProps={{
                    sx: {
                      color: lightTheme.palette.neutral.n300,
                    },
                  }}
                  onFocus={trackSignUpEmailFieldOpened}
                  disabled={isSubmitting || disableEmailField}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('validation.passwordRequired'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  error={!!passwordError}
                  helperText={passwordError?.message}
                  label={t('label.password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword ? 'Hide password' : 'Show password'
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      color: lightTheme.palette.neutral.n300,
                    },
                  }}
                  onFocus={trackSignUpPasswordFieldOpened}
                  disabled={isSubmitting}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Button
            type="submit"
            variant="primary"
            size="medium"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              submitLabel ?? t('tryForFree')
            )}
          </Button>
          <Box
            textAlign="center"
            fontSize="small"
            color={lightTheme.palette.neutral.n300}
            lineHeight={1.5}
          >
            <Trans
              i18nKey="signup.form.termsDisclaimer"
              values={{ submitLabel: submitLabel ?? t('tryForFree') }}
              components={{
                Link: (
                  <Link
                    target="_blank"
                    underline="always"
                    onClick={trackSignUpTermsClicked}
                  />
                ),
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
