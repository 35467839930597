import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isBackofficeLanguage } from '../i18n/config';
import { useProfile } from './useProfile';

export const LANGUAGES_PREFERENCES_KEY = 'languages';

export const useApplyPreferences = () => {
  const { i18n } = useTranslation();
  const { me, preferences } = useProfile();

  useEffect(() => {
    if (!me.isFetched) return;

    const preferredLanguage =
      preferences?.[LANGUAGES_PREFERENCES_KEY]?.defaultLanguage;

    if (
      isBackofficeLanguage(preferredLanguage) &&
      i18n.language !== preferredLanguage
    ) {
      i18n.changeLanguage(preferredLanguage);
    }
  }, [i18n, me.isFetched, preferences]);
};
