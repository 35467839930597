import NiceModal from '@ebay/nice-modal-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import * as api from '../../../../../Api';
import { Oauth2Consent } from '../../../../../Api';
import { PublicApiOauth2IntegrationDialog } from '../../../../../Modals/PublicApiOauth2IntegrationDialog';
import {
  IntegrationObject,
  LoadingIntegrationObject,
} from '../use-integrations';

export const useOauth2Integration = ():
  | IntegrationObject[]
  | [LoadingIntegrationObject] => {
  const { consents, deleteConsent } = useOAuth2Consents();

  // there can be multiple consents for the same client but we revoke all or
  // nothing as there is usually no meaningful way to interpret the case, ie.
  // how would the host know which set of credentials to revoke?
  const clientConsents: Record<string, Oauth2Consent[]> | undefined =
    consents.data?.consents?.reduce<{ [clientId: string]: Oauth2Consent[] }>(
      (acc, current) => {
        const clientConsents = acc[current.client.id];
        if (!clientConsents) {
          return { ...acc, [current.client.id]: [current] };
        }

        clientConsents.push(current);
        return acc;
      },
      {}
    );

  if (consents.isLoading) {
    return [
      {
        loading: true,
      },
    ];
  }

  if (!clientConsents || Object.keys(clientConsents).length === 0) {
    // if we want to show integration placeholders we could add them here
    return [];
  }

  const handleInstall =
    (client: Oauth2Consent['client'], consents: Oauth2Consent[]) =>
    () =>
    async () => {
      NiceModal.show(PublicApiOauth2IntegrationDialog, {
        client,
        consents,
        onRevoke: async () => {
          deleteConsent.mutate(client.id);
          toast.success(`${client.name} integration removed`);
        },
      });
    };

  return Object.keys(clientConsents).map((clientId) => {
    const client = clientConsents[clientId][0].client;

    return {
      id: `understoryApi-oauth2-${clientId}`,
      title: client.name,
      description: '',
      extendedDescription: '',
      status: 'connected',
      icon: client.logoUri,
      onInstall: handleInstall(client, clientConsents[clientId]),
      buttonLabel: (status: string) => status,
    };
  });
};

const useOAuth2Consents = () => {
  const queryClient = useQueryClient();

  const QueryKey = ['public-api', 'oauth2-consents'];

  const consents = useQuery({
    queryKey: QueryKey,

    queryFn: async () => api.getOauth2Consents(),

    enabled: true,
    retry: false,
  });

  const deleteConsent = useMutation({
    mutationFn: (clientId: string) => api.deleteOauth2Consent(clientId),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: QueryKey,
      });

      const previous = queryClient.getQueryData(QueryKey);

      queryClient.setQueryData<unknown>(QueryKey, () => {
        return {};
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(QueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    },
  });

  return {
    consents,
    deleteConsent,
  };
};
