import { useExperience } from '../../../../Hooks/useExperience';

export function useTranslationStatus() {
  const { experiences } = useExperience();

  return (newLanguage: string) => {
    const anyNotTranslated = experiences.data?.some((experience) => {
      if (
        !experience.headline ||
        !experience.headline[newLanguage] ||
        experience.headline[newLanguage] === ''
      ) {
        return true;
      }
      if (
        !experience.practicalInfo ||
        !experience.practicalInfo[newLanguage] ||
        experience.practicalInfo[newLanguage] === ''
      ) {
        return true;
      }
    });

    return anyNotTranslated;
  };
}
