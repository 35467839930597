import { Divider, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { PlatformCustomer } from '@understory-io/customer-management-types';
import { Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateCompanyProfile } from '../../../Api';
import { updatePlatformCustomer } from '../../../Api/customer-management';
import { companyProfileQuery, userProfileQuery } from '../../../Api/queries';
import { platformCustomerQuery } from '../../../Api/queries/customer-management';
import { CountryVatRatesQuery } from '../../../Api/queries/vat-rates';
import { FixedActionsBar } from '../../../Components/FixedActionsBar/FixedActionsBar';
import { useYupForm } from '../../../Hooks/use-yup-form';
import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { t } from '../../../i18n/config';
import { getVatCompliance } from '../../../Sections/SettingsSections/Company/domain/helpers';
import {
  trackBillingPageViewed,
  trackBillingSettingsUpdated,
  trackCompanyVatSettingsUpdated,
} from '../../../tracking/settings/settings-events';
import { BillingFormData, billingFormSchema } from './billing-schema';
import { BasicBillingCompanyInfo } from './billing-section-basic-company-info';
import { BillingSectionTaxRegistration } from './billing-section-tax-registration';

type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader = (client: QueryClient) => async () => {
  const [companyProfile, userProfile] = await Promise.all([
    client.fetchQuery(companyProfileQuery()),
    client.fetchQuery(userProfileQuery()),
  ]);
  const platformCustomer = await client.fetchQuery(
    platformCustomerQuery(companyProfile.id)
  );

  return {
    companyProfile,
    userProfile,
    platformCustomer,
  };
};

export const BillingSection = () => {
  const submit = useSubmit();
  const { state } = useNavigation();
  const { t } = useTranslation();
  const actionData = useActionData<ActionData>();
  const { platformCustomer, userProfile } = useLoaderData<LoaderData>();
  const { data: countryVatRates } = useCountryVatRates();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => trackBillingPageViewed());
  }, [fireOnce]);

  const formMethods = useYupForm({
    schema: billingFormSchema(countryVatRates),
    defaultValues: {
      isTaxRegistered: false,
      ...platformCustomer.companyInfo,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const onSubmit = (data: BillingFormData) => {
    trackBillingSettingsUpdated({
      isTaxRegistered: !!data.isTaxRegistered,
    });
    trackCompanyVatSettingsUpdated(
      {
        isTaxRegistered: !!data.isTaxRegistered,
        taxCountry: data.legalLocation.countryCode,
      },
      userProfile.id
    );
    submit(data, {
      method: 'post',
      encType: 'application/json',
    });
  };

  useEffect(() => {
    if (actionData?.updatedPlatformCustomer) {
      reset({
        ...actionData.updatedPlatformCustomer.companyInfo,
      });
    }
  }, [actionData, reset]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={2} sx={{ width: '75%' }}>
          <Text variant="medium">{t('settings.navigation.billing')}</Text>
          <Text variant="normal">{t('settings.billing.explainer')}</Text>

          <Stack gap={4}>
            <BasicBillingCompanyInfo />
            <Divider />
            <BillingSectionTaxRegistration
              platformCustomer={platformCustomer}
            />
          </Stack>
        </Stack>
        <FixedActionsBar
          onReset={() => reset()}
          isSubmitting={state === 'submitting'}
          unsavedChanges={isDirty}
        />
      </Form>
    </FormProvider>
  );
};

type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    try {
      const { legalName, legalLocation, isTaxRegistered, vatNumber } =
        (await request.json()) as BillingFormData;

      const [companyProfile, countryVatRates] = await Promise.all([
        client.fetchQuery(companyProfileQuery()),
        client.fetchQuery(CountryVatRatesQuery()),
      ]);
      const platformCustomer = await client.fetchQuery(
        platformCustomerQuery(companyProfile.id)
      );

      const updateCompanyInfo: PlatformCustomer['companyInfo'] = {
        ...platformCustomer.companyInfo,
        legalName,
        legalLocation,
        vatNumber,
        isTaxRegistered,
      };

      const updatedPlatformCustomer: PlatformCustomer = {
        ...platformCustomer,
        companyInfo: updateCompanyInfo,
      };

      const [vatRegistration] = companyProfile.vatCompliance.vatRegistrations;

      const updatedVatCompliance = getVatCompliance(
        {
          vatCompliance: {
            isVatRegistered: isTaxRegistered ?? false,
            vatRegistration: {
              country: legalLocation.countryCode,
              defaultVatCategory:
                vatRegistration?.defaultVatCategory ?? 'standard',
              vatNumber: updatedPlatformCustomer.companyInfo?.vatNumber ?? '',
            },
          },
        },
        countryVatRates,
        companyProfile
      );

      const updatedCompanyProfile: CompanyProfile = {
        ...companyProfile,
        vatCompliance: updatedVatCompliance,
        location: {
          ...companyProfile.location,
          address: legalLocation.address,
          city: legalLocation.city,
          zipCode: legalLocation.zipCode,
          country: legalLocation.countryCode,
          state: legalLocation.state,
        },
      };

      const result = await Promise.allSettled([
        updatePlatformCustomer(updatedPlatformCustomer.companyId, {
          companyInfo: updateCompanyInfo,
        }),
        updateCompanyProfile(updatedCompanyProfile),
      ]);

      if (result.some((x) => x.status === 'rejected')) {
        throw new Error(
          'Failed to update both platform customer and company profile'
        );
      }

      client.invalidateQueries({
        queryKey: companyProfileQuery().queryKey,
      });

      client.invalidateQueries({
        queryKey: platformCustomerQuery(platformCustomer.companyId).queryKey,
      });

      return {
        updatedPlatformCustomer,
        updatedCompanyProfile,
      };
    } catch (error) {
      toast.error(t('utils.errors.generic'));
      captureException(error);
      return null;
    }
  };
