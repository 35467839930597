import { SxProps } from '@mui/material';

/**
 * Merges multiple sx objects
 *
 * @see https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
 */
export function mergeSx<T extends object>(
  ...sxs: (SxProps<T> | null | undefined | false)[]
): SxProps<T> {
  return sxs.flatMap((sx) => sx || []);
}
