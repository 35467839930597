import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { ampli } from '../../../Ampli';
import { useMenuNotification } from '../../../Components/menu-notification/menu-notification-context';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  containerVariants,
  defaultTransition,
  itemVariants,
  paymentVariants,
} from './animation-variants';
import { DashboardSuggestionsDraftSavedDialog } from './dashboard-suggestions-draft-saved-dialog';
import { PaymentSection } from './sections/payment-section';
import { SuggestionsSection } from './sections/suggestions-section';
import { VideoSection } from './sections/video/video-section';
import { SuggestionsContainer, SuggestionsContent } from './styles';

const STORAGE_KEY = 'hide_dashboard_suggestions_video_section';

export const DashboardSuggestions = () => {
  const { t } = useTranslate('dashboard.suggestions');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromOnboarding = searchParams.get('fromOnboarding');
  const { onboardingCompleted, company } = useProfile();
  const { addNotification } = useMenuNotification();

  const [showDraftSavedDialog, setShowDraftSavedDialog] = useState(false);
  const [isVideoHidden, setIsVideoHidden] = useState(() => {
    return localStorage.getItem(STORAGE_KEY) === 'true';
  });

  const handleCloseDraftSavedDialog = useCallback(() => {
    setShowDraftSavedDialog(false);
    addNotification('experiences', t('notifications.experienceSaved'));
  }, [addNotification, t]);

  const handleHideVideo = useCallback(() => {
    ampli.dashboardSuggestionsVideoSectionHidden();
    setIsVideoHidden(true);
    localStorage.setItem(STORAGE_KEY, 'true');
  }, []);

  useEffect(() => {
    if (fromOnboarding) {
      window.scrollTo(0, 0);
      setShowDraftSavedDialog(true);
    }
  }, [fromOnboarding]);

  const shouldShowContent = !onboardingCompleted && !company.isLoading;

  return (
    <>
      <DashboardSuggestionsDraftSavedDialog
        open={showDraftSavedDialog}
        onClose={handleCloseDraftSavedDialog}
      />
      <LayoutGroup>
        <SuggestionsContainer>
          <AnimatePresence mode="popLayout">
            {shouldShowContent && (
              <motion.div
                layout
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={defaultTransition}
              >
                <SuggestionsContent>
                  <AnimatePresence mode="popLayout">
                    {!isVideoHidden && (
                      <motion.div
                        layout
                        key="video"
                        variants={itemVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <VideoSection onHide={handleHideVideo} />
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <motion.div
                    layout
                    key="suggestions"
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <SuggestionsSection />
                  </motion.div>
                </SuggestionsContent>
              </motion.div>
            )}
            <motion.div
              layout
              key="payment"
              variants={paymentVariants}
              initial="hidden"
              animate="visible"
              transition={defaultTransition}
            >
              <PaymentSection />
            </motion.div>
          </AnimatePresence>
        </SuggestionsContainer>
      </LayoutGroup>
    </>
  );
};
