import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { MediaItem } from '@understory-io/utils-types';
import { useContext, useMemo, useState } from 'react';

import {
  getMimeTypeFromMediaItem,
  getURLFromMediaItem,
} from '../../../../../Components/DraggableGallery/Media';
import {
  FileBeingUploaded,
  UploadedMedia,
} from '../../../../onboarding-deprecated/media-upload/use-uploader';
import { trackExperienceEditMediaDragged } from '../../utils/tracking-helper';
import { AddMedia } from './add-media';
import { mediaDropZoneContext } from './media-dropzone';
import { MediaPreview } from './media-preview';
import { MovableMedia } from './movable-media';

type MediaListProps = {
  uploadedMedia: UploadedMedia[];
  mediaBeingUploaded: FileBeingUploaded[];
  onChangeOrder: (media: MediaItem[]) => void;
  onDelete: (url: string) => void;
  isDisabled: boolean;
};

export const MediaList = ({
  uploadedMedia,
  mediaBeingUploaded,
  onChangeOrder,
  onDelete,
  isDisabled,
}: MediaListProps) => {
  const { handleOpen } = useContext(mediaDropZoneContext);

  const hasMedia = useMemo(
    () => Boolean(uploadedMedia?.length) || Boolean(mediaBeingUploaded.length),
    [uploadedMedia, mediaBeingUploaded]
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 200, tolerance: 10 },
    })
  );

  const coverMediaIndex = uploadedMedia.findIndex(
    (media) => media.type === 'image'
  );

  const [mediaBeingMoved, setMediaBeingMoved] = useState<
    UploadedMedia | undefined
  >();

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const uploaededMedia = uploadedMedia.find(
      (media) => getURLFromMediaItem(media) === active.id
    );
    setMediaBeingMoved(uploaededMedia);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!!over && active.id !== over.id) {
      trackExperienceEditMediaDragged();
      onChangeOrder(
        arrayMove(
          uploadedMedia,
          active.data.current?.sortable.index,
          over.data.current?.sortable.index
        )
      );
    }
    setMediaBeingMoved(undefined);
  };

  const items = uploadedMedia.map(getURLFromMediaItem);

  return (
    <Stack
      sx={{
        // General
        display: { xs: 'flex', md: 'grid' },
        gap: { xs: 1, md: 1.5 },
        py: { xs: 2, md: 0 },

        // Desktop grid
        gridTemplateColumns: { md: hasMedia ? 'repeat(5, 1fr)' : '1fr' },

        // Mobile flex
        overflowY: { xs: 'scroll', md: 'auto' },
        flexDirection: 'row',
        flexWrap: { xs: 'nowrap' },
        alignItems: 'stretch',
        scrollbarWidth: 'none',

        '& > *': {
          width: hasMedia
            ? {
                xs: '45%',
                md: 'unset',
              }
            : '100%',
          flex: '0 0 auto',
        },
      }}
    >
      {!isDisabled && (
        <AddMedia onClick={() => handleOpen?.()} hasMedia={hasMedia} />
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items}>
          {uploadedMedia.map((media, index) => {
            const url = getURLFromMediaItem(media);
            const mimeType = getMimeTypeFromMediaItem(media);

            return (
              <MovableMedia
                key={index}
                url={url}
                type={media.type}
                isLocal={!!media.localUrl}
                isCover={index === coverMediaIndex}
                onDelete={() => onDelete(url)}
                mimeType={mimeType}
              />
            );
          })}
        </SortableContext>
        <DragOverlay>
          {!!mediaBeingMoved && (
            <MediaPreview
              url={getURLFromMediaItem(mediaBeingMoved)}
              type={mediaBeingMoved?.type}
              isLocal={!!mediaBeingMoved?.localUrl}
              mimeType={getMimeTypeFromMediaItem(mediaBeingMoved)}
              isMoving={true}
            />
          )}
        </DragOverlay>
      </DndContext>
      {!!mediaBeingUploaded.length &&
        mediaBeingUploaded.map((file) => (
          <MediaPreview
            key={file.localUrl}
            url={file.localUrl}
            type={file.type}
            isLocal={true}
            progress={file.progress}
            mimeType={getMimeTypeFromMediaItem(file)}
          />
        ))}
    </Stack>
  );
};
