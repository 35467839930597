import { useTranslation } from 'react-i18next';

import { BackofficeLanguage } from '../i18n/config';
import { getLocalizedString } from './locales/use-locale.context';

export type LocalizedString = Record<string, string>;

export type LocalizerFunction = (
  value: string | LocalizedString | undefined | null,
  defaultValue?: string
) => string;

export const useLocalizedStringFormatter = (): LocalizerFunction => {
  const { i18n } = useTranslation();

  return (value, defaultValue = '') =>
    getLocalizedString(
      value,
      i18n.languages as BackofficeLanguage[],
      defaultValue
    );
};
