import { useMemo } from 'react';

import { StorefrontLanguage } from '../../i18n/config';
import { useLanguages } from '../locales/use-languages';

export const useSelectedLanguages = (selected?: string[]) => {
  const { storefrontLanguages, languageOption } = useLanguages();

  return useMemo(() => {
    if (!selected) return undefined;

    const possibleLanguages = selected.map((locale) =>
      languageOption(locale as StorefrontLanguage)
    );

    // Sorts the languages based on detected language in the backoffice
    return possibleLanguages.sort((a, b) => {
      const aIndex = storefrontLanguages.indexOf(a.locale);
      const bIndex = storefrontLanguages.indexOf(b.locale);

      if (aIndex === -1) {
        return 1;
      }

      if (bIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    });
  }, [languageOption, selected, storefrontLanguages]);
};
