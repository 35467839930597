import { Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

import { ampli } from '../../../../Ampli';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { ANIMATIONS } from '../../config';
import { useOnboardingFlow } from '../../onboarding-context';
import { StepLayoutActions } from './step-layout-actions';

type OnboardingStepLayoutProps = {
  title: string;
  description?: string;
  isLoading?: boolean;
  showBackButton?: boolean;
  onSubmit?: () => void;
  children: ReactNode;
};

export const OnboardingStepLayout = ({
  title,
  description,
  isLoading = false,
  showBackButton = true,
  onSubmit,
  children,
}: OnboardingStepLayoutProps) => {
  const { currentStepKey } = useOnboardingFlow();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => {
      ampli.onboardingFlowStepViewed({ step: currentStepKey });
    });
  }, [currentStepKey, fireOnce]);

  return (
    <MainContainer>
      <ContentContainer>
        <Stack gap={1}>
          <AnimatePresence mode="wait">
            <motion.div
              key={`title-${title}`}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={ANIMATIONS.title}
              style={{ minHeight: '32px' }}
            >
              <Text variant="medium" fontSize="xlarge">
                {title}
              </Text>
            </motion.div>
          </AnimatePresence>
          <AnimatePresence>
            {description && (
              <motion.div
                key={`description-${description}`}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={ANIMATIONS.description}
                style={{ minHeight: '24px' }}
              >
                <Text color={lightTheme.palette.neutral.n300}>
                  {description}
                </Text>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
        <motion.div {...ANIMATIONS.content}>{children}</motion.div>
      </ContentContainer>
      <StepLayoutActions
        showBackButton={showBackButton}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </MainContainer>
  );
};

const MainContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  maxWidth: '650px',
  minWidth: '650px',
  justifyContent: 'space-between',
  height: '100%',
  flex: 1,
  gap: '40px',
  [theme.breakpoints.down('md')]: {
    minWidth: '100%',
    height: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingBottom: `calc(${theme.spacing(3)} * 4 + env(safe-area-inset-bottom))`,
  },
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '100%',
  gap: '32px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    WebkitOverflowScrolling: 'touch',
    '& > *': {
      width: '100%',
    },
  },
}));
