import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDate } from '@understory-io/utils-date';
import { ExperiencesGetWaitlistResponse } from '@understory-io/utils-types';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';

interface WaitlistDialogProps {
  items: ExperiencesGetWaitlistResponse['items'];
}

export const WaitlistDialog = NiceModal.create<WaitlistDialogProps>(
  ({ items }) => {
    const { t } = useTranslate('experience.details.waitlist');
    const { isSm } = useResponsive();
    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        fullScreen={isSm}
      >
        <Stack gap={4} p={4} height={'100%'} justifyContent={'space-between'}>
          <Stack gap={2}>
            <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
              <Stack gap={1}>
                <Text fontSize="xlarge" variant="medium">
                  {t('title')}
                </Text>
                <Text fontSize="small">{t('description')}</Text>
              </Stack>
              <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Stack>
            <Stack gap={1}>
              {items?.map((item) => (
                <Stack
                  key={item.id}
                  direction={isSm ? 'column' : 'row'}
                  gap={isSm ? 0 : 2}
                >
                  <Text key={item.id} fontSize="small" variant="medium">
                    {item.email}
                  </Text>
                  <Text
                    fontSize="small"
                    color={lightTheme.palette.neutral.n300}
                  >
                    {t('subscribed')}: {renderDate(item.createdAt)}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
