import * as api from '..';

/* ---- Company Profile ----  */
export const companyProfileQuery = () => ({
  queryKey: ['company'],
  queryFn: () => api.getCompanyProfile(),
});

/* ---- Company Domain ----  */
const companyDomainQueryKey = (companyId: string) => [
  'companyDomain',
  companyId,
];

export const companyDomainQuery = (companyId: string) => ({
  queryKey: companyDomainQueryKey(companyId),
  queryFn: () => api.getCompanyDomain(companyId),
  enabled: !!companyId,
});

/* ---- Company Users ----  */
export const companyUsersQuery = () => ({
  queryKey: ['companyUsers'],
  queryFn: () => api.getCompanyUsers(),
});

/* ---- Public Company Profile ----  */
const publicCompanyProfileQueryKey = (companyId: string) => [
  'public-company',
  companyId,
];

export const publicCompanyProfileQuery = (experienceId: string) => ({
  queryKey: publicCompanyProfileQueryKey(experienceId),
  queryFn: () => api.getPublicCompanyProfile(experienceId),
  enabled: !!experienceId,
});

/* ---- User profile ----  */
export const userProfileQuery = () => ({
  queryKey: ['me'],
  queryFn: () => api.getMyProfile(),
  retry: true,
});

/* ---- User info ---- */
export const userInfoQuery = () => ({
  queryKey: ['userinfo'],
  queryFn: () => api.getUserinfo(),
});
