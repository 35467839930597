import { Divider } from '@mui/material';

import { Page } from '../../Components/Page/Page';
import { DashboardGraphs } from './dashboard-graphs/dashboard-graphs';
import { DashboardHeader } from './dashboard-header/dashboard-header';
import { DashboardSuggestions } from './dashboard-suggestions/dashboard-suggestions';
import { DashboardSuggestionsHelp } from './dashboard-suggestions/sections/help-section';
import { DashboardPageContextProvider } from './use-dashboard-page-context';
import { useDashboardPageState } from './use-dashboard-page-state';

export const DashboardPage = () => {
  const { showDivider, showSuggestions, showGraphsOrLoading } =
    useDashboardPageState();

  return (
    <DashboardPageContextProvider>
      <Page gap={4} pb={4} maxWidth={1400}>
        <DashboardHeader />
        {showDivider && <Divider />}
        {showSuggestions && <DashboardSuggestions />}
        {showGraphsOrLoading && <DashboardGraphs />}
        <DashboardSuggestionsHelp />
      </Page>
    </DashboardPageContextProvider>
  );
};
