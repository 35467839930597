import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Link } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackSignUpLogInClicked } from '../../../../tracking/signup/trackSignUpLogInClicked';

interface LoginPromptProps {
  color?: string;
  dropShadow?: boolean;
  variant?: 'floating' | 'inline';
}

export const LoginPrompt = ({
  color = '#C6D194',
  dropShadow = false,
  variant = 'floating',
}: LoginPromptProps) => {
  const { t } = useTranslate('signup.form');

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        ...(variant === 'floating'
          ? {
              position: 'absolute',
              top: 16,
              right: 16,
              backgroundColor: color,
              padding: '8px 16px',
              borderRadius: 1.5,
            }
          : {
              my: 1.5,
              color: lightTheme.palette.neutral.n300,
            }),
        boxShadow: dropShadow ? lightTheme.shadows.medium : 'none',
      }}
    >
      <Text fontSize="small">{t('existingAccount')}</Text>
      <Link
        to="/login"
        onClick={trackSignUpLogInClicked}
        style={{
          textDecoration: 'underline',
          color: lightTheme.palette.action.a300,
          fontSize: '14px',
        }}
      >
        {t('action.login')}
      </Link>
    </Stack>
  );
};
