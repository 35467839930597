import { useQuery } from '@tanstack/react-query';

import { resourceTypesQuery } from '../../../Api/queries/resources';
import { useProfile } from '../../../Hooks/useProfile';

export const useResourceTypes = () => {
  const { company } = useProfile();

  return useQuery({
    ...resourceTypesQuery(),
    enabled: !!company.data?.id,
  });
};
