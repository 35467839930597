import { styled } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { CustomChip } from '../../../../Components/CustomChip/CustomChip';

type UsesChipProps = {
  isSelected: boolean;
  label: string;
  onClick: () => void;
};

export const UsesChip = ({ isSelected, label, onClick }: UsesChipProps) => {
  return (
    <UseChip
      selected={isSelected}
      isSelected={isSelected}
      label={label}
      onClick={onClick}
    />
  );
};

const UseChip = styled(CustomChip)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    height: 35,
    fontSize: '0.88em',
    fontWeight: 'bold',
    borderRadius: 32,
    border: isSelected
      ? `1px solid ${lightTheme.palette.action.a300}`
      : `1px solid ${lightTheme.palette.neutral.n100}`,
    '&:hover': {
      backgroundColor: isSelected
        ? `${lightTheme.palette.action.a100} !important`
        : 'initial',
    },
    '&:focus': {
      backgroundColor: isSelected
        ? lightTheme.palette.action.a100
        : lightTheme.palette.contrast.white,
    },
  })
);
