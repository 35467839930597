import { useQuery } from '@tanstack/react-query';

import * as api from '../Api';

export const useAdUsps = () => {
  const UspsQueryKey = ['ad-usps'];

  const usps = useQuery({
    queryKey: UspsQueryKey,
    queryFn: async () => {
      const usps = await api.generateCompanyUsps();
      return usps;
    },
  });

  return { usps };
};
