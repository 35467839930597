import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';

import { eventQuery, experienceLocationsQuery } from '../../../../Api/queries';
import { queryClient } from '../../../../query-client';
import routes from '../../../../Utils/routes';
import { EventForm } from '../form/event-form';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (!id) {
    throw new Response('Event id is required', { status: 400 });
  }

  const event = await queryClient.fetchQuery(eventQuery(id));

  const eventLocationsPromise = queryClient.fetchQuery(
    experienceLocationsQuery(event.experienceId)
  );

  return { event, eventLocationsPromise };
};

export default function EditEventPage() {
  const { event, eventLocationsPromise } = useLoaderData<LoaderData>();
  const { id } = useParams();

  const flags = useFlags();
  const navigate = useNavigate();
  if (!flags?.featureNewEventFlow) {
    navigate(id ? routes.event.details(id).edit : routes.event.create);
    return null;
  }

  return <EventForm handleSubmit={() => console.log('submit edit')} />;
}
