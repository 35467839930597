import styled from '@emotion/styled';
import { Box, css, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router';

import { useLocale } from '../../../../Hooks/locales/use-locale.context';
import { useCalendar } from '../../context';
import { CalendarGrid } from '../calendar-grid/CalendarGrid';
import { useCalendarLink } from '../hooks/useCalendarLink';
import { EventsSummary } from './EventsSummary';

const ARRAY_OF_SEVEN = Array.from({ length: 7 });

export const CalendarMonthListUngrouped: FC = () => {
  const { selectedDate } = useCalendar();

  const getCalendarLink = useCalendarLink();
  const { dateFnsLocale } = useLocale();

  const [today] = useState(new Date());

  const { startDate, dates } = useMemo(() => {
    const monthStart = startOfMonth(selectedDate);
    const startDate = startOfWeek(monthStart, {
      weekStartsOn: dateFnsLocale.options?.weekStartsOn,
    });

    const monthEnd = endOfMonth(monthStart);
    const endDate = endOfWeek(monthEnd, {
      weekStartsOn: dateFnsLocale.options?.weekStartsOn,
    });

    const dates = eachDayOfInterval({ start: startDate, end: endDate });

    return { startDate, dates };
  }, [dateFnsLocale.options?.weekStartsOn, selectedDate]);

  return (
    <CalendarGrid $columns={7}>
      {ARRAY_OF_SEVEN.map((_, i) => {
        const date = addDays(startDate, i);
        return (
          <Box padding={1.5} key={date.toISOString()} textAlign="center">
            <DayOfWeek variant="medium" fontSize="small">
              {format(date, 'EEE')}{' '}
            </DayOfWeek>
          </Box>
        );
      })}

      {dates.map((date) => (
        <Link key={date.toISOString()} to={getCalendarLink(date, 'day')}>
          <Stack
            padding={0.5}
            gap={0.5}
            minHeight="150px"
            alignItems={'center'}
          >
            <DayOfWeek
              variant="medium"
              fontSize="small"
              $isToday={isSameDay(date, today)}
              $isFirst={date.getDate() === 1}
            >
              {formatDate(date)}
            </DayOfWeek>
            <EventsSummary date={date} />
          </Stack>
        </Link>
      ))}
    </CalendarGrid>
  );
};

const formatDate = (date: Date) => {
  const day = format(date, 'd');
  if (day === '1') return format(date, 'd. MMM');

  return day;
};

const DayOfWeek = styled(Text)<{ $isToday?: boolean; $isFirst?: boolean }>`
  min-width: 32px;
  height: 32px;
  line-height: 32px;

  color: ${lightTheme.palette.neutral.n300};

  text-transform: capitalize;
  text-align: center;

  ${({ $isToday }) =>
    $isToday &&
    css`
      color: white;
      background: ${lightTheme.palette.action.a300};
    `}

  ${({ $isFirst }) => css`
    border-radius: ${$isFirst ? '10px' : '50%'};
    padding: ${$isFirst ? '0 8px' : '0'};
  `}
`;
