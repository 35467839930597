import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRevalidator } from 'react-router';
import { toast } from 'react-toastify';

import DialogBase from '../../../Components/dialog/dialog-base';
import { useExperience } from '../../../Hooks/useExperience';

export const DiscardDraftDialog = NiceModal.create(
  ({ experienceId }: { experienceId: string }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const revalidator = useRevalidator();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    const [isLoading, setIsLoading] = useState(false);
    const { discardExperience } = useExperience(experienceId);

    const handleDelete = async () => {
      try {
        setIsLoading(true);

        await discardExperience.mutateAsync(experienceId);

        toast.success(t('experience.details.discard.success'));
        revalidator.revalidate();
        handleClose();
      } catch (error) {
        toast.error(t('experience.details.discard.error'));
        setIsLoading(false);
        captureException(error);
      }
    };

    return (
      <DialogBase
        key={experienceId}
        open={modal.visible}
        onClose={handleClose}
        maxWidth="sm"
        title={t('experience.details.discard.title')}
        description={t('experience.details.discard.description')}
        fullHeight={false}
        actionsInHeader={false}
        showSecondaryActionDesktop
        secondaryAction={{
          label: t('experience.details.discard.cancel'),
          variant: 'secondary',
          onClick: handleClose,
        }}
        primaryAction={{
          label: t('experience.details.discard.delete'),
          variant: 'danger',
          onClick: handleDelete,
          loading: isLoading,
        }}
      />
    );
  }
);
