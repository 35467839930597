import { Stack } from '@mui/system';
import { Text } from '@understory-io/pixel';
import { LoaderFunctionArgs, useLoaderData } from 'react-router';

import { bookingsQuery } from '../../../Api/queries';
import { queryClient } from '../../../query-client';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const bookings = await queryClient.fetchQuery(bookingsQuery());

  return { bookings };
};

export function BookingsOverview() {
  const { bookings } = useLoaderData<LoaderData>();

  return (
    <Stack
      sx={{
        py: { xs: 3, md: 5 },
      }}
    >
      <Text variant="medium" fontSize="h5">
        Bookings
      </Text>
    </Stack>
  );
}
