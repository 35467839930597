import {
  DesktopTimePicker,
  PickerValidDate,
  TimePickerProps,
} from '@mui/x-date-pickers';
import { format, set } from 'date-fns';
import { forwardRef, useMemo } from 'react';

const timeInputRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

interface TimeInputProps
  extends Omit<TimePickerProps<PickerValidDate>, 'value' | 'onChange'> {
  value: string | undefined;
  onChange: (time: string | undefined) => void;
}

/**
 * Takes `value` and returns a time string in the format of `HH:mm` in `onChange`.
 * Passing an `undefined` date will result in an empty input
 */
export const TimeInput = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ value, onChange, ...props }: TimeInputProps, _ref) => {
    const _value = useMemo(() => {
      if (!value) return undefined;
      if (!value.match(timeInputRegex)) return new Date();
      const [hours, minutes] = parseTime(value);
      return set(new Date(), { hours, minutes });
    }, [value]);

    const handleChange = (date: Date | null) => {
      if (!date || isNaN(date.valueOf())) return onChange(undefined);

      const newValue = format(date, 'HH:mm');
      onChange(newValue);
    };

    return (
      <DesktopTimePicker {...props} value={_value} onChange={handleChange} />
    );
  }
);

TimeInput.displayName = 'TimeInput';

export const parseTime = (timeValue: string): [number, number] => {
  const parts = timeValue.split(':').map(Number);
  if (parts.length !== 2 || parts.some(isNaN)) {
    throw new Error('Invalid time format.');
  }
  return parts as [number, number];
};
