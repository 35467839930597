import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { Button } from '@understory-io/pixel';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ActionFunctionArgs, Outlet, replace, useSubmit } from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { resourceTypesQuery, userInfoQuery } from '../../../../Api/queries';
import { createResourceType } from '../../../../Api/resource-management';
import { FullPage } from '../../../../Components/full-page/full-page';
import { FullPageHeader } from '../../../../Components/full-page/full-page-header';
import { FullPageHeaderBackButton } from '../../../../Components/full-page/full-page-header-back-button';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import routes from '../../../../Utils/routes';
import { UpdateResourceTypeContextProvider } from './update-resource-type-context';
import { UpdateResourceTypeForm } from './update-resource-type-form';
import {
  UpdateResourceTypeInputs,
  updateResourceTypeSchema,
} from './update-resource-type-inputs';

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const toastId = toast.loading(
      t('resourceManagement.resourceType.createNew.toast.loading')
    );

    try {
      const user = await queryClient.fetchQuery(userInfoQuery());
      if (!user.companyId) {
        throw new Error("User doesn't have a company ID");
      }

      const payload = (await request.json()) as UpdateResourceTypeInputs;

      const { resourceTypeId } = await createResourceType({
        ...payload,
        companyId: user.companyId,
      });

      await queryClient.invalidateQueries({
        queryKey: resourceTypesQuery().queryKey,
      });

      toast.dismiss(toastId);

      return replace(routes.resourceManagement.details(resourceTypeId).index);
    } catch (error) {
      captureException(error);
      toast.dismiss(toastId);
      toast.error(t('resourceManagement.resourceType.createNew.toast.error'), {
        delay: 500,
      });
      return {
        success: false,
      };
    }
  };

export const CreateResourceTypePage = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const fireOnce = useFireOnce();
  useEffect(() => {
    fireOnce(() => ampli.resourceTypeCreateFlowStarted());
  }, [fireOnce]);

  const formMethods = useForm({
    defaultValues: {
      name: '',
      capacity: 1,
      recoverTimeInSeconds: 0,
    },
    resolver: yupResolver(updateResourceTypeSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = useCallback(
    async (data: UpdateResourceTypeInputs) => {
      await submit(data, {
        method: 'post',
        action: '',
        encType: 'application/json',
      });
      ampli.resourceTypeCreateFlowCompleted({
        capacity: data.capacity,
        follow_up_time_seconds: data.recoverTimeInSeconds,
      });
    },
    [submit]
  );

  return (
    <UpdateResourceTypeContextProvider links={routes.resourceManagement.create}>
      <FormProvider {...formMethods}>
        <FullPage component="form" onSubmit={handleSubmit(onSubmit)}>
          <FullPageHeader>
            <FullPageHeaderBackButton
              fallbackUrl={routes.resourceManagement.index}
            />
            <Button variant="primary" size="medium" loading={isSubmitting}>
              {t('create', 'fullPageForm.generic')}
            </Button>
          </FullPageHeader>
          <UpdateResourceTypeForm />
        </FullPage>
        <Outlet />
      </FormProvider>
    </UpdateResourceTypeContextProvider>
  );
};
