import { Stack } from '@mui/material';

import { useTranslate } from '../../Hooks/useTranslate';
import DialogBase from '../dialog/dialog-base';
import { TipContent } from './tip-content';
import { Tip } from './use-tips';

interface TipSidebarMobileProps {
  showTip: boolean;
  currentTip: Tip | null;
  onClose: () => void;
  onDismiss: () => void;
}

export const TipSidebarMobile = ({
  showTip,
  currentTip,
  onClose,
  onDismiss,
}: TipSidebarMobileProps) => {
  const { t } = useTranslate('tips');

  return (
    <DialogBase
      open={showTip}
      onClose={onClose}
      title={currentTip?.title}
      description={currentTip?.description}
      maxWidth="sm"
      fullHeight={true}
      actionsInHeader={false}
      showSecondaryActionDesktop={true}
      primaryAction={{
        label: t('dontShowAgainShort'),
        variant: 'secondary',
        onClick: onDismiss,
      }}
    >
      {currentTip && (
        <Stack gap={3} pb={8}>
          <TipContent currentTip={currentTip} />
        </Stack>
      )}
    </DialogBase>
  );
};
