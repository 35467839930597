import {
  CheckRounded,
  ChevronLeftRounded,
  FavoriteBorderRounded,
} from '@mui/icons-material';
import { Divider, Grid, Link, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { updateAdvertisementPlan } from '../../../../../../Api/Marketing';
import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useLocale } from '../../../../../../Hooks/locales/use-locale.context';
import { useAdPlans } from '../../../../../../Hooks/use-ad-plans';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { useProfile } from '../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { getFormattedPrice } from '../../../../../../Utils/helpers';
import { AdPreview } from '../../components/ad-preview';
import { SERVICE_FEE_PRICE_MAP } from '../../constants';
import {
  AdvertisementPlanContext,
  StepProps,
} from './grow-advertisement-flow-steps';

export const ReviewStep: FC<StepProps> = ({ onNext, onPrevious }) => {
  const { t } = useTranslate('grow.advertisement.launch');
  const { isSm } = useResponsive();
  const { adPlan, setAdPlan } = useContext(AdvertisementPlanContext);

  const {
    company: { data: company },
    defaultCurrency,
  } = useProfile();

  const {
    experiences: { data: experiences },
  } = useExperience();

  const activeExperiences = experiences?.filter(
    ({ status }) => status === 'active'
  );

  const {
    adPlans: { refetch: refetchAdPlans },
  } = useAdPlans();

  const { dateFnsLocale } = useLocale();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const serviceFee =
    SERVICE_FEE_PRICE_MAP[
      defaultCurrency as keyof typeof SERVICE_FEE_PRICE_MAP
    ];
  const monthlyTotal = adPlan.budget + serviceFee;

  const formatPrice = (amount: number) =>
    getFormattedPrice(amount, defaultCurrency, dateFnsLocale.code);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const updatedPlan = await updateAdvertisementPlan({
        ...adPlan,
        state: 'pending',
      });
      setAdPlan({ ...updatedPlan, experiences: adPlan.experiences });
      await refetchAdPlans();

      onNext();
    } catch (error) {
      console.error('Error updating advertisement plan:', error);
      toast.error(t('generic', 'utils.errors'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Stack gap={2}>
              {adPlan.goals?.length > 0 && (
                <Stack
                  gap={1}
                  sx={{
                    padding: 3,
                    backgroundColor: lightTheme.palette.contrast.surface2,
                    borderRadius: 2,
                  }}
                >
                  <Text variant="medium">{t('goals')}</Text>
                  {adPlan.goals?.map((goal) => (
                    <Stack key={goal} direction={'row'} gap={0.5}>
                      <CheckRounded fontSize="small" />
                      <Text fontSize="xsmall">
                        {t(`${goal}`, 'grow.advertisement.getStarted.goals')}
                      </Text>
                    </Stack>
                  ))}
                  <Stack direction={'row'} gap={0.5}>
                    <CheckRounded fontSize="small" />
                    <Text fontSize="xsmall">
                      {t('experiences', 'grow.advertisement.getStarted.goals', {
                        count:
                          adPlan.experiences?.length === 0
                            ? activeExperiences?.length
                            : adPlan.experiences?.length,
                      })}
                    </Text>
                  </Stack>
                </Stack>
              )}
              {adPlan.usps?.length > 0 && (
                <Stack
                  gap={1}
                  sx={{
                    padding: 3,
                    backgroundColor: lightTheme.palette.contrast.surface2,
                    borderRadius: 2,
                  }}
                >
                  <Text variant="medium">{t('usps')}</Text>
                  {adPlan.usps?.map((usp) => (
                    <Stack key={usp} direction={'row'} gap={0.5}>
                      <FavoriteBorderRounded fontSize="small" />
                      <Text fontSize="xsmall">{usp}</Text>
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <AdPreview
              companyName={company?.name ?? ''}
              adPlanId={adPlan.id ?? ''}
            />
          </Grid>
        </Grid>
      </Stack>
      <Divider />
      <Stack gap={1.5}>
        <Stack direction="row" justifyContent="space-between">
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {t('adBudget')}
          </Text>
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {formatPrice(adPlan.budget)}
          </Text>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {t('dialog.order.serviceFee')}
          </Text>
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {formatPrice(serviceFee)}
          </Text>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text fontSize="large" variant="medium">
            {t('dialog.order.monthlyCost')}
          </Text>
          <Text fontSize="large" variant="medium">
            {formatPrice(monthlyTotal)}
          </Text>
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          <Trans
            i18nKey="grow.advertisement.launch.dialog.order.disclaimer"
            values={{
              amount: formatPrice(monthlyTotal),
            }}
            components={{
              Link: <Link target="_blank" />,
            }}
          />
        </Text>
        <Stack
          direction={isSm ? 'column-reverse' : 'row'}
          gap={1}
          justifyContent="space-between"
          width={'100%'}
        >
          <Button
            variant="secondary"
            size="large"
            leftIcon={<ChevronLeftRounded />}
            style={{ flex: !isSm ? 1 : 'unset' }}
            onClick={onPrevious}
            disabled={isSubmitting}
          >
            {t('back', 'buttons')}
          </Button>
          <Button
            variant="primary"
            size="large"
            style={{ flex: !isSm ? 1 : 'unset' }}
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('cta')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
