import { useLanguages } from '../../../Hooks/locales/use-languages';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { isStorefrontLanguage } from '../../../i18n/config';
import { useCalendar } from '../context';
import { Filter } from './view/Filter';

export const LanguagesFilter = () => {
  const { t } = useTranslate('');

  const { languageFilters, setLanguageFilters } = useCalendar();
  const { languageOption } = useLanguages();

  const {
    company: { data, isLoading },
  } = useProfile();

  const options = data?.languages
    ?.filter(isStorefrontLanguage)
    .map((locale) => {
      const { label, country } = languageOption(locale);
      return { id: locale, name: country ? `${label} (${country})` : label };
    });

  if (!options) return null;

  return (
    <Filter
      inputId="calendar-languages-filter"
      label={t('languages', 'utils.generic')}
      options={options}
      checkedIds={languageFilters}
      onChange={setLanguageFilters}
      isLoading={isLoading}
      sortOptions={(a, b) => a.name.localeCompare(b.name)}
      includeEmptyOption
    />
  );
};
