import { Event, EventStates } from '@understory-io/utils-types';
import { isAfter, isBefore, isSameDay, subHours } from 'date-fns';

import { getLocalizedString } from '../Hooks/locales/use-locale.context';
import { IExperience, Variant } from '../Hooks/useExperience';
import { Product } from '../Hooks/useProducts';
import { TUser } from '../Hooks/useUsers';
import { BackofficeLanguage } from '../i18n/config';
import { TStatus } from '../Utils/types';
import { sum } from './helpers';
import { mapAssignableGuidesToUsers } from './map-assignable-guides-to-users';

export type Localized = {
  da: string;
  en: string;
};

type OptionValue = {
  selectedOptionKey?: string;
  value: string;
};

export interface IEvent extends Record<string, any> {
  id?: string;
  status: string;
  headline?: Localized | any;
  practicalities?: Localized;
  whatsIncluded?: Localized;
  bookings?: TShallowBooking[] | null;
  slots?: unknown;
}

export type TEvent<
  T extends string | { [key: string]: string | object } = string,
> = {
  name: string;
  recurring: {
    selectedOptionKey: 'yes' | 'no';
    rrulestr: string;
    exDateTimes: string[];
  };
  org: string;
  visibility?: 'public' | 'private';
  id: string;
  externalEventId?: string;
  dates?: { modified: string; created: string };
  experienceId: string;
  parentId?: string;
  status: TStatus;
  states: EventStates;
  slots: {
    booked?: number;
    total: number;
  };
  seatCount: OptionValue;
  bookings: TShallowBooking[];
  waitingList: number;
  assignedGuides: T[];
  languages: Array<keyof Localized>;
  addresses?: {
    selectedOptionKey: string | 'companyAddress' | 'custom';
    value?: string;
  };
  information: Localized;
  locationId: string;
  isOneDay: boolean;
  startDateTime: string;
  endDateTime?: string;
  intervals?: TFromValue;
  cancellationDetails?: {
    cancellationErrors: {
      bookingId: string;
      error: string;
    };
    cancellationNote: [];
    cancelledByUserId: string;
    cancelledOn: string;
    shouldRefund: boolean;
  };
} & Pick<Event, 'resourceManagement' | 'computed' | 'resourceManagementConfig'>;

type TFromValue = {
  selectedOptionKey?: string;
  value: string | number | Record<string, any> | any[];
};

export type TShallowBooking = {
  bookingId: string;
  items: { [variant: string]: number };
  slots: number;
};

export const eventStates = ['all', 'future', 'past'] as const;
export type EventState = (typeof eventStates)[number];

export const isEventState = (value: string): value is EventState => {
  return eventStates.includes(value as EventState);
};

export const eventStatuses = [
  'anyStatus',
  'statusIsActive',
  'statusIsInactive',
  'statusIsCancelled',
] as const;
export type EventStatus = (typeof eventStatuses)[number];

export const isEventStatus = (value: string): value is EventStatus => {
  return eventStatuses.includes(value as EventStatus);
};

export type TEventFilter = {
  state: EventState;
  status: EventStatus;
  locationId?: string;
};

export const getGuidesByIds = (
  guideIds: string[],
  guides: TUser[]
): TUser[] => {
  return guideIds.flatMap(
    (id) => guides?.find((guide) => guide.id === id) ?? []
  );
};

export type TEventColor = {
  color: string;
  borderLeftColor: string;
  backgroundColor: string;
};

export type TEventWithTitle = TEvent & {
  title: string;
  location: string;
  description?: Localized;
  guide: {
    name: string;
    email: string;
  };
  guides?: TUser[];
  colors: TEventColor;
  ticketTypes: Array<{
    ticketName: string;
    quantity: number;
  }>;
  variants?: Variant[];
};

export const transformEvents = (events: TEvent[], users?: TUser[]) => {
  return events.map((ev) => {
    return {
      ...ev,
      ...(users &&
        ev?.assignedGuides && {
          eventGuides: mapAssignableGuidesToUsers(ev.assignedGuides, users),
        }),
      slots: {
        total: Number(ev.seatCount?.value ?? 0),
        booked:
          ev.bookings?.reduce((total, { slots }) => total + slots, 0) ?? 0,
      },
    };
  });
};

export const getEventTitle = (
  exp: IExperience | undefined,
  language: string
): string => {
  const headlines =
    Object.fromEntries(
      Object.entries(exp?.headline ?? {}).filter(([, v]) => v !== '')
    ) ?? {};
  return headlines[language] ?? Object.values(headlines)[0] ?? 'Event';
};

export const stripGuides = (
  items: Array<string | { id: string }> | undefined
) => items?.map((el) => (typeof el === 'string' ? el : el.id)) ?? [];

export const getGuestCount = (items?: Record<string, number>): number => {
  if (!items) return 0;

  const tickets = Object.entries(items)
    .filter(([key]) => key.startsWith('variant'))
    .map(([, value]) => value);

  return sum(tickets);
};

/**
 * Remove guest selections with a count of 0 or without a parent
 */
export const cleanupGuestSelection = (
  guests: Record<string, number>,
  products: Product[]
) => {
  return Object.fromEntries(
    Object.entries(guests).filter(([id, value]) => {
      if (value <= 0) {
        return false;
      }

      const product = products.find((product) => product.id === id);
      if (!product) return false;

      if (product.type === 'addon') {
        const parentValue = guests[product.parent] ?? 0;
        return parentValue > 0;
      }

      return true;
    })
  );
};

export const renderGuestTypes = (
  booking?: { items: Record<string, number> },
  type?: 'variant' | 'addon',
  language: string = 'da',
  variants: Variant[] = []
): string[] => {
  if (!booking) {
    return [];
  }

  const items =
    type === 'addon'
      ? variants.flatMap(({ addons }) => addons ?? [])
      : variants;

  const enriched = Object.entries(booking.items ?? {}).reduce(
    (tickets, [id, count]) => {
      const variantId = id.split('/').pop();
      const variant = items.find((v) => v.id === variantId);

      if (type === 'variant' && (!items || !items.length)) {
        return {
          ...tickets,
          Standard: (tickets['Standard'] ?? 0) + count,
        };
      }

      const name =
        (variant &&
          getLocalizedString(variant.name, [language as BackofficeLanguage])) ??
        'Standard';
      if (type && !id.startsWith(type)) {
        return tickets;
      }

      return {
        ...tickets,
        [name]: (tickets[name] ?? 0) + count,
      };
    },
    {} as Record<string, number>
  );

  return Object.entries(enriched).map(([name, count]) => `${count} x ${name}`);
};

/**
 * Constant to track how many hours to subtract from an event to find it's check in date
 * This is relevant if the event starts at midnight or shortly after,
 * but tickets could be checked before midnight.
 */
const CHECKIN_BEFORE_EVENT_HOURS = 4;

export function isSameDayAsEvent(startDateTime: string, endDateTime?: string) {
  const startDate = subHours(
    new Date(startDateTime),
    CHECKIN_BEFORE_EVENT_HOURS
  );
  const endDate = endDateTime ? new Date(endDateTime) : new Date(startDateTime);

  const today = new Date();
  return (
    isSameDay(today, startDate) ||
    (isAfter(today, startDate) && isBefore(today, endDate))
  );
}
