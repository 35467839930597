import { CloseRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { ContentCard } from './content-card';

export const LoadingKeysError = () => (
  <ContentCard>
    <Box position="relative" width={60} height={60}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-30 -30 60 60"
        fill={lightTheme.palette.error.e100}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <circle r="29.5" fill={lightTheme.palette.error.e100} />
      </svg>
      <CloseRounded
        sx={{
          color: lightTheme.palette.error.e300,
          zIndex: 1,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: 34,
        }}
      />
    </Box>
  </ContentCard>
);
