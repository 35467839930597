import DialogBase from '../../../Components/dialog/dialog-base';
import { useTranslate } from '../../../Hooks/useTranslate';

type DashboardSuggestionsDraftSavedDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const DashboardSuggestionsDraftSavedDialog = ({
  open,
  onClose,
}: DashboardSuggestionsDraftSavedDialogProps) => {
  const { t } = useTranslate('dashboard.suggestions.draftSaved');

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      primaryAction={{
        label: t('ok', 'utils.generic'),
        variant: 'primary',
        type: 'button',
        onClick: onClose,
      }}
      maxWidth="xs"
      fullHeight={false}
      actionsInHeader={false}
    />
  );
};
