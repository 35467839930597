import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightTheme } from '@understory-io/pixel';

export const SuggestionsContainer = styled(Stack)(({ theme }) => ({
  maxWidth: 850,
  gap: theme.spacing(5),
}));

export const SuggestionsContent = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
}));

export const SuggestionCard = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  gap: theme.spacing(2),
  cursor: 'pointer',
  borderRadius: theme.spacing(2),
  backgroundColor: lightTheme.palette.contrast.white,
  boxShadow: lightTheme.shadows.small,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const SuggestionImage = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 12,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  aspectRatio: '16/9',
  [theme.breakpoints.down('sm')]: {
    width: 64,
    minHeight: 64,
    aspectRatio: '1/1',
    borderRadius: 8,
  },
}));

export const SuggestionContent = styled(Stack)(({ theme }) => ({
  flex: 1,
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
  },
}));

export const SuggestionDuration = styled(Box)(() => ({
  color: lightTheme.palette.neutral.n300,
}));

export const SkeletonCard = styled(Stack)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(3),
  height: '100%',
  gap: theme.spacing(2),
  boxShadow: lightTheme.shadows.small,
}));
