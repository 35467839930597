import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightTheme } from '@understory-io/pixel';

export const VideoContainer = styled(Stack)(({ theme }) => ({
  minHeight: '280px',
  padding: theme.spacing(2),
  gap: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: lightTheme.palette.contrast.white,
  boxShadow: lightTheme.shadows.small,
}));

export const VideoThumbnail = styled(Stack)(({ theme }) => ({
  minWidth: '55%',
  minHeight: '160px',
  borderRadius: theme.spacing(1.5),
  overflow: 'hidden',
  cursor: 'pointer',
  backgroundImage: 'url(/graphics/citrus-pine-green-background.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

export const VideoIframeContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%', // 16:9 aspect ratio
  [theme.breakpoints.up('md')]: {
    width: '55%',
    minWidth: '55%',
    paddingTop: '20%',
  },
}));

export const VideoIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
  borderRadius: '8px',
});
