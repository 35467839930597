import { Paper } from '@mui/material';
import { BubbleMenu } from '@tiptap/react';
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonItalic,
  MenuButtonOrderedList,
  useRichTextEditorContext,
} from 'mui-tiptap';
import { PropsWithChildren } from 'react';

import useResponsive from '../../../Hooks/layout/useResponsive';
import {
  MARKDOWN_BUTTON_TYPE,
  trackMarkdownEditorButtonClicked,
} from '../tracking-helper';
import CustomMenuSelectHeading from './custom-menu-select-heading';

interface CustomBubbleMenuProps {}

export default function CustomBubbleMenu({
  children,
}: PropsWithChildren<CustomBubbleMenuProps>) {
  const editor = useRichTextEditorContext();
  const { isSm } = useResponsive();

  if (!editor) return null;

  return (
    <BubbleMenu tippyOptions={{ duration: 100 }} editor={editor}>
      <Paper sx={{ display: { xs: 'flex', md: 'none' }, p: 0.5, gap: 0.25 }}>
        <CustomMenuSelectHeading
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.SELECT_HEADING,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        <MenuButtonBold
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.BOLD,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        <MenuButtonItalic
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.ITALIC,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        <MenuButtonEditLink
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.LINK,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        <MenuButtonBulletedList
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.BULLETED_LIST,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        <MenuButtonOrderedList
          onClick={() =>
            trackMarkdownEditorButtonClicked({
              markdown_button_type: MARKDOWN_BUTTON_TYPE.ORDERED_LIST,
              device_type: isSm ? 'mobile' : 'desktop',
            })
          }
        />
        {children}
      </Paper>
    </BubbleMenu>
  );
}
