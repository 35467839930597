import { ArrowOutwardRounded } from '@mui/icons-material';
import { Box, Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

export const StyledAdvertisementBox = styled(Box)(() => ({
  backgroundColor: 'rgba(213, 223, 202, 0.5)',
  borderRadius: 16,
  padding: 16,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none',
  transition: 'box-shadow 0.2s ease-in-out',
  '&:hover': {
    boxShadow: lightTheme.shadows.small,
  },
}));

const LabelContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const IconCircle = styled(Box)(() => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: 'black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type OutcomeBoxProps = {
  label: string;
  value: React.ReactNode;
  description: string;
  backgroundColor?: string;
};

export const OutcomeBox = ({
  label,
  value,
  description,
  backgroundColor,
}: OutcomeBoxProps) => {
  return (
    <StyledAdvertisementBox sx={{ backgroundColor }}>
      <Stack gap={1} height="100%">
        <LabelContainer>
          <IconCircle>
            <ArrowOutwardRounded
              sx={{ color: lightTheme.palette.primary.p200, fontSize: 14 }}
            />
          </IconCircle>
          <Text fontSize="small">{label}</Text>
        </LabelContainer>
        <Text fontSize="h5" variant="medium">
          {value}
        </Text>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {description}
        </Text>
      </Stack>
    </StyledAdvertisementBox>
  );
};
