import { ConnectedItem, Connection } from '@understory-io/utils-types';

import { getPublicCompanyProfile } from '../../../Api/Profiles';

export const getDistributorNames = async (
  connections: Connection[],
  experienceId: string
): Promise<string> => {
  const distributedToCompanies = connections
    .filter(
      (connection) =>
        connection.state === 'connected' &&
        (connection.sharedExperiences === 'all' ||
          connection.sharedExperiences.includes(experienceId))
    )
    .map((connection) => (connection as ConnectedItem).connectedCompanyId);

  const profiles = await Promise.all(
    distributedToCompanies.map((id) => getPublicCompanyProfile(id))
  );

  return profiles.map(({ name }) => name).join(', ');
};
