import { LayersOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ResourceRule } from '@understory-io/experiences-types';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { FC } from 'react';

import { Badge } from '../../../../Components/badge/badge';
import { useTicketsAndAddons } from '../../../../Hooks/use-tickets-and-addons';
import { useLocalizedStringFormatter } from '../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';
import {
  ElementItem,
  ElementItemContent,
  ElementItemProps,
} from './element-list';

type Props = {
  experienceId: string;
  rule: ResourceRule;
  resourceTypes: ResourceType[];
  locations: Location[];
  allTickets: ReturnType<typeof useTicketsAndAddons>;
} & Pick<ElementItemProps, 'hasError'>;

export const ResourceRuleListItem: FC<Props> = ({
  experienceId,
  locations,
  resourceTypes,
  rule,
  allTickets,
  ...props
}) => {
  const { t } = useTranslate('experience.edit.details');

  const navigateWithParams = useNavigateWithParams();

  const localizer = useLocalizedStringFormatter();

  const resourceTypeByLocationAsArray = Object.entries(
    rule.resourceTypeIdByLocation
  );

  const additionalLocations = resourceTypeByLocationAsArray.length - 1;

  const [locationId, resourceTypeId]: [string | undefined, string | undefined] =
    resourceTypeByLocationAsArray[0] ?? [];

  const resourceTypeName =
    resourceTypes.find((type) => type.resourceTypeId === resourceTypeId)
      ?.name ?? '-';

  const locationName = localizer(
    locations.find((location) => location.locationId === locationId)
      ?.locationName,
    '-'
  );

  const ticketType =
    rule.allocationType === 'ticket'
      ? localizer(
          allTickets.find((ticket) => ticket.id === rule.ticketId)?.name,
          '-'
        )
      : null;

  const baseRoute = routes.experience.details(experienceId).edit.resources;

  return (
    <ElementItem
      {...props}
      key={rule.ruleId}
      onEdit={() => navigateWithParams(baseRoute.edit(rule.ruleId))}
      onDelete={() => navigateWithParams(baseRoute.delete(rule.ruleId))}
      Icon={LayersOutlined}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>
          <ElementItemContent
            value={`${resourceTypeName} (${locationName})`}
            description={`${t(
              `resourceRules.per.${rule.allocationType}`
            )}${ticketType ? `: ${ticketType}` : ''}`}
          />
        </div>
        {additionalLocations > 0 && (
          <Badge size="small" appearance="success">
            {`+${additionalLocations}`}{' '}
            {t('locations', 'utils.generic').toLocaleLowerCase()}
          </Badge>
        )}
      </Stack>
    </ElementItem>
  );
};
