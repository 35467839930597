import { Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StorefrontLanguage } from '../../../../../i18n/config';
import { Variant } from '../../../schemas/ticketSchema';
import { createErrorObject } from '../../utils/form-helpers';
import { VatRegistrationPopover } from './vat-registration-popover';

interface TicketInfoProps {
  defaultCurrency: string;
  defaultVatCategory: string;
  vatCategories: { key: string; label: string }[];
  variant: Variant | undefined;
  activeLanguage: StorefrontLanguage;
  errors?: ReturnType<typeof createErrorObject<Variant>>;
  hasPaymentsEnabled: boolean;
  onPricingClick: () => void;
}

export const TICKET_PROPERTY_NAME = 'ticketName';
export const TICKET_PRICE_PROPERTY_NAME = 'ticketPrice';
export const TICKET_VAT_CATEGORY_PROPERTY_NAME = 'ticketVatCategory';
export const TICKET_EXPLANATION_PROPERTY_NAME = 'ticketExplanation';
export const MIN_VALUE = 0;

export function TicketInfo({
  defaultCurrency,
  defaultVatCategory,
  vatCategories,
  variant,
  activeLanguage,
  errors,
  hasPaymentsEnabled,
  onPricingClick,
}: TicketInfoProps) {
  const { t } = useTranslate('experience.edit.dialog.tickets.input.label');
  const { t: tError } = useTranslation();

  return (
    <Grid container spacing={1} rowSpacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t('name')}
          placeholder={t('name')}
          defaultValue={variant?.name?.[activeLanguage]}
          error={Boolean(errors?.name?.[activeLanguage])}
          helperText={
            errors?.name?.[activeLanguage]
              ? tError(errors.name[activeLanguage])
              : undefined
          }
          name={TICKET_PROPERTY_NAME}
          fullWidth
          hiddenLabel
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        item
        xs={hasPaymentsEnabled ? 8 : 12}
        sm={hasPaymentsEnabled ? 3 : 6}
      >
        <TextField
          label={t('price', {
            currency: t(defaultCurrency?.toUpperCase(), 'utils.currency'),
          })}
          placeholder={t('price', {
            currency: t(defaultCurrency?.toUpperCase(), 'utils.currency'),
          })}
          defaultValue={
            typeof variant?.priceBreakdown?.vatInclusivePriceCents === 'number'
              ? variant.priceBreakdown.vatInclusivePriceCents / 100
              : 0
          }
          error={Boolean(errors?.priceBreakdown?.vatInclusivePriceCents)}
          helperText={
            errors?.priceBreakdown?.vatInclusivePriceCents
              ? tError(errors.priceBreakdown.vatInclusivePriceCents)
              : undefined
          }
          type="number"
          name={TICKET_PRICE_PROPERTY_NAME}
          fullWidth
          hiddenLabel
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              min: MIN_VALUE,
              readOnly: !hasPaymentsEnabled,
            },
          }}
          onClick={onPricingClick}
        />
      </Grid>
      {hasPaymentsEnabled && (
        <Grid item xs={4} sm={3}>
          {vatCategories.length > 0 ? (
            <TextField
              label={t('vatCategory')}
              placeholder={t('vatCategory')}
              defaultValue={
                variant?.priceBreakdown?.vatSetting?.vatCategory &&
                variant?.priceBreakdown?.vatSetting?.vatCategory !== 'n/a'
                  ? variant?.priceBreakdown?.vatSetting?.vatCategory
                  : defaultVatCategory
              }
              name={TICKET_VAT_CATEGORY_PROPERTY_NAME}
              error={Boolean(errors?.priceBreakdown?.vatSetting?.vatCategory)}
              helperText={
                errors?.priceBreakdown?.vatSetting?.vatCategory
                  ? tError(errors.priceBreakdown.vatSetting.vatCategory)
                  : undefined
              }
              select
              fullWidth
              hiddenLabel
              InputLabelProps={{ shrink: true }}
            >
              {vatCategories.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <VatRegistrationPopover />
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label={t('explanation')}
          placeholder={t('explanation')}
          defaultValue={variant?.explanation?.[activeLanguage]}
          error={Boolean(errors?.explanation?.[activeLanguage])}
          helperText={
            errors?.explanation?.[activeLanguage]
              ? tError(errors.explanation[activeLanguage])
              : undefined
          }
          name={TICKET_EXPLANATION_PROPERTY_NAME}
          fullWidth
          hiddenLabel
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
}
